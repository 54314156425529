import React, { CSSProperties, useEffect, useState } from 'react';
import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const MyPortalBox = ({ icon, customText, notificationType, toggleVisibility, selected, additionalText = '' }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const [boxColor, setBoxColor] = useState<any>(isDarkTheme ? '#192b45' : '#d9d9d9');
    const [titleColor, setTitleColor] = useState<any>(isDarkTheme ? '#ffffff' : 'black');

    const getSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION_SETTINGS.LIST, { max: 100 }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    const foundSetting = result.data.content?.find(
                        (setting: any) => setting.notificationType === notificationType
                    );
                    if (foundSetting) {
                        setBoxColor(foundSetting.color);
                    }
                }
            }
        });
    };

    const lightenColor2 = (color: string): string => {
        color = color?.replace('#', '');

        let r: number = parseInt(color?.substring(0, 2), 16);
        let g: number = parseInt(color?.substring(2, 4), 16);
        let b: number = parseInt(color?.substring(4, 6), 16);

        r = Math.floor(((255 - r) * 70) / 100) + r;
        g = Math.floor(((255 - g) * 70) / 100) + g;
        b = Math.floor(((255 - b) * 70) / 100) + b;

        r = Math.min(Math.max(0, r), 255);
        g = Math.min(Math.max(0, g), 255);
        b = Math.min(Math.max(0, b), 255);

        let lightenedColor: string = `#${(r * 65536 + g * 256 + b).toString(16).padStart(6, '0')}`;

        return lightenedColor;
    };

    const isColorDark = (color: any) => {
        color = color?.replace('#', '');

        const r = parseInt(color?.substr(0, 2), 16);
        const g = parseInt(color?.substr(2, 2), 16);
        const b = parseInt(color?.substr(4, 2), 16);

        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        return luminance < 0.3;
    };

    const isDarkColor = isColorDark(boxColor);

    let iconStyle: CSSProperties = {
        margin: '0 0 25px',
        overflow: 'hidden',
        fontWeight: 'bold',
        fontSize: '35px',
        color: titleColor,
        zIndex: 2,
        position: 'absolute',
        transition: 'color 0.3s ease',
    };

    let bgStyle: CSSProperties = {
        background: `linear-gradient(0.25turn, ${boxColor} 5%, ${lightenColor2(boxColor)} 100%)`,
        transform: selected ? 'scale(3)' : 'scale(0.5)',
    };

    const handleClick = () => {
        if (selected) {
            if (isDarkColor) {
                setTitleColor(isDarkTheme ? '#ffffff' : 'black');
            }
        } else {
            if (isDarkColor) {
                setTitleColor('#ffffff');
            }
        }

        toggleVisibility();
    };

    useEffect(() => {
        if (!selected) {
            if (isDarkColor) {
                setTitleColor(isDarkTheme ? '#ffffff' : 'black');
            }
        }
    }, [selected]);

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <>
            <div
                className="ag-courses_item"
                style={{
                    backgroundColor: isDarkTheme ? '#192b45' : '#fafafa',
                    outlineColor: '#f0f0f0',
                    outlineStyle: 'solid',
                    outlineWidth: isDarkTheme ? '0px' : '1px',
                    cursor: 'pointer',
                }}
            >
                <div className="ag-courses-item_link" onClick={handleClick}>
                    {icon && React.cloneElement(icon, { style: iconStyle })}
                    <div className="ag-courses-item_bg" style={bgStyle}></div>
                    <div style={{ display: 'inline-block' }}>
                        <div
                            className="ag-courses-item_title"
                            style={{
                                color: titleColor,
                                display: 'flex',
                                justifyContent: 'center',
                                top: '25px',
                            }}
                        >
                            {customText ? customText : geti18nText('myPortal.widget.title.' + notificationType)}
                        </div>
                        <div
                            style={{
                                color: titleColor,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                position: 'relative',
                                zIndex: '2',
                                top: '20px',
                            }}
                        >
                            {additionalText}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyPortalBox;
