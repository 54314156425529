import { geti18nText, NyDataEdit, NySession } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';

const HumanCodebooksEdit = (props: any) => {
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    const [editHeader, setEditHeader] = useState(geti18nText('human.codebooks.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [codebookType, setCodebookType] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [booleanCodeValue, setBooleanCodeValue] = useState<boolean>(false);

    const enumOptions = [
        { value: '0', label: geti18nText('employee.evaluation.competency.type.resultsOrientation') },
        { value: '1', label: geti18nText('employee.evaluation.competency.type.leadership') },
        { value: '2', label: geti18nText('employee.evaluation.competency.type.collaboration') },
    ];

    const canCreate = () => {
        if (props?.editProps?.additionalRoles) {
            return HumanResourcesRights.canCreateCodebooks() || NySession.hasAnyRole(props?.editProps?.additionalRoles);
        } else return HumanResourcesRights.canCreateCodebooks();
    };

    const getCodebooksType = () => {
        return props?.editProps?.codebooksType ?? props?.addedData?.codebooksType;
    };

    const isSelectValue = () => {
        return false;
    };

    const isBooleanValue = () => {
        const codebooksType = getCodebooksType();
        return (
            codebooksType &&
            Object.values([
                enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION,
                enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE,
            ]).includes(codebooksType)
        );
    };

    const getCodeTitle = () => {
        const codebooksType = getCodebooksType();
        return codebooksType == enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE
            ? geti18nText('human.codebooks.table.column.evaluationLeader')
            : codebooksType == enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION
            ? geti18nText('human.codebooks.edit.needComment')
            : geti18nText('human.codebooks.edit.code');
    };

    useEffect(() => {
        setCodebookType(getCodebooksType());
    }, [props]);

    useEffect(() => {
        getHumanCodebooksView(codebookType);
    }, [codebookType]);

    const getHumanCodebooksView = (codebooksType: any = 0, isCreate = true, additionalData = '') => {
        if (codebooksType && codebooksType > 0) {
            setEditHeader(
                isCreate
                    ? geti18nText('human.codebooks.edit.new.' + codebooksType)
                    : geti18nText('human.codebooks.edit.title.' + codebooksType) + ' - ' + additionalData
            );
        } else {
            setEditHeader(
                isCreate
                    ? geti18nText('human.codebooks.edit.new')
                    : geti18nText('human.codebooks.edit.title') + ' - ' + additionalData
            );
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (
            dataForm.codebookType == enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_TYPE ||
            dataForm.codebookType == enCodebookType.EMPLOYEE_EVALUATION_COMPETENCY_ESTIMATION ||
            dataForm.codebookType == enCodebookType.PERSON_MARRIAGE_STATUS ||
            dataForm.codebookType == enCodebookType.EMPLOYEE_DEMISSION_TYPE
        ) {
            if (dataForm.hasOwnProperty('name')) {
                getHumanCodebooksView(dataForm.codebookType, false, dataForm.name);
            }
        } else {
            if (dataForm.hasOwnProperty('name')) {
                getHumanCodebooksView(dataForm.name);
            }
        }
        if (isBooleanValue()) {
            dataForm.code = dataForm?.code == '1' ? true : false;
            setBooleanCodeValue(dataForm.code);
        } else if (isSelectValue()) {
        }
        setCodebookType(dataForm.codebookType);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setCodebookType(null);
        setEditHeader(geti18nText('human.codebooks.edit.new'));
        setBooleanCodeValue(false);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalOpen={onModalOpen}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.HUMAN_CODEBOOKS.EDIT}
            setValues={setValues}
            width={820}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                if (codebookType) {
                    values.codebookType = codebookType;
                }
                if (isBooleanValue()) {
                    values.code = booleanCodeValue == true ? '1' : '0';
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('human.codebooks.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} style={{ width: '100%' }} disabled={!canCreate()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {isBooleanValue() ? (
                        <Form.Item label={getCodeTitle()} name="code" valuePropName="checked">
                            <Checkbox
                                checked={booleanCodeValue}
                                onChange={(e: any) => {
                                    setBooleanCodeValue(e.target.checked);
                                }}
                            ></Checkbox>
                        </Form.Item>
                    ) : isSelectValue() ? (
                        <Form.Item
                            label={getCodeTitle()}
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <Select options={enumOptions} />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label={getCodeTitle()}
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} disabled={!canCreate()} />
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default HumanCodebooksEdit;
