import { AuditOutlined, EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { refreshItemList } from '../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorCustomNotification,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumArray,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';
import EmployeeTravelRelationTable from './EmployeeTravelRelationTable';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const { TabPane } = Tabs;
const { confirm } = Modal;

const EmployeeTravelRelationPaymentEdit = (props: any) => {
    const dispatch = useDispatch();
    const [editHeader, setEditHeader] = useState(geti18nText('employeeTravelRelationPayment.edit.new'));
    const [loading, setLoading] = useState(false);

    const [paymentForm] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [travelWarrantStatusOptions] = useState([
        ...getEnumArrayStatus('TRAVEL_WARRANT_STATUS').map((s: any) => {
            return s.id === 1 ? { ...s, disabled: true } : s;
        }),
    ]);
    const [status, setStatus] = useState(1);
    const [travelWarrantStatus, setTravelWarrantStatus] = useState(1);
    const [employeeTravelRelationPayment, setEmployeeTravelRelationPayment] = useState<any>(null);
    const [defaultPaymentType, setDefaultPaymentType] = useState<any>(undefined);
    const [form] = Form.useForm();

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelLocal();
    };

    function setValues(dataForm: any) {
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        if (dataForm.calculationDate) {
            dataForm.calculationDate = setDateFormat(dataForm.calculationDate);
        }
        if (dataForm.periodFrom) {
            dataForm.periodFrom = setDateFormat(dataForm.periodFrom);
        }
        if (dataForm.periodTo) {
            dataForm.periodTo = setDateFormat(dataForm.periodTo);
        }
        if (dataForm.payDate) {
            dataForm.payDate = setDateFormat(dataForm.payDate);
        }
        if (dataForm.status) {
            setStatus(dataForm.status);
            setTravelWarrantStatus(dataForm.status);
            dataForm.status = setEnumFormat('TRAVEL_WARRANT_STATUS', dataForm.status);
        }
        if (dataForm.paymentType) {
            dataForm.paymentType = setEnumFormat('TRAVEL_WARRANT_PAYMENT_TYPE', dataForm.paymentType);
        }

        setEditHeader(geti18nText('employeeTravelRelationPayment.edit.title') + ' - ' + dataForm.ordinal);
        setEmployeeTravelRelationPayment(dataForm.id);

        delete dataForm.active;
        paymentForm.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        paymentForm.resetFields();
        setStatus(1);
        setTravelWarrantStatus(1);
        setEmployeeTravelRelationPayment(null);
        setEditHeader(geti18nText('employeeTravelRelationPayment.edit.new'));
    };

    const statusOnChange = (value: any) => {
        if (value && value.text != '' && value.id) {
            if (travelWarrantStatus > value.id) {
                showConfirm(value);
            } else {
                setStatus(value.id);
            }
        }
    };

    function showConfirm(val: any) {
        const message = geti18nText('payment.orders.initialization.edit.status.comfirm');
        const content =
            geti18nText('travel.warrant.current.status') +
            ': ' +
            geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + travelWarrantStatus);
        confirm({
            title: message,
            content: content,
            okText: geti18nText('app.default.button.ok'),
            icon: <ExclamationCircleOutlined />,
            onCancel() {
                paymentForm.setFieldsValue({ status: setEnumFormat('TRAVEL_WARRANT_STATUS', status) });
            },
            onOk() {
                setStatus(val && val.id);
                paymentForm.setFieldsValue({ payDate: undefined });
            },
        });
    }

    const onSaveAndGetID = (data: any) => {
        dispatch(refreshItemList());
    };

    const paymentTypeOnChange = (value: any) => {
        if (value.id === -1) {
            paymentForm.setFieldsValue({ paymentType: undefined });
        }
    };

    const generatePaymentOrderForEmployeeTravelRelationPayment = () => {
        if (employeeTravelRelationPayment) {
            setLoading(true);
            NyRequestResolver.requestPost(
                CONSTANTS_REQ.PAYMENT_ORDER.GENERATE_EMPLOYEE_TRAVEL_RELATION_PAYMENT,
                undefined,
                {
                    employeeTravelRelationPaymentId: employeeTravelRelationPayment,
                    lang: NyUtils.getSelectedLanguage(),
                }
            ).then((result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                } else if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {canCreate() && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            block
                            icon={<AuditOutlined />}
                            onClick={() => {
                                generatePaymentOrderForEmployeeTravelRelationPayment();
                            }}
                        >
                            {geti18nText(
                                'employeeTravelRelationPayment.action.generatePaymentOrderForEmployeeTravelRelationPayment'
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']} disabled={id === 'create'}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        </>
    );
    useEffect(() => {
        getAppSettings();
    }, []);

    const setAppSettingsDefaultFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 2 },
            { field: 'settingGroup', condition: 'equals', value: 'TRAVEL_WARRANT' },
        ];
    };

    const getAppSettings = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsDefaultFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_PAYMENT_TYPE') {
                                form.setFieldsValue({
                                    paymentType: setEnumFormat(
                                        'TRAVEL_WARRANT_PAYMENT_TYPE',
                                        Number(element.value ? element.value : element.defaultValue)
                                    ),
                                });
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <NyDataEdit
            {...props}
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onSaveAndGetID={onSaveAndGetID}
            url={CONSTANTS_REQ.EMPLOYEE_TRAVEL_RELATION_PAYMENT.EDIT}
            setValues={setValues}
            width={1200}
            form={paymentForm}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                if (values.employee) values.employee = getSearchFormat(values.employee);
                if (values.calculationDate) values.calculationDate = getDateFormat(values.calculationDate);
                if (values.periodFrom) values.periodFrom = getDateFormat(values.periodFrom);
                if (values.periodTo) values.periodTo = getDateFormat(values.periodTo);
                if (values.payDate) values.payDate = getDateFormat(values.payDate);
                if (values.paymentType) values.paymentType = getEnumFormat(values.paymentType);
                values.status = values.status ? getEnumFormat(values.status) : 2;
                if (values.status === 1) {
                    delete values.status;
                }

                return values;
            }}
            shortcuts={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            customFooterContent={getCustomFooterContent}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Tabs type="card">
                        <TabPane tab={geti18nText('employeeTravelRelationPayment.tab.general')} key="general">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Row gutter={24}>
                                        <Form.Item name="id" style={{ display: 'none' }}>
                                            <Input />
                                        </Form.Item>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText(
                                                    'employeeTravelRelationPayment.edit.calculationDate'
                                                )}
                                                name="calculationDate"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                                initialValue={moment()}
                                            >
                                                <NyDatePicker
                                                    style={{ width: '100%' }}
                                                    nyTestId="calculation-date"
                                                    autoFocus={true}
                                                    disabled={status !== 1}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.ordinal')}
                                                name="ordinal"
                                            >
                                                <NyInputNumber
                                                    disabled={status !== 2}
                                                    style={{ width: '100%' }}
                                                    nyTestId="ordinal"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.employee')}
                                                name="employee"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                    map={{
                                                        id: 'id',
                                                        label: 'text',
                                                        employmentRecordId: 'employmentRecordId',
                                                    }}
                                                    searchBy="person.first_name || ' ' || person.last_name"
                                                    itemName={[
                                                        ['person', 'firstName'],
                                                        ['person', 'lastName'],
                                                    ]}
                                                    renderOption={customEmployeeRenderOption}
                                                    customItemNameLabel={'firstName lastName'}
                                                    SearchPopupComponent={
                                                        <EmployeeIndex
                                                            disabled={true}
                                                            parentKey="travel-relation-payment-edit"
                                                        />
                                                    }
                                                    nyTestId="employee"
                                                    disabled={status !== 1}
                                                    order="person.last_name, person.first_name"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.periodFrom')}
                                                name="periodFrom"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NyDatePicker
                                                    style={{ width: '100%' }}
                                                    nyTestId="period-from"
                                                    disabled={status !== 1}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.periodTo')}
                                                name="periodTo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NyDatePicker
                                                    style={{ width: '100%' }}
                                                    nyTestId="period-to"
                                                    disabled={status !== 1}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.totalDistance')}
                                                name="totalDistance"
                                            >
                                                <NyInputNumber style={{ width: '100%' }} disabled={status !== 2} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.totalPrice')}
                                                name="totalPrice"
                                            >
                                                <NyInputNumber
                                                    isDecimal={true}
                                                    style={{ width: '100%' }}
                                                    disabled={status !== 2}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.totalAmount')}
                                                name="totalAmount"
                                            >
                                                <NyInputNumber
                                                    isDecimal={true}
                                                    style={{ width: '100%' }}
                                                    disabled={status !== 2}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.payDate')}
                                                name="payDate"
                                                rules={[
                                                    {
                                                        required: status === 3,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NyDatePicker
                                                    style={{ width: '100%' }}
                                                    nyTestId="pay-date"
                                                    disabled={status === 1 ? true : false}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.paymentType')}
                                                name="paymentType"
                                                rules={[
                                                    {
                                                        required: status !== 1,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    options={getEnumArray('TRAVEL_WARRANT_PAYMENT_TYPE').map(
                                                        (s: any) => {
                                                            return s.id !== 2 ? { ...s, disabled: true } : s;
                                                        }
                                                    )}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    nyTestId="payment-type"
                                                    disabled={status === 1 ? true : false}
                                                    onChange={paymentTypeOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label={geti18nText('employeeTravelRelationPayment.edit.status')}
                                                name="status"
                                                rules={[
                                                    {
                                                        required: status !== 1,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                                initialValue={setEnumFormat('TRAVEL_WARRANT_STATUS', 1)}
                                            >
                                                <NySearchField
                                                    options={travelWarrantStatusOptions}
                                                    map={{ id: 'id', label: 'text' }}
                                                    searchBy="text"
                                                    className=""
                                                    style={{ width: '100%' }}
                                                    disabled={status === 1 ? true : false}
                                                    onChange={statusOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane
                            tab={geti18nText('employeeTravelRelationPayment.tab.relation')}
                            key="relation"
                            disabled={status === 1}
                        >
                            <EmployeeTravelRelationTable
                                disabled={true}
                                employeeTravelRelationPayment={employeeTravelRelationPayment}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeTravelRelationPaymentEdit;
