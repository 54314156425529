import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Alert, Checkbox, Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import JoppdCodeIndex from '../../components/joppd-code';
import NyNoteModal from '../../components/note-modal';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumNameForValue } from '../../utils/Enums';
import NyFilesUpload from '../../utils/ny-file-upload-custom';
import {
    formatJoppdCodeSearch,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setJoppdCodeDefaultFilterValue,
    setSearchFormat,
    warningNotification,
} from '../../utils/Utils';
import CurrencySearch from '../../views/administration/views/currency/search';
import TravelExpensePaymentMethodSearch from '../../views/travel-warrant/views/travel-expense-payment-method/search';
import TravelWarrantExpensesSearch from './search';
import InModalFilePreview from '../in-modal-file-preview';

const TravelWarrantExpenseEdit = (props: any) => {
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [editHeader, setEditHeader] = useState(geti18nText('travelWarrantExpense.edit.new'));
    const { employee } = useSelector((state: RootState) => state.employee);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [isAdmin, setIsAdmin] = useState<Boolean>(false);
    const [person, setPerson] = useState<any>({});
    const [dataForm, setDataForm] = useState<any>(null);
    const [travelExpense, setTravelExpense] = useState<any>('');
    const [description, setDescription] = useState<any>('');
    const [mandatoryFile, setMandatoryFile] = useState(props.editProps.mandatoryFile);
    const [hasMandatoryFiles, setHasMandatoryFiles] = useState<boolean>(false);
    const [initialCurrency, setInitialCurrency] = useState<any>(false);
    const [taxable, setTaxable] = useState<any>(false);
    const [previewID, setPreviewID] = useState<any>(null);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const [employeeTravelWarrant, setEmployeeTravelWarrant] = useState<any>(null);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);

    const disableForm: boolean = props?.editProps?.disabled;
    const canEditStatusAndCalculationDatePaid: boolean = props?.editProps?.canEditStatusAndCalculationDatePaid;

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    useEffect(() => {
        if (props?.editProps.setRefreshFiles) {
            props.editProps.setRefreshFiles(props.editProps.refreshFiles + 1);
        }
    }, [hasMandatoryFiles]);

    useEffect(() => {
        if (employee.person && employee.person) setPerson(employee.person);
    }, [employee]);

    const canCreateAdmin = () => {
        return TravelWarrantRights.canCreateTravelWarrantAdmin();
    };

    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrant() && !disableForm;
    };

    const filesError = () => {
        return !hasMandatoryFiles && mandatoryFile && !isAdmin;
    };

    const setDefaultFilterValueFiles = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: props && props.addedData && props.addedData.id },
            { field: 'travel_warrant_expense_id', condition: 'equals', value: dataForm },
        ];
    };

    const setDefaultFilterValueAppSettingsFiles = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' },
            { field: 'setting_key', condition: 'equals', value: 'TRAVEL_WARRANT_MANDATORY_PURPOSE_AND_ATTACHMENT' },
        ];
    };

    const setDefaultFilterValuePaymentMethod = () => {
        if (canEditStatusAndCalculationDatePaid) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'reimbursed', condition: 'equals_bool', value: 0 },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const getExchangeRate = () => {
        let date = form.getFieldValue(['expenseDate']);
        let currency = form.getFieldValue(['currency']);

        if (date && currency && currency.id > 0) {
            var parms: any = { currencyId: currency.id, date: getDateFormat(date) };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EXCHANGE_RATE.GET_EXCHANGE_RATE, parms).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    if (response.data && response.data.middleRate) {
                        form.setFieldsValue({ exchangeRate: response.data.middleRate });
                        calculateAmount();
                    }
                } else {
                    form.setFieldsValue({ exchangeRate: 0 });
                    warningNotification(geti18nText('exchangeRate.not.found'));
                }
            });
        } else {
            form.setFieldsValue({ exchangeRate: 0 });
        }
    };

    const getAppSettingsFiles = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettingsFiles())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    const element = result.data[0];
                    const value =
                        element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
                    if (value == 1) {
                        setMandatoryFile(true);
                    } else {
                        setMandatoryFile(false);
                    }
                }
            }
        });
    };

    async function getCurrencyIsoCode(code: any) {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.ISO_CODE, { code }).then((result: any) => {
            if (result.data.id) {
                setInitialCurrency(setSearchFormat(result.data, 'isoCode'));
            } else {
                setInitialCurrency(setSearchFormat({ id: 0, text: code }));
            }

            if (dataForm == null) {
                form.setFieldsValue({ currency: setSearchFormat(result.data, 'isoCode') });
            }

            getExchangeRate();
        });
    }

    async function setValues(dataForm: any) {
        if (dataForm.expenseDate) {
            dataForm.expenseDate = setDateFormat(dataForm.expenseDate);
        }
        if (dataForm.travelExpense) {
            dataForm.travelExpense = setSearchFormat(dataForm.travelExpense, 'name', 'name');
        }
        if (dataForm.currency) {
            dataForm.currency = setSearchFormat(dataForm.currency, 'isoCode');
        }
        if (dataForm.joppdTaxFreeCode) {
            dataForm.joppdTaxFreeCode = await formatJoppdCodeSearch(dataForm.joppdTaxFreeCode, 2, '15.1.');
        }
        if (dataForm.joppdPaymentCode) {
            dataForm.joppdPaymentCode = await formatJoppdCodeSearch(dataForm.joppdPaymentCode, 2, '16.1.');
        }
        if (dataForm.travelExpensePaymentMethod) {
            dataForm.travelExpensePaymentMethod = setSearchFormat(dataForm.travelExpensePaymentMethod, 'name', 'name');
        }
        if (dataForm.bookExpense !== undefined) {
            dataForm.bookExpense = setEnumFormat('BOOK_OPTIONS', dataForm.bookExpense);
        }

        setTaxable(dataForm.taxable);

        setNote(dataForm.note ? dataForm.note : undefined);
        setEditHeader(geti18nText('travelWarrantExpense.edit.title') + ' - ' + dataForm.id);
        setDataForm(dataForm.id);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
        resolveFileDescription();
    }

    const onModalClose = () => {
        form.resetFields();
        form.setFieldsValue({ fileDescription: '' });
        setNote(undefined);
        setDataForm(null);
        setDescription('');
        setEditHeader(geti18nText('travelWarrantExpense.edit.new'));
    };

    const onModalOpen = () => {
        setIsAdmin(props && props.addedData && props.addedData.isAdmin ? true : false);
        getTravelWarrantEmployee();
        getAppSettingsFiles();
        if (!props?.value?.id) {
            getCurrencyIsoCode(defaultCurrencyISO);
        }
        resolveFileDescription();
    };

    const getTravelWarrantEmployee = () => {
        if (props && props.addedData && props.addedData.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + props.addedData.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data && result.data.employee) {
                        setEmployeeTravelWarrant(result.data.employee);
                    }
                }
            );
        }
    };

    const resolveFileDescription = () => {
        let travelExpenseName = travelExpense;
        let descriptionValue = form.getFieldValue(['description']) ? form.getFieldValue(['description']) : '';
        form.setFieldsValue({ fileDescription: travelExpenseName.concat(' ').concat(descriptionValue) });
    };

    const travelExpenseOnChange = async (value: any) => {
        const val = value?.name?.props && value?.name?.props['custom-data'];

        if (val && val.id > 0) {
            if (val.name) {
                setTravelExpense(val.name);
                resolveFileDescription();
            }

            if (val.includeInJoppd != undefined) {
                form.setFieldsValue({ includeInJoppd: val.includeInJoppd ? val.includeInJoppd : false });
            }

            if (val.joppdPaymentCode != undefined) {
                form.setFieldsValue({
                    joppdPaymentCode: val.joppdPaymentCode
                        ? await formatJoppdCodeSearch(val.joppdPaymentCode, 2, '16.1.')
                        : undefined,
                });
            }

            if (val.joppdTaxFreeCode != undefined) {
                form.setFieldsValue({
                    joppdTaxFreeCode: val.joppdTaxFreeCode
                        ? await formatJoppdCodeSearch(val.joppdTaxFreeCode, 2, '15.1.')
                        : undefined,
                });
            }
            form.setFieldsValue({
                bookExpense: val?.bookExpense ? setEnumFormat('BOOK_OPTIONS', val.bookExpense) : null,
            });

            form.setFieldsValue({ price: 0 });
        }
    };

    const descriptionOnChange = (value: any) => {
        if (value && value.target && value.target.value) {
            setDescription(value.target.value);
            resolveFileDescription();
        }
    };

    const setDefaultFilterValue = (type: String) => {
        return setJoppdCodeDefaultFilterValue(2, type);
    };

    const calculateAmount = () => {
        let amount = 0;
        let exchangeRate = form.getFieldValue(['exchangeRate']);
        let amountInCurrency = form.getFieldValue(['amountInCurrency']);
        if (exchangeRate && amountInCurrency) {
            amount = amountInCurrency * exchangeRate;
        }
        form.setFieldsValue({ amount: amount });
    };

    const travelExpensePaymentMethodOnChange = (value: any) => {
        const val = value?.name?.props && value?.name?.props['custom-data'];

        if (val && val.id > -1) {
            if (val.reimbursed != undefined) {
                form.setFieldsValue({
                    reimbursed: val.reimbursed ? val.reimbursed : false,
                });
            }
        }
    };

    const getCustomFooterContent = (
        <div className="ny-modal-footer-content">
            <NyNoteModal note={note} />
        </div>
    );

    const disabledExpenseDate = (current: any) => {
        if (props && props.addedData && props.addedData.startDate) {
            return moment(current) < moment(props.addedData.startDate);
        }
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.TRAVEL_WARRANT_EXPENSE.EDIT}
            setValues={setValues}
            width={previewID != null && showPreview ? 2000 : 900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={props && props.addedData && props.addedData.id}
            {...props}
            normalize={(values: any) => {
                if (values.expenseDate) {
                    values.expenseDate = getDateFormat(values.expenseDate);
                }
                if (values.travelExpense) {
                    values.travelExpense = getSearchFormat(values.travelExpense);
                }
                if (values.currency) {
                    values.currency = getSearchFormat(values.currency);
                }
                if (values.travelExpensePaymentMethod) {
                    values.travelExpensePaymentMethod = getSearchFormat(values.travelExpensePaymentMethod);
                }

                if (props.addedData?.isAdmin) {
                    values.includeInJoppd = values.includeInJoppd ?? false;
                    if (values.joppdTaxFreeCode) values.joppdTaxFreeCode = values.joppdTaxFreeCode.code;
                    if (values.joppdPaymentCode) values.joppdPaymentCode = values.joppdPaymentCode.code;
                    values.reimbursed = values.reimbursed ?? false;
                } else {
                    delete values.reimbursed;
                    delete values.includeInJoppd;
                    delete values.joppdTaxFreeCode;
                    delete values.joppdPaymentCode;
                }

                if (props.editProps?.isAdminForm) {
                    values.isAdmin = true;
                }
                values.bookExpense = values?.bookExpense ? getEnumFormat(values.bookExpense) : null;

                values.taxable = taxable;

                values.travelWarrant = { id: props && props.addedData && props.addedData.id };
                delete values.files;
                return values;
            }}
            customFooterContent={getCustomFooterContent}
            shortcuts={true}
            nyTestId="expense-data-edit"
            hideSubmitButton={!canCreate() || filesError()}
            hideActivationButtons={!canCreate()}
            onSaveAndGetID={(id: any) => {
                if (uploadFilesRef?.current) {
                    uploadFilesRef.current.filesUpload(id);
                }
            }}
        >
            <Row gutter={24} style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Col span={previewID != null && showPreview ? 14 : 24}>
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="fileDescription" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={12}>
                            <TravelWarrantExpensesSearch
                                travelExpenseOnChange={travelExpenseOnChange}
                                showAllColumns={canCreateAdmin()}
                                mustGetPopupContainer={false}
                                tooltip={geti18nText('travelWarrantExpense.edit.travelExpenseName.tooltip')}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.expenseDate')}
                                name="expenseDate"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                initialValue={
                                    props && props.addedData && props.addedData.startDate
                                        ? props.addedData.startDate
                                        : moment()
                                }
                                tooltip={geti18nText('travelWarrantExpense.edit.expenseDate.tooltip')}
                            >
                                <NyDatePicker
                                    defaultPickerValue={moment(props.addedData.startDate)}
                                    onChange={getExchangeRate}
                                    style={{ width: '100%' }}
                                    nyTestId="date"
                                    // disabledDate={disabledExpenseDate}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.description')}
                                name="description"
                                tooltip={geti18nText('travelWarrantExpense.edit.description.tooltip')}
                            >
                                <Input.TextArea
                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                    onKeyDown={onKeyDownTextAreaCustom}
                                    onBlur={descriptionOnChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.amountInCurrency')}
                                name="amountInCurrency"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                tooltip={geti18nText('travelWarrantExpense.edit.amountInCurrency.tooltip')}
                            >
                                <NyInputNumber
                                    isDecimal={true}
                                    onChange={calculateAmount}
                                    style={{ width: '100%' }}
                                    nyTestId="amount-in-currency"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <CurrencySearch
                                initialValue={initialCurrency}
                                label={geti18nText('travelWarrantExpense.edit.isoCode')}
                                name="currency"
                                required={true}
                                readonly={!isAdmin}
                                onChange={getExchangeRate}
                                noClassName={true}
                                tooltip={geti18nText('travelWarrantExpense.edit.isoCode.tooltip')}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.exchangeRate')}
                                name="exchangeRate"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <NyInputNumber
                                    onChange={calculateAmount}
                                    isDecimal={true}
                                    decimalPlaces={6}
                                    style={{ width: '100%' }}
                                    nyTestId="exchange-rate"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.amount')}
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <NyInputNumber
                                    isDecimal={true}
                                    decimalPlaces={2}
                                    style={{ width: '100%' }}
                                    nyTestId="amount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <TravelExpensePaymentMethodSearch
                                required={true}
                                readonly={!TravelWarrantRights.canCreateTravelWarrantCodebooks()}
                                isAdmin={TravelWarrantRights.canCreateTravelWarrantCodebooks()}
                                onChange={travelExpensePaymentMethodOnChange}
                                setDefaultFilterValue={setDefaultFilterValuePaymentMethod}
                                tooltip={geti18nText('travelWarrantExpense.edit.travelExpensePaymentMethod.tooltip')}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ display: isAdmin ? 'block' : 'none' }}
                                name="invoiceNr"
                                label={geti18nText('travelWarrantExpense.edit.invoiceNr')}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelPaymentMethod.edit.bookExpense')}
                                name="bookExpense"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <NySearchField
                                    options={getEnumArray('BOOK_OPTIONS')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    style={{ width: '100%' }}
                                    className={''}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.reimbursed')}
                                name="reimbursed"
                                valuePropName="checked"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                label={geti18nText('travelWarrantWage.table.column.taxable')}
                                name="taxable"
                                valuePropName="checked"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <Checkbox
                                    checked={taxable}
                                    onChange={(val: any) => {
                                        setTaxable(val.target.checked);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.includeInJoppd')}
                                name="includeInJoppd"
                                valuePropName="checked"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.joppdTaxFreeCode')}
                                name="joppdTaxFreeCode"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                    setDefaultFilterValue={() => setDefaultFilterValue('15.1.')}
                                    map={{ id: 'id', label: 'code' }}
                                    searchBy="code"
                                    nyTestId="joppdTaxFreeCode"
                                    SearchPopupComponent={
                                        <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValue('15.1.')} />
                                    }
                                    itemName="code"
                                    addNewItem={true}
                                    order="code"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('travelWarrantExpense.edit.joppdPaymentCode')}
                                name="joppdPaymentCode"
                                style={{ display: isAdmin ? 'block' : 'none' }}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.JOPPD_CODE.SEARCH}
                                    setDefaultFilterValue={() => setDefaultFilterValue('16.1.')}
                                    map={{ id: 'id', label: 'code' }}
                                    searchBy="code"
                                    nyTestId="joppdPaymentCode"
                                    SearchPopupComponent={
                                        <JoppdCodeIndex defaultFilteredValue={() => setDefaultFilterValue('16.1.')} />
                                    }
                                    itemName="code"
                                    addNewItem={true}
                                    order="code"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Alert
                        message={geti18nText('travelWarrantWizard.table.column.files.deliver')}
                        type="warning"
                        showIcon
                        style={{ marginBottom: '5px' }}
                    />
                    {filesError() && (
                        <Alert
                            message={geti18nText('app.default.required.file')}
                            type="error"
                            showIcon
                            style={{ marginBottom: '5px' }}
                        />
                    )}
                    <NyFilesUpload
                        documentType={documentTypeEnum.TRAVEL_WARRANT_EXPENSE}
                        url={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST}
                        editUrl={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.EDIT}
                        defaultFilesFilter={setDefaultFilterValueFiles()}
                        ref={uploadFilesRef}
                        id={props && props.addedData && props.addedData.id && Number(props.addedData.id)}
                        refId={dataForm}
                        setCanContinue={setHasMandatoryFiles}
                        employee={employee}
                        employeeTravelWarrantId={employeeTravelWarrant?.id}
                        showDocumentTypeColumn={true}
                        maxHeight={'205px'}
                        isTravelWarrant={true}
                        moduleAccessRight={moduleAccessRight}
                        showPreview
                        setPreviewID={setPreviewID}
                        showPreviewFirstFile={props?.editProps?.showPreviewFirstFile}
                    />
                </Col>
                {previewID != null && (
                    <InModalFilePreview
                        previewID={previewID}
                        setPreviewID={setPreviewID}
                        setShowPreview={setShowPreview}
                    />
                )}
            </Row>
        </NyDataEdit>
    );
};

export default TravelWarrantExpenseEdit;
