import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NyInputNumber, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Row, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, formatJoppdCodeSearch, okNotification } from '../../../../utils/Utils';
import { CronInput } from './CronInput';

const { Panel } = Collapse;

const SystemSettingsTable = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();

    const [dataForm, setDataForm] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2', '3']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});
    const [currentData, setCurrentData] = useState<any>(undefined);
    const [cronErrors1, setCronErrors1] = useState(0);
    const [cronChanged1, setCronChanged1] = useState(false);
    const [cronErrors2, setCronErrors2] = useState(0);
    const [cronChanged2, setCronChanged2] = useState(false);
    const timeFormat = 'HH:mm';

    function getCronErrorArray(errors: number) {
        let array = [];
        for (let i = 0; i < 5; i++) {
            if (errors & (1 << i)) {
                array.push(geti18nText('app.setting.cron.info.message.' + i));
            }
        }
        return array;
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3']);
        }

        setCollapseAll(!collapseAll);
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['systemSettings'] = isChanged;
        setDirty(tmpDirty);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
        </div>
    );

    const setDefaultFilterValue = () => {
        return [
            { field: 'settingGroup', condition: 'in', value: ['POINT_SYNC_SETTINGS', 'SYSTEM_SETTINGS'].toString() },
        ];
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                }
            }
        });
    };

    useEffect(() => {
        if (currentData) {
            setDataForm([...dataForm, ...currentData]);
        }
    }, [currentData]);

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setCurrentData(dataForm);
        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 5) {
                if (element.value) {
                    newDataForm[element.settingKey] = moment(element.value, timeFormat);
                } else if (element.defaultValue) {
                    newDataForm[element.settingKey] = moment(element.defaultValue, timeFormat);
                }
            }
        }

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        params.push({ id: item.id, value: value });
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    return (
        <>
            <Form
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onValuesChange={(value: any) => {
                    checkIsDirty(value);
                }}
                form={form}
                onFinishFailed={({ errorFields }) => {
                    form.scrollToField(errorFields[0].name);
                }}
            >
                <Row gutter={24}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('app.setting.group.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS')}
                                key="2"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MAX_UPLOAD_FILE_SIZE'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MAX_UPLOAD_FILE_SIZE'
                                            )}
                                        >
                                            <NyInputNumber isDecimal={false} style={{ width: '100%' }} min={0} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'TRAVEL_WARRANT_SYNC_TYPE'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.TRAVEL_WARRANT_SYNC_TYPE'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('app.setting.group.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.meals')}
                                key="1"
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_PRICE_LIST'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_PRICE_LIST'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_CASH_REGISTER'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_CASH_REGISTER'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_PAYMENT_TYPE'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_PAYMENT_TYPE'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_REQUEST_RETRY_NUMBER'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_REQUEST_RETRY_NUMBER'
                                            )}
                                        >
                                            <NyInputNumber
                                                isDecimal={false}
                                                style={{ width: '100%' }}
                                                min={0}
                                                max={100}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_ASSORTMENT'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_ASSORTMENT'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_GROUPS'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_GROUPS'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_CANCEL_RESERVATION'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_CANCEL_RESERVATION'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_CHANGE_RESERVATION'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_CHANGE_RESERVATION'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_LOAD_BILL'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_LOAD_BILL'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_FUNDS_RESERVATION'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_FUNDS_RESERVATION'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_CHECK_STATUS'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_CHECK_STATUS'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_TYPE'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_TYPE'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_ENDPOINT_AUTH_TOKEN'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_ENDPOINT_AUTH_TOKEN'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'MEALS_SYNC_TIME'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.SYSTEM_SETTINGS.MEALS_SYNC_TIME'
                                            )}
                                        >
                                            <NyDatePicker
                                                defaultPickerValue={null}
                                                mode="time"
                                                format={timeFormat}
                                                style={{ width: '100%' }}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('app.setting.group.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS')}
                                key="3"
                            >
                                <Form.Item
                                    name={'COMPANY_CODE'}
                                    label={geti18nText(
                                        'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.COMPANY_CODE'
                                    )}
                                >
                                    <Input />
                                </Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_TW_PAYMENT_STATUS_CRON'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_TW_PAYMENT_STATUS_CRON'
                                            )}
                                            rules={[
                                                {
                                                    validator: (rule: any, value: any, callback: any) => {
                                                        if (cronErrors1) {
                                                            return callback(
                                                                geti18nText('app.setting.cron.error.message', [
                                                                    getCronErrorArray(cronErrors1).join(', '),
                                                                ])
                                                            );
                                                        }
                                                        return callback();
                                                    },
                                                },
                                            ]}
                                        >
                                            <CronInput
                                                cronErrors={cronErrors1}
                                                setCronErrors={setCronErrors1}
                                                setCronChanged={setCronChanged1}
                                                cronChanged={cronChanged1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_ADVANCE_PAYMENT_STATUS_CRON'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_ADVANCE_PAYMENT_STATUS_CRON'
                                            )}
                                            rules={[
                                                {
                                                    validator: (rule: any, value: any, callback: any) => {
                                                        if (cronErrors2) {
                                                            return callback(
                                                                geti18nText('app.setting.cron.error.message', [
                                                                    getCronErrorArray(cronErrors2).join(', '),
                                                                ])
                                                            );
                                                        }
                                                        return callback();
                                                    },
                                                },
                                            ]}
                                        >
                                            <CronInput
                                                cronErrors={cronErrors2}
                                                setCronErrors={setCronErrors2}
                                                setCronChanged={setCronChanged2}
                                                cronChanged={cronChanged2}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_USERNAME'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_USERNAME'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_PASSWORD'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_PASSWORD'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_ENDPOINT_AUTH_TOKEN'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_ENDPOINT_AUTH_TOKEN'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_ENDPOINT_BOOK_FK_DOCUMENT'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_ENDPOINT_BOOK_FK_DOCUMENT'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_TRAVEL_WARRANT_DOCUMENT_TYPE'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_TRAVEL_WARRANT_DOCUMENT_TYPE'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_REQUEST_RETRY_NUMBER'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_REQUEST_RETRY_NUMBER'
                                            )}
                                        >
                                            <NyInputNumber isDecimal={false} style={{ width: '100%' }} min={0} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_TRAVEL_WARRANT_WORK_ORDER'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_TRAVEL_WARRANT_WORK_ORDER'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={'POINT_SYNC_TRAVEL_WARRANT_CLIENT'}
                                            label={geti18nText(
                                                'app.setting.name.SYSTEM_SETTINGS.POINT_SYNC_SETTINGS.POINT_SYNC_TRAVEL_WARRANT_CLIENT'
                                            )}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row className={'buttons-sticky'}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        {getCustomFooterContent}
                        {canCreate() && (
                            <Button
                                style={{ marginRight: '1em' }}
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={(e) => save(e)}
                            >
                                {geti18nText('app.default.button.save')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default SystemSettingsTable;
