import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyModalConfirm,
    NyRequestResolver,
    NySpinner,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Popover, Row, Tabs, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import RowAction from '../../../../components/row-action';
import StatusHistoryIndex from '../../../../components/status-history/StatusHistory';
import TravelWarrantExpensesIndex from '../../../../components/travel-warrant-expenses';
import TravelWarrantPaymentIndex from '../../../../components/travel-warrant-payment';
import TravelWarrantPlanSteps from '../../../../components/travel-warrant-plan-steps';
import TravelWarrantRecapitulationIndex from '../../../../components/travel-warrant-recapitulation';
import TravelWarrantStartEndIndex from '../../../../components/travel-warrant-start-end';
import TravelWarrantVehicleIndex from '../../../../components/travel-warrant-vehicle';
import TravelWarrantWageIndex from '../../../../components/travel-warrant-wage';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useEnum from '../../../../hooks/useEnum';
import { RootState } from '../../../../rootReducer';
import { employeeIdSet } from '../../../../slices/selectedItemSlice';
// import { addTab /* , removeTab, setIsDirtyTab, updateTabTitle */ } from '../../../../slices/tabsSlice';
import TravelWarrantHistoryIndex from '../../../../components/travel-warrant-history';
import TWTypeComponent from '../../../../components/travel-warrant-type/TravelWarrantTypeComponent';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { updateHistoryI18n } from '../../../../slices/historySlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorCustomNotification,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumFormat,
    getFileList,
    getSearchFormat,
    getTravelWarrantStatusIcon,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    userIsNotificationRecipient,
    warningNotification,
} from '../../../../utils/Utils';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import edit from '../../../administration/views/currency/edit';
import GeneralEditData from './GeneralEditData';
import OrganizationTravelWarrantModal from './modal/OrganizationTravelWarrantModal';
import ReportApproveTravelWarrantModal from './modal/ReportApproveTravelWarrantModal';
import SendTripModal from './modal/SendTripModal';
import TravelWarrantActionModal from './modal/TravelWarrantActionModal';
import TravelWarrantUnpaidModal from './modal/TravelWarrantUnpaidModal';
import TravelWarrantPersonalWizardEdit from './wizard/wizard-edit';
import TravelWarrantPersonalWizardNew from './wizard/wizard-new';

const { TabPane } = Tabs;
const { confirm } = Modal;

const TravelWarrantEdit = (props: any) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<any>();
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const { employee } = useSelector((state: RootState) => state.employee);
    //enums
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const type = useEnum('MODULE_ACCESS_RIGHT');
    //app properties
    const travelWarrantStartingPoint = useApplicationSetting('TRAVEL_WARRANT_DEFAULT_STARTING_POINT');
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const paymentDeclaredInCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY');
    const textTemplate = useApplicationSetting('TRAVEL_WARRANT_REPORT_TEXT_TEMPLATE');
    const mandatoryPurposeAttachment = useApplicationSetting('TRAVEL_WARRANT_MANDATORY_PURPOSE_AND_ATTACHMENT');
    const accountingCostCenterSetting = useApplicationSetting('ACCOUNTING_COST_CENTER');
    const accountingCostObjectSetting = useApplicationSetting('ACCOUNTING_COST_OBJECT');
    const vehicle2EnableSetting = useApplicationSetting('TRAVEL_WARRANT_VEHICLE_2_ENABLED');
    const enableTravelWarrantChangeOnStatusPaid: any = useApplicationSetting(
        'TRAVEL_WARRANT_ENABLE_CHANGE_ON_STATUS_PAID'
    );
    //travelWarrant
    let dataFormCopy: any = null;
    const focusInputReport = useRef<any>(null);
    const [formReport] = Form.useForm();
    const [ordinal, setOrdinal] = useState<any>(null);
    const [travelWarrant, setTravelWarrant] = useState<any>(undefined);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [form] = Form.useForm();
    const [reportForm] = Form.useForm();
    const [formMap] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [disabledTable, setDisabledTable] = useState(false);
    const [disabledTabs, setDisabledTabs] = useState(true);
    const [refresh, setRefresh] = useState<any>(0);
    const [dataForm, setDataForm] = useState<any>(null);
    const [status, setStatus] = useState<any>(-1);
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(travelWarrantStatusEnum.DRAFT);
    const [showAccountingCostCenter, setShowAccountingCostCenter] = useState(true);
    const [accountingCostCenterRequired, setAccountingCostCenterRequired] = useState(true);
    const [showAccountingCostObject, setShowAccountingCostObject] = useState(true);
    const [accountingCostObjectRequired, setAccountingCostObjectRequired] = useState(true);
    const [accountingCostCenter, setAccountingCostCenter] = useState(null);
    const [reportApprovalVisible, setReportApprovalVisible] = useState<any>(false);
    const [employeeId, setEmployeeId] = useState(null);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [checkedData, setCheckedData] = useState<any>([]);
    const uploadFilesRef = useRef<any>(null);
    const [isPersonalVehicle, setIsPersonalVehicle] = useState(false);
    const [isPersonalVehicle2, setIsPersonalVehicle2] = useState(false);
    const [isPersonalOrOfficial, setIsPersonalOrOfficial] = useState(false);
    const [showAnotherVehicle, setShowAnotherVehicle] = useState(false);
    const [travelVehicle, setTravelVehicle] = useState(null);
    const [mandatoryFile, setMandatoryFile] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [travelWarrantPlans, setTravelWarrantPlans] = useState<any>([]);
    const [title, setTitle] = useState('');
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const [wizard, setWizard] = useState<boolean>(false);
    const [wizardNew, setWizardNew] = useState<boolean>(false);
    const [isPlane, setIsPlane] = useState(false);
    const [destination, setDestination] = useState<any>(undefined);
    const [destinationJson, setDestinationJson] = useState(null);
    const [showTime, setShowTime] = useState(true);
    const [sendModalVisible, setSendModalVisible] = useState<boolean>(false);
    const [init, setInit] = useState<boolean>(false);
    const [advance, setAdvance] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<boolean>(false);
    const [vehicle2, setVehicle2] = useState<boolean>(false);
    const [travelWarrantDate, setTravelWarrantDate] = useState('');
    const [canApprove, setCanApprove] = useState(false);
    const [canReject, setCanReject] = useState(false);
    const [activeKey, setActiveKey] = useState('general');
    const [refreshHistory, setRefreshHistory] = useState(0);
    const [rerenderKey, setRerenderKey] = useState<any>(0);
    const [historyLoading, setHistoryLoading] = useState(false);
    const [visibleButton, setVisibleButton] = useState<boolean>(false);
    const [dataAdditional, setDataAdditional] = useState<any>([]);
    const [needTransport, setNeedTransport] = useState<any>(undefined);
    //modal
    const [approveReportModal, setApproveReportModal] = useState<any>(false);
    const [organizeModal, setOrganizeModal] = useState<any>(false);
    const [defaultCurrency, setDefaultCurrency] = useState<any>(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    const [travelWarrantConfirmationModal, setTravelWarrantConfirmationModal] = useState(false);
    //map
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [initWaypoints, setInitWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [initialWaypoints, setInitialWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const [destinationData, setDestinationData] = useState<any>([]);
    //additional data
    const [loadingAdditional, setLoadingAdditional] = useState<boolean>(false);
    const [destinationsAdditional, setDestinationsAdditional] = useState<any>([]);
    const [destinationAdditional, setDestinationAdditional] = useState<any>(null);
    const [itemAdditional, setItemAdditional] = useState<any>(null);
    const [formAdditional] = Form.useForm();
    //roles
    const isAdmin = TravelWarrantRights.canCreateTravelWarrantAdmin();
    const isUser = TravelWarrantRights.canCreateTravelWarrantPersonal();
    const canView = TravelWarrantRights.canViewTravelWarrantAdmin();
    const canEdit = TravelWarrantRights.canCreateTravelWarrantAdmin();
    const canEditPersonal =
        isUser && userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_COMPENSATION_PAYMENT);
    const canCreateInit = isAdmin || isUser;
    const canSaveAdmin = isAdmin || canEditPersonal;
    const [payoutByAnotherOrganization, setPayoutByAnotherOrganization] = useState<any>(false);
    const defaultCurrencyISO = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [includeEducationDate, setIncludeEducationDate] = useState<boolean>(false);
    const [travelWarrantType, setTravelWarrantType] = useState<any>(null);
    const [travelWarrantTypes, setTravelWarrantTypes] = useState<any>([]);
    const [defaultEndDate, setDefaultEndDate] = useState<any>(moment());
    const [rerenderKey2, setRerenderKey2] = useState<any>(0);
    const [wageAmountReceived, setWageAmountReceived] = useState<any>(0);
    const showCheckboxesDestinationName = true;

    const isDisabledPaidWarrant = () => {
        return !AdministrationRights.isAdmin() && status == travelWarrantStatusEnum.PAID;
    };
    const canEditStatusAndCalculationDatePaid = () => {
        return (
            AdministrationRights.isAdmin() ||
            (status == travelWarrantStatusEnum.PAID && enableTravelWarrantChangeOnStatusPaid == 1 && canSaveAdmin)
        );
    };

    const showIsDirtyWage = () => {
        return travelWarrantStatus !== travelWarrantStatusEnum.PAID && canSaveAdmin;
    };

    const disabledendEduDate = (current: any) => {
        if (form.getFieldValue('educationStartDate')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('educationStartDate')).endOf('day');
        }
    };

    const setDefaultFilterValue = () => {
        if (employeeId !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const setDefaultFilterValuePersonalOrOfficial = () => {
        if (employeeId !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId },
                { field: 'vehicleType', condition: 'in', value: '1,2' },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'vehicleType', condition: 'in', value: '1,2' },
            ];
        }
    };

    const getModuleAccessRights2 = () => {
        const url = CONSTANTS_REQ.EMPLOYEE.ACCESS_RIGHT_FOR;
        const urltype = url.replace('{type}', type.TRAVEL_WARRANT);
        const urlreplaced = urltype.replace('{employeeId}', employeeId);

        employeeId != null &&
            employeeId != undefined &&
            NyRequestResolver.requestGet(urlreplaced).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    setVisibleButton(true);
                } else {
                    setVisibleButton(false);
                }
            });
    };

    const setDefaultFilterValueFiles = (travelWarrantId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
        ];
    };

    const canCompleteAction = () => {
        return (
            dataForm &&
            (employee?.id == employeeId || isAdmin || visibleButton) &&
            Object.values([
                travelWarrantStatusEnum.APPROVED,
                travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION,
                travelWarrantStatusEnum.ORGANIZED,
                travelWarrantStatusEnum.REPORT_FIX_NEEDED,
            ]).includes(status)
        );
    };

    const canOpenNewWizard = () => {
        return (
            Object.values([travelWarrantStatusEnum.DRAFT, travelWarrantStatusEnum.REJECTED]).includes(status) &&
            dataForm == undefined
        );
    };

    const canOpenNewWizard2 = () => {
        return (
            Object.values([travelWarrantStatusEnum.DRAFT, travelWarrantStatusEnum.REJECTED]).includes(status) &&
            dataForm !== undefined
        );
    };

    const canReportAction = () => {
        return (
            ((isAdmin ||
                (canCreateInit && userIsNotificationRecipient(employee, notificationType.TRAVEL_WARRANT_APPROVAL))) &&
                status == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL) ||
            (canSaveAdmin &&
                Object.values([
                    travelWarrantStatusEnum.REPORT_APPROVED,
                    travelWarrantStatusEnum.NOT_CALCULATED,
                    status == travelWarrantStatusEnum.CALCULATED,
                ]).includes(status))
        );
    };

    const canCalculateAction = () => {
        return (
            canSaveAdmin &&
            (status == travelWarrantStatusEnum.REPORT_APPROVED ||
                status == travelWarrantStatusEnum.NOT_CALCULATED ||
                status == travelWarrantStatusEnum.CALCULATED)
        );
    };

    const canPointBookAction = () => {
        return TravelWarrantRights.canPointBookAdmin() && status && status == travelWarrantStatusEnum.CALCULATED;
    };

    const statusForFiles = () => {
        return !Object.values([
            travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
            travelWarrantStatusEnum.REPORT_APPROVED,
            travelWarrantStatusEnum.CANCELLED,
            travelWarrantStatusEnum.PAID,
        ]).includes(travelWarrantStatus);
    };

    const warrantDateOnChange = (value: any) => {
        if (value) getExchangeRate();
    };

    useEffect(() => {
        getAppSettings();
        warrantDateOnChange(moment());
        fetchTypes();
    }, []);

    useEffect(() => {
        getModuleAccessRights2();
    }, [employeeId]);

    useEffect(() => {
        if (defaultCurrency && defaultCurrency.id && id == 'create') {
            form.setFieldsValue({
                advancePaymentCurrency: setSearchFormat(defaultCurrency, 'isoCode'),
            });
        } else if (defaultCurrency && defaultCurrency.code && id == 'create') {
            form.setFieldsValue({
                advancePaymentCurrency: setSearchFormat({ id: 0, text: defaultCurrency.code }),
            });
        }
    }, [defaultCurrency]);

    useEffect(() => {
        if (init) {
            if (form.getFieldValue('destinationJson') != undefined) {
                setDestinationData(form.getFieldValue('destinationJson'));
                setDestinationJson(JSON.parse(form.getFieldValue('destinationJson')));
                setDestinationValues(JSON.parse(form.getFieldValue('destinationJson')));
                setInitialWaypointOnSetValues(JSON.parse(form.getFieldValue('destinationJson')));
            }
        }
    }, [init]);

    useEffect(() => {
        if (id && id > 0) {
            setValuesFiles(id);
        } else {
            getDefaultStartingPoint();
        }
    }, [id]);

    useEffect(() => {
        if (employee && !dataForm) {
            setEmployeeValues(employee);
        }
    }, [employee]);

    useEffect(() => {
        if (
            !isAdmin &&
            Object.values([
                travelWarrantStatusEnum.APPROVED,
                travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION,
                status == travelWarrantStatusEnum.ORGANIZED,
                status == travelWarrantStatusEnum.REPORT_FIX_NEEDED,
            ]).includes(status) &&
            employee?.id == employeeId
        ) {
            setWizard(true);
        }
        setDisabledTabs(
            (status &&
                Object.values([
                    travelWarrantStatusEnum.WAITING_FOR_APPROVAL,
                    travelWarrantStatusEnum.REJECTED,
                    travelWarrantStatusEnum.APPROVED,
                    travelWarrantStatusEnum.DRAFT,
                    travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION,
                    travelWarrantStatusEnum.ORGANIZED,
                ]).includes(status)) ||
                !status
        );

        setDisabledTable(
            (!isAdmin &&
                status &&
                !Object.values([travelWarrantStatusEnum.REJECTED, travelWarrantStatusEnum.DRAFT]).includes(status)) ||
                isDisabledPaidWarrant()
        );
    }, [status]);

    useEffect(() => {
        if (title !== '') {
            dispatch(
                updateHistoryI18n({
                    path: location.pathname,
                    args: [geti18nText('travelWarrant.edit.title.short') + ' - ' + title],
                })
            );
        }
    }, [title]);

    useEffect(() => {
        if (dataForm && dataForm > 0) {
            setValuesFiles(dataForm);
        }
    }, [refreshFiles]);

    useEffect(() => {
        if (waypoints.length > 0) {
            const newData = [...dataAdditional];
            const dataAdditionalInfo = waypoints
                .filter(
                    (waypoint: any) =>
                        waypoint?.id > 0 &&
                        waypoint?.shortName != '' &&
                        waypoint.placeId != undefined &&
                        waypoint?.id < 9000
                )
                .map((item: any) => {
                    const destinationExists: any = newData.find((dest: any) => dest?.destination == item?.shortName);
                    if (destinationExists && destinationExists?.destination != '') {
                        return destinationExists;
                    } else if (item?.shortName != '') {
                        return { destination: item?.shortName };
                    }
                });
            setDataAdditional(dataAdditionalInfo);
            getDataAdditional(dataAdditionalInfo);
        }
    }, [waypoints]);

    const getDataAdditional = (data: any) => {
        setLoadingAdditional(true);
        if (dataAdditional?.length > 0) {
            const newData: any = data.map((dataItem: any) => dataItem.destination);
            setDestinationsAdditional(newData);
            onChangeDestinationAdditional(newData[0], false);
        } else {
            setDestinationsAdditional([]);
            onChangeDestinationAdditional(null, false);
        }
    };

    const onChangeDestinationAdditional = (value: any, saveValues: any = true) => {
        setLoadingAdditional(true);
        if (itemAdditional?.destination && saveValues) {
            saveFormValuesAdditional(value);
        } else {
            setItemValueAdditional(value);
        }
        setLoadingAdditional(false);
    };

    const setItemValueAdditional = (value: any) => {
        if (value) {
            setDestinationAdditional(value);
            const index = dataAdditional.findIndex((dat: any) => dat?.destination === value);
            setItemAdditional(index > -1 && dataAdditional[index] ? dataAdditional[index] : null);
        } else {
            setDestinationAdditional(null);
            setItemAdditional(null);
        }
    };

    const saveFormValuesAdditional = (value: any) => {
        const values: any = normalizeValuesAdditional(formAdditional.getFieldsValue());
        formAdditional.resetFields();

        const newData = [...dataAdditional];
        const index = newData.findIndex((dat: any) => dat?.destination === itemAdditional?.destination);
        if (index > -1) {
            newData.splice(index, 1, { ...itemAdditional, ...values });
            setDataAdditional(newData);
            if (value) {
                setItemValueAdditional(value);
            }
        }
    };

    const normalizeValuesAdditional = (values: any) => {
        if (values.needAccommodation) {
            values.needAccommodation = getEnumFormat(values.needAccommodation);
        }

        if (values.needTransport) {
            values.needTransport = getEnumFormat(values.needTransport);
        }

        if (values.baggageType) {
            values.baggageType = getEnumFormat(values.baggageType);
        }

        if (values.arrivalDate) {
            values.arrivalDate = getDateFormat(moment(values.arrivalDate).format('yyyy-MM-DD HH:mm'));
        }

        if (values.returnDate) {
            values.returnDate = getDateFormat(moment(values.returnDate).format('yyyy-MM-DD HH:mm'));
        }

        if (values.arrivalDayPart) {
            values.arrivalDayPart = getEnumFormat(values.arrivalDayPart);
        }

        if (values.returnDayPart) {
            values.returnDayPart = getEnumFormat(values.returnDayPart);
        }
        if (values.travelVehicle) {
            if (values.vehicleType) {
                values.travelVehicle.vehicleType = getEnumFormat(values.vehicleType);
            }
        }
        values.showTime = values.showTime ? 1 : 0;
        return values;
    };

    const getAdditionalValues = (reset: boolean = false) => {
        if (itemAdditional?.destination) {
            const values: any = normalizeValuesAdditional(formAdditional.getFieldsValue());
            if (reset) {
                formAdditional.resetFields();
            }

            const newData = [...dataAdditional];
            const index = newData.findIndex((dat: any) => dat?.destination === itemAdditional?.destination);
            if (index > -1) {
                newData.splice(index, 1, { ...itemAdditional, ...values });
                return newData;
            }
        } else {
            return [];
        }
    };

    const formatCurrencyIsoCodeSearch = async function (code: any) {
        let temp;
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.ISO_CODE, { code }).then((result: any) => {
            if (result.data.id) {
                temp = { id: result.data['id'], isoCode: result.data['isoCode'] };
            } else {
                temp = { id: 0, isoCode: code };
            }
        });

        return temp;
    };

    const getDefaultStartingPoint = async () => {
        let waypointsTemp: any = [];
        if (dataForm) {
            waypointsTemp = initWaypoints;
        } else {
            try {
                waypointsTemp = [JSON.parse(travelWarrantStartingPoint)];
            } catch {}
            if (waypointsTemp.length === 0) {
                waypointsTemp.push({ id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' });
            }
            waypointsTemp.push({ id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' });
            let destinationName = '';
            const newData = { ...dataFormCopy };
            newData.startingPoint = destinationName;
            newData.destination = destinationName;
            newData.destinationInput = destinationName;
            setDestination(destinationName);
            form.setFieldsValue({ destination: destinationName });
            setValues(newData);
        }
        setDefaultWaypoints(waypointsTemp.slice());
        setWaypoints(JSON.parse(JSON.stringify(waypointsTemp)));
    };

    const setStartDate = (value: any) => {
        form.setFieldsValue({ startDate: value });
    };

    const setEndDate = (value: any) => {
        form.setFieldsValue({ endDate: value });
    };

    const getAppSettings = async () => {
        if (!reportForm.getFieldValue('report')) {
            reportForm.setFieldsValue({
                report: textTemplate,
            });
        }
        setDefaultCurrency(await formatCurrencyIsoCodeSearch(defaultCurrencyIsoCode));
        setMandatoryFile(mandatoryPurposeAttachment == 1);

        setShowAccountingCostCenter(accountingCostCenterSetting === '1');
        setAccountingCostCenterRequired(accountingCostCenterSetting === '1');
        setAccountingCostObjectRequired(accountingCostCenterSetting !== '1');

        setShowAccountingCostObject(accountingCostObjectSetting === '1');
        if (accountingCostObjectSetting === '1' && !accountingCostCenterRequired && accountingCostCenter != null) {
            setAccountingCostCenterRequired(false);
            setAccountingCostObjectRequired(true);
        } else {
            setAccountingCostCenterRequired(true);
            setAccountingCostObjectRequired(false);
        }
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3', '4']);
        }
        setCollapseAll(!collapseAll);
    };

    const setDestinationValues = (destinationJson: any) => {
        let waypointsList: any = [];
        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        let initList = [];
        if (lastItem?.id == 9999) {
            const firstItem = waypointsList[0];
            setReturnRoute(true);
            const returnList = waypointsList.filter((x: any) => x.id >= 9000 && x.id != 9999);
            initList = JSON.parse(JSON.stringify(returnList));
            returnIntermediateWaypointsInit.current = initList;
            let list = [firstItem].concat(returnList).concat(lastItem);

            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);

            setReturnWaypoints(JSON.parse(JSON.stringify(list)));
        }
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);
        // setTravelWarrantPlans(waypointsList);
    };

    const setInitialWaypointOnSetValues = (destinationJson: any) => {
        let waypointsList: any = [];
        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        if (lastItem?.id == 9999) {
            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
        }
        setInitialWaypoints(waypointsList);
    };

    function resetDataForm() {
        setValues(dataFormCopy);
    }

    async function setValues(dataForm: any) {
        setTravelWarrant({ status: dataForm?.status?.id, ...dataForm });

        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        dataForm.advanceApproved = false;
        dataForm.vehicleApproved = false;
        dataForm.vehicleApproved2 = false;

        dataFormCopy = _.cloneDeep(dataForm);

        if (dataForm.ordinal) {
            setOrdinal(dataForm.ordinal);
            if (dataForm.warrantDate) {
                setTitle(dataForm.ordinal + '/' + moment(dataForm.warrantDate).format('YYYY'));
            }
        }

        if (dataForm.startDate) {
            dataForm.startDate = setDateFormat(dataForm.startDate, 'yyyy-MM-DD HH:mm');
        }

        if (dataForm.endDate) {
            dataForm.endDate = setDateFormat(dataForm.endDate, 'yyyy-MM-DD HH:mm');
        }

        if (dataForm.warrantDate) {
            setTravelWarrantDate(moment(dataForm.warrantDate).format('YYYY'));
            dataForm.warrantDate = setDateFormat(dataForm.warrantDate, 'yyyy-MM-DD');
        }

        if (dataForm.calculationDate) {
            dataForm.calculationDate = setDateFormat(dataForm.calculationDate, 'yyyy-MM-DD');
        }

        if (dataForm.report) {
            formReport.setFieldsValue({ report: dataForm.report });
        }

        let isPersonalOrOfficial = false;

        if (dataForm.travelVehicle) {
            if (dataForm.travelVehicle.vehicleType == 8) {
                setNeedTransport(setEnumFormat('TRAVEL_NEED_TRANSPORT', 3));
            }
            setIsPersonalVehicle(dataForm.travelVehicle.vehicleType && dataForm.travelVehicle.vehicleType === 1);
            setIsPlane(
                dataForm.travelVehicle.vehicleType &&
                    (dataForm.travelVehicle.vehicleType === 3 ||
                        dataForm.travelVehicle.vehicleType === 4 ||
                        dataForm.travelVehicle.vehicleType === 6)
            );
            if (
                dataForm.travelVehicle.vehicleType &&
                (dataForm.travelVehicle.vehicleType === 1 || dataForm.travelVehicle.vehicleType === 2)
            ) {
                isPersonalOrOfficial = true;
            }
            setIsPersonalOrOfficial(isPersonalOrOfficial);
            dataForm.travelVehicle = setSearchFormat(dataForm.travelVehicle, 'name', 'name');
            setTravelVehicle(dataForm.travelVehicle);
            dataForm.vehicleApproved = true;
            setVehicle(false);
        } else {
            dataForm.vehicleApproved = false;
            setVehicle(true);
            setIsPlane(false);
        }
        if (dataForm.travelVehicle2) {
            setIsPersonalVehicle2(dataForm.travelVehicle2.vehicleType && dataForm.travelVehicle2.vehicleType === 1);
            dataForm.travelVehicle2 = setSearchFormat(dataForm.travelVehicle2, 'name', 'name');
            dataForm.vehicleApproved2 = true;
            setVehicle2(false);
            if (!isPersonalOrOfficial) {
                setShowAnotherVehicle(true);
            }
        } else {
            dataForm.vehicleApproved2 = false;
            setVehicle2(true);
        }
        if (dataForm.employee) {
            setEmployeeId(dataForm.employee.id);
            dispatch(employeeIdSet({ employeeId: dataForm.employee.id }));
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }

        if (dataForm.advancePaymentCurrency) {
            dataForm.advancePaymentCurrency = setSearchFormat(dataForm.advancePaymentCurrency, 'isoCode');
        }

        dataForm.advanceApproved = dataForm.advancePaymentInCurrency ? true : false;

        setAdvance(dataForm.advancePaymentInCurrency ? false : true);
        setTravelWarrantStatus(dataForm.status ? dataForm.status : travelWarrantStatusEnum.DRAFT);
        setStatus(dataForm.status ? dataForm.status : travelWarrantStatusEnum.DRAFT);

        dataForm.status = setEnumFormat('TRAVEL_WARRANT_STATUS', dataForm.status);

        if (dataForm.advanceApproved) {
            dataForm.advanceApproved = true;
        } else {
            dataForm.advanceApproved = false;
        }

        if (dataForm.vehicleApproved) {
            dataForm.vehicleApproved = true;
        } else {
            dataForm.vehicleApproved = false;
        }

        if (dataForm.vehicleApproved2) {
            dataForm.vehicleApproved2 = true;
        } else {
            dataForm.vehicleApproved2 = false;
        }
        if (dataForm.destinationJson) {
            setInitialWaypointOnSetValues(JSON.parse(dataForm.destinationJson));
        }

        if (dataForm.destination) {
            formMap.setFieldsValue({ name: dataForm.destination });
            dataForm.name = dataForm.destination;
        }

        if (dataForm.startingPoint) {
            formMap.setFieldsValue({ name: dataForm.startingPoint });
            dataForm.name = dataForm.startingPoint;
        }

        if (dataForm.travelWarrantPlans) {
            setTravelWarrantPlans(dataForm.travelWarrantPlans.sort(compare));
        }

        if (dataForm.accountingCostCenter) {
            setAccountingCostCenter(dataForm.accountingCostCenter);
            dataForm.accountingCostCenter = setSearchFormat(dataForm.accountingCostCenter, 'name', 'name', 'code');
        }

        if (dataForm.accountingCostObject) {
            dataForm.accountingCostObject = setSearchFormat(dataForm.accountingCostObject, 'name', 'name', 'code');
        }

        if (dataForm.travelWarrantAdditionalInfoList) {
            setDataAdditional(dataForm.travelWarrantAdditionalInfoList);
        }

        if (dataForm.travelEventNumber) {
            dataForm.travelEventNumber = setSearchFormat(dataForm.travelEventNumber, 'name', 'name', 'code');
        }

        if (dataForm.travelWarrantType) {
            setTravelWarrantType(dataForm.travelWarrantType);
            setIncludeEducationDate(dataForm.travelWarrantType.includeEducationDate);
            setPayoutByAnotherOrganization(dataForm.travelWarrantType.payoutByAnotherOrganization);
        }

        if (dataForm.educationStartDate) {
            dataForm.educationStartDate = setDateFormat(dataForm.educationStartDate);
        }
        if (dataForm.educationEndDate) {
            dataForm.educationEndDate = setDateFormat(dataForm.educationEndDate);
        }

        if (dataForm.wageAmountReceived) {
            setWageAmountReceived(dataForm.wageAmountReceived);
        }
        setReturnRoute(dataForm.returnRoute);
        setDestination(dataForm.destination);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);

        setInit(true);
    }

    function compare(a: any, b: any) {
        if (a.sort < b.sort) {
            return -1;
        }
        if (a.sort > b.sort) {
            return 1;
        }
        return 0;
    }

    const setEmployeeValues = (employeeData: any) => {
        setEmployeeId(employeeData?.id);
        let formUser: any = {};
        formUser.employee = {};
        formUser.employee = getEmployeeSelectOption(employeeData);
        if (employeeData && employeeData.vocation && employeeData.vocation.name) {
            formUser.employeeVocation = employeeData.vocation.name;
        }
        if (employeeData && employeeData.businessUnit && employee.businessUnit.accountingCostCenter) {
            formUser.accountingCostCenter = setSearchFormat(
                employeeData.businessUnit.accountingCostCenter,
                'name',
                'name',
                'code'
            );
            setAccountingCostCenter(employeeData.businessUnit.accountingCostCenter);
        }
        form.setFieldsValue(formUser);
    };

    const onTravelVehicleChange = (value: any) => {
        if (value && value.id > -1) {
            form.setFieldsValue({ vehicleApproved: true });
            setTravelVehicle(value);
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_VEHICLE.EDIT + '/' + value.id).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    if (result.data) {
                        if (result.data.vehicleType == 8) {
                            setNeedTransport(setEnumFormat('TRAVEL_NEED_TRANSPORT', 3));
                        }
                        setIsPersonalVehicle(result.data.vehicleType && result.data.vehicleType === 1);
                        setIsPlane(
                            result.data.vehicleType &&
                                (result.data.vehicleType === 3 ||
                                    result.data.vehicleType === 4 ||
                                    result.data.vehicleType === 6)
                        );
                        if (
                            result.data.vehicleType &&
                            (result.data.vehicleType === 1 || result.data.vehicleType === 2)
                        ) {
                            setIsPersonalOrOfficial(true);
                        } else {
                            setIsPersonalOrOfficial(false);
                            setShowAnotherVehicle(false);
                            form.setFieldsValue({ travelVehicle2: undefined });
                        }
                        result.data = setSearchFormat(result.data, 'name', 'name');
                        setTravelVehicle(result.data);
                        form.setFieldsValue({ travelVehicle: result.data });
                    }
                }
            });
        } else {
            setTravelVehicle(null);
            setIsPersonalVehicle(false);
            setIsPersonalOrOfficial(false);
            setShowAnotherVehicle(false);
            form.setFieldsValue({ vehicleApproved: false });
            form.setFieldsValue({ travelVehicle2: undefined });
        }
    };

    const onTravelVehicle2Change = (value: any) => {
        if (value && value.id > -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_VEHICLE.EDIT + '/' + value.id).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    if (result.data) {
                        setIsPersonalVehicle2(result.data.vehicleType && result.data.vehicleType === 1);
                        result.data = setSearchFormat(result.data, 'name', 'name');
                        form.setFieldsValue({ travelVehicle2: result.data });
                    }
                }
            });
        } else {
            setIsPersonalVehicle2(false);
        }
    };

    const onStartDateChange = (value: any) => {
        if (form.getFieldValue('endDate')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('endDate').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
        setRerenderKey((prevKey: any) => prevKey + 1);
        form.setFieldsValue({ endDate: value });
    };

    const onEndDateChange = (value: any) => {
        if (form.getFieldValue('startDate')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('startDate').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('endDate')) {
            return current > moment(form.getFieldValue('endDate')).endOf('day');
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('startDate')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('startDate')).endOf('day');
        }
    };

    const currencyOnChange = (value: any) => {
        if (value && value.id > 0) {
            getExchangeRate();
        }
    };

    const getExchangeRate = () => {
        let date = form.getFieldValue(['warrantDate']);
        let currency = form.getFieldValue(['advancePaymentCurrency']);

        if (date && currency) {
            var parms: any = { currencyId: currency.id, date: getDateFormat(date) };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EXCHANGE_RATE.GET_EXCHANGE_RATE, parms).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    if (response.data && response.data.middleRate) {
                        form.setFieldsValue({ advancePaymentExchangeRate: response.data.middleRate });
                        calculateTotalAdvancePayment();
                    }
                } else {
                    form.setFieldsValue({ advancePaymentExchangeRate: 0 });
                    warningNotification(geti18nText('exchangeRate.not.found'));
                }
            });
        }
    };

    const calculateTotalAdvancePayment = () => {
        let totalAdvancePayment = 0;
        let advancePaymentExchangeRate = form.getFieldValue(['advancePaymentExchangeRate']);
        let advancePaymentInCurrency = form.getFieldValue(['advancePaymentInCurrency']);
        if (advancePaymentExchangeRate !== undefined && advancePaymentInCurrency !== undefined) {
            totalAdvancePayment = advancePaymentInCurrency * advancePaymentExchangeRate;
            form.setFieldsValue({ totalAdvancePayment: totalAdvancePayment });
        }
    };

    const paymentOnChange = () => {
        calculateTotalAdvancePayment();
    };

    const onChangeCostCenter = (value: any) => {
        if (value.id > -1) {
            setAccountingCostCenterRequired(true);
            setAccountingCostObjectRequired(false);
        } else if (showAccountingCostObject) {
            setAccountingCostCenterRequired(false);
            setAccountingCostObjectRequired(true);
        }
    };

    const onChangeCostObject = (value: any) => {
        if (value.id > -1) {
            setAccountingCostCenterRequired(false);
            setAccountingCostObjectRequired(true);
        } else if (showAccountingCostCenter) {
            setAccountingCostCenterRequired(true);
            setAccountingCostObjectRequired(false);
        }
    };

    // useEffect(() => {
    //     setRefresh((prevValue: any) => prevValue + 1);
    // }, [id]);

    const editWarrant = (values: any) => {
        if (values.tourJson === undefined || JSON.parse(values.tourJson).length == 0) {
            values.tourJson = values.destinationJson;
        }
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + values.id, undefined, values).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    setRefresh((prevValue: any) => prevValue + 1);
                    setInit(false);
                } else {
                    errorNotification();
                }
            }
        );
    };

    const saveWarrant = (values: any) => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT, undefined, values).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (uploadFilesRef?.current) {
                    uploadFilesRef.current.filesUpload(result?.data?.id);
                }
                okNotification();
                setInit(false);
                history.goBack();
            } else {
                errorNotification();
            }
        });
    };

    const editTravelWarrant = (statusId: any) => {
        const fileUploaded = files && files[0] && files.length > 0 ? true : false;
        form.validateFields()
            .then((values: any) => {
                if (
                    statusId == travelWarrantStatusEnum.WAITING_FOR_APPROVAL &&
                    travelWarrantStatus == undefined &&
                    mandatoryFile &&
                    !fileUploaded
                ) {
                    warningNotification(geti18nText('travelWarrantPersonal.notification.files'));
                } else {
                    values = normalizeValues(values);
                    values.status = statusId;
                    editWarrant(values);
                }
            })
            .catch((errorInfo: any) => {
                warningNotification(geti18nText('travelWarrantPersonal.notification.form'));
            });
    };

    const saveTravelWarrant = (statusId: any) => {
        const fileUploaded = files && files[0] && files.length > 0 ? true : false;
        form.validateFields()
            .then((values: any) => {
                if (mandatoryFile && !fileUploaded && statusId != -1) {
                    warningNotification(geti18nText('travelWarrantPersonal.notification.files'));
                } else {
                    values = normalizeValues(values);
                    values.travelWarrantAdditionalInfoList = [{ showTime: 1, destination: 'Split' }];
                    values.status = statusId;
                    if (dataForm != null && dataForm != undefined && dataForm > 0) {
                        editWarrant(values);
                    } else {
                        saveWarrant(values);
                    }
                }
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo);
                warningNotification(geti18nText('travelWarrantPersonal.notification.form'));
            });
    };

    const saveIncludedAmountReceived = () => {
        const resultAmounts = form.validateFields().then((values: any) => {
            let saveValues: any = {};
            saveValues.id = dataForm;
            saveValues.wageAmountReceived = values.wageAmountReceived;
            saveValues.travelVehicleExpenseAmountReceived = values.travelVehicleExpenseAmountReceived;
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_AMOUNT_RECEIVED + '/' + dataForm,
                undefined,
                saveValues
            ).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setWageAmountReceived(saveValues.wageAmountReceived);
                    okNotification();
                } else {
                    errorCustomNotification(geti18nText('app.default.save.nok'), '');
                }
            });
        });
        return resultAmounts;
    };

    const updateEducationDates = () => {
        const resultDates = form.validateFields().then(() => {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_EDUCATION_DATE + '/' + dataForm,
                undefined,
                {
                    id: dataForm,
                    educationStartDate: getDateFormat(form.getFieldValue(['educationStartDate'])),
                    educationEndDate: getDateFormat(form.getFieldValue(['educationEndDate'])),
                }
            ).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    okNotification();
                } else {
                    errorNotification();
                }
            });
        });
        return resultDates;
    };

    const updateTravelWarrantType = () => {
        return new Promise<void>((resolve, reject) => {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TRAVEL_WARRANT.UPDATE_TRAVEL_WARRANT_TYPE_ADMIN + '/' + dataForm,
                undefined,
                {
                    id: dataForm,
                    travelWarrantTypeId: travelWarrantType.id,
                }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    const promises: Promise<void>[] = [];

                    if (payoutByAnotherOrganization) {
                        promises.push(saveIncludedAmountReceived());
                    }

                    if (includeEducationDate) {
                        promises.push(updateEducationDates());
                    }

                    Promise.all(promises)
                        .then(() => {
                            okNotification();
                            resolve();
                        })
                        .catch((error) => {
                            errorNotification(error);
                            reject(error);
                        });
                } else {
                    errorNotification(result.data);
                    reject(result.data);
                }
            });
        });
    };

    const updateTwType = async () => {
        try {
            await updateTravelWarrantType();
            // window.location.reload();
        } catch (error) {}
    };

    const save = () => {
        if (dataForm) {
            if (
                travelWarrantStatus == travelWarrantStatusEnum.DRAFT ||
                travelWarrantStatus == travelWarrantStatusEnum.REJECTED
            ) {
                editTravelWarrant(travelWarrantStatusEnum.WAITING_FOR_APPROVAL);
            } else {
                editTravelWarrant(travelWarrantStatus);
            }
        } else {
            saveTravelWarrant(travelWarrantStatusEnum.WAITING_FOR_APPROVAL);
        }
    };

    const changeStatus = (recordId: any, status: any, info: any) => {
        setLoading(true);
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: info,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh: number) => refresh + 1);
                setStatus(status);
                if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                    okNotification(geti18nText('travelWarrantPersonal.sendToApproval.ok'));
                } else if (status == travelWarrantStatusEnum.REJECTED) {
                    okNotification(geti18nText('travelWarrantPersonal.cancelTrip.ok'));
                } else {
                    okNotification();
                }
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
            }
            setLoading(false);
        });
    };

    const showTravelWizardComplete = async () => {
        setWizard(true);
    };

    const calculate = (recordId: any) => {
        if (recordId) {
            NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT.CALCULATE, undefined, {
                id: recordId,
                lang: NyUtils.getSelectedLanguage(),
            }).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    setRefresh((refresh: number) => refresh + 1);
                    setTravelWarrantStatus(travelWarrantStatusEnum.CALCULATED);
                    setStatus(travelWarrantStatusEnum.CALCULATED);
                    setActiveKey('payment');
                } else {
                    if (result.data && result.data instanceof String) {
                        errorNotification(result.data);
                    } else {
                        errorNotification(result.data.error);
                    }
                }
            });
        }
    };

    const pointBook = (recordId: any) => {
        if (recordId) {
            NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT.POINT_BOOK, undefined, {
                id: recordId,
            }).then((result: any) => {
                if (result?.status === RESPONSE.CREATED || result.status === RESPONSE.OK) {
                    okNotification();
                    setRefresh((refresh: number) => refresh + 1);
                } else {
                    if (result.data && result.data instanceof String) {
                        errorNotification(result.data);
                    } else {
                        errorNotification(result.data.error);
                    }
                }
            });
        }
    };

    const approveReport = (recordId: any) => {
        if (recordId) {
            setApproveReportModal(true);
        }
    };

    const organize = (recordId: any) => {
        if (recordId) {
            setOrganizeModal(true);
        }
    };

    const reportApprovedFooter = (
        <>
            <Button
                onClick={() => {
                    setReportApprovalVisible(false);
                }}
            >
                {geti18nText('app.default.button.no')}
            </Button>
            <Button
                type="primary"
                onClick={() => {
                    if (form.getFieldValue(['info']) != null && form.getFieldValue(['info']) != undefined) {
                        changeStatus(dataForm, travelWarrantStatusEnum.REPORT_FIX_NEEDED, form.getFieldValue(['info']));
                    } else {
                        warningNotification(geti18nText('travelWarrantOrganization.note.info'));
                    }
                }}
            >
                {geti18nText('app.default.button.yes')}
            </Button>
        </>
    );

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    )}
                </Tooltip>
            </div>
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                {travelWarrant != undefined && (
                    <>
                        <RowAction
                            component={'TravelWarrantPrint'}
                            record={travelWarrant}
                            showInEdit={true}
                            printTravelWarrant={true}
                        ></RowAction>

                        <RowAction
                            component={'TravelWarrantAction'}
                            record={travelWarrant}
                            changeStatus={changeStatus}
                            showTravelWizard={showTravelWizardComplete}
                            calculate={calculate}
                            approveReport={approveReport}
                            organize={organize}
                            showInEdit
                            changeStatusUnpaid={() => {
                                setModalVisible(true);
                            }}
                            showTravelAction={() => {
                                setTravelWarrantConfirmationModal(true);
                                setCanApprove(true);
                                setCanReject(true);
                            }}
                            fetchAccessRights
                        ></RowAction>
                    </>
                )}
            </div>
            {canCreateInit && (
                <div style={{ float: 'right' }}>
                    {canSaveAdmin &&
                        travelWarrantStatus !== travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL &&
                        (canEditStatusAndCalculationDatePaid() || status != travelWarrantStatusEnum.PAID) && (
                            <div style={{ display: 'inline' }}>
                                <Button type="primary" onClick={save}>
                                    {geti18nText('app.default.button.save')}
                                </Button>
                            </div>
                        )}
                    {canCompleteAction() && (
                        <div style={{ display: 'inline' }}>
                            <Button type="primary" className="purple-button" onClick={() => setWizard(true)}>
                                {geti18nText('travelWarrant.overview.complete')}
                            </Button>
                        </div>
                    )}
                    {canOpenNewWizard() && (
                        <div style={{ display: 'inline' }}>
                            <Button type="primary" className="purple-button" onClick={() => setWizardNew(true)}>
                                {geti18nText('travelWarrantWizard.header.draft')}
                            </Button>
                            {!canSaveAdmin && (
                                <NyModalConfirm
                                    title={geti18nText('travelWarrantPlan.edit.sendToApproval.confirm')}
                                    onConfirm={() => {
                                        saveTravelWarrant(travelWarrantStatusEnum.DRAFT);
                                    }}
                                >
                                    <Button style={{ display: 'inline' }} type="primary">
                                        {geti18nText('travelWarrantWizard.saveSketch')}
                                    </Button>
                                </NyModalConfirm>
                            )}
                            <NyModalConfirm
                                title={geti18nText('travelWarrantPlan.edit.sendToApproval.send')}
                                onConfirm={() => {
                                    saveTravelWarrant(travelWarrantStatusEnum.WAITING_FOR_APPROVAL);
                                }}
                            >
                                <Tooltip
                                    placement="top"
                                    title={geti18nText('travelWarrantWizard.sendForApproval.tooltip')}
                                >
                                    <Button className="green-button" style={{ display: 'inline' }} type="primary">
                                        {geti18nText('travelWarrantWizard.sendForApproval')}
                                    </Button>
                                </Tooltip>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canReportAction() && (
                        <div style={{ display: 'inline' }}>
                            <Modal
                                visible={reportApprovalVisible}
                                title={geti18nText('travelWarrant.popconfirm.reportFixNeeded.cancel')}
                                footer={reportApprovedFooter}
                                onCancel={() => setReportApprovalVisible(false)}
                                closable={false}
                            >
                                <Form.Item
                                    label={geti18nText('travelWarrantOrganization.table.column.message')}
                                    name="info"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        autoSize={{ minRows: 6, maxRows: 6 }}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                    />
                                </Form.Item>
                            </Modal>
                            <Button
                                type="primary"
                                className={theme == 'dark' ? 'report-fix-needed-dark' : 'report-fix-needed'}
                                onClick={() => {
                                    setReportApprovalVisible(true);
                                }}
                            >
                                {geti18nText('travelWarrant.edit.button.reportFixNeeded')}
                            </Button>
                        </div>
                    )}
                    {canPointBookAction() && (
                        <div style={{ display: 'inline' }}>
                            <NyModalConfirm
                                title={geti18nText('travelWarrant.popconfirm.point.book.confirm')}
                                onConfirm={() => {
                                    pointBook(dataForm);
                                }}
                            >
                                <Button type="primary" className="green-button">
                                    {geti18nText('travelWarrant.table.point.book')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canCalculateAction() && (
                        <div style={{ display: 'inline' }}>
                            <NyModalConfirm
                                title={geti18nText('travelWarrant.popconfirm.calculate.cancel')}
                                onConfirm={() => {
                                    calculate(dataForm);
                                }}
                            >
                                <Button type="primary" className="purple-button">
                                    {geti18nText('travelWarrant.table.calculate')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    )}
                    {canOpenNewWizard2() && (
                        <Button type="primary" className="purple-button" onClick={() => setWizardNew(true)}>
                            {geti18nText('travelWarrantWizard.header.draft')}
                        </Button>
                    )}
                </div>
            )}
        </React.Fragment>
    );

    const statusOnChange = (value: any) => {
        if (value && value.text != '' && value.id) {
            if (status != value.id) {
                showConfirm(value);
            } else {
                changeStatus(dataForm, value.id, undefined);
                setTravelWarrantStatus(value.id);
            }
        }
    };

    function showConfirm(val: any) {
        const message = geti18nText('payment.orders.initialization.edit.status.comfirm');
        const content =
            geti18nText('travel.warrant.current.status') +
            ': ' +
            geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + status);
        confirm({
            title: message,
            content: content,
            okText: geti18nText('app.default.button.yes'),
            cancelText: geti18nText('app.default.button.no'),
            icon: <ExclamationCircleOutlined />,
            onCancel() {
                form.setFieldsValue({ status: setEnumFormat('TRAVEL_WARRANT_STATUS', status) });
            },
            onOk() {
                setTravelWarrantStatus(val && val.id);
                changeStatus(dataForm, val.id, undefined);
                if (
                    status !== travelWarrantStatusEnum.WAITING_FOR_APPROVAL &&
                    val.id === travelWarrantStatusEnum.WAITING_FOR_APPROVAL
                ) {
                    form.setFieldsValue({ calculationDate: undefined });
                }
            },
        });
    }

    const employeeOnChange = (value: any) => {
        if (value.id === -1) {
            form.setFieldsValue({ employee: undefined });
        }

        setEmployeeId(null);
        form.setFieldsValue({
            travelVehicle: null,
            travelVehicle2: null,
        });
        if (value && value.id > 0) {
            dispatch(employeeIdSet({ employeeId: value.id }));
            setEmployeeId(value.id);
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data.vocation && result.data.vocation.name) {
                            form.setFieldsValue({ employeeVocation: result.data.vocation.name });
                        }
                        if (result.data && result.data.businessUnit && result.data.businessUnit.accountingCostCenter) {
                            form.setFieldsValue({
                                accountingCostCenter: setSearchFormat(
                                    result.data.businessUnit.accountingCostCenter,
                                    'name',
                                    'name',
                                    'code'
                                ),
                            });
                            setAccountingCostCenter(result.data.businessUnit.accountingCostCenter);
                        }
                    }
                }
            );
        }
    };

    const setValuesFiles = (travelWarrantId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(travelWarrantId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
    };

    const normalizeValues = (values: any) => {
        values.returnRoute = returnRoute;
        if (!values.ordinal) values.ordinal = ordinal;

        if (values.warrantDate) {
            setTravelWarrantDate(moment(values.warrantDate).format('YYYY'));
            values.warrantDate = getDateFormat(values.warrantDate);
        } else {
            values.warrantDate = getDateFormat(moment());
        }

        if (waypoints[0]?.shortName) {
            values.startingPoint = waypoints[0]?.shortName;
        }

        if (
            vehicle2EnableSetting === '1' &&
            values.travelVehicle &&
            !isPersonalOrOfficial &&
            showAnotherVehicle &&
            values.travelVehicle2
        ) {
            values.travelVehicle2 = values.travelVehicle2;
        } else {
            values.travelVehicle2 = undefined;
        }
        if (values.employee) values.employee = getSearchFormat(values.employee);
        if (values.employee.travelVehicle)
            values.employee.travelVehicle = getSearchFormat(values.employee.travelVehicle);
        if (values.travelVehicle) {
            values.travelVehicle = getSearchFormat(values.travelVehicle);
        }
        if (values.startDate) {
            values.startDate = getDateFormat(moment(values.startDate).format('yyyy-MM-DD'));
            values.endDate = getDateFormat(
                moment(values.startDate)
                    .add(values.duration - 1, 'days')
                    .format('yyyy-MM-DD')
            );
        }

        if (values.calculationDate) values.calculationDate = getDateFormat(values.calculationDate);
        if (values.accountingCostCenter && showAccountingCostCenter)
            values.accountingCostCenter = getSearchFormat(values.accountingCostCenter);
        if (values.accountingCostObject && showAccountingCostObject)
            values.accountingCostObject = getSearchFormat(values.accountingCostObject);

        if (values.advancePaymentCurrency) {
            values.advancePaymentCurrency = getSearchFormat(values.advancePaymentCurrency);
        }
        if (values.advancePaymentCurrency)
            values.advancePaymentCurrency = getSearchFormat(values.advancePaymentCurrency);

        const travelWarrantPlansList: any = [];
        const newData = [...travelWarrantPlans];
        let tourJson: any = [];
        if (newData !== undefined && newData.length > 0) {
            var index = 0;
            newData.map((element: any) => {
                const nextItem = newData[index + 1];
                if (nextItem != undefined) {
                    const object = newData[index + 1];
                    let planItem: any = {};
                    if (object.countryId) {
                        planItem.country = getSearchFormat(object.countryId);
                    }
                    if (element.shortName && object.shortName) {
                        planItem.destination = element.shortName + ' - ' + object.shortName;
                    }
                    if (values.travelVehicle) {
                        planItem.travelVehicle = getSearchFormat(values.travelVehicle);
                    }
                    if (element && object) {
                        planItem.relationJson = JSON.stringify([element, object]);
                    }
                    tourJson.push([element, object]);
                    travelWarrantPlansList.push(planItem);
                }
                index += 1;
            });
            values.tourJson = JSON.stringify(tourJson);
        } else {
            values.tourJson = form.getFieldValue('tourJson');
        }

        values.destinationJSon = values.destinationJSon
            ? values.destinationJSon
            : values.destinationJson
            ? values.destinationJson
            : destinationData;
        delete values.destinationJson;

        values.travelWarrantPlans = travelWarrantPlansList;
        values.travelWarrantWageExpenses = checkedData;
        if (values.travelEventNumber?.id < 0) {
            delete values.travelEventNumber;
        }
        if (values.educationStartDate) {
            values.educationStartDate = setDateFormat(values.educationStartDate);
            values.educationStartDate = values.educationStartDate;
        }
        if (values.educationEndDate) {
            values.educationEndDate = setDateFormat(values.educationEndDate);
            values.educationEndDate = values.educationEndDate;
        }

        values.status = travelWarrantStatus;
        values.travelWarrantAdditionalInfoList = getAdditionalValues();
        if (values?.travelWarrantAdditionalInfoList?.length < 1) {
            const valuesAdd: any = normalizeValuesAdditional(formAdditional.getFieldsValue());
            valuesAdd.destination = waypoints && waypoints[1]?.shortName;
            values.travelWarrantAdditionalInfoList = valuesAdd;
        }
        delete values.destinationInput;
        if (!values.ordinal || values?.ordinal < 0) {
            delete values.ordinal;
        }
        return values;
    };

    const fetchTypes = () => {
        const setDefaultFilterValue = () => {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        };

        const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));

        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_TYPE.LIST, {
            max: 999,
            search: stringUri,
            order: 'sort',
            orderType: 'asc',
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setLoading(false);
                if (result.data) {
                    setTravelWarrantTypes(result.data.content);
                }
            }
        });
    };

    const changeId = (returnedId: any) => {
        // dispatch(removeTab(location.pathname));
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        const pathname = CONSTANTS_REQ.TRAVEL_WARRANT.PATHNAME + returnedId;
        // dispatch(addTab({ title: 'travelWarrant.edit.title.short', addedData: returnedId, path: pathname }));
        history.push(pathname);
    };

    const getTravelWarrantHistory = (status: any) => {
        return (
            <div>
                <Popover
                    title={geti18nText('travelWarrantPersonal.tab.history')}
                    placement="bottomLeft"
                    content={<StatusHistoryIndex id={dataForm} type="travelWarrant" />}
                    style={{ marginLeft: '4rem' }}
                >
                    <div style={{ marginLeft: '5px' }}> {getTravelWarrantStatusIcon(status)}</div>
                </Popover>
            </div>
        );
    };

    const onChangeTab = (key: string) => {
        setActiveKey(key);
        setHistoryLoading(true);
        if (key === 'history') {
            setRefreshHistory((refreshHistory) => refreshHistory + 1);
            setHistoryLoading(false);
        } else if (key === 'files') {
            setRefreshFiles((refreshFiles) => refreshFiles + 1);
        }
    };

    return (
        <>
            <Tabs
                type="card"
                className="tabs-sticky"
                id="travel-warrant-edit-tabs"
                style={{ overflowY: 'auto', paddingBottom: '10px' }}
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onChange={onChangeTab}
            >
                <TabPane tab={<span>{geti18nText('travelWarrant.tab.general')}</span>} key="general">
                    <NyDataEdit
                        layout="vertical"
                        formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                        loading={loading}
                        setLoading={setLoading}
                        url={CONSTANTS_REQ.TRAVEL_WARRANT.EDIT}
                        setValues={setValues}
                        form={form}
                        paramsId={id}
                        fetchWhenChange={refresh || id}
                        {...props}
                        shortcuts={true}
                        checkIsFormChanged={true}
                        changeCollapseVisibility={changeCollapseVisibility}
                        customFooterContent={getCustomFooterContent}
                        buttonsClassName="buttons-sticky"
                        hideSubmitButton={true}
                        hideActivationButtons
                        goBack={() => {
                            history.goBack();
                            // dispatch(removeTab(location.pathname));
                        }}
                        onSaveAndGetID={(returnedId: any) => {
                            if (id === 'create') {
                                changeId(returnedId);
                            } else {
                                // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
                            }
                        }}
                        normalize={(values: any) => {
                            values = normalizeValues(values);
                            return values;
                        }}
                        validateOnLoad={false}
                    >
                        {init == true && (
                            <GeneralEditData
                                isAdmin={isAdmin}
                                leftCollapseActiveKeys={collapseActiveKeys}
                                setLeftCollapseActiveKeys={setCollapseActiveKeys}
                                currencyOnChange={currencyOnChange}
                                paymentOnChange={paymentOnChange}
                                calculateTotalAdvancePayment={calculateTotalAdvancePayment}
                                note={form.getFieldValue('note')}
                                canCreate={canCreateInit}
                                waypoints={waypoints}
                                destination={destination}
                                setDestination={setDestination}
                                setWaypoints={setWaypoints}
                                maxWaypointsKey={maxWaypointsKey}
                                setMaxWaypointsKey={setMaxWaypointsKey}
                                form={form}
                                defaultWaypoints={defaultWaypoints}
                                setTravelWarrantPlans={setTravelWarrantPlans}
                                accountingCostCenterRequired={accountingCostCenterRequired}
                                accountingCostObjectRequired={accountingCostObjectRequired}
                                showAccountingCostCenter={showAccountingCostCenter}
                                showAccountingCostObject={showAccountingCostObject}
                                onChangeCostCenter={onChangeCostCenter}
                                onChangeCostObject={onChangeCostObject}
                                disabledTable={
                                    disabledTable ||
                                    travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL
                                }
                                setDefaultFilterValue={setDefaultFilterValue}
                                setDefaultFilterValuePersonalOrOfficial={setDefaultFilterValuePersonalOrOfficial}
                                status={status}
                                employeeOnChange={employeeOnChange}
                                mandatoryFile={mandatoryFile}
                                moduleAccessRight={moduleAccessRight}
                                onTravelVehicleChange={onTravelVehicleChange}
                                onTravelVehicle2Change={onTravelVehicle2Change}
                                isPlane={isPlane}
                                isPersonalOrOfficial={isPersonalOrOfficial}
                                showAnotherVehicle={showAnotherVehicle}
                                setShowAnotherVehicle={setShowAnotherVehicle}
                                onStartDateChange={onStartDateChange}
                                onEndDateChange={onEndDateChange}
                                disabledStartDate={disabledStartDate}
                                disabledendDate={disabledendDate}
                                dataForm={dataForm}
                                travelVehicle={travelVehicle}
                                vehicle2Enabled={vehicle2EnableSetting === '1'}
                                showTime={showTime}
                                setShowTime={setShowTime}
                                warrantDateOnChange={warrantDateOnChange}
                                advance={advance}
                                vehicle={vehicle}
                                vehicle2={vehicle2}
                                statusOnChange={statusOnChange}
                                focusInputReport={focusInputReport}
                                canView={canView}
                                canEdit={
                                    canEdit &&
                                    !travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL
                                }
                                canEditPersonal={
                                    canEditPersonal &&
                                    !travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL
                                }
                                getTravelWarrantHistory={getTravelWarrantHistory}
                                rerenderKey={rerenderKey}
                                employeeId={employeeId}
                                loadingAdditional={loadingAdditional}
                                dataAdditional={dataAdditional}
                                destinationsAdditional={destinationsAdditional}
                                destinationAdditional={destinationAdditional}
                                onChangeDestinationAdditional={onChangeDestinationAdditional}
                                itemAdditional={itemAdditional}
                                formAdditional={formAdditional}
                                needTransport={needTransport}
                                isDisabledPaidWarrant={
                                    isDisabledPaidWarrant() ||
                                    travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL
                                }
                                getDefaultStartingPoint={getDefaultStartingPoint}
                                initWaypoints={initialWaypoints}
                                setInitWaypoints={setInitialWaypoints}
                                returnWaypoints={returnWaypoints}
                                setReturnWaypoints={setReturnWaypoints}
                                returnRoute={returnRoute}
                                returnIntermediateWaypointsInit={returnIntermediateWaypointsInit.current}
                                showCheckboxesDestinationName={showCheckboxesDestinationName}
                            />
                        )}
                    </NyDataEdit>
                </TabPane>
                {!disabledTabs &&
                    (travelWarrantTypes.length > 1 || payoutByAnotherOrganization || includeEducationDate) && (
                        <TabPane
                            tab={geti18nText('travelWarrantWizard.step.1.5')}
                            key="AmountReceived"
                            disabled={disabledTabs}
                        >
                            <TWTypeComponent
                                formTravelWarrant={form}
                                setIncludeEducationDate={setIncludeEducationDate}
                                includeEducationDate={includeEducationDate}
                                setPayoutByAnotherOrganization={setPayoutByAnotherOrganization}
                                travelWarrantType={travelWarrantType}
                                setTravelWarrantType={setTravelWarrantType}
                                payoutByAnotherOrganization={payoutByAnotherOrganization}
                                defaultCurrencyISO={defaultCurrencyISO}
                                disabledendEduDate={disabledendEduDate}
                                showSearch={false}
                                isNotEditable={
                                    travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL ||
                                    !TravelWarrantRights.canOpenTravelWarrantWage(employee, notificationType) ||
                                    isDisabledPaidWarrant()
                                }
                                defaultEndDate={defaultEndDate}
                                setDefaultEndDate={setDefaultEndDate}
                                rerenderKey={rerenderKey2}
                                setRerenderKey={setRerenderKey2}
                            />
                            {TravelWarrantRights.canOpenTravelWarrantWage(employee, notificationType) && (
                                <div style={{ float: 'right', paddingRight: '15px' }}>
                                    <Button type="primary" onClick={updateTwType} disabled={isDisabledPaidWarrant()}>
                                        {geti18nText('app.default.button.save')}
                                    </Button>
                                </div>
                            )}
                        </TabPane>
                    )}
                {!disabledTabs && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.2')} key="plan" disabled={disabledTabs}>
                        {init == true && (
                            <TravelWarrantStartEndIndex
                                travelWarrantId={dataForm}
                                isWizard={false}
                                isAdmin={isAdmin}
                                startDate={form.getFieldValue('startDate')}
                                setStartDate={setStartDate}
                                endDate={form.getFieldValue('endDate')}
                                setEndDate={setEndDate}
                                isPlane={isPlane}
                                destinationJson={destinationJson}
                                setDestinationJson={setDestinationJson}
                                disableEdit={true}
                                disabled={true}
                                readonly={travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL}
                                returnRouteInit={returnRoute}
                            />
                        )}
                    </TabPane>
                )}
                {!disabledTabs && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.8')} key="planSteps" disabled={disabledTabs}>
                        <TravelWarrantPlanSteps
                            travelWarrantId={dataForm}
                            isPlane={isPlane}
                            isWizard={false}
                            startDate={form.getFieldValue('startDate')}
                            endDate={form.getFieldValue('endDate')}
                            readonly={travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL}
                        />
                    </TabPane>
                )}
                {/* {!disabledTabs && travelWarrantType?.includeWageExpenses && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.9')} key="wageExpense" disabled={disabledTabs}>
                        <TravelWarrantWageExpensesIndex id={id} isWizard={false} />
                    </TabPane>
                )} */}
                {!disabledTabs && travelWarrantType?.includeWage && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.10')} key="wage" disabled={disabledTabs}>
                        <TravelWarrantWageIndex
                            isAdmin={canSaveAdmin}
                            startDate={form.getFieldValue('startDate')}
                            id={id}
                            travelWarrant={travelWarrant}
                            travelWarrantType={travelWarrantType}
                            wageAmountReceived={wageAmountReceived}
                            activeKey={activeKey}
                            disabled={
                                travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL ||
                                isDisabledPaidWarrant()
                            }
                        />
                    </TabPane>
                )}
                {!disabledTabs && isPersonalVehicle === true && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.11')} key="transport" disabled={disabledTabs}>
                        <TravelWarrantVehicleIndex
                            isAdmin={canSaveAdmin}
                            id={id}
                            isPersonalVehicle={isPersonalVehicle}
                            isWizard={false}
                            readonly={
                                travelWarrantStatus == travelWarrantStatusEnum.CANCELLED ||
                                travelWarrantStatus == travelWarrantStatusEnum.PAID ||
                                travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL ||
                                ((travelWarrantStatus == travelWarrantStatusEnum.CALCULATED ||
                                    travelWarrantStatus == travelWarrantStatusEnum.REPORT_APPROVED) &&
                                    !canSaveAdmin) ||
                                isDisabledPaidWarrant()
                            }
                        />
                    </TabPane>
                )}
                {!disabledTabs && travelWarrantType?.includeTravelExpenses && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.12')} key="expense" disabled={disabledTabs}>
                        <TravelWarrantExpensesIndex
                            isAdmin={canSaveAdmin}
                            isAdminForm={true}
                            id={id}
                            startDate={form.getFieldValue('startDate')}
                            refreshFiles={refreshFiles}
                            setRefreshFiles={setRefreshFiles}
                            mandatoryFile={mandatoryFile}
                            setMandatoryFile={setMandatoryFile}
                            readonly={
                                travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL ||
                                travelWarrantStatus == travelWarrantStatusEnum.CANCELLED ||
                                travelWarrantStatus == travelWarrantStatusEnum.PAID ||
                                (travelWarrantStatus == travelWarrantStatusEnum.REPORT_APPROVED && !canSaveAdmin) ||
                                isDisabledPaidWarrant()
                            }
                            showPreviewFirstFile
                            canEditStatusAndCalculationDatePaid={canEditStatusAndCalculationDatePaid()}
                        />
                    </TabPane>
                )}
                {!disabledTabs && (
                    <TabPane tab={geti18nText('travelWarrant.tab.payment')} key="payment" disabled={disabledTabs}>
                        <TravelWarrantPaymentIndex
                            edit={edit}
                            id={id}
                            travelWarrantStatus={travelWarrantStatus}
                            getTravelWarrantHistory={getTravelWarrantHistory}
                            status={status}
                            activeKey={activeKey}
                            setRefresh={setRefresh}
                            readonly={
                                isDisabledPaidWarrant() ||
                                travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL
                            }
                            isWizard={false}
                        />
                    </TabPane>
                )}
                <TabPane tab={geti18nText('travelWarrantPersonal.tab.history')} key="history">
                    {historyLoading ? (
                        <NySpinner />
                    ) : (
                        <TravelWarrantHistoryIndex
                            id={id}
                            link={'travelWarrant'}
                            refresh={refreshHistory}
                            showHistorySnapshotByStatus={travelWarrantStatus == travelWarrantStatusEnum.PAID && isAdmin}
                        />
                    )}
                </TabPane>
                <TabPane tab={geti18nText('travelWarrant.tab.files')} key="files">
                    <NyFilesUpload
                        documentType={documentTypeEnum.TRAVEL_WARRANT_FILE}
                        editUrl={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.EDIT}
                        dataSourceFiles={files}
                        setDataSourceFiles={setFiles}
                        ref={uploadFilesRef}
                        canAddNewFile={statusForFiles()}
                        canEdit={statusForFiles()}
                        canDelete={statusForFiles()}
                        id={dataForm}
                        refId={dataForm}
                        employee={employee}
                        employeeTravelWarrantId={employeeId}
                        setRefreshDataSourceFiles={setRefreshFiles}
                        showDocumentTypeColumn={true}
                        isTravelWarrantEdit={true}
                        columnStyleClass={'description-div-large'}
                        showHeader={false}
                        maxHeight={'550px'}
                        isTravelWarrant={true}
                        moduleAccessRight={moduleAccessRight}
                        readonly={
                            travelWarrantStatus == travelWarrantStatusEnum.PAID &&
                            !canEditStatusAndCalculationDatePaid()
                        }
                    />
                </TabPane>
                {!disabledTabs && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.14')} key="recapitulation">
                        <TravelWarrantRecapitulationIndex
                            dataForm={dataForm}
                            paymentInDeclaredCurrency={paymentDeclaredInCurrency === '1'}
                            defaultCurrency={defaultCurrency}
                            isPlane={isPlane}
                            activeKey={activeKey}
                            status={status}
                            readonly={travelWarrantStatus == travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL}
                            showIsDirtyWage={showIsDirtyWage()}
                        />
                    </TabPane>
                )}
                {!disabledTabs && (
                    <TabPane tab={geti18nText('travelWarrantWizard.step.15')} key="report">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form
                                    id={`repor-form-${dataForm}`}
                                    form={formReport}
                                    layout="vertical"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    onFinishFailed={({ errorFields }) => {
                                        formReport.scrollToField(errorFields[0].name);
                                    }}
                                >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('travelWarrantPersonal.edit.report')}
                                                name="report"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea
                                                    autoSize={{ minRows: 8, maxRows: 8 }}
                                                    onKeyDown={onKeyDownTextAreaCustom}
                                                    autoFocus
                                                    placeholder={geti18nText(
                                                        'travelWarrantPersonal.edit.report.mandatory'
                                                    )}
                                                    disabled={
                                                        isDisabledPaidWarrant() ||
                                                        travelWarrantStatus ==
                                                            travelWarrantStatusEnum.WAITING_FOR_REPORT_APPROVAL
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </TabPane>
                )}
            </Tabs>
            {wizard && init == true && (
                <TravelWarrantPersonalWizardEdit
                    ordinal={ordinal}
                    warrantDate={travelWarrantDate}
                    visible={wizard}
                    setVisible={setWizard}
                    travelVehicle={travelVehicle}
                    startDate={form.getFieldValue('startDate')}
                    endDate={form.getFieldValue('endDate')}
                    files={files}
                    setFiles={setFiles}
                    travelWarrantStatus={travelWarrantStatus}
                    dataForm={dataForm}
                    report={formReport.getFieldValue('report')}
                    isPersonalVehicle={isPersonalVehicle || isPersonalVehicle2}
                    destinationJson={destinationJson}
                    isPlane={isPlane}
                    setDestinationJson={setDestinationJson}
                    resetDataForm={resetDataForm}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setRefresh={setRefresh}
                />
            )}
            {wizardNew && init == true && (
                <TravelWarrantPersonalWizardNew
                    visible={wizardNew}
                    setVisible={setWizardNew}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    dataForm={dataForm}
                />
            )}
            {sendModalVisible && init == true && (
                <SendTripModal
                    visible={sendModalVisible}
                    setVisible={setSendModalVisible}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    dataForm={dataForm}
                    report={reportForm.getFieldValue('report')}
                />
            )}
            {travelWarrantConfirmationModal && init == true && (
                <TravelWarrantActionModal
                    refresh={refresh}
                    setRefresh={setRefresh}
                    travelWarrantId={id}
                    modalVisible={travelWarrantConfirmationModal}
                    setModalVisible={setTravelWarrantConfirmationModal}
                    employee={employee}
                    files={files}
                    canApprove={canApprove}
                    canReject={canReject}
                    isTravelWarrantEdit={true}
                />
            )}
            {approveReportModal && init == true && (
                <ReportApproveTravelWarrantModal
                    modalVisible={approveReportModal}
                    setModalVisible={setApproveReportModal}
                    travelWarrantId={id}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            )}
            {organizeModal && init == true && (
                <OrganizationTravelWarrantModal
                    modalVisible={organizeModal}
                    setModalVisible={setOrganizeModal}
                    travelWarrantId={id}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            )}
            {modalVisible && init == true && (
                <TravelWarrantUnpaidModal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    travelWarrantId={dataForm}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    tab={4}
                />
            )}
        </>
    );
};

export default TravelWarrantEdit;
