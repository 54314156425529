import {
    CaretLeftOutlined,
    CaretRightOutlined,
    InfoCircleTwoTone,
    PrinterOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    NyDatePicker,
    NyInputNumber,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Popover, Row, Steps, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import TravelWarrantAdditionalInfoIndex from '../../../../../components/travel-warrant-additional-info';
import TravelWarrantMapModal from '../../../../../components/travel-warrant-map/TravelWarrantMapModal';
import TravelWarrantSummaryIndex from '../../../../../components/travel-warrant-summary';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import { TravelWarrantRights } from '../../../../../rights/travelWarrantRights';
import { RootState } from '../../../../../rootReducer';
import { setTravelWarrantData, travelWarrantInit } from '../../../../../slices/travelWarrantSlice';
import { CONSTANTS_REQ, TRAVEL_WARRANT_WIZARD_EDIT_STEPS as WIZARD_STEP } from '../../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../../utils/Enums';
import {
    customEmployeeRenderOption,
    errorCustomNotification,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getFileList,
    getNewTravelWizardDocsUrl,
    getSearchFormat,
    isAdmin,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    warningNotification,
} from '../../../../../utils/Utils';
import { validateObjectId } from '../../../../../utils/Validation';
import NyFilesUpload from '../../../../../utils/ny-file-upload-custom';
import CostCenterEdit from '../../../../accounting/views/cost-center/edit';
import CostCenterSearch from '../../../../accounting/views/cost-center/search';
import CostObjectEdit from '../../../../accounting/views/cost-object/edit';
import CostObjectSearch from '../../../../accounting/views/cost-object/search';
import CurrencySearch from '../../../../administration/views/currency/search';
import EmployeeIndex from '../../../../human/views/employee';
import TravelEventNumberSearch from '../../travel-event-number/search';
import TravelVehiclePersonalIndex from '../../travel-vehicle-personal';
import TravelVehiclePersonalEdit from '../../travel-vehicle-personal/edit';
import './_index.scss';

const { Step } = Steps;
const { TextArea } = Input;

const TravelWarrantPersonalWizardNew = ({
    visible,
    setVisible,
    refresh,
    setRefresh,
    adminRefresh,
    setAdminRefresh,
    dataForm = null,
}: any) => {
    const { id } = useParams<any>();
    const travelWarrantStartingPoint = useApplicationSetting('TRAVEL_WARRANT_DEFAULT_STARTING_POINT');
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const travelWarrantShowEducation = useApplicationSetting('TRAVEL_WARRANT_SHOW_EDU_DECISION_COMMISSION');
    const mandatoryFile = useApplicationSetting('TRAVEL_WARRANT_MANDATORY_PURPOSE_AND_ATTACHMENT');
    const vehicle2Enabled = useApplicationSetting('TRAVEL_WARRANT_VEHICLE_2_ENABLED');
    const showAccountingCostCenter = useApplicationSetting('ACCOUNTING_COST_CENTER');
    const showAccountingCostObject = useApplicationSetting('ACCOUNTING_COST_OBJECT');

    const [current, setCurrent] = useState(0);
    const [employeeId, setEmployeeId] = useState(null);
    const [accountingCostCenterRequired, setAccountingCostCenterRequired] = useState(showAccountingCostCenter == '1');
    const [accountingCostObjectRequired, setAccountingCostObjectRequired] = useState(
        showAccountingCostCenter != '1' && showAccountingCostObject == '1'
    );
    const [person, setPerson] = useState<any>({});
    const [startingPoint, setStartingPoint] = useState<any>(undefined);
    const [destination, setDestination] = useState<any>(undefined);
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [initialWaypoints, setInitialWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [travelWarrantPlans, setTravelWarrantPlans] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(null);

    const [isPlane, setIsPlane] = useState(false);
    const [isPersonalOrOfficial, setIsPersonalOrOfficial] = useState(false);
    const [showAnotherVehicle, setShowAnotherVehicle] = useState(false);

    const [form] = Form.useForm();
    const [formSummary] = Form.useForm();
    const { employee } = useSelector((state: RootState) => state.employee);

    const [globalForm, setGlobalForm] = useState<any>(undefined);
    const [travelVehicle, setTravelVehicle] = useState<any>(undefined);
    const [currency, setCurrency] = useState<any>(undefined);
    const [ordinal, setOrdinal] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const { travelWarrantData } = useSelector((state: RootState) => state.travelWarrant);
    const [travelWarrantEmployee, setTravelWarrantEmployee] = useState<any>(employee);
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const [tourJsonData, setTourJsonData] = useState<any>([]);
    const [destinationData, setDestinationData] = useState<any>([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [defaultEndDate, setDefaultEndDate] = useState<any>(moment());
    const [rerenderKey, setRerenderKey] = useState<any>(0);

    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const uploadFilesRef = useRef<any>(null);
    const [refreshFiles, setRefreshFiles] = useState(0);

    const [travelWarrantAdditionalInfo, setTravelWarrantAdditionalInfo] = useState<any>([]);
    const [needTransport, setNeedTransport] = useState<any>(undefined);
    const [refreshAdditionalInfo, setRefreshAdditionalInfo] = useState<any>(0);
    const [saveFormValuesOnNext, setSaveFormValuesOnNext] = useState<any>(0);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState<any>(false);
    const [showConfirmAdditionalModal, setShowConfirmAdditionalModal] = useState<any>(true);

    const [includeEducationDate, setIncludeEducationDate] = useState<boolean>(false);

    const [helpModalVisible, setHelpModalVisible] = useState(false);
    const isTravelAdmin = TravelWarrantRights.canCreateTravelWarrantAdmin();

    const initModal = () => {
        getModuleAccessRights();
        getAppSettings();
        getDefaultStartingPoint();
    };

    useEffect(() => {
        initModal();
    }, []);

    useEffect(() => {
        if (employee) {
            setEmployeeValues(employee);
        }
    }, [employee]);

    const setEmployeeFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'moduleAccessRightReadOnly', condition: 'equals', value: 1 },
        ];
    };

    useEffect(() => {
        if (waypoints.length > 0) {
            let newData = [...travelWarrantAdditionalInfo];
            setTravelWarrantAdditionalInfo(
                waypoints
                    .filter(
                        (waypoint: any) =>
                            waypoint?.id > 0 &&
                            waypoint?.shortName != '' &&
                            waypoint.placeId != undefined &&
                            waypoint?.id < 9000
                    )
                    .map((item: any) => {
                        const destinationExists: any = newData.find(
                            (dest: any) => dest?.destination == item?.shortName
                        );
                        if (destinationExists && destinationExists?.destination != '') {
                            return destinationExists;
                        } else if (item?.shortName != '') {
                            return { destination: item?.shortName };
                        }
                    })
            );
            setRefreshAdditionalInfo((refreshAdditionalInfo: number) => refreshAdditionalInfo + 1);
        }
    }, [waypoints]);

    useEffect(() => {
        if (visible && travelWarrantData && !dataForm) {
            let data = _.cloneDeep(travelWarrantData);
            if (data.travelWarrantAdditionalInfoList) {
                setTravelWarrantAdditionalInfo(data.travelWarrantAdditionalInfoList);
            }
            if (data.startDate) {
                data.startDate = setDateFormat(data.startDate, 'yyyy-MM-DD HH:mm');
            }
            if (data.endDate) {
                data.endDate = setDateFormat(data.endDate, 'yyyy-MM-DD HH:mm');
            }
            if (data.calculationDate) {
                data.calculationDate = setDateFormat(data.calculationDate, 'yyyy-MM-DD');
            }
            delete data.employee;
            if (data.travelWarrantEmployee) {
                setEmployeeValues(data.travelWarrantEmployee);
            }
            if (data.travelWarrantPlans) {
                setTravelWarrantPlans(data.travelWarrantPlans);
            }
            if (data.maxWaypointsKey) {
                setMaxWaypointsKey(data.maxWaypointsKey);
            }
            if (data.waypoints) {
                setWaypoints(data.waypoints);
            }
            if (data.destination) {
                setDestination(data.destination);
            }
            if (data.startingPoint) {
                setStartingPoint(data.startingPoint);
            }
            if (data.travelVehicle) {
                setTravelVehicle(data.travelVehicle);
                setIsPlane(
                    data.vehicleType && (data.vehicleType === 3 || data.vehicleType === 4 || data.vehicleType === 6)
                );
                setIsPersonalOrOfficial(data.vehicleType && (data.vehicleType === 1 || data.vehicleType === 2));
                if (data.travelVehicle2) {
                    setShowAnotherVehicle(true);
                }
            }

            if (data.accountingCostObject) {
                data.accountingCostCenter = setSearchFormat(data.accountingCostCenter, 'name', 'name', 'code');
            }
            if (data.accountingCostCenter) {
                data.accountingCostCenter = setSearchFormat(data.accountingCostCenter, 'name', 'name', 'code');
            }

            setGlobalForm(data);
            form.setFieldsValue(data);
        }
    }, [visible]);

    useEffect(() => {
        if (dataForm != null && dataForm != undefined && dataForm > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + dataForm).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setTravelWarrantValues(result.data);
                    setValuesFiles(dataForm);
                }
            });
        }
    }, [dataForm]);

    useEffect(() => {
        if (dataForm) {
            setValuesFiles(dataForm);
        }
    }, [refreshFiles]);

    const setDefaultFilterValue = () => {
        if (employeeId !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const setDefaultFilterValuePersonalOrOfficial = () => {
        if (employeeId !== null) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employeeId', condition: 'equals', value: employeeId },
                { field: 'vehicleType', condition: 'in', value: '1,2' },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'vehicleType', condition: 'in', value: '1,2' },
            ];
        }
    };

    const setDefaultFilterValueAppSettingsFiles = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' }];
    };

    const setDefaultFilterValueFiles = (travelWarrantId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: dataForm },
        ];
    };

    const setDestinationValues = (destinationJson: any) => {
        let waypointsList: any = [];

        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);
        setTravelWarrantPlans(waypointsList);
    };

    const setInitialWaypointOnSetValues = (destinationJson: any) => {
        let waypointsList: any = [];

        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );
        setInitialWaypoints(waypointsList);
    };

    const setTravelWarrantValues = (data: any) => {
        setLoading(true);
        if (data.travelWarrantAdditionalInfoList) {
            setTravelWarrantAdditionalInfo(data.travelWarrantAdditionalInfoList);
        }
        if (data.ordinal) {
            setOrdinal(data.ordinal);
        }
        if (data.startDate) {
            data.startDate = setDateFormat(data.startDate, 'yyyy-MM-DD HH:mm');
        }
        if (data.endDate) {
            data.endDate = setDateFormat(data.endDate, 'yyyy-MM-DD HH:mm');
        }
        if (data.calculationDate) {
            data.calculationDate = setDateFormat(data.calculationDate, 'yyyy-MM-DD');
        }
        if (data.travelVehicle) {
            if (data.travelVehicle.vehicleType == 8) {
                setNeedTransport(setEnumFormat('TRAVEL_NEED_TRANSPORT', 3));
            }
            setIsPlane(
                data.travelVehicle.vehicleType &&
                    (data.travelVehicle.vehicleType === 3 ||
                        data.travelVehicle.vehicleType === 4 ||
                        data.travelVehicle.vehicleType === 6)
            );
            setIsPersonalOrOfficial(
                data.travelVehicle.vehicleType &&
                    (data.travelVehicle.vehicleType === 1 || data.travelVehicle.vehicleType === 2)
            );
            data.travelVehicle = setSearchFormat(data.travelVehicle, 'name', 'name');
            setTravelVehicle(data.travelVehicle);
        }
        if (data.travelVehicle2) {
            data.travelVehicle2 = setSearchFormat(data.travelVehicle2, 'name', 'name');
            setShowAnotherVehicle(true);
        }
        if (data.employee && data.employee.id) {
            setTravelWarrantEmployee(data.employee);
            if (data.employee.person) setPerson(data.employee.person);
            data.employee = getEmployeeSelectOption(data.employee);
        }
        if (data.advancePaymentCurrency) {
            data.advancePaymentCurrency = setSearchFormat(data.advancePaymentCurrency, 'isoCode');
        }
        if (data.accountingCostCenter) {
            data.accountingCostCenter = setSearchFormat(data.accountingCostCenter, 'name', 'name', 'code');
        }
        if (data.accountingCostObject) {
            data.accountingCostObject = setSearchFormat(data.accountingCostObject, 'name', 'name', 'code');
        }

        if (data.travelWarrantPlans) {
            setTravelWarrantPlans(data.travelWarrantPlans);
        }
        if (data.destination) {
            setDestination(data.destination);
        }
        if (data.destinationJson) {
            setInitialWaypointOnSetValues(JSON.parse(data.destinationJson));
            setDestinationData(data.destinationJson);
            setDestinationValues(JSON.parse(data.destinationJson));
        }
        if (data.tourJson) {
            setTourJsonData(data.tourJson);
        }
        if (data.employee) {
            employeeOnChange(data.employee, data.accountingCostCenter);
        }
        setGlobalForm(data);
        form.setFieldsValue(data);
        setLoading(false);
    };

    const setEmployeeValues = (employeeData: any) => {
        setEmployeeId(employeeData?.id);
        let formUser: any = {};
        formUser.employee = {};
        formUser.employee = getEmployeeSelectOption(employeeData);
        if (employeeData && employeeData.vocation && employeeData.vocation.name) {
            formUser.employeeVocation = employeeData.vocation.name;
        }
        if (employeeData && employeeData?.businessUnit && employee?.businessUnit?.accountingCostCenter) {
            formUser.accountingCostCenter = setSearchFormat(
                employeeData.businessUnit.accountingCostCenter,
                'name',
                'name',
                'code'
            );
        }
        form.setFieldsValue(formUser);
        if (employeeData && employeeData.person) setPerson(employeeData.person);
    };

    function setValuesFiles(travelWarrantId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(travelWarrantId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
    }

    const getDefaultStartingPoint = async () => {
        let waypointsTemp: any = [];
        if (dataForm) {
            waypointsTemp = initialWaypoints;
        } else if (!dataForm && travelWarrantData?.waypoints?.length > 0 && travelWarrantData?.waypoints[0]?.placeId) {
            waypointsTemp = travelWarrantData.waypoints;
        } else {
            try {
                waypointsTemp = [JSON.parse(travelWarrantStartingPoint)];
            } catch {}
            if (waypointsTemp.length === 0) {
                waypointsTemp.push({ id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' });
            }
            waypointsTemp.push({ id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' });

            let destinationName = '';
            const newData = { ...globalForm };
            newData.startingPoint = destinationName;
            newData.destination = destinationName;
            newData.destinationInput = destinationName;
            setDestination(destinationName);
            form.setFieldsValue({ destination: destinationName });
            setGlobalForm(newData);
        }

        setDefaultWaypoints(waypointsTemp.slice());
        setWaypoints(JSON.parse(JSON.stringify(waypointsTemp)));
    };

    async function getCurrencyIsoCode(code: any) {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.ISO_CODE, { code }).then((result: any) => {
            if (result.data.id) {
                form.setFieldsValue({ advancePaymentCurrency: setSearchFormat(result.data, 'isoCode') });
                setCurrency({ id: result.data.id, text: result.data.isoCode });
                getExchangeRate();
            } else {
                form.setFieldsValue({ advancePaymentCurrency: setSearchFormat({ id: 0, text: code }) });
                setCurrency({ id: 0, text: code });
            }
        });
    }

    const getAppSettings = () => {
        getCurrencyIsoCode(defaultCurrencyIsoCode);
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });
            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
        if (current == 2 && !dataForm) {
            setSaveFormValuesOnNext((saveFormValuesOnNext: number) => saveFormValuesOnNext + 1);
        }
        if (dataForm == null) {
            let values = _.cloneDeep(globalForm);
            if (values) {
                if (values.startDate) {
                    values.startDate = getDateFormat(values.startDate, 'yyyy-MM-DD');
                }
                if (values.endDate) {
                    values.endDate = getDateFormat(values.endDate, 'yyyy-MM-DD');
                }
                delete values.employee;
                const travelWarrantDataa = {
                    ...values,
                    travelWarrantEmployee: travelWarrantEmployee,
                    travelWarrantPlans: travelWarrantPlans,
                    maxWaypointsKey: maxWaypointsKey,
                    waypoints: waypoints,
                    travelWarrantAdditionalInfoList: travelWarrantAdditionalInfo,
                };
                dispatch(
                    setTravelWarrantData({
                        travelWarrantData: travelWarrantDataa,
                    })
                );
            }
        }
    };

    const handleReset = () => {
        setGlobalForm(undefined);
        setTravelWarrantPlans([]);
        setAccountingCostCenterRequired(showAccountingCostCenter == '1');
        setAccountingCostObjectRequired(showAccountingCostCenter != '1' && showAccountingCostObject == '1');
        setFiles([]);
        setIsPlane(false);
        setNeedTransport(undefined);
        setIsPersonalOrOfficial(false);
        setShowAnotherVehicle(false);
        setTravelVehicle(undefined);
        setOrdinal(null);
        form.resetFields();
        dispatch(travelWarrantInit());
        initModal();
        if (employee) {
            setEmployeeValues(employee);
        }
        setCurrent(0);
        getDefaultStartingPoint();
        setTravelWarrantAdditionalInfo([]);
        setRefreshAdditionalInfo(0);
        setSaveFormValuesOnNext(0);
        setDefaultEndDate(null);
    };

    const employeeOnChange = (value: any, accountingCostCenter: any = null) => {
        if (value.id === -1) {
            form.setFieldsValue({ employee: undefined });
        }

        setEmployeeId(null);
        form.setFieldsValue({
            travelVehicle: null,
        });
        if (value && value.id > 0) {
            setEmployeeId(value.id);
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        setTravelWarrantEmployee(result.data);
                        if (accountingCostCenter) {
                            form.resetFields(['employeeVocation']);
                        } else {
                            form.resetFields(['employeeVocation', 'accountingCostCenter']);
                        }
                        if (result?.data?.vocation?.name) {
                            form.setFieldsValue({ employeeVocation: result.data.vocation.name });
                        }
                        if (result?.data?.businessUnit?.accountingCostCenter && !accountingCostCenter) {
                            form.setFieldsValue({
                                accountingCostCenter: setSearchFormat(
                                    result.data.businessUnit.accountingCostCenter,
                                    'name',
                                    'name',
                                    'code'
                                ),
                            });
                        }
                    }
                }
            );
        }
    };

    const onChangeCostCenter = (value: any) => {
        if (value.id > -1) {
            setAccountingCostCenterRequired(true);
            setAccountingCostObjectRequired(false);
        } else if (showAccountingCostObject === '1') {
            setAccountingCostCenterRequired(false);
            setAccountingCostObjectRequired(true);
        }
    };

    const onChangeCostObject = (value: any) => {
        if (value.id > -1) {
            setAccountingCostCenterRequired(false);
            setAccountingCostObjectRequired(true);
        } else if (showAccountingCostCenter === '1') {
            setAccountingCostCenterRequired(true);
            setAccountingCostObjectRequired(false);
        }
    };

    const onChangeDestination = (value: any) => {
        setDestination(value.target.value);
    };

    const calculateTotalAdvancePayment = () => {
        let totalAdvancePayment = 0;
        let advancePaymentExchangeRate = form.getFieldValue(['advancePaymentExchangeRate']);
        let advancePaymentInCurrency = form.getFieldValue(['advancePaymentInCurrency']);
        if (advancePaymentExchangeRate !== undefined && advancePaymentInCurrency !== undefined) {
            totalAdvancePayment = advancePaymentInCurrency * advancePaymentExchangeRate;
            form.setFieldsValue({ totalAdvancePayment: totalAdvancePayment });
        }
    };

    const paymentOnChange = () => {
        calculateTotalAdvancePayment();
    };

    const currencyOnChange = (value: any) => {
        if (value && value.id > 0) {
            setCurrency(value);
            getExchangeRate();
        }
    };

    const getExchangeRate = () => {
        let currency = form.getFieldValue(['advancePaymentCurrency']);

        if (currency) {
            var parms: any = { currencyId: currency.id, date: getDateFormat(moment()) };
            NyRequestResolver.requestGet(CONSTANTS_REQ.EXCHANGE_RATE.GET_EXCHANGE_RATE, parms).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    if (response.data && response.data.middleRate) {
                        form.setFieldsValue({ advancePaymentExchangeRate: response.data.middleRate });
                        calculateTotalAdvancePayment();
                    }
                } else {
                    form.setFieldsValue({ advancePaymentExchangeRate: 0 });
                    errorCustomNotification(geti18nText('exchangeRate.not.found'), '');
                }
            });
        }
    };

    const onStartDateChange = (value: any) => {
        if (form.getFieldValue('endDate')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('endDate').startOf('day').diff(value.startOf('day'), 'days') + 1),
            });
        }
        setRerenderKey((prevKey: any) => prevKey + 1);
        setDefaultEndDate(value);
    };

    const onEndDateChange = (value: any) => {
        if (form.getFieldValue('startDate')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('startDate').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const normalizeValues = (values: any) => {
        if (waypoints[0]?.shortName) {
            values.startingPoint = waypoints[0]?.shortName;
        }

        if (ordinal != null) {
            values.ordinal = ordinal;
        }
        if (values.travelVehicle) {
            values.travelVehicle = getSearchFormat(values.travelVehicle);
        }
        if (
            vehicle2Enabled === '1' &&
            values.travelVehicle &&
            !isPersonalOrOfficial &&
            showAnotherVehicle &&
            values.travelVehicle2
        ) {
            values.travelVehicle2 = getSearchFormat(values.travelVehicle2);
        } else {
            values.travelVehicle2 = undefined;
        }
        if (values.employee) {
            values.employee = getSearchFormat(values.employee);
        }
        if (values.startDate) {
            values.startDate = getDateFormat(moment(values.startDate).format('yyyy-MM-DD'));
        }
        if (values.endDate) {
            values.endDate = getDateFormat(moment(values.endDate).format('yyyy-MM-DD'));
        }
        values.warrantDate = getDateFormat(moment());
        if (values.accountingCostCenter && showAccountingCostCenter === '1') {
            values.accountingCostCenter = getSearchFormat(values.accountingCostCenter);
        }
        if (values.accountingCostObject && showAccountingCostObject === '1') {
            values.accountingCostObject = getSearchFormat(values.accountingCostObject);
        }
        if (values.advancePaymentCurrency) {
            values.advancePaymentCurrency = getSearchFormat(values.advancePaymentCurrency);
        }
        if (values.educationStartDate) {
            values.educationStartDate = getDateFormat(moment(values.educationStartDate).format('yyyy-MM-DD'));
        }
        if (values.educationEndDate) {
            values.educationEndDate = getDateFormat(moment(values.educationEndDate).format('yyyy-MM-DD'));
        }
        if (values.travelWarrantType) {
            values.travelWarrantType = { id: values.travelWarrantType.id };
        }
        // values.status = travelWarrantStatusEnum.WAITING_FOR_APPROVAL;

        const travelWarrantPlansList: any = [];
        const newData = [...travelWarrantPlans];
        let tourJson: any = [];
        if (newData !== undefined && newData.length > 0) {
            var index = 0;
            newData.map((element: any) => {
                const nextItem = newData[index + 1];
                if (nextItem != undefined) {
                    const object = newData[index + 1];
                    let planItem: any = {};
                    if (object.countryId) planItem.country = getSearchFormat(object.countryId);
                    if (element.shortName && object.shortName)
                        planItem.destination = element.shortName + ' - ' + object.shortName;
                    if (values.travelVehicle) planItem.travelVehicle = values.travelVehicle;
                    if (element && object) planItem.relationJson = JSON.stringify([element, object]);
                    tourJson.push([element, object]);
                    travelWarrantPlansList.push(planItem);
                }
                index += 1;
            });
            values.tourJson = JSON.stringify(tourJson);
        } else {
            values.tourJson = tourJsonData;
        }

        values.destinationJSon = values.destinationJSon ? values.destinationJSon : destinationData;
        values.travelWarrantPlans = travelWarrantPlansList;
        values.travelWarrantWageExpenses = [];
        values.travelWarrantAdditionalInfoList = [...travelWarrantAdditionalInfo];

        if (values.travelEventNumber?.id < 0) {
            delete values.travelEventNumber;
        }

        if (values.advancePaymentInCurrency == 0) {
            values.totalAdvancePayment = undefined;
        }
        return values;
    };

    const closeModal = (result: any) => {
        if (id == 'create') {
            history.goBack();
        }
        setRefresh((refresh: number) => refresh + 1);
        setAdminRefresh && setAdminRefresh((adminRefresh: number) => adminRefresh + 1);
        setVisible(false);
        setGlobalForm(undefined);
        setTravelWarrantPlans([]);
        setAccountingCostCenterRequired(showAccountingCostCenter == '1');
        setAccountingCostObjectRequired(showAccountingCostCenter != '1' && showAccountingCostObject == '1');
        setFiles([]);
        setIsPlane(false);
        setNeedTransport(undefined);
        setIsPersonalOrOfficial(false);
        setShowAnotherVehicle(false);
        setOrdinal(null);
        okNotification();
        form.resetFields();
        dispatch(travelWarrantInit());
        setTourJsonData([]);
        setDestinationData([]);
        setTravelWarrantAdditionalInfo([]);
        setRefreshAdditionalInfo(0);
        setSaveFormValuesOnNext(0);
        setIncludeEducationDate(false);
    };

    const saveWarrant = (values: any) => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT, undefined, values).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (uploadFilesRef?.current && result?.data?.id) {
                    uploadFilesRef.current.filesUpload(result.data.id);
                }
                closeModal(result);
            } else {
                errorNotification();
            }
            setLoading(false);
        });
    };

    const editWarrant = (values: any, status: number) => {
        values.id = dataForm;
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + dataForm, undefined, values).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    if (uploadFilesRef?.current && result?.data?.id) {
                        uploadFilesRef.current.filesUpload(result.data.id);
                    }
                    NyRequestResolver.requestPut(
                        CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + values.id,
                        undefined,
                        {
                            id: values.id,
                            status: status,
                            mail: false,
                        }
                    ).then((result: any) => {
                        if (result.status === RESPONSE.OK) {
                            setRefresh((refresh: number) => refresh + 1);
                            okNotification(geti18nText('travelWarrantPersonal.sendToApproval.ok'));
                        } else {
                            if (result.data && result.data.error) {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification();
                            }
                        }
                    });
                    closeModal(result);
                } else {
                    errorNotification();
                }
                setLoading(false);
            }
        );
    };

    const saveTravelWarrant = (status: any) => {
        const fileUploaded = files && files[0] && files.length > 0 ? true : false;
        if (mandatoryFile == 1 && !fileUploaded) {
            warningNotification(
                geti18nText('travelWarrantPersonal.notification.files'),
                geti18nText('travelWarrantPersonal.notification.files.explained')
            );
        } else {
            let values = normalizeValues(globalForm);
            if (dataForm != null && dataForm != undefined && dataForm > 0) {
                setLoading(true);
                editWarrant(values, status);
            } else {
                setLoading(true);
                values.status = status;
                saveWarrant(values);
            }
        }
    };

    const onTravelVehicleChange = (value: any) => {
        if (value != undefined && value.id >= 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_VEHICLE.EDIT + '/' + value.id).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    if (result.data) {
                        const vehicleType = result.data.vehicleType;
                        if (result.data.vehicleType == 8) {
                            setNeedTransport(setEnumFormat('TRAVEL_NEED_TRANSPORT', 3));
                        }
                        setIsPlane(
                            result.data.vehicleType &&
                                (result.data.vehicleType === 3 ||
                                    result.data.vehicleType === 4 ||
                                    result.data.vehicleType === 6)
                        );

                        if (
                            result.data.vehicleType &&
                            (result.data.vehicleType === 1 || result.data.vehicleType === 2)
                        ) {
                            setIsPersonalOrOfficial(true);
                        } else {
                            setIsPersonalOrOfficial(false);
                            setShowAnotherVehicle(false);
                            form.setFieldsValue({ travelVehicle2: undefined });
                        }
                        result.data = setSearchFormat(result.data, 'name', 'name');
                        setTravelVehicle(result.data);
                        form.setFieldsValue({ travelVehicle: result.data, vehicleType: vehicleType });
                    }
                }
            });
        } else {
            setTravelVehicle(undefined);
            setIsPersonalOrOfficial(false);
            setShowAnotherVehicle(false);
            form.setFieldsValue({ travelVehicle2: undefined, vehicleType: undefined });
        }
    };

    const disabledStartDate = (current: any) => {
        if (form.getFieldValue('endDate')) {
            return current > moment(form.getFieldValue('endDate')).endOf('day');
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('startDate')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('startDate')).endOf('day');
        }
    };

    const steps = [
        {
            title: geti18nText('travelWarrantWizard.step.1'),
            content: (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.employee')}
                                    name="employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    style={{ fontWeight: '500' }}
                                    tooltip={geti18nText('travelWarrantPersonal.edit.employee.tooltip')}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.TRAVEL_SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            employmentRecordId: 'employmentRecordId',
                                            businessUnit: 'businessUnit',
                                        }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={
                                            <EmployeeIndex
                                                customFilter={setEmployeeFilterValue}
                                                disabled={true}
                                                filteredForTravel
                                                parentKey="wizard-new"
                                            />
                                        }
                                        nyTestId="employee"
                                        onChange={employeeOnChange}
                                        renderOption={customEmployeeRenderOption}
                                        disabled={
                                            moduleAccessRight == null && !moduleAccessRight.includes('TRAVEL_WARRANT')
                                        }
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('employee.edit.professional.vocation')}
                                    name="employeeVocation"
                                    style={{ fontWeight: '500' }}
                                    tooltip={geti18nText('employee.edit.professional.vocation.tooltip')}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            {showAccountingCostCenter === '1' && (
                                <Col span={24}>
                                    <CostCenterSearch
                                        label={geti18nText('travelWarrant.edit.cost.center')}
                                        name="accountingCostCenter"
                                        AddNewModalComponent={isAdmin() ? CostCenterEdit : null}
                                        rules={
                                            accountingCostCenterRequired
                                                ? [
                                                      {
                                                          required: accountingCostCenterRequired,
                                                          message: geti18nText('app.default.required'),
                                                      },
                                                      {
                                                          validator: validateObjectId,
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          required: accountingCostCenterRequired,
                                                          message: geti18nText('app.default.required'),
                                                      },
                                                  ]
                                        }
                                        onChange={(data: any) => {
                                            onChangeCostCenter(data);
                                        }}
                                        formStyle={{ fontWeight: '500' }}
                                        tooltip={geti18nText('travelWarrant.edit.cost.center.tooltip')}
                                    />
                                </Col>
                            )}
                            {showAccountingCostObject === '1' && (
                                <Col span={24}>
                                    <CostObjectSearch
                                        label={geti18nText('travelWarrant.edit.cost.object')}
                                        name="accountingCostObject"
                                        AddNewModalComponent={isAdmin() ? CostObjectEdit : null}
                                        rules={
                                            accountingCostObjectRequired
                                                ? [
                                                      {
                                                          required: accountingCostObjectRequired,
                                                          message: geti18nText('app.default.required'),
                                                      },
                                                      {
                                                          validator: validateObjectId,
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          required: accountingCostObjectRequired,
                                                          message: geti18nText('app.default.required'),
                                                      },
                                                  ]
                                        }
                                        onChange={(data: any) => {
                                            onChangeCostObject(data);
                                        }}
                                        formStyle={{ fontWeight: '500' }}
                                        tooltip={geti18nText('travelWarrant.edit.cost.object.tooltip')}
                                    />
                                </Col>
                            )}
                            <Col span={24}>
                                <TravelEventNumberSearch
                                    formStyle={{ fontWeight: '500' }}
                                    tooltip={geti18nText('travelWarrant.edit.travelEventNumber.tooltip')}
                                />
                            </Col>
                        </Col>
                        <Col span={12}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.startDate')}
                                    name="startDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    initialValue={moment().startOf('day')}
                                    style={{ fontWeight: '500' }}
                                    tooltip={geti18nText('travelWarrantPersonal.edit.startDate.tooltip')}
                                >
                                    <NyDatePicker
                                        autoFocus
                                        style={{ width: '100%' }}
                                        onChange={onStartDateChange}
                                        nyTestId="start-date"
                                        disabledDate={disabledStartDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.endDate')}
                                    name="endDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    style={{ fontWeight: '500' }}
                                    // initialValue={moment().startOf('day')}
                                    tooltip={geti18nText('travelWarrantWizard.table.column.endDate.tooltip')}
                                >
                                    <NyDatePicker
                                        defaultPickerValue={defaultEndDate}
                                        key={rerenderKey}
                                        style={{ width: '100%' }}
                                        onChange={onEndDateChange}
                                        nyTestId="end-date"
                                        disabledDate={disabledendDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.duration')}
                                    name="duration"
                                    initialValue={1}
                                    style={{ fontWeight: '500' }}
                                    tooltip={geti18nText('travelWarrantPersonal.edit.duration.tooltip')}
                                >
                                    <NyInputNumber
                                        isDecimal={false}
                                        style={{ width: '100%' }}
                                        min={1}
                                        max={30}
                                        nyTestId="duration"
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.purposeInfo')}
                                    name="purposeInfo"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                    style={{ fontWeight: '500' }}
                                    tooltip={geti18nText('travelWarrantPersonal.edit.purposeInfo.tooltip')}
                                >
                                    <Input.TextArea
                                        autoSize={{ minRows: 2, maxRows: 3 }}
                                        onKeyDown={onKeyDownTextAreaCustom}
                                    />
                                </Form.Item>
                            </Col>
                            {/* {TravelWarrantTypeComponent(form, setIncludeEducationDate, includeEducationDate)} */}
                        </Col>
                    </Row>
                </>
            ),
            key: WIZARD_STEP.BASIC,
        },
        {
            title: geti18nText('travelWarrantWizard.step.2'),
            content: (
                <>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('travelWarrantWizard.table.column.vehicle.info')}
                                type="info"
                                showIcon
                                style={{
                                    marginBottom: '5px',
                                    marginLeft: '12px',
                                    marginRight: '12px',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Col span={24}>
                                <Form.Item name="vehicleType" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantPersonal.edit.travelVehicle')}
                                    name="travelVehicle"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                        {
                                            validator: validateObjectId,
                                        },
                                    ]}
                                    style={{ fontWeight: '500' }}
                                    tooltip={geti18nText('travelWarrantPersonal.edit.travelVehicle.tooltip')}
                                >
                                    <NySearchField
                                        autoFocus
                                        url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'name',
                                        }}
                                        searchBy="name"
                                        itemName="model"
                                        SearchPopupComponent={
                                            <TravelVehiclePersonalIndex
                                                vehicleType={null}
                                                newButtonTextPersonal={true}
                                                employeeId={employeeId}
                                                sort={true}
                                            />
                                        }
                                        AddNewModalComponent={TravelVehiclePersonalEdit}
                                        nyTestId="travel-vehicle"
                                        setDefaultFilterValue={setDefaultFilterValue}
                                        onChange={onTravelVehicleChange}
                                        order="sort"
                                        orderType="asc"
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={12}>
                            <Col span={24}>
                                <div style={{ width: '100%' }}>
                                    <Form.Item
                                        style={{ width: '93%', float: 'left', fontWeight: '500' }}
                                        label={geti18nText('travelWarrantPersonal.edit.destination')}
                                        name="destination"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        tooltip={geti18nText('travelWarrantPersonal.edit.destination.tooltip')}
                                    >
                                        <Input onChange={onChangeDestination} disabled={!isTravelAdmin} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '3%', float: 'left', marginTop: '1px' }}
                                        label={' '}
                                        name="destinationInput"
                                    >
                                        <TravelWarrantMapModal
                                            form={form}
                                            waypoints={waypoints}
                                            setWaypoints={setWaypoints}
                                            maxWaypointsKey={maxWaypointsKey}
                                            setMaxWaypointsKey={setMaxWaypointsKey}
                                            setDestination={setDestination}
                                            setTravelWarrantPlans={setTravelWarrantPlans}
                                            modalHeader={geti18nText('travelWarrantPersonal.edit.destination')}
                                            index={false}
                                            isPlane={isPlane}
                                            showInfoMessage={true}
                                            isWizard
                                            getDefaultStartingPoint={getDefaultStartingPoint}
                                            showCheckboxesDestinationName={true}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                    {vehicle2Enabled === '1' && travelVehicle && !isPersonalOrOfficial && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('travel.warrant.edit.use.another.vehicle')}
                                        name="showAnotherVehicle"
                                        style={{ fontWeight: '500' }}
                                    >
                                        <Checkbox
                                            checked={showAnotherVehicle}
                                            onChange={(event: any) => setShowAnotherVehicle(event?.target.checked)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            {showAnotherVehicle && (
                                <Col span={12}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={geti18nText('travelWarrant.edit.travelVehicle2')}
                                            name="travelVehicle2"
                                            style={{ fontWeight: '500' }}
                                        >
                                            <NySearchField
                                                autoFocus
                                                url={CONSTANTS_REQ.TRAVEL_VEHICLE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'name',
                                                }}
                                                searchBy="name"
                                                SearchPopupComponent={
                                                    <TravelVehiclePersonalIndex
                                                        vehicleType={[1, 2]}
                                                        newButtonTextPersonal={true}
                                                        employeeId={employeeId}
                                                        sort={true}
                                                    />
                                                }
                                                AddNewModalComponent={TravelVehiclePersonalEdit}
                                                nyTestId="travel-vehicle2"
                                                setDefaultFilterValue={setDefaultFilterValuePersonalOrOfficial}
                                                order="sort"
                                                orderType="asc"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Col>
                            )}
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('travelWarrantWizard.table.column.other.vehicle.info')}
                                type="info"
                                showIcon
                                style={{
                                    marginBottom: '5px',
                                    marginLeft: '12px',
                                    marginRight: '12px',
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.other.vehicle')}
                                    name={'otherVehicle'}
                                    style={{ fontWeight: '500' }}
                                >
                                    <TextArea rows={3}></TextArea>
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                </>
            ),
            key: WIZARD_STEP.DESTINATION_AND_TRANSPORT,
        },
        {
            title: geti18nText('travelWarrantWizard.step.3'),
            content: (
                <TravelWarrantAdditionalInfoIndex
                    data={travelWarrantAdditionalInfo}
                    setData={setTravelWarrantAdditionalInfo}
                    refresh={refreshAdditionalInfo}
                    saveFormValuesOnNext={saveFormValuesOnNext}
                    setSaveFormValuesOnNext={setSaveFormValuesOnNext}
                    travelWarrantEmployeeId={employeeId}
                    isPlane={isPlane}
                    //needTransport={needTransport}
                />
            ),
            key: WIZARD_STEP.ADDITIONAL_TRAVEL_ORGANIZATION_DATA,
        },
        {
            title: geti18nText('travelWarrantWizard.step.4'),
            content: (
                <Row gutter={24}>
                    <Form.Item name="advancePaymentExchangeRate" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="totalAdvancePayment" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={12}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantPersonal.edit.advancePaymentInCurrency', [
                                    defaultCurrencyIsoCode,
                                ])}
                                name="advancePaymentInCurrency"
                                style={{ fontWeight: '500' }}
                                tooltip={geti18nText('travelWarrantPersonal.edit.advancePaymentInCurrency.tooltip')}
                            >
                                <NyInputNumber
                                    autoFocus
                                    isDecimal={true}
                                    style={{ width: '100%' }}
                                    onChange={paymentOnChange}
                                    max={999999.99}
                                />
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={24} style={{ display: 'none' }}>
                            <CurrencySearch
                                label={geti18nText('travelWarrantPersonal.edit.advancePaymentCurrency')}
                                name="advancePaymentCurrency"
                                onChange={currencyOnChange}
                                nyTestId="advance-currency"
                                noClassName={true}
                                readonly={true}
                            />
                        </Col>
                    </Col>
                    <Col span={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('travelWarrantPersonal.edit.advancePaymentComment')}
                                name={'advancePaymentComment'}
                                labelAlign={'left'}
                                style={{ fontWeight: '500' }}
                                tooltip={{
                                    title: geti18nText('travelWarrantPersonal.edit.advancePaymentComment.tooltip'),
                                    overlayStyle: { whiteSpace: 'pre-wrap' },
                                }}
                            >
                                <TextArea rows={4} maxLength={300} />
                            </Form.Item>
                        </Col>
                    </Col>
                </Row>
            ),
            key: WIZARD_STEP.ADVANCE,
        },
        {
            title: geti18nText('travelWarrantWizard.step.5'),
            content: (
                <>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                message={
                                    travelWarrantShowEducation
                                        ? geti18nText('travelWarrantWizard.table.column.files.education')
                                        : geti18nText('travelWarrantWizard.table.column.files')
                                }
                                type="info"
                                showIcon
                                style={{ marginBottom: '5px' }}
                            />
                            <Alert
                                message={geti18nText('travelWarrantWizard.table.column.files.deliver')}
                                type="warning"
                                showIcon
                                style={{ marginBottom: '5px' }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="file"
                                rules={[
                                    {
                                        required: mandatoryFile == 1 && files && files.length <= 0 ? true : false,
                                        message: geti18nText('travelWarrantPersonal.notification.files'),
                                    },
                                ]}
                            >
                                <NyFilesUpload
                                    documentType={documentTypeEnum.TRAVEL_WARRANT_FILE}
                                    editUrl={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.EDIT}
                                    dataSourceFiles={files}
                                    setDataSourceFiles={setFiles}
                                    ref={uploadFilesRef}
                                    canAddNewFile={
                                        travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL ||
                                        !travelWarrantStatus
                                    }
                                    canEdit={
                                        travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL ||
                                        !travelWarrantStatus
                                    }
                                    id={dataForm}
                                    refId={dataForm}
                                    employee={employee}
                                    employeeTravelWarrantId={employeeId}
                                    showDocumentTypeColumn={true}
                                    isTravelWarrantEdit={true}
                                    maxHeight={'205px'}
                                    isTravelWarrant={true}
                                    moduleAccessRight={moduleAccessRight}
                                    setRefreshDataSourceFiles={dataForm ? setRefreshFiles : null}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('travelWarrantWizard.table.column.annotation')}
                                name={['note', 'note']}
                                labelAlign={'left'}
                                style={{ fontWeight: '500' }}
                            >
                                <TextArea autoFocus rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
            key: WIZARD_STEP.FILES_AND_NOTES,
        },
        {
            title: geti18nText('travelWarrantWizard.step.6'),
            content: (
                <TravelWarrantSummaryIndex
                    componentRefProp={componentRef}
                    printPdfProp={printPdf}
                    setPrintPdfProp={setPrintPdf}
                    employeeTravelWarrant={travelWarrantEmployee}
                    globalForm={globalForm}
                    formSummary={formSummary}
                    isPlane={isPlane}
                    currency={currency}
                    files={files}
                    setFiles={setFiles}
                    isUploaded={false}
                    printPdfHeader={geti18nText('travelWarrantWizard.header')}
                    showPrintButton={false}
                    setRefreshFiles={setRefreshFiles}
                    travelWarrantStatus={travelWarrantStatus}
                    isNewWizard={true}
                    travelWarrantAdditionalInfo={travelWarrantAdditionalInfo}
                ></TravelWarrantSummaryIndex>
            ),
            key: WIZARD_STEP.SUMMARY,
        },
    ];

    const next = () => {
        form.validateFields()
            .then((values: any) => {
                const newData = { ...globalForm, ...values };
                newData.startingPoint = waypoints[0].shortName;
                setGlobalForm(newData);
                setCurrent(current + 1);
                if (current == 1 && showConfirmAdditionalModal) {
                    setConfirmationModalVisible(true);
                    setShowConfirmAdditionalModal(false);
                }
            })
            .catch((errorInfo: any) => {
                warningNotification(
                    geti18nText('travelWarrantPersonal.notification.form'),
                    geti18nText('travelWarrantPersonal.notification.form.explained')
                );
            });

        if (current == 2) {
            setSaveFormValuesOnNext((saveFormValuesOnNext: number) => saveFormValuesOnNext + 1);
        }
    };

    const prev = () => {
        if (current == 2) {
            setSaveFormValuesOnNext((saveFormValuesOnNext: number) => saveFormValuesOnNext + 1);
            const timeout = setTimeout(() => {
                setCurrent(current - 1);
            }, 1000);
        } else {
            setCurrent(current - 1);
        }
    };

    const onChange = (value: number) => {
        setCurrent(value);
    };

    const openAdminForm = () => {
        setVisible(false);
        if (dataForm) {
            history.push('/travel-warrant/travelWarrant/' + dataForm.toString());
        } else {
            history.push('/travel-warrant/travelWarrant/create');
        }
    };

    const getCustomFooterContent = (
        <div style={{ marginBottom: '30px' }}>
            <div style={{ float: 'left' }}>
                {current === 0 && (
                    <Button type="primary" onClick={openAdminForm}>
                        {geti18nText('travelWarrantWizard.skip')}
                    </Button>
                )}
            </div>
            <div style={{ float: 'right' }}>
                <div className="steps-action">
                    {current == 5 && (
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <Button key={'print'} icon={<PrinterOutlined />}>
                                        {geti18nText('travelWarrant.action.pdf.short')}
                                    </Button>
                                );
                            }}
                            onBeforeGetContent={() => {
                                setPrintPdf(true);
                                return Promise.resolve();
                            }}
                            onAfterPrint={() => {
                                setPrintPdf(false);
                            }}
                            content={() => componentRef.current}
                            pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                        />
                    )}
                    <Button onClick={handleReset}>{geti18nText('travelWarrantWizard.reset')}</Button>
                    <Button onClick={handleCancel}>{geti18nText('app.default.button.cancel')}</Button>
                    {current > 0 && (
                        <Button onClick={() => prev()} icon={<CaretLeftOutlined />}>
                            {geti18nText('travelWarrantWizard.previous')}
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            {geti18nText('travelWarrantWizard.next')}
                            <CaretRightOutlined />
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <NyModalConfirm
                            title={geti18nText('travelWarrantPlan.edit.sendToApproval.confirm')}
                            onConfirm={() => {
                                saveTravelWarrant(travelWarrantStatusEnum.DRAFT);
                            }}
                        >
                            <Button style={{ display: 'inline' }} type="primary" loading={loading}>
                                {geti18nText('travelWarrantWizard.saveSketch')}
                            </Button>
                        </NyModalConfirm>
                    )}
                    {current === steps.length - 1 && (
                        <Popover content={geti18nText('webshopItem.edit.status')}>
                            <NyModalConfirm
                                title={geti18nText('travelWarrantPlan.edit.sendToApproval.send')}
                                onConfirm={() => {
                                    saveTravelWarrant(travelWarrantStatusEnum.WAITING_FOR_APPROVAL);
                                }}
                            >
                                <Tooltip
                                    placement="top"
                                    title={geti18nText('travelWarrantWizard.sendForApproval.tooltip')}
                                >
                                    <Button style={{ display: 'inline' }} type="primary" loading={loading}>
                                        {geti18nText('travelWarrantWizard.sendForApproval')}
                                    </Button>
                                </Tooltip>
                            </NyModalConfirm>
                        </Popover>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <>
            {visible && (
                <Modal
                    title={
                        <>
                            <span style={{ fontSize: '15px' }}>{geti18nText('travelWarrantWizard.header')}</span>{' '}
                            {/* <DocumentationModal documentUrl={getNewTravelWizardDocsUrl(steps[current]?.key)} /> */}
                            <div
                                style={{
                                    display: 'block',
                                    float: 'right',
                                    marginRight: '24px',
                                    marginTop: '-2.7px',
                                }}
                            >
                                {getNewTravelWizardDocsUrl(steps[current]?.key) != undefined && (
                                    <Button
                                        onClick={() => setHelpModalVisible(!helpModalVisible)}
                                        icon={<QuestionCircleOutlined />}
                                        style={{ border: 'none' }}
                                    />
                                )}
                            </div>
                        </>
                    }
                    visible={visible}
                    onOk={handleOk}
                    width={helpModalVisible ? 1400 : 900}
                    onCancel={handleCancel}
                    footer={getCustomFooterContent}
                    maskClosable={false}
                >
                    <Row>
                        <Col span={helpModalVisible ? 18 : 24} style={helpModalVisible ? { paddingRight: '10px' } : {}}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    width: '100%',
                                    height: '70vh',
                                }}
                            >
                                <Steps current={current} size="small" progressDot>
                                    {steps.map((item: any) => (
                                        <Step
                                            key={item.title}
                                            title={<div style={{ fontWeight: '500' }}>{item.title}</div>}
                                        />
                                    ))}
                                </Steps>
                                <div className="steps-content">
                                    <Form
                                        layout="vertical"
                                        form={form}
                                        onFinishFailed={({ errorFields }) => {
                                            form.scrollToField(errorFields[0].name);
                                        }}
                                    >
                                        {steps[current].content}
                                    </Form>
                                </div>
                                <div style={{ display: 'none' }}>
                                    <NyFilesUpload
                                        documentType={documentTypeEnum.TRAVEL_WARRANT_FILE}
                                        editUrl={CONSTANTS_REQ.TRAVEL_WARRANT_FILE.EDIT}
                                        dataSourceFiles={files}
                                        setDataSourceFiles={setFiles}
                                        ref={uploadFilesRef}
                                        canAddNewFile={
                                            travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL ||
                                            !travelWarrantStatus
                                        }
                                        canEdit={
                                            travelWarrantStatus != travelWarrantStatusEnum.WAITING_FOR_APPROVAL ||
                                            !travelWarrantStatus
                                        }
                                        id={dataForm}
                                        refId={dataForm}
                                        employee={employee}
                                        employeeTravelWarrantId={employeeId}
                                        showDocumentTypeColumn={true}
                                        isTravelWarrantEdit={true}
                                        maxHeight={'205px'}
                                        isTravelWarrant={true}
                                        moduleAccessRight={moduleAccessRight}
                                        setRefreshDataSourceFiles={dataForm ? setRefreshFiles : null}
                                    />
                                </div>
                            </div>
                        </Col>
                        {helpModalVisible && (
                            <Col span={6}>
                                <div
                                    style={{
                                        maxHeight: '75vh',
                                        overflowY: 'auto',
                                        overflowX: 'auto',
                                    }}
                                >
                                    <iframe
                                        style={{
                                            width: '100%',
                                            height: '50vh',
                                            border: '2px solid #bfbfbf',
                                            backgroundColor: 'white',
                                        }}
                                        src={
                                            NySession.getSetting('docs') +
                                            getNewTravelWizardDocsUrl(steps[current]?.key)
                                        }
                                    ></iframe>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Modal>
            )}
            {confirmationModalVisible && (
                <Modal
                    visible={confirmationModalVisible}
                    onCancel={() => {
                        next();
                        setConfirmationModalVisible(false);
                    }}
                    cancelText={geti18nText('app.default.button.no')}
                    okText={geti18nText('app.default.button.yes')}
                    width={360}
                    onOk={() => {
                        setConfirmationModalVisible(false);
                    }}
                >
                    <Title style={{ marginTop: '12px', marginBottom: '-12px' }} level={5}>
                        <InfoCircleTwoTone
                            style={{ marginRight: '8px', fontSize: '24px', top: '3px', position: 'relative' }}
                        />
                        {geti18nText('travelWarrantWizard.trip.organization.confirm')}
                    </Title>
                </Modal>
            )}
        </>
    );
};

export default TravelWarrantPersonalWizardNew;
