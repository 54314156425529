import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import CostCenterIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const CostCenterSearch = ({
    label = geti18nText('travelWarrant.report.accountingCostCenter'),
    name = 'accountingCostCenter',
    renderOption = (option: any) => `${option.text}${option.code ? ' (' + option.code + ')' : ''}`,
    onChange = null,
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    required,
    validator = null,
    readonly = false,
    addNewItem = false,
    disabled = false,
    AddNewModalComponent = null,
    rules = undefined,
    mustGetPopupContainer = undefined,
    formStyle = undefined,
    tooltip = undefined,
}: any) => {
    const getRules = () => {
        if (rules) {
            return rules;
        } else if (required && validator != null) {
            return [
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
                {
                    validator: validator,
                },
            ];
        } else if (!validator || validator == null) {
            return [
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ];
        }
    };
    return (
        <Form.Item label={label} name={name} rules={getRules()} style={formStyle} tooltip={tooltip}>
            <NySearchField
                url={CONSTANTS_REQ.COST_CENTER.SEARCH}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                searchBy="name,code"
                itemName={[['code'], ['name']]}
                customItemNameLabel={'name (code)'}
                renderOption={renderOption}
                addNewItem={addNewItem}
                SearchPopupComponent={<CostCenterIndex readonly={readonly} />}
                AddNewModalComponent={AddNewModalComponent}
                nyTestId="accounting-cost-center"
                setDefaultFilterValue={setDefaultFilterValue}
                onChange={onChange}
                disabled={disabled}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default CostCenterSearch;
