import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CoffeeOutlined,
    FilePdfOutlined,
    InfoCircleOutlined,
    MedicineBoxOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons';
import { NySpinner, NyUtils, UseRequestSwrGet, geti18nText } from '@nybble/nyreact';
import { Alert, Badge, Card, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HumanResourcesRights } from '../../../rights/humanResourcesRights';
import { RootState } from '../../../rootReducer';
// import { addTab, setActiveTab } from '../../../slices/tabsSlice';
import useEnum from '../../../hooks/useEnum';
import { CONSTANTS_REQ, getReportDocumentExtension } from '../../../utils/Constants';
import { fileDownload, getColorFromNotificationType } from '../../../utils/Utils';
import ReportExportType from '../../../components/report-export-type/ReportExportType';

const { Title, Text } = Typography;

const EmployeeLeavePersonalLightWidget = ({ isOverview = false, setIsVacation, setAddAbsenceModalVisible }: any) => {
    const enExportType = useEnum('EXPORT_TYPE');
    const [exportType, setExportType] = useState(enExportType.PDF);
    const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);
    const history = useHistory();
    // const { active /* , tabs */ } = useSelector((state: RootState) => state.tabs);
    const { settings } = useSelector((state: RootState) => state.notification);
    const { employee } = useSelector((state: RootState) => state.employee);
    const widgetColor = getColorFromNotificationType(settings, 'EMPLOYEE_LEAVE');
    const [lostDays, setLostDays] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeLeave();
    };

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
    } = UseRequestSwrGet(CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.GET_AVAILABLE_DAYS, {
        active: true,
        employeeId: employee?.id,
    });

    useEffect(() => {
        if (statisticData) {
            if (!statisticData.availableDays) statisticData.availableDays = 0;
            if (!statisticData.approvedDays) statisticData.approvedDays = 0;
            if (!statisticData.approvedOldDays) statisticData.approvedOldDays = 0;
            if (!statisticData.usedDays) statisticData.usedDays = 0;
            if (!statisticData.usedOldDays) statisticData.usedOldDays = 0;

            if (statisticData.oldDaysLimit && moment(statisticData.oldDaysLimit).isBefore(moment())) {
                const lostDaysCount = statisticData.approvedOldDays - statisticData.usedDaysOld;
                setLostDays(lostDaysCount >= 0 ? lostDaysCount : 0);
            }
        }
    }, [statisticData]);

    const gridStyle: any = {
        width: '25%',
        cursor: 'pointer',
        fontSize: '2em',
        paddingLeft: '14px',
        paddingTop: '4px',
        height: '100%',
        display: 'inline-block',
    };

    const getDocumentExtension = () => {
        return getReportDocumentExtension(exportType);
    };

    const downloadReport = async () => {
        let parms = {
            lang: NyUtils.getSelectedLanguage(),
            subreportId: undefined,
            useDefault: false,
            exportType: exportType ? exportType : enExportType.PDF,
            ...{
                employeeId: employee && employee.id,
                year: statisticData && statisticData.annualLeaveYear,
            },
        };
        let addition = moment().format('yyyyMMDD');
        const fileDownloadName =
            `${geti18nText('employeeAnnualLeave.edit.title')}_${addition}` + getDocumentExtension();
        fileDownload(CONSTANTS_REQ.REPORT.APPROVED_ANNUAL_LEVE_REPORT, parms, fileDownloadName);
    };

    return (
        <>
            <Card
                className="ny-widget-card"
                headStyle={{ borderBottom: `2px solid ${widgetColor}`, background: `${widgetColor}33` }}
                title={geti18nText('employee.leave.personal')}
                bodyStyle={{ height: '45%', overflowY: 'hidden', overflowX: 'hidden', padding: '5px 5px 0px 5px' }}
                type="inner"
                extra={
                    <>
                        {canCreate() && (
                            <>
                                {isOverview && (
                                    <Tooltip title={geti18nText('employeeAnnualLeave.edit.vacation.pdf')}>
                                        <FilePdfOutlined
                                            style={{ marginRight: '5px' }}
                                            className="ny-card-icon"
                                            onClick={downloadReport}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip placement="top" title={geti18nText('employee.leave.add.vacation')}>
                                    <CoffeeOutlined
                                        style={{ marginRight: '5px' }}
                                        className="ny-card-icon"
                                        onClick={() => {
                                            if (setIsVacation) setIsVacation(true);
                                            if (setAddAbsenceModalVisible) setAddAbsenceModalVisible(true);
                                            history.push({
                                                pathname: '/human/employee-leave-overview',
                                                state: { openModal: true, isVacation: true },
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip placement="top" title={geti18nText('employee.leave.add.medical.leave')}>
                                    <MedicineBoxOutlined
                                        className="ny-card-icon"
                                        onClick={() => {
                                            if (setIsVacation) setIsVacation(false);
                                            if (setAddAbsenceModalVisible) setAddAbsenceModalVisible(true);
                                            history.push({
                                                pathname: '/human/employee-leave-overview',
                                                state: { openModal: true, isVacation: false },
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </>
                }
            >
                {loading ? (
                    <div style={{ paddingTop: 20 }}>
                        <NySpinner />
                    </div>
                ) : error ||
                  (statisticData != null &&
                      !statisticData['availableDays'] &&
                      !statisticData['approvedDays'] &&
                      !statisticData['approvedOldDays'] &&
                      !statisticData['usedDays'] &&
                      !statisticData['usedOldDays']) ||
                  !statisticData ? (
                    <div style={{ paddingTop: 10 }}>
                        <Alert
                            className="ny-dashboard-light-empty"
                            icon={<InfoCircleOutlined style={{ color: `${widgetColor}` }} />}
                            showIcon
                            style={{ background: `${widgetColor}11` }}
                            description={
                                <>
                                    <Title level={5}>{geti18nText('app.default.nodata')}</Title>
                                </>
                            }
                        />
                    </div>
                ) : (
                    <>
                        {statisticData && (
                            <>
                                <div style={gridStyle}>
                                    <Tooltip
                                        title={
                                            <>
                                                {geti18nText('employee.leave.days.available')}
                                                {' - '}
                                                {statisticData['availableDays'] ?? 0}
                                                <br />
                                                {statisticData['annualLeaveYear'] &&
                                                    '(' +
                                                        geti18nText('employee.leave.days.annualLeaveYear') +
                                                        ' ' +
                                                        statisticData['annualLeaveYear'] +
                                                        ')'}
                                                <br />
                                                {geti18nText('employee.leave.days.reserved').replace(
                                                    '{0}',
                                                    statisticData['reservedDays'] ?? 0
                                                )}
                                            </>
                                        }
                                    >
                                        <Badge
                                            count={statisticData['availableDays'] ? statisticData['availableDays'] : 0}
                                            showZero
                                            size="small"
                                        >
                                            <CheckCircleOutlined style={{ fontSize: '2em', color: 'green' }} />
                                        </Badge>
                                    </Tooltip>
                                </div>
                                <div style={gridStyle}>
                                    <Tooltip
                                        title={
                                            <>
                                                {geti18nText('employee.leave.days.used')}
                                                <br />
                                                {statisticData['annualLeaveYear'] &&
                                                    '(' +
                                                        geti18nText('employee.leave.days.annualLeaveYear') +
                                                        ' ' +
                                                        statisticData['annualLeaveYear'] +
                                                        ')'}
                                            </>
                                        }
                                    >
                                        <Badge
                                            count={
                                                (statisticData['usedDays'] ? statisticData['usedDays'] : 0) +
                                                (statisticData['usedOldDays'] ? statisticData['usedOldDays'] : 0)
                                            }
                                            showZero
                                            size="small"
                                        >
                                            <CloseCircleOutlined style={{ fontSize: '2em', color: 'red' }} />
                                        </Badge>
                                    </Tooltip>
                                </div>
                                <div style={gridStyle}>
                                    <Tooltip
                                        title={
                                            <>
                                                {geti18nText('employee.leave.days.approved')}
                                                <br />
                                                {statisticData['annualLeaveYear'] &&
                                                    '(' +
                                                        geti18nText('employee.leave.days.annualLeaveYear') +
                                                        ' ' +
                                                        statisticData['annualLeaveYear'] +
                                                        ')'}
                                                <div>
                                                    {statisticData['approvedDays'] > 0 && (
                                                        <div>
                                                            {statisticData['approvedDays'] > 1
                                                                ? statisticData['approvedDays'] +
                                                                  ' ' +
                                                                  geti18nText('employee.leave.days.available.hover')
                                                                : statisticData['approvedDays'] +
                                                                  ' ' +
                                                                  geti18nText('employee.leave.days.available.hover.1')}
                                                        </div>
                                                    )}{' '}
                                                </div>
                                                {statisticData['approvedOldDays'] > 0 && (
                                                    <div>
                                                        {statisticData['approvedOldDays'] > 1
                                                            ? statisticData['approvedOldDays'] +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.old.hover')
                                                            : statisticData['approvedOldDays'] +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.old.hover.1')}
                                                    </div>
                                                )}
                                                {lostDays > 0 && (
                                                    <div>
                                                        {lostDays > 1
                                                            ? lostDays +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.lost.hover')
                                                            : lostDays +
                                                              ' ' +
                                                              geti18nText('employee.leave.days.lost.hover.1')}
                                                    </div>
                                                )}
                                                {statisticData['oldDaysLimit'] &&
                                                    moment(statisticData['oldDaysLimit']) && (
                                                        <div>
                                                            {geti18nText('employee.leave.days.expire') +
                                                                ' ' +
                                                                moment(statisticData['oldDaysLimit']).format(
                                                                    'DD.MM.YYYY'
                                                                )}
                                                        </div>
                                                    )}
                                            </>
                                        }
                                    >
                                        <Badge
                                            count={
                                                (statisticData['approvedDays'] ? statisticData['approvedDays'] : 0) +
                                                (statisticData['approvedOldDays']
                                                    ? statisticData['approvedOldDays']
                                                    : 0)
                                            }
                                            showZero
                                            size="small"
                                        >
                                            <PlayCircleOutlined style={{ fontSize: '2em' }} />
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </>
                )}
            </Card>
            <ReportExportType
                exportType={exportType}
                setExportType={setExportType}
                isModal={true}
                visible={reportModalVisible}
                setVisible={setReportModalVisible}
                onOk={downloadReport}
                title={geti18nText('employeeAnnualLeave.edit.title')}
            />
        </>
    );
};

export default EmployeeLeavePersonalLightWidget;
