import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import TravelExpensePaymentMethodIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import TravelExpensePaymentMethodEdit from './edit';
import { customRenderTextOption } from '../../../../utils/Utils';

const TravelExpensePaymentMethodSearch = ({
    label = geti18nText('travelWarrantExpense.edit.travelExpensePaymentMethod'),
    name = 'travelExpensePaymentMethod',
    required = false,
    readonly = false,
    isAdmin = false,
    onChange = null,
    setDefaultFilterValue = [{ field: 'active', condition: 'equals_bool', value: 1 }],
    tooltip = undefined,
}: any) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={
                required
                    ? [
                          {
                              required: true,
                              message: geti18nText('app.default.required'),
                          },
                      ]
                    : []
            }
            tooltip={tooltip}
        >
            <NySearchField
                url={CONSTANTS_REQ.TRAVEL_EXPENSE_PAYMENT_METHOD.SEARCH}
                map={{
                    id: 'id',
                    label: 'name',
                    name: 'name',
                    text: 'name',
                    reimbursed: 'reimbursed',
                    bookExpense: 'bookExpense',
                }}
                searchBy="name"
                SearchPopupComponent={
                    <TravelExpensePaymentMethodIndex readonly={readonly} defaultFilterValue={setDefaultFilterValue()} />
                }
                AddNewModalComponent={isAdmin ? TravelExpensePaymentMethodEdit : null}
                onChange={onChange ? onChange : null}
                renderOption={customRenderTextOption}
                setDefaultFilterValue={setDefaultFilterValue}
            />
        </Form.Item>
    );
};

export default TravelExpensePaymentMethodSearch;
