import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Form, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import RowAction from '../../../../../components/row-action';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
// import { addTab } from '../../../../../slices/tabsSlice';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../../utils/Filters';
import {
    errorNotification,
    getDateFormat,
    getEnumArrayStatus,
    getFileList,
    getLoginRedirectUrl,
    getOrdinal,
    getTravelWarrantStatusIcon,
    getWageErrorsMessages,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import TravelWarrantEdit from '../edit';
import TravelWarrantPersonalWizardEdit from '../wizard/wizard-edit';
import TravelWarrantPersonalWizardNew from '../wizard/wizard-new';
import moment from 'moment';

const TravelWarrantPersonal = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    refresh,
    setRefresh,
    employee,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    canViewWageErrors = false,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [wizard, setWizard] = useState<boolean>(false);
    const location: any = useLocation();
    const [visible, setVisible] = useState<boolean>(false);
    const [travelWarrantId, setTravelWarrantId] = useState<any>(null);
    const [isPlane, setIsPlane] = useState<any>();
    const [files, setFiles] = useState<any>([]);
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(travelWarrantStatusEnum.DRAFT);
    const [destinationJson, setDestinationJson] = useState(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [travelVehicle, setTravelVehicle] = useState(null);
    const [isPersonalVehicle, setIsPersonalVehicle] = useState(false);
    const [isPersonalVehicle2, setIsPersonalVehicle2] = useState(false);
    const [person, setPerson] = useState<any>({});
    const [reportForm] = Form.useForm();
    const [report, setReport] = useState(null);
    const [ordinal, setOrdinal] = useState(null);
    const [warrantDate, setWarrantDate] = useState<any>('');
    const [defaultPlane, setDefaultPlane] = useState(false);
    const [personalVehicleFinal, setPersonalVehicleFinal] = useState<any>(false);
    const [isPersonal, setIsPersonal] = useState(false);
    const [dataFormCopy, setDataFormCopy] = useState(null);
    const [item, setItem] = useState(null);

    useHelper('travel_order/travel_warrant_personal');

    useEffect(() => {
        setPersonalVehicleFinal(isPersonal || isPersonalVehicle || isPersonalVehicle2);
    }, [isPersonalVehicle]);

    useEffect(() => {
        const id = getLoginRedirectUrl();
        if (id) {
            setTravelWarrantId(id);
            showTravelWizardComplete(id);
        }
    }, [location]);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'employee_id', condition: 'equals', value: employee && employee.id },
        ];
    };

    const changeStatus = (recordId: any, status: any, info: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: info,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh: number) => refresh + 1);
                if (status == travelWarrantStatusEnum.WAITING_FOR_APPROVAL) {
                    okNotification(geti18nText('ravelWarrantPersonal.sendToApproval.ok'));
                } else if (status == travelWarrantStatusEnum.REJECTED) {
                    okNotification(geti18nText('travelWarrantPersonal.cancelTrip.ok'));
                }
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
            }
        });
    };

    const showTravelWizardComplete = async (recordId: any) => {
        const res = await getModalDataComplete(recordId);
        setVisible(true);
        fetchAppSettings();
    };

    const getModalDataComplete = async (recordId: any) => {
        if (recordId) {
            setTravelWarrantId(recordId);
            return await NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + recordId).then(
                (result: any) => {
                    if (result && result.status === RESPONSE.OK && result.data) {
                        setValues(result.data);
                    }
                }
            );
        }
    };

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    const element = result.data[0];
                    setReport(
                        element.value !== null && element.value !== undefined ? element.value : element.defaultValue
                    );
                    if (!reportForm.getFieldValue('report')) {
                        reportForm.setFieldsValue({
                            report:
                                element.value !== null && element.value !== undefined
                                    ? element.value
                                    : element.defaultValue,
                        });
                    }
                }
            }
        });
    };

    const setValues = (data: any) => {
        setTravelWarrantStatus(data.status);
        if (data.ordinal) {
            setOrdinal(data.ordinal);
        }
        if (data.warrantDate) {
            setWarrantDate(moment(data.warrantDate).format('YYYY'));
        }
        if (data.startDate) {
            data.startDate = setDateFormat(data.startDate, 'yyyy-MM-DD HH:mm');
            setStartDate(data.startDate);
        }
        if (data.endDate) {
            data.endDate = setDateFormat(data.endDate, 'yyyy-MM-DD HH:mm');
            setEndDate(data.endDate);
        }
        if (data.travelVehicle) {
            setIsPersonalVehicle(data.travelVehicle.vehicleType && data.travelVehicle.vehicleType === 1);

            setIsPlane(
                data.travelVehicle.vehicleType &&
                    (data.travelVehicle.vehicleType === 3 ||
                        data.travelVehicle.vehicleType === 4 ||
                        data.travelVehicle.vehicleType === 6)
            );
            setDefaultPlane(
                data.travelVehicle.vehicleType &&
                    (data.travelVehicle.vehicleType === 3 ||
                        data.travelVehicle.vehicleType === 4 ||
                        data.travelVehicle.vehicleType === 6)
            );
            data.travelVehicle = setSearchFormat(data.travelVehicle, 'name', 'name');
            setTravelVehicle(data.travelVehicle);
        } else {
            setIsPlane(false);
        }
        if (data.travelVehicle2) {
            setIsPersonalVehicle2(data.travelVehicle2.vehicleType && data.travelVehicle2.vehicleType === 1);
            data.travelVehicle2 = setSearchFormat(data.travelVehicle2, 'name', 'name');
        } else {
            setIsPersonalVehicle2(false);
        }
        if (data.destinationJson) {
            setDestinationJson(JSON.parse(data.destinationJson));
        }

        setIsPersonal(isPersonalVehicle || isPersonalVehicle2);
        setDataFormCopy(data);
        if (data && data.content) {
            setFiles(getFileList(data.content));
        }
        getModalFiles(data.id);
    };

    const getModalFiles = (cardId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'travel_warrant_id', condition: 'equals', value: cardId },
                ])
            ),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(getFileList(result.data.content));
                } else {
                    setFiles([]);
                }
            }
        });
    };

    function resetDataForm() {
        setValues(dataFormCopy);
        setIsPlane(defaultPlane);
    }

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'TRAVEL_WARRANT' },
            { field: 'setting_key', condition: 'equals', value: 'TRAVEL_WARRANT_REPORT_TEXT_TEMPLATE' },
        ];
    };

    const columns = [
        {
            title: geti18nText('travelWarrantPersonal.table.column.ordinal'),
            dataIndex: 'ordinal',
            width: '110px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (text: any, record: any) => {
                if (record && record.ordinal && record.status > travelWarrantStatusEnum.DRAFT) {
                    return (
                        <>
                            <Tooltip title={geti18nText('menu.travel.warrant.details')}>
                                <Button
                                    type="link"
                                    style={{ textDecoration: 'underline' }}
                                    onClick={() =>
                                        // dispatch(
                                        //     addTab({
                                        //         title: 'travelWarrantPersonal.edit.title.short' + '-',
                                        //         addedData: record.id,
                                        //         path: '/travelWarrant/' + record.id,
                                        //     })
                                        // )
                                        history.push('/travel-warrant/travelWarrant/' + record.id)
                                    }
                                >
                                    {getOrdinal(record)}
                                </Button>
                            </Tooltip>
                            {getWageErrorsMessages(
                                record?.wageErrors,
                                canViewWageErrors && record?.status != travelWarrantStatusEnum.PAID
                            )}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrantPersonal.table.column.warrantDate'),
            dataIndex: 'warrantDate',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(true),
            render: (warrantDate: any, record: any) => {
                if (record.warrantDate) {
                    return getDateFormat(record.warrantDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrantPersonal.table.column.person'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (person: any, record: any) => {
                if (record && record.person) {
                    return (
                        <>
                            {`${record.person.firstName} ${record.person.lastName} `}
                            {record.employee &&
                                record.employee.employmentRecordId &&
                                ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('travelWarrantPersonal.table.column.destination'),
            dataIndex: 'destination',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },

        {
            title: geti18nText('travelWarrantPersonal.table.column.startDate'),
            dataIndex: 'startDate',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (startDate: any, record: any) => {
                if (record.startDate != null) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrantPersonal.table.column.endDate'),
            dataIndex: 'endDate',
            width: '12%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (endDate: any, record: any) => {
                if (record.endDate != null) {
                    return getDateFormat(record.endDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('travelWarrantPersonal.table.column.status'),
            dataIndex: 'status',
            width: '220px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('TRAVEL_WARRANT_STATUS')),
            render: (status: any) => {
                return getTravelWarrantStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <RowAction
                            component={'TravelWarrantPersonal'}
                            record={record}
                            changeStatus={changeStatus}
                            showTravelWizard={showTravelWizardComplete}
                            printTravelWarrant={true}
                            cancelTravelWarrantAction={true}
                            setWizard={setWizard}
                            setWarrantID={setItem}
                            fetchAccessRights
                        ></RowAction>
                    );
                }
            },
        },
    ];

    const addedButtons = () => {
        return (
            <Button
                onClick={() => {
                    setItem(null);
                    setWizard(true);
                }}
                type="primary"
                style={{ float: 'right' }}
            >
                {geti18nText('travelWarrantPersonal.table.add')}
            </Button>
        );
    };

    const onRowSelect = (item: any) => {
        if (item.status == travelWarrantStatusEnum.DRAFT || item.status == travelWarrantStatusEnum.REJECTED) {
            setItem(item.id);
            setWizard(true);
        } else {
            history.push('/travel-warrant/travelWarrant/' + item.id);
        }
    };

    return (
        <>
            <NyDataTable
                nyId="travel-warrant-travel-warrant-personal-table"
                url={CONSTANTS_REQ.TRAVEL_WARRANT.MY_LIST}
                addNewButtonText={geti18nText('travelWarrantPersonal.table.add')}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                columns={columns}
                fetchWhenChange={refresh}
                setDefaultSortOrder={sortOrder}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                shortcuts={true}
                hideNewButton={true}
                addedButtons={addedButtons}
                modalComponent={TravelWarrantEdit}
                onRowSelect={(item: any) => onRowSelect(item)}
            />
            {wizard && (
                <TravelWarrantPersonalWizardNew
                    visible={wizard}
                    setVisible={setWizard}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    dataForm={item}
                />
            )}
            {visible && (
                <TravelWarrantPersonalWizardEdit
                    ordinal={ordinal}
                    warrantDate={warrantDate}
                    visible={visible}
                    setVisible={setVisible}
                    travelVehicle={travelVehicle}
                    startDate={startDate}
                    endDate={endDate}
                    files={files}
                    setFiles={setFiles}
                    person={person}
                    travelWarrantStatus={travelWarrantStatus}
                    dataForm={travelWarrantId}
                    report={report}
                    isPersonalVehicle={isPersonal || isPersonalVehicle || isPersonalVehicle2 || personalVehicleFinal}
                    destinationJson={destinationJson}
                    isPlane={isPlane}
                    setDestinationJson={setDestinationJson}
                    resetDataForm={resetDataForm}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            )}
        </>
    );
};

export default TravelWarrantPersonal;
