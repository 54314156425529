import {
    AppstoreOutlined,
    BankOutlined,
    BellOutlined,
    BookOutlined,
    BuildOutlined,
    BulbOutlined,
    CodeOutlined,
    CompassOutlined,
    ControlOutlined,
    ExperimentOutlined,
    FlagOutlined,
    FolderViewOutlined,
    GlobalOutlined,
    GoldOutlined,
    HomeOutlined,
    LinkOutlined,
    MailOutlined,
    PayCircleOutlined,
    SettingOutlined,
    TagOutlined,
    TagsOutlined,
    TeamOutlined,
    ToolOutlined,
    TransactionOutlined,
} from '@ant-design/icons';
import { AdministrationRights } from '../../rights/administrationRights';

const AdministrationModuleMenu = [
    {
        key: 'menu.administration',
        path: '/administration',
        icon: <AppstoreOutlined />,
        i18n: 'menu.home',
        role: AdministrationRights.canViewAdministrationHome(),
    },
    {
        key: 'menu.application.settings',
        path: '/administration/application-settings',
        icon: <SettingOutlined />,
        i18n: 'menu.application.settings',
        role: AdministrationRights.canViewAppSettingsMenu(),
    },
    {
        key: 'menu.users',
        path: '/administration/users',
        icon: <TeamOutlined />,
        i18n: 'menu.users',
        role: AdministrationRights.canViewAuthUsersMenu(),
    },
    {
        key: 'menu.notification.settings',
        path: '/administration/notification-settings',
        icon: <ControlOutlined />,
        i18n: 'menu.notification.settings',
        role: AdministrationRights.canViewNotifSettingsMenu(),
    },
    {
        key: 'menu.access.rights',
        path: '/administration/access-right',
        icon: <FolderViewOutlined />,
        i18n: 'menu.access.rights',
        role: AdministrationRights.canViewAccessRightsMenu(),
    },
    {
        key: 'menu.order.view',
        path: '/administration/order-view',
        icon: <ToolOutlined />,
        i18n: 'menu.order.view',
        role: AdministrationRights.canViewOrderViewMenu(),
    },
    {
        key: 'menu.notification',
        path: '/administration/notifications-overview',
        icon: <BellOutlined />,
        i18n: 'menu.notification',
        role: AdministrationRights.canViewNotifMenu(),
    },
    {
        key: 'menu.message.access.right',
        path: '/administration/message-access-right',
        icon: <FolderViewOutlined />,
        i18n: 'menu.message.access.right',
        role: AdministrationRights.canViewMessageMenu(),
    },
    {
        key: 'menu.message',
        path: '/administration/message',
        icon: <MailOutlined />,
        i18n: 'menu.message',
        role: AdministrationRights.canViewMessageMenu(),
    },
    {
        key: 'menu.logs',
        path: '/administration/logs',
        icon: <CodeOutlined />,
        i18n: 'menu.logs',
        role: AdministrationRights.canViewLogsMenu(),
    },
    {
        key: 'menu.codebook.initialization',
        path: '/administration/codebook-initialization',
        icon: <BookOutlined />,
        i18n: 'menu.codebook.initialization',
        role: AdministrationRights.canViewSuperAdminMenu(),
    },
    {
        key: 'menu.administration.codebooks',
        path: '/administration/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.codebooks',
        role: AdministrationRights.canViewCodebooksCompanyMenu(),
        submenu: [
            {
                key: 'menu.company',
                path: '/administration/codebooks/company',
                icon: <HomeOutlined />,
                i18n: 'menu.company',
                role: AdministrationRights.canViewCodebooksCompanyMenu(),
            },
            {
                key: 'menu.bank',
                path: '/administration/codebooks/bank',
                icon: <BankOutlined />,
                i18n: 'menu.bank',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.currency',
                path: '/administration/codebooks/currency',
                icon: <PayCircleOutlined />,
                i18n: 'menu.currency',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.exchange.rate',
                path: '/administration/codebooks/exchange-rate',
                icon: <TransactionOutlined />,
                i18n: 'menu.exchange.rate',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.country',
                path: '/administration/codebooks/country',
                icon: <GlobalOutlined />,
                i18n: 'menu.country',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.county',
                path: '/administration/codebooks/county',
                icon: <CompassOutlined />,
                i18n: 'menu.county',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.township',
                path: '/administration/codebooks/township',
                icon: <GoldOutlined />,
                i18n: 'menu.township',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.settlement',
                path: '/administration/codebooks/settlement',
                icon: <FlagOutlined />,
                i18n: 'menu.settlement',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.district',
                path: '/administration/codebooks/settlement-district',
                icon: <BuildOutlined />,
                i18n: 'menu.district',
                role: AdministrationRights.canViewSettlementMenu(),
            },
            {
                key: 'menu.township.district',
                path: '/administration/codebooks/township-district',
                icon: <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-city" />,
                i18n: 'menu.township.district',
                role: AdministrationRights.canViewSettlementMenu(),
            },
            {
                key: 'menu.post.office',
                path: '/administration/codebooks/post-office',
                icon: <MailOutlined />,
                i18n: 'menu.post.office',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.holiday',
                path: '/administration/codebooks/holiday',
                icon: <BulbOutlined />,
                i18n: 'menu.holiday',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.links',
                path: '/administration/codebooks/links',
                icon: <LinkOutlined />,
                i18n: 'menu.links',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.measureUnit',
                path: '/administration/codebooks/measure-unit',
                icon: <ExperimentOutlined />,
                i18n: 'menu.measureUnit',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.item.group',
                path: '/administration/codebooks/item-group',
                icon: <TagsOutlined />,
                i18n: 'menu.item.group',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
            {
                key: 'menu.item',
                path: '/administration/codebooks/item',
                icon: <TagOutlined />,
                i18n: 'menu.item',
                role: AdministrationRights.canViewCodebooksMenu(),
            },
        ],
    },
];
export default AdministrationModuleMenu;
