import { geti18nText, NyDataTable } from '@nybble/nyreact';
import moment from 'moment';

const CustomHistoryEmployee = ({ url, id, customColumns = undefined }: any) => {
    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'entityId', condition: 'equals', value: id },
        ];
    };

    const columns = [
        {
            title: geti18nText('customHistory.table.column.created'),
            dataIndex: 'created',
            render: (timestamp: any) => moment(timestamp).format('DD.MM.yyyy. HH:mm'),
        },
        {
            title: geti18nText('customHistory.table.column.createdBy'),
            dataIndex: 'createdPerson',
            render: (text: any, record: any) => (
                <>
                    {record?.createdPerson?.firstName} {record?.createdPerson?.lastName}
                </>
            ),
        },
        {
            title: geti18nText('customHistory.table.column.person'),
            dataIndex: 'person',
            render: (text: any, record: any) => {
                if (record && record.person) {
                    if (typeof record.person === 'object' && record.person !== null) {
                        return (
                            <>
                                {record?.person?.firstName} {record?.person?.lastName}
                            </>
                        );
                    } else {
                        const formattedPerson = JSON.parse(record?.person);
                        return (
                            <>
                                {formattedPerson?.firstName} {formattedPerson?.lastName}
                            </>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('employee.contact.edit.email'),
            dataIndex: 'contact',
            render: (text: any, record: any) => {
                if (record && record.contact) {
                    if (typeof record.contact === 'object' && record.contact !== null) {
                        return <>{record.contact?.email && record.contact?.email} </>;
                    } else {
                        const formattedContact = JSON.parse(record?.contact);
                        return <>{formattedContact?.email && formattedContact?.email} </>;
                    }
                }
            },
        },
        {
            title: geti18nText('employee.contact.edit.phone'),
            dataIndex: 'contact',
            render: (text: any, record: any) => {
                if (record && record.contact) {
                    if (typeof record.contact === 'object' && record.contact !== null) {
                        return <>{record.contact?.phone && record.contact?.phone} </>;
                    } else {
                        const formattedContact = JSON.parse(record?.contact);
                        return <>{formattedContact?.phone && formattedContact?.phone} </>;
                    }
                }
            },
        },
        {
            title: geti18nText('employee.contact.edit.mobile'),
            dataIndex: 'contact',
            render: (text: any, record: any) => {
                if (record && record.contact) {
                    if (typeof record.contact === 'object' && record.contact !== null) {
                        return <>{record.contact?.mobile && record.contact?.mobile} </>;
                    } else {
                        const formattedContact = JSON.parse(record?.contact);
                        return <>{formattedContact?.mobile && formattedContact?.mobile} </>;
                    }
                }
            },
        },
        {
            title: geti18nText('customHistory.table.column.businessUnit'),
            dataIndex: 'businessUnit',
            render: (text: any, record: any) => {
                if (record && record.businessUnit) {
                    if (typeof record.businessUnit === 'object' && record.businessUnit !== null) {
                        return (
                            record.businessUnit.name +
                            (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '')
                        );
                    } else {
                        const formattedBU = JSON.parse(record?.businessUnit);
                        return (
                            <>
                                {formattedBU?.name} {formattedBU?.code ? '(' + formattedBU?.code + ') ' : ''}
                            </>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('customHistory.table.column.room'),
            dataIndex: 'room',
            render: (text: any, record: any) => record?.room?.name,
        },
        {
            title: geti18nText('customHistory.table.column.vocation'),
            dataIndex: 'vocation',
            render: (text: any, record: any) => {
                if (record && record.vocation) {
                    if (typeof record.vocation === 'object' && record.vocation !== null) {
                        return record.vocation?.name;
                    } else {
                        const formattedVocation = JSON.parse(record?.vocation);
                        return formattedVocation?.name;
                    }
                }
            },
        },
        { title: geti18nText('customHistory.table.column.mainStatus'), dataIndex: 'mainStatus' },
        { title: geti18nText('customHistory.table.column.status'), dataIndex: 'status' },
        { title: geti18nText('customHistory.table.column.process'), dataIndex: 'process' },
    ];

    return (
        <div style={{ maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
            <NyDataTable
                url={url}
                columns={customColumns ? customColumns : columns}
                nyId="custom-history-employee"
                hideButtons
                setDefaultFilterValue={setDefaultFilterValue}
                onRowSelect={() => null}
            />
        </div>
    );
};

export default CustomHistoryEmployee;
