import { NyDataEdit, NyInputNumber, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getSearchFormat, setSearchFormat } from '../../../../utils/Utils';
import ProfessionalQualificationSearch from '../professional-qualification/search';
import VocationGroupSearch from '../vocation-group/search';

const VocationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('vocation.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [clearField, setClearField] = useState(true);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [clearFunc, setClearFunc] = useState<any>(props?.addedData?.clearSearchField); // <-- ne brisati ovu liniju

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('vocation.edit.title') + ' - ' + dataForm.name);
        }

        if (dataForm.professionalQualification) {
            dataForm.professionalQualification = setSearchFormat(dataForm.professionalQualification);
        }

        if (dataForm.vocationGroup) {
            dataForm.vocationGroup = setSearchFormat(dataForm.vocationGroup);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('vocation.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.VOCATION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
            normalize={(values: any) => {
                if (values.vocationGroup) values.vocationGroup = getSearchFormat(values.vocationGroup);

                if (values.professionalQualification)
                    values.professionalQualification = getSearchFormat(values.professionalQualification);
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.edit.english.name')}
                        name="nameEng"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('vocation.edit.short.name')}
                        name="shortName"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <VocationGroupSearch
                        name="vocationGroup"
                        label={geti18nText('vocation.group.edit.parent.code')}
                        // required={true}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <ProfessionalQualificationSearch
                        label={geti18nText('vocation.edit.professionalQualificationType')}
                        name="professionalQualification"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('vocation.edit.itemRank')} name="itemRank" initialValue={1}>
                        <NyInputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default VocationEdit;
