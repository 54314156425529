import { DeleteTwoTone } from '@ant-design/icons';
import { geti18nText, NyModalConfirm, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Col, Modal, Row, Select, Space, Table, Tag, Tooltip } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { recordKeyCombination } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { setShortcutsSettings } from '../../../slices/shortcutsSettingsSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { errorNotification, okNotification } from '../../../utils/Utils';

const { Option } = Select;

const ShortcutsEdit = forwardRef((props: any, ref) => {
    const dispatch = useDispatch();
    const settingKey = 'shortcuts';

    const [settingsId, setSettingsId] = useState<any>(null);
    const [showShortcutsDialog, setShowShortcutsDialog] = useState<any>(false);
    const [changingActionShortcut, setChangingActionShortcut] = useState<any>(null);

    const [maxShortcutId, setMaxShortcutId] = useState<any>(0);
    const [shortcutEnums, setShortcutEnums] = useState<any>([]);

    const [keyMapData, setKeyMapData] = useState<any>({});
    useImperativeHandle(ref, () => ({
        addNewShortcut() {
            handleAddShortcut();
        },
        saveShortcut(evetn: any) {
            save(evetn);
        },
    }));

    const columns = [
        {
            title: geti18nText('settings.shortcuts.name'),
            dataIndex: 'name',
            key: 'name',
            width: '250px',
            render: (name: any, record: any) => {
                return name ? (
                    geti18nText('settings.shortcuts.name.' + name)
                ) : (
                    <Select style={{ width: 220 }} onChange={(value: any) => onSelectNameChange(value, record)}>
                        {shortcutEnums}
                    </Select>
                );
            },
        },
        {
            title: geti18nText('settings.shortcuts.group'),
            dataIndex: 'group',
            key: 'group',
            render: (group: any) => {
                return geti18nText('settings.shortcuts.group.' + group);
            },
        },
        {
            title: geti18nText('settings.shortcuts.sequence'),
            key: 'sequence',
            dataIndex: 'sequence',
            render: (sequence: any) => (sequence ? <Tag key={sequence}>{sequence.toUpperCase()}</Tag> : ''),
        },
        {
            title: geti18nText('settings.shortcuts.action'),
            key: 'action',
            width: '100px',
            render: (text: any, record: any) => {
                if (data.length >= 1) {
                    return (
                        <Space size="middle" style={{ textAlign: 'right' }}>
                            <Tooltip placement="top" title={geti18nText('app.default.change')}>
                                <div className="ny-icon-rec-div">
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="far"
                                        data-icon="dot-circle"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        className="ny-icon-rec"
                                        onClick={() => {
                                            props.setColorChange(false);
                                            showChangeShortcutDialog(record.actionName, record.name, record.id);
                                        }}
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z"
                                        ></path>
                                    </svg>
                                </div>
                            </Tooltip>
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <NyModalConfirm
                                    title={geti18nText('app.default.delete.confirm')}
                                    onConfirm={() => {
                                        handleDeleteShortcut(record.id);
                                    }}
                                >
                                    <DeleteTwoTone
                                        style={{ fontSize: '20px', cursor: 'pointer' }}
                                        twoToneColor="#ff7875"
                                    />
                                </NyModalConfirm>
                            </Tooltip>
                        </Space>
                    );
                }
                return <></>;
            },
        },
    ];

    const [data, setData] = useState<any>([]);

    useEffect(() => {
        let newData: any = [];
        let newShortcutEnums: any = [];
        let id = 0;
        Object.keys(keyMapData).forEach(function (actionName: any) {
            keyMapData[actionName].sequences.forEach(function (sequence: any) {
                let value: any = { ...keyMapData[actionName] };
                value['id'] = id;
                value['actionName'] = actionName;
                delete value['sequences'];
                value['sequence'] = sequence.sequence;
                newData.push(value);
                id++;
            });
            newShortcutEnums.push(
                <Option value={actionName}>
                    {geti18nText('settings.shortcuts.name.' + keyMapData[actionName].name) +
                        ' [' +
                        geti18nText('settings.shortcuts.group.' + keyMapData[actionName].group) +
                        ']'}
                </Option>
            );
        });
        setMaxShortcutId(id);
        setData(newData);
        setShortcutEnums(newShortcutEnums);
    }, [keyMapData]);

    useEffect(() => {
        fetch();
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [props.colorChange]);

    const onKeyDown = (event: any) => {
        if (!props.colorChange) {
            event.preventDefault();
        }
        event.stopPropagation();
    };

    /// FETCH AND SET DATA
    // useEffect(() => {
    //     fetch();
    //     window.addEventListener('keydown', onKeyDown);
    //     return () => {
    //         window.removeEventListener('keydown', onKeyDown);
    //     };
    // }, [props.colorChange]);

    const fetch = () => {
        var parms: any = { settingKey: settingKey };
        if (settingKey) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.USER_SESTTINGS.GET_BY_SETTINGS_KEY + '/' + settingKey,
                parms
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setValues(result.data);
                    }
                }
            });
        }
    };

    function setValues(dataForm: any) {
        setSettingsId(dataForm.id);
        let settingsValue = JSON.parse(dataForm.settingValue);
        setKeyMapData(settingsValue);
    }
    /// END FETCH AND SET DATA

    /// SAVE DATA
    const save = (event: any) => {
        event.preventDefault();

        let settingsValue: any = {};
        data.forEach((element: any) => {
            if (element.actionName) {
                if (!settingsValue.hasOwnProperty(element.actionName)) {
                    settingsValue[element.actionName] = {
                        name: element.name,
                        group: element.group,
                        sequences: [
                            {
                                sequence: element.sequence,
                            },
                        ],
                    };
                } else {
                    if (element.sequence) {
                        settingsValue[element.actionName].sequences.push({
                            sequence: element.sequence,
                        });
                    }
                }
            }
        });

        let parms: any = {};
        parms.id = settingsId;
        parms.settingKey = settingKey;
        parms.settingValue = JSON.stringify(settingsValue);
        NyRequestResolver.requestPut(CONSTANTS_REQ.USER_SESTTINGS.EDIT + '/' + settingsId, undefined, parms).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    dispatch(setShortcutsSettings({ keyMap: settingsValue }));
                    okNotification();
                } else {
                    if (result.data && result.data.error) {
                        if (geti18nText(result.data.error) != '') {
                            errorNotification(result.data.error);
                        } else {
                            errorNotification(JSON.stringify(result.data.error));
                        }
                    } else {
                        errorNotification();
                    }
                }
            }
        );
    };
    /// END SAVE DATA

    /// BUTTONS ACTION
    const showChangeShortcutDialog = (actionName: any, name: any, id: any) => {
        setShowShortcutsDialog(true);
        setChangingActionShortcut({ name: name, actionName: actionName, id: id });
    };

    const handleAddShortcut = () => {
        setData([
            ...data,
            {
                id: maxShortcutId,
            },
        ]);
        setMaxShortcutId(maxShortcutId + 1);
    };

    const handleDeleteShortcut = (rowId: any) => {
        setData(data.filter((row: any) => row.id !== rowId));
    };

    const cancelListening = recordKeyCombination(({ id }) => {
        if (changingActionShortcut) {
            let newData = [...data];
            let index = newData.findIndex((item: any) => item.id === changingActionShortcut.id);
            if (index >= 0) {
                newData[index].sequence = id;
            }
            setData(newData);
        }

        setShowShortcutsDialog(false);
        setChangingActionShortcut(null);
    });

    const onModalCancel = () => {
        cancelListening();
        setShowShortcutsDialog(false);
        setChangingActionShortcut(null);
    };

    const onSelectNameChange = (actionName: any, record: any) => {
        let newData = [...data];
        let index = newData.findIndex((item: any) => item.id === record.id);
        if (index >= 0) {
            newData[index].actionName = actionName;
            newData[index].group = keyMapData[actionName].group;
            newData[index].name = keyMapData[actionName].name;
        }
        setData(newData);
    };
    /// END BUTTONS ACTION

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table columns={columns} dataSource={data} size="small" pagination={false} />
                </Col>
            </Row>
            {showShortcutsDialog && (
                <Modal
                    visible={showShortcutsDialog}
                    title={geti18nText('settings.shortcuts.change')}
                    forceRender={true}
                    onCancel={() => {
                        onModalCancel();
                    }}
                    closable={false}
                    okButtonProps={{ style: { display: 'none' } }}
                    maskClosable={false}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <h4 style={{ textAlign: 'center' }}>
                                {geti18nText('settings.shortcuts.change.text')} "
                                {changingActionShortcut
                                    ? geti18nText('settings.shortcuts.name.' + changingActionShortcut['name'])
                                    : ''}
                                ".
                            </h4>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
});

export default ShortcutsEdit;
