import {
    EllipsisOutlined,
    ExclamationCircleOutlined,
    FilePdfOutlined,
    InfoCircleOutlined,
    LeftCircleOutlined,
} from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Descriptions, Dropdown, Menu, Popover, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import {
    GetEmployeeAnnualLeaveStatusIcon,
    fileDownload,
    getDateFormat,
    getEnumArrayStatusNotDraft,
} from '../../../../utils/Utils';
import AnualLeaveModal from './AnualLeaveModal';
import EmployeeAnnualLeaveEdit from './EmployeeAnnualLeaveEdit';
import EmployeeAnnualLeaveReportModal from './EmployeeAnnualLeaveReportModal';

const { Text } = Typography;

const EmployeeAnnualLeaveIndex = ({
    employeeOverview = false,
    listView,
    setListView,
    refreshView,
    setRefreshView,
    isPersonal = false,
    employeeId,
    isHumanResources = true,
    activeList,
    isOverview = false,
    scroll = { y: 583, x: 800 },
    sortOrder,
}: any) => {
    const location = useLocation();
    const table = useTableSettings();

    const [deactiveModalVisible, setDeactiveModalVisible] = useState(false);
    const [approveModalVisible, setApproveModalVisible] = useState(false);
    const [usedAnnualLeaveSummaryReportModalVisible, setUsedAnnualLeaveSummaryReportModalVisible] = useState(false);
    const [usedAnnualLeaveSpecificationReportModalVisible, setUsedAnnualLeaveSpecificationReportModalVisible] =
        useState(false);
    const [refreshTable, setRefreshTable] = useState(0);

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    const setDefaultSortOrder = { order: 'annualLeaveYear', orderType: 'desc' };

    const setDefaultFilterValue = () => {
        if (activeList == 1 && employeeId) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_id', condition: 'equals', value: employeeId },
            ];
        } else if (activeList == 2 && employeeId) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_id', condition: 'not_equals', value: employeeId },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const descirptionContent = (description: any) => {
        let items = [];
        if (description != undefined) {
            for (const [key, value] of Object.entries(description) as any) {
                if (
                    key == 'employeeAnnualLeave.description.developmentalDisabilities' ||
                    key == 'employeeAnnualLeave.description.singleParent' ||
                    key == 'employeeAnnualLeave.description.child'
                ) {
                    const numbers = value.split('|');
                    if (numbers != undefined) {
                        items.push(
                            <Descriptions.Item
                                label={
                                    geti18nText(key) +
                                    ' ' +
                                    numbers[1] +
                                    ' ' +
                                    geti18nText('app.default.filter.range.year').toLowerCase()
                                }
                            >
                                <Text strong>
                                    {numbers[0] +
                                        ' (' +
                                        numbers[2] +
                                        ' x ' +
                                        numbers[3] +
                                        ') ' +
                                        (parseInt(numbers[0]) == 1
                                            ? geti18nText('app.default.filter.range.day').toLowerCase()
                                            : geti18nText('employeeLeave.table.column.days').toLowerCase())}
                                </Text>
                            </Descriptions.Item>
                        );
                    }
                } else {
                    items.push(
                        <Descriptions.Item label={geti18nText(key) ? geti18nText(key) : key}>
                            <Text strong>
                                {value +
                                    ' ' +
                                    (parseInt(value) == 1
                                        ? geti18nText('app.default.filter.range.day').toLowerCase()
                                        : geti18nText('employeeLeave.table.column.days').toLowerCase())}
                            </Text>
                        </Descriptions.Item>
                    );
                }
            }
        }

        return (
            <Descriptions bordered column={1} style={{ width: '700px' }}>
                {items}
            </Descriptions>
        );
    };

    const initialColumns = [
        {
            title: geti18nText('employeeAnnualLeave.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (id: any, record: any) => {
                return (
                    <>
                        {id}
                        {record.conditionChanged && (
                            <Tooltip title="employeeLeave.table.column.conditionChanged">
                                <ExclamationCircleOutlined
                                    className="ny-blink"
                                    style={{ color: 'red', marginLeft: '5px', fontWeight: 'bold' }}
                                />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        /*   {
            title: geti18nText('employeeAnnualLeave.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            width: '15%',
            ...getColumnSearch('string'),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return (
                        <>
                            {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                            {record.employee.employmentRecordId && ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        }, */
        {
            title: geti18nText('employeeAnnualLeave.table.column.annualLeaveYear'),
            dataIndex: 'annualLeaveYear',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.annualLeaveDate'),
            dataIndex: 'annualLeaveDate',
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.annualLeaveDate) {
                    return getDateFormat(record.annualLeaveDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.dateFrom) {
                    return getDateFormat(record.dateFrom, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.approvedDays'),
            dataIndex: 'approvedDays',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (status: any, record: any) => {
                return (
                    <>
                        {record.approvedDays}
                        {record.description != undefined && (
                            <Popover
                                content={descirptionContent(JSON.parse(record.description))}
                                title={geti18nText('employeeAnnualLeave.edit.action.calculationHelp')}
                            >
                                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                            </Popover>
                        )}
                    </>
                );
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.usedDays'),
            dataIndex: 'usedDays',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.usedOldDays'),
            dataIndex: 'usedOldDays',
            width: '11%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.approvedOldDays'),
            dataIndex: 'approvedOldDays',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.availableDays'),
            dataIndex: 'availableDays',
            width: '6%',
            render: (status: any, record: any) => {
                if (record.currentRecord) {
                    return (
                        <Tooltip
                            title={
                                <>
                                    {geti18nText('employeeAnnualLeave.table.column.availableDays')} -{' '}
                                    {record.availableDays} <br />
                                    {geti18nText('employeeAnnualLeave.table.column.reservedDays')} -{' '}
                                    {record.reservedDays ?? 0}
                                </>
                            }
                        >
                            {record.availableDays} ({record.reservedDays ?? 0})
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('employeeLeave.table.column.status'),
            dataIndex: 'status',
            width: '175px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatusNotDraft('EMPLOYEE_ANNUAL_LEAVE_STATUS')),
            render: (status: any, record: any) => {
                return GetEmployeeAnnualLeaveStatusIcon(record.status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '6%',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record.fileId && record.status == 2 && (
                                <Button
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                            null,
                                            geti18nText('employeeAnnualLeave.edit.title')
                                        );
                                    }}
                                >
                                    {geti18nText('employeeAnnualLeave.edit.vacation.pdf')}
                                </Button>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const adminColumns = [
        {
            title: geti18nText('employeeAnnualLeave.table.column.id'),
            dataIndex: 'id',
            width: '4%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (id: any, record: any) => {
                return (
                    <>
                        {id}
                        {record.conditionChanged && (
                            <Tooltip title="employeeLeave.table.column.conditionChanged">
                                <ExclamationCircleOutlined
                                    className="ny-blink"
                                    style={{ color: 'red', marginLeft: '5px', fontWeight: 'bold' }}
                                />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.employee'),
            dataIndex:
                "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
            sorter: (a: any, b: any) => {},
            width: '15%',
            ...getColumnSearch('string'),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return (
                        <>
                            {`${record.employee.person.firstName} ${record.employee.person.lastName} `}
                            {record.employee.employmentRecordId && ` (${record.employee.employmentRecordId})`}
                        </>
                    );
                }
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.annualLeaveYear'),
            dataIndex: 'annualLeaveYear',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.annualLeaveDate'),
            dataIndex: 'annualLeaveDate',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.annualLeaveDate) {
                    return getDateFormat(record.annualLeaveDate, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.dateFrom'),
            dataIndex: 'dateFrom',
            width: '9%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.dateFrom) {
                    return getDateFormat(record.dateFrom, 'DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.approvedDays'),
            dataIndex: 'approvedDays',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (status: any, record: any) => {
                return (
                    <>
                        {record.approvedDays}
                        {record.description != undefined && (
                            <Popover
                                content={descirptionContent(JSON.parse(record.description))}
                                title={geti18nText('employeeAnnualLeave.edit.action.calculationHelp')}
                            >
                                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                            </Popover>
                        )}
                    </>
                );
            },
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.usedDays'),
            dataIndex: 'usedDays',
            width: '6%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.usedOldDays'),
            dataIndex: 'usedOldDays',
            width: '11%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.approvedOldDays'),
            dataIndex: 'approvedOldDays',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('employeeAnnualLeave.table.column.availableDays'),
            dataIndex: 'availableDays',
            width: '6%',
            render: (status: any, record: any) => {
                if (record.currentRecord) {
                    return (
                        <Tooltip
                            title={
                                <>
                                    {geti18nText('employeeAnnualLeave.table.column.availableDays')} -{' '}
                                    {record.availableDays} <br />
                                    {geti18nText('employeeAnnualLeave.table.column.reservedDays')} -{' '}
                                    {record.reservedDays ?? 0}
                                </>
                            }
                        >
                            {record.availableDays} ({record.reservedDays ?? 0})
                        </Tooltip>
                    );
                }
            },
        },
        {
            title: geti18nText('employeeLeave.table.column.status'),
            dataIndex: 'status',
            width: '175px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatusNotDraft('EMPLOYEE_ANNUAL_LEAVE_STATUS')),
            render: (status: any, record: any) => {
                return GetEmployeeAnnualLeaveStatusIcon(record.status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record.fileId && record.status == 2 && (
                                <Button
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        fileDownload(
                                            CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                            null,
                                            geti18nText('employeeAnnualLeave.edit.title')
                                        );
                                    }}
                                >
                                    {geti18nText('employeeAnnualLeave.edit.vacation.pdf')}
                                </Button>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button onClick={() => setApproveModalVisible(true)} block>
                        {geti18nText('employeeAnnualLeave.table.action.1')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button onClick={() => setDeactiveModalVisible(true)} block>
                        {geti18nText('employeeAnnualLeave.table.action.2')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button onClick={() => setUsedAnnualLeaveSummaryReportModalVisible(true)} block>
                        {geti18nText('employeeAnnualLeave.report.generateUsedAnnualLeaveSummaryReport')}
                    </Button>
                </div>
                {/* <div style={{ display: 'block', margin: '5px' }}>
                    <Button onClick={() => setUsedAnnualLeaveSpecificationReportModalVisible(true)} block>
                        {geti18nText('employeeAnnualLeave.report.generateUsedAnnualLeaveSpecificationReport')}
                    </Button>
                </div> */}
            </div>
        </Menu>
    );

    const DeactivateOldAnualLeaveButton = (onSave: () => void) => {
        return (
            <>
                {employeeOverview && (
                    <div style={{ float: 'left', paddingLeft: '8px' }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setListView(0);
                                setRefreshView((refreshView: any) => refreshView + 1);
                            }}
                        >
                            <LeftCircleOutlined />
                            {geti18nText('employee.leave.cards.back')}
                        </Button>
                    </div>
                )}
                {canCreate() &&
                    activeList != 1 && ( // uvjetno actions za moja odsustva
                        <div style={{ float: 'left' }}>
                            <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                                <Button type="primary">
                                    {geti18nText('app.default.actions')}
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    )}
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="human-employee-anual-leave-table"
                url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.LIST}
                addNewButtonText={activeList != 1 ? geti18nText('employeeAnnualLeave.table.add') : ''} //isto uvijetno
                buttonsClassName="buttons-sticky"
                columns={activeList == 1 ? initialColumns : adminColumns}
                setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                shortcuts={true}
                hideNewButton={activeList == 1} // uvjet
                modalComponent={activeList != 1 ? EmployeeAnnualLeaveEdit : undefined}
                editProps={{
                    isHumanResources: isHumanResources,
                }}
                showRecordModal={activeList != 1}
                addedButtons={DeactivateOldAnualLeaveButton}
                fetchWhenChange={refreshTable}
                readonly={activeList == 1}
            />
            <AnualLeaveModal
                modalVisible={approveModalVisible}
                setModalVisible={setApproveModalVisible}
                url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.ADD_NEW_ANNUAL_LEAVE}
                title={geti18nText('employeeAnnualLeave.table.action.1')}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                isCreate
            />
            <AnualLeaveModal
                modalVisible={deactiveModalVisible}
                setModalVisible={setDeactiveModalVisible}
                url={CONSTANTS_REQ.EMPLOYEE_ANNUAL_LEAVE.CHANGE_STATUS_IN_PREPARATION_TO_ACTIVATED}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
                title={geti18nText('employeeAnnualLeave.table.action.2')}
            />
            <EmployeeAnnualLeaveReportModal
                modalVisible={usedAnnualLeaveSummaryReportModalVisible}
                setModalVisible={setUsedAnnualLeaveSummaryReportModalVisible}
                type="generateUsedAnnualLeaveSummaryReport"
            />
            <EmployeeAnnualLeaveReportModal
                modalVisible={usedAnnualLeaveSpecificationReportModalVisible}
                setModalVisible={setUsedAnnualLeaveSpecificationReportModalVisible}
                type="generateUsedAnnualLeaveSpecificationReport"
            />
        </>
    );
};

export default EmployeeAnnualLeaveIndex;
