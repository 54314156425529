import { CloseCircleOutlined, PrinterOutlined, SendOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, NySession, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Divider, Form, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import NyReportButton from '../../../../../components/report-button';
import TravelWarrantPaymentIndex from '../../../../../components/travel-warrant-payment';
import TravelWarrantSummaryIndex from '../../../../../components/travel-warrant-summary';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    okNotification,
    onKeyDownTextAreaCustom,
    warningNotification,
} from '../../../../../utils/Utils';
import { TravelWarrantRights } from '../../../../../rights/travelWarrantRights';

const TravelWarrantUnpaidModal = ({
    modalVisible,
    setModalVisible,
    travelWarrantId,
    refresh,
    setRefresh,
    tab,
}: any) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const enVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const travelWarrantPaymentStatusEnum = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(travelWarrantStatusEnum.DRAFT);
    const [travelWarrant, setTravelWarrant] = useState<any>(null);
    const [sendNotification, setSendNotification] = useState(true);
    const [employeeTravelWarrant, setEmployeeTravelWarrant] = useState<any>(null);
    const [isPlane, setIsPlane] = useState(false);
    const [currency, setCurrency] = useState<any>(undefined);
    const [ordinal, setOrdinal] = useState<any>('');
    const componentRef = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const [dataFormId, setDataFormId] = useState<any>(undefined);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [infoRequired, setInfoRequired] = useState(false);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const setDefaultFilterValueFiles = (travelWarrantId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
        ];
    };

    useEffect(() => {
        if (travelWarrantId) {
            setTravelWarrantValues(travelWarrantId);
            setValuesFiles(travelWarrantId);
        }
    }, [travelWarrantId]);

    useEffect(() => {
        if (dataFormId && dataFormId > 0) {
            setValuesFiles(dataFormId);
        }
    }, [refreshFiles]);

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false);
        setIsPlane(false);
        setFiles([]);
        setTravelWarrantStatus(travelWarrantStatusEnum.DRAFT);
        setTravelWarrant(null);
        setEmployeeTravelWarrant(null);
        setSendNotification(true);
        setCurrency(null);
        setOrdinal('');
    };

    const setTravelWarrantValues = (travelWarrantId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + travelWarrantId, undefined).then(
            (result: any) => {
                if (result && result.status === RESPONSE.OK && result.data) {
                    setValues(result.data);
                }
            }
        );
    };

    const setValues = (data: any) => {
        if (data.status > travelWarrantStatusEnum.DRAFT) {
            setOrdinal(
                data.ordinal && data.warrantDate ? data.ordinal + '/' + moment(data.warrantDate).format('YYYY') : null
            );
        }
        setTravelWarrantStatus(data.status ? data.status : travelWarrantStatusEnum.DRAFT);
        setDataFormId(data.id && data.id);

        const advancePaymentCurrency = data.advancePaymentCurrency;
        if (advancePaymentCurrency != undefined && advancePaymentCurrency.id && advancePaymentCurrency.isoCode) {
            setCurrency({ id: advancePaymentCurrency.id, text: advancePaymentCurrency.isoCode });
        }

        setIsPlane(false);
        if (data.travelVehicle && data.travelVehicle.vehicleType == 3) {
            setIsPlane(true);
        }

        setTravelWarrant(data);
        if (data.employee && data.employee.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + data.employee.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setEmployeeTravelWarrant(result.data);
                        }
                    }
                }
            );
        }
    };

    function setValuesFiles(travelWarrantId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(travelWarrantId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(result.data.content);
                } else {
                    setFiles([]);
                }
            }
        });
    }

    const save = (cancelPayment: boolean) => {
        setInfoRequired(cancelPayment);
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let message = form.getFieldValue(['message']) ? form.getFieldValue(['message']) : null;
                if (sendNotification) {
                    if (tab == 9) {
                        changeStatus(
                            travelWarrantId,
                            cancelPayment
                                ? travelWarrantPaymentStatusEnum.REJECTED
                                : travelWarrantPaymentStatusEnum.APPROVED,
                            message
                        );
                    } else {
                        changeStatus(
                            travelWarrantId,
                            cancelPayment
                                ? travelWarrantPaymentStatusEnum.CANCELED
                                : travelWarrantPaymentStatusEnum.PAID,
                            message
                        );
                    }
                }
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const changeStatus = (recordId: any, status: any, message: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT_PAYMENT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: status == travelWarrantStatusEnum.ORGANIZED ? true : false,
            info: message,
            advance: tab == travelWarrantStatusEnum.WAITING_FOR_ORGANIZATION ? true : false,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                if (setRefresh) setRefresh(() => refresh + 1);
                if (setLoading) setLoading(false);
                closeModal();
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
            }
        });
    };

    const getOrdinal = () => {
        let returnHeader = '';
        if (ordinal) returnHeader = returnHeader + ' - ' + ordinal;
        return returnHeader;
    };

    const openDetails = () => {
        history.push('/travel-warrant/travelWarrant/' + travelWarrantId);
    };

    const getCustomFooterContent = (
        <div>
            {tab != 4 && (
                <div style={{ marginLeft: '5px', float: 'left' }}>
                    <NyReportButton
                        url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION}
                        customParms={{ id: Number(travelWarrantId) }}
                        buttoni18nText={'travelWarrant.action.pdf.calculation'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT_CALCULATION)}
                        subreportType={'TRAVEL_WARRANT_CALCULATION'}
                        fileNameAddition={ordinal}
                        type={'button'}
                    />
                </div>
            )}
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button key={'print'} icon={<PrinterOutlined />}>
                            {geti18nText('travelWarrant.action.pdf.short')}
                        </Button>
                    );
                }}
                onBeforeGetContent={() => {
                    setPrintPdf(true);
                    return Promise.resolve();
                }}
                onAfterPrint={() => {
                    setPrintPdf(false);
                }}
                content={() => componentRef.current}
                pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
            />
            {TravelWarrantRights.canCreateTravelWarrantAdmin() && (
                <Button type="primary" onClick={openDetails}>
                    {geti18nText('travelWarrant.overview.details')}
                </Button>
            )}
            <Button onClick={closeModal}>{geti18nText('app.default.button.cancel')}</Button>
            <NyModalConfirm
                title={geti18nText(
                    tab == 9 ? 'travelWarrant.rejectPayment.confirm' : 'travelWarrant.cancelPayment.confirm'
                )}
                onConfirm={() => {
                    if (form.getFieldValue(['message'])) {
                        save(true);
                    } else {
                        warningNotification(geti18nText('travelWarrantOrganization.note.info'));
                    }
                }}
            >
                <Button type="primary" danger icon={<CloseCircleOutlined />} disabled={loading}>
                    {geti18nText(tab == 9 ? 'travelWarrant.table.rejectPayment' : 'travelWarrant.table.cancelPayment')}
                </Button>
            </NyModalConfirm>
            <NyModalConfirm
                title={geti18nText(tab == 9 ? 'travelWarrant.approvePayment.confirm' : 'travelWarrant.payoff.confirm')}
                onConfirm={() => {
                    save(false);
                }}
            >
                <Button type="primary" icon={<SendOutlined />} disabled={loading}>
                    {geti18nText(tab == 9 ? 'travelWarrant.table.approvePayment' : 'travelWarrant.table.paysoff')}
                </Button>
            </NyModalConfirm>
        </div>
    );

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={
                        tab == 4
                            ? geti18nText('app.enum.NOTIFICATION_TYPE.14') + getOrdinal()
                            : geti18nText('app.enum.NOTIFICATION_TYPE.21') + getOrdinal()
                    }
                    onOk={() => null}
                    onCancel={closeModal}
                    confirmLoading={loading}
                    width={1100}
                    maskClosable={false}
                    footer={getCustomFooterContent}
                >
                    <div ref={componentRef}>
                        <Row
                            gutter={24}
                            style={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: '70vh',
                            }}
                        >
                            <Col span={24}>
                                <Form
                                    layout="vertical"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    form={form}
                                    onFinishFailed={({ errorFields }) => {
                                        form.scrollToField(errorFields[0].name);
                                    }}
                                >
                                    <TravelWarrantSummaryIndex
                                        componentRefProp={componentRef}
                                        printPdfProp={printPdf}
                                        setPrintPdfProp={setPrintPdf}
                                        employeeTravelWarrant={employeeTravelWarrant}
                                        globalForm={travelWarrant}
                                        formSummary={form}
                                        isPlane={isPlane}
                                        currency={currency}
                                        files={files}
                                        setFiles={setFiles}
                                        isOrganizationModal={true}
                                        printPdfHeader={
                                            tab == 4
                                                ? geti18nText('app.enum.NOTIFICATION_TYPE.14') + getOrdinal()
                                                : geti18nText('app.enum.NOTIFICATION_TYPE.21') + getOrdinal()
                                        }
                                        showPrintButton={false}
                                        id={travelWarrantId}
                                        setRefreshFiles={setRefreshFiles}
                                        travelWarrantStatus={travelWarrantStatus}
                                        message={form.getFieldValue(['message'])}
                                        showCo2={
                                            travelWarrant?.travelVehicle?.vehicleType == enVehicleType.PRIVATE_CAR ||
                                            travelWarrant?.travelVehicle?.vehicleType == enVehicleType.OFFICIAL_CAR
                                                ? false
                                                : true
                                        }
                                    />
                                    <Divider orientation="left" plain>
                                        {geti18nText('travelWarrantPayment.edit.info')}
                                    </Divider>
                                    <TravelWarrantPaymentIndex
                                        id={travelWarrantId}
                                        forUnpaid={true}
                                        hideButtons
                                        tab={tab}
                                        printPdf={printPdf}
                                    />
                                    {!printPdf && (
                                        <>
                                            <Divider orientation="left" plain>
                                                {geti18nText('travelWarrantOrganization.table.column.note')}
                                            </Divider>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="message"
                                                        label={geti18nText(
                                                            "travelWarrantOrganization.table.column.note'"
                                                        )}
                                                        rules={[
                                                            {
                                                                required: infoRequired,
                                                                message: geti18nText('app.default.required'),
                                                                whitespace: infoRequired,
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea
                                                            rows={4}
                                                            onKeyDown={onKeyDownTextAreaCustom}
                                                            autoSize={{ minRows: 4, maxRows: 5 }}
                                                        ></TextArea>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default TravelWarrantUnpaidModal;
