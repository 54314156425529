import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Dropdown, Menu } from 'antd';
import { ReactText, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import NyErrorLogModal from '../../../../../components/error-log-modal';
import ImportCSVModal from '../../../../../components/import-csv-modal';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { AssetRights } from '../../../../../rights/assetRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookAssetCategory, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import { errorNotification, getEnumArrayStatus, okNotification } from '../../../../../utils/Utils';
import AssetEdit from './edit';

const AssetIndex = ({ scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS, sortOrder }: any) => {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [errorLogModalData, setErrorLogModalData] = useState(undefined);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const [isInactive, setIsInactive] = useState(false);
    const [csvTitle, setCsvTitle] = useState<any>('IT Oprema');
    const [index, setIndex] = useState<any>('D');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    const { settings } = useSelector((state: RootState) => state.notification);

    const table = useTableSettings();
    useHelper('asset/codebooks/asset');

    const canCreate = () => {
        return AssetRights.canCreateCodebooks();
    };

    const canExportCSV = () => {
        return AssetRights.canCsvExportCodebooks();
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'availabilityStatus', condition: 'equals', value: 1 },
        ];
    };

    let clearKeys: any;

    const clearRowKeys = () => {
        clearKeys();
    };

    const initialColumns = [
        {
            title: geti18nText('asset.table.column.id'),
            dataIndex: 'id',
            width: '7%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('asset.table.column.assetCategory'),
            dataIndex: ['assetCategory', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookAssetCategory(),
            render: (text: any, record: any) => {
                if (record.assetCategory) {
                    return (
                        record.assetCategory.name +
                        (record.assetCategory.code ? ' (' + record.assetCategory.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_TYPE.SEARCH, 'assetType.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.ASSET_MODEL.SEARCH, 'assetModel.id', 'name', 'name', {
                id: 'id',
                label: 'name',
            }),
        },
        {
            title: geti18nText('asset.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.ASSET_STATUS.' + record.status);
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('ASSET_STATUS'), 'in', undefined),
        },
        {
            title: geti18nText('asset.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
            render: (text: any, record: any) => {
                if (record.availabilityStatus !== undefined) {
                    return geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + record.availabilityStatus);
                }
            },
            ...getColumnSearchOption(getEnumArrayStatus('ASSET_AVAILABILITY_STATUS'), 'equals', 1),
        },
        {
            title: geti18nText('asset.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1, undefined, undefined, undefined, clearRowKeys),
        },
    ];

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelected(hasSelected);

        const selectedRowsFilter = selectedRows;

        if (selectedRowsFilter.find((element: { active: boolean }) => element.active === false) !== undefined) {
            setIsInactive(true);
        } else {
            setIsInactive(false);
        }
        setSelectedValues(selectedRowKeys);
        clearKeys = clearSelectedRowKeys;
    };

    const addedButtons = (onSave: () => void) => {
        const deactivate = () => {
            if (selectedValues.length > 0) {
                let values = { ids: selectedValues };
                NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET.DEACTIVATE, undefined, values).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification(geti18nText('asset.deactivate.success'));
                        clearKeys();
                        onSave();
                    } else {
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            }
        };

        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <NyModalConfirm
                            title={geti18nText('asset.deactivate.confirm')}
                            onConfirm={() => {
                                deactivate();
                            }}
                        >
                            <Button block disabled={!hasSelected || isInactive} danger>
                                {geti18nText('asset.button.deactivate')}
                            </Button>
                        </NyModalConfirm>
                    </div>

                    <div style={{ display: 'block', margin: '5px' }}>
                        <NyReportButton
                            url={CONSTANTS_REQ.REPORT.ASSET_SERVICE}
                            customParms={{ ids: selectedValues }}
                            subreportType={'ASSET_SERVICE'}
                            buttoni18nText={'report.assetService'}
                            fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_SERVICE)}
                            disabled={!hasSelected}
                            allowExportTypeChange={true}
                        />
                    </div>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <ImportCSVModal
                            url={CONSTANTS_REQ.ASSET.IMPORT_CSV}
                            onSave={onSave}
                            customInfoMessage={geti18nText('assetImportCSV.upload.info')}
                            disabled={loading}
                        />
                    </div>
                </div>
            </Menu>
        );

        return (
            <div style={{ float: 'left', paddingLeft: '15px' }}>
                <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                    <Button type="primary">
                        {geti18nText('app.default.actions')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="webshop-assets"
                url={CONSTANTS_REQ.ASSET.LIST}
                addNewButtonText={geti18nText('asset.table.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                modalComponent={AssetEdit}
                columns={initialColumns}
                scroll={scroll}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                onDataLoaded={table.onDataLoaded}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                showRowSelection={true}
                rowSelectionType={'checkbox'}
                rowSelectionModal={setSelectedValuesFunc as any}
                addedButtons={canCreate() ? addedButtons : undefined}
                hideNewButton={!canCreate()}
                exportCSV={canExportCSV()}
                //     colCSVHeaderType={csvTitle}
                CSVFileName={geti18nText('menu.asset')}
                colCSVCustomization={csvColumnCustomisation()}
                setDefaultFilterValue={setDefaultFilterValue}
                csvColumns={csvColumns()}
                headerTitle={geti18nText('menu.asset')}
            />
            <NyErrorLogModal
                modalVisible={errorLogModalVisible}
                setModalVisible={setErrorLogModalVisible}
                title={errorLogModalTitle}
                errorData={errorLogModalData}
                setErrorData={setErrorLogModalData}
            />
        </>
    );
};
export const csvColumnCustomisation: any = () => {
    return [
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_STATUS.' + value);
            },
        },
        {
            availabilityStatus: (value: any) => {
                return value != undefined && geti18nText('app.enum.ASSET_AVAILABILITY_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('asset.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('asset.table.column.assetCategory'),
            dataIndex: ['assetCategory', 'name'],
        },
        {
            title: geti18nText('asset.table.column.serialNumber'),
            dataIndex: 'serialNumber',
        },
        {
            title: geti18nText('asset.table.column.assetType'),
            dataIndex: ['assetType', 'name'],
        },
        {
            title: geti18nText('asset.table.column.assetModel'),
            dataIndex: ['assetModel', 'name'],
        },
        {
            title: geti18nText('asset.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('asset.table.column.availabilityStatus'),
            dataIndex: 'availabilityStatus',
        },
        {
            title: geti18nText('asset.table.column.active'),
            dataIndex: 'active',
        },
    ];
};

export default AssetIndex;
