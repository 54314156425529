import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customPersonRenderOption,
    getEnumArray,
    getSearchFormat,
    getUserSelectOption,
    setEnumFormat,
} from '../../../../utils/Utils';
import UserIndex from '../../../tasks/views/user';

const MessageAccessRightEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('message.access.rights.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [hiddenFields, setHiddenFields] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [accessRightType, setAccessRightType] = useState(
        props?.editProps?.accessRightType ? props.editProps.accessRightType : null
    );
    const [readOnly, setReadOnly] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return true;
    };

    function setValues(dataForm: any) {
        setHiddenFields(true);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('message.access.rights.edit'));

        if (dataForm.accessRightType) {
            dataForm.accessRightType = setEnumFormat('MODULE_ACCESS_RIGHT', dataForm.accessRightType);
        }
        if (!dataForm.active) {
            setIsActive(false);
        }

        if (dataForm.createdBy && dataForm.createdBy.person) {
            dataForm.createdText = dataForm.createdBy.person.firstName + ' ' + dataForm.createdBy.person.lastName;

            if (dataForm.created) {
                dataForm.createdText += ' ' + moment(dataForm.created).format('DD.MM.YYYY HH:mm');
            }
        }

        if (dataForm.updatedBy && dataForm.updatedBy.person) {
            dataForm.updatedText = dataForm.updatedBy.person.firstName + ' ' + dataForm.updatedBy.person.lastName;

            if (dataForm.updated) {
                dataForm.updatedText += ' ' + moment(dataForm.updated).format('DD.MM.YYYY HH:mm');
            }
        }
        if (dataForm.user) {
            dataForm.user = getUserSelectOption(dataForm.user);
        }
        setReadOnly(dataForm.readOnly);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setHiddenFields(false);
        setEditHeader(geti18nText('message.access.rights.new'));
        setIsActive(true);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            setValues={setValues}
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.MESSAGE_ACCESS_RIGHT.EDIT}
            width={600}
            form={form}
            hideActivationButtons={!canCreate() || !isActive}
            hideSubmitButton={!canCreate() || !isActive}
            goBack={() => history.goBack()}
            {...props}
            onModalOpen={onModalOpen}
            normalize={(values: any) => {
                values.accessRightType = values?.accessRightType?.id ? values.accessRightType.id : accessRightType;
                values.readOnly = readOnly;
                if (values.user) {
                    values.user = getSearchFormat(values.user);
                }
                return values;
            }}
        >
            {!accessRightType && (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label={geti18nText('message.access.rights.table.type')}
                            name="accessRightType"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={
                                accessRightType != undefined
                                    ? setEnumFormat('MODULE_ACCESS_RIGHT', accessRightType)
                                    : getEnumArray('MODULE_ACCESS_RIGHT').at(0)
                            }
                        >
                            <NySearchField
                                options={getEnumArray('MODULE_ACCESS_RIGHT')}
                                searchBy="text"
                                disabled={hiddenFields || accessRightType != undefined}
                                style={{ width: '100%' }}
                                className=""
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('message.access.rights.table.user')}
                        name="user"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                            }}
                            searchBy="user"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customPersonRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            style={{ width: '100%', maxHeight: '26px' }}
                            placeholder={geti18nText('travelWarrant.edit.employee')}
                            // AddNewModalComponent={OrderViewEdit}
                            addNewItem={false}
                            disabled={hiddenFields}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('message.access.rights.table.readOnly')} name="readOnly">
                        <Checkbox
                            checked={readOnly}
                            onChange={(val: any) => {
                                setReadOnly(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {hiddenFields && (
                <Row gutter={24} justify="center">
                    <Col span={24}>
                        <Form.Item name="createdText" label={geti18nText('message.access.rights.table.created.by')}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {hiddenFields && !isActive && (
                <Row gutter={24} justify="center">
                    <Col span={24}>
                        <Form.Item name="updatedText" label={geti18nText('message.access.rights.table.deactivated.by')}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </NyDataEdit>
    );
};

export default MessageAccessRightEdit;
