import {
    geti18nText,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Modal, Radio, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    okNotification,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import EmployeeGroupIndex from '../employee-group';
import EmployeeGroupSearch from '../employee-group/search';
import ShiftSearch from '../shift/search';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const AddAttendance = (onSave: () => void) => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('employeeGroup');
    const [rewrite, setRewrite] = useState(false);

    const options = [
        { label: geti18nText('attendance.addAttendance.employee'), value: 'employee' },
        { label: geti18nText('attendance.addAttendance.employeeGroup'), value: 'employeeGroup' },
    ];

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendance();
    };

    const save = (event: any) => {
        event.preventDefault();
        setLoading(true);
        form.validateFields()
            .then((values: any) => {
                if (values.dateFrom) values.dateFrom = getDateFormat(values.dateFrom);
                if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
                if (values.timeFrom) values.timeFrom = getDateFormat(values.timeFrom, 'HH:mm');
                if (values.timeTo) values.timeTo = getDateFormat(values.timeTo, 'HH:mm');
                values.rewrite = rewrite;

                if (values.shift) values.shift = getSearchFormat(values.shift);
                if (values.attendanceType) {
                    values.attendanceType = getEnumFormat(values.attendanceType);
                }

                if (values.type === 'employee' && values.employee) {
                    values.ids = values.employee.map((val: any) => val.id);
                    delete values.employee;
                } else if (values.type === 'employeeGroup' && values.employeeGroup) {
                    values.ids = values.employeeGroup.map((val: any) => val.id);
                    delete values.employeeGroup;
                } else {
                    values.ids = [];
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.ATTENDANCE.WRITE, undefined, values).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        setModalVisible(false);
                        onSave();
                        form.resetFields();
                        setRewrite(false);
                    } else {
                        errorNotification();
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
            });
    };

    return (
        canCreate() && (
            <>
                <Button style={{ float: 'left' }} onClick={() => setModalVisible(true)}>
                    {geti18nText('attendance.addAttendance.title')}
                </Button>
                {modalVisible && (
                    <Modal
                        visible={modalVisible}
                        title={geti18nText('attendance.addAttendance.title')}
                        okText={geti18nText('app.default.button.save')}
                        onOk={(e) => save(e)}
                        onCancel={() => {
                            form.resetFields();
                            setModalVisible(false);
                            setRewrite(false);
                            setType('employeeGroup');
                        }}
                        confirmLoading={loading}
                        width={800}
                        maskClosable={false}
                    >
                        <Form
                            layout="vertical"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            form={form}
                            onFinishFailed={({ errorFields }) => {
                                form.scrollToField(errorFields[0].name);
                            }}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item name="type" initialValue={'employeeGroup'}>
                                        <Radio.Group
                                            options={options}
                                            onChange={(e: any) => setType(e.target.value)}
                                            value={type}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    {type === 'employee' ? (
                                        <Form.Item
                                            label={geti18nText('attendance.addAttendance.employee')}
                                            name="employee"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                renderOption={customEmployeeRenderOption}
                                                customItemNameLabel={'firstName lastName'}
                                                mode={'multiple'}
                                                autoFocus={true}
                                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="add-attendance"/>}
                                                nyTestId="input-employee"
                                                order="person.last_name, person.first_name"
                                            />
                                        </Form.Item>
                                    ) : (
                                        <EmployeeGroupSearch
                                            autoFocus={true}
                                            label={geti18nText('attendance.addAttendance.employeeGroup')}
                                            mode={'multiple'}
                                            name={'employeeGroup'}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText('attendance.addAttendance.dateFrom')}
                                        name="dateFrom"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={moment().startOf('month')}
                                    >
                                        <NyDatePicker style={{ width: '100%' }} nyTestId="input-date-from" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText('attendance.addAttendance.dateTo')}
                                        name="dateTo"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={moment()}
                                    >
                                        <NyDatePicker style={{ width: '100%' }} nyTestId="input-date-to" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('attendance.addAttendance.attendanceType')}
                                        name="attendanceType"
                                    >
                                        <NySearchField
                                            options={getEnumArray('ATTENDANCE_TYPE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            nyTestId="input-attendance-type"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label={geti18nText('attendance.addAttendance.shift')} name="shift">
                                        <NySearchField
                                            url={CONSTANTS_REQ.SHIFT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            style={{ width: '100%' }}
                                            nyTestId="input-shift"
                                        />
                                    </Form.Item>
                                    <ShiftSearch
                                        label={geti18nText('attendance.addAttendance.shift')}
                                        name="shift"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={geti18nText('attendance.addAttendance.timeFrom')} name="timeFrom">
                                        <NyDatePicker
                                            mode="time"
                                            format="HH:mm"
                                            style={{ width: '100%' }}
                                            nyTestId="input-time-from"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={geti18nText('attendance.addAttendance.timeTo')} name="timeTo">
                                        <NyDatePicker
                                            mode="time"
                                            format="HH:mm"
                                            style={{ width: '100%' }}
                                            nyTestId="input-time-to"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText('attendance.addAttendance.rewrite')}
                                        name="rewrite"
                                        valuePropName="checked"
                                    >
                                        <NyModalConfirm
                                            title={
                                                !rewrite
                                                    ? geti18nText('attendance.addAttendance.rewrite.yesTitle')
                                                    : geti18nText('attendance.addAttendance.rewrite.noTitle')
                                            }
                                            onConfirm={() => {
                                                setRewrite(!rewrite);
                                            }}
                                        >
                                            <Checkbox checked={rewrite} />
                                        </NyModalConfirm>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                )}
            </>
        )
    );
};

export default AddAttendance;
