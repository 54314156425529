import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { customEmployeeRenderOption, errorNotification, okNotification } from '../../../../utils/Utils';
import EmployeeIndex from '../../../human/views/employee';

const GeneralEditTab = ({ employeeId, setEmployeeId, defaultEmployee, isCreate, form }: any) => {
    function employeeOnChange(value: any) {
        if (!(value?.id >= 0)) {
            setEmployeeId(undefined);
            form.setFieldsValue({
                firstName: '',
                lastName: '',
                email: '',
            });
            return;
        }
        setEmployeeId(value.id);
        if (value.id > -1) {
            form.setFieldsValue({
                firstName: value.text.props['first-name'],
                lastName: value.text.props['last-name'],
                email: value.text.props['e-mail'],
            });
        }
    }

    return (
        <>
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        name="firstName"
                        label={geti18nText('user.auth.edit.firstName')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input autoFocus disabled={employeeId !== undefined} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="lastName"
                        label={geti18nText('user.auth.edit.lastName')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled={employeeId !== undefined} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="username"
                        label={geti18nText('user.auth.edit.username')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input disabled={!isCreate} />
                    </Form.Item>
                </Col>
                {isCreate ? (
                    <Col span={12}>
                        <Form.Item name="password" label={geti18nText('user.auth.edit.password')}>
                            <Input type="password" />
                        </Form.Item>
                    </Col>
                ) : (
                    <Col span={12}>
                        <Form.Item label={<br />}>
                            <Button
                                type={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    NyRequestResolver.requestPut(
                                        CONSTANTS_REQ.USER_AUTH.PASSWORD_RESET.replace(
                                            '{id}',
                                            form.getFieldValue('id')
                                        ),
                                        undefined,
                                        {
                                            id: form.getFieldValue('id'),
                                            redirectApplicationUrl: document.location.origin,
                                        }
                                    ).then((result: any) => {
                                        if (result && result.status === RESPONSE.OK) {
                                            okNotification(geti18nText('user.auth.edit.password.reset.success'), '');
                                        } else {
                                            errorNotification(geti18nText('app.default.save.nok'));
                                        }
                                    });
                                }}
                            >
                                {geti18nText('user.auth.edit.password.reset')}
                            </Button>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="email"
                        label={geti18nText('user.auth.edit.email')}
                        rules={[
                            {
                                type: 'email',
                                message: geti18nText('app.default.valid.email'),
                            },
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('payment.calculation.edit.employee')} name="employeeId">
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.UNASSIGNED_SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                person: 'person',
                                employmentRecordId: 'employmentRecordId',
                                contact: 'contact',
                            }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={
                                <EmployeeIndex userless={true} disabled={true} parentKey="users-general" />
                            }
                            order="person.last_name, person.first_name"
                            onChange={employeeOnChange}
                            mustGetPopupContainer={false}
                            setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}></Row>
            {isCreate ? (
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name="description" label={geti18nText('user.auth.edit.description')}>
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                defaultEmployee?.id &&
                employeeId !== defaultEmployee.id && (
                    <Row gutter={24}>
                        <Col span={12} />
                        <Col span={12}>
                            <Button
                                type={'primary'}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    form.setFieldValue('employeeId', defaultEmployee);
                                    form.setFieldsValue({
                                        firstName: defaultEmployee.firstName,
                                        lastName: defaultEmployee.lastName,
                                    });
                                    setEmployeeId(defaultEmployee.id);
                                }}
                            >
                                {geti18nText('user.auth.edit.employee.return.default')}
                            </Button>
                        </Col>
                    </Row>
                )
            )}
        </>
    );
};

export default GeneralEditTab;
