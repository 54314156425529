import { NyDataTable, NyDatePicker, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../utils/Filters';
import DailyAttendanceIcons from '../attendance-registration/DailyAttendanceIcons';
import EmployeeLeaveTypeIcons from './EmployeeLeaveTypeIcons';
import EmployeeTravelWarrantIcons from './EmployeeTravelWarrantIcons';

const EmployeeAttendanceIndex = ({ showRowSelection, scroll = { y: 583, x: 800 }, sortOrder }: any) => {
    const [refresh, setRefresh] = useState(0);
    const [date, setDate] = useState<any>(moment());
    const table = useTableSettings();

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [date]);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'date', condition: 'equals', value: date.format('YYYY-MM-DD') },
        ];
    };

    const columns = [
        {
            title: geti18nText('attendance.table.column.employee'),
            dataIndex: "concat(person.first_name,' ', person.last_name)",
            width: '10%',
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record && record.person) {
                    return (
                        <div>
                            {record.person.firstName +
                                ' ' +
                                record?.person.lastName +
                                (record?.employmentRecordId ? ' (' + record.employmentRecordId + ')' : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('attendance.edit.type'),
            dataIndex: 'type',
            width: '180px',
            render: (text: any, record: any) => {
                return (
                    (record?.attendanceRegistration && geti18nText('menu.attendance.registration')) ||
                    (record?.employeeLeaveType && geti18nText('menu.employeeLeave')) ||
                    (record?.travelWarrant && geti18nText('travelWarrant.edit.title'))
                );
            },
        },
        {
            dataIndex: 'attendanceRegistration',
            render: (text: any, record: any) => {
                return (
                    (record?.attendanceRegistration && <DailyAttendanceIcons record={record} noEditModal />) ||
                    (record?.employeeLeaveType && <EmployeeLeaveTypeIcons record={record} />) ||
                    (record?.travelWarrant && <EmployeeTravelWarrantIcons record={record} />)
                );
            },
        },
    ];

    return (
        <>
            <Form.Item label={geti18nText('attendance.edit.date')} style={{ marginBottom: '-20px' }}>
                <NyDatePicker
                    format="DD.MM.YYYY."
                    value={date}
                    style={{ width: '100%', maxWidth: '150px' }}
                    onChange={(value: any) => {
                        setDate(value ? moment(value) : null);
                    }}
                    mustGetPopupContainer={false}
                    allowClear={false}
                />
            </Form.Item>
            <NyDataTable
                nyId="human-employee-attendance-registration-table"
                url={CONSTANTS_REQ.ATTENDANCE_REGISTRATION.EMPLOYEE_LIST}
                hideButtons
                buttonsClassName="buttons-sticky"
                columns={columns}
                setDefaultSortOrder={sortOrder}
                scroll={scroll}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                showRowSelection={showRowSelection}
                shortcuts={true}
                nyTestId="human-employee-attendance-registration-table"
                fetchWhenChange={refresh}
                onRowSelect={() => null}
            />
        </>
    );
};

export default EmployeeAttendanceIndex;
