import { Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { getDateFormat, getOrdinal, getTravelWarrantStatusIcon } from '../../../../utils/Utils';

const EmployeeTravelWarrantIcons = ({ record }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const item: any = JSON.parse(record?.travelWarrant?.value);
    const formattedDateFrom = getDateFormat(item?.start_date, 'DD.MM.yyyy.');
    const formattedDateTo = getDateFormat(item?.end_date, 'DD.MM.yyyy.');
    const warrantDate = item?.warrant_date;
    const warrant = { ...item, warrantDate };
    return (
        <>
            <Tag
                style={{
                    position: 'relative',
                    color: isDarkTheme ? 'lightgray' : 'black',
                    cursor: 'default',
                }}
            >
                <div
                    key={item.id}
                    style={{
                        display: 'inline-flex',
                    }}
                >
                    {formattedDateFrom} - {formattedDateTo}
                </div>
            </Tag>
            <Tag
                style={{
                    position: 'relative',
                    color: isDarkTheme ? 'lightgray' : 'black',
                    cursor: 'default',
                }}
            >
                <div
                    key={item.id}
                    style={{
                        display: 'inline-flex',
                    }}
                >
                    {getOrdinal(warrant)}
                </div>
            </Tag>
            <Tooltip title={warrant?.purpose_info}>
                <Tag
                    style={{
                        position: 'relative',
                        color: isDarkTheme ? 'lightgray' : 'black',
                        cursor: 'default',
                    }}
                >
                    <div
                        key={item.id}
                        style={{
                            display: 'inline-flex',
                        }}
                    >
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '800px',
                            }}
                        >
                            {warrant?.purpose_info}
                        </span>
                    </div>
                </Tag>
            </Tooltip>
            {getTravelWarrantStatusIcon(warrant?.status)}
        </>
    );
};

export default EmployeeTravelWarrantIcons;
