import { BellOutlined, DeleteOutlined, PushpinFilled, PushpinOutlined, ShrinkOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, NySession, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { iResponse } from '@nybble/nyreact/build/utils/Types';
import { Badge, Button, Col, Collapse, Empty, List, Popover, Row, Tooltip, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { setModalOpen } from '../../slices/modalOpenSlice';
import { addNotification, addSettings } from '../../slices/notificationSlice';
import { setPinedNotifications } from '../../slices/pinedNotificationsSlice';
// import { updateTab } from '../../slices/tabsSlice';
import { SSE } from 'sse.js';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { mailClose, mailOpen } from '../../utils/CustomIcons';
import { GetEnumNameForValue } from '../../utils/Enums';
import DEFAULT_NOTIFICATION_SETTINGS from '../../utils/NotificationSettings';
import { clearCache, setEnumFormat } from '../../utils/Utils';
import NyErrorLogModal from '../error-log-modal';
import NyNotifNoteModal from '../notif-note-modal';
import { getNotificationData } from './getNotificationData';

const { Title } = Typography;
const { Panel } = Collapse;

const NotificationsIndex = ({ isPinned = false }: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pinedNotifications } = useSelector((state: RootState) => state.pinedNotifications);
    const [notificationsVisible, setNotificationsVisible] = useState(false);
    const [activePanel, setActivePanel] = useState(undefined);

    const eventSourceRef = useRef<any>(null);

    const [rowSelected, setRowSelected] = useState(undefined);
    const [notificationSettings, setNotificationSettings] = useState<any>([]);

    const [errorLogModalData, setErrorLogModalData] = useState([]);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const [errorLogModalLink, setErrorLogModalLink] = useState<any>();
    const [notificationSwitchType, setNotificationSwitchType] = useState<any>(undefined);

    const [noteModalData, setNoteModalData] = useState([]);
    const [noteModalVisible, setNoteModalVisible] = useState(false);
    const [noteModalTitle, setNoteModalTitle] = useState<any>('');
    const [noteModalKey, setNoteModalKey] = useState<any>(undefined);
    const [listData, setListData] = useState<any>();
    const [notifications, setNotifications] = useState<any>([]);

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const { count } = useSelector((state: RootState) => state.notification);

    const [laodingBtns, setLoadingBtns] = useState([]);

    const [initLoading, setInitLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const [loadingGroups, setLoadingGroups] = useState(true);

    const { employee } = useSelector((state: RootState) => state.employee);

    const [offset, setOffset] = useState<any>(0);
    const [hasMoreToLoad, setHasMoreToLoad] = useState<boolean>(true);

    useEffect(() => {
        sseConection();
        getNotificationsCount();

        let sscInterval = setInterval(function () {
            sseConection();
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(sscInterval);
        };
    }, []);

    useEffect(() => {
        if (notificationsVisible || isPinned) {
            getNotificationsCount();
            getNotificationSettings();
        }
    }, [notificationsVisible]);

    const sseConection = () => {
        eventSourceRef.current = new SSE(CONSTANTS_REQ.NOTIFICATION.GET_ALL, {
            headers: {
                Authorization: 'Bearer ' + NySession.getUserToken(),
            },
        });

        eventSourceRef.current.onmessage = (e: any) => {
            dispatch(addNotification(JSON.parse(e.data)));
        };

        eventSourceRef.current.onerror = async (e: any) => {
            console.error('Error ', e);

            if (e?.source?.xhr?.status === RESPONSE.UNAUTORIZED) {
                const success = await NySession.requestRefreshToken();
                if (success) {
                    return sseConection();
                } else {
                    clearCache();
                    NySession.logoutUser();
                    window.location.reload();
                    let retValue = { status: 0, data: {} } as iResponse;
                    return retValue;
                }
            }
        };

        eventSourceRef.current.stream();
    };

    const getNotificationsCount = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION.COUNT_ALL, {}).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const hashMap = result.data.reduce((acc: any, element: any) => {
                    acc[element.notificationType] = element;
                    return acc;
                }, {});
                dispatch(addNotification(hashMap));
                setNotifications(hashMap);
            } else {
                // errorNotification(geti18nText('app.download.error'));
            }
        });
    };

    // useEffect(() => {
    //     let temp = [...tabs];
    //     setTab(() => temp);
    // }, [tabs]);

    useEffect(() => {
        setOffset(0);
        if (activePanel != undefined) {
            setLoading(true);
            getNotificationByType(activePanel, true, 0);
        }
    }, [activePanel]);

    function getEnumKeyByNotificationType(notificationType: any) {
        return GetEnumNameForValue({
            enumName: 'NOTIFICATION_TYPE',
            value: Number(notificationType),
        });
    }

    function compare(a: any, b: any) {
        if (a.sort < b.sort) {
            return -1;
        }
        if (a.sort > b.sort) {
            return 1;
        }
        return 0;
    }

    const getNotificationSettings = () => {
        setLoadingGroups(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION_SETTINGS.LIST, { max: 100 })
            .then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data.content) {
                        setNotificationSettings(result.data.content.sort(compare));
                        dispatch(addSettings(result.data.content));
                    } else {
                        setNotificationSettings(DEFAULT_NOTIFICATION_SETTINGS.sort(compare));
                    }
                } else {
                    setNotificationSettings(DEFAULT_NOTIFICATION_SETTINGS.sort(compare));
                }
            })
            .finally(() => {
                setLoadingGroups(false);
            });
    };

    function addTabPane(path: string, notificaiton: any, state: any = undefined) {
        if (state?.replace) {
            delete state.replace;
            history.replace({ pathname: path, state: state });
        } else {
            history.push({ pathname: path, state: state });
        }

        if (notificaiton.unread) {
            notificationRead(notificaiton);
        }
    }

    const openItemInTab = (path: any, modalName: any, item: any, notif: any, state: any) => {
        setNotificationsVisible(false);
        addTabPane(path, notif, state);
        dispatch(
            setModalOpen({
                modal: modalName,
                visible: true,
                item: item,
            })
        );
    };

    const openNoteInModal = (item: any, notif: any, title: any, key: any = undefined) => {
        if (notif.unread) {
            notificationRead(notif);
        }

        setNoteModalData(item);
        setNoteModalKey(key);
        setNoteModalVisible(true);
        setNoteModalTitle(title);
    };

    const onReadAllButtonClick = () => {
        if (activePanel !== undefined) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.READ_ALL, undefined, {
                notificationType: activePanel,
            }).then(() => {
                getNotificationByType(activePanel);
                getNotificationsCount();
            });
        } else {
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.READ_ALL, undefined, {}).then(() => {
                getNotificationByType(activePanel);
                getNotificationsCount();
            });
        }
    };

    const onDeleteAllButtonClick = () => {
        if (activePanel !== undefined) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.DELETE_ALL, undefined, {
                notificationType: activePanel,
            }).then(() => {
                getNotificationByType(activePanel);
                getNotificationsCount();
            });
        } else {
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.DELETE_ALL, undefined, {}).then(() => {
                getNotificationByType(activePanel);
                getNotificationsCount();
            });
        }
    };

    const onPinToDashboardClick = () => {
        setNotificationsVisible(false);
        dispatch(setPinedNotifications(1));
    };

    const notificationRead = (notification: any) => {
        const params = {
            id: notification.id,
            unread: !notification.unread,
        };
        NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.EDIT + '/' + notification.id, undefined, params).then(
            () => {
                getNotificationByType(activePanel);
                getNotificationsCount();
            }
        );
    };

    function notificationDelete(notification: any) {
        const params = {
            id: notification.id,
        };
        NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.DELETE + '/' + notification.id, undefined, params).then(
            () => {
                getNotificationByType(activePanel);
                getNotificationsCount();
            }
        );
    }

    const getLink = (link: any) => {
        if (link.function === 'addTabPane') {
            addTabPane(link.path, link.notification, link.state);
        } else if (link.function === 'openItemInTab') {
            openItemInTab(link.path, link.modalName, link.item, link.notification, link?.state);
        } else if (link.function === 'openNoteInModal') {
            openNoteInModal(link.item, link.notification, link.title, link.key);
        }
    };

    function readDeleteButtons(data: any) {
        return (
            <Col span={6}>
                <div style={{ width: '100%' }}>
                    <Button
                        style={{ marginRight: '2px' }}
                        shape="circle"
                        icon={data.unread ? mailClose : mailOpen}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            notificationRead(data);
                        }}
                    />
                    <Button
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            notificationDelete(data);
                        }}
                    />
                </div>
            </Col>
        );
    }

    const itemRow = (data: any) => {
        const notifData = getNotificationData(data, employee, dispatch);
        return [
            <Row
                gutter={24}
                className={
                    data.unread
                        ? `${rowSelected === data.id ? 'ny-notification-selected' : 'ny-notification-unread'}`
                        : `${rowSelected === data.id ? 'ny-notification-selected' : 'ny-notification'}`
                }
                onClick={() => {
                    if (rowSelected === data.id && notifData && !notifData.hideMenuLink && notifData.link) {
                        getLink(notifData.link);
                    } else {
                        setRowSelected(data.id);
                    }
                }}
                style={{
                    cursor: 'pointer',
                    width: '100%',
                }}
            >
                <Col span={18}>
                    <h4>{notifData.content}</h4>
                    {notifData.errors && (
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                notifData.notificationSwitchType != undefined &&
                                    setNotificationSwitchType(notifData && notifData.notificationSwitchType);
                                setErrorLogModalLink(notifData && notifData.link);
                                setErrorLogModalData(notifData && notifData.errors && notifData.errors.data);
                                setErrorLogModalVisible(true);
                                setErrorLogModalTitle(notifData && notifData.errors && notifData.errors.title);
                            }}
                        >
                            {geti18nText('notification.error.details')}
                        </Button>
                    )}
                </Col>
                {readDeleteButtons(data)}
            </Row>,
        ];
    };

    const getNotificationByType = async (type: any, updateOffset = false, offsetInit?: any) => {
        let offsetValue = offsetInit ?? offset;
        if (!updateOffset) {
            offsetValue = offsetValue == 0 ? offsetValue : offsetValue - 1;
        }

        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION.GET_BY_TYPE, {
            notificationType: type?.notificationType ? type?.notificationType : type,
            offset: offsetValue,
        }).then((result: any) => {
            if (result && result.data && result.data.content) {
                setHasMoreToLoad(result?.data?.offset + result?.data?.numberOfElements < result?.data?.totalSize);

                var copyArray = JSON.parse(JSON.stringify(notifications));

                const unread = copyArray[type]?.unread;
                copyArray[type] = { ...copyArray[type], content: result.data.content };
                copyArray[type].unread = unread;
                var loadingBtnArray: any = [...laodingBtns];
                loadingBtnArray[type] = true;
                setLoadingBtns(loadingBtnArray);
                if (updateOffset) {
                    if (offsetInit) {
                        setOffset(offsetInit + 1);
                    } else {
                        setOffset((prevValue: any) => prevValue + 1);
                    }
                }
                setListData(copyArray[type]);
                setLoading(false);
            } else {
                var loadingBtnArray: any = [...laodingBtns];
                loadingBtnArray[type] = false;
                setLoadingBtns(loadingBtnArray);

                setListData([]);
                setLoading(false);
            }
        });
    };

    const onLoadMore = (type: any) => {
        setLoading(true);
        getNotificationByType(type, true, offset);
    };

    const loadMore = (type: any) => {
        return !initLoading &&
            !loading &&
            (laodingBtns[type] == undefined || laodingBtns[type] == true) &&
            hasMoreToLoad ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={(e) => onLoadMore(type)}>{geti18nText('app.default.btn.loading.more')}</Button>
            </div>
        ) : null;
    };

    const getNotificationItems = (notificationType: any) => {
        return loading ? (
            <NySpinner />
        ) : (
            <>
                {errorLogModalVisible && (
                    <NyErrorLogModal
                        modalVisible={errorLogModalVisible}
                        setModalVisible={setErrorLogModalVisible}
                        title={errorLogModalTitle}
                        errorData={errorLogModalData}
                        setErrorData={setErrorLogModalData}
                        addTabPane={addTabPane}
                        errorDataLink={errorLogModalLink}
                        setNotificationsVisible={setNotificationsVisible}
                        notificationSwitchType={notificationSwitchType}
                    />
                )}
                {noteModalVisible && (
                    <NyNotifNoteModal
                        modalVisible={noteModalVisible}
                        setModalVisible={setNoteModalVisible}
                        title={noteModalTitle}
                        data={noteModalData}
                        setData={setNoteModalData}
                        notifKey={noteModalKey}
                        setNotifKey={setNoteModalKey}
                    />
                )}
                <List
                    className="demo-loadmore-list"
                    loading={false}
                    itemLayout="horizontal"
                    loadMore={loadMore(notificationType)}
                    dataSource={listData?.content}
                    // dataSource={getNotificationByType(notificationType)}
                    renderItem={(item) => <List.Item>{itemRow(item)}</List.Item>}
                />
            </>
        );
    };

    function getNotificationGroups() {
        let items = [];
        let notificationType: any;

        for (notificationType of notificationSettings) {
            let enumKey: any = getEnumKeyByNotificationType(notificationType.notificationType);

            if (
                enumKey &&
                notifications &&
                notifications[notificationType.notificationType]
                // notifications[notificationType.notificationType].content &&
                // notifications[notificationType.notificationType].content.length > 0
            ) {
                let typeName: any = setEnumFormat('NOTIFICATION_TYPE', Number(notificationType.notificationType));

                items.push(
                    <Panel
                        showArrow={false}
                        header={
                            <Row gutter={24} style={{ width: '100%' }}>
                                <Col span={20}> {typeName && typeName.text ? typeName.text : ''}</Col>
                                <Col span={4} style={{ textAlign: 'right' }}>
                                    <Badge
                                        count={count && count[enumKey] ? count[enumKey] : 0}
                                        style={{
                                            fontSize: '13px',
                                            minWidth: '24px',
                                            height: '24px',
                                            lineHeight: '24px',
                                            color: '#252525',
                                            fontWeight: 'bold',
                                            borderRadius: '24px',
                                            backgroundColor: notificationType.color
                                                ? notificationType.color
                                                : '#ff4d4f',
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                        key={notificationType.notificationType}
                    >
                        <div className={'ny-notification-panel'}>
                            {activePanel == notificationType.notificationType && getNotificationItems(notificationType)}
                        </div>
                    </Panel>
                );
            }
        }

        return items;
    }

    const popoverContent = () => {
        let notifData = notifications ? { ...notifications } : undefined;
        if (notifData && notifData.hasOwnProperty('10')) {
            delete notifData['10'];
        }

        if (notifData !== null && Object.keys(notifData).length > 0) {
            return [
                <div style={isMobile ? { width: 300 } : { width: 400 }}>
                    <Collapse
                        style={{ maxHeight: '83vh', overflow: 'auto' }}
                        accordion
                        destroyInactivePanel
                        onChange={(data: any) => {
                            if (data) {
                                setActivePanel(data);
                            } else {
                                setActivePanel(undefined);
                            }
                        }}
                    >
                        {loadingGroups ? <NySpinner /> : getNotificationGroups()}
                    </Collapse>
                    <div>
                        <NyModalConfirm
                            title={geti18nText('app.default.button.popconfirm.markAsRead')}
                            onConfirm={() => {
                                onReadAllButtonClick();
                            }}
                        >
                            <Button className={'ny-notification-button'}>
                                {activePanel !== undefined
                                    ? geti18nText('app.default.button.markAsRead.group')
                                    : geti18nText('app.default.button.markAsRead')}
                            </Button>
                        </NyModalConfirm>
                        <NyModalConfirm
                            title={geti18nText('app.default.button.popconfirm.delete.all')}
                            onConfirm={() => {
                                onDeleteAllButtonClick();
                            }}
                        >
                            <Button className={'ny-notification-button-delete'}>
                                {activePanel !== undefined
                                    ? geti18nText('app.default.button.delete.all.group')
                                    : geti18nText('app.default.button.delete.all')}
                            </Button>
                        </NyModalConfirm>

                        <Tooltip title={geti18nText('tabs.pin')}>
                            <Button
                                onClick={onPinToDashboardClick}
                                className={'ny-notification-button-pin'}
                                icon={<PushpinFilled />}
                            ></Button>
                        </Tooltip>
                    </div>
                </div>,
            ];
        } else {
            return [
                <div style={isMobile ? { width: 300 } : { width: 400 }}>
                    <Empty description={<Title level={5}>{geti18nText('notification.noData')}</Title>} />
                </div>,
            ];
        }
    };

    const pinnedPopoverContent = () => {
        let notifData = notifications ? { ...notifications } : undefined;
        if (notifData && notifData.hasOwnProperty('10')) {
            delete notifData['10'];
        }

        if (notifData && Object.keys(notifData).length > 0) {
            return (
                <div
                    style={{
                        width: 300,
                        right: 0,
                        position: pinedNotifications == 2 ? 'fixed' : 'relative',
                        height: '90vh',
                        overflowY: 'auto',
                    }}
                >
                    {pinedNotifications == 1 ? (
                        <div style={{ width: '296px', marginTop: 8 }}>
                            <Collapse defaultActiveKey={['1']}>
                                <Panel
                                    header={
                                        <div style={{ marginTop: '4px', marginLeft: '4px' }}>
                                            {geti18nText('menu.application.notifications')}
                                        </div>
                                    }
                                    key="1"
                                    showArrow={false}
                                    collapsible="icon"
                                    extra={
                                        <>
                                            <Tooltip title={geti18nText('app.default.minimise')}>
                                                <Button
                                                    icon={<ShrinkOutlined />}
                                                    onClick={() => dispatch(setPinedNotifications(2))}
                                                ></Button>
                                            </Tooltip>
                                            <Tooltip title={geti18nText('tabs.unpin')}>
                                                <Button
                                                    onClick={() => dispatch(setPinedNotifications(0))}
                                                    icon={<PushpinOutlined />}
                                                ></Button>
                                            </Tooltip>
                                        </>
                                    }
                                >
                                    {/*    <div style={{ height: '30px' }}>
                                        <div style={{ float: 'left', padding: 5 }}>
                                            {geti18nText('menu.application.notifications')}
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Tooltip title={geti18nText('app.default.minimise')}>
                                                <Button
                                                    icon={<ShrinkOutlined />}
                                                    onClick={() => dispatch(setPinedNotifications(2))}
                                                ></Button>
                                            </Tooltip>
                                            <Tooltip title={geti18nText('tabs.unpin')}>
                                                <Button
                                                    onClick={() => dispatch(setPinedNotifications(0))}
                                                    icon={<PushpinOutlined />}
                                                ></Button>
                                            </Tooltip>
                                        </div>
                                    </div> */}
                                    <Collapse
                                        accordion
                                        destroyInactivePanel
                                        onChange={(data: any) => {
                                            if (data) {
                                                setActivePanel(data);
                                            } else {
                                                setActivePanel(undefined);
                                            }
                                        }}
                                    >
                                        {loadingGroups ? <NySpinner /> : getNotificationGroups()}
                                    </Collapse>
                                    <div>
                                        <NyModalConfirm
                                            title={geti18nText('app.default.button.popconfirm.markAsRead')}
                                            onConfirm={() => {
                                                onReadAllButtonClick();
                                            }}
                                        >
                                            <Button className={'ny-notification-button'}>
                                                {activePanel !== undefined
                                                    ? geti18nText('app.default.button.markAsRead.group.pinned')
                                                    : geti18nText('app.default.button.markAsRead')}
                                            </Button>
                                        </NyModalConfirm>
                                        <NyModalConfirm
                                            title={geti18nText('app.default.button.popconfirm.delete.all')}
                                            onConfirm={() => {
                                                onDeleteAllButtonClick();
                                            }}
                                        >
                                            <Button className={'ny-notification-button'}>
                                                {activePanel !== undefined
                                                    ? geti18nText('app.default.button.delete.all.group.pined')
                                                    : geti18nText('app.default.button.delete.all')}
                                            </Button>
                                        </NyModalConfirm>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    ) : pinedNotifications == 2 ? (
                        <div
                            style={{
                                width: '30px',
                                transform: 'rotate(-90deg)',
                                position: 'fixed',
                                top: '50%',
                                right: 0,
                            }}
                        >
                            <Button
                                icon={<BellOutlined />}
                                onClick={() => dispatch(setPinedNotifications(1))}
                                style={{ opacity: 1 }}
                            >
                                {geti18nText('menu.application.notifications')}
                            </Button>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            );
        } else {
            return (
                <>
                    {(loading || loadingGroups) && !(notifData && Object.keys(notifData).length > 0) ? (
                        <div style={{ width: '300px', height: '200px', display: 'inline-grid' }}>
                            <NySpinner />
                        </div>
                    ) : (
                        <div style={{ width: 300, marginTop: 60 }}>
                            <Empty description={<Title level={5}>{geti18nText('notification.noData')}</Title>} />
                            <Button
                                style={{ float: 'right', marginRight: '4px' }}
                                onClick={() => dispatch(setPinedNotifications(0))}
                                icon={<PushpinOutlined />}
                            ></Button>
                        </div>
                    )}
                </>
            );
        }
    };

    const notificationButton = (
        <Tooltip title={geti18nText('notifications.overview.table.header')}>
            <Button
                style={{ borderRadius: '50%' }}
                className="bell-icon"
                icon={
                    <Badge
                        style={{
                            marginTop: '-7px',
                            right: '-4px',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            paddingBottom: '15px',
                        }}
                        count={count && count['ALL'] ? count['ALL'] : 0}
                        size="small"
                        offset={[0, -5]}
                    >
                        <BellOutlined style={{ transition: 'color 0.3s ease' }} />
                    </Badge>
                }
            ></Button>
        </Tooltip>
    );

    return (
        <>
            {isPinned ? (
                pinnedPopoverContent()
            ) : pinedNotifications == 0 ? (
                <Popover
                    content={notificationsVisible ? popoverContent : <></>}
                    trigger="click"
                    placement="topRight"
                    open={notificationsVisible}
                    onOpenChange={(visible: any) => setNotificationsVisible(visible)}
                >
                    {notificationButton}
                </Popover>
            ) : (
                { notificationButton }
            )}
        </>
    );
};

export default NotificationsIndex;
