import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearchCheckbox,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Popover, Tag, Tooltip } from 'antd';
import { useState } from 'react';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookUserPersonWithAlias } from '../../../../utils/Filters';
import { errorNotification, getDateFormat, getEnumArray, okNotification } from '../../../../utils/Utils';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';
import ScheduleSuggestionsEdit from './edit';
import PlanActions from './scheduleSuggestionsActions';

const ScheduleSuggestionsIndex = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const militaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const militaryWorkApprovalStatus = useEnum('MILITARY_WORK_APPROVAL_STATUS');
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    const canCreateActions = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getEnumMilitaryTypeArray = function () {
        return [
            { id: 1, text: geti18nText('app.enum.MILITARY.TYPE.1') },
            { id: 2, text: geti18nText('app.enum.MILITARY.TYPE.2') },
        ];
    };

    const onAction = (record: any) => {
        let data = {
            active: !record.active,
        };
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.MILITARY_WORK_APPROVAL.ACTIVATE_STATUS + '/' + record.id,
            undefined,
            data
        ).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'));
                setRefreshTable((refresh: any) => refresh + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const content = (item: any) => {
        if (
            item.sentObjectName ||
            item.sentClassification ||
            item.sentSubmissionNumber ||
            item.sentInputDate ||
            item.sentOutputDate
        ) {
            return (
                <div>
                    {item.sentObjectName && (
                        <p>{geti18nText('schedule.suggestions.edit.approval.name') + ': ' + item.sentObjectName} </p>
                    )}
                    {item.sentClassification && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.classification') + ': ' + item.sentClassification}{' '}
                        </p>
                    )}
                    {item.sentSubmissionNumber && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.registry.number') +
                                ': ' +
                                item.sentSubmissionNumber}{' '}
                        </p>
                    )}
                    {item.sentInputDate && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.date.document.input') +
                                ': ' +
                                getDateFormat(item.sentInputDate, 'DD.MM.YYYY')}{' '}
                        </p>
                    )}
                    {item.sentOutputDate && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.date.document.output') +
                                ': ' +
                                getDateFormat(item.sentOutputDate, 'DD.MM.YYYY')}{' '}
                        </p>
                    )}
                </div>
            );
        }
        return null;
    };

    const formStatusTagColor = (status: any) => {
        switch (status) {
            case 0:
                return 'blue';
            case 1:
                return 'gold';
            case 2:
                return 'green';
        }
    };

    const displayAdditionalInfo = (item: any) => {
        if (
            item.objectName ||
            item.classification ||
            item.submissionNumber ||
            item.inputDate ||
            item.outputDate ||
            item.sentObjectName ||
            item.sentClassification ||
            item.sentSubmissionNumber ||
            item.sentInputDate ||
            item.sentOutputDate
        ) {
            return (
                <div>
                    {item.objectName && (
                        <p>{geti18nText('schedule.suggestions.edit.approval.name') + ': ' + item.objectName} </p>
                    )}
                    {item.classification && (
                        <p>{geti18nText('schedule.suggestions.edit.classification') + ': ' + item.classification} </p>
                    )}
                    {item.submissionNumber && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.registry.number') +
                                ': ' +
                                item.submissionNumber}{' '}
                        </p>
                    )}
                    {item.inputDate && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.date.document.input') +
                                ': ' +
                                getDateFormat(item.inputDate, 'DD.MM.YYYY')}{' '}
                        </p>
                    )}
                    {item.outputDate && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.date.document.output') +
                                ': ' +
                                getDateFormat(item.outputDate, 'DD.MM.YYYY')}{' '}
                        </p>
                    )}

                    {(item.sentObjectName ||
                        item.sentClassification ||
                        item.sentSubmissionNumber ||
                        item.sentInputDate ||
                        item.sentOutputDate) && (
                        <p>{geti18nText('schedule.suggestions.approved.details.selected.as.sent.to.morh')}</p>
                    )}
                    {item.sentObjectName && (
                        <p>{geti18nText('schedule.suggestions.edit.approval.name') + ': ' + item.sentObjectName} </p>
                    )}
                    {item.sentClassification && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.classification') + ': ' + item.sentClassification}{' '}
                        </p>
                    )}
                    {item.sentSubmissionNumber && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.registry.number') +
                                ': ' +
                                item.sentSubmissionNumber}{' '}
                        </p>
                    )}
                    {item.sentInputDate && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.date.document.input') +
                                ': ' +
                                getDateFormat(item.sentInputDate, 'DD.MM.YYYY')}{' '}
                        </p>
                    )}
                    {item.sentOutputDate && (
                        <p>
                            {geti18nText('schedule.suggestions.edit.approval.date.document.output') +
                                ': ' +
                                getDateFormat(item.sentOutputDate, 'DD.MM.YYYY')}{' '}
                        </p>
                    )}
                </div>
            );
        }
        return null;
    };

    const militaryApprovalStatusOptions = [
        {
            id: 0,
            text: geti18nText('app.enum.MILITARY_WORK_APPROVAL_STATUS.' + 0),
        },
        {
            id: 1,
            text: geti18nText('app.enum.MILITARY_WORK_APPROVAL_STATUS.' + 1),
        },
        {
            id: 2,
            text:
                geti18nText('app.enum.MILITARY_WORK_APPROVAL_STATUS.' + 2) +
                '/' +
                geti18nText('app.enum.MILITARY_WORK_APPROVAL_STATUS.' + 3),
        },
    ];

    const columns = [
        ...[
            {
                title: geti18nText('schedule.suggestions.table.column.type'),
                dataIndex: 'type',
                width: '260px',
                sorter: (a: any, b: any) => {},
                render: (created: any, record: any) => {
                    if (record.type) {
                        return (
                            <div>
                                {geti18nText('app.enum.MILITARY.TYPE.' + record.type) +
                                    (record.type === militaryWorkApprovalType.MORH
                                        ? record.defenseDepartment
                                            ? ' - ' + record.defenseDepartment.name
                                            : record.defenseSection
                                            ? ' - ' +
                                              geti18nText('schedule.suggestions.table.column.section') +
                                              ' ' +
                                              record.defenseSection.name
                                            : ''
                                        : '')}
                            </div>
                        );
                    }
                },
                ...getColumnSearchOption(getEnumMilitaryTypeArray(), 'equals'),
            },
            {
                title: geti18nText('schedule.suggestions.table.column.action'),
                dataIndex: 'action',
                width: '240px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(getEnumArray('MILITARY_WORK_APPROVAL_ACTION')),
                render: (text: any, record: any) => {
                    if (record.action) {
                        return geti18nText('app.enum.MILITARY_WORK_APPROVAL_ACTION.' + record.action);
                    }
                },
            },
        ],
        {
            title: geti18nText('schedule.suggestions.table.column.created'),
            dataIndex: 'created',
            width: '140px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.created) {
                    return getDateFormat(record.created, 'DD.MM.YYYY HH:mm:ss');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.table.column.user'),
            dataIndex: 'createdBy',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.createdBy) {
                    return (
                        <a style={{ textDecoration: 'underline' }} onClick={() => modalVisible(record.createdEmployee)}>
                            {(record.createdBy.firstName ? record.createdBy.firstName + ' ' : '') +
                                (record.createdBy.lastName ? record.createdBy.lastName : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookUserPersonWithAlias('createdBy.id'),
        },
        {
            title: geti18nText('schedule.suggestions.table.column.approved'),
            dataIndex: 'approved',
            width: '160px',
            sorter: (a: any, b: any) => {},
            render: (created: any, record: any) => {
                if (record.approved) {
                    return getDateFormat(record.approved, 'DD.MM.YYYY HH:mm:ss');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('schedule.suggestions.table.column.user.approved'),
            dataIndex: 'approvedBy',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.approvedByPerson) {
                    return (
                        <a
                            style={{ textDecoration: 'underline' }}
                            onClick={() => modalVisible(record.approvedEmployee)}
                        >
                            {(record.approvedByPerson.firstName ? record.approvedByPerson.firstName + ' ' : '') +
                                (record.approvedByPerson.lastName ? record.approvedByPerson.lastName : '')}
                        </a>
                    );
                }
            },
            ...getColumnSearchByCodebookUserPersonWithAlias('approvedBy.id'),
        },
        {
            title: geti18nText('menu.work.obligation.schedule.suggestion.approvals.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return (
                        <Tag style={{ marginLeft: '10px' }} color={formStatusTagColor(record.status)}>
                            {geti18nText(
                                'app.enum.MILITARY_WORK_APPROVAL_STATUS.' +
                                    (record.type === militaryWorkApprovalType.GOVERNOR &&
                                    record.status == militaryWorkApprovalStatus.SENT
                                        ? '3'
                                        : record.status)
                            )}
                        </Tag>
                    );
                }
            },
            ...getColumnSearchCheckbox(militaryApprovalStatusOptions),
        },
        {
            title: geti18nText('menu.work.obligation.schedule.suggestion.sent.details'),
            dataIndex: 'sentDetails',
            width: '110px',
            render: (created: any, record: any) => {
                if (record.status && record.status === militaryWorkApprovalStatus.SENT && content(record)) {
                    return (
                        <div>
                            <Popover content={content(record)} placement="top" style={{ width: '50%' }}>
                                <Button style={{ marginRight: '10px' }} icon={<InfoCircleOutlined />} />
                            </Popover>
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('menu.work.obligation.schedule.suggestion.approvals.details'),
            dataIndex: 'approvalsDetails',
            width: '120px',
            render: (created: any, record: any) => {
                if (
                    record.status &&
                    record.status === militaryWorkApprovalStatus.APPROVAL &&
                    displayAdditionalInfo(record)
                ) {
                    return (
                        <div>
                            <Popover content={displayAdditionalInfo(record)} placement="top" style={{ width: '50%' }}>
                                <Button style={{ marginRight: '10px' }} icon={<InfoCircleOutlined />} />
                            </Popover>
                        </div>
                    );
                }
            },
        },

        {
            title: geti18nText('schedule.suggestions.table.column.active'),
            dataIndex: 'active',
            width: '100px',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '80px',
            render: (text: any, record: any) => {
                if (record.id && record.status !== militaryWorkApprovalStatus.APPROVAL) {
                    return record.active === true ? (
                        <NyModalConfirm
                            title={geti18nText('schedule.suggestions.deactivate.plan')}
                            onConfirm={() => {
                                onAction(record);
                            }}
                        >
                            <Tooltip title={geti18nText('app.default.button.deactivate')}>
                                <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
                            </Tooltip>
                        </NyModalConfirm>
                    ) : (
                        <NyModalConfirm
                            title={geti18nText('schedule.suggestions.activate.plan')}
                            onConfirm={() => {
                                onAction(record);
                            }}
                        >
                            <Tooltip title={geti18nText('app.default.button.activate')}>
                                <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
                            </Tooltip>
                        </NyModalConfirm>
                    );
                }
            },
        },
    ];

    const modalVisible = (employee: any) => {
        setItem(employee.id);
        setVisible(true);
    };

    const addedButtons = () => {
        return <PlanActions setRefreshTable={setRefreshTable} canCreateActions={canCreateActions} />;
    };

    return (
        <>
            <NyDataTable
                nyId="human-schedule-suggestions-military-approval-table"
                url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.LIST}
                addedButtons={addedButtons}
                fetchWhenChange={refreshTable}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                modalComponent={ScheduleSuggestionsEdit}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={true}
                editProps={{ scroll: { y: 400, x: 3200 }, sortOrder: null }}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default ScheduleSuggestionsIndex;
