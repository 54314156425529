import { PrinterOutlined } from '@ant-design/icons';
import { geti18nText, NyModalConfirm, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Descriptions, Divider, Form, Modal, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import NyReportButton from '../../../../../components/report-button';
import TravelWarrantMap from '../../../../../components/travel-warrant-map/TravelWarrantMap';
import TravelWarrantSummaryIndex from '../../../../../components/travel-warrant-summary';
import TravelWarrantWageSummaryIndex from '../../../../../components/travel-warrant-wage-summary';
import useEnum from '../../../../../hooks/useEnum';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    okNotification,
    onKeyDownTextAreaCustom,
    warningNotification,
} from '../../../../../utils/Utils';

const { Text } = Typography;

const ReportApproveTravelWarrantModal = ({
    modalVisible,
    setModalVisible,
    travelWarrantId,
    setTravelWarrantId,
    refresh,
    setRefresh,
}: any) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState<any>(undefined);
    const [existingFiles, setExistingFiles] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);
    const { employee } = useSelector((state: RootState) => state.employee);
    const travelWarrantStatusEnum = useEnum('TRAVEL_WARRANT_STATUS');
    const [travelWarrantStatus, setTravelWarrantStatus] = useState<any>(travelWarrantStatusEnum.DRAFT);
    const [travelWarrant, setTravelWarrant] = useState<any>(null);
    const [employeeTravelWarrant, setEmployeeTravelWarrant] = useState<any>(null);
    const [isPlane, setIsPlane] = useState(false);
    const [currency, setCurrency] = useState<any>(undefined);
    const [ordinal, setOrdinal] = useState<any>('');
    const [warrantDate, setWarrantDate] = useState<any>('');
    const componentRefModal = useRef<any>();
    const [printPdf, setPrintPdf] = useState(false);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;

    const [waypoints, setWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [maxWaypointsKey, setMaxWaypointsKey] = useState(2);
    const [returnRoute, setReturnRoute] = useState<boolean>(false);
    const [returnWaypoints, setReturnWaypoints] = useState<any>([]);
    const [returnRouteWaypoints, setReturnRouteWaypoints] = useState<any>([]);
    const returnIntermediateWaypointsInit = useRef<any>(null);
    const [defaultWaypoints, setDefaultWaypoints] = useState<any>([
        { id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' },
        { id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' },
    ]);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [success, setSuccess] = useState<any>(true);

    const setDefaultFilterValueFiles = (travelWarrantId: any) => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travel_warrant_id', condition: 'equals', value: travelWarrantId },
        ];
    };

    useEffect(() => {
        if (travelWarrantId) {
            setTravelWarrantValues(travelWarrantId);
            setValuesFiles(travelWarrantId);
        }
    }, [travelWarrantId]);

    useEffect(() => {
        if (travelWarrantId) {
            setValuesFiles(travelWarrantId);
        }
    }, [refreshFiles]);

    function setDestinationValues(destinationJson: any) {
        let waypointsList: any = [];
        if (destinationJson != undefined) {
            destinationJson.forEach((element: any) => {
                if (element && element.relationJson) {
                    const waypoint = JSON.parse(element?.relationJson);
                    waypointsList = waypointsList.concat(waypoint);
                } else {
                    waypointsList = defaultWaypoints;
                }
            });
        }

        waypointsList = waypointsList.filter(
            (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.id === value.id)
        );

        const lastItem = waypointsList[waypointsList.length - 1];
        let initList = [];
        setReturnRoute(false);
        if (lastItem?.id == 9999) {
            const firstItem = waypointsList[0];
            setReturnRoute(true);
            const returnList = waypointsList.filter((x: any) => x.id >= 9000 && x.id != 9999);
            initList = JSON.parse(JSON.stringify(returnList));
            returnIntermediateWaypointsInit.current = initList;
            let list = [firstItem].concat(returnList).concat(lastItem);

            waypointsList = waypointsList.slice(0, waypointsList.length - 1);
            waypointsList = waypointsList.filter((x: any) => x.id < 9000);
            form.setFieldsValue({ returnRoute: true });

            setReturnWaypoints(JSON.parse(JSON.stringify(list)));
            setReturnRouteWaypoints(JSON.parse(JSON.stringify(list)));
        }
        const maxWaypointsKey = Math.max(...waypointsList.map((el: any) => el.id));
        setMaxWaypointsKey(maxWaypointsKey);
        setWaypoints(waypointsList);
    }

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false);
        setIsPlane(false);
        setFiles([]);
        setTravelWarrantStatus(travelWarrantStatusEnum.DRAFT);
        setTravelWarrant(null);
        setEmployeeTravelWarrant(null);
        setCurrency(null);
        setOrdinal('');
        setSuccess(true);
        if (setTravelWarrantId) {
            setTravelWarrantId(null);
        }
    };

    const setTravelWarrantValues = (travelWarrantId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.EDIT + '/' + travelWarrantId, undefined).then(
            (result: any) => {
                if (result && result.status === RESPONSE.OK && result.data) {
                    setValues(result.data);
                }
            }
        );
    };

    const setValues = (data: any) => {
        if (data.status > travelWarrantStatusEnum.DRAFT) {
            setOrdinal(
                data.ordinal && data.warrantDate ? data.ordinal + '/' + moment(data.warrantDate).format('YYYY') : null
            );
        }
        setOrdinal(data.ordinal ? data.ordinal : null);
        setWarrantDate(data.warrantDate ? moment(data.warrantDate).format('YYYY') : '');
        setNote(data.note && data.note.note ? data.note.note : undefined);
        setTravelWarrantStatus(data.status ? data.status : travelWarrantStatusEnum.DRAFT);

        const advancePaymentCurrency = data.advancePaymentCurrency;
        if (advancePaymentCurrency != undefined && advancePaymentCurrency.id && advancePaymentCurrency.isoCode) {
            setCurrency({ id: advancePaymentCurrency.id, text: advancePaymentCurrency.isoCode });
        }

        setIsPlane(data.travelVehicle && data.travelVehicle.vehicleType == 3 ? true : false);

        if (data.destinationJson) {
            setDestinationValues(JSON.parse(data.destinationJson));
        }

        setTravelWarrant(data);
        if (data.employee && data.employee.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + data.employee.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setEmployeeTravelWarrant(result.data);
                        }
                    }
                }
            );
        }
    };

    function setValuesFiles(travelWarrantId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT_FILE.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueFiles(travelWarrantId))),
            max: 1000,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    setFiles(result.data.content);
                    setExistingFiles(result.data.content);
                } else {
                    setFiles([]);
                    setExistingFiles([]);
                }
            }
        });
    }

    const save = (status: any) => {
        setLoading(true);
        let message = form.getFieldValue(['message']) ? form.getFieldValue(['message']) : null;
        if (!message && status == travelWarrantStatusEnum.REPORT_FIX_NEEDED) {
            warningNotification(geti18nText('travelWarrantOrganization.note.info'));
        } else {
            changeStatus(travelWarrantId, status, message);
        }
    };

    const changeStatus = (recordId: any, status: any, message: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.TRAVEL_WARRANT.CHANGE_STATUS + '/' + recordId, undefined, {
            id: recordId,
            status: status,
            mail: false,
            info: message,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (status == travelWarrantStatusEnum.REPORT_APPROVED) {
                    okNotification(geti18nText('travelWarrantReportApprove.confirm.ok'));
                }
                if (status == travelWarrantStatusEnum.REPORT_FIX_NEEDED) {
                    okNotification(geti18nText('travelWarrantReportApprove.reject.ok'));
                }
                if (setRefresh) setRefresh(() => refresh + 1);
                if (setLoading) setLoading(false);
                closeModal();
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
            }
        });
    };

    const openDetails = () => {
        history.push('/travel-warrant/travelWarrant/' + travelWarrantId);
    };

    const getCustomFooterContent = (
        <>
            <div style={{ marginLeft: '5px', float: 'left' }}>
                <NyReportButton
                    url={CONSTANTS_REQ.REPORT.TRAVEL_WARRANT_CALCULATION}
                    customParms={{ id: Number(travelWarrantId) }}
                    buttoni18nText={'travelWarrant.action.pdf.calculation'}
                    fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.TRAVEL_WARRANT_CALCULATION)}
                    subreportType={'TRAVEL_WARRANT_CALCULATION'}
                    fileNameAddition={ordinal}
                    type={'button'}
                />
            </div>

            <div>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Button key={'print'} icon={<PrinterOutlined />}>
                                {geti18nText('travelWarrant.action.pdf.short')}
                            </Button>
                        );
                    }}
                    onBeforeGetContent={() => {
                        setPrintPdf(true);
                        return Promise.resolve();
                    }}
                    onAfterPrint={() => {
                        setPrintPdf(false);
                    }}
                    content={() => componentRefModal.current}
                    pageStyle={`@page {
                    size: auto;
                    margin: 10mm;
                  }`}
                />
                <Button type="primary" onClick={openDetails}>
                    {geti18nText('travelWarrant.overview.details')}
                </Button>
                <Button key="cancel" onClick={closeModal}>
                    {geti18nText('app.default.button.cancel')}
                </Button>
                {success && (
                    <>
                        <NyModalConfirm
                            title={geti18nText('travelWarrantReportApprove.reject.confirm')}
                            onConfirm={() => {
                                save(travelWarrantStatusEnum.REPORT_FIX_NEEDED);
                            }}
                        >
                            <Button key="reject" style={{ color: 'white', backgroundColor: 'red' }}>
                                {geti18nText('travelWarrantReportApprove.reject')}
                            </Button>
                        </NyModalConfirm>
                        <NyModalConfirm
                            title={geti18nText('travelWarrantReportApprove.save.confirm')}
                            onConfirm={() => {
                                save(travelWarrantStatusEnum.REPORT_APPROVED);
                            }}
                        >
                            <Button key="submit" style={{ color: 'white', backgroundColor: 'green' }}>
                                {geti18nText('travelWarrantReportApprove.save')}
                            </Button>
                        </NyModalConfirm>
                    </>
                )}
            </div>
        </>
    );

    return (
        <>
            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={
                        ordinal
                            ? geti18nText('travelWarrantReportApprove.header') + ' - ' + ordinal + '/' + warrantDate
                            : geti18nText('travelWarrantReportApprove.header')
                    }
                    onCancel={closeModal}
                    confirmLoading={loading}
                    width={1200}
                    maskClosable={false}
                    footer={getCustomFooterContent}
                >
                    {' '}
                    <Row
                        gutter={24}
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '70vh',
                        }}
                    >
                        <Col span={24}>
                            <Form
                                layout="vertical"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                form={form}
                                onFinishFailed={({ errorFields }) => {
                                    form.scrollToField(errorFields[0].name);
                                }}
                            >
                                <div ref={componentRefModal}>
                                    {printPdf && (
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Divider orientation="left">
                                                    <b>
                                                        {geti18nText('travelWarrantReportApprove.header')}
                                                        {' - '}
                                                        {ordinal}/{warrantDate}
                                                    </b>
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <TravelWarrantSummaryIndex
                                                printPdfProp={printPdf}
                                                setPrintPdfProp={setPrintPdf}
                                                employeeTravelWarrant={employeeTravelWarrant}
                                                globalForm={travelWarrant}
                                                formSummary={form}
                                                isPlane={isPlane}
                                                currency={currency}
                                                files={files}
                                                setFiles={setFiles}
                                                showPrintButton={false}
                                                id={travelWarrantId}
                                                travelWarrantStatus={travelWarrantStatus}
                                                refId={travelWarrantId}
                                                setRefreshFiles={setRefreshFiles}
                                                datePadding={true}
                                                showCo2={true}
                                                message={form.getFieldValue(['message'])}
                                            />
                                        </Col>
                                    </Row>
                                    {!printPdf && (
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Descriptions
                                                    column={1}
                                                    title={geti18nText('travelWarrantRelation.destination')}
                                                    size="small"
                                                />
                                                <Form.Item>
                                                    <TravelWarrantMap
                                                        hidePrint={true}
                                                        showMap={true}
                                                        form={form}
                                                        waypoints={waypoints}
                                                        setWaypoints={setWaypoints}
                                                        maxWaypointsKey={maxWaypointsKey}
                                                        setMaxWaypointsKey={setMaxWaypointsKey}
                                                        nyTestId="map"
                                                        isPlane={isPlane}
                                                        displayOnly={true}
                                                        returnRoute={returnRoute}
                                                        returnWaypoints={returnWaypoints}
                                                        setReturnWaypoints={setReturnWaypoints}
                                                        returnIntermediateWaypointsInit={
                                                            returnIntermediateWaypointsInit.current
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <TravelWarrantWageSummaryIndex
                                                travelWarrantId={travelWarrantId}
                                                printPdf={printPdf}
                                                showRecapitulationTable={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Descriptions
                                                column={1}
                                                bordered
                                                title={geti18nText('travelWarrantPersonal.edit.report')}
                                                size="small"
                                                style={{ color: isDarkTheme ? 'white' : 'black' }}
                                                className={printPdf ? 'ant-typography-print-title' : ''}
                                                labelStyle={{ fontWeight: '500' }}
                                            >
                                                <Descriptions.Item
                                                    className={printPdf ? 'ant-typography-print' : ''}
                                                    label={geti18nText('travelWarrantPersonal.edit.report')}
                                                >
                                                    <Text>
                                                        {travelWarrant && travelWarrant.report
                                                            ? travelWarrant.report
                                                            : '-'}
                                                    </Text>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                    {!printPdf && (
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Divider orientation="left">
                                                    {geti18nText('travelWarrantOrganization.table.column.note')}
                                                </Divider>
                                                <Form.Item name="message">
                                                    <TextArea
                                                        rows={4}
                                                        onKeyDown={onKeyDownTextAreaCustom}
                                                        autoSize={{ minRows: 4, maxRows: 5 }}
                                                    ></TextArea>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default ReportApproveTravelWarrantModal;
