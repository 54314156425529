import { InfoCircleOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Card, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification } from '../../../../../utils/Utils';

const MenuItems = ({ menus, setMenu, setMenuModal }: any) => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        if (menus && menus.length > 0) {
            setData(menus);
        }
    }, [menus]);

    const openModal = (menuId: any) => {
        setMenu(undefined);
        if (menuId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_MENU.EDIT + '/' + menuId, undefined).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    setMenu(result.data);
                    setMenuModal(true);
                } else if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            });
        }
    };

    //// boje po restoranu ////

    // const restaurantColorMap = new Map();
    // const menuColorMap = new Map();

    // const getRandomColor = () => {
    //     return (
    //         '#' +
    //         Math.floor(Math.random() * 0xffffff)
    //             .toString(16)
    //             .padEnd(6, '0')
    //     );
    // };

    // const getRestaurantColor = (restaurantId: any) => {
    //     if (!restaurantColorMap.has(restaurantId)) {
    //         restaurantColorMap.set(restaurantId, getRandomColor());
    //     }
    //     return restaurantColorMap.get(restaurantId);
    // };

    // const getMenuColor = (menuId: any) => {
    //     if (!menuColorMap.has(menuId)) {
    //         menuColorMap.set(menuId, getRandomColor());
    //     }
    //     return menuColorMap.get(menuId);
    // };

    return (
        <>
            {data && data.length > 0 && (
                <>
                    {data.map((menu: any) => {
                        return (
                            <Card /*style={{ backgroundColor: getRestaurantColor(menu.restaurant.id) }}*/>
                                <Meta
                                    title={
                                        <div
                                            onClick={() => {
                                                openModal(menu.id);
                                            }}
                                        >
                                            <Tooltip
                                                placement="topLeft"
                                                overlayStyle={{ maxWidth: '300px' }}
                                                title={
                                                    <div>
                                                        {geti18nText('meal.menu.tab.header') + ': ' + menu.name}
                                                        <br />
                                                        {geti18nText('restaurant.table.header') +
                                                            ': ' +
                                                            menu.restaurant.name}
                                                    </div>
                                                }
                                            >
                                                <div style={{ maxWidth: '155px' }}>
                                                    <p
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            marginTop: '-0.4rem',
                                                            marginBottom: '-1rem',
                                                        }}
                                                    >
                                                        <div style={{ fontWeight: 'normal' }}>
                                                            {geti18nText('meal.menu.tab.header') + ': '}
                                                        </div>
                                                        {menu.name}
                                                        <div style={{ fontWeight: 'normal' }}>
                                                            {geti18nText('restaurant.table.header') + ': '}
                                                        </div>
                                                        {menu.restaurant.name}
                                                    </p>
                                                </div>
                                            </Tooltip>
                                            <Tooltip placement="topLeft" title={geti18nText('history.details')}>
                                                <InfoCircleOutlined
                                                    style={{
                                                        float: 'right',
                                                        fontSize: '14px',
                                                        marginRight: '5px',
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                />
                            </Card>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default MenuItems;
