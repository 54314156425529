import {
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleFilled,
    FormOutlined,
    PlusCircleOutlined,
    RetweetOutlined,
    SaveTwoTone,
    SwapOutlined,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import {
    Alert,
    Badge,
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import { InventoryRights } from '../../../../../rights/inventoryRights';
import { RootState } from '../../../../../rootReducer';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { refreshItemList } from '../../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumArrayStatus,
    getEnumFormat,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    userIsNotificationRecipient,
} from '../../../../../utils/Utils';
import ItemEdit from '../../../../administration/views/item/edit';
import EmployeeIndex from '../../../../human/views/employee';
import RoomSearch from '../../../../human/views/room/search';
import WarehouseIndex from '../../../../warehouse/views/warehouse';
import ItemReplaceModal from '../../webshop-asset/asset-order/ItemReplaceModal';
import EditableCell from './EditableCell';

const InventoryOrderEdit = (props: any) => {
    const dispatch = useDispatch();
    const [editHeader, setEditHeader] = useState(geti18nText('inventoryOrder.edit.new'));
    const [cancelItemsHeader, setCancelItemsHeader] = useState(geti18nText('report.inventoryOrder.remove.title'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [editingKey, setEditingKey] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [status, setStatus] = useState<any>(undefined);
    const [showDecision, setShowDecision] = useState(true);
    const [itemEditVisible, setItemEditVisible] = useState(false);
    const [itemNoteEditVisible, setItemNoteEditVisible] = useState(false);
    const [noteGeneralId, setNoteGeneralId] = useState<any>(false);
    const [noteId, setNoteId] = useState<any>(false);
    const [itemNoteId, setItemNoteId] = useState<any>(undefined);
    const [itemStatus, setItemStatus] = useState<any>(false);
    const [itemEditData, setItemEditData] = useState<any>(undefined);
    const [newItemModalVisible, setNewItemVisible] = useState(false);
    const [orderViewId, setOrderViewId] = useState<any>(
        props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null
    );
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [cancelInventoryVisible, setCancelInventoryVisible] = useState<any>(false);
    const [cancelAllInventoryVisible, setCancelAllInventoryVisible] = useState<any>(false);
    const [rejectInventoryVisible, setRejectInventoryVisible] = useState<any>(false);
    const [hasCanceledItem, setHasCanceledItem] = useState(false);
    const [hasPartiallyReleasedItem, setHasPartiallyReleasedItem] = useState(false);
    const themeStorage = JSON.parse(localStorage['persist:root']);
    const [priority, setPriority] = useState<boolean>(true);
    const [editable, setEditable] = useState<boolean>(true);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const isEditing = (record: any) => record.id === editingKey;
    const { employee } = useSelector((state: RootState) => state.employee);
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const [noteForm] = Form.useForm();
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [itemsCanceledRefresh, setItemsCanceledRefresh] = useState<any>(true);
    const isDarkTheme = themeStorage != undefined && JSON.parse(themeStorage.generalSettings).theme === 'dark';
    const [warehouse, setWarehouse] = useState<any>(null);

    useEffect(() => {
        if (props.value == undefined) {
            if (employee && employee.id !== undefined && dataForm == null) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + employee.id, undefined).then(
                    (result: any) => {
                        if (result.status === RESPONSE.OK) {
                            if (result.data && result.data) {
                                form.setFieldsValue({
                                    employee: getEmployeeSelectOption(result.data),
                                    room: result.data.room,
                                });
                            }
                        }
                    }
                );
            }
        }
    }, [props.value]);

    const canCreateRelease = () => {
        return InventoryRights.canCreateRelease();
    };

    const canCreate = () => {
        return InventoryRights.canCreateOrder() || userIsNotificationRecipient(employee, 7);
    };

    useEffect(() => {
        getWarehouse(orderViewId);
    }, [orderViewId]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getWarehouseSearchUrl = () => {
        return orderViewId
            ? CONSTANTS_REQ.WAREHOUSE.SEARCH_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString())
            : CONSTANTS_REQ.WAREHOUSE.SEARCH;
    };

    const getWarehouse = (orderViewId: any) => {
        if (orderViewId > 0) {
            setWarehouse(null);
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.WAREHOUSE.LIST_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString()),
                {
                    search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                }
            ).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.content?.length > 0 && result?.data.content[0]) {
                    const warehouseValue: any = setSearchFormat(result.data.content[0], 'name', 'name', 'code');
                    setWarehouse(warehouseValue);
                    form.setFieldsValue({ warehouse: warehouseValue });
                }
            });
        }
    };

    async function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setEditHeader(
                geti18nText('menu.inventory.order.single') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
            setCancelItemsHeader(
                geti18nText('report.inventoryOrder.remove.title') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
        } else {
            setEditHeader(geti18nText('menu.inventory.order.single'));
        }

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.inventoryOrderItem) {
            setItems(
                dataForm.inventoryOrderItem.map((item: any) => {
                    if (item.status != undefined && item.status === 8) {
                        setHasCanceledItem(true);
                    }
                    let qty = item.quantity ? item.quantity : 0;
                    let releasedQty = item.releasedQuantity ? item.releasedQuantity : 0;
                    if (qty > 0 && releasedQty > 0 && qty - releasedQty > 0) {
                        setHasPartiallyReleasedItem(true);
                    }
                    return { ...item, releaseQuantity: qty - releasedQty };
                })
            );
        }

        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }

        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }

        if (dataForm.orderView && dataForm.orderView.id) {
            setOrderViewId(dataForm.orderView.id);
        }
        if (dataForm.warehouse) {
            setWarehouse(setSearchFormat(dataForm.warehouse, 'name', 'name', 'code'));
        }

        setShowDecision(false);

        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('INVENTORY_ORDER_STATUS_TYPE', dataForm.status);
            if (dataForm.status.id == 1 && canCreate()) {
                setShowDecision(true);
            }
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setSelectedItems([]);
        assetForm.resetFields();
        noteForm.resetFields();
        setItems([]);
        setEditingKey('');
        setDataForm(null);
        setNote(undefined);
        setEditHeader(geti18nText('inventoryOrder.edit.new'));
        setCancelItemsHeader(geti18nText('report.inventoryOrder.remove.title'));
        setStatus(undefined);
        setShowDecision(false);
        setOrderViewId(null);
        setWarehouse(null);
    };

    const onModalOpen = () => {
        setShowDecision(false);
        setOrderViewId(props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (record.item == undefined) {
            deleteRow(record.id);
        }
        setAddItemInProcess(false);
    };

    async function getItemData(id: any) {
        const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + id, undefined);
        if (result.status === RESPONSE.OK) {
            if (result.data) {
                return result.data;
            }
        }
        return undefined;
    }

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await assetForm.validateFields();
            const newData = [...items];
            const index = newData.findIndex((item) => id === item.id);

            if (row && row.item) {
                let itemId = row.item.id;
                let itemData: any = await getItemData(itemId);
                if (itemData) {
                    row.item = itemData;
                }
            }

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }
            setAddItemInProcess(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const modalComponentOnSaveAndGetData = (data: any) => {
        if (data) {
            const newData = [...items];
            const index = newData.findIndex((item) => data.id === item.item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...{ item: data } });
                setItems(newData);
                setItemEditData(undefined);
                setEditingKey('');
            }
        }
    };

    const saveItemNote = () => {
        setItemNoteEditVisible(!itemNoteEditVisible);
        const arr = [...items];
        const index = arr.findIndex((item: any) => item.id == noteId);

        if (noteForm.getFieldValue('itemNote') && noteForm.getFieldValue('itemNote').length > 0) {
            arr[index].note = { id: itemNoteId, note: noteForm.getFieldValue('itemNote'), priority: priority };
        }
        setPriority(true);
        setItems(arr);
        noteForm.resetFields();
    };

    const normalizeItems = (selectedItems: any) => {
        let values: any = {};
        if (dataForm != undefined) {
            values = { id: dataForm };
        }
        let inventoryOrderItem: any = [];
        if (selectedItems != undefined && selectedItems.length > 0) {
            selectedItems.map((item: any) => {
                if (item.note && item.note.note) {
                    inventoryOrderItem.push({
                        id: item.id,
                        note: { id: item.note.id, note: item.note.note, priority: item.note.priority },
                    });
                } else {
                    inventoryOrderItem.push({
                        id: item.id,
                    });
                }
            });
        }
        if (inventoryOrderItem != undefined && inventoryOrderItem.length > 0) {
            values.inventoryOrderItem = inventoryOrderItem;
        }

        if (note != undefined) {
            values.note = {
                id: form.getFieldValue('note').id,
                note: form.getFieldValue('note').note,
                priority: form.getFieldValue('note').priority,
            };
        } else {
            values.note = { note: form.getFieldValue('note').note, priority: form.getFieldValue('note').priority };
        }

        return values;
    };

    const sendCanceledItems = () => {
        const normalized = normalizeItems(selectedItems);

        NyRequestResolver.requestPut(
            CONSTANTS_REQ.INVENTORY_ORDER.CANCEL_ITEMS + '/' + dataForm,
            undefined,
            normalized
        ).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.status) {
                    if (status === undefined || status != result.data.status) {
                        okNotification(
                            geti18nText('app.default.save.ok.desc'),
                            geti18nText('app.default.save.ok.desc') +
                                ' ' +
                                geti18nText('inventoryOrder.edit.status') +
                                ': ' +
                                geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + result.data.status)
                        );
                    }
                } else {
                    okNotification();
                }
                setSelectedItems([]);
                setCancelInventoryVisible(false);
                setLoading(false);
                setItemsCanceledRefresh(!itemsCanceledRefresh);
                if (result.data.status !== status) {
                    props.setVisible(false);
                    setCancelInventoryVisible(false);
                    dispatch(refreshItemList());
                }
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                    setLoading(false);
                    //setSelectedItems([]);
                } else {
                    errorNotification();
                    setLoading(false);
                    //setSelectedItems([]);
                }
            }
        });
    };

    const cancelAllUndelivered = () => {
        let values: any = {};
        if (dataForm != undefined) {
            values = { id: dataForm };
        }
        if (note != undefined) {
            values.note = {
                id: note.id,
                note: form.getFieldValue('note').note,
                priority: form.getFieldValue('note').priority,
            };
        } else {
            values.note = { note: form.getFieldValue('note').note, priority: form.getFieldValue('note').priority };
        }

        NyRequestResolver.requestPut(CONSTANTS_REQ.INVENTORY_ORDER.CANCEL + '/' + dataForm, undefined, values).then(
            (result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    props.setVisible(false);
                    setCancelInventoryVisible(false);
                    setLoading(false);
                    dispatch(refreshItemList());
                } else {
                    if (result.data && result.data.error) {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification();
                    }
                }
            }
        );
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <NyModalConfirm
                        title={geti18nText('app.default.cancel.confirm')}
                        onConfirm={() => {
                            cancel(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                            <Button type="link">
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </Tooltip>
                    </NyModalConfirm>
                </Space>
            </React.Fragment>
        );
    };

    const EmptyDiv = () => {
        return (
            <React.Fragment>
                <Space>
                    <div
                        style={{
                            height: '20px',
                        }}
                    />
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {(isCreate || !status || status === 2 || status === 3 || status === 7) && (
                    <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                        {record &&
                        record.item &&
                        record.item.availabilityStatus &&
                        (record.item.availabilityStatus === 5 || record.item.replaceble) ? (
                            <>
                                <Tooltip placement="top" title={geti18nText('assetOrder.itemReplace.button.fillData')}>
                                    <Button
                                        type="link"
                                        disabled={editingKey !== ''}
                                        onClick={() => {
                                            setItemEditData(record.item);
                                            setItemEditVisible(true);
                                        }}
                                    >
                                        <FormOutlined
                                            style={{
                                                fontSize: '20px',
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title={geti18nText('assetOrder.itemReplace.button.replace')}>
                                    <Button
                                        type="link"
                                        disabled={editingKey !== ''}
                                        onClick={() => {
                                            setItemEditData(record.item);
                                            setNewItemVisible(true);
                                        }}
                                    >
                                        <RetweetOutlined
                                            style={{
                                                fontSize: '20px',
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                                    <EditTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        )}
                        <NyModalConfirm
                            title={
                                dataForm
                                    ? geti18nText('inventory.order.delete.confirm')
                                    : geti18nText('inventory.order.delete.new.confirm')
                            }
                            onConfirm={() => {
                                deleteRow(record.id);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={editingKey !== ''}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const columns: any = [
        ...[
            {
                title: geti18nText('inventoryOrder.edit.code'),
                dataIndex: ['item', 'code'],
                editable: false,
                width: '250px',
            },
            {
                title: geti18nText('inventoryOrder.edit.status'),
                dataIndex: ['status'],
                editable: false,
                width: 'auto',
                render: (text: any, record: any) => {
                    if (record != undefined && record.status != undefined && record.status === 8) {
                        return <Tag color={'red'}>{geti18nText('inventoryOrder.edit.status.canceled')}</Tag>;
                    }
                },
            },
            {
                title: geti18nText('inventoryOrder.edit.name'),
                dataIndex: 'item',
                editable: !status,
                inputType: 'item',
                render: (text: any, record: any) => {
                    if (record.item != undefined && record.item.name != undefined) {
                        return record.item.name;
                    }
                },
            },
            {
                title: geti18nText('inventoryOrder.edit.measureUnit'),
                dataIndex: ['item', 'measureUnit', 'abbreviation'],
                editable: false,
                width: '250px',
            },
        ],
        ...(status === 3 || status === 7
            ? [
                  {
                      title: geti18nText('inventoryOrder.edit.orderQuantity'),
                      dataIndex: 'quantity',
                      render: (text: any, record: any) => {
                          return record.quantity ? record.quantity : 0;
                      },
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releasedQuantity'),
                      dataIndex: 'releasedQuantity',
                      render: (text: any, record: any) => {
                          return record.releasedQuantity ? record.releasedQuantity : 0;
                      },
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releaseQuantity'),
                      dataIndex: 'releaseQuantity',
                      editable: true,
                      inputType: 'releaseQuantity',
                      render: (text: any, record: any) => {
                          if (record != undefined && record.status != undefined && record.status === 8) {
                              return 0;
                          } else {
                              return record.releaseQuantity ? record.releaseQuantity : 0;
                          }
                      },
                  },
              ]
            : status === 4 && hasCanceledItem && hasPartiallyReleasedItem
            ? [
                  {
                      title: geti18nText('inventoryOrder.edit.quantity'),
                      dataIndex: 'quantity',
                      width: '250px',
                      editable: !status,
                      inputType: 'quantity',
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releasedQuantity'),
                      dataIndex: 'releasedQuantity',
                      render: (text: any, record: any) => {
                          return record.releasedQuantity ? record.releasedQuantity : 0;
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('inventoryOrder.edit.quantity'),
                      dataIndex: 'quantity',
                      width: '250px',
                      editable: !status,
                      inputType: 'quantity',
                  },
              ]),
        ...(!status || status === 2 || status === 3 || status === 7
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (
                              items.length >= 1 &&
                              record.status != 8 &&
                              (record.quantity != record.releasedQuantity || record.quantity == 0)
                          ) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          } else {
                              return <EmptyDiv />;
                          }
                      },
                  },
              ]
            : []),
        ...(status !== undefined && status !== 1
            ? [
                  {
                      title: geti18nText('inventoryOrder.table.column.itemNote'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return (
                                  <Tooltip title={geti18nText('app.default.note')}>
                                      <Badge
                                          count={
                                              record.note && record.note.priority && record.note.priority == true ? (
                                                  <ExclamationCircleFilled
                                                      className="ny-blink"
                                                      style={{ color: '#f5222d' }}
                                                  />
                                              ) : (
                                                  0
                                              )
                                          }
                                      >
                                          <FormOutlined
                                              className="item-note"
                                              onClick={() => {
                                                  editNote(record);
                                                  if (record.note) {
                                                      noteForm.setFieldsValue({ itemNote: record.note.note });
                                                  }

                                                  setNoteId(record.id);
                                                  if (record.note && record.note.id && record.note.id != undefined) {
                                                      setItemNoteId(record.note.id);
                                                  } else {
                                                      setItemNoteId(undefined);
                                                  }

                                                  if (record.releasedQuantity == record.quantity) {
                                                      setEditable(false);
                                                  } else {
                                                      setEditable(true);
                                                  }

                                                  setItemStatus(record.status);
                                                  setItemNoteEditVisible(true);
                                              }}
                                              style={
                                                  isDarkTheme
                                                      ? {
                                                            color: record.note
                                                                ? 'rgb(64, 169, 255)'
                                                                : 'rgba(255, 255, 255, 0.85)',
                                                        }
                                                      : { color: record.note ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)' }
                                              }
                                          />
                                      </Badge>
                                  </Tooltip>
                              );
                          } else {
                              return <EmptyDiv />;
                          }
                      },
                  },
              ]
            : []),
    ];

    const removedItemColumns: any = [
        ...[
            {
                title: geti18nText('inventoryOrder.edit.code'),
                dataIndex: ['item', 'code'],
                editable: false,
                width: '250px',
            },
            {
                title: geti18nText('inventoryOrder.edit.name'),
                dataIndex: 'item',
                editable: !status,
                inputType: 'item',
                render: (text: any, record: any) => {
                    if (record.item != undefined && record.item.name != undefined) {
                        return record.item.name;
                    }
                },
            },
            {
                title: geti18nText('inventoryOrder.edit.measureUnit'),
                dataIndex: ['item', 'measureUnit', 'abbreviation'],
                editable: false,
                width: '250px',
            },
        ],
        ...(status === 3 || status === 7
            ? [
                  {
                      title: geti18nText('inventoryOrder.edit.orderQuantity'),
                      dataIndex: 'quantity',
                      render: (text: any, record: any) => {
                          if (record != undefined && record.status != undefined && record.status === 8) {
                              return 0;
                          } else {
                              return record.quantity ? record.quantity : 0;
                          }
                      },
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releasedQuantity'),
                      dataIndex: 'releasedQuantity',
                      render: (text: any, record: any) => {
                          return record.releasedQuantity ? record.releasedQuantity : 0;
                      },
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releaseQuantity'),
                      dataIndex: 'releaseQuantity',
                      editable: true,
                      inputType: 'releaseQuantity',
                      render: (text: any, record: any) => {
                          return record.releaseQuantity ? record.releaseQuantity : 0;
                      },
                  },
                  {
                      title: geti18nText('inventoryOrder.table.column.itemNote'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1 && record.status != 8) {
                              const editable = isEditing(record);

                              return (
                                  <Tooltip title={geti18nText('app.default.note')}>
                                      <Badge
                                          count={
                                              record.note && record.note.priority && record.note.priority == true ? (
                                                  <ExclamationCircleFilled
                                                      className="ny-blink"
                                                      style={{ color: '#f5222d' }}
                                                  />
                                              ) : (
                                                  0
                                              )
                                          }
                                      >
                                          <FormOutlined
                                              className="item-note"
                                              onClick={() => {
                                                  editNote(record);
                                                  if (record.note) {
                                                      noteForm.setFieldsValue({ itemNote: record.note.note });
                                                  }
                                                  setNoteId(record.id);
                                                  if (record.note && record.note.id && record.note.id != undefined) {
                                                      setItemNoteId(record.note.id);
                                                  } else {
                                                      setItemNoteId(undefined);
                                                  }
                                                  if (record.releasedQuantity == record.quantity) {
                                                      setEditable(false);
                                                  } else {
                                                      setEditable(true);
                                                  }
                                                  setItemStatus(record.status);
                                                  setItemNoteEditVisible(true);
                                              }}
                                              style={
                                                  isDarkTheme
                                                      ? {
                                                            color: record.note
                                                                ? 'rgb(64, 169, 255)'
                                                                : 'rgba(255, 255, 255, 0.85)',
                                                        }
                                                      : { color: record.note ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)' }
                                              }
                                          />
                                      </Badge>
                                  </Tooltip>
                              );
                          } else {
                              return <EmptyDiv />;
                          }
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('inventoryOrder.edit.quantity'),
                      dataIndex: 'quantity',
                      width: '250px',
                      editable: !status,
                      inputType: 'quantity',
                  },
                  {
                      title: geti18nText('inventoryOrder.table.column.itemNote'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1 && record.status != 8) {
                              const editable = isEditing(record);

                              return (
                                  <Tooltip title={geti18nText('app.default.note')}>
                                      <Badge
                                          count={
                                              record.note && record.note.priority && record.note.priority == true ? (
                                                  <ExclamationCircleFilled
                                                      className="ny-blink"
                                                      style={{ color: '#f5222d' }}
                                                  />
                                              ) : (
                                                  0
                                              )
                                          }
                                      >
                                          <FormOutlined
                                              className="item-note"
                                              onClick={() => {
                                                  editNote(record);
                                                  if (record.note) {
                                                      noteForm.setFieldsValue({ itemNote: record.note.note });
                                                  }
                                                  setNoteId(record.id);
                                                  if (record.note && record.note.id && record.note.id != undefined) {
                                                      setItemNoteId(record.note.id);
                                                  } else {
                                                      setItemNoteId(undefined);
                                                  }
                                                  if (record.releasedQuantity == record.quantity) {
                                                      setEditable(false);
                                                  } else {
                                                      setEditable(true);
                                                  }
                                                  setItemStatus(record.status);
                                                  setItemNoteEditVisible(true);
                                              }}
                                              style={
                                                  isDarkTheme
                                                      ? {
                                                            color: record.note
                                                                ? 'rgb(64, 169, 255)'
                                                                : 'rgba(255, 255, 255, 0.85)',
                                                        }
                                                      : { color: record.note ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)' }
                                              }
                                          />
                                      </Badge>
                                  </Tooltip>
                              );
                          } else {
                              return <EmptyDiv />;
                          }
                      },
                  },
              ]),
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                let qty = record.quantity ? record.quantity : 0;
                let releasedQty = record.releasedQuantity ? record.releasedQuantity : 0;
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    maxQuantity: qty - releasedQty,
                    warehouse: warehouse,
                    form: assetForm,
                };
            },
        };
    });

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };
        assetForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const editNote = (record: any) => {
        let editForm = undefined;
        editForm = { ...record.item.note };
        noteForm.setFieldsValue(editForm);
    };

    const addNewRowAsset = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            item: undefined,
            quantity: 0,
            releasedQuantity: 0,
            releaseQuantity: 0,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const createInventoryRelease = () => {
        setLoading(true);
        form.validateFields()
            .then((values) => {
                let inventoryReleaseData: any = {};
                inventoryReleaseData['employee'] = values.employee;
                inventoryReleaseData['room'] = values.room;
                inventoryReleaseData['warehouse'] = warehouse;
                inventoryReleaseData['date'] = getDateFormat(moment());
                inventoryReleaseData['inventoryOrder'] = { id: values.id };

                if (props.addedData.orderViewId != null) {
                    inventoryReleaseData['orderView'] = { id: orderViewId };
                }

                const itemsBasket: any = [];
                if (items != undefined) {
                    items.map((value: any) => {
                        if (
                            value &&
                            value.item &&
                            value.item.availabilityStatus &&
                            value.item.availabilityStatus === 1 &&
                            value.status !== 8
                        ) {
                            let obj: any = {};
                            obj.inventoryOrderItem = { id: value.id };
                            obj.item = { id: value.item.id };
                            obj.quantity = value.releaseQuantity;
                            itemsBasket.push(obj);
                        }
                    });
                }
                inventoryReleaseData['inventoryReleaseItem'] = itemsBasket;

                if (itemsBasket.length > 0) {
                    NyRequestResolver.requestPost(
                        CONSTANTS_REQ.INVENTORY_RELEASE.EDIT,
                        undefined,
                        inventoryReleaseData
                    ).then((result: any) => {
                        setLoading(false);
                        if (result.status === RESPONSE.CREATED) {
                            okNotification(
                                geti18nText('app.default.order.save.ok'),
                                <>
                                    {geti18nText('assetRelease.edit.title')}:
                                    <Button type="link" onClick={() => openInventoryRelease(result.data.id)}>
                                        <span style={{ textDecoration: 'underline' }}>
                                            {result.data.ord}/{moment(result.data.date).format('YYYY')}
                                        </span>
                                    </Button>
                                </>,
                                15
                            );
                            props.setVisible(false);
                            dispatch(refreshItemList());
                        } else {
                            if (result.data && result.data.error) {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification();
                            }
                        }
                    });
                } else {
                    setLoading(false);
                    errorNotification(geti18nText('inventoryOrder.error.availabilityStatus'));
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    };

    const openInventoryRelease = (id: any) => {
        if (id) {
            history.push('/webshop/webshop-item/inventory-release');
            dispatch(
                setModalOpen({
                    modal: 'inventoryReleaseEdit',
                    visible: true,
                    item: id,
                })
            );
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px' }}>
                <NyNoteModal disabled={!(!status || status == 3 || status == 7)} note={note} />
            </div>

            {!isCreate && (
                <div style={{ float: 'left', marginLeft: '50px' }}>
                    <NyReportButton
                        url={CONSTANTS_REQ.REPORT.INVENTORY_ORDER}
                        customParms={{ inventoryOrderId: Number(dataForm) }}
                        subreportType={'INVENTORY_ORDER'}
                        buttoni18nText={'report.inventoryOrder.print'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.INVENTORY_ORDER)}
                        type={'button'}
                        allowExportTypeChange={true}
                    />
                </div>
            )}

            {!isCreate && (status === 3 || status === 7) && (
                <div style={{ float: 'left', marginLeft: '15px' }}>
                    <Button
                        disabled={selectedItems?.length < 1 || !canCreateRelease()}
                        onClick={() => {
                            setCancelInventoryVisible(!cancelInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                    >
                        {geti18nText('report.inventoryOrder.remove')}
                    </Button>
                </div>
            )}

            {!isCreate && status === 3 && (
                <div style={{ float: 'left', marginLeft: '15px' }}>
                    <Button
                        disabled={!canCreateRelease()}
                        onClick={() => {
                            setCancelAllInventoryVisible(!cancelAllInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                    >
                        {geti18nText('report.inventoryOrder.removeAll')}
                    </Button>
                </div>
            )}

            {!isCreate && (status === 3 || status === 7) && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('inventoryOrder.popconfirm.createInventoryRelease')}
                        onConfirm={() => {
                            createInventoryRelease();
                        }}
                    >
                        <Button
                            loading={loading}
                            icon={<SwapOutlined />}
                            type="primary"
                            disabled={!(items.length > 0) || editingKey !== '' || !canCreateRelease()}
                        >
                            {geti18nText('inventoryOrder.createInventoryRelease')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {showDecision == true && canCreate() && (
                <div style={{ float: 'right' }}>
                    <Button
                        key="submit"
                        style={{ color: 'white', backgroundColor: 'red' }}
                        onClick={() => {
                            setRejectInventoryVisible(!rejectInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                    >
                        {geti18nText('inventoryOrder.button.reject')}
                    </Button>
                    <NyModalConfirm
                        title={geti18nText('inventoryOrder.button.popconfirm.approve')}
                        onConfirm={() => {
                            saveDecision(3, null);
                        }}
                    >
                        <Button key="submit" style={{ color: 'white', backgroundColor: 'green' }}>
                            {geti18nText('inventoryOrder.button.approve')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const saveDecision = (selectedDecision: any, info: any) => {
        setLoading(true);
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.INVENTORY_ORDER.CHANGE_STATUS + '/' + form.getFieldValue('id'),
            undefined,
            {
                id: form.getFieldValue('id'),
                status: selectedDecision,
                info: info,
            }
        )
            .then((result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    okNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 15);
                    props.setVisible(false);
                    dispatch(refreshItemList());
                } else if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            if (result.data.room) {
                                form.setFieldsValue({ room: result.data.room });
                            } else {
                                form.setFieldsValue({ room: undefined });
                            }
                        }
                    }
                }
            );
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedItems(selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled:
                record.status === 8 ||
                (record.quantity ? record.quantity : 0) === (record.releasedQuantity ? record.releasedQuantity : 0),
        }),
    };

    return (
        <>
            <NyDataEdit
                fetchWhenChange={itemsCanceledRefresh}
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.INVENTORY_ORDER.EDIT}
                setValues={setValues}
                width={1400}
                hideActivationButtons={(!isCreate || !canCreate() || showDecision) && status != undefined}
                hideSubmitButton={
                    !canCreate() || status >= 3 || !(items.length > 0) || editingKey !== '' || showDecision
                }
                form={form}
                setIsCreate={setIsCreate}
                goBack={() => history.goBack()}
                paramsId={id}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={false}
                customFooterContent={canCreate() ? getCustomFooterContent : undefined}
                normalize={(values: any) => {
                    values.warehouse = warehouse ? getSearchFormat(warehouse) : null;
                    values.employee = getSearchFormat(values.employee);
                    values.date = getDateFormat(values.date);

                    if (orderViewId != null) values.orderView = { id: orderViewId };
                    if (values?.status?.id > 0) {
                        values.status = getEnumFormat(values.status);
                    } else {
                        values.status = null;
                    }

                    const itemsBasket: any = [];
                    if (items != undefined) {
                        items.map((value: any) => {
                            let obj: any = {};
                            obj.item = { id: value.item.id };
                            obj.quantity = value.quantity;

                            itemsBasket.push(obj);
                        });
                    }
                    values.inventoryOrderItem = itemsBasket;

                    return values;
                }}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="createdBy" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="ord" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="createdBy" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.date')}
                            name="date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                disabled={!isCreate}
                                ref={focusInput}
                                style={{ width: '100%' }}
                                format={'DD.MM.YYYY HH:mm'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('inventoryRelease.edit.warehouse')}
                            name="warehouse"
                            initialValue={warehouse}
                        >
                            <NySearchField
                                style={{ width: '100%' }}
                                url={getWarehouseSearchUrl()}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={true}
                                mustGetPopupContainer={false}
                                defaultValue={warehouse}
                                SearchPopupComponent={<WarehouseIndex orderViewId={orderViewId} />}
                                setDefaultFilterValue={setDefaultFilterValue}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.status')}
                            name="status"
                            rules={[
                                {
                                    required: status,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                options={getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE').map((s: any) => {
                                    if (!status) {
                                        return s.id > 1 ? { ...s, disabled: true } : s;
                                    } else if (status === 1) {
                                        return s.id !== 2 && s.id !== 3 ? { ...s, disabled: true } : s;
                                    } else if (status === 2) {
                                        return s.id !== 1 ? { ...s, disabled: true } : s;
                                    } else {
                                        return s;
                                    }
                                })}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                style={{ width: '100%' }}
                                className={''}
                                disabled={status >= 3 || showDecision}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={
                                    <EmployeeIndex disabled={true} parentKey="inventory-order-edit" />
                                }
                                disabled={status !== undefined}
                                onChange={onEmployeeChange}
                                order="person.last_name, person.first_name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <RoomSearch
                            disabled={status !== undefined}
                            label={geti18nText('inventoryOrder.edit.room')}
                            map={{ id: 'id', label: 'name' }}
                            name="room"
                            required={true}
                            searchBy="name"
                        />
                    </Col>
                </Row>
                <Divider>{geti18nText('inventoryOrder.divider.1')}</Divider>
                {(isCreate || !status) && (
                    <Row gutter={24} style={{ height: '36px' }}>
                        <Col span={24}>
                            <Button
                                style={{ float: 'right', marginRight: '12px' }}
                                icon={<PlusCircleOutlined />}
                                onClick={addNewRowAsset}
                                disabled={addItemInProcess}
                            >
                                {geti18nText('inventoryOrder.button.addItem')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row gutter={24}>
                    <Col span={24}>
                        <Form form={assetForm} component={false}>
                            <Table
                                rowKey={'id'}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                size={'small'}
                                dataSource={items}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                rowSelection={
                                    status === 3 || status === 7
                                        ? {
                                              type: 'checkbox',
                                              ...rowSelection,
                                          }
                                        : undefined
                                }
                            />
                        </Form>
                    </Col>
                </Row>
                {React.createElement(
                    ItemEdit,
                    {
                        isModal: true,
                        visible: itemEditVisible,
                        setVisible: setItemEditVisible,
                        value: itemEditData,
                        onSaveAndGetData: modalComponentOnSaveAndGetData,
                        addedData: { type: 8, itemGroupType: 2 },
                    },
                    null
                )}

                <ItemReplaceModal
                    orderId={dataForm}
                    itemEditData={itemEditData}
                    setItemEditData={setItemEditData}
                    newItemModalVisible={newItemModalVisible}
                    setNewItemVisible={setNewItemVisible}
                    setItems={setItems}
                    setEditingKey={setEditingKey}
                    type={8}
                />
                {itemNoteEditVisible && (
                    <Modal
                        visible={itemNoteEditVisible}
                        onCancel={() => {
                            setItemNoteEditVisible(!itemNoteEditVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                            noteForm.resetFields();
                        }}
                        zIndex={999999}
                        width={520}
                        title={geti18nText('inventoryOrder.table.column.itemNote')}
                        okText={geti18nText('app.default.button.ok')}
                        okButtonProps={{ hidden: !editable || itemStatus === 8 }}
                        onOk={() => {
                            if (!editable || !(itemStatus === undefined || itemStatus === 3 || itemStatus === 7)) {
                                setItemNoteEditVisible(!itemNoteEditVisible);

                                setNote({
                                    id: form.getFieldValue('note').id,
                                    note: form.getFieldValue('note').note,
                                    priority: form.getFieldValue('note').priority,
                                });
                                noteForm.resetFields();
                            } else {
                                saveItemNote();
                            }
                        }}
                    >
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item name={'priority'} initialValue={false} style={{ marginBottom: '0px' }}>
                                    <Checkbox
                                        defaultChecked={true}
                                        disabled={
                                            !editable ||
                                            !(itemStatus === undefined || itemStatus === 3 || itemStatus === 7)
                                        }
                                        onChange={(event: any) => {
                                            setPriority(event.target.checked);
                                        }}
                                        checked={priority}
                                    >
                                        {geti18nText('app.default.note.priority')}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <div style={{ marginBottom: '4px' }}>{geti18nText('app.default.note')}</div>
                                <Form form={noteForm} component={false}>
                                    <Form.Item name={'itemNote'}>
                                        <TextArea
                                            style={{
                                                resize: 'none',
                                            }}
                                            disabled={
                                                !editable ||
                                                !(itemStatus === undefined || itemStatus === 3 || itemStatus === 7)
                                            }
                                            id={noteId}
                                            rows={4}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Modal>
                )}
                {cancelInventoryVisible && (
                    <Modal
                        visible={cancelInventoryVisible}
                        onCancel={() => {
                            setCancelInventoryVisible(!cancelInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                        width={1200}
                        title={cancelItemsHeader}
                        okType="primary"
                        okButtonProps={{ danger: true }}
                        okText={geti18nText('report.inventoryOrder.remove.send')}
                        onOk={() => {
                            form.validateFields().then((values) => {
                                sendCanceledItems();

                                setNote({
                                    id: form.getFieldValue('note').id,
                                    note: form.getFieldValue('note').note,
                                    priority: form.getFieldValue('note').priority,
                                });
                            });
                        }}
                        // footer={getFooterContentModal}
                    >
                        <Alert
                            style={{ marginBottom: '8px' }}
                            message={<b>{geti18nText('report.inventoryOrder.remove.info')}</b>}
                            type="info"
                            showIcon
                        />

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form form={assetForm} component={false}>
                                    <Table
                                        rowKey={'id'}
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        size={'small'}
                                        dataSource={selectedItems}
                                        columns={removedItemColumns}
                                        rowClassName="editable-row"
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={['note', 'note']}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>
                )}
                {cancelAllInventoryVisible && (
                    <Modal
                        visible={cancelAllInventoryVisible}
                        onCancel={() => {
                            setCancelAllInventoryVisible(!cancelAllInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                        title={geti18nText('inventoryOrder.cancel.title')}
                        footer={[
                            <>
                                <Button
                                    key="back"
                                    onClick={() => setCancelAllInventoryVisible(!cancelAllInventoryVisible)}
                                >
                                    {geti18nText('app.default.button.cancel')}
                                </Button>
                                <NyModalConfirm
                                    title={geti18nText('report.inventoryOrder.removeAll.comfirm')}
                                    onConfirm={() => {
                                        form.validateFields()
                                            .then((values) => {
                                                cancelAllUndelivered();
                                                setNote({
                                                    id: form.getFieldValue('note').id,
                                                    note: form.getFieldValue('note').note,
                                                    priority: form.getFieldValue('note').priority,
                                                });
                                                setCancelAllInventoryVisible(!cancelAllInventoryVisible);
                                            })
                                            .catch(() => {
                                                errorNotification(geti18nText('report.inventoryOrder.note.required'));
                                            });
                                    }}
                                >
                                    <Button key="submit" type="primary" danger>
                                        {geti18nText('report.inventoryOrder.removeAll')}
                                    </Button>
                                </NyModalConfirm>
                            </>,
                        ]}
                    >
                        <Alert
                            style={{ marginBottom: '8px' }}
                            message={<b>{geti18nText('inventoryOrder.cancel.reject.order.info')}</b>}
                            type="info"
                            showIcon
                        />
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={['note', 'note']}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>
                )}
                {rejectInventoryVisible && (
                    <Modal
                        visible={rejectInventoryVisible}
                        onCancel={() => {
                            setRejectInventoryVisible(!rejectInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                        title={geti18nText('inventoryOrder.reject.title')}
                        footer={[
                            <>
                                <Button key="back" onClick={() => setRejectInventoryVisible(!rejectInventoryVisible)}>
                                    {geti18nText('app.default.button.cancel')}
                                </Button>
                                <NyModalConfirm
                                    title={geti18nText('inventoryOrder.button.popconfirm.reject')}
                                    onConfirm={() => {
                                        form.validateFields()
                                            .then((values) => {
                                                saveDecision(2, form.getFieldValue('note').note ?? '');
                                                setNote({
                                                    id: form.getFieldValue('note').id,
                                                    note: form.getFieldValue('note').note,
                                                    priority: form.getFieldValue('note').priority,
                                                });
                                                setRejectInventoryVisible(!rejectInventoryVisible);
                                            })
                                            .catch(() => {
                                                errorNotification(geti18nText('report.inventoryOrder.note.required'));
                                            });
                                    }}
                                >
                                    <Button key="submit" style={{ color: 'white', backgroundColor: 'red' }}>
                                        {geti18nText('inventoryOrder.button.reject')}
                                    </Button>
                                </NyModalConfirm>
                            </>,
                        ]}
                    >
                        <Alert
                            style={{ marginBottom: '8px' }}
                            message={<b>{geti18nText('inventoryOrder.reject.order.info')}</b>}
                            type="info"
                            showIcon
                        />
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={['note', 'note']}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>
                )}
            </NyDataEdit>
        </>
    );
};

export default InventoryOrderEdit;
