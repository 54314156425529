import { UserOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySession, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Avatar, Col, Descriptions, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnum } from '../../../../utils/Enums';
import { errorNotification, getDateFormat, setDateFormat, setEnumFormat } from '../../../../utils/Utils';

const EmployeeInfo = ({ employeeId }: any) => {
    const [resultData, setResultData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const enSexType: any = GetEnum({ enumName: 'SEX_TYPE' });
    const personSexType: any = setEnumFormat('SEX_TYPE', resultData?.person?.sex);
    const birthDate: any = setDateFormat(resultData?.person?.birthDate);
    const employeeStatusValidFrom: any = setDateFormat(resultData?.employeeStatusValidFrom);
    const employeeStatusValidTo: any = setDateFormat(resultData?.employeeStatusValidTo);
    const labelStyle: any = { width: '300px' };

    useEffect(() => {
        fetchEmployeeData();
    }, []);

    const fetchEmployeeData = () => {
        setResultData(null);
        setLoading(true);
        if (employeeId) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.MOBILIZATION_INFO + '/' + employeeId).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setResultData(result.data);
                        }
                    } else {
                        errorNotification();
                    }
                    setLoading(false);
                }
            );
        }
    };

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    <Row gutter={24}>
                        <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                {resultData?.person?.fileId ? (
                                    <Avatar
                                        size={150}
                                        src={
                                            CONSTANTS_REQ.FILES.DOWNLOAD +
                                            '/' +
                                            resultData.person.fileId +
                                            '?tenant=' +
                                            NySession.getUser().tenantId
                                        }
                                    />
                                ) : (
                                    <Avatar size={150} icon={<UserOutlined />} />
                                )}
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {geti18nText('employee.mobilization.edit.info2')}
                            </div>
                            <Descriptions
                                bordered
                                layout="horizontal"
                                column={1}
                                size="small"
                                labelStyle={labelStyle}
                                contentStyle={{ fontWeight: 'bold' }}
                                style={{ width: '100%' }}
                            >
                                <Descriptions.Item
                                    label={geti18nText('employee.mobilization.edit.lastParentFirstName')}
                                >
                                    {resultData?.person &&
                                        resultData?.person?.lastName +
                                            ', ' +
                                            (resultData?.person?.parentName
                                                ? resultData?.person?.parentName + ', '
                                                : '') +
                                            resultData?.person?.firstName}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.employmentRecordId')}>
                                    {resultData?.employmentRecordId}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.nationality')}>
                                    {resultData?.person?.citizenship?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.sex')}>
                                    {personSexType?.text}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.dob')}>
                                    {resultData?.person?.birthDate && getDateFormat(birthDate, 'DD.MM.yyyy.')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.oib')}>
                                    {resultData?.person?.oib}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={geti18nText('employee.mobilization.edit.professionalQualification')}
                                >
                                    {resultData?.formalEducation?.qualificationLevel?.qualification}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={
                                        resultData?.person?.sex == enSexType?.FEMALE
                                            ? geti18nText('employee.mobilization.edit.completionYear.female')
                                            : geti18nText('employee.mobilization.edit.completionYear.male')
                                    }
                                >
                                    {resultData?.person?.sex == enSexType?.FEMALE
                                        ? resultData?.person?.completion55Year
                                        : `${
                                              resultData?.person?.completion55Year
                                                  ? resultData?.person?.completion55Year + ','
                                                  : ''
                                          }
                                ${
                                    resultData?.person?.completion60Year
                                        ? resultData?.person?.completion60Year + ','
                                        : ''
                                }
                                    ${
                                        resultData?.person?.completion65Year ? resultData?.person?.completion65Year : ''
                                    }`}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {geti18nText('employee.mobilization.edit.info5')}
                            </div>
                            <Descriptions
                                bordered
                                layout="horizontal"
                                column={1}
                                size="small"
                                labelStyle={labelStyle}
                                contentStyle={{ fontWeight: 'bold' }}
                                style={{ width: '100%' }}
                            >
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.typeOfEmployment')}>
                                    {resultData?.type?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.employmentType')}>
                                    {resultData?.employmentType?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.employeeStatus')}>
                                    {resultData?.employeeStatus?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employmentContract.edit.validFrom')}>
                                    {resultData?.employeeStatusValidFrom &&
                                        getDateFormat(employeeStatusValidFrom, 'DD.MM.yyyy.')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employmentContract.edit.validTo')}>
                                    {resultData?.employeeStatusValidTo &&
                                        getDateFormat(employeeStatusValidTo, 'DD.MM.yyyy.')}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.statusDescription')}>
                                    {resultData?.employeeStatusDescription}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {geti18nText('employee.mobilization.edit.info1')}
                            </div>
                            <Descriptions
                                bordered
                                layout="horizontal"
                                column={1}
                                size="small"
                                labelStyle={labelStyle}
                                contentStyle={{ fontWeight: 'bold' }}
                                style={{ width: '100%', marginTop: '10px' }}
                            >
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.businessUnit')}>
                                    {resultData &&
                                        resultData?.businessUnits &&
                                        resultData?.businessUnits
                                            .map((businessUnit: any) => {
                                                return (
                                                    <p style={{ marginBottom: '0px' }}>
                                                        {(businessUnit.code ? `[${businessUnit.code}] ` : '') +
                                                            (businessUnit.name ? businessUnit.name : '')}
                                                    </p>
                                                );
                                            })
                                            .reverse()}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.profession')}>
                                    {resultData?.vocation?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={geti18nText('employee.mobilization.edit.profession.description')}
                                >
                                    {resultData?.vocationDescription?.name}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {geti18nText('employee.mobilization.edit.info3')}
                            </div>
                            <Descriptions
                                bordered
                                layout="horizontal"
                                column={1}
                                size="small"
                                labelStyle={labelStyle}
                                contentStyle={{ fontWeight: 'bold' }}
                                style={{ width: '100%' }}
                            >
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.county')}>
                                    {resultData?.address?.settlement?.county?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.zipCode')}>
                                    {resultData?.address?.settlement?.postOffice?.zipCode}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.township')}>
                                    {resultData?.address?.settlement?.township?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.settlement')}>
                                    {resultData?.address?.settlement?.name}
                                </Descriptions.Item>
                                {resultData?.address?.settlement?.township?.allowDistrict ? (
                                    <Descriptions.Item
                                        label={geti18nText('employee.mobilization.edit.townshipDistrict')}
                                    >
                                        {resultData?.address?.townshipDistrict?.name}
                                    </Descriptions.Item>
                                ) : null}
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.street')}>
                                    {resultData?.address?.street}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {geti18nText('employee.mobilization.edit.info4')}
                            </div>
                            <Descriptions
                                bordered
                                layout="horizontal"
                                column={1}
                                size="small"
                                labelStyle={labelStyle}
                                contentStyle={{ fontWeight: 'bold' }}
                                style={{ width: '100%' }}
                            >
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.county')}>
                                    {resultData?.employeeAddress?.address?.settlement?.county?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.zipCode')}>
                                    {resultData?.employeeAddress?.address?.settlement?.postOffice?.zipCode}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.township')}>
                                    {resultData?.employeeAddress?.address?.settlement?.township?.name}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.settlement')}>
                                    {resultData?.employeeAddress?.address?.settlement?.name}
                                </Descriptions.Item>
                                {resultData?.employeeAddress?.address?.settlement?.township?.allowDistrict ? (
                                    <Descriptions.Item
                                        label={geti18nText('employee.mobilization.edit.townshipDistrict')}
                                    >
                                        {resultData?.employeeAddress?.address?.townshipDistrict?.name}
                                    </Descriptions.Item>
                                ) : null}
                                <Descriptions.Item label={geti18nText('employee.mobilization.edit.street')}>
                                    {resultData?.employeeAddress?.address?.street}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default EmployeeInfo;
