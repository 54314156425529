import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import { ExclamationCircleFilled, FilterOutlined, FullscreenOutlined } from '@ant-design/icons';
import { geti18nText, NySearchField, NyUtils, UseRequestSwrPost } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Modal, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/hr';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useEnum from '../../hooks/useEnum';
import { RootState } from '../../rootReducer';
// import { setActiveTab } from '../../slices/tabsSlice';
import { setModalOpen } from '../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    customEmployeeRenderOption,
    getColorFromType,
    GetEmployeeLeaveCardColor,
    getEmployeeLeaveOverviewIconSimple,
    GetEmployeeLeaveStatusIcon,
    getEnumArrayStatus,
    getTravelWarrantIconSimple,
    getTravelWarrantOverviewCardColor,
    getTravelWarrantStatusIcon,
} from '../../utils/Utils';
import EmployeeIndex from '../../views/human/views/employee';

const { Link, Text } = Typography;

const NyFullCalendar = ({
    keys = ['holiday', 'travelWarrant', 'shifts', 'employeeLeave'],
    initialView = 'dayGridWeek',
    firstDay = 1,
    height = '200px',
    defaultAllDay = true,
    navLinks = true,
    stickyHeaderDates = true,
    showNonCurrentDates = true,
    weekends = true,
    editable = false,
    selectable = true,
    expandRows = true,
    isCard = false,
    isFullScreenCalendar = false,
    filterDialogVisible,
    setFilterDialogVisible,
    fullScreenDialogVisible,
    setFullScreenDialogVisible,
    initialSelectedOptions = [],
    isAdmin,
    isPersonal = false,
    search,
    isDashboard = false,
    activeList,
    employeeUrl = CONSTANTS_REQ.EMPLOYEE.SEARCH,
    refreshView,
    canCreate = false,
    canCreateAdmin = false,
}: any) => {
    const employeeLeaveType = useEnum('EMPLOYEE_LEAVE_TYPE');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const history = useHistory();
    const dispatch = useDispatch();
    const currentLang = NyUtils.getSelectedLanguage();
    const { settings } = useSelector((state: RootState) => state.notification);
    const [filterForm] = Form.useForm();
    const [refresh, setRefresh] = useState(0);
    const [keysCalendar, setKeysCalendar] = useState<any>(keys);
    //events
    const [eventsHolidays, setEventsHolidays] = useState<any>([]);
    const [eventsTravelWarrant, setEventsTravelWarrant] = useState<any>([]);
    const [eventsShifts, setEventsShifts] = useState<any>([]);
    const [eventsEmployeeLeave, setEventsEmployeeLeave] = useState<any>([]);
    const [eventsHolidaysInit, setEventsHolidaysInit] = useState<any>([]);
    const [eventsTravelWarrantInit, setEventsTravelWarrantInit] = useState<any>([]);
    const [eventsShiftsInit, setEventsShiftsInit] = useState<any>([]);
    const [eventsEmployeeLeaveInit, setEventsEmployeeLeaveInit] = useState<any>([]);
    //selected period
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const startDateInitValue: any =
        initialView == 'dayGridWeek'
            ? moment().startOf('week').format('yyyy-MM-DD HH:mm').toString()
            : moment().startOf('month').format('yyyy-MM-DD HH:mm').toString();
    const endDateInitValue: any =
        initialView == 'dayGridWeek'
            ? moment().endOf('week').format('yyyy-MM-DD HH:mm').toString()
            : moment().endOf('month').format('yyyy-MM-DD HH:mm').toString();

    const [startDate, setStartDate] = useState<any>(startDateInitValue);
    const [endDate, setEndDate] = useState<any>(endDateInitValue);
    const [startDateInit, setStartDateInit] = useState<any>(startDateInitValue);
    const [endDateInit, setEndDateInit] = useState<any>(endDateInitValue);
    //full screen
    const [filterDialog, setFilterDialog] = useState<boolean>(false);
    const [fullScreenDialog, setFullScreenDialog] = useState<boolean>(false);
    //filters
    const [employeeIds, setEmployeeIds] = useState<any>([]);
    const [employeeCondition, setEmployeeCondition] = useState<any>(null);
    const [employeeIdsInit, setEmployeeIdsInit] = useState<any>([]);
    const [employeeConditionInit, setEmployeeConditionInit] = useState<any>(null);
    const [statusTravelWarrant, setStatusTravelWarrant] = useState<any>([]);
    const [statusEmployeeLeave, setStatusEmployeeLeave] = useState<any>([]);
    //edit modals
    const [editEmployeeLeaveVisible, setEditEmployeeLeaveVisible] = useState<boolean>(false);
    const [employeeLeave, setEmployeeLeave] = useState<any>(undefined);
    const [editEmployeeSickLeaveVisible, setEditEmployeeSickLeaveVisible] = useState<boolean>(false);
    const [employeeSickLeave, setEmployeeSickLeave] = useState<any>(undefined);

    document.querySelectorAll('button.fc-today-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridWeek-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-dayGridMonth-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-listMonth-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-prev-button')?.forEach((element) => element.setAttribute('title', ''));
    document.querySelectorAll('button.fc-next-button')?.forEach((element) => element.setAttribute('title', ''));

    const hasKey = (key: any) => {
        return (
            keysCalendar &&
            keysCalendar.filter((k: any) => k.includes(key)) &&
            keysCalendar.filter((k: any) => k.includes(key)).length > 0
        );
    };

    const changeActiveKeys = (value: any, key: any) => {
        let newData: any = [...keysCalendar];
        const index = newData.findIndex((object: any) => object === key);
        if (value) {
            if (index == -1) {
                newData.push(key);
            }
        } else {
            newData.splice(index, 1);
        }
        setKeysCalendar(newData);
    };

    useEffect(() => {
        if (search) {
            const searchEmployee = search.filter((s: any) => s.field == 'employee_id');
            const employeeValue =
                searchEmployee && searchEmployee[0] && searchEmployee[0].value ? searchEmployee[0].value : null;
            const employeeCond =
                searchEmployee && searchEmployee[0] && searchEmployee[0].condition ? searchEmployee[0].condition : null;
            setEmployeeCondition(employeeCond);
            setEmployeeIds(employeeValue);
            setEmployeeConditionInit(employeeCond);
            setEmployeeIdsInit(employeeValue);
            filterForm.resetFields();
        }
        if (initialSelectedOptions) {
            const selectedValues = JSON.parse(initialSelectedOptions);
            if (selectedValues) {
                if (hasKey('employeeLeave')) {
                    const initDataLeave = resolveSelectedOptions(
                        getEnumArrayStatus('EMPLOYEE_LEAVE_STATUS'),
                        selectedValues,
                        'employeeLeave'
                    );
                    setStatusEmployeeLeave(initDataLeave);
                    filterForm.setFieldsValue({
                        employeeLeaveStatus: resolveSelectedValues(
                            getEnumArrayStatus('EMPLOYEE_LEAVE_STATUS'),
                            initDataLeave
                        ),
                    });
                }
                if (hasKey('travelWarrant')) {
                    const initDataTravel = resolveSelectedOptions(
                        getEnumArrayStatus('TRAVEL_WARRANT_STATUS'),
                        selectedValues,
                        'travelWarrant'
                    );
                    setStatusTravelWarrant(initDataTravel);
                    filterForm.setFieldsValue({
                        travelWarrantStatus: resolveSelectedValues(
                            getEnumArrayStatus('TRAVEL_WARRANT_STATUS'),
                            initDataTravel
                        ),
                    });
                }
            }
        }
    }, [initialSelectedOptions, activeList, refresh, isPersonal, refreshView]);

    const {
        data: statisticData,
        isError: error,
        isLoading: loading,
        mutate,
    } = UseRequestSwrPost(
        CONSTANTS_REQ.DASHBOARD.GET_CALENDAR,
        undefined,
        {
            keys: keysCalendar,
            dateFrom: startDate && moment(startDate) ? moment(startDate).format('yyyy-MM-DD HH:mm').toString() : null,
            dateTo: endDate && moment(endDate) ? moment(endDate).format('yyyy-MM-DD HH:mm').toString() : null,
            statusTravelWarrant: statusTravelWarrant,
            statusEmployeeLeave: statusEmployeeLeave,
            employeeIds: employeeIds && employeeIds.length > 0 ? employeeIds : employeeIdsInit,
            employeeCondition: employeeIds && employeeIds.length > 0 ? employeeCondition : employeeConditionInit,
            refreshView: refreshView || refresh,
        },
        true,
        false,
        {
            holidays: [],
            travelWarrant: [],
            shifts: [],
            employeeLeave: [],
        }
    );

    useEffect(() => {
        fetchHoliday(statisticData);
        fetchTravelWarrant(statisticData);
        fetchShifts(statisticData);
        fetchEmployeeLeave(statisticData);
    }, [statisticData]);

    useEffect(() => {
        mutate();
    }, [mutate, refreshView, refresh]);

    const fetchHoliday = (statisticData: any) => {
        const resolvedData: any = [];
        if (statisticData && statisticData.holiday && statisticData.holiday.length > 0) {
            statisticData.holiday.map((element: any) => {
                element.start = moment(element.date).format('yyyy-MM-DD');
                element.end = moment(element.date).format('yyyy-MM-DD');
                element.tempId = element.id;
                element.type = 'holiday';
                element.display = 'background';
                resolvedData.push(element);
            });
        }
        setEventsHolidays(resolvedData);
        if (!fullScreenDialog) {
            setEventsHolidaysInit(resolvedData);
        }
    };

    const fetchTravelWarrant = (statisticData: any) => {
        const resolvedData: any = [];
        if (statisticData && statisticData.travelWarrant && statisticData.travelWarrant) {
            statisticData.travelWarrant.map((element: any) => {
                element.color =
                    keysCalendar && keysCalendar.length > 1
                        ? settings && getColorFromType(settings, 12)
                            ? getColorFromType(settings, 12)
                            : 'blue'
                        : getTravelWarrantOverviewCardColor(element.status);
                element.start = moment(element.startDate).format('yyyy-MM-DD');
                element.end = moment(element.startDate).add(element.duration, 'days').format('yyyy-MM-DD');
                element.tempId = element.id;
                element.type = 'travelWarrant';
                resolvedData.push(element);
            });
        }
        setEventsTravelWarrant(resolvedData);
        if (!fullScreenDialog) {
            setEventsTravelWarrantInit(resolvedData);
        }
    };

    const fetchShifts = (statisticData: any) => {
        const resolvedData: any = [];
        if (statisticData && statisticData.shifts && statisticData.shifts.length > 0) {
            statisticData.shifts.map((element: any) => {
                element.start = moment(element.shiftStartDate).format('yyyy-MM-DD');
                element.end = moment(element.shiftStartDate).format('yyyy-MM-DD');
                element.tempId = element.id;
                element.type = 'shifts';
                resolvedData.push(element);
            });
        }
        setEventsShifts(resolvedData);
        if (!fullScreenDialog) {
            setEventsShiftsInit(resolvedData);
        }
    };

    const fetchEmployeeLeave = async (statisticData: any) => {
        const resolvedData: any = [];
        if (statisticData && statisticData.employeeLeave && statisticData.employeeLeave.length > 0) {
            await statisticData.employeeLeave.map((element: any) => {
                element.color =
                    keysCalendar && keysCalendar.length > 1
                        ? settings && getColorFromType(settings, 4)
                            ? getColorFromType(settings, 4)
                            : 'blue'
                        : GetEmployeeLeaveCardColor(element.status);
                element.start = moment(element.dateFrom).format('yyyy-MM-DD');
                element.end = moment(element.dateTo).add(1, 'day').format('yyyy-MM-DD');
                element.tempId = element.id;
                resolvedData.push(element);
            });
        }
        setEventsEmployeeLeave(resolvedData);
        if (!fullScreenDialog) {
            setEventsEmployeeLeaveInit(resolvedData);
        }
    };

    const resolveSelectedOptions = (selectedOptions: any, selectedValues: any, type: any) => {
        if (selectedOptions && selectedValues && type) {
            const values = selectedValues && selectedValues.filter((v: any) => v.keyType == type);
            const valuesValue = values && values[0] && values[0].selectedOptions && values[0].selectedOptions;
            return valuesValue;
        }
        return null;
    };

    const resolveSelectedValues = (selectedOptions: any, valuesValue: any) => {
        if (selectedOptions && valuesValue) {
            if (valuesValue) {
                const defaultValue = selectedOptions.filter((s: any) => valuesValue.includes(s.id));
                defaultValue.map((value: any) => {
                    value.label = value.text;
                    value.key = value.id;
                });
                return defaultValue;
            }
        }
        return null;
    };

    const onEmployeeChange = (val: any) => {
        setEmployeeIds(val ? val.filter((val: any) => val.id && val.text != '').map((value: any) => value.id) : null);
        setEmployeeCondition(null);
    };

    const onStatusChangeTravel = (val: any) => {
        setStatusTravelWarrant(
            val ? val.filter((val: any) => val.id && val.text != '').map((value: any) => value.id) : null
        );
    };

    const onStatusChangeLeave = (val: any) => {
        setStatusEmployeeLeave(
            val ? val.filter((val: any) => val.id && val.text != '').map((value: any) => value.id) : null
        );
    };

    const onCLick = (id: any, type: any, modal: any) => {
        if (id) {
            if (modal) {
                switch (type) {
                    case 'holiday':
                        break;
                    case 'shifts':
                        break;
                    case 'employeeLeave':
                        setEmployeeLeave(
                            eventsEmployeeLeave &&
                                eventsEmployeeLeave.filter((leave: any) => leave && leave.id == id) &&
                                eventsEmployeeLeave.filter((leave: any) => leave.id == id)[0]
                        );
                        dispatch(
                            setModalOpen({
                                modal: 'employeeLeaveEdit',
                                visible: true,
                                item:
                                    eventsEmployeeLeave &&
                                    eventsEmployeeLeave.filter((leave: any) => leave && leave.id == id) &&
                                    eventsEmployeeLeave.filter((leave: any) => leave.id == id)[0].id,
                                openModalData: {
                                    zIndex: 1001,
                                    canCreateAdmin: canCreateAdmin,
                                    canCreate: canCreate,
                                    setRefresh: setRefresh,
                                },
                            })
                        );
                        // setEditEmployeeLeaveVisible(true);
                        break;
                    case 'employeeSickLeave':
                        setEmployeeSickLeave(
                            eventsEmployeeLeave &&
                                eventsEmployeeLeave.filter((leave: any) => leave && leave.id == id) &&
                                eventsEmployeeLeave.filter((leave: any) => leave.id == id)[0]
                        );
                        dispatch(
                            setModalOpen({
                                modal: 'employeeLeaveEdit',
                                visible: true,
                                item:
                                    eventsEmployeeLeave &&
                                    eventsEmployeeLeave.filter((leave: any) => leave && leave.id == id) &&
                                    eventsEmployeeLeave.filter((leave: any) => leave.id == id)[0].id,
                                openModalData: {
                                    canCreateAdmin: canCreateAdmin,
                                    canCreate: canCreate,
                                    setRefresh: setRefresh,
                                },
                            })
                        );
                        // setEditEmployeeSickLeaveVisible(true);
                        break;
                }
            } else {
                switch (type) {
                    case 'travelWarrant':
                        history.push('/travel-warrant/travelWarrant/' + id);
                        break;
                    default:
                        history.push('/' + type + '/' + id);
                }
            }
        }
    };

    const getTooltipValueTravelWarrant = (travelWarrant: any) => {
        let year = travelWarrant.startDate ? moment(travelWarrant.startDate).format('YY') : '';
        let startDate = travelWarrant.startDate ? moment(travelWarrant.startDate).format('DD.MM.YYYY') : '';
        let endDate = travelWarrant.endDate ? moment(travelWarrant.endDate).format('DD.MM.YYYY') : '';
        return (
            <>
                {keysCalendar && keysCalendar.length > 1 && (
                    <>
                        {geti18nText('travelWarrant.edit.title')}
                        <hr />
                    </>
                )}
                {getTravelWarrantStatusIcon(travelWarrant.status)}
                <br />
                {`${travelWarrant?.person?.firstName} ${travelWarrant?.person?.lastName} `}
                {travelWarrant?.employee &&
                    travelWarrant?.employee?.employmentRecordId &&
                    `${geti18nText('employee.employmentId.abbreviation')}: (${
                        travelWarrant.employee.employmentRecordId
                    })`}
                <br />
                {`${startDate} - ${endDate}`}
                <br /> {geti18nText('travelWarrant.abbreviation')}:
                <Link onClick={() => onCLick(travelWarrant.id, travelWarrant.type, false)}>
                    <Text underline style={{ color: 'white' }}>
                        {` ${travelWarrant.ordinal}/${year}`}
                    </Text>
                </Link>
                <br />
            </>
        );
    };

    const getTooltipValueEmployeeLeave = (employeeLeave: any) => {
        let dateFrom = employeeLeave.dateFrom ? moment(employeeLeave.dateFrom).format('DD.MM.YYYY') : '';
        let dateTo = employeeLeave.dateTo ? moment(employeeLeave.dateTo).format('DD.MM.YYYY') : '';
        return (
            <>
                {keysCalendar && keysCalendar.length > 1 && (
                    <>
                        {geti18nText('calendar.employeeLeave')}
                        <hr />
                    </>
                )}
                {GetEmployeeLeaveStatusIcon(employeeLeave.status)}
                <br />
                {` ${geti18nText('app.enum.EMPLOYEE_LEAVE_TYPE.' + employeeLeave.type)}`}
                {getActionNeededIcon('employeeLeave', employeeLeave)}
                <br />
                {`${employeeLeave.person.firstName} ${employeeLeave.person.lastName}`}
                {employeeLeave.employee &&
                    employeeLeave.employee.employmentRecordId &&
                    ` ${geti18nText('employee.employmentId.abbreviation')}: (${
                        employeeLeave.employee.employmentRecordId
                    })`}
                <br />
                {`${dateFrom} - ${dateTo}`}
                <br />
                {geti18nText('employeeLeave.table.column.workingDays')}:
                <Link onClick={() => onCLick(employeeLeave.id, employeeLeave.url, true)}>
                    <Text underline style={{ color: 'white' }}>
                        {` ${employeeLeave.workingDays}`}
                    </Text>
                </Link>
                <br />
            </>
        );
    };

    const getActionNeededIcon = (type: any, elementValue: any) => {
        if (
            type == 'employeeLeave' &&
            canCreateAdmin &&
            elementValue.type == employeeLeaveType.SICK_LEAVE &&
            elementValue.status == employeeLeaveStatus.CONFIRMED &&
            !elementValue.employeeSickLeave
        ) {
            return <ExclamationCircleFilled style={{ color: 'red', marginLeft: '5px', fontWeight: 'bold' }} />;
        }
    };

    const resolveRenderContentType = (elementValue: any) => {
        switch (elementValue.type) {
            case 'holiday':
                return <div></div>;
            case 'travelWarrant':
                return (
                    <Tooltip placement="topLeft" title={getTooltipValueTravelWarrant(elementValue)}>
                        <Link onClick={() => onCLick(elementValue.id, elementValue.type, false)}>
                            <div style={{ fontSize: '10px', overflow: 'hidden' }}>
                                <Text>
                                    {getTravelWarrantIconSimple(elementValue?.status)}
                                    {` ${elementValue?.person?.firstName} ${elementValue?.person?.lastName} `}
                                    {elementValue?.employee &&
                                        elementValue?.employee?.employmentRecordId &&
                                        `${geti18nText('employee.employmentId.abbreviation')}: (${
                                            elementValue.employee.employmentRecordId
                                        })`}
                                </Text>
                            </div>
                        </Link>
                    </Tooltip>
                );
            case 'shifts':
                return <div></div>;
            //type =1,2,3,4 || employeeLeave ostaviti za sad plis jer polje type treba za prikaz
            case 'employeeLeave':
            case 1:
            case 2:
            case 3:
            case 4:
                if (elementValue.type == 1) {
                    elementValue.url = 'employeeLeave'; //godišnji
                } else if (elementValue.type == 2) {
                    elementValue.url = 'employeeLeave'; //plaćeni dopust
                } else if (elementValue.type == 3) {
                    elementValue.url = 'employeeLeave'; //neplaćeni dopust
                } else if (elementValue.type == 4) {
                    elementValue.url = 'employeeSickLeave'; //bolovanje
                }
                return (
                    <Tooltip placement="topLeft" title={getTooltipValueEmployeeLeave(elementValue)}>
                        <Link onClick={() => onCLick(elementValue.id, elementValue.url, true)}>
                            <div style={{ fontSize: '10px', overflow: 'hidden' }}>
                                <Text>
                                    {getEmployeeLeaveOverviewIconSimple(elementValue.status)}
                                    {` ${elementValue.person.firstName} ${elementValue.person.lastName}`}
                                    {elementValue.employee &&
                                        elementValue.employee.employmentRecordId &&
                                        ` ${geti18nText('employee.employmentId.abbreviation')}: (${
                                            elementValue.employee.employmentRecordId
                                        })`}
                                </Text>
                                {getActionNeededIcon('employeeLeave', elementValue)}
                            </div>
                        </Link>
                    </Tooltip>
                );
            default:
                return <div></div>;
        }
    };

    function renderEventContent(eventInfo: any) {
        let elementValue: any = null;
        if (eventInfo && eventInfo.event && eventInfo.event._def && eventInfo.event._def.extendedProps) {
            elementValue = { ...eventInfo.event._def.extendedProps };
            if (!elementValue.id && elementValue.tempId) {
                elementValue.id = elementValue.tempId;
            }
            if (elementValue) {
                return resolveRenderContentType(elementValue);
            }
        }
    }

    const handleDateClick = (date: any) => {
        setSelectedDate(date);
    };

    const handleEventClick = (eventInfo: any) => {};

    const handleEvents = (events: any) => {
        /*   if (events && events[0]?._context?.currentViewType != undefined) {
			console.log('event -->', events[0]?._context?.currentViewType);
			document.querySelectorAll('button.fc-prev-button')?.forEach((element) => {
				switch (events[0]?._context?.currentViewType) {
					case 'dayGridWeek':
						element.setAttribute('title', geti18nText('app.default.filter.range.prevWeek'));
						break;
					case 'dayGridMonth':
						element.setAttribute('title', geti18nText('app.default.filter.range.prevMonth'));
						break;
					case 'listMonth':
						element.setAttribute('title', geti18nText('app.default.filter.range.prevMonth'));
						break;
					case 'dayGridDay':
						element.setAttribute('title', geti18nText('app.default.filter.range.prevDay'));
						break;
				}
			});
			document.querySelectorAll('button.fc-next-button')?.forEach((element) => {
				switch (events[0]?._context?.currentViewType) {
					case 'dayGridWeek':
						element.setAttribute('title', geti18nText('app.default.filter.range.nextWeek'));
						break;
					case 'dayGridMonth':
						element.setAttribute('title', geti18nText('app.default.filter.range.nextMonth'));
						break;
					case 'listMonth':
						element.setAttribute('title', geti18nText('app.default.filter.range.nextMonth'));
						break;
					case 'dayGridDay':
						element.setAttribute('title', geti18nText('app.default.filter.range.nextDay'));
						break;
				}
			});
		} */
    };

    const closeFullscreenModal = () => {
        if (isCard) setFullScreenDialogVisible(false);
        setFullScreenDialog(false);
        setStartDate(startDateInit);
        setEndDate(endDateInit);
    };

    const closeFilterModal = () => {
        if (isCard) setFilterDialogVisible(false);
        setFilterDialog(false);
    };

    return (
        <React.Fragment>
            <div style={{ fontSize: '10px' }}>
                {!isCard && (
                    <Row justify="end">
                        <div style={{ position: 'sticky', top: '0px', marginBottom: '1px' }}>
                            <Tooltip title={geti18nText('app.default.filter')}>
                                <Button icon={<FilterOutlined />} onClick={() => setFilterDialog(true)} />
                            </Tooltip>
                            {!isFullScreenCalendar && (
                                <Tooltip title={geti18nText('app.default.fullScreen')}>
                                    <Button icon={<FullscreenOutlined />} onClick={() => setFullScreenDialog(true)} />
                                </Tooltip>
                            )}
                        </div>
                    </Row>
                )}
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                    initialView={initialView}
                    events={
                        fullScreenDialog
                            ? [
                                  ...eventsHolidaysInit,
                                  ...eventsTravelWarrantInit,
                                  ...eventsShiftsInit,
                                  ...eventsEmployeeLeaveInit,
                              ]
                            : [...eventsHolidays, ...eventsTravelWarrant, ...eventsShifts, ...eventsEmployeeLeave]
                    }
                    locale={currentLang}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,listMonth',
                    }}
                    buttonText={{
                        today: geti18nText('app.default.filter.range.today'),
                        month: geti18nText('app.default.filter.range.month'),
                        week: geti18nText('app.default.filter.range.week'),
                        day: geti18nText('app.default.filter.range.day'),
                        list: geti18nText('app.default.list'),
                    }}
                    defaultAllDay={defaultAllDay}
                    firstDay={firstDay}
                    navLinks={navLinks}
                    stickyHeaderDates={stickyHeaderDates}
                    showNonCurrentDates={showNonCurrentDates}
                    height={height}
                    weekends={weekends}
                    editable={editable}
                    selectable={selectable}
                    expandRows={expandRows}
                    eventClick={handleEventClick}
                    dateClick={handleDateClick}
                    eventsSet={() => setTimeout(handleEvents, 500)}
                    eventContent={renderEventContent}
                    datesSet={(dateInfo) => {
                        setStartDate(dateInfo.start);
                        setEndDate(dateInfo.end);
                        setStartDateInit(dateInfo.start);
                        setEndDateInit(dateInfo.end);
                    }}
                    noEventsContent={null}
                />
            </div>
            {(fullScreenDialogVisible || fullScreenDialog) && (
                <Modal
                    visible={fullScreenDialogVisible || fullScreenDialog}
                    title={geti18nText('widgets.calendar')}
                    onOk={closeFullscreenModal}
                    onCancel={closeFullscreenModal}
                    width={1200}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    <Row justify="end" style={{ marginTop: '-10px' }}>
                        <div>
                            <Tooltip title={geti18nText('app.default.filter')}>
                                <Button icon={<FilterOutlined />} onClick={() => setFilterDialog(true)} />
                            </Tooltip>
                        </div>
                    </Row>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                        initialView={initialView}
                        events={[...eventsHolidays, ...eventsTravelWarrant, ...eventsShifts, ...eventsEmployeeLeave]}
                        locale={currentLang}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth',
                        }}
                        buttonText={{
                            today: geti18nText('app.default.filter.range.today'),
                            month: geti18nText('app.default.filter.range.month'),
                            week: geti18nText('app.default.filter.range.week'),
                            day: geti18nText('app.default.filter.range.day'),
                            list: geti18nText('app.default.list'),
                        }}
                        defaultAllDay={defaultAllDay}
                        firstDay={firstDay}
                        navLinks={navLinks}
                        stickyHeaderDates={stickyHeaderDates}
                        showNonCurrentDates={showNonCurrentDates}
                        height={'600px'}
                        weekends={weekends}
                        editable={editable}
                        selectable={selectable}
                        expandRows={expandRows}
                        eventClick={handleEventClick}
                        dateClick={handleDateClick}
                        eventsSet={handleEvents}
                        eventContent={renderEventContent}
                        datesSet={(dateInfo) => {
                            setStartDate(dateInfo.start);
                            setEndDate(dateInfo.end);
                        }}
                    />
                </Modal>
            )}
            {(filterDialogVisible || filterDialog) && (
                <Modal
                    visible={filterDialogVisible || filterDialog}
                    title={geti18nText('app.default.filter')}
                    onOk={closeFilterModal}
                    onCancel={closeFilterModal}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    <Form form={filterForm} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        {isDashboard && (
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('menu.travel.warrant')}
                                        name="travelWarrant"
                                        valuePropName="checked"
                                        initialValue={hasKey('travelWarrant')}
                                    >
                                        <Checkbox
                                            style={{ width: '30%' }}
                                            checked={hasKey('travelWarrant')}
                                            onChange={(event: any) => {
                                                changeActiveKeys(event.target.checked, 'travelWarrant');
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('calendar.employeeLeave')}
                                        name="employeeLeave"
                                        valuePropName="checked"
                                        initialValue={hasKey('employeeLeave')}
                                    >
                                        <Checkbox
                                            style={{ width: '30%' }}
                                            checked={hasKey('employeeLeave')}
                                            onChange={(event: any) => {
                                                changeActiveKeys(event.target.checked, 'employeeLeave');
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {!isPersonal && isAdmin && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('travelWarrant.edit.employee')} name="employee">
                                        <div>
                                            <NySearchField
                                                url={employeeUrl}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                    businessUnit: 'businessUnit',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                renderOption={customEmployeeRenderOption}
                                                customItemNameLabel={'firstName lastName'}
                                                SearchPopupComponent={
                                                    <EmployeeIndex
                                                        disabled={true}
                                                        filteredForTravel={true}
                                                        parentKey="ny-full-callendar"
                                                    />
                                                }
                                                style={{ width: '100%' }}
                                                onChange={onEmployeeChange}
                                                mode={'multiple'}
                                                order="person.last_name, person.first_name"
                                                disabled={isPersonal || !isAdmin}
                                            />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {hasKey('travelWarrant') && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('calendar.status.travelWarrant')}
                                        name="travelWarrantStatus"
                                    >
                                        <NySearchField
                                            options={getEnumArrayStatus('TRAVEL_WARRANT_STATUS')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            className=""
                                            style={{ width: '100%' }}
                                            onChange={onStatusChangeTravel}
                                            mode={'multiple'}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {hasKey('employeeLeave') && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('calendar.status.employeeLeave')}
                                        name="employeeLeaveStatus"
                                    >
                                        <NySearchField
                                            options={getEnumArrayStatus('EMPLOYEE_LEAVE_STATUS')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="text"
                                            className=""
                                            style={{ width: '100%' }}
                                            onChange={onStatusChangeLeave}
                                            mode={'multiple'}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </Modal>
            )}
            {/* {editEmployeeLeaveVisible && (
                <EmployeeLeaveReqEdit
                    visible={editEmployeeLeaveVisible}
                    onOk={() => setEditEmployeeLeaveVisible(false)}
                    onCancel={() => setEditEmployeeLeaveVisible(false)}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    editProps={{
                        value: employeeLeave,
                        setVisible: setEditEmployeeLeaveVisible,
                        refresh: refresh,
                        setRefresh: setRefresh,
                        canCreate: canCreate,
                        canCreateAdmin: canCreateAdmin,
                        editHeader: geti18nText('employee.leave.new.request'),
                        submitButtonText: geti18nText('employee.leave.send.request'),
                        isHumanResources: false,
                        isVacation: true,
                        search: search,
                    }}
                />
            )}
            {editEmployeeSickLeaveVisible && (
                <EmployeeLeaveReqEdit
                    visible={editEmployeeSickLeaveVisible}
                    onOk={() => setEditEmployeeSickLeaveVisible(false)}
                    onCancel={() => setEditEmployeeSickLeaveVisible(false)}
                    width={600}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    editProps={{
                        value: employeeSickLeave,
                        setVisible: setEditEmployeeSickLeaveVisible,
                        refresh: refresh,
                        setRefresh: setRefresh,
                        canCreate: canCreate,
                        canCreateAdmin: canCreateAdmin,
                        editHeader: geti18nText('sickLeave.edit.new'),
                        submitButtonText: geti18nText('sickLeave.table.add'),
                        isHumanResources: false,
                        isVacation: false,
                    }}
                />
            )} */}
        </React.Fragment>
    );
};

export default NyFullCalendar;
