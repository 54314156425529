import { NyDataEdit, NyDatePicker, NyInputNumber, NySearchField, geti18nText } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getDateFormat,
    getEnumArray,
    getEnumFormat,
    getSearchFormat,
    setDateFormat,
    setEnumFormat,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import LocationSearch from '../location/search';

const AttendanceEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('attendance.edit.new'));
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceOrAdmin();
    };

    function setValues(dataForm: any) {
        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }
        if (dataForm.from) {
            dataForm.from = setDateFormat(dataForm.from);
        }
        if (dataForm.fromReal) {
            dataForm.fromReal = setDateFormat(dataForm.fromReal);
        }
        if (dataForm.to) {
            dataForm.to = setDateFormat(dataForm.to);
        }
        if (dataForm.toReal) {
            dataForm.toReal = setDateFormat(dataForm.toReal);
        }

        if (dataForm.employee) {
            if (dataForm.employee.person) {
                if (dataForm.employee.person.oib) {
                    dataForm.employee = {
                        id: dataForm.employee.id,
                        text:
                            dataForm.employee.person.firstName +
                            ' ' +
                            dataForm.employee.person.lastName +
                            ' (' +
                            dataForm.employee.person.oib +
                            ')',
                    };
                } else {
                    dataForm.employee = {
                        id: dataForm.employee.id,
                        text: dataForm.employee.person.firstName + ' ' + dataForm.employee.person.lastName,
                    };
                }
            }
        }

        if (dataForm.type) {
            dataForm.type = setEnumFormat('ATTENDANCE_TYPE', dataForm.type);
        }

        if (dataForm.hasOwnProperty('employee')) {
            setEditHeader(geti18nText('attendance.edit.title') + ' - ' + dataForm.employee.text);
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('attendance.edit.new'));
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ATTENDANCE.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            buttonsClassName="buttons-sticky"
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            goBack={() => {
                history.goBack();
            }}
            fetchWhenChange={id}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.date) {
                    let date = values.date.clone();
                    values.date = getDateFormat(values.date);
                    values.employee = getSearchFormat(values.employee);

                    if (values.from) {
                        let time = moment(values.from, 'HH:mm');
                        values.from = getDateFormat(
                            date.clone().set({ hour: time.get('hour'), minute: time.get('minute') })
                        );
                    }
                    if (values.to) {
                        let time = moment(values.to, 'HH:mm');
                        values.to = getDateFormat(
                            date.clone().set({ hour: time.get('hour'), minute: time.get('minute') })
                        );
                    }
                    if (values.fromReal) {
                        let time = moment(values.fromReal, 'HH:mm');
                        values.fromReal = getDateFormat(
                            date.clone().set({ hour: time.get('hour'), minute: time.get('minute') })
                        );
                    }
                    if (values.toReal) {
                        let time = moment(values.toReal, 'HH:mm');
                        values.toReal = getDateFormat(
                            date.clone().set({ hour: time.get('hour'), minute: time.get('minute') })
                        );
                    }
                }
                if (values.type) values.type = getEnumFormat(values.type);
                return values;
            }}
            shortcuts={true}
            checkIsFormChanged={true}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('attendance.edit.employee')}
                        name="employee"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="attendance-edit"/>}
                            nyTestId="employee"
                            autoFocus={true}
                            order="person.last_name, person.first_name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('attendance.edit.type')}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('ATTENDANCE_TYPE')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            nyTestId="type"
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('attendance.edit.date')}
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker style={{ width: '100%' }} nyTestId="date" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={geti18nText('attendance.edit.hours')} name="hours">
                        <NyInputNumber style={{ width: '100%' }} nyTestId="hours" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('attendance.edit.from')}
                        name="from"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            mode={'time'}
                            showTime
                            format="HH:mm"
                            style={{ width: '100%' }}
                            nyTestId="time-from"
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={geti18nText('attendance.edit.to')}
                        name="to"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            mode={'time'}
                            showTime
                            format="HH:mm"
                            style={{ width: '100%' }}
                            nyTestId="time-to"
                        />
                    </Form.Item>
                </Col>
                {AdministrationRights.isAdmin() && (
                    <>
                        <Col span={6}>
                            <Form.Item label={geti18nText('attendance.edit.fromReal')} name="fromReal">
                                <NyDatePicker
                                    mode={'time'}
                                    showTime
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    nyTestId="real-time-from"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={geti18nText('attendance.edit.toReal')} name="toReal">
                                <NyDatePicker
                                    mode={'time'}
                                    showTime
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    nyTestId="real-time-to"
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
            {AdministrationRights.isAdmin() && (
                <Row gutter={24}>
                    <Col span={12}>
                        <LocationSearch label={geti18nText('attendance.edit.locationIn')} name="locationIn" />
                    </Col>
                    <Col span={12}>
                        <LocationSearch label={geti18nText('attendance.edit.locationOut')} name="locationOut" />
                    </Col>
                </Row>
            )}
        </NyDataEdit>
    );
};

export default AttendanceEdit;
