import { ScheduleOutlined } from '@ant-design/icons';
import { geti18nText, NyDatePicker, NyInputNumber, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Input, Modal, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NyNoteModal from '../../../../components/note-modal';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorCustomNotification,
    errorNotification,
    getDateFormat,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import CompanySearch from '../../../administration/views/company/search';
import WarehouseAcquisitionItems from './WarehouseAcquisitionItems';

const { Panel } = Collapse;

const CreateAssetAcquisitionModal = ({
    warehouseReceiptId,
    warehouseId,
    warehouseItems,
    ord,
    company,
    document,
    date,
    bookedAsset,
    setBookedAsset,
    disabled = false,
}: any) => {
    const history = useHistory();
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '3']);
    const [note, setNote] = useState<any>(undefined);
    const [tempId, setTempId] = useState(0);
    const [disabledInventoryNumber, setDisabledInventoryNumber] = useState(false);
    const [items, setItems] = useState<any>([]);
    const [form] = Form.useForm();
    const [formItems] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const setDefaultFilterValueAppSettings = () => {
        return [
            { field: 'setting_group', condition: 'equals', value: 'ASSET_DEPRECIATION' },
            { field: 'setting_key', condition: 'equals', value: 'AUTO_ASSIGN_INVENTORY_NUMBER' },
        ];
    };

    const setDefaultFilterAssetAcquisition = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'warehouse_receipt_id', condition: 'equals', value: warehouseReceiptId },
        ];
    };

    useEffect(() => {
        fetchAppSettings();
    }, [warehouseReceiptId]);

    const fetchAppSettings = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValueAppSettings())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.length == 1) {
                    let value =
                        result.data[0] && result.data[0].value
                            ? result.data[0].value
                            : result.data[0].defaultValue
                            ? result.data[0].defaultValue
                            : '0';
                    setDisabledInventoryNumber(value == '1' ? true : false);
                }
            }
        });
    };

    const openAssetAcquisition = (id: any) => {
        if (id) {
            history.push('/accounting/assets/asset-acquisition/' + id);
        }
    };

    const fetchAssetAcquisition = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_ACQUISITION.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterAssetAcquisition())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content && result.data.content[0] && result.data.content[0].id) {
                    errorCustomNotification(
                        geti18nText('app.default.save.nok'),
                        <>
                            {geti18nText('warehouseReceipt.button.asset.exists')}:
                            <Button type="link" onClick={() => openAssetAcquisition(result.data.content[0].id)}>
                                <span style={{ textDecoration: 'underline' }}>
                                    {result.data.content[0].ord ? result.data.content[0].ord + '/' : ''}
                                    {result.data.content[0].date
                                        ? moment(result.data.content[0].date).format('YYYY')
                                        : ''}
                                </span>
                            </Button>
                        </>
                    );
                }
            }
        });
    };

    const normalize = (values: any) => {
        values.warehouseReceipt = { id: warehouseReceiptId };
        values.warehouse = { id: warehouseId };

        if (values.date) values.date = getDateFormat(values.date);
        if (values.company) {
            values.company = getSearchFormat(values.company);
            if (values.company.id < 1) {
                delete values.company;
            }
        }
        values.booked = false;
        values.bookedGeneralLedger = false;

        const acquisitionItem: any = [];
        let tempItems: any = _.cloneDeep(items);
        if (tempItems != undefined && tempItems.length > 0) {
            tempItems.map((element: any) => {
                delete element.tempId;
                if (element.item) element.item = getSearchFormat(element.item);

                if (element.assetDepreciationGroup)
                    element.assetDepreciationGroup = getSearchFormat(element.assetDepreciationGroup);
                if (element.assetModel) element.assetModel = getSearchFormat(element.assetModel);
                if (element.assetType) element.assetType = getSearchFormat(element.assetType);
                acquisitionItem.push(element);
            });
        }
        values.warehouseAcquisitionItems = acquisitionItem;
        return values;
    };

    const itemsComplete = () => {
        let tempItems: any = [...items];
        if (tempItems != undefined && tempItems.length > 0) {
            tempItems.map((element: any) => {
                if (!element.item || !element.assetDepreciationGroup || !element.assetModel || !element.assetType) {
                    return false;
                }
            });
        }
        return true;
    };

    const save = (event: any) => {
        event.preventDefault();
        if (itemsComplete()) {
            setLoading(true);
            form.validateFields()
                .then((values: any) => {
                    normalize(values);
                    if (values != null) {
                        NyRequestResolver.requestPost(CONSTANTS_REQ.ASSET_ACQUISITION.EDIT, undefined, values).then(
                            (result: any) => {
                                if (result && result.status === RESPONSE.CREATED) {
                                    okNotification();
                                    setBookedAsset(true);
                                    closeModal();
                                } else if (result.data && result.data.error) {
                                    errorNotification(result.data.error);
                                } else {
                                    errorNotification();
                                }
                            }
                        );
                    }
                })
                .catch((errorInfo: any) => {});
            setLoading(false);
        } else {
            errorCustomNotification(
                geti18nText('app.default.save.nok'),
                geti18nText('warehouseReceipt.notification.form')
            );
        }
    };

    const mapWarehouseReceiptItem = () => {
        let formItem: any = {};
        if (ord) formItem.ord = ord;
        if (company) formItem.company = setSearchFormat(company, 'name', 'name');
        if (document) formItem.documentInfo = document;
        if (date) formItem.date = setDateFormat(date);
        form.setFieldsValue(formItem);

        const acquisitionItem: any = [];
        if (warehouseItems && warehouseItems.length > 0) {
            let tempIdValue = tempId;
            warehouseItems.map((element: any) => {
                if (element.item && (element.item.type == 4 || element.item.type == 5)) {
                    let temp: any = {};
                    tempIdValue = tempIdValue + 1;
                    temp.tempId = tempIdValue;
                    temp.item = element.item;
                    temp.quantity = element.quantity ? element.quantity : 0;
                    temp.price = element.price
                        ? element.price
                        : element.netPrice
                        ? element.netPrice
                        : element.invoicedPrice
                        ? element.invoicedPrice
                        : 0;
                    acquisitionItem.push(temp);
                }
            });
            setTempId(tempIdValue);
        }

        setItems(acquisitionItem);
    };

    const openModal = () => {
        if (bookedAsset) {
            fetchAssetAcquisition();
        } else {
            mapWarehouseReceiptItem();
            setModalVisible(true);
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setTempId(0);
        form.resetFields();
    };

    return (
        <>
            <Button icon={<ScheduleOutlined />} onClick={openModal} style={{ width: '100%' }} disabled={disabled}>
                {geti18nText('warehouseReceipt.edit.createAssetAcquisition')}
            </Button>
            {modalVisible && (
                <Modal
                    title={geti18nText('asset.acquisition.edit.title')}
                    visible={modalVisible}
                    maskClosable={false}
                    okText={geti18nText('warehouseReceipt.edit.createAssetAcquisition')}
                    width={1200}
                    onOk={save}
                    onCancel={closeModal}
                    confirmLoading={loading}
                >
                    <Form form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Collapse
                                    activeKey={collapseActiveKeys}
                                    onChange={(key: any) => {
                                        setCollapseActiveKeys(key);
                                    }}
                                >
                                    <Panel
                                        header={geti18nText('asset.acquisition.tab.1')}
                                        key="1"
                                        extra={
                                            <NyNoteModal
                                                note={note}
                                                modalTitle={
                                                    geti18nText('asset.acquisition.edit.title') +
                                                    ' - ' +
                                                    geti18nText('app.default.note')
                                                }
                                            />
                                        }
                                    >
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item name="id" style={{ display: 'none' }}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item label={geti18nText('asset.acquisition.edit.ord')} name="ord">
                                                    <NyInputNumber style={{ width: '100%' }} nyTestId="ord" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <CompanySearch
                                                    label={geti18nText('asset.acquisition.edit.company')}
                                                    isModal={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('asset.acquisition.edit.document')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                    name="documentInfo"
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.date')}
                                                    name="date"
                                                    initialValue={moment()}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyDatePicker style={{ width: '100%' }} nyTestId="date" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                            <Form component={false} form={formItems}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Collapse
                                        activeKey={collapseActiveKeys}
                                        onChange={(key: any) => {
                                            setCollapseActiveKeys(key);
                                        }}
                                    >
                                        <Panel header={geti18nText('asset.writeOff.divider.2')} key="3">
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <WarehouseAcquisitionItems
                                                        items={items}
                                                        setItems={setItems}
                                                        tempId={tempId}
                                                        setTempId={setTempId}
                                                        disabledInventoryNumber={disabledInventoryNumber}
                                                    />
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Form>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default CreateAssetAcquisitionModal;
