import { DeleteOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { getColumnDateOption, getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { ReactText, useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { AssetRights } from '../../../../rights/assetRights';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { InventoryRights } from '../../../../rights/inventoryRights';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { ServicesRights } from '../../../../rights/servicesRights';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployee,
    getColumnSearchByCodebookEmployeeNotification,
    getColumnSearchByCodebookSimple,
} from '../../../../utils/Filters';
import { errorNotification, getEnumArrayNotificationSettings, okNotification } from '../../../../utils/Utils';
import NotificationSettingsEdit from './edit';

const NotificationSettingsIndex = ({
    moduleType = undefined,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);
    const [selectedValuesDelete, setSelectedValuesDelete] = useState<any>([]);
    const [hasSelectedDelete, setHasSelectedDelete] = useState(false);
    const [filterDate, setFilterDate] = useState(undefined);

    useHelper('common/notification_settings' + (moduleType ? '_' + moduleType : ''));

    const canCreate = () => {
        if (AdministrationRights.isAdmin()) {
            return true;
        } else if (moduleType == 1) {
            return ServicesRights.canCreateSettings();
        } else if (moduleType == 2) {
            return InventoryRights.canCreateSettings();
        } else if (moduleType == 3) {
            return AssetRights.canCreateSettings();
        } else if (moduleType == 4) {
            return TravelWarrantRights.canOnlyCreateSettings();
        } else if (moduleType == 5) {
            return HumanResourcesRights.canCreateSettings();
        } else if (moduleType == 8) {
            return MobilizationRights.canOnlyCreateSettings();
        } else {
            return AdministrationRights.canCreateSettings();
        }
    };

    const setDefaultFilterValue = () => {
        let filter = table
            .setDefaultFilterValue()
            .filter((item: any) => item.field !== 'filter_date' && item.field !== 'active');

        if (filterDate) {
            filter.push({
                field: 'filter_date',
                condition: 'equals',
                value: moment(filterDate).format('YYYY-MM-DD HH:mm'),
            });
        } else {
            filter.push({ field: 'active', condition: 'equals_bool', value: 1 });
        }
        return filter;
    };

    let clearKeysDelete: any;

    const setSelectedValuesFuncDelete = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDelete(hasSelected);
        setSelectedValuesDelete(selectedRowKeys);
        clearKeysDelete = clearSelectedRowKeys;
    };

    const columns = [
        ...[
            {
                title: geti18nText('notificationSettings.table.column.id'),
                dataIndex: 'id',
                width: '4%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('notificationSettings.table.column.notificationType'),
                dataIndex: 'notificationType',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchCheckbox(getEnumArrayNotificationSettings(moduleType)),
                render: (text: any, record: any) => {
                    if (record.notificationType !== undefined) {
                        return geti18nText('app.enum.NOTIFICATION_TYPE_REQUEST.' + record.notificationType);
                    }
                },
            },
            {
                title: geti18nText('notificationSettings.table.column.user'),
                dataIndex:
                    "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),''))",
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookEmployee(),
                render: (person: any, record: any) => {
                    if (record && record.person) {
                        return (
                            <>
                                {`${record.person.firstName} ${record.person.lastName} `}
                                {record.employee &&
                                    record.employee.employmentRecordId &&
                                    ` (${record.employee.employmentRecordId})`}
                            </>
                        );
                    }
                },
            },
            {
                title: geti18nText('notificationSettings.table.column.businessUnit'),
                dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookBusinessUnit(),
                render: (text: any, record: any) => {
                    if (record.businessUnit) {
                        return (
                            record.businessUnit.name +
                            (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                        );
                    }
                },
            },
        ],
        ...(Object.values([1, 2, 3]).includes(moduleType)
            ? [
                  {
                      title: geti18nText('notificationSettings.table.column.orderView'),
                      dataIndex: ['orderView', 'name'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.ORDER_VIEW.SEARCH,
                          'orderView.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          }
                      ),
                  },
              ]
            : []),
        {
            title: geti18nText('notificationSettings.table.column.notificationUser'),
            dataIndex:
                "concat(notification_person.first_name, ' ', notification_person.last_name, ' ', coalesce(cast(notification_employee.employment_record_id as varchar),''))",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployeeNotification(),
            render: (text: any, record: any) => {
                if (record.notificationPerson) {
                    return (
                        `${record.notificationPerson.firstName} ${record.notificationPerson.lastName}` +
                        (record.notificationEmployee && record.notificationEmployee.employmentRecordId
                            ? ` (${record.notificationEmployee.employmentRecordId})`
                            : '')
                    );
                }
            },
        },
        {
            title: geti18nText('menu.access.rights.table.from'),
            dataIndex: 'created',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (record.created) {
                    return moment(record.created).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('menu.access.rights.table.to'),
            dataIndex: 'updated',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (text: any, record: any) => {
                if (!record.active && record.updated) {
                    return moment(record.updated).format('DD.MM.YYYY HH:mm');
                }
            },
        },
    ];

    const OnFilterDateChange = (value: any) => {
        setFilterDate(value);
        setRefreshTable(refreshTable + 1);
    };

    const deleteSettings = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.DELETE, undefined, {
            ids: selectedValuesDelete,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDelete) {
                    clearKeysDelete();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification();
            }
        });
    };

    const addedButtons = (onSave: () => void) => {
        return (
            <>
                {canCreate() && (
                    <div style={{ float: 'left', paddingLeft: '15px' }}>
                        <NyModalConfirm
                            title={geti18nText('app.default.destructive.confirm')}
                            onConfirm={() => {
                                deleteSettings();
                            }}
                        >
                            <Button type="primary" disabled={!hasSelectedDelete} danger icon={<DeleteOutlined />}>
                                {geti18nText('app.default.button.deactivate')}
                            </Button>
                        </NyModalConfirm>
                    </div>
                )}
            </>
        );
    };

    const getListUrl = () => {
        let url = '';
        if (moduleType == 1) {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST_SERVICES;
        } else if (moduleType == 2) {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST_INVENTORY;
        } else if (moduleType == 3) {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST_ASSET;
        } else if (moduleType == 4) {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST_TRAVEL;
        } else if (moduleType == 5) {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST_HR;
        } else if (moduleType == 8) {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST_MOBILIZATION;
        } else {
            url = CONSTANTS_REQ.NOTIFICATION_RECIPIENT_SETTINGS.LIST;
        }
        return url;
    };

    return (
        <React.Fragment>
            <Form.Item label={geti18nText('menu.access.rights.table.valid.on.date')}>
                <NyDatePicker
                    value={filterDate}
                    showTime
                    format="DD.MM.YYYY HH:mm"
                    style={{ width: '100%', maxWidth: '150px' }}
                    onChange={(value: any) => {
                        OnFilterDateChange(value);
                    }}
                />
            </Form.Item>
            <NyDataTable
                nyId="notification-settings-table"
                url={getListUrl()}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                modalComponent={NotificationSettingsEdit}
                selectOnSave={false}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                hideNewButton={!canCreate()}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                fetchWhenChange={refreshTable}
                rowSelectionModal={setSelectedValuesFuncDelete as any}
                showRowSelection={canCreate()}
                addedButtons={addedButtons}
                editProps={{
                    moduleType: moduleType,
                    setRefreshTable: setRefreshTable,
                    refreshTable: refreshTable,
                }}
            />
        </React.Fragment>
    );
};

export default NotificationSettingsIndex;
