import { ReloadOutlined } from '@ant-design/icons';
import { geti18nText, NyColorPicker, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Collapse, Form, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, hexToRgb, isValidRGB, okNotification } from '../../../../utils/Utils';

const TenantThemeChanger = ({ canCreate }: any) => {
    const { Panel } = Collapse;
    const [form] = Form.useForm();
    const [activeCollapseKey, setActiveCollapseKey] = useState(['1', '2', '3']);
    const [currentData, setCurrentData] = useState<any>(undefined);
    const [dataForm, setDataForm] = useState<any>([]);
    const [defaultData, setDefaultData] = useState([]);
    const [primaryColor, setPrimaryColor] = useState<any>('24, 144, 255');
    const [collapseBgColor, setCollapseBgColor] = useState<any>('250, 250, 250');
    const [collapseBgColorHeader, setCollapseBgColorHeader] = useState<any>('50, 250, 250');
    const [collapseBgColorDark, setCollapseBgColorDark] = useState<any>('9, 19, 36');
    const [collapseBgColorHeaderDark, setCollapseBgColorHeaderDark] = useState<any>('18, 25, 36');

    const setDefaultFilterValue = (group: any) => {
        return [{ field: 'setting_group', condition: 'equals', value: group }];
    };

    useEffect(() => {
        fetch('APP_COLORS');
    }, []);

    const fetch = (group: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue(group))),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    let newData: any = [...defaultData, ...result.data];
                    setDefaultData(newData);
                }
            }
        });
    };

    useEffect(() => {
        if (currentData) {
            setDataForm([...dataForm, ...currentData]);
        }
    }, [currentData]);

    function setValues(dataForm: any) {
        let newDataForm: any = {};
        setCurrentData(dataForm);

        const PRIMARY_COLOR = dataForm.find((item: any) => item.settingKey === 'PRIMARY_COLOR')?.value;
        const BACKGROUND_COLLAPSE_COLOR = dataForm.find(
            (item: any) => item.settingKey === 'BACKGROUND_COLLAPSE_COLOR'
        )?.value;
        const BACKGROUND_COLLAPSE_HEADER_COLOR = dataForm.find(
            (item: any) => item.settingKey === 'BACKGROUND_COLLAPSE_HEADER_COLOR'
        )?.value;
        const BACKGROUND_COLLAPSE_COLOR_DARK = dataForm.find(
            (item: any) => item.settingKey === 'BACKGROUND_COLLAPSE_COLOR_DARK'
        )?.value;
        const BACKGROUND_COLLAPSE_HEADER_COLOR_DARK = dataForm.find(
            (item: any) => item.settingKey === 'BACKGROUND_COLLAPSE_HEADER_COLOR_DARK'
        )?.value;

        if (PRIMARY_COLOR)
            newDataForm.PRIMARY_COLOR = isValidRGB(PRIMARY_COLOR) ? PRIMARY_COLOR : `rgb(${PRIMARY_COLOR})`;
        if (BACKGROUND_COLLAPSE_COLOR)
            newDataForm.BACKGROUND_COLLAPSE_COLOR = isValidRGB(BACKGROUND_COLLAPSE_COLOR)
                ? BACKGROUND_COLLAPSE_COLOR
                : `rgb(${BACKGROUND_COLLAPSE_COLOR})`;
        if (BACKGROUND_COLLAPSE_HEADER_COLOR)
            newDataForm.BACKGROUND_COLLAPSE_HEADER_COLOR = isValidRGB(BACKGROUND_COLLAPSE_HEADER_COLOR)
                ? BACKGROUND_COLLAPSE_HEADER_COLOR
                : `rgb(${BACKGROUND_COLLAPSE_HEADER_COLOR})`;
        if (BACKGROUND_COLLAPSE_COLOR_DARK)
            newDataForm.BACKGROUND_COLLAPSE_COLOR_DARK = isValidRGB(BACKGROUND_COLLAPSE_COLOR_DARK)
                ? BACKGROUND_COLLAPSE_COLOR_DARK
                : `rgb(${BACKGROUND_COLLAPSE_COLOR_DARK})`;
        if (BACKGROUND_COLLAPSE_HEADER_COLOR)
            newDataForm.BACKGROUND_COLLAPSE_HEADER_COLOR_DARK = isValidRGB(BACKGROUND_COLLAPSE_HEADER_COLOR_DARK)
                ? BACKGROUND_COLLAPSE_HEADER_COLOR_DARK
                : `rgb(${BACKGROUND_COLLAPSE_HEADER_COLOR_DARK})`;

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                let params: any = [];

                function isHexColor(value: any) {
                    const hexRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
                    return hexRegex.test(value);
                }

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    let rgbValue = null;

                    if (isHexColor(value)) {
                        rgbValue = hexToRgb(value);
                    } else if (isValidRGB(value)) {
                        rgbValue = value;
                    }

                    if (value != undefined && value != null && rgbValue && item?.id != undefined) {
                        params.push({ id: item.id, value: rgbValue });
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        okNotification();
                        window.location.reload();
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                console.log(errorInfo);
            });
    };

    const revertColor = (field: any, defaultColor: any) => {
        form.setFieldValue(field, defaultColor);
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={activeCollapseKey}
                        onChange={(key: any) => {
                            setActiveCollapseKey(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.tenantThemeChanger.default')} key="2">
                            <Row gutter={24}>
                                <Col span={1}>
                                    <Tooltip title={geti18nText('app.default.revertColor')}>
                                        <Button
                                            size="small"
                                            style={{ marginTop: '33px' }}
                                            onClick={() =>
                                                revertColor('BACKGROUND_COLLAPSE_COLOR', 'rgb(250, 250, 250)')
                                            }
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.TENANT_COLORS.BACKGROUND_COLLAPSE_COLOR'
                                        )}
                                        name="BACKGROUND_COLLAPSE_COLOR"
                                    >
                                        <NyColorPicker
                                            value={collapseBgColor}
                                            defaultColor={collapseBgColor}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    setCollapseBgColor(hexToRgb(value));
                                                }
                                            }}
                                            popover
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Tooltip title={geti18nText('app.default.revertColor')}>
                                        <Button
                                            size="small"
                                            style={{ marginTop: '33px' }}
                                            onClick={() =>
                                                revertColor('BACKGROUND_COLLAPSE_HEADER_COLOR', 'rgb(250, 250, 250)')
                                            }
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.TENANT_COLORS.BACKGROUND_COLLAPSE_HEADER_COLOR'
                                        )}
                                        name="BACKGROUND_COLLAPSE_HEADER_COLOR"
                                    >
                                        <NyColorPicker
                                            value={collapseBgColorHeader}
                                            defaultColor={collapseBgColorHeader}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    setCollapseBgColorHeader(hexToRgb(value));
                                                }
                                            }}
                                            popover
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.tab.tenantThemeChanger.dark')} key="3">
                            <Row gutter={24}>
                                <Col span={1}>
                                    <Tooltip title={geti18nText('app.default.revertColor')}>
                                        <Button
                                            size="small"
                                            style={{ marginTop: '33px' }}
                                            onClick={() =>
                                                revertColor('BACKGROUND_COLLAPSE_COLOR_DARK', 'rgb(9, 19, 36)')
                                            }
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.TENANT_COLORS.BACKGROUND_COLLAPSE_COLOR'
                                        )}
                                        name="BACKGROUND_COLLAPSE_COLOR_DARK"
                                    >
                                        <NyColorPicker
                                            value={collapseBgColorDark}
                                            defaultColor={collapseBgColorDark}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    setCollapseBgColorDark(hexToRgb(value));
                                                }
                                            }}
                                            popover
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Tooltip title={geti18nText('app.default.revertColor')}>
                                        <Button
                                            size="small"
                                            style={{ marginTop: '33px' }}
                                            onClick={() =>
                                                revertColor('BACKGROUND_COLLAPSE_HEADER_COLOR_DARK', 'rgb(18, 25, 36)')
                                            }
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.TENANT_COLORS.BACKGROUND_COLLAPSE_HEADER_COLOR'
                                        )}
                                        name="BACKGROUND_COLLAPSE_HEADER_COLOR_DARK"
                                    >
                                        <NyColorPicker
                                            value={collapseBgColorHeaderDark}
                                            defaultColor={collapseBgColorHeaderDark}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    setCollapseBgColorHeaderDark(hexToRgb(value));
                                                }
                                            }}
                                            popover
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={activeCollapseKey}
                        onChange={(key: any) => {
                            setActiveCollapseKey(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.tenantThemeChanger.universal')} key="1">
                            <Row gutter={24}>
                                <Col span={1}>
                                    <Tooltip title={geti18nText('app.default.revertColor')}>
                                        <Button
                                            size="small"
                                            style={{ marginTop: '33px' }}
                                            onClick={() => revertColor('PRIMARY_COLOR', 'rgb(24, 144, 255)')}
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_COMMON.TENANT_COLORS.PRIMARY_COLOR'
                                        )}
                                        name="PRIMARY_COLOR"
                                    >
                                        <NyColorPicker
                                            value={primaryColor}
                                            defaultColor={primaryColor}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    setPrimaryColor(hexToRgb(value));
                                                }
                                            }}
                                            popover
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {canCreate() && (
                        <Button
                            style={{
                                marginRight: '1em',
                                backgroundColor: primaryColor ? primaryColor : 'rgb(24, 144, 255)',
                            }}
                            key="submit"
                            type="primary"
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default TenantThemeChanger;
