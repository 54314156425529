import { CheckOutlined, CloseOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { message, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { GetEnum } from '../../../../utils/Enums';
import { downloadFile, getEnumArray, getEnumBooleanArray } from '../../../../utils/Utils';
import ReportSettingsEdit from './ReportSettingsEdit';

const ReportSettingsTable = ({ canCreate, scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const [loading, setLoading] = useState(false);
    const [subreportTypeEnums] = useState(GetEnum({ enumName: 'SUBREPORT_TYPE' }));
    const [pdfVisible, setPdfVisible] = useState(false);
    const [pdfFile, setPdfFile] = useState<any>(undefined);
    const [pdfTitle, setPdfTitle] = useState<any>(undefined);

    const location = useLocation();

    const table = useTableSettings();

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const initialColumns = [
        {
            title: geti18nText('report.setting.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('report.setting.table.column.name'),
            dataIndex: ['name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('report.setting.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.type != undefined) {
                    return geti18nText('app.enum.SUBREPORT_TYPE.' + record.type);
                }
            },
            ...getColumnSearchOption(
                getEnumArray('SUBREPORT_TYPE').filter(
                    (item: any) => item.id !== subreportTypeEnums['HEADER'] && item.id !== subreportTypeEnums['FOOTER']
                ),
                'equals',
                undefined
            ),
        },
        {
            title: geti18nText('report.setting.table.column.defaultReport'),
            dataIndex: 'defaultReport',
            width: '10%',
            render: (text: any, record: { defaultReport: any }) => {
                if (record.defaultReport) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('report.setting.table.column.autoUpdate'),
            dataIndex: 'autoUpdate',
            width: '10%',
            render: (text: any, record: { autoUpdate: any }) => {
                if (record.autoUpdate) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray()),
        },
        {
            title: geti18nText('report.setting.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: setDefaultFilterValue(),
        },
        {
            dataIndex: 'actions',
            width: '10%',
            render: (text: any, record: any) => {
                if (record.id) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Tooltip title={geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_PREVIEW')}>
                                <EyeOutlined
                                    onClick={() => preview(record)}
                                    disabled={loading}
                                    style={{ fontSize: '16px' }}
                                />
                            </Tooltip>
                            <Tooltip title={geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_DOWNLOAD')}>
                                <FilePdfOutlined
                                    style={{ marginLeft: '10px', fontSize: '16px' }}
                                    onClick={() => download(record)}
                                    disabled={loading}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            },
        },
    ];

    const preview = (row: any) => {
        setLoading(true);
        message.loading(geti18nText('import.text.loading'));
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.SUBREPORT.PREVIEW,
            { subreportId: row.id, lang: NyUtils.getSelectedLanguage() },
            false,
            true
        ).then((result: any) => {
            setLoading(false);
            message.destroy();
            if (result.status === RESPONSE.OK && result.data) {
                setPdfFile(window.URL.createObjectURL(result.data.file));
                setPdfTitle(row.name + ' - ' + row.id);
                setPdfVisible(true);
            }
        });
    };

    const download = (row: any) => {
        setLoading(true);
        message.loading(geti18nText('import.text.loading'), 0);
        NyRequestResolver.requestGet(CONSTANTS_REQ.SUBREPORT.DOWNLOAD, { subreportId: row.id }, false, true).then(
            (result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK && result.data) {
                    result.data.filename = `${row.id}_${row.name}.jrxml`;
                    downloadFile(result);
                }
                message.destroy();
            }
        );
    };

    return (
        <>
            {pdfVisible && (
                <Modal
                    centered={true}
                    width={'800px'}
                    open={pdfVisible}
                    title={pdfTitle}
                    onOk={(event: any) => {
                        setPdfVisible(false);
                        setPdfTitle(undefined);
                        setPdfFile(undefined);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={(event: any) => {
                        setPdfVisible(false);
                        setPdfTitle(undefined);
                        setPdfFile(undefined);
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    forceRender={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText={geti18nText('app.default.button.ok')}
                >
                    {pdfFile && (
                        <object
                            style={{ height: '600px', width: '100%' }}
                            data={pdfFile}
                            type={'application/pdf'}
                        ></object>
                    )}
                </Modal>
            )}
            <NyDataTable
                nyId="report-settings-table"
                url={CONSTANTS_REQ.SUBREPORT.LIST}
                addNewButtonText={geti18nText('report.setting.table.add')}
                showRecordModal={true}
                modalComponent={ReportSettingsEdit}
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                hideButtons={!canCreate()}
            />
        </>
    );
};

export default ReportSettingsTable;
