import { geti18nText, NyIsDirtyModal, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Prompt, useLocation, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import _ from 'lodash';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { errorNotification, okNotification } from '../../../../utils/Utils';
import { useDispatch } from 'react-redux';
// import { removeTab, setIsDirtyTab } from '../../../../slices/tabsSlice';
import PageA from './PageA';
import PageB from './PageB';
import useHelper from '../../../../hooks/useHelper';

const { TabPane } = Tabs;

const JoppdEdit = () => {
    const [loading, setLoading] = useState(false);
    const [headerDataDefinition, setHeaderDataDefinition] = useState<any>(undefined);
    const [headerDataDefinitionGrouped, setHeaderDataDefinitionGrouped] = useState<any>(undefined);
    const [detailsDataDefinition, setDetailsDataDefinition] = useState<any>(undefined);
    const [detailsDataDefinitionGrouped, setDetailsDataDefinitionGrouped] = useState<any>(undefined);
    const [headerData, setHeaderData] = useState<any>(undefined);
    const [dirty, setDirty] = useState<any>(false);
    const [showDirtyDataModal, setShowDirtyDataModal] = useState(false);
    const [activeTab, setActiveTab] = useState('page-a');
    const [nexActiveTab, setNextActiveTab] = useState<any>(undefined);
    const [joppdName, setJoppdName] = useState('');
    const [fetching, setFetching] = useState<any>(false);
    const [joppdActive, setJoppdActive] = useState(true);

    const { id } = useParams<any>();

    const dispatch = useDispatch();
    const location = useLocation();

    useHelper('payroll/joppd');

    const setDefaultFilterValue = () => {
        return [];
    };

    useEffect(() => {
        setLoading(true);
        fetch();
    }, [id]);

    // useEffect(() => {
    //     dispatch(setIsDirtyTab({ path: location.pathname, isDirty: dirty }));
    // }, [dirty]);

    const fetch = () => {
        if (id !== 'create') {
            NyRequestResolver.requestGet(CONSTANTS_REQ.JOPPD.XML_DETAILS, {
                id: id,
                order: 'P122',
                orderType: 'desc',
                search: encodeURI(JSON.stringify(setDefaultFilterValue())),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data.headerDataDefinition) {
                            setHeaderDataDefinition(result.data.headerDataDefinition);
                            setHeaderDataDefinitionGrouped(
                                _.groupBy(result.data.headerDataDefinition, 'joppdNodeGroup.sort')
                            );
                        }

                        if (result.data.detailsDataDefinition) {
                            setDetailsDataDefinition(result.data.detailsDataDefinition);
                            setDetailsDataDefinitionGrouped(
                                _.groupBy(result.data.detailsDataDefinition, 'joppdNodeGroup.sort')
                            );
                        }

                        if (result.data.headerData) {
                            setHeaderData(result.data.headerData);
                        }
                    }
                }
                setLoading(false);
            });

            NyRequestResolver.requestGet(CONSTANTS_REQ.JOPPD.EDIT + '/' + id).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data && result.data.name) {
                        setJoppdName(result.data.name);
                        setJoppdActive(result.data.active);
                    }
                }
            });
        } else {
            NyRequestResolver.requestGet(CONSTANTS_REQ.JOPPD.XML_DETAILS_DATA_DEFINITION).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data.headerDataDefinition) {
                            setHeaderDataDefinition(result.data.headerDataDefinition);
                            setHeaderDataDefinitionGrouped(
                                _.groupBy(result.data.headerDataDefinition, 'joppdNodeGroup.sort')
                            );

                            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.JOPPD_INITIAL_DATA).then(
                                (result: any) => {
                                    if (result.status === RESPONSE.OK) {
                                        if (result.data) {
                                            setHeaderData(result.data);
                                        }
                                    }
                                    setLoading(false);
                                }
                            );
                        }
                    }
                }
            });
        }
    };

    const editJoppd = (activate: boolean) => {
        let params: any = {};
        params.id = parseInt(id);
        params.active = activate;
        NyRequestResolver.requestPut(CONSTANTS_REQ.JOPPD.EDIT + '/' + id, undefined, {
            ...params,
        }).then((result: any) => {
            if (setLoading) setLoading(false);
            if (result && result.status === RESPONSE.CREATED) {
                if (setLoading) setLoading(false);
                okNotification();
                // dispatch(removeTab(location.pathname));
            } else {
                if (result && result.status === RESPONSE.BAD_REQUEST) {
                    if (result.data && result.data.field) {
                        switch (result.data.field) {
                            case 'username_exist':
                                errorNotification(geti18nText('app.default.username_exist'));
                                return;
                            case 'mandatory_fields_required':
                                errorNotification(geti18nText('app.default.mandatory_fields_required'));
                                return;
                            default:
                                break;
                        }
                    }
                }
                if (result.data && result.data.error) {
                    if (geti18nText(result.data.error) != '') {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification(JSON.stringify(result.data.error));
                    }
                } else {
                    errorNotification();
                    console.log(result);
                }
            }
        });
    };

    const onTabChange = (activeKey: any) => {
        setNextActiveTab(activeKey);
        if (dirty) {
            setShowDirtyDataModal(true);
        } else {
            setFetching(true);
            fetch();
            setActiveTab(activeKey);
        }
    };

    const onContinue = () => {
        setShowDirtyDataModal(false);
        setDirty(false);
        if (nexActiveTab) {
            setActiveTab(nexActiveTab);
        }
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Tabs type="card" className="tabs-sticky" onChange={onTabChange} activeKey={activeTab} id="joppd-edit-tabs">
                <TabPane
                    tab={
                        <span>
                            {geti18nText('joppd.edit.tab.a')}
                            {dirty ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="page-a"
                >
                    <PageA
                        id={id}
                        headerData={headerData}
                        headerDataDefinition={headerDataDefinition}
                        headerDataDefinitionGrouped={headerDataDefinitionGrouped}
                        setDirty={setDirty}
                        dirty={dirty}
                        deactivateJoppd={editJoppd}
                        joppdName={joppdName}
                        joppdActive={joppdActive}
                        fetching={fetching}
                        setFetching={setFetching}
                        fetch={fetch}
                    />
                </TabPane>

                <TabPane tab={geti18nText('joppd.edit.tab.b')} key="page-b" disabled={id === 'create'}>
                    <PageB
                        id={id}
                        detailsDataDefinition={detailsDataDefinition}
                        detailsDataDefinitionGrouped={detailsDataDefinitionGrouped}
                        joppdActive={joppdActive}
                        deactivateJoppd={editJoppd}
                    />
                </TabPane>
            </Tabs>
            <NyIsDirtyModal
                visible={showDirtyDataModal}
                onContinue={onContinue}
                onCancel={() => setShowDirtyDataModal(false)}
            />
            <Prompt message={''} when={dirty} />
        </React.Fragment>
    );
};

export default JoppdEdit;
