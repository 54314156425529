import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AssetRights } from '../../../../../rights/assetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getEnumArrayStatus,
    getEnumFormat,
    getInventoryAssetCategoriesSelectOption,
    getInventoryAssetRoomsSelectOption,
    getInventoryAssetTypesSelectOption,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import RoomSearch from '../../../../human/views/room/search';
import AssetCategoryIndex from '../asset-category';
import AssetCategoryEdit from '../asset-category/edit';
import AssetInventoryItemIndex from '../asset-inventory-item';
import AssetTypeIndex from '../asset-type';
import AssetTypeEdit from '../asset-type/edit';
import InventoryLink from './InventoryLink';

const { TabPane } = Tabs;

const AssetInventoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('assetInventory.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);
    const [activeKey, setAcitveKey] = useState('1');
    const [isCreate, setIsCreate] = useState(false);
    const [isBooked, setIsBooked] = useState(false);
    const [isDraft, setIsDraft] = useState(true);
    const [businessUnitId, satBusinessUnitId] = useState(undefined);
    const [refresh, setRefresh] = useState<any>(1);

    const inventoryAssetItemStatus = getEnumArrayStatus('INVENTORY_ASSET_ITEM_STATUS');
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return AssetRights.canCreateInventory();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('assetInventory.edit.title') + ' - ' + dataForm.id);
        }

        if (dataForm.dueDate) {
            dataForm.dueDate = setDateFormat(dataForm.dueDate);
        }

        if (dataForm.inventoryAssetStatus) {
            setIsDraft(dataForm.inventoryAssetStatus === -1 ? true : false);
            setIsBooked(dataForm.inventoryAssetStatus === 2 ? true : false);
            if (dataForm.inventoryAssetStatus > 0) {
                dataForm.inventoryAssetStatus = setEnumFormat('INVENTORY_ASSET_STATUS', dataForm.inventoryAssetStatus);
            } else {
                dataForm.inventoryAssetStatus = setEnumFormat('INVENTORY_ASSET_STATUS', 1);
            }
        }

        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? dataForm.businessUnit.name + ' (' + dataForm.businessUnit.code + ')'
                    : dataForm.businessUnit.name,
            };

            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            satBusinessUnitId(dataForm.businessUnit.id);
        }

        if (dataForm.inventoryAssetRoomsList) {
            dataForm.inventoryAssetRoomsList = getInventoryAssetRoomsSelectOption(dataForm.inventoryAssetRoomsList);
        }
        if (dataForm.inventoryAssetCategoryList) {
            dataForm.inventoryAssetCategoryList = getInventoryAssetCategoriesSelectOption(
                dataForm.inventoryAssetCategoryList
            );
        }
        if (dataForm.inventoryAssetTypesList) {
            dataForm.inventoryAssetTypesList = getInventoryAssetTypesSelectOption(dataForm.inventoryAssetTypesList);
        }

        setDataForm(dataForm.id);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setAcitveKey('1');
        setIsBooked(false);
        setIsDraft(true);
        satBusinessUnitId(undefined);
        setEditHeader(geti18nText('assetInventory.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const onChangeTab = (key: string) => {
        setAcitveKey(key);
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                {inventoryAssetItemStatus.map((status: any) => (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <Button
                            block
                            onClick={() => {
                                changeInventoryAssetItemStatus(status.id);
                            }}
                        >
                            {geti18nText('app.enum.INVENTORY_ASSET_ITEM_STATUS.' + status.id)}
                        </Button>
                    </div>
                ))}
            </div>
        </Menu>
    );

    const getCustomFooterContent = canCreate() && (
        <div style={{ float: 'left', marginLeft: '15px' }}>
            <Dropdown key="more" overlay={actionsMenu} trigger={['click']} disabled={isBooked || !hasSelected}>
                <Button type="primary">
                    {geti18nText('app.default.actions')}
                    <EllipsisOutlined />
                </Button>
            </Dropdown>
        </div>
    );

    const getDefaultFooterContent = (
        <div style={{ float: 'right', marginRight: '15px', marginLeft: '5px' }}>
            {props.value && (
                <NyModalConfirm
                    title={geti18nText('app.default.destructive.confirm')}
                    onConfirm={() => {
                        deactivateInventory();
                    }}
                >
                    <Button style={{ display: 'inline' }} danger type="primary">
                        {geti18nText('app.default.button.deactivate')}
                    </Button>
                </NyModalConfirm>
            )}
            {isDraft && (
                <Button
                    style={{ display: 'inline' }}
                    onClick={() => {
                        props.value && props.value.id !== undefined ? updateInventory(-1) : saveInventory(-1);
                    }}
                    type="primary"
                >
                    {geti18nText('app.default.button.save.sketch')}
                </Button>
            )}
            {!isDraft && !isBooked && (
                <Button
                    style={{ display: 'inline' }}
                    onClick={() => {
                        props.value && props.value.id !== undefined ? updateInventory(2) : saveInventory();
                    }}
                    type="primary"
                >
                    {geti18nText('app.default.button.save.booked')}
                </Button>
            )}
            <Button
                style={{ display: 'inline' }}
                onClick={() => {
                    props.value && props.value.id !== undefined ? updateInventory() : saveInventory();
                }}
                type="primary"
            >
                {geti18nText('app.default.button.save')}
            </Button>
        </div>
    );

    const callRefresh = () => {
        props.editProps.setRefreshTable(props.editProps.refreshTable + 1);
    };

    const saveInventory = (status?: Number) => {
        form.validateFields().then((values: any) => {
            NyRequestResolver.requestPost(CONSTANTS_REQ.INVENTORY_ASSET.EDIT, undefined, {
                ...normalize(values, status),
            }).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();

                    props.setVisible(false);
                    callRefresh();
                    onModalClose();
                } else {
                    if (result.data && geti18nText(result.data.error) != '') {
                        errorNotification(result.data.error);
                    } else if (result.data && result.data.error) {
                        errorNotification(
                            `${geti18nText('itemGroup.modal.deleteAttributes.error')}: ${result.data.error}`
                        );
                    } else {
                        errorNotification();
                    }
                }
            });
        });
    };

    const updateInventory = (status?: Number) => {
        form.validateFields().then((values: any) => {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.INVENTORY_ASSET.EDIT + '/' + form.getFieldValue('id'),
                undefined,
                {
                    ...normalize(values, status),
                }
            ).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification();
                    props.setVisible(false);
                    callRefresh();
                    onModalClose();
                } else {
                    if (result.data && geti18nText(result.data.error) != '') {
                        errorNotification(result.data.error);
                    } else if (result.data && result.data.error) {
                        errorNotification(
                            `${geti18nText('itemGroup.modal.deleteAttributes.error')}: ${result.data.error}`
                        );
                    } else {
                        errorNotification();
                    }
                }
            });
        });
    };

    const deactivateInventory = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.INVENTORY_ASSET.EDIT + '/' + form.getFieldValue('id'), undefined, {
            active: false,
            id: form.getFieldValue('id'),
            ...normalize(form.getFieldsValue(true)),
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();

                props.setVisible(false);
                callRefresh();
                onModalClose();
            } else {
                if (result.data && result.data.error) {
                    errorNotification(`${geti18nText('itemGroup.modal.deleteAttributes.error')}: ${result.data.error}`);
                } else {
                    errorNotification();
                }
            }
        });
    };

    const changeInventoryAssetItemStatus = (inventoryAssetItemStatus: any) => {
        Modal.confirm({
            title: geti18nText('assetInventory.change.status.info'),
            icon: <ExclamationCircleOutlined />,
            centered: true,
            okText: geti18nText('app.default.button.yes'),
            cancelText: geti18nText('app.default.button.no'),
            onOk() {
                if (selectedValues.length > 0 && inventoryAssetItemStatus) {
                    let values = { ids: selectedValues, inventoryAssetItemStatus: inventoryAssetItemStatus };
                    NyRequestResolver.requestPost(
                        CONSTANTS_REQ.INVENTORY_ASSET_ITEM.CHANGE_STATUS,
                        undefined,
                        values
                    ).then((result: any) => {
                        if (result && result.status === RESPONSE.OK) {
                            okNotification();
                            setRefresh((refresh: number) => refresh + 1);
                        } else {
                            errorNotification();
                        }
                    });
                }
            },
            onCancel() {},
        });
    };

    const normalize = (data: any, status?: Number) => {
        let values = _.cloneDeep(data);
        if (values.dueDate) values.dueDate = getDateFormat(values.dueDate);
        if (values.businessUnit) values.businessUnit = getSearchFormat(values.businessUnit);
        if (values.inventoryAssetStatus) values.inventoryAssetStatus = getEnumFormat(values.inventoryAssetStatus);
        if (status && values.inventoryAssetStatus) values.inventoryAssetStatus = getEnumFormat(status);
        if (values.inventoryAssetRoomsList != undefined) {
            let inventoryAssetRoomsListEdit: any = [];
            if (values.inventoryAssetRoomsList.length > 0) {
                values.inventoryAssetRoomsList.map((item: any) => {
                    inventoryAssetRoomsListEdit.push({ room: { id: parseInt(item.id) } });
                });
            }
            values.inventoryAssetRoomsList = inventoryAssetRoomsListEdit;
        }
        if (values.inventoryAssetCategoryList != undefined) {
            let inventoryAssetCategoryListEdit: any = [];
            if (values.inventoryAssetCategoryList.length > 0) {
                values.inventoryAssetCategoryList.map((item: any) => {
                    inventoryAssetCategoryListEdit.push({ assetCategory: { id: parseInt(item.id) } });
                });
            }
            values.inventoryAssetCategoryList = inventoryAssetCategoryListEdit;
        }
        if (values.inventoryAssetTypesList != undefined) {
            let inventoryAssetTypesListEdit: any = [];
            if (values.inventoryAssetTypesList.length > 0) {
                values.inventoryAssetTypesList.map((item: any) => {
                    inventoryAssetTypesListEdit.push({ assetType: { id: parseInt(item.id) } });
                });
            }
            values.inventoryAssetTypesList = inventoryAssetTypesListEdit;
        }
        return values;
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.INVENTORY_ASSET.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            setIsCreate={setIsCreate}
            onModalOpen={onModalOpen}
            checkIsFormChanged={false}
            hideSubmitButton={activeKey === '1' || activeKey === '2' || activeKey === '3' || !canCreate()}
            hideActivationButtons={
                activeKey === '1' || activeKey === '2' || activeKey === '3' || isBooked || !canCreate()
            }
            normalize={(values: any) => normalize(values)}
            customFooterContent={activeKey === '2' ? getCustomFooterContent : getDefaultFooterContent}
        >
            <Tabs defaultActiveKey={activeKey} onChange={onChangeTab} type="card">
                <TabPane tab={geti18nText('assetInventory.tab.1')} key="1">
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Col span={12}>
                            <BusinessUnitSearch
                                label={geti18nText('assetInventory.edit.businessUnit')}
                                formItemName={'businessUnit'}
                                disabled={!isDraft}
                                autoFocus={true}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('assetInventory.edit.dueDate')}
                                name="dueDate"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NyDatePicker style={{ width: '100%' }} disabled={isBooked} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={geti18nText('assetInventory.edit.inventoryAssetStatus')}
                                name="inventoryAssetStatus"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                initialValue={setEnumFormat('INVENTORY_ASSET_STATUS', 1)}
                            >
                                <NySearchField
                                    options={getEnumArrayStatus('INVENTORY_ASSET_STATUS')}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    nyTestId="input-status"
                                    style={{ width: '100%' }}
                                    className={''}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('asset.table.column.assetType')}
                                name="inventoryAssetTypesList"
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.ASSET_TYPE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    itemName="name"
                                    AddNewModalComponent={AssetTypeEdit}
                                    SearchPopupComponent={<AssetTypeIndex />}
                                    setDefaultFilterValue={() => [
                                        { field: 'active', condition: 'equals_bool', value: 1 },
                                    ]}
                                    mode={'multiple'}
                                    disabled={!isDraft}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('fixed.asset.edit.assetCategory')}
                                name="inventoryAssetCategoryList"
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.ASSET_CATEGORY.SEARCH}
                                    map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                                    searchBy="name || ' ' || code"
                                    itemName={[['text'], ['code']]}
                                    customItemNameLabel={'text (code)'}
                                    renderOption={(option: any) =>
                                        `${option.text}${option.code ? ' (' + option.code + ')' : ''}`
                                    }
                                    AddNewModalComponent={AssetCategoryEdit}
                                    SearchPopupComponent={<AssetCategoryIndex />}
                                    setDefaultFilterValue={() => [
                                        { field: 'active', condition: 'equals_bool', value: 1 },
                                    ]}
                                    mode={'multiple'}
                                    disabled={!isDraft}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <RoomSearch
                                disabled={!isDraft}
                                label={geti18nText('assetInventory.edit.room')}
                                map={{ id: 'id', label: 'name' }}
                                mode={'multiple'}
                                name="inventoryAssetRoomsList"
                                searchBy="name"
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('app.default.note')} name="note">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('assetInventory.tab.2')} key="2" disabled={isDraft}>
                    <AssetInventoryItemIndex
                        inventoryAssetId={dataForm}
                        isBooked={isBooked}
                        setHasSelected={setHasSelected}
                        setSelectedValues={setSelectedValues}
                        hasSelected={hasSelected}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        scroll={{ y: 450, x: 800 }}
                    />
                </TabPane>
                <TabPane tab={geti18nText('assetInventory.tab.3')} key="3" disabled={true}>
                    <InventoryLink inventoryAssetId={dataForm} businessUnitId={businessUnitId} />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default AssetInventoryEdit;
