import { NyDataEdit, geti18nText } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { onKeyDownTextAreaCustom } from '../../../../utils/Utils';

const AttendanceBreakEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('attendance.break.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [defaultChecked, setDefaultChecked] = useState<boolean>(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('attendance.break.edit.title') + ' - ' + dataForm.name);
        }
        setDefaultChecked(dataForm.defaultType);

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('attendance.break.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const validateLength = (_: any, value: any) => {
        if (value?.length >= 200) {
            return Promise.reject(
                new Error(geti18nText('app.default.text.maxAllowedCharacters').replace('{0}', '200'))
            );
        }
        return Promise.resolve();
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ATTENDANCE_BREAK.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            normalize={(values: any) => {
                values.defaultType = defaultChecked;
                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={21}>
                    <Form.Item
                        label={geti18nText('attendance.break.edit.title')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} disabled={!canCreate()} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label={geti18nText('work.type.edit.default')} name="defaultType">
                        <Checkbox
                            checked={defaultChecked}
                            onChange={(val: any) => {
                                setDefaultChecked(val.target.checked);
                            }}
                            disabled={!canCreate()}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('attendance.break.edit.description')}
                        name="description"
                        rules={[{ validator: validateLength }]}
                    >
                        <Input.TextArea
                            rows={4}
                            onKeyDown={onKeyDownTextAreaCustom}
                            maxLength={200}
                            disabled={!canCreate()}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AttendanceBreakEdit;
