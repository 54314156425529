import { DeleteTwoTone, NotificationTwoTone, PlusCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Card, Col, Empty, Form, Input, List, Modal, Row, Switch, Tag, Tooltip, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { AdministrationRights } from '../../rights/administrationRights';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { errorNotification, getDateFormat, okNotification, onKeyDownTextAreaCustom } from '../../utils/Utils';
const { Title } = Typography;

const PersonalNotesWidget = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<any>(false);
    const [update, setUpdate] = useState<any>(false);
    const [personalNoteDate, setNotificationDate] = useState(undefined);
    const [personalNotes, setPersonalNotes] = useState<any>(undefined);
    const [notesList, setNotesList] = useState<any>(undefined);
    const [viewActiveNotes, setViewActiveNotes] = useState<any>(true);

    useEffect(() => {
        getStatistics();
    }, []);

    useEffect(() => {
        notesList && setPersonalNotes(notesList.filter((note: any) => note.active === viewActiveNotes));
    }, [viewActiveNotes, notesList]);

    const getStatistics = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER_NOTE.LIST, undefined).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.content) {
                    let notes = result.data.content;
                    notes.sort((a: any, b: any) => (a.pined < b.pined ? 1 : -1));
                    setNotesList(notes);
                }
            }
        });
    };

    const hasPersonalNotesEditRole = () => {
        return AdministrationRights.isAdminOrUser();
    };

    const linkGridStyle: any = {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        padding: '5px',
    };

    const savePersonalNote = () => {
        form.validateFields().then((values: any) => {
            if (values.dateTo) values.dateTo = getDateFormat(values.dateTo);
            !update
                ? NyRequestResolver.requestPost(CONSTANTS_REQ.USER_NOTE.EDIT, undefined, values).then((result: any) => {
                      if (result.status === RESPONSE.CREATED) {
                          okNotification();
                          form.resetFields();
                          getStatistics();
                          setVisible(false);
                      } else {
                          errorNotification(result.data.error);
                      }
                  })
                : NyRequestResolver.requestPut(CONSTANTS_REQ.USER_NOTE.EDIT + '/' + values.id, undefined, {
                      id: values.id,
                      instance: values,
                  }).then((result: any) => {
                      setUpdate(false);
                      if (result.status === RESPONSE.CREATED) {
                          okNotification();
                          form.resetFields();
                          getStatistics();
                          setVisible(false);
                      } else {
                          errorNotification(result.data.error);
                      }
                  });
        });
    };

    const updateNotification = (personalNote: any) => {
        form.setFieldsValue(personalNote);
        setNotificationDate(personalNote.note.created && getDateFormat(personalNote.note.created, 'DD.MM.YYYY'));

        setUpdate(true);
        setVisible(true);
    };

    const personalNoteDelete = (personalNote: any) => {
        if (personalNote) {
            personalNote.active = false;
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.USER_NOTE.EDIT + '/' + personalNote.id,
                undefined,

                { id: personalNote.id, instance: personalNote }
            ).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    form.resetFields();
                    getStatistics();
                }
            });
        }
    };

    const personalNotePin = (personalNote: any) => {
        if (personalNote) {
            personalNote.pined = true;
            NyRequestResolver.requestPut(CONSTANTS_REQ.USER_NOTE.EDIT + '/' + personalNote.id, undefined, {
                id: personalNote.id,
                instance: personalNote,
            }).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    form.resetFields();
                    getStatistics();
                }
            });
        }
    };

    const unpinPersonalNote = (personalNote: any) => {
        if (personalNote) {
            personalNote.pined = false;
            NyRequestResolver.requestPut(CONSTANTS_REQ.USER_NOTE.EDIT + '/' + personalNote.id, undefined, {
                id: personalNote.id,
                instance: personalNote,
            }).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    form.resetFields();
                    getStatistics();
                }
            });
        }
    };

    return (
        <>
            <Card
                title={geti18nText('menu.personal.notes')}
                className="ny-widget-card"
                bodyStyle={{ padding: '0px' }}
                type="inner"
                extra={
                    hasPersonalNotesEditRole() && (
                        <>
                            <Switch
                                defaultChecked={true}
                                size={'small'}
                                style={{ marginRight: '12px' }}
                                checkedChildren={geti18nText('personal.notes.active')}
                                unCheckedChildren={geti18nText('personal.notes.inactive')}
                                onChange={() => {
                                    setViewActiveNotes(!viewActiveNotes);
                                }}
                            />
                            <Tooltip placement="top" title={geti18nText('personal.notes.add')}>
                                <PlusCircleOutlined className="ny-card-icon" onClick={() => setVisible(true)} />
                            </Tooltip>
                        </>
                    )
                }
            >
                {(personalNotes == undefined ||
                    (personalNotes != undefined && personalNotes == undefined) ||
                    (personalNotes != undefined && personalNotes != undefined && personalNotes == undefined) ||
                    (personalNotes != undefined &&
                        personalNotes &&
                        personalNotes != undefined &&
                        Object.entries(personalNotes).length < 1)) && <></>}
                <List
                    style={{
                        maxHeight: '325px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        padding: '4px 5px 0px 5px',
                        marginTop: '1px',
                    }}
                    grid={{
                        gutter: 12,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={personalNotes}
                    renderItem={(personalNote: any) => (
                        <List.Item className="ny-personal-notes-widget-list-item">
                            <Card.Grid className="ny-card-link" style={linkGridStyle}>
                                <Meta
                                    style={{ padding: '5px' }}
                                    avatar={<NotificationTwoTone className="ny-personal-notes-widget-avatar" />}
                                    title={
                                        <>
                                            <span
                                                className="ny-personal-notes-widget-footer"
                                                onClick={() => updateNotification(personalNote)}
                                            >
                                                {personalNote.note.created
                                                    ? '  ' + getDateFormat(personalNote.note.created, 'DD.MM.YYYY')
                                                    : ''}
                                                <Button
                                                    className="ny-personal-notes-pushpin-btn"
                                                    type="link"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        personalNote.pined
                                                            ? unpinPersonalNote(personalNote)
                                                            : personalNotePin(personalNote);
                                                    }}
                                                    style={{ paddingRight: '5px', paddingLeft: '0px' }}
                                                >
                                                    {personalNote.pined ? (
                                                        <PushpinOutlined
                                                            rotate={-45}
                                                            className="ny-personal-notes-pushpin"
                                                            style={{
                                                                fontSize: '12px',
                                                                color: '#faad14',
                                                            }}
                                                            twoToneColor="#ff7875"
                                                        />
                                                    ) : (
                                                        <PushpinOutlined
                                                            className="ny-personal-notes-pushpin"
                                                            style={{
                                                                fontSize: '12px',
                                                            }}
                                                            twoToneColor="#ff7875"
                                                        />
                                                    )}
                                                </Button>
                                            </span>
                                            <div onClick={() => updateNotification(personalNote)}>
                                                <span>{personalNote.name ? personalNote.name : ''} </span>
                                            </div>
                                        </>
                                    }
                                    description={
                                        <div>
                                            <div
                                                className="ny-personal-notes-widget-description"
                                                onClick={() => updateNotification(personalNote)}
                                            >
                                                {personalNote.note && personalNote.note.note
                                                    ? personalNote.note.note
                                                    : ''}
                                            </div>
                                            <span>
                                                <>
                                                    <Tooltip
                                                        placement="top"
                                                        title={geti18nText('app.default.button.delete')}
                                                    >
                                                        <NyModalConfirm
                                                            title={geti18nText('app.default.delete.confirm')}
                                                            onConfirm={() => {
                                                                personalNoteDelete(personalNote);
                                                            }}
                                                        >
                                                            <Button
                                                                type="link"
                                                                style={{ paddingRight: '5px', paddingLeft: '0px' }}
                                                            >
                                                                {viewActiveNotes && (
                                                                    <DeleteTwoTone
                                                                        style={{
                                                                            fontSize: '12px',
                                                                        }}
                                                                        twoToneColor="#ff7875"
                                                                    />
                                                                )}
                                                            </Button>
                                                        </NyModalConfirm>
                                                    </Tooltip>
                                                </>
                                            </span>
                                        </div>
                                    }
                                />
                            </Card.Grid>
                        </List.Item>
                    )}
                    className="ny-widget-list"
                    locale={{
                        emptyText: (
                            <div style={{ paddingTop: 10 }}>
                                <Empty
                                    className="ny-dashboard-empty"
                                    imageStyle={{ width: '100%' }}
                                    description={<Title level={5}>{geti18nText('personal.notes.noData')}</Title>}
                                />
                            </div>
                        ),
                    }}
                />
            </Card>
            {visible && (
                <Modal
                    visible={visible}
                    title={update ? geti18nText('personal.notes.update') : geti18nText('personal.notes.now')}
                    okText={geti18nText('personal.notes.publish')}
                    onOk={(event: any) => {
                        savePersonalNote();
                        setNotificationDate(undefined);
                        event.stopPropagation();
                        event.preventDefault();
                        getStatistics();
                    }}
                    onCancel={(event: any) => {
                        setVisible(false);
                        setUpdate(false);
                        setNotificationDate(undefined);
                        form.resetFields();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    maskClosable={false}
                >
                    <Form form={form} layout="vertical" {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                {personalNoteDate && <Tag color="processing">{personalNoteDate}</Tag>}
                                <Form.Item
                                    label={geti18nText('personal.notes.name')}
                                    name={'name'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={'id'} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={'pined'} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('personal.notes.note')}
                                    name={['note', 'note']}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea
                                        autoSize={{ minRows: 10 }}
                                        size="large"
                                        onKeyDown={onKeyDownTextAreaCustom}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}></Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default PersonalNotesWidget;
