import HumanCodebooksIndex from '../../../../components/human-codebooks';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const DemissionTypeIndex = () => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const enCodebookType = useEnum('HUMAN_CODEBOOKS');
    return (
        <HumanCodebooksIndex
            codebooksType={enCodebookType.EMPLOYEE_DEMISSION_TYPE}
            disabled={!canCreate()}
            canExportCSV
        />
    );
};

export default DemissionTypeIndex;
