import {
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    ExclamationCircleFilled,
    FormOutlined,
    MergeCellsOutlined,
    PlusCircleOutlined,
    RetweetOutlined,
    SaveTwoTone,
    SwapOutlined,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import {
    Alert,
    Badge,
    Button,
    Checkbox,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import { AssetRights } from '../../../../../rights/assetRights';
import { RootState } from '../../../../../rootReducer';
import { setModalOpen } from '../../../../../slices/modalOpenSlice';
import { refreshItemList } from '../../../../../slices/selectedItemSlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumArrayStatus,
    getEnumFormat,
    getOrderStatusIcon,
    getSearchFormat,
    okNotification,
    onKeyDownTextAreaCustom,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
    userIsNotificationRecipient,
} from '../../../../../utils/Utils';
import ItemEdit from '../../../../administration/views/item/edit';
import EmployeeIndex from '../../../../human/views/employee';
import RoomSearch from '../../../../human/views/room/search';
import EditableCell from './EditableCell';
import ItemReplaceModal from './ItemReplaceModal';

const AssetOrderEdit = (props: any) => {
    const dispatch = useDispatch();
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const { employee } = useSelector((state: RootState) => state.employee);
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const [editHeader, setEditHeader] = useState(geti18nText('inventoryOrder.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [editingKey, setEditingKey] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [status, setStatus] = useState<any>(undefined);
    const [showDecision, setShowDecision] = useState(true);
    const [itemEditVisible, setItemEditVisible] = useState(false);
    const [itemEditData, setItemEditData] = useState<any>(undefined);
    const [newItemModalVisible, setNewItemVisible] = useState(false);
    const [orderViewId, setOrderViewId] = useState<any>(props?.addedData?.orderViewId);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [rejectInventoryVisible, setRejectInventoryVisible] = useState<any>(false);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [cancelInventoryVisible, setCancelInventoryVisible] = useState<any>(false);
    const [cancelAllInventoryVisible, setCancelAllInventoryVisible] = useState<any>(false);
    const [itemsCanceledRefresh, setItemsCanceledRefresh] = useState<any>(true);
    const [cancelItemsHeader, setCancelItemsHeader] = useState(geti18nText('report.inventoryOrder.remove.title'));
    const [noteId, setNoteId] = useState<any>(false);
    const [itemNoteId, setItemNoteId] = useState<any>(undefined);
    const [noteForm] = Form.useForm();
    const [editable, setEditable] = useState<boolean>(true);
    const [itemStatus, setItemStatus] = useState<any>(false);
    const [itemNoteEditVisible, setItemNoteEditVisible] = useState(false);
    const themeStorage = JSON.parse(localStorage['persist:root']);
    const isDarkTheme = themeStorage != undefined && JSON.parse(themeStorage.generalSettings).theme === 'dark';
    const [priority, setPriority] = useState<boolean>(true);
    const [globalData, setGlobalData] = useState<any>(null);
    const [employeeAsset, setEmployeeAsset] = useState<any>(null);
    const [dateTo, setDateTo] = useState<any>(null);
    const [showAssetColumn, setShowAssetColumn] = useState<boolean>(false);
    const [showDateColumn, setShowDateColumn] = useState<boolean>(false);
    const [room, setRoom] = useState<any>(null);
    const [warehouse, setWarehouse] = useState<any>(null);

    const isEditing = (record: any) => record.id === editingKey;

    const inputDateTo = () => {
        return status && (status === 3 || status === 7);
    };

    const showDescriptions = () => {
        return !isCreate && dataForm;
    };

    useEffect(() => {
        if (dateTo) {
            let newData: any = [...items];
            if (newData?.length > 0) {
                newData.map((item: any) => {
                    if (!item?.dateTo) {
                        item.dateTo = dateTo;
                    }
                    return item;
                });
                setItems(newData);
            }
            if (!assetForm.getFieldValue('dateTo')) {
                assetForm.setFieldValue('dateTo', dateTo);
            }
        }
    }, [dateTo]);

    useEffect(() => {
        if (props.value == undefined && employee && employee.id !== undefined && dataForm == null) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + employee.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data && result.data) {
                            setEmployeeAsset(result.data);
                            form.setFieldsValue({
                                employee: getEmployeeSelectOption(result.data),
                                room: result.data.room,
                            });
                        }
                    }
                }
            );
        }
    }, [props?.value]);

    const canCreateRelease = () => {
        return AssetRights.canCreateRelease();
    };

    const canCreate = () => {
        return AssetRights.canCreateOrder() || userIsNotificationRecipient(employee, notificationType.ASSET_ORDER);
    };

    useEffect(() => {
        getWarehouse(orderViewId);
    }, [orderViewId]);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getWarehouse = (orderViewId: any) => {
        if (orderViewId > 0) {
            setWarehouse(null);
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.WAREHOUSE.LIST_BY_ORDER_VIEW.replace('{orderViewId}', orderViewId?.toString()),
                {
                    search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                }
            ).then((result: any) => {
                if (result?.status === RESPONSE.OK && result?.data?.content?.length > 0 && result?.data.content[0]) {
                    const warehouseValue: any = setSearchFormat(result.data.content[0], 'name', 'name', 'code');
                    setWarehouse(warehouseValue);
                    form.setFieldsValue({ warehouse: warehouseValue });
                }
            });
        }
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.room) {
            setRoom(dataForm.room);
        }

        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setEditHeader(
                geti18nText('menu.inventory.order.single') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
            setCancelItemsHeader(
                geti18nText('report.inventoryOrder.remove.title') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.date).format('YYYY')
            );
        } else if (dataForm.hasOwnProperty('id')) {
            setEditHeader(geti18nText('menu.inventory.order.single') + ' (ID:' + dataForm.id + ')');
        } else {
            setEditHeader(geti18nText('menu.inventory.order.single'));
        }

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.assetOrderItem) {
            setItems(
                dataForm.assetOrderItem.map((item: any) => {
                    const qty = item?.quantity ? item.quantity : 0;
                    const releasedQty = item?.releasedQuantity ? item.releasedQuantity : 0;
                    const releaseQty = qty - releasedQty >= 0 ? qty - releasedQty : 0;
                    if (item?.item?.mandatoryReceipt) {
                        setShowAssetColumn(true);
                    } else {
                        setShowDateColumn(true);
                    }
                    return { ...item, releaseQuantity: releaseQty };
                })
            );
        }

        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }
        setShowDecision(false);
        if (dataForm.status) {
            setStatus(dataForm.status);
            dataForm.status = setEnumFormat('INVENTORY_ORDER_STATUS_TYPE', dataForm.status);
            if (dataForm.status.id == 1 && canCreate()) {
                setShowDecision(true);
            }
        }

        if (dataForm.employee) {
            setEmployeeAsset(dataForm.employee);
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        setGlobalData(dataForm);
        if (dataForm.createdByEmployee) {
            dataForm.createdByEmployee = getEmployeeSelectOption(dataForm.createdByEmployee);
        }
        if (dataForm.orderView && dataForm.orderView.id) {
            setOrderViewId(dataForm.orderView.id);
        }
        if (dataForm.warehouse) {
            setWarehouse(setSearchFormat(dataForm.warehouse, 'name', 'name', 'code'));
        }
        if (dataForm.dateTo) {
            dataForm.dateTo = setDateFormat(dataForm.dateTo);
            setDateTo(dataForm.dateTo);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        noteForm.resetFields();
        assetForm.resetFields();
        setItems([]);
        setEditingKey('');
        setDataForm(null);
        setNote(undefined);
        setEditHeader(geti18nText('inventoryOrder.edit.new'));
        setStatus(undefined);
        setShowDecision(false);
        setOrderViewId(null);
        setSelectedItems([]);
        setGlobalData(null);
        setRoom(null);
        setEmployeeAsset(null);
        setDateTo(null);
        setShowAssetColumn(false);
        setShowDateColumn(false);
        setWarehouse(null);
    };

    const onModalOpen = () => {
        setShowDecision(false);
        setOrderViewId(props && props.addedData && props.addedData.orderViewId ? props.addedData.orderViewId : null);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (record.item == undefined) {
            deleteRow(record.id);
        }
        setAddItemInProcess(false);
        assetForm.resetFields();
    };

    async function getItemData(id: any) {
        if (id) {
            const result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.ITEM.EDIT + '/' + id, undefined);
            if (result.status === RESPONSE.OK && result.data) {
                return result.data;
            }
        }
        return undefined;
    }

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await assetForm.validateFields();
            const newData = [...items];
            const index = newData.findIndex((item) => id === item?.id);
            if (row && row.item) {
                let itemId = row?.item?.name?.id ? row.item.name.id : row.item.id;
                let itemData: any = itemId > 0 ? await getItemData(itemId) : null;
                if (itemData) {
                    row.item = itemData;
                }
            }

            if (index > -1) {
                const item = newData[index];
                if (!row?.item?.id && item?.item?.id && row?.item?.name == item?.item?.name) {
                    row.item = item.item;
                }
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }
            setAddItemInProcess(false);
            assetForm.resetFields();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const modalComponentOnSaveAndGetData = (data: any) => {
        if (data) {
            const newData = [...items];
            const index = newData.findIndex((item) => data.id === item.item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...{ item: data } });
                setItems(newData);
                setItemEditData(undefined);
                setEditingKey('');
            }
        }
    };

    const saveItemNote = () => {
        setItemNoteEditVisible(!itemNoteEditVisible);
        const arr = [...items];
        const index = arr.findIndex((item: any) => item.id == noteId);

        if (noteForm.getFieldValue('itemNote') && noteForm.getFieldValue('itemNote').length > 0) {
            arr[index].note = { id: itemNoteId, note: noteForm.getFieldValue('itemNote'), priority: priority };
        }
        setPriority(true);
        setItems(arr);
        noteForm.resetFields();
    };

    const normalizeItems = (selectedItems: any) => {
        let values: any = {};
        if (dataForm != undefined) {
            values = { id: dataForm };
        }
        let assetOrderItem: any = [];
        if (selectedItems != undefined && selectedItems.length > 0) {
            selectedItems.map((item: any) => {
                if (item.note && item.note.note) {
                    assetOrderItem.push({
                        id: item.id,
                        note: { id: item.note.id, note: item.note.note, priority: item.note.priority },
                    });
                } else {
                    assetOrderItem.push({
                        id: item.id,
                    });
                }
            });
        }
        if (assetOrderItem != undefined && assetOrderItem.length > 0) {
            values.assetOrderItem = assetOrderItem;
        }

        if (note != undefined) {
            values.note = {
                id: form.getFieldValue('note').id,
                note: form.getFieldValue('note').note,
                priority: form.getFieldValue('note').priority,
            };
        } else {
            values.note = { note: form.getFieldValue('note').note, priority: form.getFieldValue('note').priority };
        }

        return values;
    };

    const sendCanceledItems = () => {
        const normalized = normalizeItems(selectedItems);

        NyRequestResolver.requestPut(
            CONSTANTS_REQ.ASSET_ORDER.CANCEL_ITEMS + '/' + dataForm,
            undefined,
            normalized
        ).then((result: any) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                if (result.data && result.data.status) {
                    if (status === undefined || status != result.data.status) {
                        okNotification(
                            geti18nText('app.default.save.ok.desc'),
                            geti18nText('app.default.save.ok.desc') +
                                ' ' +
                                geti18nText('inventoryOrder.edit.status') +
                                ': ' +
                                geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + result.data.status)
                        );
                    }
                } else {
                    okNotification();
                }
                setSelectedItems([]);
                setCancelInventoryVisible(false);
                setLoading(false);
                setItemsCanceledRefresh(!itemsCanceledRefresh);
                if (result.data.status !== status) {
                    props.setVisible(false);
                    setCancelInventoryVisible(false);
                    dispatch(refreshItemList());
                }
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                    setLoading(false);
                    //setSelectedItems([]);
                } else {
                    errorNotification();
                    setLoading(false);
                    //setSelectedItems([]);
                }
            }
        });
    };

    const cancelAllUndelivered = () => {
        let values: any = {};
        if (dataForm != undefined) {
            values = { id: dataForm };
        }
        if (note != undefined) {
            values.note = {
                id: note.id,
                note: form.getFieldValue('note').note,
                priority: form.getFieldValue('note').priority,
            };
        } else {
            values.note = { note: form.getFieldValue('note').note, priority: form.getFieldValue('note').priority };
        }

        NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_ORDER.CANCEL + '/' + dataForm, undefined, values).then(
            (result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    props.setVisible(false);
                    setCancelInventoryVisible(false);
                    setLoading(false);
                    dispatch(refreshItemList());
                } else {
                    if (result.data && result.data.error) {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification();
                    }
                }
            }
        );
    };

    const ActionNote = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'center' }}>
                    <Tooltip title={geti18nText('app.default.note')}>
                        <Badge
                            count={
                                record.note && record.note.priority && record.note.priority == true ? (
                                    <ExclamationCircleFilled className="ny-blink" style={{ color: '#f5222d' }} />
                                ) : (
                                    0
                                )
                            }
                        >
                            <FormOutlined
                                className="item-note"
                                onClick={() => {
                                    editNote(record);
                                    if (record.note) {
                                        noteForm.setFieldsValue({ itemNote: record.note.note });
                                    }
                                    setNoteId(record.id);
                                    setItemNoteId(record?.note?.id ? record.note.id : undefined);
                                    setEditable(!(record.releasedQuantity == record.quantity));
                                    setItemStatus(record.status);
                                    setItemNoteEditVisible(true);
                                }}
                                style={
                                    isDarkTheme
                                        ? {
                                              color: record.note ? 'rgb(64, 169, 255)' : 'rgba(255, 255, 255, 0.85)',
                                              fontSize: '20px',
                                              paddingTop: '5px',
                                          }
                                        : {
                                              color: record.note ? 'rgb(64, 169, 255)' : 'rgb(0, 0, 0)',
                                              fontSize: '20px',
                                              paddingTop: '5px',
                                          }
                                }
                            />
                        </Badge>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="small" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button
                            type="link"
                            onClick={() => {
                                cancel(record);
                            }}
                        >
                            <CloseCircleTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#faad14"
                            />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {(isCreate || !status || status === 2 || inputDateTo()) && (
                    <Space size="small" style={{ textAlign: 'right', gap: 'unset!important' }}>
                        {record &&
                        record.item &&
                        record.item.availabilityStatus &&
                        (record.item.availabilityStatus === 5 || record.item.replaceble) ? (
                            <>
                                <Tooltip placement="top" title={geti18nText('assetOrder.itemReplace.button.fillData')}>
                                    <Button
                                        type="link"
                                        disabled={editingKey !== ''}
                                        onClick={() => {
                                            setItemEditData(record.item);
                                            setItemEditVisible(true);
                                        }}
                                    >
                                        <FormOutlined
                                            style={{
                                                fontSize: '20px',
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title={geti18nText('assetOrder.itemReplace.button.replace')}>
                                    <Button
                                        type="link"
                                        disabled={editingKey !== ''}
                                        onClick={() => {
                                            setItemEditData(record.item);
                                            setNewItemVisible(true);
                                        }}
                                    >
                                        <RetweetOutlined
                                            style={{
                                                fontSize: '20px',
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </>
                        ) : record?.item?.id ? (
                            <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                                <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                                    <EditTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        ) : (
                            <div style={{ width: '44px' }}></div>
                        )}
                        <NyModalConfirm
                            title={geti18nText('app.default.delete.confirm')}
                            onConfirm={() => {
                                deleteRow(record.id);
                            }}
                        >
                            <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                <Button type="link" disabled={editingKey !== ''}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </Tooltip>
                        </NyModalConfirm>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const itemDataColumns: any = [
        ...[
            {
                title: geti18nText('inventoryOrder.edit.orderQuantity'),
                dataIndex: 'quantity',
                width: '140px',
            },
            {
                title: geti18nText('inventoryOrder.edit.releasedQuantity'),
                dataIndex: 'releasedQuantity',
                width: '140px',
            },
            {
                title: geti18nText('inventoryOrder.edit.releaseQuantity'),
                dataIndex: 'releaseQuantity',
                width: '140px',
                editable: true,
                inputType: 'releaseQuantity',
                render: (text: any, record: any) => {
                    if (record != undefined && record.status != undefined && record.status === 8) {
                        return 0;
                    } else {
                        return record?.releaseQuantity ? record.releaseQuantity : 0;
                    }
                },
            },
            ...(showAssetColumn
                ? [
                      {
                          title: geti18nText('assetRelease.table.column.asset'),
                          dataIndex: 'asset',
                          editable: true,
                          inputType: 'asset',
                          width: '270px',
                          render: (text: any, record: any) => {
                              if (record?.asset?.name?.name?.props?.children) {
                                  return (
                                      (record.asset.name.name.props.children[0] &&
                                          record.asset.name.name.props.children[0]?.props?.children) ??
                                      ''
                                  );
                              } else if (record?.asset?.label) {
                                  return record.asset.label;
                              } else if (record?.asset?.name) {
                                  return record.asset.name;
                              } else if (!isEditing(record) && record?.item?.mandatoryReceipt) {
                                  return (
                                      <MergeCellsOutlined
                                          style={{
                                              fontSize: '20px',
                                          }}
                                      />
                                  );
                              }
                          },
                      },
                  ]
                : []),
            ...(showDateColumn
                ? [
                      {
                          title: geti18nText('assetRelease.table.column.dateTo'),
                          dataIndex: 'dateTo',
                          editable: true,
                          inputType: 'dateTo',
                          width: '160px',
                          render: (text: any, record: any) => {
                              if (text) {
                                  return getDateFormat(text, 'DD.MM.YYYY');
                              }
                          },
                      },
                  ]
                : []),
        ],
    ];

    const columns: any = [
        ...[
            {
                title: geti18nText('inventoryOrder.edit.code'),
                dataIndex: ['item', 'code'],
                editable: false,
                width: '120px',
            },
            {
                title: geti18nText('inventoryOrder.edit.status'),
                dataIndex: ['status'],
                editable: false,
                width: '130px',
                render: (text: any, record: any) => {
                    if (record != undefined && record.status != undefined && record.status === 8) {
                        return <Tag color={'red'}>{geti18nText('inventoryOrder.edit.status.canceled')}</Tag>;
                    }
                },
            },
            {
                title: geti18nText('inventoryOrder.edit.name'),
                dataIndex: ['item', 'name'],
                editable: !status,
                inputType: 'item',
            },
            {
                title: geti18nText('inventoryOrder.edit.measureUnit'),
                dataIndex: ['item', 'measureUnit', 'abbreviation'],
                editable: false,
                width: '100px',
            },
        ],
        ...(inputDateTo()
            ? itemDataColumns
            : [
                  {
                      title: geti18nText('inventoryOrder.edit.quantity'),
                      dataIndex: 'quantity',
                      editable: !status,
                      inputType: 'quantity',
                      width: '140px',
                  },
              ]),
        ...(!status || status === 2 || status === 3 || status === 7
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '150px',
                      render: (text: any, record: any) => {
                          if (
                              items.length >= 1 &&
                              record.status != 8 &&
                              (record.quantity != record.releasedQuantity || record.quantity == 0)
                          ) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
        ...(status !== undefined && status !== 1
            ? [
                  {
                      title: geti18nText('inventoryOrder.table.column.itemNote'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1 && record.status != 8) {
                              return <ActionNote text={text} record={record} />;
                          }
                      },
                  },
              ]
            : []),
    ];

    const removedItemColumns: any = [
        ...[
            {
                title: geti18nText('inventoryOrder.edit.code'),
                dataIndex: ['item', 'code'],
                editable: false,
                width: '120px',
            },
            {
                title: geti18nText('inventoryOrder.edit.name'),
                dataIndex: 'item',
                editable: !status,
                inputType: 'item',
                render: (text: any, record: any) => {
                    if (record.item != undefined && record.item.name != undefined) {
                        return record.item.name;
                    }
                },
            },
            {
                title: geti18nText('inventoryOrder.edit.measureUnit'),
                dataIndex: ['item', 'measureUnit', 'abbreviation'],
                editable: false,
                width: '100px',
            },
        ],
        ...(status === 3 || status === 7
            ? [
                  {
                      title: geti18nText('inventoryOrder.edit.orderQuantity'),
                      dataIndex: 'quantity',
                      width: '140px',
                      render: (text: any, record: any) => {
                          if (record != undefined && record.status != undefined && record.status === 8) {
                              return 0;
                          } else {
                              return record.quantity ? record.quantity : 0;
                          }
                      },
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releasedQuantity'),
                      dataIndex: 'releasedQuantity',
                      width: '140px',
                  },
                  {
                      title: geti18nText('inventoryOrder.edit.releaseQuantity'),
                      dataIndex: 'releaseQuantity',
                      width: '140px',
                      editable: true,
                      inputType: 'releaseQuantity',
                  },
                  {
                      title: geti18nText('inventoryOrder.table.column.itemNote'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1 && record.status != 8) {
                              return <ActionNote text={text} record={record} />;
                          }
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('inventoryOrder.edit.quantity'),
                      dataIndex: 'quantity',
                      width: '250px',
                      editable: !status,
                      inputType: 'quantity',
                  },
                  {
                      title: geti18nText('inventoryOrder.table.column.itemNote'),
                      key: 'action',
                      width: '100px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1 && record.status != 8) {
                              return <ActionNote text={text} record={record} />;
                          }
                      },
                  },
              ]),
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                let qty = record.quantity ? record.quantity : 0;
                let releasedQty = record.releasedQuantity ? record.releasedQuantity : 0;
                let maxQuantity = qty - releasedQty;
                let isMultipleAsset: boolean =
                    col?.dataIndex == 'asset' && record?.item?.mandatoryReceipt && maxQuantity > 0;
                let canEditRecord: boolean = isEditing(record);
                if (col?.dataIndex == 'asset') {
                    canEditRecord = isEditing(record) && record?.item?.mandatoryReceipt;
                } else if (col?.dataIndex == 'dateTo') {
                    canEditRecord = isEditing(record) && !record?.item?.mandatoryReceipt;
                }
                return {
                    record,
                    inputType: isMultipleAsset ? 'assetList' : col.inputType,
                    dataIndex: isMultipleAsset ? 'assetList' : col.dataIndex,
                    title: col.title,
                    editing: canEditRecord,
                    maxQuantity: maxQuantity,
                    form: assetForm,
                    mandatoryReceipt: record?.item?.mandatoryReceipt,
                    dateTo: dateTo,
                    warehouse: warehouse,
                };
            },
        };
    });

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };
        assetForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const editNote = (record: any) => {
        let editForm = undefined;
        editForm = { ...record.item.note };
        noteForm.setFieldsValue(editForm);
    };

    const addNewRowAsset = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        assetForm.resetFields();
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            item: undefined,
            quantity: 0,
            releasedQuantity: 0,
            releaseQuantity: 0,
            dateTo: dateTo,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const pushItems = (
        itemsBasket: any,
        items: any,
        stop: any,
        record: any,
        isOrder: boolean = false,
        showError: boolean = true
    ) => {
        if (items != undefined) {
            items.map((value: any) => {
                if (value.releaseQuantity > 0 || record?.id) {
                    if (value?.assetList?.length > 0 && !isOrder) {
                        pushItems(itemsBasket, value.assetList, stop, value);
                    } else {
                        let obj: any = {};
                        obj.item = record?.id && record?.item ? record.item : value.item;
                        if (isOrder) {
                            obj.quantity = value.quantity;
                            obj.releaseQuantity = value.releaseQuantity;
                            obj.releasedQuantity =
                                obj.quantity -
                                obj.releaseQuantity -
                                (value?.releasedQuantity ? value.releasedQuantity : 0);
                        } else {
                            obj.quantity = record?.id ? 1 : value.releaseQuantity;
                        }
                        if (record?.id) {
                            obj.asset = { id: value.asset.id };
                            if (value.dateTo) {
                                obj.dateTo = getDateFormat(value.dateTo);
                            }
                        } else {
                            if (value?.item?.mandatoryReceipt) {
                                if (!value.asset && !isOrder && showError) {
                                    errorNotification(geti18nText('assetRelease.save.noAsset'));
                                    stop = true;
                                } else if (value?.asset?.id) {
                                    obj.asset = { id: value.asset.id };
                                }
                            }
                            if (value.dateTo) {
                                obj.dateTo = getDateFormat(value.dateTo);
                            }
                        }

                        if (!isOrder) {
                            let assetOrderItemTemp = record?.id ? record : value;
                            const prevReleased = assetOrderItemTemp.releasedQuantity ?? 0;
                            assetOrderItemTemp.releasedQuantity = assetOrderItemTemp.releaseQuantity;
                            assetOrderItemTemp.releaseQuantity =
                                assetOrderItemTemp.quantity - assetOrderItemTemp.releasedQuantity - prevReleased;
                            delete assetOrderItemTemp.assetList;
                            obj.assetOrderItem = assetOrderItemTemp;
                        } else if (dataForm) {
                            obj.assetOrder = { id: dataForm };
                            obj.id = value.id;
                        }
                        delete obj.assetList;
                        itemsBasket.push(obj);
                    }
                }
            });
        }
    };

    const getNoAssetCount = () => {
        const noAssetSelected: any =
            items?.length > 0
                ? items.map((value: any) => {
                      if (value?.assetList?.length > 0) {
                          value.assetList.map((assetValue: any) => {
                              if (value?.item?.mandatoryReceipt && !assetValue.asset) {
                                  return true;
                              }
                          });
                      } else if (value?.item?.mandatoryReceipt && value.releaseQuantity > 0 && !value.asset) {
                          return true;
                      }
                  })
                : [];
        const noAssetCount =
            noAssetSelected?.length > 0 ? noAssetSelected.filter((value: any) => value === true)?.length > 0 : false;
        return noAssetCount;
    };

    const createAssetRelease = () => {
        setLoading(true);
        form.validateFields()
            .then(async (values) => {
                let assetReleaseData: any = {};
                assetReleaseData['employee'] = values?.employee ?? globalData?.employee;
                assetReleaseData['room'] = values?.room?.id ? values.room : room;
                assetReleaseData['warehouse'] = warehouse;
                assetReleaseData['dateRelease'] = getDateFormat(moment());
                assetReleaseData['assetOrder'] = { id: values.id };
                if (values.dateTo) {
                    assetReleaseData['dateTo'] = getDateFormat(values.dateTo);
                }

                if (orderViewId != null) {
                    assetReleaseData['orderView'] = { id: orderViewId };
                }
                const noAssetSelected = await getNoAssetCount();
                if (!noAssetSelected) {
                    let stop = false;
                    const itemsBasket: any = [];
                    await pushItems(itemsBasket, items, stop, null);
                    assetReleaseData['assetReleaseItem'] = itemsBasket;

                    if (!stop && !noAssetSelected) {
                        NyRequestResolver.requestPost(
                            CONSTANTS_REQ.ASSET_RELEASE.EDIT,
                            undefined,
                            assetReleaseData
                        ).then((result: any) => {
                            if (result.status === RESPONSE.CREATED) {
                                okNotification(
                                    geti18nText('app.default.order.save.ok'),
                                    <>
                                        {geti18nText('assetRelease.edit.title')}:
                                        <Button type="link" onClick={() => openAssetRelease(result.data.id)}>
                                            <span style={{ textDecoration: 'underline' }}>
                                                {result.data.ord}/{moment(result.data.date).format('YYYY')}
                                            </span>
                                        </Button>
                                    </>,
                                    15
                                );
                                props.setVisible(false);
                                dispatch(refreshItemList());
                                setLoading(false);
                            } else {
                                if (result.data && result.data.error) {
                                    errorNotification(result.data.error);
                                } else {
                                    errorNotification();
                                }
                                setLoading(false);
                            }
                        });
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                    errorNotification(geti18nText('assetRelease.save.noAsset'));
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    };

    const openAssetRelease = (id: any) => {
        if (id) {
            history.push('/webshop/webshop-asset/asset-release');
            dispatch(
                setModalOpen({
                    modal: 'assetReleaseEdit',
                    visible: true,
                    item: id,
                })
            );
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px', float: 'left' }}>
                <NyNoteModal disabled={status >= 3} note={note} />
            </div>
            {!isCreate && (
                <div style={{ float: 'left', marginLeft: '50px' }}>
                    <NyReportButton
                        url={CONSTANTS_REQ.REPORT.ASSET_ORDER}
                        customParms={{ assetOrderId: Number(dataForm) }}
                        subreportType={'ASSET_ORDER'}
                        buttoni18nText={'report.assetOrder.print'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_ORDER)}
                        type={'button'}
                        allowExportTypeChange={true}
                    />
                </div>
            )}
            {!isCreate && (status === 3 || status === 7) && (
                <div style={{ float: 'left', marginLeft: '15px' }}>
                    <Button
                        disabled={selectedItems?.length < 1 || !canCreateRelease()}
                        onClick={() => {
                            setCancelInventoryVisible(!cancelInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                    >
                        {geti18nText('report.inventoryOrder.remove')}
                    </Button>
                </div>
            )}
            {!isCreate && status === 3 && (
                <div style={{ float: 'left', marginLeft: '15px' }}>
                    <Button
                        disabled={!canCreateRelease()}
                        onClick={() => {
                            setCancelAllInventoryVisible(!cancelAllInventoryVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                    >
                        {geti18nText('report.inventoryOrder.removeAll')}
                    </Button>
                </div>
            )}
            {!isCreate && inputDateTo() && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('inventoryOrder.popconfirm.createInventoryRelease')}
                        onConfirm={() => {
                            createAssetRelease();
                        }}
                    >
                        <Button
                            loading={loading}
                            icon={<SwapOutlined />}
                            type="primary"
                            disabled={!(items.length > 0) || editingKey !== '' || !canCreateRelease()}
                        >
                            {geti18nText('inventoryOrder.createInventoryRelease')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
            {showDecision == true && canCreate() && (
                <div style={{ float: 'right' }}>
                    <Button
                        key="submit"
                        style={{ color: 'white', backgroundColor: 'red' }}
                        onClick={() => {
                            setRejectInventoryVisible(!rejectInventoryVisible);
                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        }}
                    >
                        {geti18nText('inventoryOrder.button.reject')}
                    </Button>
                    <NyModalConfirm
                        title={geti18nText('inventoryOrder.button.popconfirm.approve')}
                        onConfirm={() => {
                            saveDecision(3, null);
                        }}
                    >
                        <Button key="submit" style={{ color: 'white', backgroundColor: 'green' }}>
                            {geti18nText('inventoryOrder.button.approve')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const saveDecision = (selectedDecision: any, info: any) => {
        setLoading(true);
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.ASSET_ORDER.CHANGE_STATUS + '/' + form.getFieldValue('id'),
            undefined,
            {
                id: form.getFieldValue('id'),
                status: selectedDecision,
                info: info,
            }
        )
            .then((result: any) => {
                setLoading(false);
                if (result.status === RESPONSE.OK) {
                    okNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 15);
                    props.setVisible(false);
                    dispatch(refreshItemList());
                } else if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else if (result.data && result.data instanceof String) {
                    errorNotification(result.data);
                } else {
                    errorNotification();
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onEmployeeChange = (value: any) => {
        form.setFieldsValue({ room: undefined });
        if (value.id !== -1) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result?.status === RESPONSE.OK && result?.data) {
                        form.setFieldsValue({ room: result?.data?.room ? result.data.room : undefined });
                    }
                }
            );
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedItems(selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled:
                record.status === 8 ||
                (record.quantity ? record.quantity : 0) === (record.releasedQuantity ? record.releasedQuantity : 0),
        }),
    };

    const getDescriptions = () => (
        <>
            <Descriptions column={1} bordered size={'small'} labelStyle={{ width: '250px' }}>
                <Descriptions.Item label={geti18nText('inventoryOrder.edit.date')}>
                    <span>{globalData?.date && moment(globalData?.date).format('DD.MM.YYYY HH:mm')}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('inventoryOrder.edit.employee')}>
                    <span>
                        {employeeAsset?.person?.firstName} {employeeAsset?.person?.lastName}{' '}
                        {employeeAsset?.employmentRecordId > 0 ? ' (' + employeeAsset.employmentRecordId + ')' : ''}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('inventoryOrder.edit.room')}>
                    <span>{globalData?.room?.name}</span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('inventoryOrder.edit.createdBy')}>
                    <span>
                        {globalData?.createdBy?.person?.firstName} {globalData?.createdBy?.person?.lastName}{' '}
                        {globalData?.createdBy?.employmentRecordId > 0
                            ? ' (' + globalData?.createdBy.employmentRecordId + ')'
                            : ''}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label={geti18nText('inventoryRelease.edit.warehouse')}>
                    <span>{warehouse?.name ? warehouse.name : ''}</span>
                </Descriptions.Item>
            </Descriptions>
        </>
    );

    const getEnabledFields = () => (
        <>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="createdBy" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="ord" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="date" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Row gutter={24}>
                {!(status >= 3 || showDecision || !dataForm) && (
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.status')}
                            name="status"
                            rules={[
                                {
                                    required: status,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            initialValue={!dataForm && setEnumFormat('INVENTORY_ORDER_STATUS_TYPE', 1)}
                        >
                            <NySearchField
                                options={getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE').map((s: any) => {
                                    if (!status) {
                                        return s.id > 1 ? { ...s, disabled: true } : s;
                                    } else if (status === 1) {
                                        return s.id !== 2 && s.id !== 3 ? { ...s, disabled: true } : s;
                                    } else if (status === 2) {
                                        return s.id !== 1 ? { ...s, disabled: true } : s;
                                    } else {
                                        return s;
                                    }
                                })}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                style={{ width: '100%' }}
                                className={''}
                                disabled={status >= 3 || showDecision || !dataForm}
                            />
                        </Form.Item>
                    </Col>
                )}
                {inputDateTo() && (
                    <Col span={12}>
                        <Form.Item label={geti18nText('assetRelease.edit.dateTo')} name="dateTo">
                            <NyDatePicker
                                disabled={isCreate}
                                style={{ width: '100%' }}
                                onChange={(value: any) => {
                                    setDateTo(value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </>
    );

    const getForm = () => (
        <>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="createdBy" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="ord" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name="date" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Row gutter={24}>
                <>
                    {(!dataForm || isCreate) && (
                        <Col span={6}>
                            <Descriptions column={1} bordered size={'small'} labelStyle={{ width: '250px' }}>
                                <Descriptions.Item label={geti18nText('inventoryOrder.edit.status')}>
                                    <span>{getOrderStatusIcon(globalData?.status?.id)}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('inventoryOrder.edit.createdBy')}>
                                    <span>
                                        {employee?.person?.firstName} {employee?.person?.lastName}{' '}
                                        {employee?.employmentRecordId > 0
                                            ? ' (' + employee.employmentRecordId + ')'
                                            : ''}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('inventoryRelease.edit.warehouse')}>
                                    <span>{warehouse?.name ? warehouse.name : ''}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    )}
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.date')}
                            name="date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NyDatePicker
                                disabled={!isCreate}
                                ref={focusInput}
                                style={{ width: '100%' }}
                                format={'DD.MM.YYYY HH:mm'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={geti18nText('inventoryOrder.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name"
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="asset-order-edit" />}
                                disabled={!isCreate}
                                onChange={onEmployeeChange}
                                order="person.last_name, person.first_name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <RoomSearch
                            disabled={!isCreate}
                            label={geti18nText('inventoryOrder.edit.room')}
                            map={{ id: 'id', label: 'name' }}
                            name="room"
                            required={true}
                            searchBy="name"
                        />
                    </Col>
                </>
            </Row>
        </>
    );

    const getItems = () => (
        <>
            <Divider>{geti18nText('inventoryOrder.divider.1')}</Divider>
            {(isCreate || !status) && (
                <Row gutter={24} style={{ height: '36px' }}>
                    <Col span={24}>
                        <Button
                            style={{ float: 'right', marginRight: '12px' }}
                            icon={<PlusCircleOutlined />}
                            onClick={addNewRowAsset}
                            disabled={addItemInProcess}
                        >
                            {geti18nText('inventoryOrder.button.addItem')}
                        </Button>
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={assetForm} layout="vertical">
                        <Table
                            rowKey={'id'}
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            size={'small'}
                            dataSource={items}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            rowSelection={
                                status === 3 || status === 7
                                    ? {
                                          type: 'checkbox',
                                          ...rowSelection,
                                      }
                                    : undefined
                            }
                            pagination={false}
                            sticky
                            scroll={{ y: 400, x: 1700 }}
                        />
                    </Form>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            <NyDataEdit
                fetchWhenChange={itemsCanceledRefresh}
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={
                    <>
                        {editHeader}
                        {dataForm && (
                            <span style={{ marginLeft: '10px' }}>{getOrderStatusIcon(globalData?.status?.id)}</span>
                        )}
                    </>
                }
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.ASSET_ORDER.EDIT}
                setValues={setValues}
                width={1900}
                hideActivationButtons={(!isCreate || !canCreate() || showDecision) && status != undefined}
                hideSubmitButton={
                    !canCreate() || status >= 3 || !(items?.length > 0) || editingKey !== '' || showDecision
                }
                form={form}
                setIsCreate={setIsCreate}
                goBack={() => history.goBack()}
                paramsId={id}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={false}
                customFooterContent={canCreate() ? getCustomFooterContent : undefined}
                normalize={(values: any) => {
                    values.warehouse = warehouse ? getSearchFormat(warehouse) : null;
                    values.employee = getSearchFormat(values?.employee ?? globalData?.employee);
                    values.date = getDateFormat(values.date);
                    values.dateTo = getDateFormat(values.dateTo);
                    if (orderViewId != null) {
                        values.orderView = { id: orderViewId };
                    }
                    if (values?.status?.id > 0) {
                        values.status = getEnumFormat(values.status);
                    } else if (globalData?.status?.id > 0) {
                        values.status = getEnumFormat(globalData.status);
                    } else {
                        delete values.status;
                    }

                    if ((values?.id || dataForm) && values?.status >= 0) {
                        const itemsOrder: any = [];
                        pushItems(itemsOrder, items, false, null, true, false);
                        values.assetOrderItem = itemsOrder;
                    } else {
                        const itemsBasket: any = [];
                        if (items != undefined) {
                            items.map((value: any) => {
                                let obj: any = {};
                                obj.item = { id: value.item.id };
                                obj.quantity = value.quantity;
                                obj.releaseQuantity = value.quantity;
                                itemsBasket.push(obj);
                            });
                        }
                        values.assetOrderItem = itemsBasket;
                    }
                    if (!values.room && room) {
                        values.room = room;
                    }

                    return values;
                }}
            >
                <>
                    {showDescriptions() ? (
                        <Row gutter={24}>
                            <Col span={12}>{getDescriptions()}</Col>
                            <Col span={12}>{getEnabledFields()}</Col>
                        </Row>
                    ) : (
                        getForm()
                    )}
                    {getItems()}
                </>
            </NyDataEdit>
            {React.createElement(
                ItemEdit,
                {
                    isModal: true,
                    visible: itemEditVisible,
                    setVisible: setItemEditVisible,
                    value: itemEditData,
                    onSaveAndGetData: modalComponentOnSaveAndGetData,
                    addedData: { type: 4, itemGroupType: 1 },
                },
                null
            )}
            <ItemReplaceModal
                orderId={dataForm}
                itemEditData={itemEditData}
                setItemEditData={setItemEditData}
                newItemModalVisible={newItemModalVisible}
                setNewItemVisible={setNewItemVisible}
                setItems={setItems}
                setEditingKey={setEditingKey}
                type={4}
            />
            {itemNoteEditVisible && (
                <Modal
                    visible={itemNoteEditVisible}
                    onCancel={() => {
                        setItemNoteEditVisible(!itemNoteEditVisible);

                        setNote({
                            id: form.getFieldValue('note').id,
                            note: form.getFieldValue('note').note,
                            priority: form.getFieldValue('note').priority,
                        });
                        noteForm.resetFields();
                    }}
                    zIndex={999999}
                    width={520}
                    title={geti18nText('inventoryOrder.table.column.itemNote')}
                    okText={geti18nText('app.default.button.ok')}
                    okButtonProps={{ hidden: !editable || itemStatus === 8 }}
                    onOk={() => {
                        if (!editable || !(itemStatus === undefined || itemStatus === 3 || itemStatus === 7)) {
                            setItemNoteEditVisible(!itemNoteEditVisible);

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                            noteForm.resetFields();
                        } else {
                            saveItemNote();
                        }
                    }}
                >
                    <Row gutter={24}>
                        <Col offset={1} span={22}>
                            <Form.Item name={'priority'} initialValue={false} style={{ marginBottom: '0px' }}>
                                <Checkbox
                                    defaultChecked={true}
                                    disabled={
                                        !editable || !(itemStatus === undefined || itemStatus === 3 || itemStatus === 7)
                                    }
                                    onChange={(event: any) => {
                                        setPriority(event.target.checked);
                                    }}
                                    checked={priority}
                                >
                                    {geti18nText('app.default.note.priority')}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col offset={1} span={22}>
                            <div style={{ marginBottom: '4px' }}>{geti18nText('app.default.note')}</div>
                            <Form form={noteForm} layout="vertical">
                                <Form.Item name={'itemNote'}>
                                    <TextArea
                                        style={{
                                            resize: 'none',
                                        }}
                                        disabled={
                                            !editable ||
                                            !(itemStatus === undefined || itemStatus === 3 || itemStatus === 7)
                                        }
                                        id={noteId}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
            {cancelInventoryVisible && (
                <Modal
                    visible={cancelInventoryVisible}
                    onCancel={() => {
                        setCancelInventoryVisible(!cancelInventoryVisible);

                        setNote({
                            id: form.getFieldValue('note').id,
                            note: form.getFieldValue('note').note,
                            priority: form.getFieldValue('note').priority,
                        });
                    }}
                    width={1200}
                    title={cancelItemsHeader}
                    okType="primary"
                    okButtonProps={{ danger: true }}
                    okText={geti18nText('report.inventoryOrder.remove.send')}
                    onOk={() => {
                        form.validateFields().then((values) => {
                            sendCanceledItems();

                            setNote({
                                id: form.getFieldValue('note').id,
                                note: form.getFieldValue('note').note,
                                priority: form.getFieldValue('note').priority,
                            });
                        });
                    }}
                >
                    <Alert
                        style={{ marginBottom: '8px' }}
                        message={<b>{geti18nText('report.inventoryOrder.remove.info')}</b>}
                        type="info"
                        showIcon
                    />

                    <Form form={form} layout="vertical">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form form={assetForm} layout="vertical">
                                    <Table
                                        rowKey={'id'}
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        size={'small'}
                                        dataSource={selectedItems}
                                        columns={removedItemColumns}
                                        rowClassName="editable-row"
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={['note', 'note']}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
            {cancelAllInventoryVisible && (
                <Modal
                    visible={cancelAllInventoryVisible}
                    onCancel={() => {
                        setCancelAllInventoryVisible(!cancelAllInventoryVisible);

                        setNote({
                            id: form.getFieldValue('note').id,
                            note: form.getFieldValue('note').note,
                            priority: form.getFieldValue('note').priority,
                        });
                    }}
                    title={geti18nText('inventoryOrder.cancel.title')}
                    footer={[
                        <>
                            <Button key="back" onClick={() => setCancelAllInventoryVisible(!cancelAllInventoryVisible)}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <NyModalConfirm
                                title={geti18nText('report.inventoryOrder.removeAll.comfirm')}
                                onConfirm={() => {
                                    form.validateFields()
                                        .then((values) => {
                                            cancelAllUndelivered();
                                            setNote({
                                                id: form.getFieldValue('note').id,
                                                note: form.getFieldValue('note').note,
                                                priority: form.getFieldValue('note').priority,
                                            });
                                            setCancelAllInventoryVisible(!cancelAllInventoryVisible);
                                        })
                                        .catch(() => {
                                            errorNotification(geti18nText('report.inventoryOrder.note.required'));
                                        });
                                }}
                            >
                                <Button key="submit" type="primary" danger>
                                    {geti18nText('report.inventoryOrder.removeAll')}
                                </Button>
                            </NyModalConfirm>
                        </>,
                    ]}
                >
                    <Alert
                        style={{ marginBottom: '8px' }}
                        message={<b>{geti18nText('inventoryOrder.cancel.reject.order.info')}</b>}
                        type="info"
                        showIcon
                    />
                    <Form form={form} layout="vertical">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={['note', 'note']}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
            {rejectInventoryVisible && (
                <Modal
                    visible={rejectInventoryVisible}
                    onCancel={() => {
                        setRejectInventoryVisible(!rejectInventoryVisible);

                        setNote({
                            id: form.getFieldValue('note').id,
                            note: form.getFieldValue('note').note,
                            priority: form.getFieldValue('note').priority,
                        });
                    }}
                    title={geti18nText('inventoryOrder.reject.title')}
                    footer={[
                        <>
                            <Button key="back" onClick={() => setRejectInventoryVisible(!rejectInventoryVisible)}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <NyModalConfirm
                                title={geti18nText('inventoryOrder.button.popconfirm.reject')}
                                onConfirm={() => {
                                    form.validateFields()
                                        .then((values) => {
                                            saveDecision(2, form.getFieldValue('note').note ?? '');
                                            setNote({
                                                id: form.getFieldValue('note').id,
                                                note: form.getFieldValue('note').note,
                                                priority: form.getFieldValue('note').priority,
                                            });
                                            setRejectInventoryVisible(!rejectInventoryVisible);
                                        })
                                        .catch(() => {
                                            errorNotification(geti18nText('report.inventoryOrder.note.required'));
                                        });
                                }}
                            >
                                <Button key="submit" style={{ color: 'white', backgroundColor: 'red' }}>
                                    {geti18nText('inventoryOrder.button.reject')}
                                </Button>
                            </NyModalConfirm>
                        </>,
                    ]}
                >
                    <Alert
                        style={{ marginBottom: '8px' }}
                        message={<b>{geti18nText('inventoryOrder.reject.order.info')}</b>}
                        type="info"
                        showIcon
                    />
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form form={form} layout="vertical">
                                <Form.Item name={['note', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('travelWarrantWizard.table.column.note')}
                                    name={['note', 'note']}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} onKeyDown={onKeyDownTextAreaCustom} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default AssetOrderEdit;
