import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TravelExpenseIndex from '../../views/travel-warrant/views/travel-expense';
import TravelExpenseEdit from '../../views/travel-warrant/views/travel-expense/edit';
import { customRenderTextOption } from '../../utils/Utils';

const TravelWarrantExpensesSearch = ({
    travelExpenseOnChange,
    formName,
    mustGetPopupContainer = true,
    disabled = false,
    label = geti18nText('travelWarrantExpense.edit.travelExpenseName'),
    required = true,
    showAllColumns = true,
    tooltip = undefined,
}: any) => {
    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={formName ? formName : 'travelExpense'}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
            tooltip={tooltip}
        >
            <NySearchField
                url={CONSTANTS_REQ.TRAVEL_EXPENSE.SEARCH}
                map={{
                    id: 'id',
                    label: 'name',
                    name: 'name',
                    text: 'name',
                    includeInJoppd: 'includeInJoppd',
                    joppdPaymentCode: 'joppdPaymentCode',
                    joppdTaxFreeCode: 'joppdTaxFreeCode',
                    bookExpense: 'bookExpense',
                }}
                searchBy="name"
                SearchPopupComponent={<TravelExpenseIndex readonly={!canCreate()} showAllColumns={showAllColumns} />}
                customListWidth={showAllColumns ? '1600px' : '900px'}
                AddNewModalComponent={canCreate() ? TravelExpenseEdit : null}
                renderOption={customRenderTextOption}
                nyTestId="travel-expense"
                autoFocus={true}
                onChange={travelExpenseOnChange}
                mustGetPopupContainer={mustGetPopupContainer}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default TravelWarrantExpensesSearch;
