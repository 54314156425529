import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { NyModalConfirm, NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Alert, Button, Checkbox, Col, Dropdown, Form, Menu, Modal, Row } from 'antd';
import { useState } from 'react';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, okNotification } from '../../../../utils/Utils';
import DepartmentSearch from '../defense-department/department/search';
import SectionSearch from '../defense-department/section/search';

const PlanActions = ({
    setRefreshTable,
    canCreateActions,
    isCustomView = false,
    planAction = undefined,
    historyRef = undefined,
}: any) => {
    const enMilitaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const enMilitaryWorkApprovalAction = useEnum('MILITARY_WORK_APPROVAL_ACTION');
    const enDefenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    //actions modal
    const [selectedType, setSelectedType] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>(null);
    const [actionForm] = Form.useForm();
    const [actionsModalVisible, setActionsModalVisible] = useState<boolean>(false);
    const [actionsModalLoading, setActionsModalLoading] = useState<boolean>(false);
    const [actionsModalTitle, setActionsModalTitle] = useState<string>(geti18nText('app.default.actions'));
    const [actionsModalOkText, setActionsModalOkText] = useState<string>(geti18nText('app.default.button.save'));
    const [departmentId, setDepartmentId] = useState<any>([]);
    const [defenseSectionId, setDefenseSectionId] = useState<any>([]);
    const [addEmployees, setAddEmployees] = useState<boolean>(true);

    const openScheduleSuggestion = (workApprovalType: any, workApprovalAction: any) => {
        setSelectedType(workApprovalType);
        setSelectedAction(workApprovalAction);
        switch (workApprovalAction) {
            case enMilitaryWorkApprovalAction.ADD:
                setActionsModalTitle(
                    workApprovalType == enMilitaryWorkApprovalType.GOVERNOR
                        ? geti18nText('schedule.suggestions.create.new.suggestion.gov')
                        : geti18nText('schedule.suggestions.genereate.plan.modal.title')
                );
                setActionsModalOkText(geti18nText('schedule.suggestions.genereate.plan.create.label'));
                break;
            case enMilitaryWorkApprovalAction.RENEWAL:
                setActionsModalTitle(
                    geti18nText('schedule.suggestions.extend.plan.modal.title') +
                        ' ' +
                        (workApprovalType == enMilitaryWorkApprovalType.GOVERNOR
                            ? geti18nText('schedule.suggestions.guvernor')
                            : geti18nText('schedule.suggestions.morh'))
                );
                setActionsModalOkText(geti18nText('schedule.suggestions.extend.button'));
                break;
            case enMilitaryWorkApprovalAction.REMOVE:
                setActionsModalTitle(
                    geti18nText('schedule.suggestions.delete.plan.modal.title') +
                        ' ' +
                        (workApprovalType == enMilitaryWorkApprovalType.GOVERNOR
                            ? geti18nText('schedule.suggestions.guvernor')
                            : geti18nText('schedule.suggestions.morh'))
                );
                setActionsModalOkText(geti18nText('schedule.suggestions.delete.button'));
                break;
        }
        actionForm.setFieldsValue({ addEmployees: true });
        setActionsModalVisible(true);
    };

    const closeActionsModal = () => {
        setSelectedType(null);
        setSelectedAction(null);
        setActionsModalVisible(false);
        setActionsModalLoading(false);
        actionForm.resetFields();
        setActionsModalTitle('');
        setActionsModalOkText('');
        setDepartmentId(undefined);
    };

    const generateScheduleSuggestion = () => {
        setActionsModalLoading(true);
        let params: any = {
            type: selectedType,
            action: selectedAction,
            addEmployees: actionForm.getFieldValue('addEmployees'),
        };
        if (defenseSectionId && defenseSectionId.length > 0) {
            const secId = defenseSectionId.map((value: any) => value.id);
            params.sectionIds = secId;
        } else if (departmentId && departmentId.length > 0 && !defenseSectionId) {
            const depId = departmentId.map((value: any) => value.id);
            params.departmentsIds = depId;
        }

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CREATE, undefined, params).then(
            (result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    closeActionsModal();
                    okNotification(
                        geti18nText('app.default.save.ok'),
                        geti18nText(
                            selectedAction == enMilitaryWorkApprovalAction.RENEWAL
                                ? 'schedule.suggestions.extend.success'
                                : selectedAction == enMilitaryWorkApprovalAction.REMOVE
                                ? 'schedule.suggestions.delete.success'
                                : 'schedule.suggestions.generate.plan.success'
                        )
                    );
                    setRefreshTable((refresh: any) => refresh + 1);
                    historyRef && historyRef.push({ pathname: '/human/work-obligation/schedule-suggestions' });
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                    setActionsModalLoading(false);
                }
            }
        );
    };

    const getActionModalForm = () => {
        return (
            <Form layout="vertical" form={actionForm}>
                {selectedType == enMilitaryWorkApprovalType.GOVERNOR ? (
                    <>
                        {getAddEmployeesCheckbox(
                            selectedAction == enMilitaryWorkApprovalAction.RENEWAL
                                ? geti18nText('schedule.suggestions.extend.addEmployees')
                                : selectedAction == enMilitaryWorkApprovalAction.REMOVE
                                ? geti18nText('schedule.suggestions.remove.addEmployees')
                                : geti18nText('schedule.suggestions.create.new.suggestion.addEmployees')
                        )}
                    </>
                ) : (
                    <>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Alert
                                    message={geti18nText('schedule.suggestions.genereate.plan.info.alert')}
                                    type="info"
                                    showIcon
                                    style={{ margin: '5px', marginBottom: 30 }}
                                />
                            </Col>
                        </Row>
                        {getAddEmployeesCheckbox(
                            selectedAction == enMilitaryWorkApprovalAction.RENEWAL
                                ? geti18nText('schedule.suggestions.extend.addEmployees')
                                : selectedAction == enMilitaryWorkApprovalAction.REMOVE
                                ? geti18nText('schedule.suggestions.remove.addEmployees')
                                : geti18nText('schedule.suggestions.create.new.suggestion.addEmployees')
                        )}
                        <Row gutter={24}>
                            <Col span={24}>
                                <DepartmentSearch
                                    label={geti18nText('employee.mobilization.edit.defenseDepartment')}
                                    canCreate={false}
                                    value={departmentId}
                                    onChange={(selectedItems: any) => {
                                        setDepartmentId(selectedItems);
                                        setDefenseSectionId(undefined);
                                        setAddEmployees(false);
                                        actionForm.setFieldsValue({ addEmployees: false });
                                    }}
                                    mode="multiple"
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <SectionSearch
                                    label={geti18nText('mobilization.edit.defenseSectionName')}
                                    setDefaultFilterValue={getSectionFilter}
                                    canCreate={false}
                                    mode={'multiple'}
                                    value={defenseSectionId}
                                    departmentId={departmentId && departmentId.map((value: any) => value.id)}
                                    onChange={(e: any) => {
                                        setDefenseSectionId(e);
                                        setAddEmployees(false);
                                        actionForm.setFieldsValue({ addEmployees: false });
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        );
    };

    const getSectionFilter = () => {
        if (departmentId != undefined) {
            const depId = departmentId.map((value: any) => value.id);
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: enDefenseDepartmentType.SECTION,
                },
                { field: 'parentId', condition: 'in', value: depId.join(',') },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: enDefenseDepartmentType.SECTION,
                },
            ];
        }
    };

    const getAddEmployeesCheckbox = (label: string) => (
        <Row gutter={24}>
            <Col span={24}>
                <Form.Item label={label} name="addEmployees" valuePropName="checked">
                    <Checkbox
                        defaultChecked
                        checked={addEmployees}
                        onChange={(e: any) => {
                            setAddEmployees(e.target.checked);
                        }}
                    />
                </Form.Item>
            </Col>
        </Row>
    );

    const getCustomView = () => (
        <div style={{ float: 'left', paddingLeft: '15px' }}>
            {planAction && (
                <div>
                    <Dropdown
                        key="more"
                        placement="top"
                        overlay={actionsMenuGuvernor(
                            openScheduleSuggestion,
                            enMilitaryWorkApprovalType,
                            enMilitaryWorkApprovalAction,
                            canCreateActions
                        )}
                        trigger={['click']}
                    >
                        <Button>
                            {geti18nText('app.default.actions') + ' ' + geti18nText('schedule.suggestions.guvernor')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                    <Dropdown
                        key="more"
                        placement="top"
                        overlay={actionsMenuMorh(
                            openScheduleSuggestion,
                            enMilitaryWorkApprovalType,
                            enMilitaryWorkApprovalAction,
                            canCreateActions
                        )}
                        trigger={['click']}
                    >
                        <Button>
                            {geti18nText('app.default.actions') + ' ' + geti18nText('schedule.suggestions.morh')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
            )}
        </div>
    );

    const getView = () => (
        <div style={{ flex: 0.5, display: 'flex' }}>
            <div style={{ display: 'flex' }}>
                <Dropdown
                    key="more"
                    placement="top"
                    overlay={actionsMenuGuvernor(
                        openScheduleSuggestion,
                        enMilitaryWorkApprovalType,
                        enMilitaryWorkApprovalAction,
                        canCreateActions
                    )}
                    trigger={['click']}
                >
                    <Button style={{ width: '100%' }}>
                        {geti18nText('app.default.actions') + ' ' + geti18nText('schedule.suggestions.guvernor')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
                <Dropdown
                    key="more"
                    placement="top"
                    overlay={actionsMenuMorh(
                        openScheduleSuggestion,
                        enMilitaryWorkApprovalType,
                        enMilitaryWorkApprovalAction,
                        canCreateActions
                    )}
                    trigger={['click']}
                >
                    <Button style={{ width: '100%' }}>
                        {geti18nText('app.default.actions') + ' ' + geti18nText('schedule.suggestions.morh')}
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            </div>
        </div>
    );

    return (
        <>
            {isCustomView ? getCustomView() : getView()}
            <Modal
                okButtonProps={{ loading: actionsModalLoading }}
                width={600}
                open={actionsModalVisible}
                title={actionsModalTitle}
                okText={actionsModalOkText}
                onOk={generateScheduleSuggestion}
                onCancel={closeActionsModal}
                destroyOnClose={true}
            >
                {getActionModalForm()}
            </Modal>
        </>
    );
};
export default PlanActions;

export const generateScheduleSuggestion = (
    type: any,
    action: any,
    employeeIds: any = null,
    historyRef: any,
    enMilitaryWorkApprovalAction: any,
    tableSearch: any = undefined
) => {
    let params: any = {
        type: type,
        action: action,
    };
    let url = '';
    if (tableSearch) {
        url = CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CREATE;
        params.search = tableSearch;
    } else {
        url = CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CREATE_EMPLOYEE;
        params.employeeIds = employeeIds;
    }
    NyRequestResolver.requestPost(url, undefined, params).then((result: any) => {
        if (result.status === RESPONSE.CREATED) {
            okNotification(
                geti18nText('app.default.save.ok'),
                geti18nText(
                    action == enMilitaryWorkApprovalAction.RENEWAL
                        ? 'schedule.suggestions.extend.success'
                        : action == enMilitaryWorkApprovalAction.REMOVE
                        ? 'schedule.suggestions.delete.success'
                        : 'schedule.suggestions.generate.plan.success'
                )
            );
            historyRef && historyRef.push({ pathname: '/human/work-obligation/schedule-suggestions' });
        } else {
            errorNotification(geti18nText('app.default.save.nok'));
        }
    });
};

export const actionsMenuGuvernor = (
    generateScheduleSuggestion: any,
    enMilitaryWorkApprovalType: any,
    enMilitaryWorkApprovalAction: any,
    canCreate: any
) => {
    return (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<FormOutlined />}
                        style={{ width: '100%' }}
                        disabled={!canCreate(enMilitaryWorkApprovalType.GOVERNOR, enMilitaryWorkApprovalAction.ADD)}
                        onClick={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.GOVERNOR,
                                enMilitaryWorkApprovalAction.ADD
                            );
                        }}
                    >
                        {geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<FormOutlined />}
                        style={{ width: '100%' }}
                        disabled={!canCreate(enMilitaryWorkApprovalType.GOVERNOR, enMilitaryWorkApprovalAction.RENEWAL)}
                        onClick={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.GOVERNOR,
                                enMilitaryWorkApprovalAction.RENEWAL
                            );
                        }}
                    >
                        {geti18nText('schedule.suggestions.extend.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.guvernor')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<FormOutlined />}
                        style={{ width: '100%' }}
                        disabled={!canCreate(enMilitaryWorkApprovalType.GOVERNOR, enMilitaryWorkApprovalAction.REMOVE)}
                        onClick={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.GOVERNOR,
                                enMilitaryWorkApprovalAction.REMOVE
                            );
                        }}
                    >
                        {geti18nText('schedule.suggestions.delete.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.guvernor')}
                    </Button>
                </div>
            </div>
        </Menu>
    );
};

export const actionsMenuMorh = (
    generateScheduleSuggestion: any,
    enMilitaryWorkApprovalType: any,
    enMilitaryWorkApprovalAction: any,
    canCreate: any
) => {
    return (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<FormOutlined />}
                        style={{ width: '100%' }}
                        disabled={!canCreate(enMilitaryWorkApprovalType.MORH, enMilitaryWorkApprovalAction.ADD)}
                        onClick={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.MORH,
                                enMilitaryWorkApprovalAction.ADD
                            );
                        }}
                    >
                        {geti18nText('schedule.suggestions.create.new.suggestion.compeneten.regional.department')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<FormOutlined />}
                        style={{ width: '100%' }}
                        disabled={!canCreate(enMilitaryWorkApprovalType.MORH, enMilitaryWorkApprovalAction.RENEWAL)}
                        onClick={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.MORH,
                                enMilitaryWorkApprovalAction.RENEWAL
                            );
                        }}
                    >
                        {geti18nText('schedule.suggestions.extend.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.morh')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<FormOutlined />}
                        style={{ width: '100%' }}
                        disabled={!canCreate(enMilitaryWorkApprovalType.MORH, enMilitaryWorkApprovalAction.REMOVE)}
                        onClick={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.MORH,
                                enMilitaryWorkApprovalAction.REMOVE
                            );
                        }}
                    >
                        {geti18nText('schedule.suggestions.delete.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.morh')}
                    </Button>
                </div>
            </div>
        </Menu>
    );
};

export const actionsMenuGuvernorConfirm = (
    enMilitaryWorkApprovalType: any,
    enMilitaryWorkApprovalAction: any,
    canCreate: any,
    employeeIds: any,
    historyRef: any
) => {
    return (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                        content={geti18nText('schedule.suggestions.add.plan.modal.title.confirm')}
                        onConfirm={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.GOVERNOR,
                                enMilitaryWorkApprovalAction.ADD,
                                employeeIds,
                                historyRef,
                                enMilitaryWorkApprovalAction
                            );
                        }}
                    >
                        <Button
                            icon={<FormOutlined />}
                            style={{ width: '100%' }}
                            disabled={!canCreate(enMilitaryWorkApprovalType.GOVERNOR, enMilitaryWorkApprovalAction.ADD)}
                        >
                            {geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                        </Button>
                    </NyModalConfirm>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={
                            geti18nText('schedule.suggestions.extend.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.guvernor')
                        }
                        content={geti18nText('schedule.suggestions.extend.plan.modal.title.confirm')}
                        onConfirm={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.GOVERNOR,
                                enMilitaryWorkApprovalAction.RENEWAL,
                                employeeIds,
                                historyRef,
                                enMilitaryWorkApprovalAction
                            );
                        }}
                    >
                        <Button
                            icon={<FormOutlined />}
                            style={{ width: '100%' }}
                            disabled={
                                !canCreate(enMilitaryWorkApprovalType.GOVERNOR, enMilitaryWorkApprovalAction.RENEWAL)
                            }
                        >
                            {geti18nText('schedule.suggestions.extend.button') +
                                ' ' +
                                geti18nText('schedule.suggestions.guvernor')}
                        </Button>
                    </NyModalConfirm>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={
                            geti18nText('schedule.suggestions.delete.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.guvernor')
                        }
                        content={geti18nText('schedule.suggestions.delete.plan.modal.title.confirm')}
                        onConfirm={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.GOVERNOR,
                                enMilitaryWorkApprovalAction.REMOVE,
                                employeeIds,
                                historyRef,
                                enMilitaryWorkApprovalAction
                            );
                        }}
                    >
                        <Button
                            icon={<FormOutlined />}
                            style={{ width: '100%' }}
                            disabled={
                                !canCreate(enMilitaryWorkApprovalType.GOVERNOR, enMilitaryWorkApprovalAction.REMOVE)
                            }
                        >
                            {geti18nText('schedule.suggestions.delete.button') +
                                ' ' +
                                geti18nText('schedule.suggestions.guvernor')}
                        </Button>
                    </NyModalConfirm>
                </div>
            </div>
        </Menu>
    );
};

export const actionsMenuMorhConfirm = (
    enMilitaryWorkApprovalType: any,
    enMilitaryWorkApprovalAction: any,
    canCreate: any,
    employeeIds: any,
    historyRef: any
) => {
    return (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={geti18nText('schedule.suggestions.create.new.suggestion.compeneten.regional.department')}
                        content={geti18nText('schedule.suggestions.add.plan.modal.title.confirm')}
                        onConfirm={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.MORH,
                                enMilitaryWorkApprovalAction.ADD,
                                employeeIds,
                                historyRef,
                                enMilitaryWorkApprovalAction
                            );
                        }}
                    >
                        <Button
                            icon={<FormOutlined />}
                            style={{ width: '100%' }}
                            disabled={!canCreate(enMilitaryWorkApprovalType.MORH, enMilitaryWorkApprovalAction.ADD)}
                        >
                            {geti18nText('schedule.suggestions.create.new.suggestion.compeneten.regional.department')}
                        </Button>
                    </NyModalConfirm>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={
                            geti18nText('schedule.suggestions.extend.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.morh')
                        }
                        content={geti18nText('schedule.suggestions.extend.plan.modal.title.confirm')}
                        onConfirm={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.MORH,
                                enMilitaryWorkApprovalAction.RENEWAL,
                                employeeIds,
                                historyRef,
                                enMilitaryWorkApprovalAction
                            );
                        }}
                    >
                        <Button
                            icon={<FormOutlined />}
                            style={{ width: '100%' }}
                            disabled={!canCreate(enMilitaryWorkApprovalType.MORH, enMilitaryWorkApprovalAction.RENEWAL)}
                        >
                            {geti18nText('schedule.suggestions.extend.button') +
                                ' ' +
                                geti18nText('schedule.suggestions.morh')}
                        </Button>
                    </NyModalConfirm>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyModalConfirm
                        title={
                            geti18nText('schedule.suggestions.delete.button') +
                            ' ' +
                            geti18nText('schedule.suggestions.morh')
                        }
                        content={geti18nText('schedule.suggestions.delete.plan.modal.title.confirm')}
                        onConfirm={() => {
                            generateScheduleSuggestion(
                                enMilitaryWorkApprovalType.MORH,
                                enMilitaryWorkApprovalAction.REMOVE,
                                employeeIds,
                                historyRef,
                                enMilitaryWorkApprovalAction
                            );
                        }}
                    >
                        <Button
                            icon={<FormOutlined />}
                            style={{ width: '100%' }}
                            disabled={!canCreate(enMilitaryWorkApprovalType.MORH, enMilitaryWorkApprovalAction.REMOVE)}
                        >
                            {geti18nText('schedule.suggestions.delete.button') +
                                ' ' +
                                geti18nText('schedule.suggestions.morh')}
                        </Button>
                    </NyModalConfirm>
                </div>
            </div>
        </Menu>
    );
};

export const getPlanActionsEmployeeAnalyze = (
    planAction: any = undefined,
    enMilitaryWorkApprovalType: any,
    enMilitaryWorkApprovalAction: any,
    employeeIds: any,
    hasSelectedDelete: any,
    historyRef: any,
    tableSearch: any
) => {
    return (
        <>
            {planAction && (
                <>
                    {planAction == enMilitaryWorkApprovalAction.ADD ? (
                        <div style={{ float: 'left', paddingLeft: '15px' }}>
                            <NyModalConfirm
                                title={geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                                content={geti18nText('schedule.suggestions.add.plan.modal.title.confirm')}
                                onConfirm={() => {
                                    generateScheduleSuggestion(
                                        enMilitaryWorkApprovalType.GOVERNOR,
                                        enMilitaryWorkApprovalAction.ADD,
                                        employeeIds,
                                        historyRef,
                                        enMilitaryWorkApprovalAction,
                                        tableSearch
                                    );
                                }}
                            >
                                <Button icon={<FormOutlined />} disabled={!hasSelectedDelete}>
                                    {geti18nText('schedule.suggestions.create.new.suggestion.gov')}
                                </Button>
                            </NyModalConfirm>{' '}
                            <NyModalConfirm
                                title={geti18nText(
                                    'schedule.suggestions.create.new.suggestion.compeneten.regional.department'
                                )}
                                content={geti18nText('schedule.suggestions.add.plan.modal.title.confirm')}
                                onConfirm={() => {
                                    generateScheduleSuggestion(
                                        enMilitaryWorkApprovalType.MORH,
                                        enMilitaryWorkApprovalAction.ADD,
                                        employeeIds,
                                        historyRef,
                                        enMilitaryWorkApprovalAction,
                                        tableSearch
                                    );
                                }}
                            >
                                <Button icon={<FormOutlined />} disabled={!hasSelectedDelete}>
                                    {geti18nText(
                                        'schedule.suggestions.create.new.suggestion.compeneten.regional.department'
                                    )}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    ) : planAction == enMilitaryWorkApprovalAction.RENEWAL ? (
                        <div style={{ float: 'left', paddingLeft: '15px' }}>
                            <NyModalConfirm
                                title={
                                    geti18nText('schedule.suggestions.extend.button') +
                                    ' ' +
                                    geti18nText('schedule.suggestions.guvernor')
                                }
                                content={geti18nText('schedule.suggestions.extend.plan.modal.title.confirm')}
                                onConfirm={() => {
                                    generateScheduleSuggestion(
                                        enMilitaryWorkApprovalType.GOVERNOR,
                                        enMilitaryWorkApprovalAction.RENEWAL,
                                        employeeIds,
                                        historyRef,
                                        enMilitaryWorkApprovalAction,
                                        tableSearch
                                    );
                                }}
                            >
                                <Button icon={<FormOutlined />} disabled={!hasSelectedDelete}>
                                    {geti18nText('schedule.suggestions.extend.button') +
                                        ' ' +
                                        geti18nText('schedule.suggestions.guvernor')}
                                </Button>
                            </NyModalConfirm>
                            <NyModalConfirm
                                title={
                                    geti18nText('schedule.suggestions.extend.button') +
                                    ' ' +
                                    geti18nText('schedule.suggestions.morh')
                                }
                                content={geti18nText('schedule.suggestions.extend.plan.modal.title.confirm')}
                                onConfirm={() => {
                                    generateScheduleSuggestion(
                                        enMilitaryWorkApprovalType.MORH,
                                        enMilitaryWorkApprovalAction.RENEWAL,
                                        employeeIds,
                                        historyRef,
                                        enMilitaryWorkApprovalAction,
                                        tableSearch
                                    );
                                }}
                            >
                                <Button icon={<FormOutlined />} disabled={!hasSelectedDelete}>
                                    {geti18nText('schedule.suggestions.extend.button') +
                                        ' ' +
                                        geti18nText('schedule.suggestions.morh')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    ) : planAction == enMilitaryWorkApprovalAction.REMOVE ? (
                        <div style={{ float: 'left', paddingLeft: '15px' }}>
                            <NyModalConfirm
                                title={
                                    geti18nText('schedule.suggestions.delete.button') +
                                    ' ' +
                                    geti18nText('schedule.suggestions.guvernor')
                                }
                                content={geti18nText('schedule.suggestions.delete.plan.modal.title.confirm')}
                                onConfirm={() => {
                                    generateScheduleSuggestion(
                                        enMilitaryWorkApprovalType.GOVERNOR,
                                        enMilitaryWorkApprovalAction.REMOVE,
                                        employeeIds,
                                        historyRef,
                                        enMilitaryWorkApprovalAction,
                                        tableSearch
                                    );
                                }}
                            >
                                <Button icon={<FormOutlined />} disabled={!hasSelectedDelete}>
                                    {geti18nText('schedule.suggestions.delete.button') +
                                        ' ' +
                                        geti18nText('schedule.suggestions.guvernor')}
                                </Button>
                            </NyModalConfirm>
                            <NyModalConfirm
                                title={
                                    geti18nText('schedule.suggestions.delete.button') +
                                    ' ' +
                                    geti18nText('schedule.suggestions.morh')
                                }
                                content={geti18nText('schedule.suggestions.delete.plan.modal.title.confirm')}
                                onConfirm={() => {
                                    generateScheduleSuggestion(
                                        enMilitaryWorkApprovalType.MORH,
                                        enMilitaryWorkApprovalAction.REMOVE,
                                        employeeIds,
                                        historyRef,
                                        enMilitaryWorkApprovalAction,
                                        tableSearch
                                    );
                                }}
                            >
                                <Button icon={<FormOutlined />} disabled={!hasSelectedDelete}>
                                    {geti18nText('schedule.suggestions.delete.button') +
                                        ' ' +
                                        geti18nText('schedule.suggestions.morh')}
                                </Button>
                            </NyModalConfirm>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};
