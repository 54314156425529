import { geti18nText } from '@nybble/nyreact';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { MobilizationRights } from '../../rights/mobilizationRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_FILTER, DEFAULT_PAGE_SIZE, DEFAULT_TABLE_SCROLL } from '../../utils/Constants';
import NyFilesUpload from '../../utils/ny-file-upload-custom';

const EmployeeDocuments = ({
    documentType = undefined,
    employeeId = undefined,
    defaultFilter = undefined,
    refresh = undefined,
    canPreview = false,
    canDownload = false,
    canDelete = false,
    canEdit = false,
    canAddNewFile = false,
    scroll = DEFAULT_TABLE_SCROLL,
    showDocumentTypeColumn = false,
    actionDeactivateRecords = true,
    CSVFileName = geti18nText('app.default.files'),
}: any) => {
    const uploadFilesRef = useRef<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);

    const canExportCSV = () => {
        return MobilizationRights.canExportCodebooks();
    };

    return (
        <>
            {employeeId && (
                <NyFilesUpload
                    documentType={documentType}
                    url={CONSTANTS_REQ.EMPLOYEE_FILE.LIST}
                    editUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                    manageUploadUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                    deactivateUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                    defaultFilesFilter={
                        defaultFilter
                            ? defaultFilter
                            : documentType
                            ? [
                                  { field: 'active', condition: 'equals_bool', value: 1 },
                                  { field: 'document_type', condition: 'equals', value: documentType },
                                  { field: 'employee_id', condition: 'equals', value: employeeId },
                              ]
                            : [
                                  { field: 'active', condition: 'equals_bool', value: 1 },
                                  { field: 'employee_id', condition: 'equals', value: employeeId },
                              ]
                    }
                    canPreview={canPreview}
                    canDownload={canDownload}
                    canDelete={canDelete}
                    canEdit={canEdit}
                    canAddNewFile={canAddNewFile}
                    id={employeeId}
                    refId={employeeId}
                    employee={employee}
                    ref={uploadFilesRef}
                    defaultType={'nyDataTable'}
                    showSwitchView={false}
                    showHeader={false}
                    hasNoRefId={true}
                    actionDeactivateRecords={actionDeactivateRecords}
                    showDocumentTypeColumn={showDocumentTypeColumn}
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    defaultFilter={DEFAULT_FILTER}
                    CSVFileName={CSVFileName}
                    exportCSV={canExportCSV()}
                    columnStyleClass={'description-div-large'}
                    scroll={scroll}
                    refresh={refresh}
                />
            )}
        </>
    );
};

export default EmployeeDocuments;
