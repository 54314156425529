import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, NyDataTable, NySession, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import SectionEdit from './edit';
import useEnum from '../../../../../hooks/useEnum';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { MobilizationRights } from '../../../../../rights/mobilizationRights';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';

const SectionIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    departmentId,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const EnDefenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');

    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCsvExportCodebooks();
    };

    const setDefaultFilterValue = () => {
        if (departmentId) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: EnDefenseDepartmentType.SECTION,
                },
                { field: 'parentId', condition: 'in', value: departmentId.join(',') },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'type',
                    condition: 'equals',
                    value: EnDefenseDepartmentType.SECTION,
                },
            ];
        }
    };

    const columns = [
        {
            title: geti18nText('defense.department.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('defense.department.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('defense.department.table.department.column.section'),
            dataIndex: ['parent', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('defense.department.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <NyDataTable
            nyId="human-defence-department-table"
            url={CONSTANTS_REQ.DEFENSE_DEPARTMENT.LIST}
            addNewButtonText={geti18nText('defense.department.table.section.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            showRecordModal={true}
            modalComponent={SectionEdit}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={!canCreate()}
            shortcuts={true}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            // exportCSV={canExportCSV()}
            // CSVFileName={geti18nText('menu.work.obligation.reserve.rank')}
            // colCSVCustomization={csvColumnCustomisation()}
        />
    );
};

export default SectionIndex;
