import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NyImageUpload from '../../../../utils/ny-file-upload-image';
import {
    BusinessUnitSelectOption,
    customEmployeeRenderOption,
    getEmployeeSelectOption,
    getEnumArray,
    getEnumFormat,
    onKeyDownTextAreaCustom,
    setEnumFormat,
} from '../../../../utils/Utils';
import { BusinessUnitSearch } from '../../../human/views/business-unit/search';
import UserIndex from '../../../tasks/views/user';

const MessageEdit = (props: any) => {
    const enMessageType = useEnum('MESSAGE_TYPE');
    const enMessageStatus = useEnum('MESSAGE_STATUS');
    const { employee } = useSelector((state: RootState) => state.employee);

    const [editHeader, setEditHeader] = useState(geti18nText('message.edit.new'));
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [type, setType] = useState(props?.editProps?.type ? props.editProps.type : null);

    const [allUsers, setAllUsers] = useState<boolean>(false);
    const [needConfirmation, setNeedConfirmation] = useState<boolean>(false);
    const [users, setUsers] = useState<any>(null);
    const [businessUnitIds, setBusinessUnitIds] = useState<any>(null);
    const [files, setFiles] = useState<any>([]);

    const canCreate = () => {
        if (AdministrationRights.isMessageAdmin()) {
            return true;
        } else if (type == enMessageType.WORK_OBLIGATION) {
            return MobilizationRights.isMobilizationAdmin();
        }
    };

    useEffect(() => {
        if (allUsers) {
            form.setFieldsValue({ businessUnit: [], user: [] });
            setBusinessUnitIds(null);
        }
    }, [allUsers]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setEditHeader(geti18nText('message.edit.title') + ' - ' + dataForm?.name);

        if (dataForm.type) {
            dataForm.type = setEnumFormat('MESSAGE_TYPE', dataForm.type);
        }
        if (dataForm.status) {
            dataForm.status = setEnumFormat('MESSAGE_STATUS', dataForm.status);
        }

        if (dataForm.businessUnits) {
            if (dataForm.businessUnitList?.length > 0) {
                setBusinessUnitIds(dataForm.businessUnitList);
            }
            dataForm.businessUnitList = BusinessUnitSelectOption(dataForm.businessUnitList);
            delete dataForm.businessUnits;
        }

        if (dataForm.users) {
            if (dataForm.userList?.length > 0) {
                setUsers(dataForm.userList);
            }
            dataForm.userList = getEmployeeSelectOption(dataForm.userList);
            delete dataForm.users;
        }

        setFiles(dataForm?.messageFiles || []);

        setAllUsers(dataForm.allUsers);
        setNeedConfirmation(dataForm.needConfirmation);

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };
    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setAllUsers(false);
        setNeedConfirmation(false);
        setBusinessUnitIds(null);
        setFiles([]);
        setEditHeader(geti18nText('message.edit.new'));
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.MESSAGE.EDIT}
            width={900}
            form={form}
            hideActivationButtons={!canCreate()}
            hideSubmitButton={!canCreate()}
            {...props}
            onModalOpen={onModalOpen}
            setValues={setValues}
            normalize={(values: any) => {
                values.type = values.type ? getEnumFormat(values.type) : type ? type : null;
                values.status = values.status ? getEnumFormat(values.status) : enMessageStatus.DRAFT;

                values.allUsers = allUsers;
                values.needConfirmation = needConfirmation;
                values.accessRightType = 1;

                if (users?.length > 0) {
                    values.userList = users?.map((value: any) => {
                        return { id: value.id };
                    });
                } else {
                    delete values.userList;
                }
                if (businessUnitIds?.length > 0) {
                    values.businessUnitList = businessUnitIds?.map((value: any) => {
                        return { id: value.id };
                    });
                } else {
                    delete values.businessUnitList;
                }
                if (files && files.length > 0) {
                    values.messageFiles = files.map((item: any) => {
                        if (item.id > 0) {
                            return item;
                        } else {
                            return {
                                file: item.id,
                                name: item.name,
                                message: { id: dataForm },
                                active: item.active,
                                id: null,
                            };
                        }
                    });
                } else {
                    values.messageFiles = null;
                }
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('message.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={geti18nText('message.edit.description')}
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <TextArea rows={3} onKeyDown={onKeyDownTextAreaCustom} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                {!type && (
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('message.edit.type')}
                            name="type"
                            initialValue={setEnumFormat('MESSAGE_TYPE', 1)}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                options={getEnumArray('MESSAGE_TYPE')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                className=""
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col span={type ? 24 : 12}>
                    <Form.Item
                        label={geti18nText('message.edit.status')}
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('MESSAGE_STATUS')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            className=""
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('message.edit.needConfirmation')}
                        name="needConfirmation"
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={needConfirmation}
                            onChange={(val: any) => {
                                setNeedConfirmation(val.target.checked);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('message.edit.allUsers')} name="allUsers" valuePropName="checked">
                        <Checkbox
                            checked={allUsers}
                            onChange={(val: any) => {
                                setAllUsers(val.target.checked);
                                setUsers([]);
                                setBusinessUnitIds([]);
                                form.setFieldsValue({ userList: [] });
                                form.setFieldsValue({ businessUnitList: [] });
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('message.edit.userList')} name="userList">
                        <NySearchField
                            url={CONSTANTS_REQ.USER.SEARCH}
                            map={{
                                id: 'id',
                                label: 'text',
                                employmentRecordId: 'employmentRecordId',
                                businessUnit: 'businessUnit',
                            }}
                            searchBy="user"
                            order="person.last_name, person.first_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<UserIndex disabled={true} />}
                            style={{ width: '100%' }}
                            addNewItem={false}
                            disabled={allUsers || businessUnitIds?.length > 0}
                            mode="multiple"
                            onChange={(value: any) => {
                                setBusinessUnitIds([]);
                                form.setFieldsValue({ businessUnitList: [] });
                                setUsers(value && value.length > 0 ? value : null);
                            }}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <BusinessUnitSearch
                        label={geti18nText('message.edit.businessUnit')}
                        formItemName={'businessUnitList'}
                        mode="multiple"
                        disabled={allUsers || users?.length > 0}
                        onChange={(value: any) => {
                            setUsers([]);
                            form.setFieldsValue({ userList: [] });
                            setBusinessUnitIds(value && value.length > 0 ? value : null);
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <NyImageUpload
                        files={files}
                        setFiles={setFiles}
                        saveOnUpload
                        accept={'*'}
                        listType="text"
                        maxImage={999}
                    />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default MessageEdit;
