import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import CostObjectIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';

const CostObjectSearch = ({
    label = geti18nText('travelWarrant.edit.cost.object'),
    name = 'accountingCostObject',
    renderOption = (option: any) => `${option.text}${option.code ? ' (' + option.code + ')' : ''}`,
    onChange = null,
    setDefaultFilterValue = () => [{ field: 'active', condition: 'equals_bool', value: 1 }],
    required = false,
    validator = null,
    readonly = false,
    disabled = false,
    addNewItem = false,
    rules = undefined,
    formStyle = undefined,
    tooltip = undefined,
}: any) => {
    const getRules = () => {
        if (rules) {
            return rules;
        } else if (required && validator != null) {
            return [
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
                {
                    validator: validator,
                },
            ];
        } else if (!validator || validator == null) {
            return [
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ];
        }
    };
    return (
        <Form.Item label={label} name={name} rules={getRules()} style={formStyle} tooltip={tooltip}>
            <NySearchField
                url={CONSTANTS_REQ.COST_OBJECT.SEARCH}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                searchBy="name,code"
                itemName={[['code'], ['name']]}
                customItemNameLabel={'name (code)'}
                renderOption={renderOption}
                SearchPopupComponent={<CostObjectIndex readonly={readonly} />}
                nyTestId="accounting-cost-object"
                addNewItem={addNewItem}
                setDefaultFilterValue={setDefaultFilterValue}
                onChange={onChange}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default CostObjectSearch;
