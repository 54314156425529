import { ClockCircleOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, NySpinner, RESPONSE, UseRequestSwrGet } from '@nybble/nyreact';
import { Button, Col, Empty, Form, Modal, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEnum from '../../hooks/useEnum';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { RootState } from '../../rootReducer';
import { saveUserWorkTimeRegistration } from '../../slices/workAttendanceSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getErrorNotificationMessage, okNotification } from '../../utils/Utils';
import LocationSearch from '../../views/human/views/location/search';
import NyCustomSelectButtons from '../ny-custom-select-buttons';

const { Text } = Typography;

const WorkAttendanceRegistration = ({}: any) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;
    const { workTimeRegistration } = useSelector((state: RootState) => state.workAttendance);
    const enAttendanceRegistrationType = useEnum('ATTENDANCE_REGISTRATION_TYPE');
    const selectedAttendanceRegistrationType = enAttendanceRegistrationType.EDIT;
    const notificationDuration: number = 1;

    const [visibleLogoutModal, setVisibleLogoutModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [refreshStatus, setRefreshStatus] = useState<any>(0);
    const [time, setTime] = useState<any>(moment());
    const [listDataBreak, setListDataBreak] = useState([]);
    const [workTypeList, setWorkTypeList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const [selectedWorktype, setSelectedWorkType] = useState<any>(null);

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceRegistrationUserOrAdmin();
    };

    const isLoggedIn = () => {
        return workTimeRegistration?.isLogged;
    };

    const {
        data: data,
        isError: error,
        isLoading: loadingData,
    } = UseRequestSwrGet(CONSTANTS_REQ.ATTENDANCE_REGISTRATION.STATUS, {}, true, false, undefined, {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        refreshInterval: 60000,
    });

    useEffect(() => {
        onDataChange(data);
    }, [data]);

    useEffect(() => {
        getStatus();
    }, [refreshStatus]);

    useEffect(() => {
        if (visibleLogoutModal) {
            setRefreshStatus((refreshStatus: any) => refreshStatus + 1);
            if (isLoggedIn()) {
                getAttendanceBreakType();
            }
            getWorkTypes();
        }
    }, [visibleLogoutModal]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(moment());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const onDataChange = (data: any) => {
        if (data && data?.id && data?.user?.id == NySession.getUser()?.id) {
            dispatch(saveUserWorkTimeRegistration(data));
            if (data?.location?.id > 0) {
                setSelectedLocation(data.location);
                form.setFieldsValue({ location: data.location });
            }
            if (data?.workType && data?.isLogged) {
                setSelectedWorkType(data?.workType);
            } else {
                setSelectedWorkType(null);
            }
        }
    };

    const getStatus = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.ATTENDANCE_REGISTRATION.STATUS, {}).then((response: any) => {
            if (response?.status === RESPONSE.OK && response?.data) {
                onDataChange(response.data);
            }
        });
        setLoading(false);
    };

    const getAttendanceBreakType = () => {
        setListDataBreak([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.ATTENDANCE_BREAK.LIST, {
            search: encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }])),
            max: 100,
        }).then((response: any) => {
            if (response?.status === RESPONSE.OK && response?.data?.content?.length > 0) {
                setListDataBreak(response.data.content);
            }
        });
    };

    const getWorkTypes = () => {
        setWorkTypeList([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.WORK_TYPE.LIST, {
            search: encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }])),
            max: 100,
        }).then((response: any) => {
            if (response?.status === RESPONSE.OK && response?.data?.content?.length > 0) {
                setWorkTypeList(response.data.content);
            }
        });
    };

    const formDayString = () => {
        let dateElement = new Date().toLocaleString('HR-hr', { weekday: 'long' });
        dateElement = dateElement.charAt(0).toUpperCase() + dateElement.slice(1);
        return dateElement.split(',')[0];
    };

    const openModal = () => {
        setVisibleLogoutModal(true);
    };
    const closeModal = () => {
        setVisibleLogoutModal(false);
        setListDataBreak([]);
        form.resetFields();
    };

    const registerWorkingTime = async (workType: any) => {
        setLoadingSave(true);
        NyRequestResolver.requestPost(
            isLoggedIn()
                ? CONSTANTS_REQ.ATTENDANCE_REGISTRATION.WORK_CONTINUE
                : CONSTANTS_REQ.ATTENDANCE_REGISTRATION.LOGIN,
            undefined,
            {
                location:
                    selectedLocation?.id > 0
                        ? selectedLocation
                        : workTimeRegistration?.location?.id > 0
                        ? workTimeRegistration.location
                        : null,
                registration: selectedAttendanceRegistrationType,
                workType: workType?.id > 0 ? workType.id : null,
            }
        ).then((result: any) => {
            if (result?.status === RESPONSE.OK) {
                setRefreshStatus((refreshStatus: any) => refreshStatus + 1);
                okNotification(
                    geti18nText('app.default.save.ok'),
                    geti18nText('work.attendance.registration.success.login'),
                    notificationDuration
                );
                closeModal();
            } else {
                getErrorNotificationMessage(result);
            }
            setLoadingSave(false);
        });
    };

    const breakWorkingTime = async (selectedBreakType: any) => {
        setLoadingSave(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.ATTENDANCE_REGISTRATION.LOGOUT, undefined, {
            location:
                selectedLocation?.id > 0
                    ? selectedLocation
                    : workTimeRegistration?.location?.id > 0
                    ? workTimeRegistration.location
                    : null,
            registration: selectedAttendanceRegistrationType,
            type: selectedBreakType?.id > 0 ? selectedBreakType.id : null,
        }).then((result: any) => {
            if (result?.status === RESPONSE.OK) {
                setRefreshStatus((refreshStatus: any) => refreshStatus + 1);
                okNotification(
                    geti18nText('app.default.save.ok'),
                    geti18nText('work.attendance.registration.success.logout'),
                    notificationDuration
                );
                closeModal();
            } else {
                getErrorNotificationMessage(result);
            }
            setLoadingSave(false);
        });
    };

    const timeStyle = {
        padding: 5,
        marginLeft: 5,
        backgroundColor: isDarkTheme ? '#292f36' : '#e9e9f2',
        color: isDarkTheme ? 'white' : 'black',
    };

    const timeView = () => (
        <>
            <Row justify="center" align="middle" style={{ fontSize: 20 }}>
                <Col>
                    <Text
                        style={{
                            padding: 5,
                            textAlign: 'center',
                        }}
                    >
                        <ClockCircleOutlined
                            style={{
                                marginRight: 10,
                            }}
                        />
                        {formDayString() + ', ' + moment(new Date()).format('DD.MM.YYYY.')}
                    </Text>
                </Col>
            </Row>
            <Row justify="center" align="middle" style={{ marginTop: 10, fontSize: 20 }}>
                <Col>
                    <Text style={timeStyle}>{moment(time).format('HH')}</Text>:
                    <Text style={timeStyle}>{moment(time).format('mm')}</Text>:
                    <Text style={timeStyle}>{moment(time).format('ss')}</Text>
                </Col>
            </Row>
        </>
    );

    const getModalForm = () => {
        return (
            <>
                {timeView()}
                <Form form={form} layout="vertical">
                    <Row justify="center" align="middle" gutter={24} style={{ marginTop: 30, fontSize: 20 }}>
                        <Col span={24}>
                            <LocationSearch
                                label={geti18nText('work.attendance.registration.location.select')}
                                name="location"
                                onChange={(value: any) => {
                                    setSelectedLocation(value?.id > 0 ? value : null);
                                }}
                                initialValue={
                                    selectedLocation?.id > 0
                                        ? selectedLocation
                                        : workTimeRegistration?.location?.id > 0
                                        ? workTimeRegistration?.location
                                        : undefined
                                }
                                disabled={loadingSave}
                            />
                        </Col>
                    </Row>
                    {((isLoggedIn() && workTypeList.length > 1) || !isLoggedIn()) && (
                        <Row justify="center" align="middle" gutter={24} style={{ marginTop: 10, fontSize: 20 }}>
                            <Col span={24}>
                                <NyCustomSelectButtons
                                    label={
                                        isLoggedIn()
                                            ? geti18nText('work.attendance.registration.work.type.select.loggedin')
                                            : geti18nText('work.attendance.registration.work.type.select')
                                    }
                                    name="attendanceWorkType"
                                    selectOptions={workTypeList}
                                    required={false}
                                    onChange={(workType: any) => {
                                        registerWorkingTime(workType);
                                        setSelectedWorkType(null);
                                    }}
                                    customStyle={{ width: '100%' }}
                                    buttonSize="large"
                                    selectedButton={selectedWorktype}
                                    loading={loadingSave}
                                />
                            </Col>
                        </Row>
                    )}
                    {isLoggedIn() && listDataBreak.length > 0 && (
                        <Row justify="center" align="middle" gutter={24} style={{ marginTop: 30, fontSize: 20 }}>
                            <Col span={24}>
                                <NyCustomSelectButtons
                                    label={geti18nText('work.attendance.registration.attendance.break.type.select')}
                                    name="attendanceBreakType"
                                    selectOptions={listDataBreak}
                                    required={false}
                                    onChange={(value: any) => {
                                        breakWorkingTime(value);
                                    }}
                                    customStyle={{ width: '100%' }}
                                    buttonSize="large"
                                    loading={loadingSave}
                                />
                            </Col>
                        </Row>
                    )}
                </Form>
            </>
        );
    };

    const getDashboardIcon = (dashboardIcon: boolean = true) => {
        const style: any = dashboardIcon
            ? {
                  borderRadius: '50%',
                  backgroundColor: isLoggedIn() ? '#47ed00' : '#00dcf8',
              }
            : {
                  borderRadius: '50%',
                  backgroundColor: isLoggedIn() ? '#47ed00' : '#00dcf8',
                  float: 'right',
                  marginRight: '25px',
                  marginLeft: '10px',
              };

        const title: any = isLoggedIn()
            ? geti18nText('work.attendance.registration.status.login')
            : geti18nText('work.attendance.registration.status.logout');
        const time: any = workTimeRegistration?.passedTime ?? 0;
        const hours = time > 0 ? Math.floor(workTimeRegistration?.passedTime / 60) : 0;
        const min = time > 0 ? time - hours * 60 : 0;
        const button: any = (
            <Button
                style={style}
                icon={
                    <i
                        className={isLoggedIn() ? 'las la-user-check' : 'las la-clock'}
                        style={{ fontSize: 20, marginTop: '2px' }}
                    ></i>
                }
                onClick={() => {
                    if (dashboardIcon) {
                        openModal();
                    }
                }}
            />
        );
        return (
            <>
                {dashboardIcon ? (
                    <Tooltip title={title}>{button}</Tooltip>
                ) : (
                    <p style={{ float: 'right' }}>
                        {title}
                        {isLoggedIn() && time > 0 ? ': ' + hours + 'h ' + min + 'min ' : ''}
                        {
                            <i
                                className={isLoggedIn() ? 'las la-user-check' : 'las la-clock'}
                                style={{ fontSize: 20, ...style, padding: '3px' }}
                            ></i>
                        }
                    </p>
                )}
            </>
        );
    };

    return (
        <React.Fragment>
            {canCreate() && getDashboardIcon()}
            <Modal
                open={visibleLogoutModal}
                title={
                    <div>
                        {isLoggedIn()
                            ? geti18nText('work.attendance.registration.logout')
                            : geti18nText('work.attendance.registration.login')}
                        {getDashboardIcon(false)}
                    </div>
                }
                onCancel={closeModal}
                forceRender={true}
                maskClosable={false}
                footer={null}
            >
                {error ? <Empty /> : !loading && !loadingData ? getModalForm() : <NySpinner />}
            </Modal>
        </React.Fragment>
    );
};

export default WorkAttendanceRegistration;
