import { BookOutlined, EllipsisOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyInputNumber,
    NyRequestResolver,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Collapse, Dropdown, Form, Input, Menu, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import NyErrorLogModal from '../../../../../components/error-log-modal';
import ImportCSVModal from '../../../../../components/import-csv-modal';
import NyNoteModal from '../../../../../components/note-modal';
import NyReportButton from '../../../../../components/report-button';
import useEnum from '../../../../../hooks/useEnum';
import { updateHistoryI18n } from '../../../../../slices/historySlice';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, getDateFormat, okNotification, setDateFormat } from '../../../../../utils/Utils';
import BookModal from './BookModal';
import DownloadByType from './DownloadByType';
import AssetInitialStateItemsIndex from './asset-initial-state-items';
import AssetInitialStateItemsActionIndex from './asset-initial-state-items/actionIndex';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';

const { Panel } = Collapse;

const AssetInitialStateEdit = (props: any) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isFormChange, setIsFormChange] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const { id } = useParams<any>();
    const location = useLocation();
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [note, setNote] = useState<any>(undefined);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [booked, setBooked] = useState(false);
    const [bookedGeneralLedger, setBookedGeneralLedger] = useState(false);
    const [title, setTitle] = useState('');
    const [showNewButton, setShowNewButton] = useState(null);
    const [errorLogModalData, setErrorLogModalData] = useState(undefined);
    const [errorLogModalVisible, setErrorLogModalVisible] = useState(false);
    const [errorLogModalTitle, setErrorLogModalTitle] = useState<any>('');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const focusInput = useRef<any>(null);
    const EnSubreportType = useEnum('SUBREPORT_TYPE');

    useEffect(() => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    }, []);

    // useEffect(() => {
    //     dispatch(setIsDirtyTab({ path: location.pathname, isDirty: isFormChange }));
    // }, [isFormChange]);

    useEffect(() => {
        if (dataForm) {
            setCollapseActiveKeys(['1', '2']);
        }
    }, [dataForm]);

    useEffect(() => {
        if (title !== '') {
            dispatch(updateHistoryI18n({ path: location.pathname, args: [title] }));
        }
    }, [title]);

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    const canCreate = () => {
        return FixedAssetRights.canCreateAssetInitialState();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setIsEdit(dataForm.id ? true : false);
        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('date')) {
            setTitle(dataForm.ord + '/' + moment(dataForm.date).format('YYYY'));
        }
        if (dataForm.booked) {
            setBooked(true);
            setShowNewButton(null);
        } else {
            setBooked(false);
            setShowNewButton(geti18nText('asset.acquisition.file.table.add'));
        }
        setBookedGeneralLedger(dataForm.bookedGeneralLedger ? true : false);
        if (dataForm.date) {
            dataForm.date = setDateFormat(dataForm.date);
        }
        setNote(dataForm.note ? dataForm.note : undefined);
        delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2']);
        }
        setCollapseAll(!collapseAll);
    };

    const book = (instance: any) => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_INITIAL_STATE.BOOK + '/' + dataForm, undefined, {
                id: dataForm,
                instance: instance,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setBooked(true);
                    setShowNewButton(null);
                    okNotification(geti18nText('asset.acquisition.button.book.success'));
                } else if (result && result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const debook = (instance: any) => {
        if (dataForm) {
            setLoading(true);
            NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_INITIAL_STATE.DE_BOOK + '/' + dataForm, undefined, {
                id: dataForm,
                instance: instance,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setBooked(false);
                    setShowNewButton(geti18nText('asset.acquisition.file.table.add'));
                    okNotification(geti18nText('asset.acquisition.button.debook.success'));
                } else if (result && result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
                setLoading(false);
            });
        }
    };

    const actionsMenu = () => {
        const data = new FormData();
        data.append('assetInitialStateId', id);
        return (
            <Menu>
                {canCreate() && !booked && !bookedGeneralLedger && (
                    <div style={{ padding: '0px', textAlign: 'left' }}>
                        <ImportCSVModal
                            url={CONSTANTS_REQ.ASSET_INITIAL_STATE.IMPORT_CSV}
                            disabled={loading}
                            data={data}
                            customInfoMessage={geti18nText('assetImportCSV.upload.info')}
                            showSaveToNotBooked
                        />
                    </div>
                )}

                {canCreate() && !booked && !bookedGeneralLedger && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <BookModal
                            dataForm={dataForm}
                            title={geti18nText('asset.acquisition.button.booking')}
                            icon={<BookOutlined />}
                            onConfirm={book}
                        />
                    </div>
                )}
                {canCreate() && booked && !bookedGeneralLedger && (
                    <div style={{ display: 'block', margin: '5px' }}>
                        <BookModal
                            dataForm={dataForm}
                            title={geti18nText('asset.acquisition.button.deBooking')}
                            icon={<BookOutlined />}
                            onConfirm={debook}
                        />
                    </div>
                )}
                <div style={{ display: 'block', margin: '5px' }}>
                    <NyReportButton
                        style={{ width: '100%' }}
                        url={CONSTANTS_REQ.REPORT.ASSET_INITIAL_STATE}
                        customParms={{ assetInitialStateId: dataForm }}
                        buttoni18nText={'app.default.button.download.pdf'}
                        fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.ASSET_INITIAL_STATE)}
                        allowExportTypeChange={true}
                    />
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <DownloadByType title={geti18nText('asset.initialState.downloadByType')} assetInitialStateId={id} />
                </div>
            </Menu>
        );
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                    {!collapseAll ? (
                        <EyeOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    ) : (
                        <EyeInvisibleOutlined
                            onClick={changeCollapseVisibility}
                            className="ny-custom-button"
                            style={{ marginRight: '20px' }}
                        />
                    )}
                </Tooltip>
                {dataForm && (
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                )}
            </div>
        </React.Fragment>
    );

    const changeId = (returnedId: any) => {
        if (returnedId == null || returnedId == undefined || returnedId < 0) {
            returnedId = 'create';
        }
        const pathname = CONSTANTS_REQ.ASSET_INITIAL_STATE.PATHNAME + returnedId;
        // dispatch(addTab({ title: 'asset.initialState.edit.title', addedData: returnedId, path: pathname }));
        history.push(pathname);
    };

    useEffect(() => {
        setRefresh((prevValue: any) => prevValue + 1);
    }, [id]);

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.ASSET_INITIAL_STATE.EDIT}
                setValues={setValues}
                width={1200}
                fetchWhenChange={refresh || id}
                form={form}
                goBack={() => {
                    //     dispatch(removeTab(location.pathname));
                    setIsFormChange(false);
                    history.goBack();
                }}
                onSaveAndGetID={(returnedId: any) => {
                    changeId(returnedId);
                    setDataForm(returnedId);
                    if (id === 'create') {
                        // dispatch(removeTab(location.pathname));
                        changeId(returnedId);
                    } else {
                        // dispatch(setIsDirtyTab({ path: location.pathname, isDirty: false }));
                    }
                }}
                paramsId={id}
                buttonsClassName="buttons-sticky"
                changeCollapseVisibility={changeCollapseVisibility}
                customFooterContent={getCustomFooterContent}
                checkIsFormChanged={true}
                setIsFormChange={setIsFormChange}
                hideSubmitButton={!canCreate() || booked}
                hideActivationButtons={!canCreate() || booked}
                shortcuts={true}
                {...props}
                normalize={(values: any) => {
                    if (values.date) values.date = getDateFormat(values.date);
                    values.booked = booked ? true : false;
                    values.bookedGeneralLedger = bookedGeneralLedger ? true : false;
                    return values;
                }}
            >
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel
                                header={geti18nText('asset.initialState.tab.1')}
                                key="1"
                                extra={
                                    <NyNoteModal
                                        note={note}
                                        modalTitle={
                                            geti18nText('asset.acquisition.edit.title') +
                                            ' - ' +
                                            geti18nText('app.default.note')
                                        }
                                    />
                                }
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item name="id" style={{ display: 'none' }}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item label={geti18nText('asset.acquisition.edit.ord')} name="ord">
                                                    <NyInputNumber
                                                        style={{ width: '100%' }}
                                                        nyTestId="ord"
                                                        disabled={!canCreate() || booked || !isEdit}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('asset.acquisition.edit.document')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                            whitespace: true,
                                                        },
                                                    ]}
                                                    name="documentInfo"
                                                >
                                                    <Input disabled={!canCreate() || booked} ref={focusInput} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label={geti18nText('warehouseReceipt.edit.date')}
                                                    name="date"
                                                    initialValue={moment()}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NyDatePicker
                                                        style={{ width: '100%' }}
                                                        nyTestId="date"
                                                        disabled={!canCreate() || booked}
                                                        mustGetPopupContainer={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {isEdit && (
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item name="booked" valuePropName={'checked'} initialValue={false}>
                                                <strong>{geti18nText('asset.acquisition.edit.booked') + ': '}</strong>
                                                <strong>
                                                    {booked
                                                        ? geti18nText('app.default.button.yes')
                                                        : geti18nText('app.default.button.no')}
                                                </strong>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="bookedGeneralLedger"
                                                valuePropName={'checked'}
                                                initialValue={false}
                                            >
                                                <strong>
                                                    {geti18nText('asset.acquisition.edit.bookedGeneralLedger') + ': '}
                                                </strong>
                                                <strong>
                                                    {bookedGeneralLedger
                                                        ? geti18nText('app.default.button.yes')
                                                        : geti18nText('app.default.button.no')}
                                                </strong>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                            activeKey={collapseActiveKeys}
                            onChange={(key: any) => {
                                setCollapseActiveKeys(key);
                            }}
                        >
                            <Panel header={geti18nText('asset.acquisition.divider.2')} key="2" disabled={!dataForm}>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        {!booked && !bookedGeneralLedger ? (
                                            <AssetInitialStateItemsActionIndex
                                                assetInitialStateId={dataForm}
                                                canCreate={canCreate()}
                                                booked={booked}
                                                bookedGeneralLedger={bookedGeneralLedger}
                                                scroll={{ y: 250, x: 800 }}
                                            />
                                        ) : (
                                            <AssetInitialStateItemsIndex
                                                assetInitialStateId={dataForm}
                                                canCreate={canCreate()}
                                                booked={booked}
                                                bookedGeneralLedger={bookedGeneralLedger}
                                                scroll={{ y: 250, x: 800 }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <NyErrorLogModal
                    modalVisible={errorLogModalVisible}
                    setModalVisible={setErrorLogModalVisible}
                    title={errorLogModalTitle}
                    errorData={errorLogModalData}
                    setErrorData={setErrorLogModalData}
                />
            </NyDataEdit>
            <Prompt message={''} when={isFormChange} />
        </>
    );
};
export default AssetInitialStateEdit;
