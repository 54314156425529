import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { geti18nText, NyColorPicker, NyInputNumber, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Input, notification, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { errorNotification, okNotification } from '../../../../utils/Utils';

const { Panel } = Collapse;
const EmailSettingsIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataForm, setDataForm] = useState<any>(undefined);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1', '3']);
    const [rightCollapseActiveKeys, setRightCollapseActiveKeys] = useState<any>(['2']);
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const [emailTest, setEmailTest] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});

    useEffect(() => {
        fetch();
    }, []);

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'EMAIL' }];
    };

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    setDefaultData(result.data);
                }
            }
        });
    };

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setDataForm(dataForm);

        dataForm.forEach((element: any) => {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 3) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            }
        });

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        let val: any = value;
                        if (val.constructor.name === 'Object') {
                            if (val.id >= 0) {
                                params.push({ id: item.id, value: val.id });
                            } else {
                                params.push({ id: item.id, value: '' });
                            }
                        } else {
                            if (item.dataType === 3) {
                                val = val ? '1' : '0';
                            }
                            params.push({ id: item.id, value: val ? String(val) : '' });
                        }
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '' });
                        }
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const testMail = () => {
        form.validateFields().then((values: any) => {
            setEmailTest(true);
            let props: any = {};
            props['host'] = values['HOST'];
            props['port'] = values['PORT'];
            props['username'] = values['USERNAME'];
            props['password'] = values['PASSWORD'];
            props['sender'] = values['SENDER'];
            props['smtpAuth'] = values['SMTP_AUTH'];
            props['startTls'] = values['START_TLS'];
            props['socketFactoryPort'] = values['SOCKET_FACTORY_PORT'];
            props['socketFactoryFallback'] = values['SOCKET_FACTORY_FALLBACK'];
            props['socketFactoryClass'] = values['SOCKET_FACTORY_CLASS'];
            props['sslConnection'] = values['SSL_CONNECTION'];
            NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.TETS_MAIL, undefined, props).then(
                (result: any) => {
                    setEmailTest(false);
                    if (result.status === RESPONSE.OK) {
                        okNotification();
                    } else {
                        errorNotification();
                    }
                    setLoading(false);
                }
            );
        });
    };

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);
        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['emailSettings'] = isChanged;
        setDirty(tmpDirty);
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setLeftCollapseActiveKeys([]);
            setRightCollapseActiveKeys([]);
        } else {
            setLeftCollapseActiveKeys(['1', '3']);
            setRightCollapseActiveKeys(['2']);
        }

        setCollapseAll(!collapseAll);
    };

    const getCustomFooterContent = (
        <div style={{ float: 'left' }}>
            <Tooltip placement="top" visible={showTooltip} title={geti18nText('app.default.shortcuts.collapse')}>
                {!collapseAll ? (
                    <EyeOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                ) : (
                    <EyeInvisibleOutlined onClick={changeCollapseVisibility} className="ny-custom-button" />
                )}
            </Tooltip>
            {canCreate() && (
                <Button type="default" onClick={testMail} style={{ marginLeft: '15px' }}>
                    {geti18nText('app.setting.mail.btn.test')}
                </Button>
            )}
        </div>
    );

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.mail.collapse.generalData')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.host')}
                                        name="HOST"
                                    >
                                        <Input disabled={emailTest} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.port')}
                                        name="PORT"
                                    >
                                        <NyInputNumber
                                            style={{ width: '100%' }}
                                            isDecimal={false}
                                            disabled={emailTest}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('app.setting.mail.username')} name="USERNAME">
                                        <Input disabled={emailTest} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('app.setting.mail.password')} name="PASSWORD">
                                        <Input type="password" disabled={emailTest} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.sender')}
                                        name="SENDER"
                                    >
                                        <Input disabled={emailTest} type="email" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('app.setting.mail.collapse.templateData')} key="3">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.title')}
                                        name="TITLE"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.logoPath')}
                                        name="LOGO_PATH"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.footerName')}
                                        name="FOOTER_NAME"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        label={geti18nText('app.setting.mail.footerDomain')}
                                        name="FOOTER_DOMAIN"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('app.setting.mail.footerColor')} name="HEADER_COLOR">
                                        <NyColorPicker popover={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('app.setting.mail.headerColor')} name="FOOTER_COLOR">
                                        <NyColorPicker popover={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Collapse
                        activeKey={rightCollapseActiveKeys}
                        onChange={(key: any) => {
                            setRightCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.mail.collapse.advancedData')} key="2">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.mail.smtp_auth')}
                                        name="SMTP_AUTH"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.mail.starttls')}
                                        name="START_TLS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.mail.ssl')}
                                        name="SSL_CONNECTION"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.mail.socket_factor_fallback')}
                                        name="SOCKET_FACTORY_FALLBACK"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.mail.socket_factor_port')}
                                        name="SOCKET_FACTORY_PORT"
                                    >
                                        <NyInputNumber
                                            style={{ width: '100%' }}
                                            isDecimal={false}
                                            disabled={emailTest}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('app.setting.mail.socket_factor_class')}
                                        name="SOCKET_FACTORY_CLASS"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {getCustomFooterContent}
                    {canCreate() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default EmailSettingsIndex;
