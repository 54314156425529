import { useState } from 'react';
import { Col, Row, Modal, Form, Input, Button } from 'antd';
import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { customAssetRenderOption, errorNotification, okNotification, onKeyDownTextAreaCustom } from '../../utils/Utils';
import { refreshItemList } from '../../slices/selectedItemSlice';

const { TextArea } = Input;

const EmployeeAssetProblemNotification = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const { employeeId } = useSelector((state: RootState) => state.attendance);

    const saveNotification = () => {
        form.validateFields().then((values: any) => {
            let parms = { assetId: values.asset.id, statusDescription: values.statusDescription };

            NyRequestResolver.requestPut(
                CONSTANTS_REQ.ASSET.REPORT_PROBLEM + '/' + values.asset.id,
                undefined,
                parms
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    dispatch(refreshItemList());
                    okNotification();
                    form.resetFields();
                    setVisible(false);
                } else {
                    errorNotification(result.data.error);
                }
            });
        });
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Button type="primary" onClick={() => setVisible(true)}>
                {geti18nText('asset.report.problem')}
            </Button>
            {visible && (
                <Modal
                    visible={visible}
                    title={geti18nText('asset.report.problem.title')}
                    maskClosable={false}
                    okText={geti18nText('app.default.button.save')}
                    onOk={(event: any) => {
                        saveNotification();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    onCancel={(event: any) => {
                        setVisible(false);
                        form.resetFields();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <Form form={form} layout="vertical" {...{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item
                                    label={geti18nText('asset.edit.assetModel')}
                                    name={'asset'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        style={{ width: '100%' }}
                                        className={''}
                                        url={CONSTANTS_REQ.ASSET.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'label',
                                            text: 'text',
                                            name: 'name',
                                            serialNumber: 'serialNumber',
                                        }}
                                        renderOption={customAssetRenderOption}
                                        searchBy="assetModel.name || serialNumber || inventoryNumber"
                                        addedFilter={[
                                            { field: 'availabilityStatus', condition: 'equals', value: 2 },
                                            {
                                                field: 'employeeId',
                                                condition: 'equals',
                                                value: employeeId,
                                            },
                                        ]}
                                        order="assetModel.name"
                                        customListWidth={'1200px'}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col offset={1} span={22}>
                                <Form.Item
                                    label={geti18nText('asset.report.problem.statusDescription')}
                                    name={'statusDescription'}
                                    labelAlign={'left'}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <TextArea onKeyDown={onKeyDownTextAreaCustom} rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default EmployeeAssetProblemNotification;
