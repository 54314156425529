import { geti18nText, NyDatePicker, NyInputNumber, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import { errorNotification, formatJoppdCodeSearch, okNotification } from '../../../../../utils/Utils';

const MealsGeneralSettingsIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();

    const [dataForm, setDataForm] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState([]);
    const [changedData, setChangedData] = useState({});

    const timeFormat = 'HH:mm';

    const checkIsDirty = (value: any) => {
        let _changedData = NyUtils.mergeObject(changedData, value);
        setChangedData(_changedData);

        let isChanged = NyUtils.dataHasChanged(_changedData, defaultData);
        let tmpDirty = { ...dirty };
        tmpDirty['travelWarrantSettings'] = isChanged;
        if (setDirty) setDirty(tmpDirty);
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'MEALS_ORDER' }];
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    setDefaultData(result.data);
                }
            }
        });
    };

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setDataForm(dataForm);

        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 3) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            } else if (element.dataType === 8 && newDataForm[element.settingKey] != undefined) {
                newDataForm[element.settingKey] = JSON.parse(newDataForm[element.settingKey]);
            } else if (element.dataType === 9) {
                let defFilter = JSON.parse(element.defaultFilter);
                newDataForm[element.settingKey] = await formatJoppdCodeSearch(
                    newDataForm[element.settingKey],
                    defFilter.page,
                    defFilter.node
                );
            } else if (element.dataType === 5) {
                if (element.defaultValue) {
                    newDataForm[element.settingKey] = moment(element.defaultValue, timeFormat);
                }
                if (element.value) {
                    newDataForm[element.settingKey] = moment(element.value, timeFormat);
                }
            }
        }

        form.setFieldsValue(newDataForm);
    }

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && item != undefined) {
                        let val: any = value;
                        if (item.dataType === 3) {
                            val = val ? '1' : '0';
                        } else if (item.dataType === 9) {
                            val = val.code;
                        } else if (item.dataType === 8) {
                            val = JSON.stringify(val);
                        } else if (item.dataType === 5) {
                            val = val.format('HH:mm');
                        }
                        params.push({ id: item.id, value: val ? String(val) : undefined });
                    }
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        if (setDirty) setDirty(false);
                        okNotification();
                    } else {
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onValuesChange={(value: any) => {
                checkIsDirty(value);
            }}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
            style={{ margin: '1rem' }}
        >
            <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText(
                                    'app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_ORDER_ENTRY_TIME'
                                )}
                                name="MEALS_ORDER_ENTRY_TIME"
                            >
                                <NyDatePicker
                                    defaultPickerValue={null}
                                    mode="time"
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText(
                                    'app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_ORDER_TODAY_ENTRY_ALLOW'
                                )}
                                name="MEALS_ORDER_TODAY_ENTRY_ALLOW"
                                valuePropName={'checked'}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_SUBVENTION')}
                                name="MEALS_SUBVENTION"
                            >
                                <NyInputNumber isDecimal={false} style={{ width: '100%' }} min={0} max={100} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.ORDER_ON_HOLIDAY')}
                                name="ORDER_ON_HOLIDAY"
                                valuePropName={'checked'}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText(
                                    'app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_ORDER_CANCELLATION_TIME'
                                )}
                                name="MEALS_ORDER_CANCELLATION_TIME"
                            >
                                <NyDatePicker
                                    defaultPickerValue={null}
                                    mode="time"
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText(
                                    'app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_ORDER_SEND_RECAPITULATION_TIME'
                                )}
                                name="MEALS_ORDER_SEND_RECAPITULATION_TIME"
                            >
                                <NyDatePicker
                                    defaultPickerValue={null}
                                    mode="time"
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText(
                                    'app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_ORDER_SEND_SPECIFICATION_TIME'
                                )}
                                name="MEALS_ORDER_SEND_SPECIFICATION_TIME"
                            >
                                <NyDatePicker
                                    defaultPickerValue={null}
                                    mode="time"
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText(
                                    'app.setting.name.SYSTEM_SETTINGS.MEALS_ORDER.MEALS_ORDER_EXECUTION_TRANSFER_FUNDS_TIME'
                                )}
                                name="MEALS_ORDER_EXECUTION_TRANSFER_FUNDS_TIME"
                            >
                                <NyDatePicker
                                    defaultPickerValue={null}
                                    mode="time"
                                    format="HH:mm"
                                    style={{ width: '100%' }}
                                    mustGetPopupContainer={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {canCreate() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};
export default MealsGeneralSettingsIndex;
