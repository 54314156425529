import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    getEmployeeSelectOption,
    getSearchFormat,
    getWorkplaceSelectOption,
} from '../../../../utils/Utils';
import EmployeeIndex from '../employee';
import WorkplaceSearch from './search';

const DetailEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('workplaceDetails.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    useEffect(() => {
        if (props?.addedData?.workplace?.id) {
            const selectedWorkplace: any = getWorkplaceSelectOption(props?.addedData?.workplace);
            form.setFieldsValue({ workplace: selectedWorkplace });
        }
    }, [props]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.id) {
            setEditHeader(geti18nText('workplaceDetails.edit.title') + ' - ' + dataForm.id);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        if (dataForm.workplace) {
            dataForm.workplace = getWorkplaceSelectOption(dataForm.workplace);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setEditHeader(geti18nText('workplaceDetails.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            onModalOpen={onModalOpen}
            url={CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT}
            setValues={setValues}
            width={450}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            checkIsFormChanged={true}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            normalize={(values: any) => {
                if (values.employee) {
                    values.employee = getSearchFormat(values.employee);
                }
                if (values.workplace) {
                    values.workplace = getSearchFormat(values.workplace);
                }
                return values;
            }}
        >
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Row gutter={24}>
                <Col span={24}>
                    <WorkplaceSearch required={true} disabled={props?.editProps?.disabled} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('workplaceDetails.edit.employee')} name="employee">
                        <NySearchField
                            url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                            map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                            searchBy="person.first_name || ' ' || person.last_name"
                            itemName={[
                                ['person', 'firstName'],
                                ['person', 'lastName'],
                            ]}
                            renderOption={customEmployeeRenderOption}
                            order="person.last_name, person.first_name"
                            customItemNameLabel={'firstName lastName'}
                            SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="workplace-detail-edit" />}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default DetailEdit;
