import { AdministrationRights } from '../../rights/administrationRights';
import AccessRightIndex from './views/access-right';
import ApplicationSettingsIndex from './views/application-settings';
import BankIndex from './views/bank';
import CodebookInitializationIndex from './views/codebook-initialization';
import CompanyIndex from './views/company';
import CompanyEdit from './views/company/edit';
import CountryIndex from './views/country';
import CountyIndex from './views/county';
import CurrencyIndex from './views/currency';
import ExchangeRateIndex from './views/exchange-rate';
import HolidayIndex from './views/holiday';
import ItemIndex from './views/item';
import ItemGroupIndex from './views/item-group';
import LinksIndex from './views/links';
import LogsIndex from './views/log';
import MeasureUnitIndex from './views/measure-unit';
import MessageIndex from './views/message';
import MessageAccessRightIndex from './views/message-access-right';
import NotificationSettingsIndex from './views/notification-settings';
import NotificationOverviewIndex from './views/notifications-overview';
import OrderViewIndex from './views/order-view';
import OrderViewEdit from './views/order-view/edit';
import PostOfficeIndex from './views/post-office';
import SettlementIndex from './views/settlement';
import SettlementDistrictIndex from './views/settlement-district';
import TownshipIndex from './views/township';
import TownshipDistrictIndex from './views/township-district';
import AuthUsersIndex from './views/users';

export const AdministrationModuleRoutes: any = [
    {
        path: '/administration',
        exact: true,
        component: () => <></>,
        role: AdministrationRights.canViewAccessRightsCompanyRoutes(),
    },
    {
        path: '/administration/access-right',
        exact: true,
        component: AccessRightIndex,
        role: AdministrationRights.canViewAccessRightsRoutes(),
    },
    {
        path: '/administration/codebooks/company',
        exact: true,
        component: CompanyIndex,
        role: AdministrationRights.canViewCodebooksCompanyRoutes(),
    },
    {
        path: '/administration/codebooks/company/:id',
        exact: true,
        component: CompanyEdit,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/township',
        exact: true,
        component: TownshipIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/currency',
        exact: true,
        component: CurrencyIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/county',
        exact: true,
        component: CountyIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/country',
        exact: true,
        component: CountryIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/bank',
        exact: true,
        component: BankIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/exchange-rate',
        exact: true,
        component: ExchangeRateIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/holiday',
        exact: true,
        component: HolidayIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/post-office',
        exact: true,
        component: PostOfficeIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/settlement',
        exact: true,
        component: SettlementIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/settlement-district',
        exact: true,
        component: SettlementDistrictIndex,
        role: AdministrationRights.canViewSettlementRoutes(),
    },
    {
        path: '/administration/codebooks/township-district',
        exact: true,
        component: TownshipDistrictIndex,
        role: AdministrationRights.canViewSettlementRoutes(),
    },
    {
        path: '/administration/order-view',
        exact: true,
        component: OrderViewIndex,
        role: AdministrationRights.canViewOrderViewRoutes(),
    },
    {
        path: '/administration/order-view/:id',
        exact: true,
        component: OrderViewEdit,
        role: AdministrationRights.canViewOrderViewRoutes(),
    },
    {
        path: '/administration/application-settings',
        exact: true,
        component: ApplicationSettingsIndex,
        role: AdministrationRights.canViewAppSettingsRoutes(),
    },
    {
        path: '/administration/codebooks/item-group',
        exact: true,
        component: ItemGroupIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/item',
        exact: true,
        component: ItemIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/codebooks/measure-unit',
        exact: true,
        component: MeasureUnitIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/notification-settings',
        exact: true,
        component: NotificationSettingsIndex,
        role: AdministrationRights.canViewNotificationRoutes(),
    },
    {
        path: '/administration/codebooks/links',
        exact: true,
        component: LinksIndex,
        role: AdministrationRights.canViewCodebooksRoutes(),
    },
    {
        path: '/administration/logs',
        exact: true,
        component: LogsIndex,
        role: AdministrationRights.canViewLogsMenu(),
    },
    {
        path: '/administration/notifications-overview',
        exact: true,
        component: NotificationOverviewIndex,
        role: AdministrationRights.canViewNotifMenu(),
    },
    {
        path: '/administration/message-access-right',
        exact: true,
        component: MessageAccessRightIndex,
        role: AdministrationRights.canViewMessageMenu(),
    },
    {
        path: '/administration/message',
        exact: true,
        component: MessageIndex,
        role: AdministrationRights.canViewMessageMenu(),
    },
    {
        path: '/administration/codebook-initialization',
        exact: true,
        component: CodebookInitializationIndex,
        role: AdministrationRights.canViewSuperAdminMenu(),
    },
    {
        path: '/administration/users',
        exact: true,
        component: AuthUsersIndex,
        role: AdministrationRights.canViewAuthUsersMenu(),
    },
];
