import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';
import { Form } from 'antd';
import BankIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import BankEdit from './edit';
import TravelEventNumberEdit from './edit';
import TravelEventNumberIndex from '.';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';

const TravelEventNumberSearch = ({
    label = geti18nText('travelWarrant.edit.travelEventNumber'),
    name = 'travelEventNumber',
    required = false,
    onChange = null,
    disabled = false,
    formStyle = undefined,
    tooltip = undefined,
}: any) => {
    const canCreate = () => {
        return TravelWarrantRights.canCreateTravelWarrantCodebooks();
    };

    const travelEventNumber = useApplicationSetting('TRAVEL_WARRANT_SHOW_EVENT_NUMBER');

    return travelEventNumber == '1' ? (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
            style={formStyle}
            tooltip={tooltip}
        >
            <NySearchField
                url={CONSTANTS_REQ.TRAVEL_EVENT_NUMBER.SEARCH}
                disabled={disabled}
                onChange={onChange}
                map={{ id: 'id', label: 'name', code: 'code', text: 'name' }}
                searchBy="name || code"
                AddNewModalComponent={canCreate() ? TravelEventNumberEdit : null}
                SearchPopupComponent={<TravelEventNumberIndex readonly={!canCreate()} />}
                autoFocus={false}
                itemName={[['name'], ['code']]}
                customItemNameLabel={'name (code)'}
                nyTestId="travelEventNumber"
                renderOption={(option: any) => `${option.text}${option.code ? ' (' + option.code + ')' : ''}`}
            />
        </Form.Item>
    ) : null;
};

export default TravelEventNumberSearch;
