import { geti18nText, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Button, Checkbox, Col, Collapse, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    formatJoppdCodeSearch,
    getEnumArray,
    getEnumFormat,
    okNotification,
} from '../../../../utils/Utils';

const { Panel } = Collapse;

const AssetSettingsIndex = ({ setDirty, dirty, canCreate }: any) => {
    const [form] = Form.useForm();
    const [dataForm, setDataForm] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['1']);
    const [defaultData, setDefaultData] = useState([]);
    const [netBookRateId, setNetBookRateId] = useState<any>(undefined);

    const save = (event: any) => {
        event.preventDefault();
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];
                for (const [key, value] of Object.entries(values)) {
                    const item = dataForm.find((item: any) => item.settingKey === key);
                    if (value != undefined && value != null && item?.id != undefined) {
                        let val: any = value;
                        if (item.dataType === 3) {
                            val = val ? '1' : '0';
                        } else if (item.dataType === 7) {
                            val = getEnumFormat(val);
                        } else if (item.dataType === 8) {
                            val = JSON.stringify(val);
                        } else if (item.dataType === 9) {
                            val = val.code;
                        }
                        params.push({
                            id: item.id,
                            value: val ? String(val) : '',
                            settingsKey: item.settingsKey,
                        });
                    } else {
                        if (item?.id) {
                            params.push({ id: item.id, value: '', settingsKey: item.settingsKey });
                        }
                    }
                }
                NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.SAVE, undefined, {
                    values: params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        if (setLoading) setLoading(false);
                        setDirty(false);
                        okNotification();
                    } else {
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'ASSET_DEPRECIATION' }];
    };

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data);
                    setDefaultData(result.data);
                }
            }
        });
    };

    const formatNetBookRateSearch = async function (id: any) {
        let temp;
        let array = getEnumArray('DEPRECIATION_NET_BOOK_RATE');
        const element: any = array.find((obj: any) => obj.id == id);

        if (element) {
            temp = { id: element['id'], label: element['text'], text: element['text'] };
            return temp;
        }
    };

    async function setValues(dataForm: any) {
        let newDataForm: any = {};
        setDataForm(dataForm);

        for (const element of dataForm) {
            newDataForm[element.settingKey] =
                element.value !== null && element.value !== undefined ? element.value : element.defaultValue;
            if (element.dataType === 3) {
                newDataForm[element.settingKey] = newDataForm[element.settingKey] === '1' ? true : false;
            } else if (element.dataType == 7) {
                newDataForm[element.settingKey] = Number(newDataForm[element.settingKey]);
            } else if (element.dataType === 8 && newDataForm[element.settingKey] != undefined) {
                newDataForm[element.settingKey] = JSON.parse(newDataForm[element.settingKey]);
            } else if (element.dataType === 9) {
                let defFilter = JSON.parse(element.defaultFilter);
                newDataForm[element.settingKey] = await formatJoppdCodeSearch(
                    newDataForm[element.settingKey],
                    defFilter.page,
                    defFilter.node
                );
            }
        }

        const netBookRateId = dataForm.find(
            (item: any) => item.settingKey === 'DEFAULT_DEPRECIATION_NET_BOOK_RATE'
        )?.value;
        if (netBookRateId) {
            newDataForm.DEFAULT_DEPRECIATION_NET_BOOK_RATE = await formatNetBookRateSearch(netBookRateId);
            setNetBookRateId(newDataForm.DEFAULT_DEPRECIATION_NET_BOOK_RATE);
        }

        form.setFieldsValue(newDataForm);
    }

    return (
        <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={(value: any) => {
                //checkIsDirty(value);
            }}
            form={form}
            onFinishFailed={({ errorFields }) => {
                form.scrollToField(errorFields[0].name);
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Collapse
                        activeKey={leftCollapseActiveKeys}
                        onChange={(key: any) => {
                            setLeftCollapseActiveKeys(key);
                        }}
                    >
                        <Panel header={geti18nText('app.setting.tab.asset.panel.1')} key="1">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_ASSET.ASSET_DEPRECIATION.ASSET_DEPRECIATION_STARTS_WITH_ACQUISITION'
                                        )}
                                        name="ASSET_DEPRECIATION_STARTS_WITH_ACQUISITION"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_ASSET.ASSET_DEPRECIATION.AUTO_ASSIGN_INVENTORY_NUMBER'
                                        )}
                                        name="AUTO_ASSIGN_INVENTORY_NUMBER"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_ASSET.ASSET_DEPRECIATION.ENABLE_DEPRECIATION_INTERNAL_RATE'
                                        )}
                                        name="ENABLE_DEPRECIATION_INTERNAL_RATE"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_ASSET.ASSET_DEPRECIATION.DEFAULT_DEPRECIATION_NET_BOOK_RATE'
                                        )}
                                        name="DEFAULT_DEPRECIATION_NET_BOOK_RATE"
                                    >
                                        <NySearchField
                                            options={getEnumArray('DEPRECIATION_NET_BOOK_RATE')}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy={'text'}
                                            className=""
                                            mustGetPopupContainer={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_ASSET.ASSET_DEPRECIATION.BOOK_ALL_UNBOOKED_ASSET_ACQUISITIONS'
                                        )}
                                        name="BOOK_ALL_UNBOOKED_ASSET_ACQUISITIONS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText(
                                            'app.setting.name.NY_ERP_ASSET.ASSET_DEPRECIATION.BOOK_ALL_UNBOOKED_ASSET_WRITEOFFS'
                                        )}
                                        name="BOOK_ALL_UNBOOKED_ASSET_WRITEOFFS"
                                        valuePropName={'checked'}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            <Row className={'buttons-sticky'}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    {/* {getCustomFooterContent} */}
                    {canCreate() && (
                        <Button
                            style={{ marginRight: '1em' }}
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={(e) => save(e)}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default AssetSettingsIndex;
