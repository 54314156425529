import {
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    getColumnTimeOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddAttendance from './AddAttendance';
import AttendanceEdit from './edit';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getDateFormat, getEnumArray } from '../../../../utils/Utils';
import { AdministrationRights } from '../../../../rights/administrationRights';

const AttendanceIndex = ({ scroll = DEFAULT_TABLE_SCROLL, sortOrder }: any) => {
    const location = useLocation();
    const { settings } = useSelector((state: RootState) => state.notification);
    const table = useTableSettings();

    useHelper('human/attendance');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceOrAdmin();
    };

    const initialAdminColumns = [
        {
            title: geti18nText('attendance.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.table.column.oib'),
            dataIndex: ['person', 'oib'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('attendance.table.column.from'),
            dataIndex: 'from',
            render: (from: any) => {
                if (from) {
                    return getDateFormat(from, 'HH:mm');
                }
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            title: geti18nText('attendance.table.column.to'),
            dataIndex: 'to',
            render: (to: any) => {
                if (to) {
                    return getDateFormat(to, 'HH:mm');
                }
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            title: geti18nText('attendance.table.column.fromReal'),
            dataIndex: 'fromReal',
            render: (fromReal: any) => {
                if (fromReal) {
                    return getDateFormat(fromReal, 'HH:mm');
                }
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            title: geti18nText('attendance.table.column.toReal'),
            dataIndex: 'toReal',
            render: (toReal: any) => {
                if (toReal) {
                    return getDateFormat(toReal, 'HH:mm');
                }
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            title: geti18nText('attendance.table.column.hours'),
            dataIndex: 'hours',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.ATTENDANCE_TYPE.' + record.type);
                }
            },
            ...getColumnSearchCheckbox(getEnumArray('ATTENDANCE_TYPE'), 'in', undefined),
        },
    ];

    const initialColumns = [
        {
            title: geti18nText('attendance.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.table.column.oib'),
            dataIndex: ['person', 'oib'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY');
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('attendance.table.column.from'),
            dataIndex: 'from',
            render: (from: any) => {
                if (from) {
                    return getDateFormat(from, 'HH:mm');
                }
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            title: geti18nText('attendance.table.column.to'),
            dataIndex: 'to',
            render: (to: any) => {
                if (to) {
                    return getDateFormat(to, 'HH:mm');
                }
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            title: geti18nText('attendance.table.column.hours'),
            dataIndex: 'hours',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('attendance.table.column.type'),
            dataIndex: 'type',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.type !== undefined) {
                    return geti18nText('app.enum.ATTENDANCE_TYPE.' + record.type);
                }
            },
            ...getColumnSearchOption(getEnumArray('ATTENDANCE_TYPE'), 'equals', undefined),
        },
    ];

    return (
        <NyDataTable
            nyId="human-attendance-table"
            url={CONSTANTS_REQ.ATTENDANCE.LIST}
            addNewButtonText={geti18nText('attendance.table.add')}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={AttendanceEdit}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            columns={AdministrationRights.isAdmin() ? initialAdminColumns : initialColumns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            addedButtons={AddAttendance}
            hideNewButton={!canCreate()}
            shortcuts={true}
        />
    );
};

export default AttendanceIndex;
