import { FormOutlined } from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnTimeOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../utils/Filters';
import {
    GetAttendanceRegistrationIcon,
    GetCustomAttendanceDateFormat,
    getTransparentColor,
} from '../../../../utils/Utils';
import AttendanceRegistrationEdit from './AttendanceRegistrationEdit';
import DailyAttendanceIcons from './DailyAttendanceIcons';

const AttendanceListIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    myPortal = false,
    scroll = myPortal ? { y: 640, x: 800 } : DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [editModalVisible, setEditModalVisible] = useState<any>(false);
    const [editModalData, setEditModalData] = useState<any>();
    const attendanceMinLoggedMin = useApplicationSetting('ATTENDANCE_MINIMUM_LOGGED_MINUTES');
    const [refreshTable, setRefreshTable] = useState<any>(0);
    const { employee } = useSelector((state: RootState) => state.employee);
    const enModuleAccessRight = useEnum('MODULE_ACCESS_RIGHT');
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);

    useHelper('human/attendance-registration');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    const canCreateOnlyUser = () => {
        return !canCreate() && HumanResourcesRights.canCreateAttendanceRegistrationOnlyUser();
    };

    const canAddNewRecord = () => {
        return canCreate() || (canCreateOnlyUser() && moduleAccessRight?.length > 0);
    };

    useEffect(() => {
        if (canCreateOnlyUser()) {
            getModuleAccessRights();
        }
    }, []);

    function getModuleAccessRights() {
        setModuleAccessRight([]);
        NyRequestResolver.requestGet(CONSTANTS_REQ.ACCESS_RIGHTS.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    {
                        field: 'accessRightType',
                        condition: 'equals',
                        value: enModuleAccessRight.ATTENDANCE_REGISTRATION,
                    },
                ])
            ),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result?.data?.content?.length > 0) {
                let data = result.data.content.filter(
                    (item: any) => item?.user?.employee?.id == employee?.id && !item?.readOnly
                );
                setModuleAccessRight(data);
            }
        });
    }

    const getPassedTimeRender = (record: any) => {
        if (record?.id && record?.dateOfApplication && record?.passedTime) {
            const [hours, minutes, seconds] = record.passedTime.split(':');
            const time = `${hours}:${minutes}`;
            const timeInMinutes = Number(hours ? hours : 0) * 60 + Number(minutes ? minutes : 0);
            const isTodayDate: boolean = moment(moment(record.dateOfApplication).format('yyyy-MM-DD')).isSame(
                moment(moment().format('yyyy-MM-DD'))
            );
            const isZeroMinutesAndNotTodayDate: boolean = timeInMinutes == 0 && !isTodayDate;
            const isLessMinutesAndNotTodayDate: boolean = timeInMinutes < attendanceMinLoggedMin && !isTodayDate;
            const colorRed: boolean = isZeroMinutesAndNotTodayDate || isLessMinutesAndNotTodayDate;
            const timeShowValue = isZeroMinutesAndNotTodayDate
                ? geti18nText('attendance.time.value.needs.correction')
                : timeInMinutes > 0
                ? time
                : '00:00';

            return <b style={{ color: colorRed ? 'red' : '' }}>{timeShowValue}</b>;
        }

        return <>{'00:00'}</>;
    };

    const initialAdminColumns = [
        {
            title: geti18nText('attendance.table.column.employee'),
            dataIndex: "concat(person.first_name,' ', person.last_name)",
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record && record.person) {
                    return (
                        <div>
                            {record.person.firstName +
                                ' ' +
                                record?.person.lastName +
                                (record?.employee?.employmentRecordId
                                    ? ' (' + record.employee?.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'dateOfApplication',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.dateOfApplication) {
                    return GetCustomAttendanceDateFormat(record.dateOfApplication);
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('attendance.table.column.hours.sum'),
            dataIndex: ['passedTime'],
            width: '15%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return getPassedTimeRender(record);
            },
            ...getColumnTimeOption('HH:mm'),
        },
        {
            dataIndex: ['attendanceRegistration'],
            width: '58%',
            render: (text: any, record: any) => {
                return (
                    <DailyAttendanceIcons
                        record={record}
                        canCreate={canCreate}
                        canCreateOnlyUser={canCreateOnlyUser}
                        setEditModalData={setEditModalData}
                        setEditModalVisible={setEditModalVisible}
                    />
                );
            },
        },
    ];

    const addedButtons = () => {
        return (
            <>
                {canAddNewRecord() && (
                    <div style={{ float: 'right' }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setEditModalVisible(true);
                            }}
                        >
                            {geti18nText('attendance.table.add')}
                        </Button>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="attendance-list"
                url={CONSTANTS_REQ.ATTENDANCE_REGISTRATION.REGISTRATION_LIST}
                addNewButtonText={geti18nText('attendance.table.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => []}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                columns={initialAdminColumns}
                scroll={scroll}
                fetchWhenChange={refreshTable}
                hideNewButton
                shortcuts={true}
                CSVFileName={geti18nText('menu.attendance')}
                exportCSV={canExportCSV()}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
                addedButtons={addedButtons}
            />
            {React.createElement(
                AttendanceRegistrationEdit,
                {
                    isModal: true,
                    visible: editModalVisible,
                    setVisible: setEditModalVisible,
                    value: editModalData,
                    onSave: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onSaveAndGetID: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onSaveAndGetData: () => {
                        setRefreshTable((prevValue: any) => prevValue + 1);
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onModalClose: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    moduleAccessRight: moduleAccessRight,
                },
                null
            )}
        </>
    );
};

export default AttendanceListIndex;

export const csvColumnCustomisation: any = () => {
    return [
        {
            dateOfApplication: (value: any) => {
                return value
                    ? moment(value).format('dddd').charAt(0).toUpperCase() +
                          moment(value).format('dddd').slice(1) +
                          moment(value).format(', DD.MM.YYYY')
                    : '';
            },
        },
        {
            passedTime: (value: any) => {
                if (value) {
                    const [hours, minutes, seconds] = value?.split(':');
                    const time = `${hours}:${minutes}`;
                    return time;
                }
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('attendance.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
        },
        {
            title: geti18nText('attendance.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
        },
        {
            title: geti18nText('attendance.table.column.employmentRecordId'),
            dataIndex: ['employee', 'employmentRecordId'],
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'dateOfApplication',
        },
        {
            title: geti18nText('attendance.table.column.hours.sum'),
            dataIndex: 'passedTime',
        },
    ];
};
