import { geti18nText, NyDataEdit, NyInputNumber, NyModalConfirm, NySearchField } from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Row, Space, Table, Tabs, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    customEmployeeRenderOption,
    customPersonRenderOption,
    getEmployeeSelectOption,
    getSearchFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import CostCenterEdit from '../../../accounting/views/cost-center/edit';
import EmployeeIndex from '../employee';
import { BusinessUnitSearch } from './search';
import CostCenterSearch from '../../../accounting/views/cost-center/search';
import {
    CloseCircleTwoTone,
    DeleteTwoTone,
    EditTwoTone,
    PlusCircleOutlined,
    PlusOutlined,
    SaveTwoTone,
} from '@ant-design/icons';
import React from 'react';
import { GetEnum } from '../../../../utils/Enums';
import LocationSearch from '../location/search';
import BusinessUnitTypeIndex from '../business-unit-type';
import PortfolioIndex from '../portfolio';
import PortfolioEdit from '../portfolio/edit';
import BusinessUnitTypeEdit from '../business-unit-type/edit';
import TranslationLanguageEdit from '../../../administration/views/translation-language/edit';
import CustomHistory from '../../../../components/ny-history/CustomHistory';
import TabPane from 'antd/lib/tabs/TabPane';
import CustomHistoryEmployee from '../employee/CustomHistoryEmployee';
import moment from 'moment';

const BusinessUnitEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('businessUnit.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);
    const [addTranslationModalVisible, setAddTranslationModalVisible] = useState<any>(false);
    const [items, setItems] = useState<any>([]);
    const [employeeList, setEmployeeList] = useState<any>([]);

    const [dataForm, setDataForm] = useState<any>(null);
    const enTranslationType = GetEnum({ enumName: 'FIELD_TRANSLATION_TYPE' });

    const [editingKey, setEditingKey] = useState<any>('');
    const [languageForm] = Form.useForm();

    const isEditing = (record: any) => record.id === editingKey;

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    if (props?.setData?.text) {
        form.setFieldsValue({ name: props.setData.text });
    }

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeCodebooks();
    };

    const getPersonSelectOption = (person: any) => {
        let persons = person.map((val: any) => {
            let personData: any = {
                text: val.person?.firstName + ' ' + val.person?.lastName,
                employmentRecordId: val.employmentRecordId,
            };
            return {
                key: val.id,
                id: val.id,
                label: customEmployeeRenderOption(personData),
            };
        });
        return persons;
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('businessUnit.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.employee) {
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
        }
        if (dataForm.accountingCostCenter) {
            dataForm.accountingCostCenter = setSearchFormat(dataForm.accountingCostCenter, 'name', 'name');
        }

        if (dataForm.nameFieldTranslationList) {
            setItems(dataForm.nameFieldTranslationList);
        }

        if (dataForm.employeeList) {
            setEmployeeList(dataForm.employeeList);
            dataForm.employeeList = getPersonSelectOption(dataForm.employeeList);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('businessUnit.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const addNewRowLanguage = (event: any) => {
        event.stopPropagation();
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            name: undefined,
            translationType: enTranslationType.BUSINESS_UNIT_NAME,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };

        languageForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const cancel = (record: any) => {
        setEditingKey('');

        if (
            !(record.translationLanguage && record.translationLanguage.id !== undefined) ||
            !(record.name !== undefined)
        ) {
            deleteRow(record.id);
        }
    };

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await languageForm.validateFields();
            const newData = [...items];
            const index = newData.findIndex((item) => id === item.id);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <NyModalConfirm
                            title={geti18nText('app.default.cancel.confirm')}
                            onConfirm={() => {
                                cancel(record);
                            }}
                        >
                            <Button type="link">
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </NyModalConfirm>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const closeModal = () => {
        setAddTranslationModalVisible(false);
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <NyModalConfirm
                                title={geti18nText('app.default.delete.confirm')}
                                onConfirm={() => {
                                    deleteRow(record.id);
                                }}
                            >
                                <Button type="link" disabled={editingKey !== ''}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </NyModalConfirm>
                        </Tooltip>
                    </Space>
                }
            </React.Fragment>
        );
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
        const inputNode =
            dataIndex === 'translationLanguage' ? (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.TRANSLATION_LANGUAGE.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                    AddNewModalComponent={TranslationLanguageEdit}
                    order="name"
                />
            ) : (
                <Input />
            );

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const columns = [
        {
            title: geti18nText('businessUnit.edit.add.translation.table.lang'),
            dataIndex: 'translationLanguage',
            editable: true,
            inputType: 'translationLanguage',
            render: (text: any, record: any) => {
                if (record?.translationLanguage?.name !== undefined) {
                    return record.translationLanguage.name;
                }
            },
        },
        {
            title: geti18nText('businessUnit.edit.add.translation.table.name'),
            dataIndex: 'name',
            width: '350px',
            editable: true,
        },
        {
            title: geti18nText('businessUnit.edit.add.translation.table.action'),
            key: 'action',
            width: '50px',
            render: (text: any, record: any) => {
                if (items.length >= 1) {
                    const editable = isEditing(record);
                    return editable ? (
                        <ActionEditable text={text} record={record} />
                    ) : (
                        <ActionNotEditable text={text} record={record} />
                    );
                }
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: languageForm,
                };
            },
        };
    });

    const customColumns = [
        {
            title: geti18nText('customHistory.table.column.created'),
            dataIndex: 'created',
            render: (timestamp: any) => moment(timestamp).format('DD.MM.yyyy. HH:mm'),
        },
        {
            title: geti18nText('customHistory.table.column.createdBy'),
            dataIndex: 'createdPerson',
            render: (text: any, record: any) => (
                <>
                    {record?.createdPerson?.firstName} {record?.createdPerson?.lastName}
                </>
            ),
        },
        { title: geti18nText('businessUnit.table.column.code'), dataIndex: 'code' },
        { title: geti18nText('businessUnit.table.column.name'), dataIndex: 'name' },
    ];

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={
                    <CustomHistory
                        editHeader={editHeader}
                        url={CONSTANTS_REQ.BUSINESS_UNIT.CHANGELOG}
                        id={dataForm}
                        type="businessUnit"
                    />
                }
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                onModalOpen={onModalOpen}
                url={CONSTANTS_REQ.BUSINESS_UNIT.EDIT}
                setValues={setValues}
                width={900}
                form={form}
                goBack={() => history.goBack()}
                paramsId={id}
                {...props}
                shortcuts={true}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate()}
                checkIsFormChanged={false}
                normalize={(values: any) => {
                    let nameFieldTranslationListItems: any = [];

                    if (items !== undefined) {
                        items.map((item: any) => {
                            let obj: any = {};
                            if (item.translationLanguage) {
                                obj.translationLanguage = {
                                    id: item.translationLanguage.id,
                                    name: item.translationLanguage.name,
                                };
                            }

                            if (item.translationType) {
                                obj.translationType = item.translationType;
                            }
                            if (item.name) {
                                obj.name = item.name;
                            }

                            nameFieldTranslationListItems.push(obj);
                        });
                    }

                    if (nameFieldTranslationListItems) {
                        values.nameFieldTranslationList = nameFieldTranslationListItems;
                    }

                    values.employeeId = null;
                    if (values.employee) {
                        if (values.employee.constructor.name === 'Object') {
                            values.employeeId = values.employee.id;
                        } else {
                            values.employeeId = values.employee;
                        }
                    }
                    delete values.employee;

                    if (values.parent) values.parent = getSearchFormat(values.parent);
                    if (!values.parent || values.parent.id === -1) {
                        delete values.parent;
                    }

                    if (values.pdfParent) values.pdfParent = getSearchFormat(values.pdfParent);
                    if (!values.pdfParent || values.pdfParent.id === -1) {
                        delete values.pdfParent;
                    }

                    if (values.accountingCostCenter)
                        values.accountingCostCenter = getSearchFormat(values.accountingCostCenter);

                    if (values.employeeList) {
                        values.employeeList = employeeList;
                    }
                    return values;
                }}
            >
                <Tabs type="card" className="tabs-sticky">
                    <TabPane tab={geti18nText('menu.common')} key="1">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <div style={{ display: 'flex' }}>
                                    <Form.Item
                                        label={geti18nText('businessUnit.edit.name')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                                whitespace: true,
                                            },
                                        ]}
                                        style={{ width: 'calc(100% - 28px)' }}
                                    >
                                        <Input ref={focusInput} />
                                    </Form.Item>
                                    <Button
                                        className="ny-add-translation-button"
                                        icon={<PlusOutlined style={{ color: 'gray' }} />}
                                        onClick={() => {
                                            setAddTranslationModalVisible(true);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('businessUnit.edit.businessUnitType')}
                                    name="businessUnitType"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.BUSINESS_UNIT_TYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        AddNewModalComponent={canCreate() ? BusinessUnitTypeEdit : null}
                                        SearchPopupComponent={<BusinessUnitTypeIndex disabled={true} />}
                                        order="name"
                                        onChange={(value: any) => {
                                            if (value?.id === -1) {
                                                form.setFieldsValue({ businessUnitType: undefined });
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                            whitespace: true,
                                        },
                                    ]}
                                    label={geti18nText('businessUnit.edit.code')}
                                    name="code"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('businessUnit.edit.employee')} name="employee">
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={
                                            <EmployeeIndex disabled={true} parentKey="business-unit-edit" />
                                        }
                                        order="person.last_name, person.first_name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('businessUnit.edit.responsibleEmployee')}
                                    name="employeeList"
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                        map={{ id: 'id', label: 'text', employmentRecordId: 'employmentRecordId' }}
                                        searchBy="person.first_name || ' ' || person.last_name"
                                        itemName={[
                                            ['person', 'firstName'],
                                            ['person', 'lastName'],
                                        ]}
                                        renderOption={customEmployeeRenderOption}
                                        customItemNameLabel={'firstName lastName'}
                                        SearchPopupComponent={<EmployeeIndex disabled={true} />}
                                        order="person.last_name, person.first_name"
                                        mode="multiple"
                                        onChange={(items: any) => {
                                            const employees = items.map((item: any) => {
                                                return { id: item?.id };
                                            });
                                            setEmployeeList(employees);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <CostCenterSearch
                                    label={geti18nText('businessUnit.edit.cost.center')}
                                    name="accountingCostCenter"
                                    AddNewModalComponent={canCreate() ? CostCenterEdit : null}
                                />
                            </Col>
                            <Col span={12}>
                                <BusinessUnitSearch
                                    label={geti18nText('businessUnit.edit.parent')}
                                    formItemName={'parent'}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <LocationSearch label={geti18nText('businessUnit.edit.location')} name="location" />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('businessUnit.edit.portfolio.name')}
                                    name="portfolio"
                                    rules={[
                                        {
                                            required: false,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.PORTFOLIO.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        AddNewModalComponent={canCreate() ? PortfolioEdit : null}
                                        SearchPopupComponent={<PortfolioIndex disabled={!canCreate()} />}
                                        order="name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {HumanResourcesRights.canSeePDFBusinessUnit() && (
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('businessUnit.edit.sort')} name="sort">
                                        <NyInputNumber style={{ width: '100%' }} min={0} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <BusinessUnitSearch
                                        label={geti18nText('businessUnit.edit.pdfParent')}
                                        formItemName={'pdfParent'}
                                    />
                                </Col>
                            </Row>
                        )}
                    </TabPane>
                    <TabPane tab={geti18nText('employee.tab.employeeHistory')} key="2">
                        <CustomHistoryEmployee
                            url={CONSTANTS_REQ.BUSINESS_UNIT.HISTORY_LIST}
                            id={dataForm}
                            customColumns={customColumns}
                        />
                    </TabPane>
                </Tabs>
            </NyDataEdit>
            <Modal
                visible={addTranslationModalVisible}
                width={900}
                onOk={closeModal}
                onCancel={closeModal}
                title={editHeader}
                okText={geti18nText('app.default.button.ok')}
            >
                {
                    <Row gutter={24} style={{ height: '36px' }}>
                        <Col span={24}>
                            <Button
                                style={{ float: 'right', marginRight: '12px' }}
                                icon={<PlusCircleOutlined />}
                                onClick={addNewRowLanguage}
                            >
                                {geti18nText('businessUnit.edit.add.translation')}
                            </Button>
                        </Col>
                    </Row>
                }
                <Row gutter={24}>
                    <Col span={24}>
                        <Form form={languageForm} component={false}>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                size={'small'}
                                dataSource={items}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                            />
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default BusinessUnitEdit;
