import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import useHelper from '../../../../hooks/useHelper';

import React, { useEffect, useState } from 'react';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import AttendanceListIndex from './AttendanceListIndex';
import UserCardIndex from './UserCardIndex';
import EmployeeAttendanceIndex from '../employee-leave/EmployeeAttendanceIndex';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { GetEnumNameForValue } from '../../../../utils/Enums';
const { TabPane } = Tabs;

const AttendanceRegistrationIndex = () => {
    const [activeKey, setActiveKey] = useState<string>('3');
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const { employee } = useSelector((state: RootState) => state.employee);

    useHelper('human/attendance-registration');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    useEffect(() => {
        if (!canViewEmployeeAttendance()) {
            setActiveKey('1');
        } else {
            setActiveKey('3');
        }
    }, [moduleAccessRight]);

    const canViewEmployeeAttendance = () => {
        return (
            HumanResourcesRights.canCreateAttendanceRegistration() ||
            (moduleAccessRight &&
                moduleAccessRight?.length > 0 &&
                moduleAccessRight.includes('ATTENDANCE_REGISTRATION'))
        );
    };

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });

            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    return (
        <>
            <React.Fragment>
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        setActiveKey(key);
                    }}
                >
                    {canViewEmployeeAttendance() && (
                        <TabPane tab={geti18nText('employeeLeave.tab.7')} key="3">
                            {activeKey == '3' && <EmployeeAttendanceIndex showMonthlyReport />}
                        </TabPane>
                    )}
                    <TabPane tab={geti18nText('menu.attendance')} key="1">
                        {activeKey == '1' && <AttendanceListIndex scroll={{ y: 580, x: 800 }} />}
                    </TabPane>
                    {canCreate() && (
                        <TabPane tab={geti18nText('attendance.card.tab.title')} key="2">
                            {activeKey == '2' && <UserCardIndex scroll={{ y: 580, x: 800 }} />}
                        </TabPane>
                    )}
                </Tabs>
            </React.Fragment>
        </>
    );
};

export default AttendanceRegistrationIndex;
