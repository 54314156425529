import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, message, Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useEnum from '../../hooks/useEnum';
import NyReportButton from '../report-button';

const PDFPreview = ({
    id,
    url,
    customParms,
    subreportType,
    buttoni18nText,
    fileName,
    customDateFormat,
    disabled,
    fileNameAddition,
    type,
    checkBeforeSave,
    showLink,
    columns,
    customIconStyle,
    showTooltip,
    allowExportTypeChange,
    selectedExportType,
    customButtonText,
    style = undefined,
}: any) => {
    const [modalVisible, setModalVisible] = useState<any>(false);
    const [file, setFile] = useState<any>(null);
    const enExportType = useEnum('EXPORT_TYPE');

    const onClick = () => {
        setModalVisible(true);
    };

    useEffect(() => {
        if (modalVisible && url) {
            generateReport();
        }
    }, [url, modalVisible]);

    const generateReport = () => {
        let parms = {
            lang: NyUtils.getSelectedLanguage(),
            subreportId: undefined,
            useDefault: true,
            exportType: enExportType.PDF,
            ...{ id: Number(id) },
            v: Date.now(),
            ...customParms,
        };
        NyRequestResolver.requestGet(url, parms, false, true).then((result: any) => {
            message.destroy();
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                setFile(result);
            }
        });
    };

    const customFooter = (
        <>
            <div style={{ display: 'inline-flex' }}>
                <NyReportButton
                    url={url}
                    customParms={customParms}
                    subreportType={subreportType}
                    buttoni18nText={buttoni18nText}
                    fileName={fileName}
                    customDateFormat={customDateFormat}
                    fileNameAddition={fileNameAddition}
                    type={type}
                    disabled={disabled}
                    checkBeforeSave={checkBeforeSave}
                    showLink={showLink}
                    columns={columns}
                    customIconStyle={customIconStyle}
                    showTooltip={showTooltip}
                    allowExportTypeChange={allowExportTypeChange}
                    selectedExportType={selectedExportType}
                />
                <Button onClick={() => setModalVisible(false)} style={{ marginLeft: '15px' }}>
                    {geti18nText('app.default.button.cancel')}
                </Button>
            </div>
        </>
    );

    return (
        <>
            <Button disabled={disabled} onClick={onClick} style={style}>
                {customButtonText ? customButtonText : geti18nText('payment.calculation.error.details')}
            </Button>
            <Modal
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={customFooter}
                maskClosable={false}
                width={1200}
                style={{ top: '15px' }}
                title={'PDF'}
            >
                {file && (
                    <div style={{ height: '80vh' }}>
                        <embed
                            src={window.URL.createObjectURL(file.data.file).concat(
                                `#toolbar=0&navpanes=0&scrollbar=0&zoom=110&v=${Date.now()}`
                            )}
                            type="application/pdf"
                            height="100%"
                            width="100%"
                        ></embed>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default PDFPreview;
