import { geti18nText } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import MobilizationDocuments from '../../../../components/mobilization-documents/MobilizationDocuments';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import WorkObligationAttachments from './WorkObligationAttachments';
import WorkObligationCallIndex from './WorkObligationCallIndex';
const { TabPane } = Tabs;

const WorkObligationIndex = ({}: any) => {
    const [activeKey, setActiveKey] = useState<string>('1');
    const [refreshFiles, setRefreshFiles] = useState(0);
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    useHelper('human/work-obligation/mobilization');

    return (
        <>
            <React.Fragment>
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        if (key === '2') {
                            setRefreshFiles((refresh: any) => refresh + 1);
                        }
                        setActiveKey(key);
                    }}
                >
                    <TabPane tab={geti18nText('menu.work.obligation.call.table.header')} key="1">
                        {activeKey == '1' && <WorkObligationCallIndex scroll={{ y: 580, x: 800 }} />}
                    </TabPane>
                    <TabPane tab={geti18nText('menu.work.obligation.tab.2')} key="2">
                        <MobilizationDocuments
                            documentType={documentTypeEnum.MOBILIZATION_DECISION}
                            scroll={{ y: 620, x: 800 }}
                            defaultFilter={[
                                { field: 'active', condition: 'equals_bool', value: 1 },
                                {
                                    field: 'document_type',
                                    condition: 'in',
                                    value: [
                                        documentTypeEnum.MOBILIZATION_DECISION,
                                        documentTypeEnum.MOBILIZATION_ORGANIZATION,
                                    ].toString(),
                                },
                            ]}
                            showDocumentTypeColumn={true}
                            refresh={refreshFiles}
                        />
                    </TabPane>
                    {/* <TabPane tab={geti18nText('menu.work.obligation.tab.3')} key="3">
                        {activeKey == '3' && <div></div>}
                    </TabPane>
                    <TabPane tab={geti18nText('menu.work.obligation.tab.4')} key="4">
                        {activeKey == '4' && <div></div>}
                    </TabPane> */}
                    <TabPane tab={geti18nText('menu.work.obligation.tab.5')} key="5">
                        {activeKey == '5' && <WorkObligationAttachments />}
                    </TabPane>
                </Tabs>
            </React.Fragment>
        </>
    );
};

export default WorkObligationIndex;
