import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, geti18nText, NyDataTable } from '@nybble/nyreact';
import { getColumnSearchCheckbox, getColumnSearchOption } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import React, { useState } from 'react';
import NyDescription from '../../../../components/ny-description';
import RowAction from '../../../../components/row-action';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { AdministrationRights } from '../../../../rights/administrationRights';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getEnumArrayStatus, getEnumBooleanArray } from '../../../../utils/Utils';
import MessageEdit from './edit';

const MessageIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    readonly = false,
    type = null,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    useHelper('common/message');
    const enMessageType = useEnum('MESSAGE_TYPE');
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState(1);

    const canCreate = () => {
        if (AdministrationRights.isMessageAdmin()) {
            return true;
        } else if (type == enMessageType.WORK_OBLIGATION) {
            return MobilizationRights.isMobilizationAdmin();
        }
    };

    const canExportCSV = () => {
        return canCreate();
    };

    const setDefaultFilterValue = () => {
        if (type) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'type', condition: 'equals', value: type },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('message.table.column.id'),
                dataIndex: 'id',
                width: '6%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
        ],
        ...(!type
            ? [
                  {
                      title: geti18nText('message.table.column.type'),
                      dataIndex: 'type',
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchCheckbox(getEnumArrayStatus('MESSAGE_TYPE')),
                      render: (text: any, record: any) => {
                          if (record.type !== undefined) {
                              return geti18nText('app.enum.MESSAGE_TYPE.' + record.type);
                          }
                      },
                      defaultFilteredValue: setDefaultFilterValue(),
                  },
              ]
            : []),
        {
            title: geti18nText('message.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record?.name) {
                    return <NyDescription title={record?.name} maxWidth={'300px'} />;
                }
            },
        },
        {
            title: geti18nText('message.table.column.description'),
            dataIndex: 'description',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (description: any, record: any) => {
                if (record?.description) {
                    return <NyDescription title={record?.description} maxWidth={'300px'} />;
                }
            },
        },
        {
            title: geti18nText('message.table.column.allUsers'),
            dataIndex: 'allUsers',
            width: '7%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { allUsers: any }) => {
                if (record.allUsers) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('message.table.column.needConfirmation'),
            dataIndex: 'needConfirmation',
            width: '9%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: { needConfirmation: any }) => {
                if (record.needConfirmation) {
                    return <CheckOutlined style={{ color: 'green', marginLeft: '8px' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red', marginLeft: '8px' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('message.table.column.status'),
            dataIndex: 'status',
            width: '7%',
            ...getColumnSearchOption(getEnumArrayStatus('MESSAGE_STATUS'), 'equals'),
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.status !== undefined) {
                    return geti18nText('app.enum.MESSAGE_STATUS.' + record.status);
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '70px',
            render: (text: any, record: any) => {
                if (record?.id && canCreate()) {
                    return <RowAction component={'Message'} record={record} setRefresh={setRefreshTable} />;
                }
            },
        },
        {
            title: geti18nText('message.table.column.active'),
            dataIndex: 'active',
            width: '8%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
        },
    ];

    return (
        <React.Fragment>
            <NyDataTable
                nyId="message-table"
                url={CONSTANTS_REQ.MESSAGE.LIST}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                modalComponent={MessageEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                scroll={scroll}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                onDataLoaded={table.onDataLoaded}
                hideNewButton={!canCreate()}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                fetchWhenChange={refreshTable}
                editProps={{
                    type: type,
                    setRefreshTable: setRefreshTable,
                    refreshTable: refreshTable,
                }}
                readonly={readonly}
                hideButtons={readonly}
                exportCSV={canExportCSV()}
                CSVFileName={geti18nText('menu.message')}
                colCSVCustomization={csvColumnCustomisation()}
                headerTitle={geti18nText('menu.message')}
            />
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            type: (value: any) => {
                return value != undefined && geti18nText('app.enum.MESSAGE_TYPE.' + value);
            },
        },
        {
            allUsers: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            needConfirmation: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.MESSAGE_STATUS.' + value);
            },
        },
        {
            active: (value: any) => {
                return value ? geti18nText('app.default.button.yes') : geti18nText('app.default.button.no');
            },
        },
    ];
};

export default MessageIndex;
