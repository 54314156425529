import { geti18nText, NySession } from '@nybble/nyreact';
import moment from 'moment';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { TasksRights } from '../../rights/tasksRights';
import { setPropOverviewTasks } from '../../slices/propSlice';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
import { MobilizationRights } from '../../rights/mobilizationRights';
import { Tooltip } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { fileDownload } from '../../utils/Utils';

export const getNotificationData = (data: any, employee: any = undefined, dispatch: any = undefined) => {
    let enumKey = GetEnumNameForValue({
        enumName: 'NOTIFICATION_TYPE',
        value: Number(data.notificationType),
    });
    let formattedDateTime;
    if (data.attendanceFrom != null) {
        const dateFrom = moment(data.attendanceFrom);
        formattedDateTime = dateFrom.format('DD.MM.YYYY HH:mm');
    }

    const employeeName = `${
        data && data?.content?.employee && data?.content?.employee?.person && data?.content?.employee?.person?.firstName
            ? data?.content?.employee?.person?.firstName
            : ''
    } 
    ${
        data && data?.content?.employee && data?.content?.employee?.person && data?.content?.employee?.person?.lastName
            ? data?.content?.employee?.person?.lastName
            : ''
    }
     [${geti18nText('notification.message.employee.person.user.employmentRecordId')} ${
        data && data.content?.employee && data.content?.employee?.employmentRecordId
            ? data.content?.employee?.employmentRecordId
            : ''
    }]`;

    const userName = `${data?.user?.person?.firstName ? data?.user?.person?.firstName : ''} 
    ${data?.user?.person?.lastName ? data?.user?.person?.lastName : ''}
     [${geti18nText('notification.message.employee.person.user.employmentRecordId')} ${
        data?.userEmployee?.employmentRecordId ? data?.userEmployee?.employmentRecordId : ''
    }]`;

    let employeeId = data.content?.employee && data.content?.employee.id ? data.content?.employee.id : data.user.id;
    let notif = data;
    let path = '/human/employee/' + employeeId;
    let link = undefined;
    let content = '';
    let errors = undefined;

    let hideMenuLink = false;
    let notificationSwitchType = undefined;

    switch (enumKey) {
        case 'ATTENDANCE_LOGOUT_NOTIFICATION':
            content = `${geti18nText('notification.message.attendance.logout.date')} ${
                formattedDateTime ? formattedDateTime : ''
            }`;
            break;
        case 'ATTENDANCE_LOGOUT_NOTIFICATION_SUPERVISOR':
            content = `${geti18nText('notification.message.attendance.logout.supervisor.user')} ${
                data.user.username
            } ${geti18nText('notification.message.attendance.logout.supervisor.date')} ${
                formattedDateTime ? formattedDateTime : ''
            }`;
            break;
        case 'EMPLOYEE_CONTRACT_EXPIRATION':
            const dateTo = moment(data?.content?.employmentContract?.validTo);
            formattedDateTime = dateTo?.format('DD.MM.YYYY HH:mm');
            link = {
                function: 'addTabPane',
                path: '/human/contracts/contractCTR',
                notification: notif,
            };
            content = `${geti18nText('notification.message.contract.expiry.employee.person.user.name')} 
            ${userName}
            ${formattedDateTime ? formattedDateTime : ''} ${geti18nText(
                'notification.message.employee.contract.expiration'
            )}`;
            break;
        case 'EMPLOYEE_LEAVE':
            const elDateFrom = moment(data.content?.dateFromTs);
            let elFormattedDateTimeFrom = elDateFrom.format('DD.MM.YYYY');

            let elDateText = elFormattedDateTimeFrom + ' ';

            if (data.content?.dateTo) {
                const elDateTo = moment(data?.content?.dateToTs);
                let elFormattedDateTimeTo = elDateTo.format('DD.MM.YYYY');
                elDateText = elFormattedDateTimeFrom + ' - ' + elFormattedDateTimeTo;
            }

            link = {
                function: 'openItemInTab',
                path: '/human/employee-leave-overview',
                modalName: 'employeeLeaveEdit',
                item: data?.content?.id,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            content = `
            ${geti18nText('notification.message.contract.expiry.employee.person.user.name')} 
            ${employeeName}
            ${
                data?.content?.type === 4
                    ? data?.content?.status === 1
                        ? geti18nText('notification.message.employee.sickLeave.requested')
                        : data?.content?.status === 2
                        ? geti18nText('notification.message.employee.sickLeave.approved')
                        : data?.content?.status === 3
                        ? geti18nText('notification.message.employee.sickLeave.rejected')
                        : ' '
                    : data?.content?.status === 1
                    ? geti18nText('notification.message.employee.leave.requested')
                    : data?.content?.status === 2
                    ? geti18nText('notification.message.employee.leave.approved')
                    : data?.content?.status === 3
                    ? geti18nText('notification.message.employee.leave.rejected')
                    : ' '
            } ${geti18nText('app.enum.EMPLOYEE_LEAVE_TYPE.' + data?.content?.type).toLowerCase()} 
            ${elDateText} 
            (${geti18nText('notification.message.working.days')}: 
            ${data && data?.content && data?.content?.workingDays ? data?.content?.workingDays : '0'})
            `;
            break;
        case 'EMPLOYEE_ANNUAL_LEAVE':
            link = {
                function: 'openItemInTab',
                path:
                    employee?.id == data?.content?.employee?.id
                        ? '/human/employee-leave-overview'
                        : '/human/employeeLeave',
                modalName: 'employeeAnnualLeaveEdit',
                item: data && data.content && data.content?.id && data.content?.id,
                notification: notif,
                title: 'employeeLeave.table.header',
                state: { activeTab: employee?.id == data?.content?.employee?.id ? 2 : 1 },
            };

            content = `
            ${geti18nText('notification.message.employeeLeave.employee')} 
            ${employeeName}
            ${geti18nText('notification.message.employee.annualLeave.generated')} 
             ${data && data.content?.annualLeaveYear ? data.content?.annualLeaveYear : ''}
            (${geti18nText('notification.message.annualLeave.approvedDays')}: ${
                data && data.content?.approvedDays ? data.content?.approvedDays : '0'
            })`;
            break;

        case 'EMPLOYEE_PAID_LEAVE_APPROVAL':
            const eplaDateFrom = moment(data?.content?.dateFrom);
            let eplaFormattedDateTimeFrom = eplaDateFrom.format('DD.MM.YYYY');

            let eplaDateText = eplaFormattedDateTimeFrom + ' ';
            if (data?.content?.dateTo) {
                const eplaDateTo = moment(data.content?.dateTo);
                let eplaFormattedDateTimeTo = eplaDateTo.format('DD.MM.YYYY');
                eplaDateText = eplaFormattedDateTimeFrom + ' - ' + eplaFormattedDateTimeTo;
            }
            link = {
                function: 'openItemInTab',
                path: '/human/employee-leave-overview',
                modalName: 'employeeLeaveEdit',
                item: data?.content?.id,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            content = `
            ${geti18nText('notification.message.employee')} 
            ${employeeName}
            ${geti18nText('notification.message.employeePaidLeaveApproval.header')}
            ${eplaDateText} 
            (${geti18nText('notification.message.working.days')}: 
            ${data && data.content?.workingDays ? data.content?.workingDays : '0'})
            `;
            break;
        case 'EMPLOYEE_ANNUAL_LEAVE_APPROVAL':
            const ealaDateFrom = moment(data.content?.dateFrom);
            let ealaFormattedDateTimeFrom = ealaDateFrom.format('DD.MM.YYYY');

            let ealaDateText = ealaFormattedDateTimeFrom + ' ';
            if (data.content?.dateTo) {
                const ealaDateTo = moment(data.content?.dateTo);
                let ealaFormattedDateTimeTo = ealaDateTo.format('DD.MM.YYYY');
                ealaDateText = ealaFormattedDateTimeFrom + ' - ' + ealaFormattedDateTimeTo;
            }
            link = {
                function: 'openItemInTab',
                path: '/human/employee-leave-overview',
                modalName: 'employeeLeaveEdit',
                item: data.content?.id,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            content = `
            ${geti18nText('notification.message.employee')} 
            ${employeeName}
            ${geti18nText('notification.message.employeeAnnualLeaveApproval.header')}
            ${ealaDateText} 
            (${geti18nText('notification.message.working.days')}: 
            ${data && data.content?.workingDays ? data.content?.workingDays : '0'})
            `;
            break;
        case 'EMPLOYEE_SICK_LEAVE':
            const eskDateFrom = moment(data.content?.dateFromTs);
            let eskFormattedDateTimeFrom = eskDateFrom.format('DD.MM.YYYY');

            let eskDateText = eskFormattedDateTimeFrom + ' ';
            if (data.content?.dateToTs) {
                const eskDateTo = moment(data.content?.dateToTs);
                let eskFormattedDateTimeTo = eskDateTo.format('DD.MM.YYYY');
                eskDateText = eskFormattedDateTimeFrom + ' - ' + eskFormattedDateTimeTo;
            }
            link = {
                function: 'openItemInTab',
                path: '/human/employeeLeave',
                modalName: 'employeeLeaveEdit',
                item: data.content?.id,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            content = `
            ${geti18nText('notification.message.employee')} 
            ${employeeName}
            ${geti18nText('notification.message.employeeSickLeave.header')}
            ${eskDateText} 
            (${geti18nText('notification.message.working.days')}: 
            ${data && data.content && data.content?.workingDays ? data.content?.workingDays : '0'})
            `;
            break;
        case 'EMPLOYEE_TAX_DEDUCTION':
            let personFullname = '';
            if (data.content?.person) {
                personFullname = '- ' + data.content?.person?.firstName + ' ' + data.content?.person?.lastName;
            }
            const etdDateTime = moment(data.content?.employeeTaxDeduction?.validTo);
            let etdFormattedDateTime = etdDateTime.format('DD.MM.YYYY HH:mm');
            const canViewLink = HumanResourcesRights.canViewEmployeeTaxDeduction();
            if (canViewLink) {
                link = {
                    function: 'addTabPane',
                    path: path,
                    string: 'tax-deduction',
                    id: employeeId,
                    notification: notif,
                };
            }
            content = `${geti18nText(
                'notification.message.employee.tax.deduction'
            )} ${data.content?.payrollTaxDeduction?.name.toLowerCase()} ${personFullname} ${geti18nText(
                'notification.message.employee.tax.deduction.employee'
            )}
            ${employeeName}
            ${geti18nText('notification.message.employee.tax.deduction.expire')} ${etdFormattedDateTime}`;
            break;
        case 'INVENTORY_ORDER':
            const ioDateFrom = moment(data.created);
            let iorFormattedDateTime = ioDateFrom.format('DD.MM.YYYY HH:mm');
            let ioDateText = iorFormattedDateTime + ' ';

            let ordinal = data.content?.inventoryOrder.ord + '/' + ioDateFrom.format('YYYY');

            link = {
                function: 'openItemInTab',
                path:
                    data.refStatus && data.refStatus === 1
                        ? '/webshop/webshop-item/inventory-order'
                        : '/webshop/webshop-item/my-inventory-order',
                modalName: 'inventoryOrderEdit',
                item: data.content?.id,
                notification: notif,
                title: 'menu.inventory.order',
            };

            if (data.refStatus && data.refStatus === 1) {
                content = `${geti18nText('notification.message.employee.person.user.name')}
                ${employeeName} ${geti18nText(
                    'notification.message.employee.inventory.order'
                )}: ${ordinal} - ${ioDateText}`;
            } else if (data.refStatus) {
                content = `${geti18nText('inventoryOrder.title')}: ${ordinal} - ${ioDateText} \n${geti18nText(
                    'inventoryOrder.table.column.status'
                )}: ${geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + data.refStatus)}`;
            }
            break;
        case 'ASSET_ORDER':
            const aoDateFrom = moment(data.created);
            let aorFormattedDateTime = aoDateFrom.format('DD.MM.YYYY HH:mm');
            let aoDateText = aorFormattedDateTime + ' ';

            let aOrdinal = data.content?.assetOrder?.ord + '/' + aoDateFrom.format('YYYY');

            link = {
                function: 'openItemInTab',
                path:
                    data.refStatus && data.refStatus === 1
                        ? '/webshop/webshop-asset/asset-order'
                        : '/webshop/webshop-asset/my-asset-order',
                modalName: 'assetOrderEdit',
                item: data.content?.id, //todo dodati na backu
                notification: notif,
                title: 'menu.asset.order',
            };

            if (data.refStatus && data.refStatus === 1) {
                content = `${geti18nText('notification.message.employee.person.user.name')}
                ${employeeName}
                ${geti18nText('notification.message.employee.asset.order')}: ${aOrdinal} - ${aoDateText}`;
            } else if (data.refStatus) {
                content = `${geti18nText('inventoryOrder.title')}: ${aOrdinal} - ${aoDateText} \n${geti18nText(
                    'inventoryOrder.table.column.status'
                )}: ${geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + data.refStatus)}`;
            }
            break;
        case 'ASSET_MOVEMENT':
            const assetMovementId = data?.content?.id;
            const assetMovementOrd = data?.content?.ord;
            const assetMovementDate = data?.content?.date;

            let assetMovementUrl: any = '/';
            let assetMovementHeder: any = 'app.enum.NOTIFICATION_TYPE.' + data?.notificationType.toString();
            let assetMovementModalName: any = '';
            if (data?.refStatus == 1) {
                assetMovementUrl = '/webshop/webshop-asset/asset-release';
                assetMovementHeder = 'assetRelease.table.header';
                assetMovementModalName = 'assetReleaseEdit';
            } else if (data?.refStatus == 2) {
                assetMovementUrl = '/webshop/webshop-asset/asset-return';
                assetMovementHeder = 'assetReturn.table.header';
                assetMovementModalName = 'assetReturnEdit';
            }

            link = {
                function: 'openItemInTab',
                path: assetMovementUrl,
                modalName: assetMovementModalName,
                item: assetMovementId,
                notification: notif,
                title: assetMovementHeder,
            };

            content = `${geti18nText(assetMovementHeder)} (${geti18nText(
                'assetRelease.table.column.ord'
            )} ${assetMovementOrd}) -   
            ${data?.user?.person?.firstName ?? ''} ${data?.user?.person?.lastName ?? ''} : ${assetMovementDate}`;

            //old notification code?
            // const amCreated = moment(data.created);
            // let amFormattedDateTime = amCreated.format('DD.MM.YYYY HH:mm');
            // let amDateText = amFormattedDateTime + ' ';

            // let amOrdinal = data.content?.ord;

            // link = {
            //     function: 'openItemInTab',
            //     path: '/webshop/webshop-asset/my-asset',
            //     modalName: data.refStatus == 1 ? 'assetReleaseEdit' : 'assetReturnEdit',
            //     item: data.content?.id,
            //     notification: notif,
            //     title: 'menu.employeeAsset',
            // };

            // if (data.refStatus == 1) {
            //     content = `${geti18nText('notification.message.assetMovement.status.1')} ${amDateText} - ${amOrdinal}`;
            // } else {
            //     content = `${geti18nText('notification.message.assetMovement.status.2')} ${amDateText} - ${amOrdinal}`;
            // }

            break;
        case 'SERVICES_ORDER':
            const soDateFrom = moment(data.created);
            let sorFormattedDateTime = soDateFrom.format('DD.MM.YYYY HH:mm');
            let soDateText = sorFormattedDateTime + ' ';

            let sOrdinal = data.content?.servicesOrder?.ord + '/' + soDateFrom.format('YYYY');

            link = {
                function: 'openItemInTab',
                path:
                    data.refStatus && data.refStatus === 1
                        ? '/webshop/webshop-services/services-order'
                        : '/webshop/webshop-services/my-services-order',
                modalName: 'servicesOrderEdit',
                item: data.content?.id,
                notification: notif,
                title: 'menu.services.order',
            };

            if (data.refStatus && data.refStatus === 1) {
                content = `${geti18nText('notification.message.employee.person.user.name')} 
                ${employeeName}
                ${geti18nText('notification.message.employee.asset.order')}: ${sOrdinal} - ${soDateText}`;
            } else if (data.refStatus) {
                content = `${geti18nText('inventoryOrder.title')}: ${sOrdinal} - ${soDateText} \n${geti18nText(
                    'inventoryOrder.table.column.status'
                )}: ${geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + data.refStatus)}`;
            }
            break;
        case 'EQUIPMENT_PROBLEM':
            const epDateFrom = moment(data.created);
            let epFormattedDateTime = epDateFrom.format('DD.MM.YYYY HH:mm');
            let epDateText = epFormattedDateTime + ' ';

            link = {
                function: 'openItemInTab',
                path: '/webshop/codebooks/webshop-asset/asset',
                modalName: 'assetEdit',
                item: data.content?.asset?.id,
                notification: notif,
                title: 'menu.asset',
            };

            content = `${geti18nText('notification.message.employee.person.user.name')} 
            ${employeeName}
            ${geti18nText('notification.message.employee.asset.problem')}: ${
                data.content?.assetModel?.name ? data.content?.assetModel?.name : ''
            } [${geti18nText('notification.message.employee.asset.serialNumber')}: ${
                data.content?.asset?.serialNumber ? data.content?.asset?.serialNumber : ''
            }] - ${epDateText}`;
            break;
        case 'TRAVEL_WARRANT_APPROVAL':
        case 'TRAVEL_WARRANT_ORGANISATION':
        case 'TRAVEL_WARRANT_PAYMENT':
        case 'TRAVEL_WARRANT_PAYMENT_APPROVAL':
        case 'TRAVEL_WARRANT_COMPENSATION_PAYMENT':
        case 'TRAVEL_WARRANT':
            const warrantDate = moment(data?.content?.travelWarrant?.warrantDateTs);
            let formatedWarrantDate = warrantDate.format('DD.MM.YYYY HH:mm');
            let warrantDateText = formatedWarrantDate + ' ';

            let twOrdinal = data?.content?.travelWarrant?.ordinal + '/' + warrantDate.format('YYYY');

            let travelWarrantPath = '/travel-warrant/travelWarrant';

            link = {
                function: 'addTabPane',
                path: travelWarrantPath + '/' + data?.content?.id,
                item: data?.content?.id,
                notification: notif,
                title: 'menu.travel.warrant',
            };

            if (enumKey === 'TRAVEL_WARRANT_PAYMENT_APPROVAL' && data.refStatus) {
                content = `${geti18nText(
                    'notification.message.travel.warrant'
                )}: ${twOrdinal} - ${warrantDateText} \n${geti18nText('notification.message.status')}: ${geti18nText(
                    'app.enum.TRAVEL_WARRANT_PAYMENT_STATUS.' + data.refStatus
                )}`;
            } else if (data.refStatus && data.refStatus === 1) {
                const isVehicle2Set = data.content?.travelVehicle2 && data.content?.travelVehicle2.id ? true : false;
                content = `${geti18nText('notification.message.employee.person.user.name')} 
                ${employeeName}
                ${geti18nText('notification.message.employee.travel.warrant')}: ${twOrdinal} - ${warrantDateText} ${
                    isVehicle2Set ? geti18nText('notification.message.employee.travel.warrant.use.another.vehicle') : ''
                }`;
            } else if (data.refStatus) {
                const vehicle2Approved =
                    !data.content?.travelVehicle2 ||
                    !data.content?.travelVehicle2.id ||
                    (data.content?.travelVehicle2 && data.content?.travelVehicle2.id && data.content?.vehicleApproved2)
                        ? true
                        : false;
                content = `${geti18nText(
                    'notification.message.travel.warrant'
                )}: ${twOrdinal} - ${warrantDateText} \n${geti18nText('notification.message.status')}: ${geti18nText(
                    'app.enum.TRAVEL_WARRANT_STATUS.' + data.refStatus
                )} ${
                    data.refStatus === 2 && !vehicle2Approved
                        ? '(' + geti18nText('notification.message.employee.travel.warrant.vehicle2.not.approved') + ')'
                        : ''
                }`;
            }

            break;
        case 'PAYMENT_CALCULATION':
            const pcDate = moment(data.created);
            let formatedPcDate = pcDate.format('DD.MM.YYYY HH:mm');
            let pcDateText = formatedPcDate + ' ';

            let nData = data.note && data.note.note ? JSON.parse(data.note.note) : {};
            if (nData.error) {
                content = `${geti18nText('payment.calculation.thread.fail', [pcDateText])}`;
                errors = {
                    data: JSON.parse(nData.error),
                    title: geti18nText('payment.calculation.error.details.modal'),
                };
            } else {
                content = `${geti18nText('payment.calculation.thread.success', [pcDateText])}`;
            }
            if (nData.id) {
                link = {
                    function: 'openItemInTab',
                    path: '/human/payroll/calculation/paymentCalculationIncome/' + nData.id,
                    modalName: 'paymentCalculationIncomeEdit',
                    item: nData.id,
                    notification: notif,
                    title: 'menu.payment.calculation.income',
                };
            }
            break;
        case 'ASSET_ASSIGN_REQUEST':
            const aarDate = moment(data.created);
            let formatedAarDate = aarDate.format('DD.MM.YYYY HH:mm');
            let aarDateText = formatedAarDate + ' ';

            content = `${geti18nText('notification.message.asset.assign', [aarDateText])}`;
            link = {
                function: 'openNoteInModal',
                notification: notif,
                item: data.content,
                title: geti18nText('notification.message.asset.assign.modal.title'),
                key: enumKey,
            };
            break;
        case 'TRAVEL_WARRANT_ERROR':
            const tweDate = moment(data.created);
            let formatedTweDate = tweDate.format('DD.MM.YYYY HH:mm');
            let tweDateText = formatedTweDate + ' ';

            let tweName =
                data.content?.ordinal && data.content?.year
                    ? data.content?.ordinal + '/' + data.content?.year
                    : data.content?.id;
            content = `${geti18nText('travel.warrant.error', [tweName, tweDateText])}`;
            if (data.content?.error) {
                errors = {
                    data: JSON.parse(data.content?.error),
                    title: geti18nText('travel.warrant.error.details.modal'),
                };
            }
            if (data.content?.id) {
                link = {
                    function: 'addTabPane',
                    path: '/travel-warrant/travelWarrant/' + data.content?.id,
                    item: data.content?.id,
                    notification: notif,
                    title: 'menu.travel.warrant',
                };
            }
            break;
        case 'MEALS_ORDER':
            const moOrderDate = moment(data.content?.orderDate);
            let moOrderDateTimeFrom = moOrderDate.format('DD.MM.YYYY');

            let time = data.content?.deliverySpotTime?.time ?? '';
            let type = data.content?.deliverySpotTime?.type ?? '';
            let delivery = data.content?.deliverySpotTime?.delivery?.name ?? '';
            let status = data.content?.status ?? 3;

            let moOrderInfo = geti18nText('notification.message.mealsOrderConfirmed.deliverySpot');
            let statusOrderHeader = geti18nText('notification.message.mealsOrderConfirmed.header');
            if (type == '2') {
                moOrderInfo = geti18nText('notification.message.mealsOrderConfirmed.pickUp');
            }
            if (status == 3) {
                statusOrderHeader = geti18nText('notification.message.mealsOrderCanceled.header');
            }

            link = {
                function: 'openItemInTab',
                path: '/webshop/meals',
                modalName: 'mealOrderWizard',
                item: data.refId,
                notification: notif,
                title: 'meal.order.tab.header',
            };
            const moContent: any = (
                <div>
                    {statusOrderHeader} {status != 3 ? moOrderInfo : ''}: {delivery} ({time}) <br />
                    {geti18nText('meal.order.table.column.orderDate')}: {moOrderDateTimeFrom}
                </div>
            );
            content = moContent;
            break;
        case 'MEALS_MENU_CHANGED':
            const mmcOrderDate = moment(data.content?.orderDate);
            let mmcOrderDateTimeFrom = mmcOrderDate.format('DD.MM.YYYY');
            link = {
                function: 'openItemInTab',
                path: '/webshop/meals',
                modalName: 'mealOrderWizard',
                item: data.content?.orderId,
                notification: notif,
                title: 'meal.order.tab.header',
            };
            content = `${geti18nText('notification.message.mealsMenuChanged.header')} ${data.content?.mealName} 
                 ${geti18nText('meal.order.table.column.orderDate')}: ${mmcOrderDateTimeFrom}`;
            break;
        case 'TASK_ASSIGN':
            const taId = data.refId;
            const taDateFrom = moment(data.created);
            const taFormattedDateTime = taDateFrom.format('DD.MM.YYYY HH:mm');
            const taStatus = data.refStatus;
            const taStatusName = taStatus ? geti18nText('app.enum.TASK_STATUS.' + taStatus) : '';

            const isTaskAssignAdmin = TasksRights.canCreateAdminOrAgent();
            const isTaskAssignUser = TasksRights.isTasksUser();
            const taskStatusEnum = GetEnum({ enumName: 'TASK_STATUS' });

            link = {
                function: 'openItemInTab',
                path: isTaskAssignAdmin ? '/tasks' : isTaskAssignUser ? '/my-task-list' : '/',
                modalName: isTaskAssignAdmin ? 'createTaskIndex' : isTaskAssignUser ? 'createTaskUserIndex' : '',
                item: data.refId,
                notification: notif,
                title: isTaskAssignAdmin
                    ? 'tasks.list.tab'
                    : isTaskAssignUser
                    ? 'task.edit.edit.myTasks'
                    : 'menu.dashboard',
            };

            if (taStatus && Object.values([taskStatusEnum.NEW, taskStatusEnum.ASSIGNED]).includes(taStatus)) {
                content = `${geti18nText('notification.message.user.person.user.name')} ${
                    data?.user?.person?.firstName
                } ${data?.user?.person?.lastName} ${geti18nText(
                    'notification.message.user.task.assign'
                )} (ID: ${taId}) - ${taFormattedDateTime}`;
            } else if (
                taStatus &&
                Object.values([
                    taskStatusEnum.IN_PROGRESS,
                    taskStatusEnum.RESOLVED,
                    taskStatusEnum.CLOSED,
                    taskStatusEnum.NOT_RESOLVED,
                    taskStatusEnum.CANCELLED,
                    taskStatusEnum.WAITING_FOR_APPROVAL,
                    taskStatusEnum.APPROVAL_REJECTED,
                    taskStatusEnum.APPROVAL_APPROVED,
                    taskStatusEnum.WAITING_FOR_RESOLUTION,
                ]).includes(taStatus)
            ) {
                content = `${geti18nText('notification.message.task.status.changed')} (ID: ${taId}) - ${taStatusName}`;
            }
            break;
        case 'MOBILIZATION_APPROVAL':
            link = {
                function: 'addTabPane',
                path: '/human/work-obligation/call/work-obligation-call',
                modalName: 'WorkObligationCallEdit',
                item: data.id,
                notification: notif,
                title: 'menu.work.obligation.call.mobilization',
            };

            const mobilizationStatus = GetEnum({ enumName: 'MOBILIZATION_STATUS' });
            const mobilizationTime = GetEnum({ enumName: 'MOBILIZATION_TIME' });

            if (data?.refStatus == mobilizationStatus.WAITING_FOR_APPROVAL) {
                content = `${geti18nText('notification.message.employee.person.user.name')}  ${
                    data.user.person.firstName + ' ' + data.user.person.lastName
                } ${geti18nText('notification.message.mobilization.user.requested')}`;
            } else if (data?.refStatus == mobilizationStatus.APPROVED) {
                if (
                    data?.content?.mobilizationTime == mobilizationTime.M8 ||
                    data?.content?.mobilizationTime == mobilizationTime.M3 ||
                    data?.content?.mobilizationTime == mobilizationTime.M24
                ) {
                    content = ` ${geti18nText('notification.message.mobilization.user.approved.hours', [
                        geti18nText('app.enum.MOBILIZATION_TIME.' + data?.content?.mobilizationTime),
                    ])}`;
                } else {
                    content = ` ${geti18nText('notification.message.mobilization.user.approved')}`;
                }
            } else if (data?.refStatus == mobilizationStatus.REJECTED) {
                content = `${geti18nText('notification.message.contract.expiry.employee.person.user.name')}  ${
                    data.user.person.firstName + ' ' + data.user.person.lastName
                } ${geti18nText('notification.message.mobilization.user.rejected')}`;
            }
            break;
        case 'TASK_APPROVAL':
            const taskApprovalDate = moment(data?.content?.created);
            const taskApprovalDateTime = taskApprovalDate.format('DD.MM.YYYY HH:mm');

            const taskApprovalId = data.content?.id;
            const taskId = data?.content?.taskApproval?.task?.id;
            const taskApprovalName = data?.content?.taskApproval?.task?.name;
            const taskApprovalUserId = data?.content?.user?.id;
            const currentUser = NySession.getUser();

            const taskApprovalType = data?.content?.taskApproval?.task?.type;
            const enTaskType = GetEnum({ enumName: 'TASK_TYPE' });

            const taskApprovalStatus = data?.content?.taskApproval?.status;
            const enTaskApprovalStatus = GetEnum({ enumName: 'TASK_APPROVAL_STATUS' });

            const isTaskAdminOrApprover = TasksRights.isTasksAdmin() || TasksRights.isTasksApprover();

            if (taskApprovalType == enTaskType.SERVICE) {
                if (isTaskAdminOrApprover) {
                    dispatch && dispatch(setPropOverviewTasks(4));
                    link = {
                        function: 'addTabPane',
                        path: '/tasks',
                        id: data?.refId,
                        notification: notif,
                        title: 'tasks.tab.list',
                        state: { id: data?.refId, activeTab: 4 },
                    };
                } else {
                    link = {
                        function: 'openItemInTab',
                        path: '/my-task-list',
                        modalName: 'createTaskUserIndex',
                        item: taskId,
                        notification: notif,
                        title: 'task.edit.edit.myTasks',
                    };
                }
                if (taskApprovalStatus == enTaskApprovalStatus.WAITING_FOR_APPROVAL) {
                    content = `${geti18nText(
                        'task.approval.notification.service.' + taskApprovalStatus
                    )}: ${taskApprovalName}`;
                } else {
                    content = `${geti18nText(
                        'task.approval.notification.service.header'
                    )}: ${taskApprovalName} ${geti18nText('task.approval.notification.service.' + taskApprovalStatus)}`;
                }
            } else if (taskApprovalType == enTaskType.SUBTASK) {
                if (taskApprovalUserId == currentUser?.id) {
                    dispatch && dispatch(setPropOverviewTasks(1));
                } else {
                    dispatch && dispatch(setPropOverviewTasks(2));
                }
                link = {
                    function: 'addTabPane',
                    path: '/tasks',
                    id: data?.refId,
                    notification: notif,
                    title: 'tasks.tab.list',
                    state: { id: data?.refId, activeTab: taskApprovalUserId == currentUser?.id ? 1 : 2 },
                };
                if (taskApprovalStatus == enTaskApprovalStatus.WAITING_FOR_APPROVAL) {
                    content = `${geti18nText(
                        'task.approval.notification.subtask.' + taskApprovalStatus
                    )}: ${taskApprovalName}`;
                } else {
                    content = `${geti18nText(
                        'task.approval.notification.subtask.header'
                    )}: ${taskApprovalName} ${geti18nText('task.approval.notification.subtask.' + taskApprovalStatus)}`;
                }
            }
            content = content + ` (ID: ${taskApprovalId} - ${taskApprovalDateTime})`;

            break;
        case 'EMPLOYEE_EDUCATION_APPROVAL':
            const eeduApprovalCreated = moment(data?.created);
            let eeduApprovalCreatedTimeFrom = eeduApprovalCreated.format('DD.MM.YYYY');
            let statusEduApp = data?.content?.status;

            const enEduStatusApproval = GetEnum({ enumName: 'EMPLOYEE_EDUCATION_STATUS' });
            let infoString = Object.values([
                enEduStatusApproval.DRAFT_REJECTED,
                enEduStatusApproval.DRAFT_APPROVED,
            ]).includes(statusEduApp)
                ? geti18nText('app.notification.education.request')
                : geti18nText('app.notification.education.info');

            content = `${infoString} ${data?.content?.educationName} 
                 ${geti18nText('app.notification.education.' + statusEduApp)}: ${eeduApprovalCreatedTimeFrom}`;
            link = {
                function: 'openItemInTab',
                path: '/human/employee-education-overview',
                modalName: 'employeeEducationEdit',
                item: data?.content?.educationId,
                notification: notif,
                title: 'menu.employee.education',
            };
            break;
        case 'EMPLOYEE_EDUCATION_REQUEST':
            const eeduRequestCreated = moment(data?.created);
            let eeduRequestCreatedTimeFrom = eeduRequestCreated.format('DD.MM.YYYY');
            let eduEmployeeRequest = data?.content?.personName ?? '';
            content = `${geti18nText('app.notification.education.employee')}: ${eduEmployeeRequest} 
            ${geti18nText('app.notification.education.new.request')} ${
                data?.content?.educationName
            }: ${eeduRequestCreatedTimeFrom} `;
            link = {
                function: 'openItemInTab',
                path: '/human/employee-education-overview',
                modalName: 'employeeEducationEdit',
                item: data?.content?.educationId,
                notification: notif,
                title: 'menu.employee.education',
            };
            break;
        case 'EMPLOYEE_EDUCATION_REALIZATION':
            const eeduRealizedCreated = moment(data?.created);
            let eeduRealizedTimeFrom = eeduRealizedCreated.format('DD.MM.YYYY');
            content = `${geti18nText('app.notification.education.relization')}: ${
                data?.content?.educationName
            }: ${eeduRealizedTimeFrom} `;
            link = {
                function: 'openItemInTab',
                path: '/human/employee-education-overview',
                modalName: 'employeeEducationEdit',
                item: data?.content?.educationId,
                notification: notif,
                title: 'menu.employee.education',
            };
            break;
        case 'EMPLOYEE_EVALUATION_REMINDER':
            const eerRealizedCreated = moment(data?.created).format('DD.MM.YYYY.');
            content = `${geti18nText('app.notification.evaluation.reminder')} "${
                data?.content?.message
            }": ${eerRealizedCreated} `;
            link = {
                function: 'openItemInTab',
                path: '/human/employee-evaluation',
                modalName: 'employeeEvaluationEdit',
                item: data?.content?.evaluationId,
                notification: notif,
                title: 'menu.evaluation.codebooks',
            };
            break;
        case 'EMPLOYEE_EVALUATION_STATUS_CHANGE':
            const eescRealizedCreated = moment(data?.created).format('DD.MM.YYYY.');
            content = `${geti18nText('app.notification.evaluation.statusChange')
                .replace('{0}', data?.content?.employeeName)
                .replace(
                    '{1}',
                    geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.' + data?.content?.status)
                )}: ${eescRealizedCreated} `;
            link = {
                function: 'openItemInTab',
                path: '/human/employee-evaluation',
                modalName: 'employeeEvaluationEdit',
                item: data?.content?.evaluationId,
                notification: notif,
                title: 'menu.evaluation.codebooks',
            };
            break;
        case 'WORKPLACE':
            const enWorkplaceStatus = GetEnum({ enumName: 'WORKPLACE_STATUS' });
            const workplaceCreated = moment(data?.created).format('DD.MM.YYYY.');
            let workPlacePerson = '';
            if (data.content?.createdBy?.person) {
                workPlacePerson =
                    data.content?.createdBy?.person?.firstName + ' ' + data.content?.createdBy?.person?.lastName;
            }
            if (data?.content?.status == enWorkplaceStatus.DRAFT) {
                content = `${geti18nText('notification.message.user.person.user.name')} ${workPlacePerson} 
                 ${geti18nText('app.notification.workplace.draft.created')}: ${workplaceCreated}`;
            } else if (data?.content?.status == enWorkplaceStatus.CONFIRMED) {
                content = `${geti18nText('app.notification.workplace.confirmed')}`;
            }
            link = {
                function: 'openItemInTab',
                path: '/human/codebooks/human/workplace',
                modalName: 'workplaceEdit',
                item: data?.content?.id,
                notification: notif,
                title: 'menu.workplace',
            };
            break;
        case 'EMPLOYEE_MILITARY_RECORDS_CERTIFICATE_EXPIRATION':
            const certificateDate = moment(data?.expirationDate);
            let certificateDateFormat = certificateDate.format('DD.MM.YYYY');
            content = `${geti18nText(
                'app.notification.military.records.security.certificate'
            )}: ${certificateDateFormat} `;
            link = {
                function: 'addTabPane',
                path: '/human/work-obligation/employee-mobilization',
                id: data?.content?.employee?.id,
                notification: notif,
                title: '',
                state: { id: data?.content?.employee?.id },
            };
            break;
        case 'EMPLOYEE_CHANGE':
            const updateDate = moment(data?.updated);
            let updateDateFormat = updateDate.format('DD.MM.YYYY. HH:mm');
            let updatedBy = `${
                data &&
                data?.content?.updatedBy &&
                data?.content?.updatedBy?.person &&
                data?.content?.updatedBy?.person?.firstName
                    ? data?.content?.updatedBy?.person?.firstName
                    : ''
            } 
                ${
                    data &&
                    data?.content?.updatedBy &&
                    data?.content?.updatedBy?.person &&
                    data?.content?.updatedBy?.person?.lastName
                        ? data?.content?.updatedBy?.person?.lastName
                        : ''
                }
                 [${geti18nText('notification.message.employee.person.user.employmentRecordId')} ${
                data && data.content?.updatedBy && data.content?.updatedBy?.employmentRecordId
                    ? data.content?.updatedBy?.employmentRecordId
                    : ''
            }]`;

            content = `${geti18nText('app.notification.military.records.employeeChange')
                .replace('{employee}', employeeName)
                .replace('{updated}', updateDateFormat)
                .replace('{updatedBy}', updatedBy)}`;

            const isMobilizationAdmin = MobilizationRights.isOnlyMobilizationAdmin();
            if (isMobilizationAdmin) {
                link = {
                    function: 'openItemInTab',
                    path: '/human/work-obligation/employee-mobilization',
                    modalName: 'employeeWorkObligationEdit',
                    item: data?.content?.employee?.id,
                    notification: notif,
                    title: 'menu.employee',
                    state: { id: data?.content?.employee?.id, replace: true },
                };
            } else {
                link = {
                    function: 'addTabPane',
                    path: '/human/employee/' + data?.content?.employee?.id,
                    id: data?.content?.employee?.id,
                    notification: notif,
                    state: { id: data?.content?.employee?.id, activeTab: 'history', replace: true },
                };
            }

            break;
        case 'MESSAGE':
            const messageContent: any = (
                <div>
                    {data?.content?.name} - {data?.content?.description} <br />
                    {data?.content?.files?.map((file: any, index: number) => (
                        <span style={{ fontSize: '12px', display: 'block' }}>
                            <Tooltip title={geti18nText('app.setting.name.NY_ERP_HUMAN.PRINT_SETTINGS.FILE_DOWNLOAD')}>
                                <a
                                    key={index}
                                    onClick={() =>
                                        fileDownload(CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file?.file, null, file?.name)
                                    }
                                    style={{
                                        marginRight: '2px',
                                    }}
                                >
                                    {file?.name}
                                </a>
                                <br />
                            </Tooltip>
                        </span>
                    ))}
                </div>
            );
            content = messageContent;
            break;
        case 'SYSTEM':
            // za doradu dok se na backendu slozi univerzalni template za slanje notifikacija
            const sysDate = moment(data?.created);

            let formatedSystemDate = sysDate.format('DD.MM.YYYY. HH:mm');

            const enSystemNotificationType = GetEnum({ enumName: 'SYSTEM_NOTIFICATION_TYPE' });
            let pathSystemUrl: any = '/';
            let titleSystem: any = 'menu.dashboard';
            let activeTabKey: any = null;
            let functionName = 'addTabPane';

            let statisticData = data?.content?.statistic;
            let statisticContent = '';
            if (statisticData) {
                let sdE = statisticData.error;
                let sdS = statisticData.success;
                let sdT = statisticData.total;

                let sdCPMOE = statisticData.changedPriceMealOrderError;
                let sdCPMOS = statisticData.changedPriceMealOrderSuccess;
                let sdCPM = statisticData.changedPriceMeals;
                let sdDM = statisticData.deactivatedMeals;
                let sdNM = statisticData.newMeals;

                const sdCPMOEedited =
                    sdCPMOE != 0
                        ? `${geti18nText('notification.message.syncMeal.changedPriceMealOrderError')}: ${sdCPMOE}, `
                        : '';
                const sdCPMOSedited =
                    sdCPMOS != 0
                        ? `${geti18nText('notification.message.syncMeal.changedPriceMealOrderSuccess')}: ${sdCPMOS}, `
                        : '';
                const sdCPMedited =
                    sdCPM != 0 ? `${geti18nText('notification.message.syncMeal.changedPriceMeals')}: ${sdCPM}, ` : '';
                const sdDMEedited =
                    sdDM != 0 ? `${geti18nText('notification.message.syncMeal.deactivatedMeals')}: ${sdDM}, ` : '';
                const sdNMedited =
                    sdNM != 0 ? `${geti18nText('notification.message.syncMeal.newMeals')}: ${sdNM}, ` : '';
                const sdTedited = sdT != 0 ? `${geti18nText('notification.message.syncMeal.total')}: ${sdT}` : '';

                statisticContent =
                    data.content?.statistic && data.content?.systemType == enSystemNotificationType.BILLING_ORDER
                        ? `${geti18nText('notification.message.billingOrder.error')}: ${sdE}, ${geti18nText(
                              'notification.message.billingOrder.success'
                          )}: ${sdS}, ${geti18nText('notification.message.billingOrder.total')}: ${sdT}`
                        : data.content?.systemType == enSystemNotificationType.SYNC_MEAL
                        ? sdCPMOEedited + sdCPMOSedited + sdCPMedited + sdDMEedited + sdNMedited + sdTedited
                        : '';
            }

            switch (Number(data?.content?.systemType ?? 0)) {
                case enSystemNotificationType.GROUP_ORDER_SPECIFICATION:
                    pathSystemUrl = '/webshop/meals/meal-group-orders';
                    titleSystem = 'menu.meals.group.order.menu';
                    break;
                case enSystemNotificationType.GROUP_ORDER_RECAPITULATION:
                    pathSystemUrl = '/webshop/meals/meal-group-orders';
                    titleSystem = 'menu.meals.group.order.menu';
                    break;
                case enSystemNotificationType.BILLING_ORDER:
                    pathSystemUrl = '/webshop/meals/meal-payment';
                    titleSystem = 'menu.meals.payment';
                    break;
                case enSystemNotificationType.SYNC_MEAL:
                    pathSystemUrl = '/webshop/codebooks/meals/meal';
                    titleSystem = 'menu.meals.meal';
                    hideMenuLink = true;
                    break;
                case enSystemNotificationType.SYNC_MEAL_GROUP:
                    pathSystemUrl = '/webshop/codebooks/meals/meal-group';
                    titleSystem = 'menu.meals.meal.group';
                    break;
                case enSystemNotificationType.EMPLOYEE_ANNUAL_LEAVE:
                    pathSystemUrl = '/human/employeeLeave';
                    titleSystem = 'menu.employeeLeave';
                    activeTabKey = '1';
                    break;
                case enSystemNotificationType.ASSET_INITIAL_STATE:
                    pathSystemUrl = '/accounting/assets/asset-initial-state';
                    titleSystem = 'menu.assets.initialState';
                    if (data.content.data) {
                        errors = {
                            data: data.content.data,
                            title: geti18nText(data?.content?.message),
                        };
                    }

                    formatedSystemDate = data.content.created;
                    hideMenuLink = true;
                    notificationSwitchType = 'assetInitialState';
                    break;
                case enSystemNotificationType.ASSET:
                    pathSystemUrl = '/webshop/codebooks/webshop-asset/asset';
                    titleSystem = 'menu.asset';
                    if (data.content.data) {
                        errors = {
                            data: data.content.data,
                            title: geti18nText(data?.content?.message),
                        };
                    }
                    formatedSystemDate = data.content.created;
                    hideMenuLink = true;
                    notificationSwitchType = 'assetInitialState';
                    break;
                case enSystemNotificationType.ITEM:
                    pathSystemUrl = '/administration/codebooks/item';
                    titleSystem = 'menu.item';
                    if (data.content.data) {
                        errors = {
                            data: data.content.data,
                            title: geti18nText(data?.content?.message),
                        };
                    }
                    formatedSystemDate = data.content.created;
                    hideMenuLink = true;
                    notificationSwitchType = 'item';
                    break;
                case enSystemNotificationType.ACCOUNT:
                    pathSystemUrl = '/webshop/codebooks/webshop-asset/asset';
                    titleSystem = 'menu.accounting';
                    if (data.content.data) {
                        errors = {
                            data: data.content.data,
                            title: geti18nText(data?.content?.message),
                        };
                    }
                    formatedSystemDate = data.content.created;
                    hideMenuLink = true;
                    notificationSwitchType = 'account';
                    break;
                case enSystemNotificationType.ASSET_DEPRECATION_GROUP:
                    pathSystemUrl = '/webshop/codebooks/webshop-asset/asset';
                    titleSystem = 'menu.asset';
                    if (data.content.data) {
                        errors = {
                            data: data.content.data,
                            title: geti18nText(data?.content?.message),
                        };
                    }
                    formatedSystemDate = data.content.created;
                    hideMenuLink = true;
                    notificationSwitchType = 'asset';
                    break;
                case enSystemNotificationType.EMPLOYEE_EVALUATION_GENERATION:
                    pathSystemUrl = '/human/employee-evaluation';
                    titleSystem = 'menu.evaluation';
                    content = data.content?.message;
                    break;
                default:
                    pathSystemUrl = '/';
                    titleSystem = 'menu.dashboard';
                    break;
            }

            link = {
                function: functionName,
                path: pathSystemUrl,
                notification: notif,
                title: titleSystem,
                state: activeTabKey ? { activeTabKey: activeTabKey } : undefined,
            };
            content =
                `${data && data.content?.message ? geti18nText(data.content?.message) : ''}    
            ${' - ' + formatedSystemDate} ` + statisticContent;

            break;
    }

    return {
        content: content,
        link: link,
        errors: errors,
        hideMenuLink: hideMenuLink,
        notificationSwitchType: notificationSwitchType,
    };
};
