import {
    ApartmentOutlined,
    AuditOutlined,
    BranchesOutlined,
    CarOutlined,
    CarryOutOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    CloudOutlined,
    CoffeeOutlined,
    CreditCardOutlined,
    DatabaseOutlined,
    DiffOutlined,
    EditOutlined,
    ExceptionOutlined,
    EyeInvisibleOutlined,
    FileDoneOutlined,
    FlagOutlined,
    ForkOutlined,
    FormOutlined,
    ForwardOutlined,
    FrownOutlined,
    GlobalOutlined,
    HomeOutlined,
    HourglassOutlined,
    IssuesCloseOutlined,
    LockOutlined,
    MedicineBoxOutlined,
    MinusCircleOutlined,
    MobileOutlined,
    PartitionOutlined,
    PauseCircleOutlined,
    PlusCircleOutlined,
    ProfileOutlined,
    ScissorOutlined,
    SnippetsOutlined,
    StarOutlined,
    StopOutlined,
    SwapOutlined,
    TabletOutlined,
    ToolOutlined,
    UserAddOutlined,
    WarningTwoTone,
    ZoomOutOutlined,
} from '@ant-design/icons';

import { NyRequestResolver, NySession, NyUtils, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Card, Col, Image, Modal, Row, Tag, Tooltip, message, notification } from 'antd';
import CSS from 'csstype';
import moment from 'moment';
import { ReactNode } from 'react';
import { CONSTANTS_REQ, FILE_FALLBACK, TRAVEL_WARRANT_WIZARD_EDIT_STEPS as WIZARD_STEP } from './Constants';
import { GetEnum, GetEnumValueForName } from './Enums';

import { round } from 'lodash';
import '../assets/scss/line-awesome.min.css';
import useEnum from '../hooks/useEnum';
import { AdministrationRights } from '../rights/administrationRights';
import { HumanResourcesRights } from '../rights/humanResourcesRights';

/// DATE
export const setDateFormat = function (date: any, format: string = 'yyyy-MM-DD HH:mm') {
    return moment(date, format);
};

export const getDateFormat = function (date: any, format: string = 'yyyy-MM-DD HH:mm') {
    return moment(date).format(format);
};

export const getRowDateFormatFromTimestamp = function (timestamp: any, format: string = 'yyyy-MM-DD HH:mm') {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return getDateFormat(formattedDate, format);
};

//setEmailFormat
export const setEmailFormat = function (email: any) {
    return email.split(';');
};

export const normalizeEmailFormat = function (email: any) {
    return email.toString().replaceAll(',', ';');
};

export const getWorkingDays = (endDate: any, startDate: any) => {
    var lastDay = moment(endDate);
    var firstDay = moment(startDate);
    let calcBusinessDays = 1 + (lastDay.diff(firstDay, 'days') * 5 - (firstDay.day() - lastDay.day()) * 2) / 7;

    if (lastDay.day() == 6) calcBusinessDays--; //SAT
    if (firstDay.day() == 0) calcBusinessDays--; //SUN
    return Math.ceil(calcBusinessDays);
};
/// END DATE

// IS JSON CHECK
export const isValidJson = (str: any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

/// ENUM
function sortByText(a: any, b: any, order: any = 'ASC') {
    if (a && a.text && b && b.text) {
        const diff = a.text.toLowerCase().localeCompare(b.text.toLowerCase());
        if (order === 'ASC') return diff;
        return -1 * diff;
    } else if (a && a.label && b && b.label) {
        const diff = a.label.toLowerCase().localeCompare(b.label.toLowerCase());
        if (order === 'ASC') return diff;
        return -1 * diff;
    }
    return 1;
}
//sorted array
export const getEnumArray = function (enumName: any, text: any = 'text', id: any = 'id', enumAlias: any = undefined) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
            }
            types.push(ret);
        }
    }
    return types.sort(sortByText);
};

//sorted array
// with text
export const getEnumArrayWithRawDataText = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);

            types.push(ret);
        }
    }
    return types.sort(sortByText);
};

export const getEnumArrayWithRawData = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            ret[text] = aTypes[key];

            types.push(ret);
        }
    }
    return types.sort(sortByText);
};

//sorted array
export const getEnumArrayRetIDValue = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key].value;
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
            }
            types.push(ret);
        }
    }
    return types.sort(sortByText);
};

//sorted array
export const getEnumArrayWithGroup = function (
    enumName: any,
    groupName: any = undefined,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];

    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key].value;

            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
                ret[groupName] = aTypes[key][groupName];
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
                ret[groupName] = aTypes[key][groupName];
            }
            types.push(ret);
        }
    }
    return types.sort(sortByText);
};
export const getEnumArrayWithKey = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
                ret['key'] = key;
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
                ret['key'] = key;
            }
            types.push(ret);
        }
    }
    return types.sort(sortByText);
};

export const getEnumArrayWithId = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id].value);
                ret['key'] = key;
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id].value);
                ret['key'] = key;
            }
            types.push(ret);
        }
    }
    return types.sort(sortByText);
};
//not sorted
export const getEnumArrayStatus = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
            }
            types.push(ret);
        }
    }
    return types;
};

export const getEnumArrayStatusNotDraft = function (
    enumName: any,
    text: any = 'text',
    id: any = 'id',
    enumAlias: any = undefined
) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
            }
            if (ret[id] >= 0) {
                types.push(ret);
            }
        }
    }
    return types;
};

export const getEnumBookedArray = function () {
    return [
        { id: 0, text: geti18nText('app.enum.BOOKED_TYPE.0') },
        { id: 1, text: geti18nText('app.enum.BOOKED_TYPE.1') },
    ];
};

export const getEnumBooleanArray = function () {
    return [
        { id: 1, text: geti18nText('app.enum.boolean.yes') },
        { id: 0, text: geti18nText('app.enum.boolean.no') },
    ];
};

export const getEnumArrayNotificationSettings = function (moduleType: any = undefined) {
    const enumTypes = GetEnum({ enumName: 'NOTIFICATION_TYPE_SUPERVISOR' });
    const moduleTypes = moduleType ? GetEnum({ enumName: 'NOTIFICATION_TYPE_MODULE_TYPE' }) : undefined;
    let types: any = [];
    for (var key in enumTypes) {
        if (
            (moduleType != undefined &&
                moduleTypes != undefined &&
                moduleTypes[key] != undefined &&
                moduleTypes[key] == moduleType) ||
            moduleType == undefined
        ) {
            let ret: any = {};
            ret['id'] = enumTypes[key];
            ret['text'] = geti18nText(`app.enum.NOTIFICATION_TYPE_REQUEST.` + ret['id']);
            types.push(ret);
        }
    }
    return types.sort(sortByText);
};

export const setEnumFormatSubgroup = function (enumName: any, value: any, enumAlias: any = undefined) {
    const enums: [] = getEnumArrayWithId(enumName, 'text', 'id', enumAlias);

    if (enums === undefined) {
        return {};
    }

    return enums.find((e: any) => {
        return e.id.value === value;
    });
};

export const setEnumFormat = function (enumName: any, value: any, enumAlias: any = undefined) {
    const enums: [] = getEnumArray(enumName, 'text', 'id', enumAlias);

    if (enums === undefined) {
        return {};
    }

    return enums.find((e: any) => {
        return e.id === value;
    });
};

export const setEnumFormatRaw = function (enumName: any, value: any, enumAlias: any = undefined) {
    const enums: [] = getEnumArrayWithRawData(enumName, 'text', 'id', enumAlias);
    if (enums === undefined) {
        return {};
    }

    return enums.find((e: any) => {
        return e.id === value;
    });
};

// getEnumArrayWithKey
export const setEnumFormatWithKey = function (enumName: any, value: any, enumAlias: any = undefined) {
    const enums: [] = getEnumArrayWithKey(enumName, 'text', 'id', enumAlias);

    if (enums === undefined) {
        return {};
    }
    return enums.find((e: any) => {
        return e.id === value.id;
    });
};

export const getEnumFormat = function (value: any) {
    if (value === undefined || value === null) {
        return null;
    }

    return value.constructor.name === 'Object'
        ? value.hasOwnProperty('id')
            ? value.id
            : value.hasOwnProperty('key')
            ? value.key
            : null
        : value;
};
/// END ENUM

/// SEARCH
export const setSearchFormat = function (value: any, text: any = 'name', label: any = 'text', code: any = undefined) {
    if (value === undefined || value === null) {
        return null;
    }

    if (value.constructor.name === 'Object') {
        if (value.hasOwnProperty('id') && value.hasOwnProperty(text)) {
            let data: any = { id: value['id'] };
            data[label] = value[text];
            if (code && value[code] != undefined) {
                data[label] += ' (' + value[code] + ')';
            }
            return data;
        }
    }

    return null;
};

export const getSearchFormat = function (value: any) {
    if (value === undefined || value === null) {
        return null;
    }

    return value.constructor.name === 'Object'
        ? value.hasOwnProperty('id') && value.id >= 0
            ? { id: value.id }
            : value.hasOwnProperty('key') && value.key >= 0
            ? { id: value.key }
            : null
        : { id: value };
};
/// END SEARCH

/// NUMBER
export const numberFormat = (value: number, dec: number = 2) => {
    return new Intl.NumberFormat('HR-hr', {
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
    }).format(value);
};

/// HOURS
export const hoursFormat = (number: number) => {
    var hours = Math.floor(number);
    var minute = Math.round((number - hours) * 60);
    return `${hours}:${minute.toString().padStart(2, '0')}`;
};

/// POSITIVE ZERO
export const makePositiveZero = (value: any) => {
    if (value == '−0,00') {
        return '0,00';
    }
    return value;
};
/// END NUMBER

/// NOTIFICATION
export const okNotification = (
    message: String = geti18nText('app.default.save.ok'),
    description: any = geti18nText('app.default.save.ok.desc'),
    duration: any = 3
) => {
    notification.success({
        message: message,
        description: description,
        duration: duration,
    });
};

export const infoNotification = (message: String, description?: string) => {
    notification.info({
        message: message,
        description: description,
        duration: 3,
    });
};

export const showNotification = (message: any, data: any) => {
    notification.open({
        message: message,
        description: <>{data}</>,
        duration: 10,
    });
};

export const warningNotification = (title: String, content?: string) => {
    Modal.warning({
        title: title,
        content: content,
        style: { marginTop: '8rem' },
        okText: geti18nText('app.default.button.ok'),
    });
};

export const errorNotification = (content?: any) => {
    if (
        content != undefined &&
        typeof content === 'object' &&
        content.hasOwnProperty('error') &&
        geti18nText(content.error) != undefined
    ) {
        Modal.error({
            title: geti18nText('app.default.save.nok'),
            content: geti18nText(content.error),
            style: { marginTop: '8rem' },
            okText: geti18nText('app.default.button.ok'),
        });
    } else if (content != undefined && typeof content === 'string' && content.includes('|')) {
        let errorStr = content.split('|')[0].replace('"', '');
        let errorStrAfter = content.slice(content.indexOf('|') + 1).replace('"', '');
        const moContent: any = <div>{errorStrAfter}</div>;

        Modal.error({
            title: geti18nText(errorStr) ? geti18nText(errorStr) : geti18nText('app.default.save.nok'),
            content: moContent,
            style: { marginTop: '8rem' },
            okText: geti18nText('app.default.button.ok'),
        });
    } else if (content != undefined && typeof content === 'string') {
        Modal.error({
            title: geti18nText('app.default.save.nok'),
            content: content,
            style: { marginTop: '8rem' },
            okText: geti18nText('app.default.button.ok'),
        });
    } else {
        Modal.error({
            title: geti18nText('app.default.save.nok'),
            style: { marginTop: '8rem' },
            okText: geti18nText('app.default.button.ok'),
        });
    }
};

export const errorNodeNotification = (content: ReactNode, title: string = 'app.default.save.nok') => {
    Modal.error({
        title: geti18nText(title),
        content: content,
        style: { marginTop: '8rem' },
        okText: geti18nText('app.default.button.ok'),
    });
};

export const errorCustomNotification = (title: String = geti18nText('app.default.error'), content?: any) => {
    Modal.error({
        title: title,
        content: content,
        style: { marginTop: '8rem' },
        okText: geti18nText('app.default.button.ok'),
    });
};

export const getErrorNotificationMessage = (result?: any) => {
    if (result && result.data && result.data.error) {
        errorNotification(result.data.error);
    } else if (result && result.data && result.data instanceof String) {
        errorNotification(result.data);
    } else {
        errorNotification();
    }
};
/// END NOTIFICATION

/// COLUMN SORT AND SEARCH
export const columnSort = (a: any, b: any) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
};

export const columnSearch = (condition: any, value: any, data: any) => {
    switch (condition) {
        case 'contains':
            return data.toLowerCase().includes(value.toLowerCase());
        case 'starts_with':
            return data.toLowerCase().startsWith(value.toLowerCase());
        case 'ends_with':
            return data.toLowerCase().endsWith(value.toLowerCase());
        case 'equals':
            return data.toLowerCase() === value.toLowerCase();
        case 'not_equals':
            return data.toLowerCase() !== value.toLowerCase();
        default:
            return true;
    }
};
/// END COLUMN SORT AND SEARCH

/// CUSTOM EMPLOYEE RENDER OPTION
export const customEmployeeRenderOption = (option: any) => {
    return (
        <div
            first-name={option?.person?.firstName}
            last-name={option?.person?.lastName}
            e-mail={option?.contact?.email}
            business-unit={option?.businessUnit}
        >
            <span style={{ display: 'block' }}>{option.text}</span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                {option.oib && (
                    <span style={{ display: 'block' }}>
                        {option.oib && geti18nText('person.edit.oib') + ': ' + option.oib}
                    </span>
                )}
                {option.employmentRecordId && (
                    <span style={{ display: 'block' }}>
                        {' '}
                        {geti18nText('employee.edit.eployment.record') + ': ' + option.employmentRecordId}
                    </span>
                )}
                {option.businessUnit && option.businessUnit.name && (
                    <span style={{ display: 'block' }}>
                        {geti18nText('employee.edit.businessUnit') +
                            ': ' +
                            option.businessUnit.name +
                            (option.businessUnit.code ? ' (' + option.businessUnit.code + ')' : '')}{' '}
                    </span>
                )}
            </span>
        </div>
    );
};

export const customAttendanceRenderOption = (option: any) => {
    return <>{option.employmentRecordId ? option.text + ' (' + option.employmentRecordId + ')' : option.text}</>;
};

///CUSTOM TRAVEL WAGE RENDER OPTION
export const customTravelWageRenderOption = (option: any) => {
    return (
        <>
            <span style={{ display: 'block' }}>{option?.text?.length > 0 ? option.text : option.name}</span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                {option.type && (
                    <span style={{ display: 'block' }}>
                        {geti18nText('travelWarrantType.table.column.wageType') + ': '}
                        {getTravelWarrantTypeIcon(option.type)}
                    </span>
                )}
                {option.amount && (
                    <span style={{ display: 'block' }}>
                        {geti18nText('travelWage.table.column.amount') + ': '}
                        {numberFormat(option.amount)} {option?.currency?.isoCode ?? ''}
                    </span>
                )}
                {(option.validFrom || option.validTo) && (
                    <span style={{ display: 'block' }}>
                        {option.validFrom &&
                            geti18nText('app.default.filter.date.from') +
                                ' ' +
                                moment(option.validFrom).format('DD.MM.YYYY')}
                        {option.validFrom && option.validTo && ' - '}
                        {option.validTo &&
                            geti18nText('app.default.filter.date.to') +
                                ' ' +
                                moment(option.validTo).format('DD.MM.YYYY')}
                    </span>
                )}
                <span style={{ display: 'none' }}>{option.nonStandardWageNr}</span>
            </span>
        </>
    );
};

export const getTravelWageSelectOption = (travelWage: any) => {
    let wageData: any = { text: travelWage?.name };

    wageData['nonStandardWageNr'] = travelWage?.nonStandardWageNr;
    wageData['type'] = travelWage?.type;
    wageData['amount'] = travelWage?.amount;
    wageData['validFrom'] = travelWage?.validFrom;
    wageData['nonStandardWageNr'] = travelWage?.nonStandardWageNr;
    wageData['currency'] = travelWage?.currency;
    return {
        key: travelWage.id,
        id: travelWage.id,
        text: customTravelWageRenderOption(wageData),
    };
};

/// CUSTOM DELIVERY SPOT TIME RENDER OPTION
export const customDeliverySpotTimeRenderOption = (option: any) => {
    return (
        <div custom-data={option}>
            <span style={{ display: 'block' }}>
                {option?.delivery?.name &&
                    geti18nText('app.enum.DELIVERY_TYPE.' + option.type) + ' - ' + option.delivery.name}
            </span>
            <span style={{ display: 'block' }}>{option?.text && option.text}</span>

            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: '11px', display: 'block' }}>
                {option?.delivery?.time && (
                    <span style={{ display: 'block' }}>
                        {option?.delivery?.time &&
                            geti18nText('meal.delivery.search.deliverySpot.time') + ': ' + option?.delivery?.time}
                    </span>
                )}
            </span>
        </div>
    );
};

/// CUSTOM MENU WIDGET RENDER OPTION
export const customMenuWidgetRenderOption = (option: any, isOverviewIndex?: any) => {
    return (
        <span
            custom-data={option}
            className={option?.delivery?.name.length > 25 && isOverviewIndex ? 'search-dropdown-marquee' : ''}
        >
            {option?.delivery?.name && option.delivery.name}
        </span>
    );
};

//TEAM RENDER OPTION
export const customTeamRenderOption = (option: any) => {
    return (
        <div>
            <span style={{ display: 'block' }}>
                {option.code ? option.name + ' (' + option.code + ')' : option.name}
            </span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: '11px', display: 'block' }}>
                {option.description}
            </span>
        </div>
    );
};

// TEAM SELECT OPTION
export const getTeamSelectOption = (option: any) => {
    let optionData: any = { ...option };
    return {
        key: option.id,
        id: option.id,
        text: customTeamRenderOption(optionData),
    };
};

// DELIVERY SPOT TIME SELECT OPTION
export const getDeliverySpotTimeSelectOption = (option: any) => {
    if (option.delivery == undefined || option.delivery == null) {
        option.delivery = {
            name: option.name,
            time: option.time,
        };
    }
    let optionData: any = { ...option, ...(option.delivery.time = option.time) };
    return {
        key: option.id,
        id: option.id,
        text: customDeliverySpotTimeRenderOption(optionData),
    };
};
// EMPLOYEE SELECT OPTION
export const getEmployeeSelectOption = (employee: any, customData: any = 'employmentRecordId') => {
    if (Array.isArray(employee)) {
        let employees = employee.map((val: any) => {
            let employeeData: any = { text: val?.person?.firstName + ' ' + val?.person?.lastName };
            employeeData[customData] = customData === 'employmentRecordId' ? val?.employmentRecordId : val?.person?.oib;
            employeeData['businessUnit'] = val?.businessUnit;
            return {
                key: val.id,
                id: val.id,
                label: customEmployeeRenderOption(employeeData),
            };
        });
        return employees;
    } else {
        let employeeData: any = { text: employee?.person?.firstName + ' ' + employee?.person?.lastName };
        employeeData[customData] =
            customData === 'employmentRecordId' ? employee?.employmentRecordId : employee?.person?.oib;
        employeeData['businessUnit'] = employee?.businessUnit;
        return {
            key: employee.id,
            id: employee.id,
            text: customEmployeeRenderOption(employeeData),
        };
    }
    return undefined;
};

export const getUserSelectOption = (data: any) => {
    let employeeData: any = { text: data?.person?.firstName + ' ' + data?.person?.lastName };
    employeeData['businessUnit'] = data?.businessUnit;
    employeeData['employmentRecordId'] = data?.businessUnit?.employmentRecordId;

    return {
        key: data.id,
        id: data.id,
        text: customEmployeeRenderOption(employeeData),
    };
};

export const getUserSelectOptionFromSession = (data: any) => {
    return {
        key: data.id,
        id: data.id,
        text: data.fullName,
    };
};

export const customPersonRenderOption = (option: any) => (
    <>
        <span>{option.text}</span>
    </>
);

export const getPersonSelectOption = (person: any) => {
    if (Array.isArray(person)) {
        let persons = person.map((val: any) => {
            let personData: any = { text: val.firstName + ' ' + val.lastName };
            return {
                key: val.id,
                id: val.id,
                label: customPersonRenderOption(personData),
            };
        });
        return persons;
    } else {
        let personData: any = { text: person.firstName + ' ' + person.lastName };
        return {
            key: person.id,
            id: person.id,
            text: customPersonRenderOption(personData),
        };
    }
};
/// END CUSTOM EMPLOYEE RENDER OPTION

/// RANDOM STRING GENERATOR
export const stringGenerator = (length: number) => {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
};
/// END RANDOM STRING GENERATOR

/// JOPPD CODES
export const fetchJoppdCodes = async function (page: any, node: any) {
    let types: any = [];
    let joppdCodesConditions = encodeURI(
        JSON.stringify([
            { field: 'page', condition: 'equals', value: page },
            { field: 'node', condition: 'equals_string', value: node },
        ])
    );

    await NyRequestResolver.requestGet(CONSTANTS_REQ.JOPPD_CODE.SEARCH, {
        search: joppdCodesConditions,
        order: 'code',
    }).then((result: any) => {
        for (var key in result.data) {
            types.push(setSearchFormat(result.data[key], 'code'));
        }
    });
    return types;
};

export const formatJoppdCodeSearch = async function (code: any, page: any, node: any) {
    let temp;
    let parms = {
        page: page,
        node: node,
        code: code,
    };
    await NyRequestResolver.requestGet(CONSTANTS_REQ.JOPPD_CODE.EDIT, parms).then((result: any) => {
        if (result.data.id) {
            temp = { id: result.data['id'], code: result.data['code'] };
        } else {
            temp = { id: 0, code: code };
        }
    });
    return temp;
};

export const setJoppdCodeDefaultFilterValue = function (page: any, node: any) {
    return [
        { field: 'page', condition: 'equals', value: page },
        { field: 'node', condition: 'equals_string', value: node },
    ];
};

/// END JOPPD CODES

///FILE DOWNLOAD

export const fileDownload = (url: string, parms: any, filename: string) => {
    message.loading(geti18nText('import.text.loading'), 0);
    console.log('FILE DOWNLOAD');
    NyRequestResolver.requestGet(url, { ...parms, v: Date.now() }, false, true).then((result: any) => {
        if (result.status === RESPONSE.OK && result.data) {
            result.data.filename = filename;
            downloadFile(result);
        } else {
            errorNotification(geti18nText('app.download.error'));
        }
        message.destroy();
    });
};

export const downloadFile = (result: any) => {
    if (result.data) {
        const url = window.URL.createObjectURL(result.data.file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        if (result.data.filename) {
            a.download = result.data.filename;
        } else {
            a.download = 'download';
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
    } else {
        return false;
    }
};

export const downloadFileCustom = (result: any) => {
    if (result.data) {
        const url = window.URL.createObjectURL(result.data.file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        if (result.data.filename) {
            a.download = result.data.filename;
        } else {
            a.download = 'download';
        }
        a.setAttribute('download', 'Dokument.docx');
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
    } else {
        return false;
    }
};

///END FILE DOWNLOAD

///CURRENCY ISO CODE
export const formatCurrencyIsoCodeSearch = async function (code: any) {
    let temp;
    await NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.ISO_CODE, { code }).then((result: any) => {
        if (result.data.id) {
            temp = { id: result.data['id'], isoCode: result.data['isoCode'], text: result.data['isoCode'] };
        } else {
            temp = { id: 0, isoCode: code };
        }
    });
    return temp;
};

///END CURRENCY ISO CODE

/// KEY EVENTS
export const onKeyDown = (event: any) => {
    if (event.keyCode === 18) {
        event.preventDefault();
        event.stopPropagation();
    }

    if (event.keyCode === 13 && event.ctrlKey && event.target.nodeName === 'TEXTAREA') {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.target.value += '\n';
    } else if (event.keyCode === 13 && (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA')) {
        event.preventDefault();
        event.stopImmediatePropagation();
        var form = event.target.form;
        if (form !== null) {
            var index = Array.prototype.indexOf.call(form, event.target);

            do {
                if (event.shiftKey) {
                    index--;
                    if (index < 0) {
                        index = 0;
                        break;
                    }
                } else {
                    index++;
                    if (index >= form.elements.length) {
                        index = form.elements.length - 1;
                        break;
                    }
                }
            } while (
                (form.elements[index].nodeName !== 'INPUT' && form.elements[index].nodeName !== 'TEXTAREA') ||
                form.elements[index].id.includes('_id') ||
                form.elements[index].id.toLowerCase().endsWith('id') ||
                form.elements[index].disabled === true
            );

            form.elements[index].focus();
        }
    }
};

export const onKeyDownTextAreaCustom = (event: any) => {
    if (event.keyCode === 13 && event.target.nodeName === 'TEXTAREA') {
        event.preventDefault();
        event.stopPropagation();
        event.target.value += '\n';
    } else if (event.keyCode === 9 && event.target.nodeName === 'TEXTAREA') {
        event.preventDefault();
        event.stopPropagation();
        event.target.value += '\t';
    }
};

export const onKeyDownTextAreaWarehouseCustom = (event: any) => {
    const textArea = event.target;
    const caretPos = textArea?.selectionStart;

    if (event.keyCode === 13 && event.target.nodeName === 'TEXTAREA') {
        event.preventDefault();
        event.stopPropagation();
        event.target.value += '\n';
    } else if (event.keyCode === 9 && event.target.nodeName === 'TEXTAREA') {
        event.preventDefault();
        event.stopPropagation();
        event.target.value += '\t';
    } else if (event.key === 'ArrowUp') {
        moveCursorVertically(textArea, caretPos, -1);
    } else if (event.key === 'ArrowDown') {
        moveCursorVertically(textArea, caretPos, 1);
    } else if (event.key === 'ArrowLeft') {
        moveCursorHorizontally(textArea, caretPos, -1);
    } else if (event.key === 'ArrowRight') {
        moveCursorHorizontally(textArea, caretPos, 1);
    }
};

const moveCursorVertically = (textArea: any, caretPos: number, offset: number) => {
    const lines = textArea.value.split('\n');
    const currentLine = lines.slice(0, caretPos).join('\n');
    const currentLineLength = currentLine.length;
    const targetLine = lines.slice(0, caretPos).length + offset;
    const targetLineLength = lines[targetLine]?.length || 0;
    const newCaretPos = caretPos - currentLineLength + targetLineLength + 1;
    textArea.setSelectionRange(newCaretPos, newCaretPos);
};

const moveCursorHorizontally = (textArea: any, caretPos: number, offset: number) => {
    textArea.setSelectionRange(caretPos + offset, caretPos + offset);
};

/// END KEY EVENTS

export const toBinary = (string: string) => {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = '';
    for (let i = 0; i < charCodes.byteLength; i++) {
        result += String.fromCharCode(charCodes[i]);
    }
    return result;
};

export const fromBinary = (binary: any) => {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    const charCodes = new Uint16Array(bytes.buffer);
    let result = '';
    for (let i = 0; i < charCodes.length; i++) {
        result += String.fromCharCode(charCodes[i]);
    }
    return result;
};
/// CUSTOM EMPLOYEE PAID LEAVE TYPE RENDER OPTION
export const customEmployeePaidLeaveTypeRenderOption = (option: any) => (
    <>
        <span style={{ display: 'block' }}>{option.text ? option.text : option.name ? option.name : ''}</span>
        {option.days && (
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>
                {geti18nText('employeePaidLeaveType.table.column.days') + ': ' + option.days}
            </span>
        )}
        {option.description && (
            <span
                style={{
                    paddingLeft: '10px',
                    color: 'gray',
                    fontSize: 'x-small',
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                }}
            >
                {geti18nText('employeePaidLeaveType.table.column.description') + ': ' + option.description}
            </span>
        )}
    </>
);

export const getEmployeePaidLeaveTypeSelectOption = (data: any) => {
    let paidLeaveTypeData: any = {
        text: data.name ? data.name : '',
        days: data.days ? data.days : '',
        description: data.description ? data.description : '',
    };
    return {
        key: data.id,
        id: data.id,
        name: customEmployeePaidLeaveTypeRenderOption(paidLeaveTypeData),
    };
};
/// END CUSTOM EMPLOYEE PAID LEAVE TYPE RENDER OPTION

/// CUSTOM ASSET DEPRECIATION GROUP RENDER OPTION
export const customAssetDepreciationGroupRenderOption = (option: any) => (
    <>
        <span>
            {option.depreciationRate > 0 && '(' + option.depreciationRate + '%) '}
            {option.code && '[' + option.code + '] '}
            {option.text ? option.text : option.name ? option.name : ''}
        </span>
    </>
);

export const getAssetDepreciationGroupSelectOption = (data: any) => {
    let assetData: any = {
        text: data.name ? data.name : '',
        depreciationRate: data.depreciationRate ? data.depreciationRate : '',
        code: data.code ? data.code : '',
    };
    return {
        key: data.id,
        id: data.id,
        name: customAssetDepreciationGroupRenderOption(assetData),
    };
};
/// END CUSTOM ASSET DEPRECIATION GROUP RENDER OPTION

/// CUSTOM ASSET RENDER OPTION
export const customAssetRenderOption = (option: any) => (
    <>
        <span style={{ display: 'block' }}>
            {option?.text
                ? option.text
                : option?.name
                ? option.name
                : option?.assetModel?.name
                ? option?.assetModel?.name
                : option?.label
                ? option.label
                : ''}
        </span>
        {option?.serialNumber && (
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>
                {'IN: ' + option.serialNumber}
            </span>
        )}
        {option?.inventoryNumber && (
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>
                {'SE: ' + option.inventoryNumber}
            </span>
        )}
    </>
);
/// CUSTOM ASSET EXTRA RENDER OPTION
export const customAssetExtraRenderOption = (option: any) => {
    return (
        <>
            <span style={{ display: 'block' }}>
                {option?.text
                    ? option.text
                    : option?.name
                    ? option.name
                    : option?.assetModel?.name
                    ? option?.assetModel?.name
                    : option?.label
                    ? option.label
                    : ''}
            </span>
            <span style={{ display: 'none' }}>{option?.item?.id}</span>
            <span style={{ display: 'none' }}>{option.id}</span>
            {option.serialNumber?.length > 0 && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    <span>{'IN: '}</span>
                    <span>{option.serialNumber}</span>
                </span>
            )}
            {option.inventoryNumber?.length > 0 && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    <span>{'SE: '}</span>
                    <span>{option?.inventoryNumber}</span>
                </span>
            )}
            {option?.item?.code?.length > 0 && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    <span>{geti18nText('inventoryOrder.edit.code') + ': '}</span>
                    <span>{option.item.code ? option.item.code : ''}</span>
                </span>
            )}
            {option?.item?.name?.length > 0 && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    <span>{geti18nText('inventoryOrder.edit.name') + ': '}</span>
                    <span>{option.item.name ? option.item.name : ''}</span>
                </span>
            )}
            {option.measureUnit?.abbreviation?.length > 0 && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    <span>{geti18nText('webshopItem.edit.measureUnit') + ': '}</span>
                    <span>{option.measureUnit.abbreviation}</span>
                </span>
            )}
            {option.quantity && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    <span>{geti18nText('webshopItem.edit.quantity') + ': '}</span>
                    <span>{option.quantity}</span>
                </span>
            )}
        </>
    );
};

/// CUSTOM MOBILIZATION RENDER OPTION
export const customEmployeeMobilizationRenderOption = (option: any) => {
    return (
        <>
            <span style={{ display: 'block' }}>
                {option?.lastName + ' ' + option?.parentName + ' ' + option?.firstName}
            </span>
        </>
    );
};

/// CUSTOM WORKPLACE MOBILIZATION RENDER OPTION
export const customWorkplaceMobilizationRenderOption = (option: any) => {
    return (
        <>
            <span style={{ display: 'block' }}>
                {option?.vocationDescriptionName
                    ? option?.vocationName + ' - ' + option?.vocationDescriptionName
                    : option?.vocationName}
            </span>
        </>
    );
};

export const customWorkplaceMobilizationSearchFieldRenderOption = (option: any) => {
    return (
        <>
            <span style={{ display: 'block' }}>{option?.vocationName}</span>
            {option.vocationDescriptionName && (
                <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                    {option?.vocationDescriptionName}
                </span>
            )}
        </>
    );
};

export const customAssetRenderOptionDepreciation = (option: any) => (
    <>
        <span style={{ display: 'block' }}>
            {option?.text
                ? option.text
                : option?.name
                ? option.name
                : option?.assetModel?.name
                ? option?.assetModel?.name
                : option?.label
                ? option.label
                : ''}
        </span>
        <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>{'IN: ' + option.serialNumber}</span>
        {option.itemName && (
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                {geti18nText('menu.item') +
                    ': ' +
                    option.itemName +
                    '' +
                    (option.itemCode ? ' (' + option.itemCode + ') ' : '')}{' '}
            </span>
        )}
    </>
);

export const getAssetSelectOption = (data: any) => {
    let assetData: any = {
        text: data.assetModel && data.assetModel.name ? data.assetModel.name : '',
        serialNumber: data.serialNumber ? data.serialNumber : '',
    };
    return {
        key: data.id,
        id: data.id,
        name: customAssetRenderOption(assetData),
    };
};

export const getAssetSelectOptionDepreciation = (data: any) => {
    let assetData: any = {
        text: data.assetModel && data.assetModel.name ? data.assetModel.name : '',
        serialNumber: data.serialNumber ? data.serialNumber : '',
        itemName: data.item && data.item.name ? data.item.name : '',
        itemCode: data.item && data.item.code ? data.item.code : '',
    };
    return {
        key: data.id,
        id: data.id,
        name: customAssetRenderOptionDepreciation(assetData),
    };
};

export const getInventoryAssetRoomsSelectOption = (room: any) => {
    if (Array.isArray(room)) {
        let rooms = room.map((val: any) => {
            return {
                key: val.room.id,
                id: val.room.id,
                label: val.room.name,
            };
        });
        return rooms;
    }
};

export const getInventoryAssetCategoriesSelectOption = (assetCategory: any) => {
    if (Array.isArray(assetCategory)) {
        let assetCategories = assetCategory.map((val: any) => {
            return {
                key: val.assetCategory.id,
                id: val.assetCategory.id,
                label: val.assetCategory.name + (val.assetCategory.code ? ' (' + val.assetCategory.code + ')' : ''),
            };
        });
        return assetCategories;
    }
};

export const getInventoryAssetTypesSelectOption = (assetType: any) => {
    if (Array.isArray(assetType)) {
        let assetTypes = assetType.map((val: any) => {
            return {
                key: val.assetType.id,
                id: val.assetType.id,
                label: val.assetType.name,
            };
        });
        return assetTypes;
    }
};
/// END CUSTOM ASSET RENDER OPTION
export const customAccountRenderOption = (option: any) => {
    return (
        <>
            <span style={{ display: 'inline-flex' }}>
                {(option.code ? '(' + option.code + ') ' : '') + (option.text ?? option.label ?? option.name)}
            </span>
            <div style={{ display: 'none' }}>{option.type}</div>
        </>
    );
};

/// CUSTOM ITEM RENDER OPTION
export const customItemRenderOption = (option: any) => (
    <>
        <span style={{ display: 'block' }}>{option.text}</span>
        <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
            {option.code && (
                <span style={{ display: 'block' }}>
                    {option.code && geti18nText('item.edit.code') + ': ' + option.code}
                </span>
            )}
            {option?.measureUnit?.name && (
                <span style={{ display: 'block' }}>
                    {' '}
                    {geti18nText('item.edit.measureUnit') + ': ' + option.measureUnit.name}
                </span>
            )}
        </span>
    </>
);

export const getItemSelectOption = (data: any) => {
    let itemData: any = {};
    if (data.item) {
        itemData = {
            text: data.item.name ? data.item.name : '',
            code: data.item.code ? data.item.code : '',
            measureUnit: data.measureUnit && data.measureUnit.abbreviation ? data.measureUnit.abbreviation : '',
        };
    } else {
        itemData = { text: '', code: '', measureUnit: '' };
    }

    return {
        key: data.id,
        id: data.id,
        name: customItemRenderOption(itemData),
    };
};
/// END CUSTOM ITEM RENDER OPTION

/// URL VALIDATION
export const getValidUrl = (url = '') => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, '');

    if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
    }

    return newUrl;
};
/// END URL VALIDATION

/// ORDER STATUS ICON
export const getOrderStatusIcon = (status: any, i18n: string = 'app.enum.INVENTORY_ORDER_STATUS_TYPE.') => {
    let icon: any = <EditOutlined />;
    let color: any = 'processing';
    status = status && status > 0 ? status : 0;
    switch (status) {
        case 0:
            icon = <EditOutlined />;
            color = 'processing';
            break;
        case 1:
            icon = <HourglassOutlined />;
            color = 'warning';
            break;
        case 2:
            icon = <CloseOutlined />;
            color = 'error';
            break;
        case 3:
            icon = <CheckOutlined />;
            color = 'success';
            break;
        case 4:
            icon = <IssuesCloseOutlined />;
            color = 'cyan';
            break;
        case 5:
            icon = <ExceptionOutlined />;
            color = 'purple';
            break;
        case 6:
            icon = <CarryOutOutlined />;
            color = 'default';
            break;
        case 7:
            icon = <PartitionOutlined />;
            color = 'orange';
            break;
        case 8:
            icon = <CloseOutlined />;
            color = 'default';
            break;
    }
    return (
        <Tag icon={icon} color={color} style={{ fontSize: '14px' }}>
            {geti18nText(i18n + status)}
        </Tag>
    );
};
/// END ORDER STATUS ICON

// DEVICE TYPE
export const getDeviceTypeTag = (type: number) => {
    let color: any = 'processing';

    switch (type) {
        case 1:
            color = 'processing';
            break;
        case 2:
            color = 'cyan';
            break;
        default:
            color = 'processing';
            break;
    }
    return (
        <Tag color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.DEVICE_TYPE.' + type)}
        </Tag>
    );
};

// booking status
export const getTravelWarrantBookingStatusTag = (type: number, errorMsg?: string) => {
    let color: any = 'processing';
    let icon: any = <HourglassOutlined />;

    switch (type) {
        case 0:
            color = 'processing';
            icon = <HourglassOutlined />;
            break;
        case 1:
            color = 'green';
            icon = <CheckOutlined />;
            break;
        case 2:
            color = 'red';
            icon = <CloseOutlined />;
            break;
        default:
            color = 'processing';
            icon = <HourglassOutlined />;
            break;
    }

    return (
        <Tooltip placement="top" title={errorMsg != undefined && type == 2 ? errorMsg : null}>
            <Tag icon={icon} color={color} style={{ fontSize: '14px' }}>
                {geti18nText('app.enum.TRAVEL_WARRANT_BOOK_STATUS.' + type)}
            </Tag>
        </Tooltip>
    );
};

/// FAMILY MEMBER TYPE STATUS ICON
export const getEmployeeFamilyMemberTypeIcon = (
    status: number,
    i18n: string = 'app.enum.EMPLOYEE_FAMILY_MEMBER_TYPE.'
) => {
    let color: string = 'processing';
    status = status ? status : 0;
    switch (status) {
        case 1:
            color = 'warning';
            break;
        case 2:
            color = 'success';
            break;
        default:
            color = 'processing';
            break;
    }
    return (
        <Tag color={color} style={{ fontSize: '14px' }}>
            {geti18nText(i18n + status)}
        </Tag>
    );
};

/// START TRAVEL WARRANT STATUS ICON
const resolveTravelWarrantStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    status = status ? status : -1;
    switch (status) {
        case -1:
            icon = <EditOutlined style={style} className={className} />;
            break;
        case 1:
            icon = <HourglassOutlined style={style} className={className} />;
            break;
        case 2:
            icon = <CheckOutlined style={style} className={className} />;
            break;
        case 3:
            icon = <CloseOutlined style={style} className={className} />;
            break;
        case 4:
            icon = <IssuesCloseOutlined style={style} className={className} />;
            break;
        case 5:
            icon = <ExceptionOutlined style={style} className={className} />;
            break;
        case 6:
            icon = <PauseCircleOutlined style={style} className={className} />;
            break;
        case 7:
            icon = <SnippetsOutlined style={style} className={className} />;
            break;
        case 8:
            icon = <ScissorOutlined style={style} className={className} />;
            break;
        case 9:
            icon = <ApartmentOutlined style={style} className={className} />;
            break;
        case 10:
            icon = <BranchesOutlined style={style} className={className} />;
            break;
        case 11:
            icon = <MinusCircleOutlined style={style} className={className} />;
            break;
        case 12:
            icon = <ZoomOutOutlined style={style} className={className} />;
            break;
        case 13:
            icon = <StopOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

/// QUALIFICATION LEVEL STATUS ICON
const resolveQualificationLevelStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = undefined;
    status = status ? status : -1;
    switch (status) {
        case 1:
            icon = <GlobalOutlined style={style} className={className} />;
            break;
        case 2:
            icon = <StarOutlined style={style} className={className} />;
            break;
        case 3:
            icon = <FileDoneOutlined style={style} className={className} />;
            break;
        case 4:
            icon = <CheckCircleOutlined style={style} className={className} />;
            break;
        default:
            break;
    }
    return icon;
};

export const getTravelWarrantOverviewCardColor = (status: any) => {
    let color: any = '#1890ff';
    status = status ? status : -1;
    switch (status) {
        case -1:
            color = '#1890ff';
            break;
        case 1:
            color = '#faad14';
            break;
        case 2:
            color = '#52c41a';
            break;
        case 3:
            color = '#ff4d4f';
            break;
        case 4:
            color = '#faad14';
            break;
        case 5:
            color = '#52c41a';
            break;
        case 6:
            color = '#faad14';
            break;
        case 7:
            color = '#52c41a';
            break;
        case 8:
            color = '#faad14';
            break;
        case 9:
            color = '#52c41a';
            break;
        case 10:
            color = '#faad14';
            break;
        case 11:
            color = '#949494';
            break;
        case 12:
            color = '#faad14';
            break;
        case 13:
            color = '#ff4d4f';
            break;
    }
    return color;
};

export const getTravelWarrantStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'processing';
    status = status ? status : -1;
    switch (status) {
        case -1:
            color = 'blue';
            break;
        case 1:
            color = 'orange';
            break;
        case 2:
            color = 'green';
            break;
        case 3:
            color = 'red';
            break;
        case 4:
            color = 'orange';
            break;
        case 5:
            color = 'green';
            break;
        case 6:
            color = 'orange';
            break;
        case 7:
            color = 'green';
            break;
        case 8:
            color = 'orange';
            break;
        case 9:
            color = 'green';
            break;
        case 10:
            color = 'orange';
            break;
        case 11:
            color = 'default';
            break;
        case 12:
            color = 'orange';
            break;
        case 13:
            color = 'red';
            break;
    }
    return (
        <Tag icon={resolveTravelWarrantStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + status)}
        </Tag>
    );
};

export const getTravelWarrantTypeIcon = (status: any, style = { fontSize: '14px' }) => {
    let color: any = 'processing';
    status = status ? status : 1;
    switch (status) {
        case 1:
            color = 'processing';
            break;
        case 2:
            color = 'green';
            break;
        case 3:
            color = 'orange';
            break;
    }
    return (
        <Tag color={color} style={style}>
            {geti18nText('app.enum.TRAVEL_WAGE_TYPE.' + status)}
        </Tag>
    );
};

export const getQualificationLevelStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = undefined;
    status = status ? status : -1;
    switch (status) {
        case 1:
            color = 'cyan';
            break;
        case 2:
            color = 'green';
            break;
        case 3:
            color = 'blue';
            break;
        case 4:
            color = 'lime';
            break;
        default:
            break;
    }
    return (
        status != -1 && (
            <Tag
                icon={resolveQualificationLevelStatusIcon(status, style, '')}
                color={color}
                style={{ fontSize: '14px' }}
            >
                {geti18nText('app.enum.QUALIFICATION_LEVEL_TYPE.' + status)}
            </Tag>
        )
    );
};

export const setTaskWatch = (
    taskId: any,
    setRefresh?: any,
    setIsWached?: any,
    setWatchLoading?: any,
    setRefreshTaskWatchTable?: any
) => {
    NyRequestResolver.requestPost(CONSTANTS_REQ.TASK_WATCHERS.EDIT + '/' + taskId, undefined, {
        taskId: taskId,
    }).then((result: any) => {
        if (result.status === RESPONSE.CREATED) {
            okNotification();
            if (setRefresh) {
                setRefresh((refresh: any) => refresh + 1);
            }
            if (setIsWached) {
                setIsWached((prevValue: any) => !prevValue);
            }
            if (setWatchLoading) {
                setWatchLoading((prevValue: any) => !prevValue);
            }
            if (setRefreshTaskWatchTable) {
                setRefreshTaskWatchTable((refresh: any) => refresh + 1);
            }
        } else {
            errorNotification(result.data.error);
            if (setWatchLoading) {
                setWatchLoading((prevValue: any) => !prevValue);
            }
        }
    });
};

export const unsetTaskWatch = (
    taskId: any,
    setRefresh?: any,
    setIsWached?: any,
    setWatchLoading?: any,
    setRefreshTaskWatchTable?: any
) => {
    NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_WATCHERS.UPDATE + '/' + taskId, undefined, {
        taskId: taskId,
    }).then((result: any) => {
        if (result.status === RESPONSE.OK) {
            okNotification();
            if (setRefresh) {
                setRefresh((refresh: any) => refresh + 1);
            }
            if (setIsWached) {
                setIsWached((prevValue: any) => !prevValue);
            }
            if (setWatchLoading) {
                setWatchLoading((prevValue: any) => !prevValue);
            }
            if (setRefreshTaskWatchTable) {
                setRefreshTaskWatchTable((refresh: any) => refresh + 1);
            }
        } else {
            errorNotification(result.data.error);
            if (setWatchLoading) {
                setWatchLoading((prevValue: any) => !prevValue);
            }
        }
    });
};

export const removeTaskWatcher = (
    recordId: any,
    setRefresh?: any,
    openTask?: any,
    setRefreshTable?: any,
    selectedTaskId?: any
) => {
    NyRequestResolver.requestPut(CONSTANTS_REQ.TASK_WATCHERS.REMOVE_TASK_WATCHER + '/' + recordId, undefined, {
        id: recordId,
    }).then((result: any) => {
        if (result.status === RESPONSE.OK) {
            okNotification();
            if (setRefresh) {
                setRefresh((refresh: any) => refresh + 1);
            }
            if (openTask) {
                openTask(selectedTaskId);
            }
            if (setRefreshTable) {
                setRefreshTable((prevValue: any) => prevValue + 1);
            }
        } else {
            errorNotification(result.data.error);
        }
    });
};

export const getTaskStatusIcon = (status: any, i18n: string = 'app.enum.TASK_STATUS.') => {
    let icon: any = <EditOutlined />;
    let color: any = 'processing';
    status = status ? status : 1;
    switch (status) {
        case 1:
            icon = <HourglassOutlined />;
            color = 'processing';
            break; // novo
        case 2:
            icon = <UserAddOutlined />;
            color = 'gold';
            break; // dodjeljeno
        case 3:
            icon = <ForwardOutlined />;
            color = 'lime';
            break; // izvršavanje
        case 4:
            icon = <FlagOutlined />;
            color = 'success';
            break; // završeno
        case 5:
            icon = <LockOutlined />;
            color = 'warning';
            break; // završeno
        case 6:
            icon = <ProfileOutlined />;
            color = 'purple';
            break; // zatvoreno
        case 7:
            icon = <ToolOutlined />;
            color = 'cyan';
            break; // zatvoreno i fakturirano
        case 8:
            icon = <FrownOutlined />;
            color = 'volcano';
            break; // Djelomično riješeno
        case 9:
            icon = <CloseOutlined />;
            color = 'red';
            break; // Neuspjelo
    }
    return (
        <Tag icon={icon} color={color} style={{ fontSize: '14px' }}>
            {geti18nText(i18n + status)}
        </Tag>
    );
};

export const getTaskApprovalStatusIcon = (status: any, i18n: string = 'app.enum.TASK_APPROVAL_STATUS.') => {
    let icon: any = null;
    let color: any = null;
    status = status ? status : 1;
    switch (status) {
        case 1:
            icon = <HourglassOutlined />;
            color = 'processing';
            break; // čeka odobrenje
        case 2:
            icon = <CloseOutlined />;
            color = 'red';
            break; // odbijeno
        case 3:
            icon = <CheckOutlined />;
            color = 'lime';
            break; // odobreno
        case 4:
            icon = <FlagOutlined />;
            color = 'success';
            break; // riješeno
        case 5:
            icon = <EyeInvisibleOutlined />;
            color = 'cyan';
            break; // nevidljivo
    }
    return (
        <Tag icon={icon} color={color} style={{ fontSize: '14px' }}>
            {geti18nText(i18n + status)}
        </Tag>
    );
};

export const getNewTravelWizardDocsUrl = (current: any) => {
    switch (current) {
        case WIZARD_STEP.BASIC:
            return 'travel_order/travel_wizard_new/basic/';
        case WIZARD_STEP.DESTINATION_AND_TRANSPORT:
            return 'travel_order/travel_wizard_new/destination_transport/';
        case WIZARD_STEP.ADDITIONAL_TRAVEL_ORGANIZATION_DATA:
            return 'travel_order/travel_wizard_new/additional_data/';
        case WIZARD_STEP.ADVANCE:
            return 'travel_order/travel_wizard_new/advance/';
        case WIZARD_STEP.FILES_AND_NOTES:
            return 'travel_order/travel_wizard_new/files_notes/';
        case WIZARD_STEP.SUMMARY:
            return 'travel_order/travel_wizard_new/overview/';
        default:
            return undefined;
    }
};

export const getTravelWizardEditDocsUrl = (current: any) => {
    switch (current) {
        case WIZARD_STEP.TYPE:
            return 'travel_order/travel_wizard_edit/type/';
        case WIZARD_STEP.DESTINATION_AND_TRANSPORT:
            return 'travel_order/travel_wizard_edit/destination_transport/';
        case WIZARD_STEP.TRIP_COURSE:
            return 'travel_order/travel_wizard_edit/journey_course/';
        case WIZARD_STEP.PAID_EXPENSES_MEALS:
            return 'travel_order/travel_wizard_edit/paid_expenses/';
        case WIZARD_STEP.WAGES:
            return 'travel_order/travel_wizard_edit/daily_wages/';
        case WIZARD_STEP.PERSONAL_VEHICLE_EXPENSE:
            return 'travel_order/travel_wizard_edit/private_car_expense/';
        case WIZARD_STEP.OTHER_TRAVEL_WARRANT_EXPENSES:
            return 'travel_order/travel_wizard_edit/other_expenses/';
        case WIZARD_STEP.VIEW_ATTACHED_FILES:
            return 'travel_order/travel_wizard_edit/attached_files_view/';
        case WIZARD_STEP.RECAPITULATION:
            return 'travel_order/travel_wizard_edit/recapitulation/';
        case WIZARD_STEP.TRAVEL_BILL:
            return 'travel_order/travel_wizard_edit/travel_bill/';
        case WIZARD_STEP.TRIP_REPORT:
            return 'travel_order/travel_wizard_edit/trip_report/';
        default:
            return undefined;
    }
};

export const getTravelWarrantPaymentStatusIcon = (status: any, travelWarrantPaymentStatus: any) => {
    const style = { fontSize: '14px' };
    // const enumTypes = GetEnum({ enumName: 'NOTIFICATION_TYPE_SUPERVISOR' });
    let color: any = 'processing';
    status = status ? status : travelWarrantPaymentStatus.DRAFT;
    switch (status) {
        case travelWarrantPaymentStatus.DRAFT:
            color = 'blue';
            break;
        case travelWarrantPaymentStatus.WAITING_FOR_APPROVAL:
            color = 'orange';
            break;
        case travelWarrantPaymentStatus.APPROVED:
            color = 'green';
            break;
        case travelWarrantPaymentStatus.REJECTED:
            color = 'red';
            break;
        case travelWarrantPaymentStatus.PAID:
            color = 'green';
            break;
        case travelWarrantPaymentStatus.CANCELED:
            color = 'red';
            break;
    }
    return (
        <Tag icon={resolveTravelWarrantStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.TRAVEL_WARRANT_PAYMENT_STATUS.' + status)}
        </Tag>
    );
};

export const getTravelWarrantPaymentSortColor = (status: any, travelWarrantPaymentSort: any) => {
    let color: any = 'processing';
    status = status ? status : travelWarrantPaymentSort.ADVANCE;
    switch (status) {
        case travelWarrantPaymentSort.ADVANCE:
            color = 'red';
            break;
        case travelWarrantPaymentSort.PAYMENT:
            color = 'orange';
            break;
        case travelWarrantPaymentSort.PAYMENT:
            color = 'green';
            break;
        case travelWarrantPaymentSort.TAXABLE:
            color = 'blue';
            break;
    }
    return (
        <Tag color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.TRAVEL_WARRANT_PAYMENT_SORT.' + status)}
        </Tag>
    );
};

export const getTravelWarrantIcon = (status: any, fontSize: any) => {
    const style = { background: getTravelWarrantOverviewCardColor(status), fontSize: fontSize };
    return (
        <Tooltip placement="top" title={geti18nText('app.enum.TRAVEL_WARRANT_STATUS.' + status)}>
            {resolveTravelWarrantStatusIcon(status, style, 'overview-icons')}
        </Tooltip>
    );
};

export const getTravelWarrantIconSimple = (status: any) => {
    return resolveTravelWarrantStatusIcon(status, { fontSize: '10px' });
};
/// END TRAVEL WARRANT STATUS ICON

///  TRAVEL WARRANT TRANSPORTATION ICON
export const getCardTransportationIcon = (type: any) => {
    let icon: any = <CarOutlined style={{ fontSize: '22px' }} />;
    type = type ? type : 0;
    switch (type) {
        case 1:
            icon = <CarOutlined style={{ fontSize: '22px' }} />;
            break;
        case 2:
            icon = <CarOutlined style={{ fontSize: '22px' }} />;
            break;
        case 3:
            icon = <i style={{ fontSize: '26px', marginLeft: '-3px', marginTop: '-2px' }} className="las la-plane"></i>;
            break;
        case 4:
            icon = <i style={{ fontSize: '26px', marginLeft: '-3px', marginTop: '-2px' }} className="las la-ship"></i>;
            break;
        case 5:
            icon = <i style={{ fontSize: '26px', marginLeft: '-3px', marginTop: '-2px' }} className="las la-bus"></i>;
            break;
        case 6:
            icon = <i style={{ fontSize: '26px', marginLeft: '-3px', marginTop: '-2px' }} className="las la-train"></i>;
            break;
        case 7:
            icon = (
                <i
                    style={{ fontSize: '28px', marginLeft: '-3px', marginRight: '-4px', marginTop: '-2px' }}
                    className="las la-tram"
                ></i>
            );
            break;
        case 8:
            icon = (
                <i
                    style={{ fontSize: '28px', marginLeft: '-3px', marginRight: '-3px', marginTop: '-2px' }}
                    className="las la-car-alt"
                ></i>
            );
            break;
        case 9:
            icon = <CarOutlined style={{ fontSize: '22px' }} />;
            break;
    }
    return icon;
};
///  END TRAVEL WARRANT TRANSPORTATION ICON

/// START EMPLOYEE LEAVE ICON
export const GetEmployeeLeaveCardColor = (status: any) => {
    let color: any = '#1890ff';
    const employeeLeaveStatusEnum = useEnum('EMPLOYEE_LEAVE_STATUS');
    status = status ? status : employeeLeaveStatusEnum.REQUESTED;
    switch (status) {
        case employeeLeaveStatusEnum.REQUESTED:
            color = '#faad14';
            break;
        case employeeLeaveStatusEnum.CONFIRMED:
            color = '#52c41a';
            break;
        case employeeLeaveStatusEnum.REJECTED:
            color = '#ff4d4f';
            break;
    }
    return color;
};

const ResolveEmployeeLeaveStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    const employeeLeaveStatusEnum = useEnum('EMPLOYEE_LEAVE_STATUS');
    status = status ? status : employeeLeaveStatusEnum.REQUESTED;
    switch (status) {
        case employeeLeaveStatusEnum.REQUESTED:
            icon = <HourglassOutlined style={style} className={className} />;
            break;
        case employeeLeaveStatusEnum.CONFIRMED:
            icon = <CheckOutlined style={style} className={className} />;
            break;
        case employeeLeaveStatusEnum.c:
            icon = <CloseOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const getEmployeeStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any;
    status = status ? status : -1;
    switch (status) {
        case 1:
            color = 'blue';
            break;
        case 2:
            color = 'green';
            break;
        case 3:
            color = 'red';
            break;
        case 4:
            color = 'orange';
            break;
        case 5:
            color = 'cyan';
            break;
        case 6:
            color = 'orange';
            break;
        case 7:
            color = 'purple';
            break;
    }
    return (
        status != -1 && (
            <Tag icon={resolveEmployeeStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
                {geti18nText('app.enum.EMPLOYEE_STATUS.' + status)}
            </Tag>
        )
    );
};

/// EMPLOYEE STATUS ICON
const resolveEmployeeStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any;
    status = status ? status : -1;
    switch (status) {
        case 1:
            icon = <EditOutlined style={style} className={className} />;
            break;
        case 2:
            icon = <CheckOutlined style={style} className={className} />;
            break;
        case 3:
            icon = <HourglassOutlined style={style} className={className} />;
            break;
        case 4:
            icon = <IssuesCloseOutlined style={style} className={className} />;
            break;
        case 5:
            icon = <ExceptionOutlined style={style} className={className} />;
            break;
        case 6:
            icon = <PauseCircleOutlined style={style} className={className} />;
            break;
        case 7:
            icon = <SnippetsOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const GetEmployeeLeaveStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'processing';
    const employeeLeaveStatusEnum = useEnum('EMPLOYEE_LEAVE_STATUS');
    status = status ? status : employeeLeaveStatusEnum.REQUESTED;
    switch (status) {
        case employeeLeaveStatusEnum.REQUESTED:
            color = 'orange';
            break;
        case employeeLeaveStatusEnum.CONFIRMED:
            color = 'green';
            break;
        case employeeLeaveStatusEnum.REJECTED:
            color = 'red';
            break;
    }
    return (
        <Tag icon={ResolveEmployeeLeaveStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.EMPLOYEE_LEAVE_STATUS.' + status)}
        </Tag>
    );
};

export const GetEmployeeAnnualLeaveStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'processing';
    const employeeAnnualLeaveStatusEnum = useEnum('EMPLOYEE_ANNUAL_LEAVE_STATUS');
    status = status ? status : employeeAnnualLeaveStatusEnum.TEMPORARY;
    switch (status) {
        case employeeAnnualLeaveStatusEnum.TEMPORARY:
            color = 'default';
            break;
        case employeeAnnualLeaveStatusEnum.IN_PREPARATION:
            color = 'blue';
            break;
        case employeeAnnualLeaveStatusEnum.ACTIVATED:
            color = 'green';
            break;
        case employeeAnnualLeaveStatusEnum.DEACTIVATED:
            color = 'red';
            break;
    }
    return (
        <Tag icon={ResolveEmployeeLeaveStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.EMPLOYEE_ANNUAL_LEAVE_STATUS.' + status)}
        </Tag>
    );
};

export const getEmployeeLeaveIcon = (status: any, fontSize: any) => {
    const style = { background: GetEmployeeLeaveCardColor(status), fontSize: fontSize };
    return (
        <Tooltip placement="top" title={geti18nText('app.enum.EMPLOYEE_LEAVE_STATUS.' + status)}>
            {ResolveEmployeeLeaveStatusIcon(status, style, 'overview-icons')}
        </Tooltip>
    );
};

export const getEmployeeLeaveOverviewIconSimple = (status: any) => {
    return ResolveEmployeeLeaveStatusIcon(status, { fontSize: '10px' });
};
/// END EMPLOYEE LEAVE ICON

/// START EMPLOYEE LEAVE TYPE ICON
export const GetEmployeeLeaveTypeCardColor = (type: any) => {
    let color: any = '#1890ff';
    const employeeLeaveTypeEnum = useEnum('EMPLOYEE_LEAVE_TYPE');
    type = type ? type : employeeLeaveTypeEnum.ANNUAL_LEAVE;
    switch (type) {
        case employeeLeaveTypeEnum.ANNUAL_LEAVE:
            color = '#faad14';
            break;
        case employeeLeaveTypeEnum.PAID_LEAVE:
            color = '#1bf537';
            break;
        case employeeLeaveTypeEnum.UNPAID_LEAVE:
            color = '#dc1bf5';
            break;
        case employeeLeaveTypeEnum.SICK_LEAVE:
            color = '#1baef5';
            break;
    }
    return color;
};

const ResolveEmployeeLeaveTypeIcon = (type: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    const employeeLeaveTypeEnum = useEnum('EMPLOYEE_LEAVE_TYPE');
    type = type ? type : employeeLeaveTypeEnum.ANNUAL_LEAVE;
    switch (type) {
        case employeeLeaveTypeEnum.ANNUAL_LEAVE:
            icon = <CoffeeOutlined style={style} className={className} />;
            break;
        case employeeLeaveTypeEnum.PAID_LEAVE:
            icon = <HomeOutlined style={style} className={className} />;
            break;
        case employeeLeaveTypeEnum.UNPAID_LEAVE:
            icon = <CloudOutlined style={style} className={className} />;
            break;
        case employeeLeaveTypeEnum.SICK_LEAVE:
            icon = <MedicineBoxOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const getEmployeeLeaveTypeIcon = (type: any, fontSize: any) => {
    const style = { background: GetEmployeeLeaveTypeCardColor(type), fontSize: fontSize };
    return (
        <Tooltip placement="top" title={geti18nText('app.enum.EMPLOYEE_LEAVE_TYPE.' + type)}>
            {ResolveEmployeeLeaveTypeIcon(type, style, 'overview-icons')}
        </Tooltip>
    );
};
/// END EMPLOYEE LEAVE TYPE ICON

/// START MEAL ORDER STATUS
export const GetMealOrderStatusColor = (status: any) => {
    let color: any = '#b9ddff';
    const mealOrderStatusEnum = useEnum('MEAL_ORDER_STATUS');
    status = status ? status : mealOrderStatusEnum.UNCONFIRMED;
    switch (status) {
        case mealOrderStatusEnum.UNCONFIRMED:
            color = '#fde6b8';
            break;
        case mealOrderStatusEnum.CONFIRMED:
            color = '#cbedba';
            break;
        case mealOrderStatusEnum.CANCELED:
            color = '#ffc9ca';
            break;
    }
    return color;
};

export const GetMealOrderStatusColorDark = (status: any) => {
    let color: any = '#072b4c';
    const mealOrderStatusEnum = useEnum('MEAL_ORDER_STATUS');
    status = status ? status : mealOrderStatusEnum.UNCONFIRMED;
    switch (status) {
        case mealOrderStatusEnum.UNCONFIRMED:
            color = '#2b1d12';
            break;
        case mealOrderStatusEnum.CONFIRMED:
            color = '#162313';
            break;
        case mealOrderStatusEnum.CANCELED:
            color = '#2a1215';
            break;
    }
    return color;
};

const resolveMealOrderStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    status = status ? status : 1;
    switch (status) {
        case 1:
            icon = <EditOutlined style={style} className={className} />;
            break;
        case 2:
            icon = <CheckOutlined style={style} className={className} />;
            break;
        case 3:
            icon = <CloseOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const getMealOrderStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'processing';
    status = status ? status : 1;
    switch (status) {
        case 1:
            color = 'orange';
            break;
        case 2:
            color = 'green';
            break;
        case 3:
            color = 'red';
            break;
    }
    return (
        <Tag icon={resolveMealOrderStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.MEAL_ORDER_STATUS.' + status)}
        </Tag>
    );
};

export const getMobilizationStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'processing';
    status = status ? status : 1;
    switch (status) {
        case 1:
            color = 'cyan';
            break;
        case 2:
            color = 'gold';
            break;
        case 3:
            color = 'green';
            break;
        case 4:
            color = 'red';
            break;
    }
    return (
        <Tag icon={resolveMobilizationStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.MOBILIZATION_STATUS.' + status)}
        </Tag>
    );
};

const resolveMobilizationStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    status = status ? status : 1;
    switch (status) {
        case 1:
            icon = <GlobalOutlined style={style} className={className} />;
            break;
        case 2:
            icon = <HourglassOutlined style={style} className={className} />;
            break;
        case 3:
            icon = <CheckOutlined style={style} className={className} />;
            break;
        case 4:
            icon = <CloseOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const getMealOrderStatusIconSimple = (status: any) => {
    return resolveMealOrderStatusIcon(status, { fontSize: '10px' });
};

export const GetAttendanceRegistrationIcon = (item: { registration: number }) => {
    switch (item?.registration) {
        case 1:
            return (
                <MobileOutlined
                    style={{
                        fontSize: '16px',
                    }}
                />
            );

        case 2:
            return (
                <EditOutlined
                    style={{
                        fontSize: '16px',
                    }}
                />
            );

        case 3:
            return (
                <FormOutlined
                    style={{
                        fontSize: '16px',
                    }}
                />
            );
        case 4:
            return (
                <CreditCardOutlined
                    style={{
                        fontSize: '16px',
                    }}
                />
            );
        case 5:
            return (
                <DatabaseOutlined
                    style={{
                        fontSize: '16px',
                    }}
                />
            );
        case 6:
        case 7:
        case 8:
            return (
                <TabletOutlined
                    style={{
                        fontSize: '16px',
                    }}
                />
            );
        default:
            return null;
    }
};

export const GetCustomAttendanceDateFormat = (date: any) => {
    return (
        moment(date).format('dddd').charAt(0).toUpperCase() +
        moment(date).format('dddd').slice(1) +
        moment(date).format(', DD.MM.YYYY')
    );
};

/// BILLING STATUS ICON
export const getBillingStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'red';
    status = status ? status : 2;
    switch (status) {
        case 1:
            color = 'green';
            break;
        case 2:
            color = 'red';
            break;
    }
    return (
        <Tag icon={resolveBillingStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.BILLING_STATUS.' + status)}
        </Tag>
    );
};

const resolveBillingStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    status = status ? status : 2;
    switch (status) {
        case 1:
            icon = <CheckOutlined style={style} className={className} />;
            break;
        case 2:
            icon = <CloseOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const getBillingStatusIconSimple = (status: any) => {
    return resolveBillingStatusIcon(status, { fontSize: '10px' });
};

/// USER NOTIFICATION RECIPIENT
export const userIsNotificationRecipient = (employee: any, notificationType: any) => {
    return (
        notificationType &&
        employee &&
        employee.notificationRecipientList &&
        employee.notificationRecipientList.includes(notificationType)
    );
};
/// END USER NOTIFICATION RECIPIENT

export const isAdmin = () => {
    return AdministrationRights.isAdmin();
};

export const getWaypointFromCompany = async function (defaultStartingPoint: any) {
    let waypoints: any = [];
    if (defaultStartingPoint) {
        await fetch(
            `https://nominatim.openstreetmap.org/search?q=${defaultStartingPoint}&format=json&addressdetails=1&accept-language=hr-HR`,
            {
                method: 'GET',
                body: null,
                headers: {
                    accept: 'application/json',
                },
            }
        )
            .then((response) =>
                response
                    .json()
                    .then((data) => ({
                        data: data,
                        status: response.status,
                    }))
                    .then(async (res) => {
                        if (res?.data?.length > 0) {
                            let companyData = res.data[0];
                            waypoints.push({
                                id: 0,
                                placeId: companyData.place_id,
                                lat: companyData.lat,
                                lng: companyData.lon,
                                name: companyData['display_name'],
                                shortName: companyData['display_name'].split(',')[0],
                                countryCode: companyData.address.country_code,
                            });
                        }
                    })
            )
            .catch(() => {
                // message.destroy();
                // message.error(`${file?.name} ${geti18nText('import.text.error')}`);
            })
            .finally(() => {});
    }

    if (waypoints.length === 0) {
        waypoints.push({ id: 0, placeId: undefined, lat: undefined, lng: undefined, name: '' });
    }

    waypoints.push({ id: 1, placeId: undefined, lat: undefined, lng: undefined, name: '' });
    return waypoints;
};

export const generateUuid = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

export const getColorFromNotificationType = (settings: any, type: any) => {
    return settings &&
        Object.entries(settings).length !== 0 &&
        settings.find((item: any) => item.notificationType == GetEnum({ enumName: 'NOTIFICATION_TYPE' })[type])
        ? settings.find((item: any) => item.notificationType == GetEnum({ enumName: 'NOTIFICATION_TYPE' })[type]).color
        : 'grey';
};

export const getColorFromType = (settings: any, type: any) => {
    return (
        settings &&
        Object.entries(settings).length !== 0 &&
        settings.find((item: any) => item.notificationType === type) &&
        settings.find((item: any) => item.notificationType === type).color
    );
};

// APPLICATION SETTINGS
export const getApplicationSettingByKey = (applicationSettings: any, settingKey: any) => {
    return (
        applicationSettings &&
        Object.entries(applicationSettings).length !== 0 &&
        applicationSettings.find((item: any) => item.settingKey == settingKey)
    );
};

export const getApplicationSettingByGroup = (applicationSettings: any, settingGroup: any) => {
    return (
        applicationSettings &&
        Object.entries(applicationSettings).length !== 0 &&
        applicationSettings.find((item: any) => item.settingGroup == settingGroup)
    );
};

export const getApplicationSettingByModule = (applicationSettings: any, settingModule: any) => {
    return (
        applicationSettings &&
        Object.entries(applicationSettings).length !== 0 &&
        applicationSettings.find((item: any) => item.settingModule == settingModule)
    );
};

export const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const checkIsFileImage = (file: any) => {
    if (file.name.match(/\.(jpg|jpeg|png|gif|webp|ico)$/i)) {
        return true;
    }

    return false;
};

export const getFile = (file: any) => {
    return checkIsFileImage(file)
        ? {
              ...file,
              thumbUrl: CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file.fileId,
              url: CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file.fileId,
          }
        : file.fileId
        ? {
              ...file,
              thumbUrl: 'data:application/pdf',
              url: CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file.fileId,
          }
        : {
              ...file,
              thumbUrl: 'data:application/pdf',
          };
};

export const getFileList = (files: any) => {
    return files.map((file: any) => {
        return getFile(file);
    });
};

///START NEXT WORK DAY
export const checkIsWeekend = function (date: any) {
    return date.day() === 0 || date.day() === 6;
};

export const checkIsHoliday = function (date: any, holidays: any) {
    let dateValueDate = moment(date, 'DD.MM.YYYY.').format('DD.MM.YYYY.');
    const holiday: any = holidays.find((day: any) => day.date == dateValueDate);
    return holiday && holiday.active == true ? true : false;
};

export const getNextWorkday = function (holidays: any, format: any = 'DD.MM.YYYY') {
    const today = moment();
    let date = moment().add(1, 'days');
    while (date.isAfter(today)) {
        if (!checkIsHoliday(date, holidays) && !checkIsWeekend(date)) {
            return moment(date).format(format);
        }
        date = date.add(1, 'days');
    }
};
///END NEXT WORK DAY
/// CUSTOM TASK TEMPLATE RENDER OPTION
const { Meta } = Card;
export const customTaskTemplateRenderOption = (option: any) => (
    <>
        <Card style={{ width: '100%' }}>
            <Meta
                style={{ width: '100%' }}
                avatar={
                    <>
                        {option.fileId ? (
                            <Image
                                style={{ objectFit: 'cover', height: '32px', width: '32px', borderRadius: '5px' }}
                                preview={false}
                                src={
                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                    '/' +
                                    option.fileId +
                                    '?tenant=' +
                                    NySession.getUser().tenantId
                                }
                            />
                        ) : (
                            <Image
                                style={{ objectFit: 'cover', height: '32px', width: '32px', borderRadius: '5px' }}
                                src="error"
                                preview={false}
                                fallback={FILE_FALLBACK}
                            />
                        )}
                    </>
                }
                title={<>{option.name}</>}
                description={
                    <>
                        {option.code && <span style={{ display: 'block' }}>{option.code}</span>}
                        {option.description && (
                            <Tooltip
                                placement="topLeft"
                                overlayStyle={{ maxWidth: '300px' }}
                                title={option.description}
                            >
                                <div className={'overview-cards-paragraph-div'}>
                                    <span style={{ display: 'block' }} className={'overview-cards-paragraph'}>
                                        {option.description}
                                    </span>
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip title={geti18nText('app.default.button.add')} placement={'bottom'}>
                            <PlusCircleOutlined
                                style={{
                                    float: 'right',
                                    display: 'flex',
                                    fontSize: '20px',
                                    alignItems: 'center',
                                    marginLeft: 'auto',
                                }}
                            />
                        </Tooltip>
                    </>
                }
            />
        </Card>
    </>
);

export const customTaskTypeCategoryRenderOption = (option: any) => (
    <span style={{ display: 'inline' }}>
        <span style={{ display: 'block', float: 'left', marginRight: '5px' }}>
            {option.fileId ? (
                <Image
                    style={{ objectFit: 'cover', height: '32px', width: '32px', borderRadius: '5px' }}
                    preview={false}
                    src={CONSTANTS_REQ.FILES.DOWNLOAD + '/' + option.fileId + '?tenant=' + NySession.getUser().tenantId}
                />
            ) : (
                <Image
                    style={{ objectFit: 'cover', height: '32px', width: '32px', borderRadius: '5px' }}
                    src="error"
                    preview={false}
                    fallback={FILE_FALLBACK}
                />
            )}
        </span>
        <span style={{ display: 'block', float: 'left' }}>
            <span style={{ display: 'block' }}>{option.name}</span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
                {option.code && <span style={{ display: 'block' }}>{option.code}</span>}
                {option.description && (
                    <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '300px' }} title={option.description}>
                        <div className={'overview-cards-paragraph-div'}>
                            <span style={{ display: 'block' }} className={'overview-cards-paragraph'}>
                                {option.description}
                            </span>
                        </div>
                    </Tooltip>
                )}
            </span>
        </span>
    </span>
);

export const getMealSubventionAmount = (mealSubventionValue: any, itemSubvention: any, formatNumber: any = false) => {
    const mealSubvention =
        itemSubvention != null ? (itemSubvention < mealSubventionValue ? itemSubvention : mealSubventionValue) : 0;
    if (formatNumber) {
        return NyUtils.formatNumber(round(mealSubvention ?? 0, 2), 2);
    } else {
        return mealSubvention ?? 0;
    }
};

export const getMealSubventionPrice = (item: any, mealSubventionValue: any) => {
    let mealSubventionPercent = getMealSubventionAmount(mealSubventionValue, item.meal.allowedDiscount);
    const mealPriceWithSubvention = round((item.priceList.price * mealSubventionPercent) / 100, 2);
    return mealPriceWithSubvention;
};

export const getMealAdvancePrice = (item: any, mealSubventionValue: any) => {
    const mealPriceWithSubvention = getMealSubventionPrice(item, mealSubventionValue);
    const priceWithSubventionAmount = item.priceList.price - mealPriceWithSubvention;
    const mealPriceWithAdvance = round(priceWithSubventionAmount > 0 ? priceWithSubventionAmount : 0, 2);
    return mealPriceWithAdvance;
};

export const getMealPriceWithSubvention = (
    mealSubventionValue: any = 0,
    itemSubvention: any = 0,
    mealPrice: any = 0,
    formatNumber = true
) => {
    const priceList: any = { price: mealPrice };
    const meal: any = { allowedDiscount: itemSubvention };
    const item: any = { priceList: priceList, meal };

    const mealPriceWithAdvance = getMealAdvancePrice(item, mealSubventionValue);
    if (formatNumber) {
        return NyUtils.formatNumber(mealPriceWithAdvance, 2);
    } else {
        return mealPriceWithAdvance;
    }
};

export const customMealRenderOption = (option: any) => (
    <>
        <span style={{ display: 'inline-flex' }}>{option.text ?? option.name}</span>
        {option.allowedDiscount && <span style={{ display: 'none' }}>{option.allowedDiscount}</span>}
    </>
);
export const getLoginRedirectCustomUrlFromStorage = () => {
    return localStorage.getItem('loginRedirectCustomUrl');
};

export const getLoginRedirectFromStorage = () => {
    return localStorage.getItem('loginRedirectUrl');
};

export const getLoginRedirectTabFromStorage = () => {
    return localStorage.getItem('loginRedirectTab');
};

export const getLoginRedirectUrl = () => {
    const loginRedirectUrl = getLoginRedirectFromStorage();
    if (loginRedirectUrl) {
        const values = JSON.parse(loginRedirectUrl);
        if (values && values[0]) {
            const url = values[0].returnType ? values[0].returnType : '/';
            const id = values[0].id ? values[0].id : null;
            if (id && url) {
                localStorage.removeItem('loginRedirectUrl');
                return id;
            }
        }
    }
    return null;
};

export const getLocationStateOpenModal = (location: any, setVisible: any, doDelete: boolean = true) => {
    if (location?.state?.openModal) {
        if (setVisible) setVisible(true);
        if (doDelete) delete location.state;
    }
};

export const customRenderTextOption = (option: any) => {
    return <span custom-data={option}>{option?.text && option.text}</span>;
};

export const customRenderNameOption = (option: any) => {
    return <span custom-data={option}>{option?.name && option.name}</span>;
};
export const customRenderTextOptionForId = (option: any) => {
    return <span custom-data={option}>{option?.text && option.text}</span>;
};

export const getHumanCodebooksUrlByType = (url: any, humanCodebookType: any = 0) => {
    const urlWithType = url.replace('{codebookType}', humanCodebookType);
    return urlWithType ?? '';
};

function ToJson(str: string) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
}

export const JsonData = (data: any) => {
    const style: CSS.Properties = {
        width: '45vw',
        whiteSpace: 'nowrap',
        maxHeight: '500px',
        overflowY: 'auto',
        cursor: 'pointer',
    };

    return (
        <Row gutter={24}>
            <Col span={24} style={style}>
                <pre>{JSON.stringify(ToJson(data.data), null, 2)}</pre>
            </Col>
        </Row>
    );
};

export const getNySearchFieldEnumInitValue = (
    enumName: any = '',
    name: any = undefined,
    initValue: any = undefined,
    enums: any = null
) => {
    return setEnumFormat(
        enumName,
        name
            ? GetEnumValueForName({
                  enumName: enumName,
                  name: name,
              })
            : initValue,
        enums
    );
};

export const setEnumFormatWithGroupAndKey = (
    enumName: any = '',
    name: any = undefined,
    initValue: any = undefined,
    enums: any = null
) => {
    let value = name
        ? GetEnumValueForName({
              enumName: enumName,
              name: name,
          })
        : initValue;
    if (value?.value) {
        value = value.value;
    }

    if (enums === undefined) {
        return {};
    }

    return enums.find((e: any) => {
        return e.id === value;
    });
};

export const getContractStatusIcon = (status: any, i18n: string = 'app.enum.CONTRACT_STATUS.') => {
    let icon: any = null;
    let color: any = null;
    status = status ? status : 1;
    switch (status) {
        case 1:
            icon = <EditOutlined />;
            color = 'processing';
            break; // skica
        case 2:
            icon = <DiffOutlined />;
            color = 'lime';
            break; // kreirano
        case 3:
            icon = <AuditOutlined />;
            color = 'success';
            break; // potpisano
    }
    return (
        <Tag icon={icon} color={color} style={{ fontSize: '14px' }}>
            {geti18nText(i18n + status)}
        </Tag>
    );
};
//CONTRACT TYPE RENDER OPTION
export const customContractTypeRenderOption = (option: any) => {
    return (
        <div>
            <span style={{ display: 'block' }}>
                {option.code ? option.name + ' (' + option.code + ')' : option.name}
            </span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: '11px', display: 'block' }}>
                {option.description}
            </span>
        </div>
    );
};

//CONTRACT RENDER OPTION
export const customContractRenderOption = (option: any) => {
    return (
        <div>
            <span style={{ display: 'block' }}>{option.name ? option.name : ''}</span>
            <span style={{ paddingLeft: '10px', color: 'gray', fontSize: '11px', display: 'block' }}>
                {option.contractNumber}
            </span>
        </div>
    );
};

//EMPLOYEE EDUCATION STATUS ICON
export const GetEmployeeEducationCardColor = (status: any) => {
    let color: any = '#1890ff';
    const employeeEducationStatusEnum = useEnum('EMPLOYEE_EDUCATION_STATUS');
    status = status ? status : employeeEducationStatusEnum.DRAFT;
    switch (status) {
        case employeeEducationStatusEnum.DRAFT:
        case employeeEducationStatusEnum.FOR_APPROVAL:
        case employeeEducationStatusEnum.REALIZATION:
            color = '#faad14';
            break;
        case employeeEducationStatusEnum.DRAFT_APPROVED:
        case employeeEducationStatusEnum.APPROVED:
        case employeeEducationStatusEnum.COMPLETED:
            color = '#52c41a';
            break;
        case employeeEducationStatusEnum.REJECTED:
            color = '#ff4d4f';
            break;
    }
    return color;
};

export const ResolveEmployeeEducationStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    const employeeEducationStatusEnum = useEnum('EMPLOYEE_EDUCATION_STATUS');
    if (status) {
        switch (status) {
            case employeeEducationStatusEnum.DRAFT:
            case employeeEducationStatusEnum.FOR_APPROVAL:
                icon = <HourglassOutlined style={style} className={className} />;
                break;
            case employeeEducationStatusEnum.DRAFT_APPROVED:
            case employeeEducationStatusEnum.APPROVED:
            case employeeEducationStatusEnum.COMPLETED:
            case employeeEducationStatusEnum.REALIZATION:
                icon = <CheckOutlined style={style} className={className} />;
                break;
            case employeeEducationStatusEnum.REJECTED:
            case employeeEducationStatusEnum.DRAFT_REJECTED:
                icon = <CloseOutlined style={style} className={className} />;
        }
    }
    return icon;
};

export const GetEmployeeEducationStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'blue';
    const employeeEducationStatusEnum = useEnum('EMPLOYEE_EDUCATION_STATUS');
    if (status) {
        switch (status) {
            case employeeEducationStatusEnum.DRAFT:
            case employeeEducationStatusEnum.FOR_APPROVAL:
                color = 'orange';
                break;
            case employeeEducationStatusEnum.DRAFT_APPROVED:
            case employeeEducationStatusEnum.APPROVED:
            case employeeEducationStatusEnum.COMPLETED:
            case employeeEducationStatusEnum.REALIZATION:
                color = 'green';
                break;
            case employeeEducationStatusEnum.REJECTED:
            case employeeEducationStatusEnum.DRAFT_REJECTED:
                color = 'red';
                break;
        }
    }
    return (
        <Tag icon={ResolveEmployeeEducationStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {status
                ? geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.' + status)
                : geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.0')}
        </Tag>
    );
};

export const getEmployeeEducationIcon = (status: any, fontSize: any) => {
    const style = { background: GetEmployeeEducationCardColor(status), fontSize: fontSize };
    return (
        <Tooltip placement="top" title={geti18nText('app.enum.EMPLOYEE_EDUCATION_STATUS.' + status)}>
            {ResolveEmployeeEducationStatusIcon(status, style, 'overview-icons')}
        </Tooltip>
    );
};

//contracts get contract or contractType list by access rights and roles
const getFullMark = (mark: any) => {
    switch (mark) {
        case 'HR':
            return 'HUMAN_RESOURCES';
        default:
            return mark;
    }
};

export const getUrlContract = async (setUrl: any, mark: string, urlList: any, urlListMark: any) => {
    setUrl(undefined);
    if (!mark) {
        setUrl(urlList);
    } else if (HumanResourcesRights.canCreateContractAdmin()) {
        setUrl(urlListMark.replace('{mark}', mark));
    } else if (HumanResourcesRights.canOnlyViewContract()) {
        const fullMark = getFullMark(mark);
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_CATEGORY_RIGHTS.CHECK).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const listUserContainsMark = result.data.filter(
                    (item: any) => item.contractCategory == fullMark && item?.active == true
                );
                if (listUserContainsMark?.length > 0) {
                    setUrl(urlListMark.replace('{mark}', mark));
                }
            }
        });
    }
};

//contracts get contract or contractType canCreate by access rights and roles
export const getCanCreateContract = async (setCanCreate: any, mark: string, categoryName: string) => {
    setCanCreate(false);
    if (HumanResourcesRights.canCreateContractAdmin()) {
        setCanCreate(true);
    } else if (HumanResourcesRights.canOnlyCreateContract()) {
        if (mark) {
            await NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_CATEGORY_RIGHTS.CHECK).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    const listUserContainsMark = result.data.filter(
                        (item: any) =>
                            (item?.contractCategory == mark || item?.contractCategory == categoryName) &&
                            item?.active == true
                    );
                    if (listUserContainsMark?.length > 0) {
                        setCanCreate(true);
                    }
                }
            });
        }
    }
};

export const getOrdinal = (travelWarrant: any) => {
    if (travelWarrant && travelWarrant.ordinal && travelWarrant.warrantDate) {
        return travelWarrant.ordinal + '/' + moment(travelWarrant.warrantDate).format('YYYY');
    }

    return '';
};

//EMPLOYEE EVALUATION STATUS ICON
export const GetEmployeeEvaluationCardColor = (status: any, presetsView = true) => {
    let color: any = presetsView ? 'blue' : '#1f8ce5';
    const employeeEvaluationStatusEnum = useEnum('EMPLOYEE_EVALUATION_STATUS');
    status = status ? status : employeeEvaluationStatusEnum.DRAFT;
    switch (status) {
        case employeeEvaluationStatusEnum.DRAFT:
            color = presetsView ? 'blue' : '#1f8ce5';
            break;
        case employeeEvaluationStatusEnum.ESTIMATION:
            color = presetsView ? 'orange' : '#ffa80c';
            break;
        case employeeEvaluationStatusEnum.PARTIALLY_FILLED:
            color = presetsView ? 'lime' : '#0dff6d';
            break;
        case employeeEvaluationStatusEnum.UNDER_REVIEW:
            color = presetsView ? 'cyan' : '#00cfd2';
            break;
        case employeeEvaluationStatusEnum.INTERMEDIATION:
            color = presetsView ? 'yellow' : '#e3e325';
            break;
        case employeeEvaluationStatusEnum.COMPLETED:
            color = presetsView ? 'green' : '#34ae00';
            break;
        case employeeEvaluationStatusEnum.CANCELLED:
        case employeeEvaluationStatusEnum.WRONG_MANAGER:
            color = presetsView ? 'red' : '#d42020';
            break;
    }
    return color;
};

export const ResolveEmployeeEvaluationStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    const employeeEvaluationStatusEnum = useEnum('EMPLOYEE_EVALUATION_STATUS');
    if (status) {
        switch (status) {
            case employeeEvaluationStatusEnum.DRAFT:
                icon = <EditOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.ESTIMATION:
                icon = <ClockCircleOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.PARTIALLY_FILLED:
                icon = <ForkOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.UNDER_REVIEW:
                icon = <HourglassOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.INTERMEDIATION:
                icon = <IssuesCloseOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.COMPLETED:
                icon = <CheckOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.CANCELLED:
                icon = <CloseOutlined style={style} className={className} />;
                break;
            case employeeEvaluationStatusEnum.WRONG_MANAGER:
                icon = <SwapOutlined style={style} className={className} />;
                break;
        }
    }
    return icon;
};

export const GetEmployeeEvaluationStatusIcon = (status: any, style: any = { fontSize: '14px' }) => {
    return (
        <Tag
            icon={ResolveEmployeeEvaluationStatusIcon(status, style, '')}
            color={GetEmployeeEvaluationCardColor(status)}
            style={{ fontSize: '14px' }}
        >
            {status
                ? geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.' + status)
                : geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.0')}
        </Tag>
    );
};

export const getEmployeeEvaluationIcon = (status: any, fontSize: any) => {
    const style = { background: GetEmployeeEvaluationCardColor(status), fontSize: fontSize };
    return (
        <Tooltip placement="top" title={geti18nText('app.enum.EMPLOYEE_EVALUATION_STATUS.' + status)}>
            {ResolveEmployeeEvaluationStatusIcon(status, style, 'overview-icons')}
        </Tooltip>
    );
};

export const GetEmployeeEvaluationGradeColors = (status: any, presetsView: boolean = false) => {
    let color: any = !presetsView ? '#05AC05' : 'green';
    const employeeEvaluationStatusEnum = useEnum('EMPLOYEE_EVALUATION_ESTIMATION');
    if (status) {
        switch (status) {
            case employeeEvaluationStatusEnum.EXCEPTIONAL:
                color = !presetsView ? '#05AC05' : 'green';
                break;
            case employeeEvaluationStatusEnum.EFFECTIVE:
                color = !presetsView ? '#7CD305' : 'lime';
                break;
            case employeeEvaluationStatusEnum.SATISFACTORY:
                color = !presetsView ? '#E0D303' : 'orange';
                break;
            case employeeEvaluationStatusEnum.UNSATISFACTORY:
                color = !presetsView ? '#E09503' : 'volcano';
                break;
            case employeeEvaluationStatusEnum.UNDEFINED:
                color = !presetsView ? '#E01A03' : 'red';
                break;
        }
    }
    return color;
};

const ResolveWorkplaceStatusIcon = (status: any, style: any = '{}', className: any = '') => {
    let icon: any = <EditOutlined style={style} className={className} />;
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    status = status ? status : enWorkplaceStatus.DRAFT;
    switch (status) {
        case enWorkplaceStatus.DRAFT:
            icon = <EditOutlined style={style} className={className} />;
            break;
        case enWorkplaceStatus.CONFIRMED:
            icon = <CheckOutlined style={style} className={className} />;
            break;
    }
    return icon;
};

export const GetWorkplaceStatusIcon = (status: any) => {
    const style = { fontSize: '14px' };
    let color: any = 'blue';
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');
    status = status ? status : enWorkplaceStatus.DRAFT;
    switch (status) {
        case enWorkplaceStatus.DRAFT:
            color = 'blue';
            break;
        case enWorkplaceStatus.CONFIRMED:
            color = 'green';
            break;
    }
    return (
        <Tag icon={ResolveWorkplaceStatusIcon(status, style, '')} color={color} style={{ fontSize: '14px' }}>
            {geti18nText('app.enum.WORKPLACE_STATUS.' + status)}
        </Tag>
    );
};

//start custom workplace render option
export const customWorkplaceRenderOption = (option: any) => (
    <span custom-data={option}>
        <span style={{ display: 'block' }}>{option.businessUnit}</span>
        <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small', display: 'block' }}>
            {option.vocation}
        </span>
        <span style={{ paddingLeft: '10px', color: 'gray', fontSize: 'x-small' }}>{option.vocationDescription}</span>
    </span>
);
export const getWorkplaceSelectOption = (workplace: any) => {
    let workplaceData: any = {
        id: workplace.id,
        businessUnit: workplace?.businessUnit?.name,
        vocation: workplace?.vocation?.name,
        vocationDescription: workplace?.vocationDescription?.name,
        vocationDescriptionID: workplace?.vocationDescription?.id,
    };

    return {
        key: workplaceData.id,
        id: workplaceData.id,
        text: customWorkplaceRenderOption(workplaceData),
    };
};
//end custom workplace render option

export const empServiceYmdFormatting = (val: any, form: any, formItemName: any) => {
    let input = val.target.value;

    input = input.replace(/[^\d]/g, '');

    if (input.length <= 2) {
        input = input;
    } else if (input.length <= 4) {
        input = `${input.slice(0, 2)}-${input.slice(2)}`;
    } else {
        input = `${input.slice(0, 2)}-${input.slice(2, 4)}-${input.slice(4, 6)}`;
    }

    if (input.length > 0) {
        form.setFieldsValue({ [formItemName]: input });
    }

    if (!/[0-9]/.test(val.key)) {
        val.preventDefault();
    }
};

export const clearCache = async () => {
    localStorage.clear();
    Object.keys(sessionStorage).forEach((key: any) => {
        if (key !== 'NyReact') {
            sessionStorage.removeItem(key);
        }
    });
    const cacheNames = await caches.keys();
    cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
    });
};

//format time value in HH:mm, not decimal number
export function formatTime(value: any) {
    if (value !== null && value !== undefined) {
        const hours = Math.floor(value);
        const minutes = Math.floor((value % 1) * 60);
        return hours + ':' + (minutes < 10 ? '0' : '') + minutes;
    }
    return '00:00';
}

//display wage error messages
export function getWageErrorsMessages(wageErrors: string, canView: boolean = true) {
    if (wageErrors) {
        const wageErrorsArray: any = wageErrors.split(',');

        return (
            <>
                {wageErrorsArray?.length > 0 && canView ? (
                    <Tooltip
                        placement="rightTop"
                        title={
                            <>
                                {wageErrorsArray.map((wageErrorItem: any) => {
                                    return (
                                        <>
                                            <p>{geti18nText(wageErrorItem)}</p>
                                        </>
                                    );
                                })}
                            </>
                        }
                        overlayStyle={{ maxWidth: '600px' }}
                    >
                        <WarningTwoTone
                            twoToneColor="#fa1414"
                            style={{ marginRight: '5px', marginTop: '8px', float: 'right' }}
                        />
                    </Tooltip>
                ) : null}
            </>
        );
    }
    return null;
}

export function getTransparentColor(color: string, alpha: any) {
    if (color && alpha) {
        let colorFormatted = isValidRGB(color) ? rgbToHex(color) : color;
        let r = parseInt(colorFormatted?.substring(1, 3), 16);
        let g = parseInt(colorFormatted?.substring(3, 5), 16);
        let b = parseInt(colorFormatted?.substring(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
}

export function hexToRgb(hex: any) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `${r}, ${g}, ${b}`;
}

export function rgbToHex(rgb: any): string {
    const rgbValues = rgb.match(/\d+/g)?.map(Number);

    if (rgbValues && rgbValues.length === 3) {
        const [r, g, b] = rgbValues;
        const toHex = (value: number) => value.toString(16).padStart(2, '0').toLowerCase();
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }
    return '';
}

export function isValidRGB(rgbString: any) {
    const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
    const match = rgbString?.match(rgbRegex);

    if (!match) return false;

    const [r, g, b] = match.slice(1).map(Number);
    return r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255;
}

export const BusinessUnitSelectOption = (businessUnit: any, isMobilization: any = false) => {
    if (Array.isArray(businessUnit)) {
        let employees = businessUnit.map((val: any) => {
            const label = isMobilization
                ? val?.code
                    ? '[' + val?.code + '] ' + val?.name
                    : val?.name
                : val?.code
                ? val?.name + ' (' + val?.code + ')'
                : val?.name;
            return {
                key: val.id,
                id: val.id,
                label: label,
            };
        });
        return employees;
    }
};
