import { EllipsisOutlined, EyeInvisibleOutlined, EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import { NyRequestResolver, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Col, Dropdown, Empty, Form, Menu, Modal, Row, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddressHistoryIndex from '../../../../components/address/addressHistoryIndex';
import EmployeeDocuments from '../../../../components/employee-documents/EmployeeDocuments';
import CustomHistory from '../../../../components/ny-history/CustomHistory';
import NyHistory from '../../../../components/ny-history/NyHistory';
import PDFPreview from '../../../../components/pdf-preview';
import useEnum from '../../../../hooks/useEnum';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    getWorkplaceSelectOption,
    okNotification,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../utils/Utils';
import { actionsMenuGuvernorConfirm, actionsMenuMorhConfirm } from '../schedule-suggestions/scheduleSuggestionsActions';
import EmployeeInfo from './EmployeeInfo';
import MilitaryRecords from './MilitaryRecords';
import Mobilization from './Mobilization';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';

const EmployeeWorkObligationEdit = ({
    isModalVisible,
    setIsModalVisible,
    id,
    refresh,
    setRefresh,
    isNew,
    setIsNew,
    isEmployeeForm = false,
}: any) => {
    const EnSubreportType = useEnum('SUBREPORT_TYPE');
    const enMobilizationFunction = useEnum('MOBILIZATION_FUNCTION');
    const enEmployeeMilitaryRecordsAssigned = useEnum('EMPLOYEE_MILITARY_RECORDS_ASSIGNED');
    const enMilitaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const enMilitaryWorkApprovalAction = useEnum('MILITARY_WORK_APPROVAL_ACTION');
    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const addressType = useEnum('ADDRESS_TYPE');
    const mobilizationScheduleValue = useApplicationSetting('MOBILIZATION_SCHEDULE_VALUE');

    const url = CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.EDIT;
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState('1');
    const [collapseAll, setCollapseAll] = useState<boolean>(true);
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>(['1', '2', '3', '4']);
    const [showTooltip, setShowTooltip] = useState(false);
    const [historyModalVisible, setHistoryModalVisible] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [mobilizationId, setMobilizationId] = useState<any>(null);
    const [obligated, setObligated] = useState<any>(false);
    const [enrolled, setEnrolled] = useState<any>(false);
    const [workplace, setWorkplace] = useState<any>(undefined);
    const [workplaceDetails, setWorkplaceDetails] = useState<any>(null);
    const [businessUnit, setBusinessUnit] = useState<any>(undefined);
    const [executorVisbile, setExecutorVisible] = useState(false);
    const [loading, setLoading] = useState<any>(true);

    const [haveSecurityCheck, setHaveSecurityCheck] = useState<any>(false);
    const [needSecurityCertification, setNeedSecurityCertification] = useState<any>(false);
    const [renewalDate, setRenewalDate] = useState<any>(null);
    const [scheduleDate, setScheduleDate] = useState<any>(null);
    const [scheduleExpiration, setScheduleExpiration] = useState<any>(null);
    const [county, setCounty] = useState<any>();
    const [township, setTownship] = useState<any>();
    const [settlement, setSettlement] = useState<any>();
    const [street, setStreet] = useState<any>();
    const [allowDistrict, setAllowDistrict] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);
    const [assignChecked, setAssignChecked] = useState(false);
    const [departmentId, setDepartmentId] = useState<any>(null);
    const [needSecurityCheck, setNeedSecurityCheck] = useState<boolean>(false);
    const [publishDate, setPublishDate] = useState<any>(null);
    const [expirationDate, setExpirationDate] = useState<any>(null);

    const [publishDateCertification, setPublishDateCertification] = useState<any>(null);
    const [expirationDateCertification, setExpirationDateCertification] = useState<any>(null);
    const [renewalDateCertification, setRenewalDateCertification] = useState<any>(null);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [employee, setEmployee] = useState<any>(undefined);

    const focusInput = useRef<any>(null);
    const history = useHistory();

    const canCreate = () => {
        return MobilizationRights.canCreateMobilizationCodebooks();
    };

    const canView = () => {
        return MobilizationRights.canViewMobilizationCodebooks();
    };

    const canCreateActions = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const canCreateActionsPlan = (workApprovalType: any, workApprovalAction: any) => {
        const hasRole: boolean = MobilizationRights.isMobilizationAdmin();
        if (!hasRole) {
            return false;
        } else {
            switch (workApprovalType) {
                case enMilitaryWorkApprovalType.GOVERNOR:
                    if (
                        workApprovalAction == enMilitaryWorkApprovalType.REMOVE ||
                        workApprovalAction == enMilitaryWorkApprovalType.RENEWAL
                    ) {
                        return (
                            dataForm?.employee?.businessUnit?.id &&
                            dataForm?.employee?.workplaceDetails?.id &&
                            dataForm?.assign == enEmployeeMilitaryRecordsAssigned.YES
                        );
                    } else {
                        return !dataForm?.noAssign && dataForm?.assign != enEmployeeMilitaryRecordsAssigned.YES;
                    }
                case enMilitaryWorkApprovalType.MORH:
                    if (
                        workApprovalAction == enMilitaryWorkApprovalType.REMOVE ||
                        workApprovalAction == enMilitaryWorkApprovalType.RENEWAL
                    ) {
                        return (
                            dataForm?.employee?.businessUnit?.id &&
                            dataForm?.employee?.workplaceDetails?.id &&
                            dataForm?.assign == enEmployeeMilitaryRecordsAssigned.YES
                        );
                    } else {
                        return !dataForm?.noAssign;
                    }
                default:
                    return false;
            }
        }
    };

    const changeCollapseVisibility = () => {
        if (collapseAll) {
            setCollapseActiveKeys([]);
        } else {
            setCollapseActiveKeys(['1', '2', '3', '4']);
        }
        setCollapseAll(!collapseAll);
    };

    useEffect(() => {
        if (isNew) {
            setActiveKey('2');
            form.resetFields();
        }
    }, []);

    const onCancel = () => {
        onModalClose();
    };

    const saveWorkplaceDetails = async (employeeId: any) => {
        let workplaceDetailsId = null;
        if (employeeId > 0) {
            if (workplaceDetails?.id) {
                let data: any =
                    workplace?.id > 0
                        ? {
                              employee: { id: employeeId },
                              id: workplaceDetails.id,
                              workplace: { id: workplace.id },
                              active: businessUnit?.id > 0,
                          }
                        : {
                              employee: { id: employeeId },
                              id: workplaceDetails.id,
                              workplace: { id: workplaceDetails?.workplace?.id },
                              active: false,
                          };
                if (
                    ((!workplace?.id || workplace?.id < 1) && workplaceDetails?.workplace?.id) ||
                    workplace?.id != workplaceDetails?.workplace?.id
                ) {
                    await NyRequestResolver.requestPut(
                        CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT + '/' + workplaceDetails.id,
                        undefined,
                        data
                    ).then((result: any) => {
                        if (result?.status == RESPONSE.CREATED && result.data) {
                            workplaceDetailsId = result.data.id;
                        } else {
                            errorNotification(geti18nText('app.default.save.nok'));
                        }
                    });
                }
            } else if (workplace?.id > 0) {
                await NyRequestResolver.requestPost(CONSTANTS_REQ.WORKPLACE_DETAILS.EDIT, undefined, {
                    employee: { id: employeeId },
                    workplace: { id: workplace.id },
                }).then((result: any) => {
                    if (result?.status == RESPONSE.CREATED && result.data) {
                        workplaceDetailsId = result.data.id;
                    } else {
                        errorNotification(geti18nText('app.default.save.nok'));
                    }
                });
            }
        }
        return workplaceDetailsId;
    };

    const normalizeValues = (values: any) => {
        values.employee = { id: id };
        values.address = { townshipDistrict: { id: values?.address?.townshipDistrict?.id }, id: values?.address?.id };

        if (values?.defenseDepartment?.id < 0) {
            delete values.defenseDepartment;
        }
        if (values?.defenseSection?.id < 0) {
            delete values.defenseSection;
        }
        if (values?.professionalSpecialty?.id < 0) {
            delete values.professionalSpecialty;
        }
        if (values?.reserveRank?.id < 0) {
            delete values.reserveRank;
        }
        if (values?.workplaceDetails?.id < 0) {
            delete values.workplaceDetails;
        }
        if (values?.mobilizationLocation?.id < 0) {
            delete values.mobilizationLocation;
        }
        if (values?.securityCertificateLevel?.id < 0) {
            delete values.securityCertificateLevel;
        }
        if (values?.businessUnit?.id < 0) {
            delete values.businessUnit;
        }
        if (values.mobilizationTime != null) {
            values.mobilizationTime = values.mobilizationTime.id;
        }
        if (values.mobilizationFunction != null) {
            values.mobilizationFunction = values.mobilizationFunction.id;
        }
        if (values.assign != null) {
            values.assign = values.assign.id;
        }
        if (values.executor != null) {
            if (values?.executor?.id < 0) {
                delete values.executor;
            } else {
                getSearchFormat(values.executor);
            }
        }
        if (values.scheduleExpiration) {
            values.scheduleExpiration = getDateFormat(values.scheduleExpiration, 'yyyy-MM-DD HH:mm');
        } else if (values.scheduleDate && moment(values.scheduleDate)) {
            values.scheduleExpiration = getDateFormat(
                moment(values.scheduleDate).add(mobilizationScheduleValue, 'years'),
                'yyyy-MM-DD HH:mm'
            );
        }
        if (values.publishDate) values.publishDate = getDateFormat(values.publishDate, 'yyyy-MM-DD HH:mm');
        if (values.scheduleDate) values.scheduleDate = getDateFormat(values.scheduleDate, 'yyyy-MM-DD HH:mm');
        if (values.expirationDate) values.expirationDate = getDateFormat(values.expirationDate, 'yyyy-MM-DD HH:mm');
        if (values.renewalDate) values.renewalDate = getDateFormat(values.renewalDate, 'yyyy-MM-DD HH:mm');
        if (values.publishDateCertification)
            values.publishDateCertification = getDateFormat(values.publishDateCertification, 'yyyy-MM-DD HH:mm');
        if (values.expirationDateCertification)
            values.expirationDateCertification = getDateFormat(values.expirationDateCertification, 'yyyy-MM-DD HH:mm');
        if (values.renewalDateCertification)
            values.renewalDateCertification = getDateFormat(values.renewalDateCertification, 'yyyy-MM-DD HH:mm');

        values.enrolled = enrolled;
        values.obligated = obligated;
        delete values.workplace;
        return values;
    };

    const saveRecord = async () => {
        form.validateFields().then(async (values: any) => {
            values = normalizeValues(values);
            const workplaceDetailsId =
                (await saveWorkplaceDetails(id)) ?? (workplace?.id != -1 ? workplaceDetails?.id : null);
            values.workplaceDetails = { id: workplaceDetailsId };
            if (mobilizationId) {
                values.id = mobilizationId;
                values.active = values.active;
                NyRequestResolver.requestPut(url + '/' + mobilizationId, undefined, {
                    ...values,
                }).then((result: any) => {
                    afterSaveActions(result);
                });
            } else {
                values.active = true;
                NyRequestResolver.requestPost(url, undefined, {
                    ...values,
                }).then((result: any) => {
                    afterSaveActions(result);
                });
            }
            setRefresh((prevVal: any) => prevVal + 1);
        });
    };

    const afterSaveActions = (result: any) => {
        if (result && result.status === RESPONSE.CREATED) {
            okNotification();
            if (!isEmployeeForm) {
                onModalClose();
                setRefresh((refresh: number) => refresh + 1);
            }
        } else {
            errorNotification(geti18nText('app.default.save.nok'));
        }
    };

    useEffect(() => {
        fetchEmployeeData();
    }, []);

    useEffect(() => {
        fetchEmployeeData();
    }, [refresh]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setDataForm(dataForm);
        setMobilizationId && setMobilizationId(dataForm.id);

        setDepartmentId(dataForm?.defenseDepartment?.id);

        if (dataForm.noAssign) {
            setChecked(true);
        }

        if (dataForm.mobilizationFunction) {
            if (dataForm.mobilizationFunction == enMobilizationFunction.DEPUTY_EXECUTOR) {
                setExecutorVisible(true);
            }
            dataForm.mobilizationFunction = setEnumFormat('MOBILIZATION_FUNCTION', dataForm.mobilizationFunction);
        }

        if (dataForm.assign) {
            if (dataForm.assign == enEmployeeMilitaryRecordsAssigned.OUTSIDE_ORGANIZATION) {
                setAssignChecked(true);
            } else {
                setAssignChecked(false);
            }
            dataForm.assign = setEnumFormat('EMPLOYEE_MILITARY_RECORDS_ASSIGNED', dataForm.assign);
        }

        if (dataForm.mobilizationTime) {
            dataForm.mobilizationTime = setEnumFormat('MOBILIZATION_TIME', dataForm.mobilizationTime);
        }

        if (dataForm.businessUnit) {
            dataForm.businessUnit = {
                id: dataForm.businessUnit.id,
                name: dataForm.businessUnit.code
                    ? '(' + dataForm.businessUnit.code + ') ' + dataForm.businessUnit.name
                    : dataForm.businessUnit.name,
            };
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            setBusinessUnit(dataForm.businessUnit);
        }

        if (dataForm.executor) {
            dataForm.executor = getEmployeeSelectOption(dataForm.executor);
        }
        if (dataForm.address?.settlement?.township?.allowDistrict) {
            setAllowDistrict(dataForm.address.settlement.township.allowDistrict);
        }
        if (dataForm.address?.settlement?.county) {
            setCounty(dataForm.address.settlement.county.name);
        }
        if (dataForm.address?.settlement?.township) {
            setTownship(dataForm.address.settlement.township);
        }
        if (dataForm.address?.settlement) {
            setSettlement(dataForm.address.settlement);
        }
        if (dataForm.address?.street) {
            setStreet(dataForm.address.street);
        }

        if (dataForm.securityCertificateLevel) {
            dataForm.securityCertificateLevel = setSearchFormat(dataForm.securityCertificateLevel, 'name', 'name');
        }
        if (dataForm.address?.townshipDistrict) {
            dataForm.address.townshipDistrict = setSearchFormat(dataForm.address.townshipDistrict, 'name', 'name');
        }
        if (dataForm.militaryWorkApproval) {
            dataForm.militaryWorkApproval.name = '';
            if (dataForm.militaryWorkApproval.type == enMilitaryWorkApprovalType.GOVERNOR) {
                dataForm.militaryWorkApproval.name = dataForm.militaryWorkApproval.name
                    .concat(dataForm.militaryWorkApproval.submissionNumber ?? '')
                    .concat(
                        dataForm.militaryWorkApproval.inputDate ? ', ' + dataForm.militaryWorkApproval.inputDate : ''
                    );
            } else if (dataForm.militaryWorkApproval.type == enMilitaryWorkApprovalType.MORH) {
                dataForm.militaryWorkApproval.name = dataForm.militaryWorkApproval.name
                    .concat(dataForm.militaryWorkApproval.sentObjectName ?? '')
                    .concat(
                        dataForm.militaryWorkApproval.sentSubmissionNumber
                            ? ', ' + dataForm.militaryWorkApproval.sentSubmissionNumber
                            : ''
                    )
                    .concat(
                        dataForm.militaryWorkApproval.inputDate ? ', ' + dataForm.militaryWorkApproval.inputDate : ''
                    );
            }
            dataForm.militaryWorkApproval = setSearchFormat(dataForm.militaryWorkApproval);
        }

        setNeedSecurityCheck(dataForm.needSecurityCheck);
        setNeedSecurityCertification(dataForm.needSecurityCertification);
        setHaveSecurityCheck(dataForm.haveSecurityCheck);
        setObligated(dataForm?.obligated);
        setEnrolled(dataForm?.enrolled);
        if (dataForm.employee) {
            setEmployee(dataForm.employee);
        }
        if (dataForm.scheduleDate) {
            dataForm.scheduleDate = setDateFormat(dataForm.scheduleDate);
            setScheduleDate(dataForm.scheduleDate);
        }
        if (dataForm.scheduleExpiration) {
            dataForm.scheduleExpiration = setDateFormat(dataForm.scheduleExpiration);
            setScheduleExpiration(dataForm.scheduleExpiration);
        }
        if (dataForm.publishDate) {
            dataForm.publishDate = setDateFormat(dataForm.publishDate);
            setPublishDate(dataForm.publishDate);
        }
        if (dataForm.expirationDate) {
            dataForm.expirationDate = setDateFormat(dataForm.expirationDate);
            setExpirationDate(dataForm.expirationDate);
        }
        if (dataForm.renewalDate) {
            dataForm.renewalDate = setDateFormat(dataForm.renewalDate);
            setRenewalDate(dataForm.renewalDate);
        }
        if (dataForm.renewalDateCertification) {
            dataForm.renewalDateCertification = setDateFormat(dataForm.renewalDateCertification);
            setRenewalDateCertification(dataForm.renewalDateCertification);
        }
        if (dataForm.expirationDateCertification) {
            dataForm.expirationDateCertification = setDateFormat(dataForm.expirationDateCertification);
            setExpirationDateCertification(dataForm.expirationDateCertification);
        }
        if (dataForm.publishDateCertification) {
            dataForm.publishDateCertification = setDateFormat(dataForm.publishDateCertification);
            setPublishDateCertification(dataForm.publishDateCertification);
        }
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const fetchEmployeeData = () => {
        setLoading(true);
        //CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.EDIT + employeeId not instanceId!!
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.EDIT + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setValues(result.data);
                getWorkplaceDetails(id);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    };

    const getWorkplaceDetails = (employeeId: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.WORKPLACE_DETAILS.LIST, {
            search: encodeURI(
                JSON.stringify([
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'employeeId', condition: 'equals', value: employeeId },
                ])
            ),
            max: 1,
            order: 'id',
            orderType: 'desc',
        }).then((result: any) => {
            if (result?.status === RESPONSE.OK) {
                const workplaceDetailsValue = result?.data?.content && result?.data?.content[0];

                if (workplaceDetailsValue?.active) {
                    if (workplaceDetailsValue.workplace) {
                        const workplaceValue = getWorkplaceSelectOption({
                            id: workplaceDetailsValue.workplace.id,
                            businessUnit: workplaceDetailsValue.businessUnit,
                            vocation: workplaceDetailsValue.vocation,
                            vocationDescription: workplaceDetailsValue.vocationDescription,
                        });
                        setWorkplace(workplaceValue);
                        form.setFieldsValue({ workplace: workplaceValue });
                    }
                    setWorkplaceDetails(workplaceDetailsValue);
                }
            }
        });
    };

    const actionsMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Dropdown
                        key="more"
                        placement="top"
                        overlay={actionsMenuGuvernorConfirm(
                            enMilitaryWorkApprovalType,
                            enMilitaryWorkApprovalAction,
                            canCreateActionsPlan,
                            [id],
                            history
                        )}
                        trigger={['click']}
                    >
                        <Button style={{ width: '100%' }}>
                            {geti18nText('app.default.actions') + ' ' + geti18nText('schedule.suggestions.guvernor')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Dropdown
                        key="more"
                        placement="top"
                        overlay={actionsMenuMorhConfirm(
                            enMilitaryWorkApprovalType,
                            enMilitaryWorkApprovalAction,
                            canCreateActionsPlan,
                            [id],
                            history
                        )}
                        trigger={['click']}
                    >
                        <Button style={{ width: '100%' }}>
                            {geti18nText('app.default.actions') + ' ' + geti18nText('schedule.suggestions.morh')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        </Menu>
    );

    const historyMenu = (
        <Menu>
            <div style={{ padding: '0px', textAlign: 'left' }}>
                <div style={{ display: 'block', margin: '5px' }}>
                    <Button
                        icon={<HistoryOutlined />}
                        onClick={() => setHistoryModalVisible(true)}
                        style={{ marginRight: '5px' }}
                    >
                        {geti18nText('mobilization.history.info')}
                    </Button>
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <CustomHistory
                        url={CONSTANTS_REQ.EMPLOYEE.CHANGELOG}
                        id={id}
                        type="employee"
                        inline={true}
                        buttonLabel={geti18nText('changelog.table.title') + ' - ' + geti18nText('employee.edit.title')}
                        maxHeight={'65vh'}
                    />
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <AddressHistoryIndex
                        employeeId={id}
                        type={addressType.PERMANENT_RESIDENCE}
                        buttonStyle={{
                            float: 'right',
                            marginRight: '7px',
                            marginTop: '2px',
                        }}
                        extraHeader={geti18nText('employee.edit.addressData')}
                        isButton
                        scroll={{ y: 380, x: 800 }}
                    />
                </div>
                <div style={{ display: 'block', margin: '5px' }}>
                    <AddressHistoryIndex
                        employeeId={id}
                        type={addressType.TEMPORARY_RESIDENCE}
                        buttonStyle={{
                            float: 'right',
                            marginRight: '7px',
                            marginTop: '2px',
                        }}
                        extraHeader={geti18nText('employee.edit.residence')}
                        isButton
                        scroll={{ y: 380, x: 800 }}
                    />
                </div>
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <>
            {activeKey == '2' && (
                <div style={{ float: 'left' }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                    <Dropdown key="more" overlay={historyMenu} trigger={['click']}>
                        <Button type="primary" style={{ marginLeft: '10px' }}>
                            {geti18nText('app.default.change.history')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                    <Tooltip
                        placement="top"
                        visible={showTooltip}
                        title={geti18nText('app.default.shortcuts.collapse')}
                    >
                        {!collapseAll ? (
                            <EyeOutlined
                                onClick={changeCollapseVisibility}
                                className="ny-custom-button"
                                style={{ marginRight: '20px' }}
                            />
                        ) : (
                            <EyeInvisibleOutlined
                                onClick={changeCollapseVisibility}
                                className="ny-custom-button"
                                style={{ marginRight: '20px' }}
                            />
                        )}
                    </Tooltip>
                    {workplace?.id && (
                        <PDFPreview
                            url={CONSTANTS_REQ.REPORT.WORKPLACE}
                            customParms={{
                                workplaceId: workplace.id,
                            }}
                            subreportType={'WORKPLACE'}
                            buttoni18nText={'workplace.button.generateReport.action'}
                            fileName={geti18nText('app.enum.SUBREPORT_TYPE.' + EnSubreportType.WORKPLACE)}
                            customDateFormat={moment().format('yyyyMMDD')}
                            columns={false}
                            customButtonText={geti18nText('employee.workplace.button.generateReport.action')}
                            style={{ marginLeft: '10px' }}
                        />
                    )}
                </div>
            )}
            <Button onClick={onCancel}>{geti18nText('app.default.button.cancel')}</Button>
            {activeKey !== '1' && canCreate() && (
                <Button onClick={saveRecord} type="primary">
                    {geti18nText('app.default.button.save')}
                </Button>
            )}
        </>
    );

    const onModalClose = () => {
        form.resetFields();
        setIsModalVisible(false);
        setIsNew(false);
        setMobilizationId(null);
    };

    return (
        <>
            {canView() ? (
                <>
                    {isEmployeeForm ? (
                        <div style={{ height: '550px' }}>
                            <Row gutter={24} style={{ height: '500px', overflowY: 'auto' }}>
                                <Col span={24}>
                                    <MilitaryRecords
                                        collapseActiveKeys={collapseActiveKeys}
                                        setCollapseActiveKeys={setCollapseActiveKeys}
                                        form={form}
                                        isEmployeeForm={isEmployeeForm}
                                        setWorkplace={setWorkplace}
                                        businessUnit={businessUnit}
                                        setBusinessUnit={setBusinessUnit}
                                        obligated={obligated}
                                        setObligated={setObligated}
                                        enrolled={enrolled}
                                        setEnrolled={setEnrolled}
                                        loading={loading}
                                        haveSecurityCheck={haveSecurityCheck}
                                        setHaveSecurityCheck={setHaveSecurityCheck}
                                        needSecurityCertification={needSecurityCertification}
                                        renewalDate={renewalDate}
                                        scheduleDate={scheduleDate}
                                        scheduleExpiration={scheduleExpiration}
                                        county={county}
                                        township={township}
                                        settlement={settlement}
                                        street={street}
                                        allowDistrict={allowDistrict}
                                        checked={checked}
                                        setChecked={setChecked}
                                        assignChecked={assignChecked}
                                        setAssignChecked={setAssignChecked}
                                        departmentId={departmentId}
                                        setDepartmentId={setDepartmentId}
                                        needSecurityCheck={needSecurityCheck}
                                        publishDate={publishDate}
                                        setPublishDate={setPublishDate}
                                        expirationDate={expirationDate}
                                        setExpirationDate={setExpirationDate}
                                        publishDateCertification={publishDateCertification}
                                        setPublishDateCertification={setPublishDateCertification}
                                        expirationDateCertification={expirationDateCertification}
                                        setExpirationDateCertification={setExpirationDateCertification}
                                        renewalDateCertification={renewalDateCertification}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    {canCreate() && (
                                        <Button
                                            onClick={saveRecord}
                                            type="primary"
                                            style={{ marginTop: '10px', float: 'right' }}
                                        >
                                            {geti18nText('app.default.button.save')}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <>
                            <Modal
                                open={isModalVisible}
                                maskClosable={false}
                                closable={true}
                                style={{ top: '50px' }}
                                width={1800}
                                onCancel={onModalClose}
                                footer={getCustomFooterContent}
                            >
                                <Tabs
                                    type="card"
                                    className="tabs-sticky"
                                    activeKey={activeKey}
                                    onChange={(key: any) => {
                                        if (key === '4') {
                                            setRefreshFiles((refresh: any) => refresh + 1);
                                        }
                                        setActiveKey(key);
                                    }}
                                    style={{ maxHeight: '85vh' }}
                                >
                                    <Tabs.TabPane
                                        tab={
                                            geti18nText('employee.mobilization.edit.tab.1') +
                                            (employee?.id && employee?.person?.id
                                                ? ': ' + employee.person.firstName + ' ' + employee.person.lastName
                                                : '')
                                        }
                                        key="1"
                                    >
                                        <EmployeeInfo employeeId={id} disabled={isNew} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={geti18nText('employee.mobilization.edit.tab.2')}
                                        key="2"
                                        forceRender={true}
                                    >
                                        <MilitaryRecords
                                            collapseActiveKeys={collapseActiveKeys}
                                            setCollapseActiveKeys={setCollapseActiveKeys}
                                            form={form}
                                            isEmployeeForm={isEmployeeForm}
                                            setWorkplace={setWorkplace}
                                            businessUnit={businessUnit}
                                            setBusinessUnit={setBusinessUnit}
                                            obligated={obligated}
                                            setObligated={setObligated}
                                            enrolled={enrolled}
                                            setEnrolled={setEnrolled}
                                            loading={loading}
                                            haveSecurityCheck={haveSecurityCheck}
                                            setHaveSecurityCheck={setHaveSecurityCheck}
                                            needSecurityCertification={needSecurityCertification}
                                            renewalDate={renewalDate}
                                            scheduleDate={scheduleDate}
                                            scheduleExpiration={scheduleExpiration}
                                            county={county}
                                            township={township}
                                            settlement={settlement}
                                            street={street}
                                            allowDistrict={allowDistrict}
                                            checked={checked}
                                            setChecked={setChecked}
                                            assignChecked={assignChecked}
                                            setAssignChecked={setAssignChecked}
                                            departmentId={departmentId}
                                            setDepartmentId={setDepartmentId}
                                            needSecurityCheck={needSecurityCheck}
                                            publishDate={publishDate}
                                            setPublishDate={setPublishDate}
                                            expirationDate={expirationDate}
                                            setExpirationDate={setExpirationDate}
                                            publishDateCertification={publishDateCertification}
                                            setPublishDateCertification={setPublishDateCertification}
                                            expirationDateCertification={expirationDateCertification}
                                            setExpirationDateCertification={setExpirationDateCertification}
                                            renewalDateCertification={renewalDateCertification}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={geti18nText('employee.mobilization.edit.tab.3')}
                                        key="3"
                                        forceRender={true}
                                    >
                                        <Mobilization
                                            setExecutorVisible={setExecutorVisible}
                                            executorVisbile={executorVisbile}
                                            form={form}
                                            loading={loading}
                                            setLoading={setLoading}
                                            employeeId={id}
                                            setMobilizationId={setMobilizationId}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={geti18nText('app.default.files')} key="4">
                                        <EmployeeDocuments
                                            employeeId={id}
                                            documentType={documentTypeEnum.EMPLOYEE_MOBILIZATION}
                                            scroll={{ y: 400, x: 800 }}
                                            refresh={refreshFiles}
                                            CSVFileName={geti18nText('systematization.documents.tab')}
                                            canPreview={canCreateActions()}
                                            canDownload={canCreateActions()}
                                            canEdit={canCreateActions()}
                                            canAddNewFile={canCreateActions()}
                                            canDelete={canCreateActions()}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </Modal>
                        </>
                    )}
                    {historyModalVisible && (
                        <Modal
                            title={geti18nText('mobilization.history.info')}
                            visible={historyModalVisible}
                            onCancel={() => {
                                setHistoryModalVisible(false);
                            }}
                            okButtonProps={{ hidden: true }}
                            maskClosable={false}
                            width={1200}
                        >
                            <NyHistory id={id} link={'mobilization'} type={'mobilization'} maxHeight={'65vh'} />
                        </Modal>
                    )}
                </>
            ) : (
                <Empty />
            )}
        </>
    );
};
export default EmployeeWorkObligationEdit;
