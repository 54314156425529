import { NyDataTable, geti18nText } from '@nybble/nyreact';
import { ReactText, useState } from 'react';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import PlanActions, { getPlanActionsEmployeeAnalyze } from '../schedule-suggestions/scheduleSuggestionsActions';
import { useHistory } from 'react-router-dom';

const EmployeeAnalyzeTable = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    columns,
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
    setDefaultFilterValue,
    refresh,
    scroll,
    activeListSubTabs,
    activeListTabs,
    externalFiltersConfig,
    filterFormInstance,
    planAction = undefined,
    showRowSelectionForPlan = false,
}: any) => {
    const history = useHistory();
    const table = useTableSettings();
    const [refreshTable, setRefreshTable] = useState<any>(1);
    const enMilitaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const enMilitaryWorkApprovalAction = useEnum('MILITARY_WORK_APPROVAL_ACTION');
    const [employeeIds, setSelectedEmployeeIds] = useState<any>([]);
    const [hasSelectedValues, setHasSelectedValues] = useState(false);
    const [tableHasSelectedAllRowsTable, seTableHasSelectedAllRowsTable] = useState<boolean>(false);
    const [filteredInfo, setFilteredInfo] = useState<any>({});

    const canCreate = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const canCreateActions = (workApprovalType: any, workApprovalAction: any) => {
        return canCreate() && planAction == workApprovalAction;
    };

    const canRowSelect = () => {
        return showRowSelectionForPlan && planAction && canCreate();
    };

    let clearKeysEmployeeSelect: any;

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void,
        tableHasSelectedAllRows: boolean
    ) => {
        setHasSelectedValues(hasSelected);
        setSelectedEmployeeIds(selectedRowKeys);
        clearKeysEmployeeSelect = clearSelectedRowKeys;
        seTableHasSelectedAllRowsTable(tableHasSelectedAllRows);
    };

    const addedButtons = () => {
        return (
            <>
                {canRowSelect() &&
                    getPlanActionsEmployeeAnalyze(
                        planAction,
                        enMilitaryWorkApprovalType,
                        enMilitaryWorkApprovalAction,
                        employeeIds,
                        hasSelectedValues,
                        history,
                        tableHasSelectedAllRowsTable ? filteredInfo : null
                    )}

                <PlanActions
                    setRefreshTable={setRefreshTable}
                    canCreateActions={canCreateActions}
                    isCustomView={true}
                    planAction={planAction}
                    historyRef={history}
                />
            </>
        );
    };

    const handleChange = (searchParams: any, addedData: any, pagination: any) => {
        setFilteredInfo(
            searchParams?.search && JSON.parse(decodeURI(searchParams.search))
                ? JSON.parse(decodeURI(searchParams.search))
                : null
        );
    };
    return (
        <NyDataTable
            nyId={'human-employee-analyze-table' + activeListTabs + activeListSubTabs}
            url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.ANALYZE_LIST}
            addNewButtonText={geti18nText('employeeAnalyze.table.add')}
            showRecordModal={true}
            buttonsClassName={canRowSelect() || showRowSelection ? '' : 'buttons-sticky'}
            showRowSelection={canRowSelect() ? true : showRowSelection}
            rowSelectionModal={canRowSelect() ? (setSelectedValuesFunc as any) : rowSelectionModal}
            rowSelectionType={rowSelectionType}
            enableSelectAllRowsButton={true}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            columns={columns}
            idOpenLink={false}
            scroll={scroll}
            shortcuts={true}
            fetchWhenChange={refresh || refreshTable}
            setDefaultFilterValue={setDefaultFilterValue}
            onDataLoaded={table.onDataLoaded}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            externalFiltersConfig={externalFiltersConfig}
            filterFormInstance={filterFormInstance}
            excludeFromLocalStorage={true}
            addedButtons={addedButtons}
            onTableChangeGetVariousParams={handleChange}
        />
    );
};

export default EmployeeAnalyzeTable;
