import { FormOutlined } from '@ant-design/icons';
import { NySession, geti18nText } from '@nybble/nyreact';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { getTransparentColor, GetAttendanceRegistrationIcon } from '../../../../utils/Utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';

const DailyAttendanceIcons = ({
    record,
    canCreate = undefined,
    canCreateOnlyUser = undefined,
    setEditModalData = undefined,
    setEditModalVisible = undefined,
    noEditModal = false,
}: any) => {
    const canOpen = noEditModal
        ? false
        : canCreate() || (canCreateOnlyUser() && record?.userId !== NySession.getUser()?.id);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    return (
        record?.attendanceRegistration?.value &&
        JSON.parse(record?.attendanceRegistration?.value).map((item: any) => (
            <div style={{ display: 'flex' }}>
                <Tag
                    style={{
                        marginTop: '2px',
                        position: 'relative',
                        color: isDarkTheme ? 'lightgray' : 'black',
                        backgroundColor: getTransparentColor(item.attendance_work_type_color, 0.25),
                        border: `1px solid ${getTransparentColor(
                            item.attendance_work_type_color,
                            isDarkTheme ? 0.25 : 0.5
                        )}`,
                        cursor: canOpen ? 'pointer' : 'default',
                    }}
                    onClick={(e: any) => {
                        if (canOpen) {
                            setEditModalData(item);
                            setEditModalVisible(true);
                        }
                    }}
                >
                    <Tooltip
                        placement={'right'}
                        title={
                            <>
                                {item?.location_in_name || item?.registration_in || item?.attendance_work_type_name ? (
                                    <>
                                        {item?.location_in_name ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.location.in', [
                                                    item?.location_in_name,
                                                ])}
                                                <br />
                                            </>
                                        ) : undefined}
                                        {item?.registration_in ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.type.in')}{' '}
                                                {item?.registration_in &&
                                                    geti18nText(
                                                        'app.enum.ATTENDANCE_REGISTRATION_TYPE.' + item?.registration_in
                                                    )}
                                                <br />
                                            </>
                                        ) : undefined}
                                    </>
                                ) : undefined}
                                {item?.location_out_name || item?.registration_out ? (
                                    <>
                                        {item?.location_out_name ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.location.out', [
                                                    item?.location_out_name,
                                                ])}
                                                <br />
                                            </>
                                        ) : undefined}
                                        {item?.registration_out ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.type.out')}{' '}
                                                {item?.registration_out &&
                                                    geti18nText(
                                                        'app.enum.ATTENDANCE_REGISTRATION_TYPE.' +
                                                            item?.registration_out
                                                    )}
                                                <br />
                                            </>
                                        ) : undefined}
                                    </>
                                ) : undefined}
                                {(item?.registration_out || item?.location_out_name) &&
                                    item?.type_name &&
                                    item?.type_name}
                            </>
                        }
                    >
                        <div
                            key={item.id}
                            style={{
                                display: 'inline-flex',
                                paddingTop: '2px',
                            }}
                        >
                            <div>
                                <GetAttendanceRegistrationIcon registration={item.registration_in} />
                                <span> {moment(Date.parse(item.from)).format('HH:mm')} </span>
                            </div>
                            {(item?.to || item?.location_out_name) && (
                                <div style={{ marginLeft: '10px' }}>
                                    <GetAttendanceRegistrationIcon registration={item.registration_out} />
                                    <span> {moment(Date.parse(item.to)).format('HH:mm')} </span>
                                </div>
                            )}
                        </div>
                    </Tooltip>
                </Tag>
                <Tag
                    color="default"
                    style={{
                        position: 'relative',
                        marginTop: '2px',
                    }}
                >
                    {item?.attendance_work_type_name}
                </Tag>
                {item?.edited && (
                    <div>
                        <Tooltip
                            placement="top"
                            title={geti18nText('app.default.record.edited', [
                                item?.edited_name,
                                moment(item?.edit_time).format('DD.MM.YYYY HH:mm'),
                            ])}
                            overlayInnerStyle={{ width: 'max-content' }}
                        >
                            <Tag
                                color="orange"
                                style={{
                                    marginTop: '2px',
                                    position: 'relative',
                                    marginRight: '2px',
                                }}
                            >
                                <FormOutlined
                                    style={{
                                        fontSize: '16px',
                                        paddingTop: '2px',
                                    }}
                                />
                            </Tag>
                        </Tooltip>
                    </div>
                )}
            </div>
        ))
    );
};

export default DailyAttendanceIcons;
