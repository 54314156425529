import { DeleteOutlined } from '@ant-design/icons';
import {
    NyDataEdit,
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Tabs } from 'antd';
import React, { ReactText, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchWorkplaceDetails,
} from '../../../../utils/Filters';
import {
    customEmployeeRenderOption,
    errorNodeNotification,
    errorNotification,
    fileDownload,
    getDateFormat,
    okNotification,
    setSearchFormat,
} from '../../../../utils/Utils';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { BusinessUnitSearch } from '../business-unit/search';
import DepartmentSearch from '../defense-department/department/search';
import SectionSearch from '../defense-department/section/search';
import WorkplaceDetailsIndex from '../workplace/DetailIndex';

const ScheduleSuggestionsEdit = (props: any) => {
    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const militaryWorkApprovalStatus = useEnum('MILITARY_WORK_APPROVAL_STATUS');
    const mobilizationReportType = useEnum('MOBILIZATION_REPORT_TYPE');
    const militaryWorkApprovalFileType = useEnum('MILITARY_WORK_APPROVAL_FILE_TYPE');
    const militaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const enMilitaryWorkApprovalAction = useEnum('MILITARY_WORK_APPROVAL_ACTION');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState<any>(geti18nText('schedule.suggestions.edit.header'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [visibleModalApproval, setVisibleModalApproval] = useState<any>(false);
    const [typeMORH, setTypeMORH] = useState<any>(false);
    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [rowModalVisible, setRowModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);
    const [sentToMorhAction, setSentToMorhAction] = useState<any>(false);
    const [approvalForm] = Form.useForm();
    const [employeeId, setEmployeeId] = useState<any>(undefined);
    const [businessUnit, setBusinessUnit] = useState<any>(undefined);
    const [businessUnitAddToSuggest, setBusinessUnitAddToSuggest] = useState<any>(undefined);
    const [workplace, setWorkplace] = useState<any>(undefined);
    const [workplaceAddToSuggest, setWorkplaceAddToSuggest] = useState<any>(undefined);
    const [selectedRowEmployee, setSelectedRowEmployee] = useState<any>(undefined);
    const [editEmployee, setEditEmployee] = useState<any>(undefined);
    const [activeKey, setActiveKey] = useState('1');
    const [activeKeyModalApprove, setActiveKeyModalApprove] = useState('1');
    const uploadFilesRef = useRef<any>(null);
    const [refreshFiles, setRefreshFiles] = useState(0);
    const [department, setDepartment] = useState<any>(null);
    const [defenseSection, setDefenseSection] = useState<any>([]);
    const [generateReportModalVisible, setGenerateReportModalVisible] = useState<boolean>(false);
    const [generateModalForm] = Form.useForm();

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultFilterValueWorkplaceDetails = () => {
        let filter: any = [{ field: 'active', condition: 'equals_bool', value: 1 }];
        if (businessUnitAddToSuggest?.id > 0) {
            filter.push({
                field: 'businessUnit.id',
                condition: 'equals',
                value: businessUnitAddToSuggest?.id,
            });
        }
        if (employee?.id > 0) {
            filter.push({
                field: 'employee.id',
                condition: 'equals',
                value: employeeId?.id,
            });
        }
        return filter;
    };

    const setDefaultFilterValueWorkplaceDetailsEdit = () => {
        let filter: any = [{ field: 'active', condition: 'equals_bool', value: 1 }];
        if (businessUnit?.id > 0) {
            filter.push({
                field: 'businessUnit.id',
                condition: 'equals',
                value: businessUnit?.id,
            });
        }
        if (selectedRowEmployee?.employee?.id > 0) {
            filter.push({
                field: 'employee.id',
                condition: 'equals',
                value: selectedRowEmployee?.employee?.id,
            });
        }
        return filter;
    };

    function setValues(dataForm: any) {
        setEditHeader(
            geti18nText('app.enum.MILITARY_WORK_APPROVAL_ACTION.' + dataForm.action) +
                ': ' +
                geti18nText('app.enum.MILITARY.TYPE.' + dataForm.type) +
                (dataForm.type === militaryWorkApprovalType.MORH
                    ? dataForm.defenseDepartment
                        ? ' - ' + dataForm.defenseDepartment.name
                        : dataForm.defenseSection
                        ? ' - ' +
                          geti18nText('schedule.suggestions.table.column.section') +
                          ' ' +
                          dataForm.defenseSection.name
                        : ''
                    : '')
        );
        setDepartment(dataForm.defenseDepartment);
        setTypeMORH(dataForm.type === militaryWorkApprovalType.MORH);
        setDataForm(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('schedule.suggestions.edit.header'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    let clearKeysDeleteGroup: any;
    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    const removeFromGroup = (employeeValue: any = null) => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.employee.id;
        });

        const employeeId = employeeValue?.id ? [employeeValue.id] : res;

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.REMOVE_EMPLOYEES, undefined, {
            militaryWorkApprovalId: dataForm.id,
            employeesId: employeeId,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                if (employeeValue) {
                    setRowModalVisible(false);
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const refreshForm = () => {
        setIsAddListModalVisibleGroup(false);
        setRefreshTable((refreshTable) => refreshTable + 1);
        form.resetFields();
        setBusinessUnitAddToSuggest(undefined);
        setWorkplaceAddToSuggest(undefined);
        setEmployeeId(undefined);
    };

    const addEmployeeToSuggestion = () => {
        form.validateFields()
            .then((values: any) => {
                setLoadingModalGroup(true);
                let data: any = {
                    militaryWorkApprovalId: dataForm.id,
                    type: dataForm.type,
                    employeeId: employeeId?.id,
                };

                if (businessUnitAddToSuggest) {
                    data.businessUnit = { id: businessUnitAddToSuggest.id };
                }
                if (workplaceAddToSuggest) {
                    data.workplaceDetails = { id: workplaceAddToSuggest.id };
                }
                if (values.note) {
                    data.remark = values.note;
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.ADD_EMPLOYEE, undefined, data).then(
                    (result: any) => {
                        if (setLoadingModalGroup) setLoadingModalGroup(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            okNotification();
                            refreshForm();
                        } else {
                            errorNotification(geti18nText('app.default.save.nok'));
                        }
                    }
                );
                setLoadingModalGroup(false);
                setIsAddListModalVisibleGroup(false);
            })
            .catch(() => {
                return false;
            });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('schedule.suggestions.modal.employees.btn.add.employee.to.suggestion')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('schedule.suggestions.remove.from.group.comfirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('schedule.suggestions.modal.employees.btn.remove')}
                        </Button>
                    </Popconfirm>
                </div>
            }
        </React.Fragment>
    );

    const onEmployeeChange = (val: any) => {
        if (val.id > 0) {
            setEmployeeId(val);
            let businessUnit = val?.text?.props['business-unit'];

            if (businessUnit) {
                setBusinessUnitAddToSuggest(businessUnit);
                let businessUnitFormatted = {
                    id: businessUnit.id,
                    name: businessUnit.code ? businessUnit.name + ' (' + businessUnit.code + ')' : businessUnit.name,
                };
                businessUnitFormatted = setSearchFormat(businessUnitFormatted, 'name', 'name');
                form.setFieldsValue({ businessUnit: businessUnitFormatted });
            }

            NyRequestResolver.requestGet(
                CONSTANTS_REQ.WORKPLACE_DETAILS.GET_BY_WAR_SYSTEMATIZATION + '/' + val.id
            ).then((response: any) => {
                const vocationDetails = response?.data;
                if (response.status == RESPONSE.OK && vocationDetails?.id) {
                    NyRequestResolver.requestGet(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CHECK_WORKPLACE_DETAILS, {
                        workplaceDetailsId: vocationDetails.id,
                        militaryWorkApprovalId: dataForm.id,
                        employeeId: val.id,
                    }).then((result: any) => {
                        if (result.status === RESPONSE.OK) {
                            let workplaceDetailsText = {
                                text: vocationDetails?.workplace?.vocation?.name,
                                vocationName: vocationDetails?.workplace?.vocation?.name,
                                vocationDescription: vocationDetails?.workplace?.vocationDescription?.name,
                            };
                            const workplaceDetailsFormatted = {
                                key: vocationDetails.id,
                                id: vocationDetails.id,
                                text: customRenderOptionWorkPlaceDetails(workplaceDetailsText),
                            };
                            setWorkplaceAddToSuggest(workplaceDetailsFormatted);
                            form.setFieldsValue({ workplaceDetailsToSuggest: workplaceDetailsFormatted });
                        } else {
                            errorNodeNotification(
                                undefined,
                                'schedule.suggestions.genereate.edit.employee.workplaceDetails.exist'
                            );
                        }
                    });
                } else {
                    setWorkplaceAddToSuggest(null);
                    form.setFieldsValue({ workplaceDetailsToSuggest: null });
                }
            });
        } else {
            setEmployeeId(null);
            setBusinessUnitAddToSuggest(null);
            setWorkplaceAddToSuggest(null);
            form.setFieldsValue({ businessUnit: null, workplaceDetailsToSuggest: null });
        }
    };

    const approveAction = (values?: any) => {
        setConfirmLoading(true);
        let data: any = {
            militaryWorkApproval: {
                ...dataForm,
                type: typeMORH ? militaryWorkApprovalType.MORH : militaryWorkApprovalType.GOVERNOR,
                id: dataForm.id,
            },
        };
        if (values) {
            if (values.objectName) {
                data.militaryWorkApproval.objectName = values.objectName;
            }
            if (values.classification) {
                data.militaryWorkApproval.classification = values.classification;
            }
            if (values.submissionNumber) {
                data.militaryWorkApproval.submissionNumber = values.submissionNumber;
            }
            if (values.dateInput) {
                data.militaryWorkApproval.inputDate = getDateFormat(values.dateInput);
            }
            if (values.dateOutput) {
                data.militaryWorkApproval.outputDate = getDateFormat(values.dateOutput);
            }
        }
        if (typeMORH) {
            if (dataForm.defenseDepartment) {
                data.militaryWorkApproval.defenseDepartment = { id: dataForm.defenseDepartment.id };
            }
            if (dataForm.defenseSection) {
                data.militaryWorkApproval.defenseSection = { id: dataForm.defenseSection.id };
            }
        }

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.APPROVE, undefined, data).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification(geti18nText('schedule.suggestions.edit.approve.success'));
                    props.setVisible(false);
                    props.onSave();
                    approvalForm.resetFields();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
                setConfirmLoading(false);
            }
        );
    };

    const onRowSelect = (item: any) => {
        setSelectedRowEmployee(item);
        let url = CONSTANTS_REQ.MILITARY_WORK_APPROVAL.GET_EMPLOYEE;
        url = url.replace('{militaryWorkApprovalId}', dataForm.id);
        url = url.replace('{itemId}', item.id);
        NyRequestResolver.requestGet(url, undefined).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                let data = result.data;
                if (data.employee) {
                    setEditEmployee(data.employee);
                }
                if (data.businessUnit) {
                    setBusinessUnit(data.businessUnit);
                    data.businessUnit = {
                        id: data?.businessUnit?.id,
                        name: data.businessUnit.code
                            ? data.businessUnit.name + ' (' + data.businessUnit.code + ')'
                            : data.businessUnit.name,
                    };
                    data.businessUnit = setSearchFormat(data.businessUnit, 'name', 'name');
                    form.setFieldsValue({ businessUnit: data.businessUnit });
                }
                if (data.workplaceDetails) {
                    let workplaceDetails = {
                        text: data.workplaceDetails?.workplace?.vocation?.name,
                        vocationName: data.workplaceDetails?.workplace?.vocation?.name,
                        vocationDescription: data.workplaceDetails?.workplace?.vocationDescription?.name,
                    };
                    setWorkplace({
                        key: data.workplaceDetails.id,
                        id: data.workplaceDetails.id,
                        text: customRenderOptionWorkPlaceDetails(workplaceDetails),
                    });
                    form.setFieldsValue({
                        workplaceDetails: {
                            key: data.workplaceDetails.id,
                            id: data.workplaceDetails.id,
                            text: customRenderOptionWorkPlaceDetails(workplaceDetails),
                        },
                    });
                }
                if (data.remark) {
                    form.setFieldsValue({ note: data.remark });
                }
            }
        });
        setRowModalVisible(true);
    };

    const saveToEmployeeRow = () => {
        let data: any = {
            id: selectedRowEmployee.id,
            militaryWorkApproval: { id: dataForm.id },
            employee: { id: selectedRowEmployee.employee.id },
        };
        if (businessUnit) {
            data.businessUnit = { id: businessUnit.id };
        }
        if (workplace) {
            data.workplaceDetails = { id: workplace.id };
        }
        if (form.getFieldValue('note')) {
            data.remark = form.getFieldValue('note');
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.UPDATE_EMPLOYEE, undefined, {
            employeeItem: data,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                setRowModalVisible(false);
                form.resetFields();
                setBusinessUnit(undefined);
                setWorkplace(undefined);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const sentToMorh = (values: any) => {
        setConfirmLoading(true);
        let data: any = { militaryWorkApprovalId: dataForm.id };
        if (values) {
            if (values.objectName) {
                data.sentObjectName = values.objectName;
            }
            if (values.classification) {
                data.sentClassification = values.classification;
            }
            if (values.submissionNumber) {
                data.sentSubmissionNumber = values.submissionNumber;
            }
            if (values.dateInput) {
                data.sentInputDate = getDateFormat(values.dateInput);
            }
            if (values.dateOutput) {
                data.sentOutputDate = getDateFormat(values.dateOutput);
            }
        }

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.SEND_PLAN, undefined, data).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification(geti18nText('schedule.suggestions.edit.sent.success'));
                    props.setVisible(false);
                    approvalForm.resetFields();
                    setSentToMorhAction(false);
                    form.resetFields();
                    props.onSave();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
                setConfirmLoading(false);
            }
        );
    };

    const closeReportModal = () => {
        setGenerateReportModalVisible(false);
        setDefenseSection([]);
        generateModalForm.resetFields();
    };

    const downloadFile = (type: any, refId: any, name: string) => {
        fileDownload(
            CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
            { type: type, refId: refId },
            geti18nText('schedule.suggestions.generate.plan') +
                '(' +
                getDateFormat(new Date(), 'DD.MM.YYYY') +
                ')' +
                (dataForm.type === militaryWorkApprovalType.MORH && name ? ' - ' + name : '') +
                '.xlsx'
        );
    };

    const downloadDocumentForPlan = () => {
        const obj = dataForm?.defenseSection?.id
            ? { ...dataForm.defenseSection, type: mobilizationReportType.EV_RO_2_2 }
            : {
                  id: dataForm.id,
                  name: dataForm?.defenseDepartment?.name ? dataForm?.defenseDepartment?.name : '',
                  type: mobilizationReportType.EV_RO_2_2,
              };

        downloadFile(obj.type, obj.id, obj.name);
    };

    const downloadDocument = (sections: any = null) => {
        const type = mobilizationReportType.EV_RO_2_2;
        if (sections?.length > 0) {
            let section: any;
            for (section of sections) {
                if (section.id && section.name) {
                    downloadFile(type, section.id, section.name);
                }
            }
        } else {
            downloadDocumentForPlan();
        }
        closeReportModal();
    };

    const formSelectedEmployeeRowTitle = () => {
        if (selectedRowEmployee) {
            return (
                (selectedRowEmployee.person.oib ? selectedRowEmployee.person.oib + ' - ' : '') +
                (selectedRowEmployee.person.firstName ? selectedRowEmployee.person.firstName : '') +
                ' ' +
                (selectedRowEmployee.person.lastName ? selectedRowEmployee.person.lastName : '')
            );
        }
    };

    const customRenderOptionWorkPlaceDetails = (option: any) => {
        return (
            <>
                <span>
                    {option.vocationName + (option.vocationDescription ? ' - ' + option.vocationDescription : '')}
                </span>
            </>
        );
    };

    const onChangeWorkplaceEditEmployee = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CHECK_WORKPLACE_DETAILS, {
            workplaceDetailsId: value.id,
            militaryWorkApprovalId: dataForm.id,
            employeeId: editEmployee?.id,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setWorkplace(value);
            } else {
                setWorkplace(workplace ? workplace : undefined);
                form.setFieldsValue({ workplaceDetails: workplace ? workplace : undefined });
                errorNodeNotification(undefined, 'schedule.suggestions.genereate.edit.employee.workplaceDetails.exist');
            }
        });
    };

    const onChangeWorkplaceAddEmployee = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CHECK_WORKPLACE_DETAILS, {
            workplaceDetailsId: value.id,
            militaryWorkApprovalId: dataForm.id,
            employeeId: employeeId?.id,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setWorkplaceAddToSuggest(value);
            } else {
                setWorkplaceAddToSuggest(workplaceAddToSuggest ? workplaceAddToSuggest : undefined);
                form.setFieldsValue({
                    workplaceDetailsToSuggest: workplaceAddToSuggest ? workplaceAddToSuggest : undefined,
                });
                errorNodeNotification(undefined, 'schedule.suggestions.genereate.edit.employee.workplaceDetails.exist');
            }
        });
    };

    const onChangeBusinessUnit = (value: any) => {
        setBusinessUnit(value);
        setWorkplace(undefined);
        form.setFieldsValue({ workplaceDetails: undefined });
    };

    const onChangeBusinessUnitAddToSuggest = (value: any) => {
        setBusinessUnitAddToSuggest(value);
        setWorkplaceAddToSuggest(undefined);
        form.setFieldsValue({ workplaceDetailsToSuggest: undefined });
    };

    const onChangeTab = (key: any) => {
        setActiveKey(key);
    };

    const getFilesView = (canAddNewFile: any) => (
        <>
            {dataForm?.id && (
                <NyFilesUpload
                    id={dataForm?.id}
                    hasNoRefId
                    url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.LIST_FILES.replace(
                        '{militaryWorkApprovalId}',
                        dataForm?.id
                    )}
                    manageUploadUrl={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.ADD_FILE.replace(
                        '{militaryWorkApprovalId}',
                        dataForm?.id
                    )}
                    manageDeleteUrl={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.DELETE_FILE}
                    maxFiles={1}
                    defaultFilesFilter={() => [
                        {
                            field: 'type',
                            condition: 'equals',
                            value: sentToMorhAction
                                ? militaryWorkApprovalFileType.EXPORT
                                : militaryWorkApprovalFileType.IMPORT,
                        },
                    ]}
                    morhType={
                        sentToMorhAction ? militaryWorkApprovalFileType.EXPORT : militaryWorkApprovalFileType.IMPORT
                    }
                    canAddNewFile={canAddNewFile}
                    canDelete={true}
                    canEdit={false}
                    ref={uploadFilesRef}
                    employee={employee}
                    maxHeight={'205px'}
                    showHeader={false}
                    isMobilization={true}
                    refresh={refreshFiles}
                />
            )}
        </>
    );

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.EDIT}
                setValues={setValues}
                width={1800}
                form={form}
                goBack={() => history.goBack()}
                hideActivationButtons={true}
                hideSubmitButton={true}
                paramsId={id}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={false}
                customFooterContent={
                    dataForm && dataForm.status !== militaryWorkApprovalStatus.APPROVAL && activeKey !== '2'
                        ? getCustomFooterContent
                        : undefined
                }
            >
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        onChangeTab(key);
                        if (key == '2') {
                            setRefreshFiles((refresh: any) => refresh + 1);
                        }
                    }}
                >
                    <Tabs.TabPane tab={geti18nText('employee.table.header')} key="1">
                        {dataForm && dataForm.status !== militaryWorkApprovalStatus.APPROVAL && (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                                <Button
                                    onClick={() => {
                                        if (department?.id > 0) {
                                            generateModalForm.setFieldsValue({
                                                parent: {
                                                    key: department.id,
                                                    id: department.id,
                                                    name: department.name,
                                                },
                                            });
                                            setGenerateReportModalVisible(true);
                                        } else {
                                            downloadDocumentForPlan();
                                        }
                                    }}
                                    style={{ marginRight: 5 }}
                                >
                                    {geti18nText('schedule.suggestions.generate.document')}
                                </Button>
                                {dataForm.status !== militaryWorkApprovalStatus.SENT && (
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 5 }}
                                        onClick={() => {
                                            setSentToMorhAction(true);
                                            setVisibleModalApproval(true);
                                        }}
                                    >
                                        {dataForm.type === militaryWorkApprovalType.MORH
                                            ? geti18nText('schedule.suggestions.sent.to.morh')
                                            : geti18nText('schedule.suggestions.sent.to.guvernor')}
                                    </Button>
                                )}
                                <Button
                                    onClick={() => setVisibleModalApproval(true)}
                                    style={{ backgroundColor: 'green', color: 'white' }}
                                >
                                    {geti18nText('schedule.suggestions.edit.approval.of.the.proposal')}
                                </Button>
                            </div>
                        )}
                        {dataForm && (
                            <NyDataTable
                                nyId="human-military-work-approval-employees-in-approval"
                                rowKey="id"
                                url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.EMPLOYEES_IN_APPROVAL + '/' + dataForm.id}
                                showRowSelection={
                                    dataForm.status !== militaryWorkApprovalStatus.APPROVAL ? true : false
                                }
                                readonly={dataForm.status !== militaryWorkApprovalStatus.APPROVAL ? false : true}
                                hideButtons
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                scroll={props?.editProps?.scroll}
                                setDefaultFilterValue={setDefaultFilterValue}
                                rowSelectionType={'checkbox'}
                                fetchWhenChange={refreshTable}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                                showRecordModal={true}
                                hideNewButton={true}
                                onRowSelect={onRowSelect}
                                columns={[
                                    ...[
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.bussiness.unit'
                                            ),
                                            width: '11%',
                                            dataIndex: [
                                                "concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))",
                                            ],
                                            render: (text: any, record: any) => {
                                                if (record.businessUnit) {
                                                    return (
                                                        record.businessUnit.name +
                                                        (record.businessUnit.code
                                                            ? ' (' + record.businessUnit.code + ')'
                                                            : '')
                                                    );
                                                }
                                            },
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchByCodebookBusinessUnit(),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.name.lastname'
                                            ),
                                            dataIndex:
                                                "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name ) ",
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            render: (text: any, record: any) => {
                                                if (record.person) {
                                                    return (
                                                        <div>
                                                            {(record.person.lastName
                                                                ? record.person.lastName + ', '
                                                                : '') +
                                                                (record?.person?.parentName
                                                                    ? record?.person?.parentName + ', '
                                                                    : record?.person?.firstName
                                                                    ? ' -, '
                                                                    : '') +
                                                                (record.person.firstName
                                                                    ? record.person.firstName
                                                                    : '')}
                                                        </div>
                                                    );
                                                }
                                            },
                                            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.work.place'
                                            ),
                                            width: '15%',
                                            dataIndex: ['workplaceDetails', 'id'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchWorkplaceDetails(),
                                            render: (created: any, record: any) => {
                                                if (record.vocation) {
                                                    return (
                                                        record.vocation.name +
                                                        (record.vocationDescription
                                                            ? ' - ' + record.vocationDescription.name
                                                            : '')
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.birthdate'
                                            ),
                                            dataIndex: ['person', 'birthDate'],
                                            width: '8%',
                                            sorter: (a: any, b: any) => {},
                                            render: (created: any, record: any) => {
                                                if (record.person && record.person.birthDate) {
                                                    return getDateFormat(record.person.birthDate, 'DD.MM.YYYY');
                                                }
                                            },
                                            ...getColumnDateOption(false),
                                        },
                                        {
                                            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
                                            dataIndex: ['person', 'oib'],
                                            width: '3%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.qualifications'
                                            ),
                                            dataIndex: ['employeeProfessionalQualification', 'name'],
                                            width: '8%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchByCodebookSimple(
                                                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                                                'employeeVocationProfessionalQualification.id',
                                                'name',
                                                'name',
                                                {
                                                    id: 'id',
                                                    label: 'name',
                                                    text: 'name',
                                                    code: 'code',
                                                }
                                            ),
                                            render: (created: any, record: any) => {
                                                if (
                                                    record.employee &&
                                                    record?.employee?.vocation?.professionalQualification
                                                ) {
                                                    return record.employee.vocation.professionalQualification;
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.address'
                                            ),
                                            dataIndex: "concat(address.street, ' ', settlement.name ) ",
                                            sorter: (a: any, b: any) => {},
                                            render: (created: any, record: any) => {
                                                if (record.address) {
                                                    return (
                                                        <div>
                                                            {(record.address.street
                                                                ? record.address.street + ', '
                                                                : '') +
                                                                (record.address.settlement
                                                                    ? record.address.settlement.name
                                                                    : '')}
                                                        </div>
                                                    );
                                                }
                                            },
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.mobile'
                                            ),
                                            dataIndex: ['contact', 'mobile'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.email'
                                            ),
                                            dataIndex: ['contact', 'email'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                    ],
                                    ...(typeMORH === true
                                        ? [
                                              {
                                                  title: geti18nText(
                                                      'schedule.suggestions.modal.employees.table.column.regional.department.defense'
                                                  ),
                                                  dataIndex: ['defenseDepartment', 'name'],
                                                  width: '12%',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearchByCodebookSimpleWithAddedData(
                                                      CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                                                      'defenseDepartment.id',
                                                      setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
                                                  ),
                                              },
                                              {
                                                  title: geti18nText(
                                                      'schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'
                                                  ),
                                                  dataIndex: ['defenseSection', 'name'],
                                                  width: '12%',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearchByCodebookSimpleWithAddedData(
                                                      CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                                                      'defenseSection.id',
                                                      setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
                                                  ),
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('app.default.files')} key="2">
                        {getFilesView(true)}
                    </Tabs.TabPane>
                </Tabs>
                <Modal
                    title={geti18nText('schedule.suggestions.modal.employees.btn.add.employee.to.suggestion')}
                    open={isAddListModalVisibleGroup}
                    okText={geti18nText('app.default.button.add')}
                    cancelText={geti18nText('app.default.button.cancel')}
                    onOk={(e) => addEmployeeToSuggestion()}
                    confirmLoading={loadingModalGroup}
                    okButtonProps={{ htmlType: 'submit' }}
                    destroyOnClose={true}
                    width={600}
                    onCancel={() => {
                        form.resetFields();
                        setEmployeeId(undefined);
                        setBusinessUnitAddToSuggest(undefined);
                        setWorkplaceAddToSuggest(undefined);
                        setIsAddListModalVisibleGroup(false);
                    }}
                >
                    <>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('schedule.suggestions.modal.add.employee')}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    name="employee"
                                >
                                    {dataForm && (
                                        <NySearchField
                                            url={CONSTANTS_REQ.EMPLOYEE.MILITARY_WORK_APPROVAL}
                                            addedData={{
                                                militaryWorkApprovalId: dataForm.id,
                                                type: dataForm.type,
                                                action: dataForm.action,
                                            }}
                                            map={{
                                                id: 'id',
                                                label: 'text',
                                                employmentRecordId: 'employmentRecordId',
                                                businessUnit: 'businessUnit',
                                            }}
                                            searchBy="person.first_name || ' ' || person.last_name"
                                            itemName={[
                                                ['person', 'firstName'],
                                                ['person', 'lastName'],
                                            ]}
                                            setDefaultFilterValue={setDefaultFilterValue}
                                            renderOption={customEmployeeRenderOption}
                                            customItemNameLabel={'firstName lastName'}
                                            style={{ width: '100%' }}
                                            onChange={onEmployeeChange}
                                            order="person.last_name, person.first_name"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <BusinessUnitSearch
                                    formItemName={'businessUnit'}
                                    onChange={onChangeBusinessUnitAddToSuggest}
                                />
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('schedule.suggestions.modal.employees.table.column.work.place')}
                                    name="workplaceDetailsToSuggest"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        disabled={!(businessUnitAddToSuggest?.id > 0)}
                                        url={CONSTANTS_REQ.WORKPLACE_DETAILS.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            vocationName: 'vocation.name',
                                            vocationDescription: 'vocationDescription.name',
                                        }}
                                        searchBy="vocation.name"
                                        order={'vocation.name'}
                                        onChange={onChangeWorkplaceAddEmployee}
                                        setDefaultFilterValue={setDefaultFilterValueWorkplaceDetails}
                                        renderOption={customRenderOptionWorkPlaceDetails}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('schedule.suggestions.modal.add.employee.note')}
                                    name="note"
                                >
                                    <Input style={{ width: '100%' }}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                </Modal>
                <Modal
                    open={rowModalVisible}
                    title={formSelectedEmployeeRowTitle()}
                    onOk={(event: any) => {
                        setRowModalVisible(false);
                        saveToEmployeeRow();
                    }}
                    onCancel={() => {
                        form.resetFields();
                        setBusinessUnit(undefined);
                        setWorkplace(undefined);
                        setRowModalVisible(false);
                    }}
                    destroyOnClose={true}
                    okText={geti18nText('app.default.button.save')}
                    cancelText={geti18nText('app.default.button.cancel')}
                    width={600}
                    footer={[
                        <Button
                            onClick={() => {
                                form.resetFields();
                                setBusinessUnit(undefined);
                                setWorkplace(undefined);
                                setRowModalVisible(false);
                            }}
                        >
                            {geti18nText('app.default.button.cancel')}
                        </Button>,
                        <Popconfirm
                            title={geti18nText('schedule.suggestions.remove.from.group.comfirm')}
                            okText={geti18nText('app.default.button.confirm')}
                            cancelText={geti18nText('app.default.button.cancel')}
                            onConfirm={() => removeFromGroup(editEmployee)}
                        >
                            <Button style={{ marginLeft: '5px' }} danger icon={<DeleteOutlined />}>
                                {geti18nText('app.default.remove')}
                            </Button>
                        </Popconfirm>,
                        <Button
                            onClick={() => {
                                setRowModalVisible(false);
                                saveToEmployeeRow();
                            }}
                            type="primary"
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <BusinessUnitSearch formItemName={'businessUnit'} onChange={onChangeBusinessUnit} />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label={geti18nText('schedule.suggestions.modal.employees.table.column.work.place')}
                                name="workplaceDetails"
                            >
                                <NySearchField
                                    disabled={!(businessUnit?.id > 0)}
                                    url={CONSTANTS_REQ.WORKPLACE_DETAILS.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        vocationName: 'vocation.name',
                                        vocationDescription: 'vocationDescription.name',
                                    }}
                                    searchBy="vocation.name"
                                    order={'vocation.name'}
                                    onChange={onChangeWorkplaceEditEmployee}
                                    setDefaultFilterValue={setDefaultFilterValueWorkplaceDetailsEdit}
                                    SearchPopupComponent={
                                        <WorkplaceDetailsIndex
                                            defaultFilter={setDefaultFilterValueWorkplaceDetailsEdit()}
                                        />
                                    }
                                    renderOption={customRenderOptionWorkPlaceDetails}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('schedule.suggestions.modal.add.employee.note')} name="note">
                                <Input></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </NyDataEdit>

            <Modal
                open={visibleModalApproval}
                title={
                    !sentToMorhAction
                        ? geti18nText('schedule.suggestions.edit.approval.header')
                        : dataForm.type === militaryWorkApprovalType.MORH
                        ? geti18nText('schedule.suggestions.sent.to.morh')
                        : geti18nText('schedule.suggestions.sent.to.guvernor')
                }
                onOk={(event: any) => {
                    setVisibleModalApproval(false);
                    setActiveKeyModalApprove('1');
                    approvalForm.submit();
                }}
                destroyOnClose={true}
                onCancel={() => {
                    setVisibleModalApproval(false);
                    setSentToMorhAction(false);
                    setActiveKeyModalApprove('1');
                }}
                okText={
                    sentToMorhAction
                        ? geti18nText('schedule.suggestions.select.sent.to.morh.btn')
                        : geti18nText('app.default.button.approve')
                }
                cancelText={geti18nText('app.default.button.cancel')}
                width={1000}
                okButtonProps={{ disabled: activeKeyModalApprove === '2' }}
                forceRender={true}
                confirmLoading={confirmLoading}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKeyModalApprove}
                    onChange={(key: any) => {
                        setActiveKeyModalApprove(key);
                    }}
                    destroyInactiveTabPane={true}
                >
                    <Tabs.TabPane tab={geti18nText('schedule.suggestions.approve.modal.tab.basic')} key="1">
                        <Form
                            form={approvalForm}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={(val) => (sentToMorhAction ? sentToMorh(val) : approveAction(val))}
                        >
                            {dataForm && (
                                <Row gutter={24}>
                                    {(dataForm.type === militaryWorkApprovalType.MORH || sentToMorhAction) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('schedule.suggestions.edit.approval.name')}
                                                name="objectName"
                                            >
                                                <Input ref={focusInput} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(dataForm.type === militaryWorkApprovalType.MORH || sentToMorhAction) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('schedule.suggestions.edit.classification')}
                                                name="classification"
                                            >
                                                <Input ref={focusInput} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col span={12}>
                                        <Form.Item
                                            label={
                                                dataForm.type === militaryWorkApprovalType.MORH
                                                    ? geti18nText('schedule.suggestions.edit.approval.registry.number')
                                                    : geti18nText(
                                                          'schedule.suggestions.edit.approval.registry.number.governor'
                                                      )
                                            }
                                            name="submissionNumber"
                                        >
                                            <Input ref={focusInput} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={
                                                dataForm.type === militaryWorkApprovalType.MORH
                                                    ? geti18nText(
                                                          'schedule.suggestions.edit.approval.date.document.input'
                                                      )
                                                    : geti18nText(
                                                          'schedule.suggestions.edit.approval.date.document.input.governor'
                                                      )
                                            }
                                            name="dateInput"
                                        >
                                            <NyDatePicker
                                                format="DD.MM.YYYY"
                                                style={{ width: '100%' }}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {(dataForm.type === militaryWorkApprovalType.MORH || sentToMorhAction) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText(
                                                    'schedule.suggestions.edit.approval.date.document.output'
                                                )}
                                                name="dateOutput"
                                            >
                                                <NyDatePicker
                                                    format="DD.MM.YYYY"
                                                    style={{ width: '100%' }}
                                                    mustGetPopupContainer={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('app.default.files')} key="2">
                        {getFilesView(true)}
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
            {generateReportModalVisible && (
                <Modal
                    width={600}
                    open={generateReportModalVisible}
                    title={geti18nText('schedule.suggestions.generate.document')}
                    okText={geti18nText('schedule.suggestions.generate.document')}
                    onOk={() => {
                        downloadDocument(defenseSection);
                    }}
                    onCancel={closeReportModal}
                    destroyOnClose={true}
                >
                    <Form layout="vertical" form={generateModalForm}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <DepartmentSearch
                                    label={geti18nText('employee.mobilization.edit.defenseDepartment')}
                                    canCreate={false}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <SectionSearch
                                    label={geti18nText('mobilization.edit.defenseSectionName')}
                                    setDefaultFilterValue={() => [
                                        { field: 'active', condition: 'equals_bool', value: 1 },
                                        {
                                            field: 'type',
                                            condition: 'equals',
                                            value: defenseDepartmentType.SECTION,
                                        },
                                        { field: 'parentId', condition: 'equals', value: department?.id.toString() },
                                    ]}
                                    canCreate={false}
                                    mode={'multiple'}
                                    value={defenseSection}
                                    departmentId={department?.id}
                                    onChange={(value: any) => {
                                        setDefenseSection(value?.length > 0 ? value : null);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default ScheduleSuggestionsEdit;
