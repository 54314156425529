import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { getDateFormat, GetEmployeeLeaveStatusIcon } from '../../../../utils/Utils';

const EmployeeLeaveTypeIcons = ({ record }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const item: any = JSON.parse(record?.employeeLeaveType?.value);
    const formattedDateFrom = getDateFormat(item?.date_from, 'DD.MM.yyyy.');
    const formattedDateTo = getDateFormat(item?.date_to, 'DD.MM.yyyy.');
    return (
        <div>
            <Tag
                style={{
                    position: 'relative',
                    color: isDarkTheme ? 'lightgray' : 'black',
                    cursor: 'default',
                }}
            >
                <div
                    key={item.id}
                    style={{
                        display: 'inline-flex',
                    }}
                >
                    {formattedDateFrom} - {formattedDateTo}
                </div>
            </Tag>
            {GetEmployeeLeaveStatusIcon(item?.status)}
        </div>
    );
};

export default EmployeeLeaveTypeIcons;
