import { NySession } from '@nybble/nyreact';
export const HumanResourcesRights = (function () {
    return {
        //human resources
        isAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES']);
        },
        //settings
        canCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_HR_SETTINGS']);
        },
        //attendance
        canCreateAttendance: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ATTENDANCE_EDIT']);
        },
        //attendance registration
        canCreateAttendanceRegistration: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ATTENDANCE_ADMIN']);
        },
        canCreateAttendanceRegistrationCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_ATTENDANCE_EDIT']);
        },
        canViewAttendanceRegistrationCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_ATTENDANCE_VIEW']);
        },
        canCreateAttendanceandLeaveSettings: () => {
            return HumanResourcesRights.canCreateAttendanceRegistration() || HumanResourcesRights.canCreateSettings();
        },
        canCreateAttendanceOrAdmin: () => {
            return HumanResourcesRights.isAdmin() || HumanResourcesRights.canCreateAttendance();
        },
        canCreateAttendanceRegistrationOrAdmin: () => {
            return HumanResourcesRights.isAdmin() || HumanResourcesRights.canCreateAttendanceRegistration();
        },
        canCreateAttendanceRegistrationUserOrAdmin: () => {
            return (
                HumanResourcesRights.canCreateAttendanceRegistration() || NySession.hasAnyRole(['ROLE_ATTENDANCE_USER'])
            );
        },
        canCreateAttendanceRegistrationOnlyUser: () => {
            return NySession.hasAnyRole(['ROLE_ATTENDANCE_USER']);
        },
        //employee leave
        canCreateEmployeeLeave: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EMPLOYEE_LEAVE']);
        },
        canCreateLeave: () => {
            return NySession.hasAnyRole([
                'ROLE_ADMIN',
                'ROLE_APPLICATION_SETTINGS_EDIT',
                'ROLE_APPLICATION_SETTINGS',
                'ROLE_HUMAN_RESOURCES',
            ]);
        },
        canViewEmployeeLeaveMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_EMPLOYEE_LEAVE_MENU'];
        },
        canViewEmployeeSickLeaveMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_SICK_LEAVE_MENU'];
        },
        //employee
        canCreateEmployee: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EMPLOYEE_EDIT']);
        },
        canReadEmployeeChangelog: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EMPLOYEE_EDIT', 'ROLE_EMPLOYEE_READ']);
        },
        showCharts: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES_USER',
                'ROLE_HUMAN_RESOURCES_EDIT',
                'ROLE_HUMAN_RESOURCES_MENU',
                'ROLE_HUMAN_RESOURCES_VIEW',
                'ROLE_HUMAN_RESOURCES',
            ];
        },
        isAdminDashboardHR: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES'];
        },
        //codebooks
        canCreateCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_CODEBOOKS_EDIT']);
        },
        canCreateEmployeeCodebooks: () => {
            return HumanResourcesRights.isAdmin() || HumanResourcesRights.canCreateCodebooks();
        },
        canCreateAttendanceAdminAndRegistration: () => {
            return (
                HumanResourcesRights.canCreateAttendanceOrAdmin() ||
                HumanResourcesRights.canCreateAttendanceRegistration()
            );
        },
        canCsvExportCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_CODEBOOKS_EXPORT']);
        },
        canCsvExportEmployeeCodebooks: () => {
            return HumanResourcesRights.isAdmin() || HumanResourcesRights.canCsvExportCodebooks();
        },
        //workplace
        canOnlyCreateWorkplace: () => {
            return NySession.hasAnyRole(['ROLE_WORKPLACE_INIT']);
        },
        canCreateWorkplace: () => {
            return (
                HumanResourcesRights.isAdmin() ||
                HumanResourcesRights.canCreateCodebooks() ||
                HumanResourcesRights.canOnlyCreateWorkplace()
            );
        },
        canCsvExportWorkplace: () => {
            return (
                HumanResourcesRights.isAdmin() ||
                HumanResourcesRights.canCsvExportCodebooks() ||
                HumanResourcesRights.canOnlyCreateWorkplace()
            );
        },
        //contracts
        canViewContractsMenuAll: () => {
            return ['ROLE_ADMIN', 'ROLE_CONTRACT_ADMIN', 'ROLE_CONTRACT', 'ROLE_CONTRACT_VIEW'];
        },
        canViewContractsMenuContract: () => {
            return ['ROLE_ADMIN', 'ROLE_CONTRACT_ADMIN', 'ROLE_CONTRACT'];
        },
        canViewContractsMenuAdmin: () => {
            return ['ROLE_ADMIN', 'ROLE_CONTRACT_ADMIN'];
        },
        canOnlyCreateContract: () => {
            return NySession.hasAnyRole(['ROLE_CONTRACT']);
        },
        canOnlyViewContract: () => {
            return NySession.hasAnyRole(['ROLE_CONTRACT', 'ROLE_CONTRACT_VIEW']);
        },
        canCreateContractAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CONTRACT_ADMIN']);
        },
        canCreateContract: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CONTRACT_ADMIN', 'ROLE_CONTRACT']);
        },
        //education
        canViewEducationCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_EDUCATION_ADMIN'];
        },
        canViewEducationMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_EDUCATION_ADMIN', 'ROLE_EDUCATION'];
        },
        canCreateEducationCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EDUCATION_ADMIN']);
        },
        isEducationAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EDUCATION_ADMIN']);
        },
        isEducationUser: () => {
            return NySession.hasAnyRole(['ROLE_EDUCATION']);
        },
        canViewEDRModal: () => {
            return NySession.hasAnyRole(['ROLE_HUMAN_RESOURCES_USER']);
        },
        //evaluation
        canViewEvaluationCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_EVALUATION_ADMIN'];
        },
        canViewEvaluation: () => {
            return ['ROLE_ADMIN', 'ROLE_EVALUATION_ADMIN', 'ROLE_EVALUATION'];
        },
        isEvaluationAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_EVALUATION_ADMIN']);
        },
        isEvaluationUser: () => {
            return NySession.hasAnyRole(['ROLE_EVALUATION']);
        },
        //other
        canViewEmployeeTaxDeduction: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_EMPLOYEE_VIEW', 'ROLE_EMPLOYEE_EDIT']);
        },
        canViewEmployeeRole: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_EMPLOYEE_VIEW', 'ROLE_MOBILIZATION_EMPLOYEE_VIEW']);
        },
        canViewCharts: () => {
            return NySession.hasAnyRole([
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES_USER',
                'ROLE_HUMAN_RESOURCES_EDIT',
                'ROLE_HUMAN_RESOURCES_MENU',
                'ROLE_HUMAN_RESOURCES_VIEW',
                'ROLE_HUMAN_RESOURCES',
            ]);
        },
        ///////////////////
        //menu
        canViewMenuCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_CODEBOOKS_EDIT', 'ROLE_HR_CODEBOOKS_VIEW'];
        },
        canViewMenuCodebooksWorkplace: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HR_CODEBOOKS_EDIT',
                'ROLE_HR_CODEBOOKS_VIEW',
                'ROLE_WORKPLACE_INIT',
            ];
        },
        canViewMenuSettingsAdmin: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_SETTINGS'];
        },
        canViewMenuSettingsAttendance: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_SETTINGS', 'ROLE_ATTENDANCE_ADMIN'];
        },
        canViewMenuSettings: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_SETTINGS'];
        },
        canViewMenuAttendanceCodebooks: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_ATTENDANCE_MENU'];
        },
        canViewMenuAttendanceCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_ATTENDANCE_VIEW'];
        },
        canViewMenuEmployeeLeave: () => {
            return ['ROLE_ADMIN', 'ROLE_EMPLOYEE_LEAVE', 'ROLE_HUMAN_RESOURCES'];
        },
        canViewMenuEmployeeCompanyLeave: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_EMPLOYEE_LEAVE',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_COMPANY_EDIT',
                'ROLE_AUTH_USER',
                'ROLE_CODEBOOKS_MENU',
            ];
        },
        canViewMenuEmployeeLeaveUser: () => {
            return ['ROLE_ADMIN', 'ROLE_EMPLOYEE_LEAVE'];
        },
        canViewEmployee: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HUMAN_RESOURCES_USER',
                'ROLE_HR_EMPLOYEE_VIEW',
                'ROLE_MOBILIZATION_EMPLOYEE_VIEW',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_ATTENDANCE_USER',
            ];
        },
        canViewEmployeeOnly: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HUMAN_RESOURCES_USER',
                'ROLE_HR_EMPLOYEE_VIEW',
                'ROLE_MOBILIZATION_EMPLOYEE_VIEW',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_ATTENDANCE_USER',
                'ROLE_AUTH_USER',
            ];
        },
        canViewEmployeeMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_CODEBOOKS_MENU', 'ROLE_MOBILIZATION_EMPLOYEE_VIEW'];
        },
        canViewEmployeeMenuAdmin: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_MOBILIZATION_EMPLOYEE_VIEW'];
        },
        canViewHumanResourcesUser: () => {
            return ['ROLE_HUMAN_RESOURCES_USER'];
        },
        canViewAttendanceMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ATTENDANCE_ADMIN', 'ROLE_ATTENDANCE_USER', 'ROLE_HUMAN_RESOURCES_USER'];
        },
        canViewMobilizationMenu: () => {
            return [
                'ROLE_ADMIN',
                // 'ROLE_HUMAN_RESOURCES',
                'ROLE_MOBILIZATION',
                'ROLE_MOBILIZATION_EXECUTION',
                'ROLE_MOBILIZATION_SETTINGS',
            ];
        },
        canViewHumanMyPortal: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HR_CODEBOOKS_MENU',
                'ROLE_CONTRACT_ADMIN',
                'ROLE_CONTRACT',
                'ROLE_CONTRACT_VIEW',
                'ROLE_EDUCATION_ADMIN',
                'ROLE_EVALUATION_ADMIN',
                'ROLE_WORKPLACE_INIT',
                'ROLE_MOBILIZATION',
            ];
        },
        canViewHumanCodebooksMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HR_CODEBOOKS_MENU',
                'ROLE_CONTRACT_ADMIN',
                'ROLE_CONTRACT',
                'ROLE_CONTRACT_VIEW',
                'ROLE_EDUCATION_ADMIN',
                'ROLE_EVALUATION_ADMIN',
                'ROLE_WORKPLACE_INIT',
                'ROLE_MOBILIZATION',
                'ROLE_HR_ATTENDANCE_MENU',
            ];
        },
        canViewCodeooksHumanMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_CODEBOOKS_MENU', 'ROLE_HUMAN_RESOURCES_USER'];
        },
        canViewCodeooksHumanMenuWorkplace: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HR_CODEBOOKS_MENU',
                'ROLE_HUMAN_RESOURCES_USER',
                'ROLE_WORKPLACE_INIT',
            ];
        },
        canViewAttendanceHumanMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_HR_CODEBOOKS_MENU',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_HUMAN_RESOURCES_USER',
            ];
        },
        canViewHRCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_CODEBOOKS_MENU'];
        },
        isAdminMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES'];
        },
        canViewAttendanceHRMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_ATTENDANCE_MENU'];
        },
        canViewHumanOverviewModule: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HR_CODEBOOKS_MENU',
                'ROLE_HR_EMPLOYEE_MENU',
                'ROLE_HR_EMPLOYEE_LEAVE_MENU',
                'ROLE_HR_SICK_LEAVE_MENU',
                'ROLE_HR_SETTINGS',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_EMPLOYEE_LEAVE',
                'ROLE_CONTRACT_ADMIN',
                'ROLE_CONTRACT',
                'ROLE_CONTRACT_VIEW',
                'ROLE_EDUCATION',
                'ROLE_EDUCATION_ADMIN',
                'ROLE_EVALUATION_ADMIN',
                'ROLE_EVALUATION',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_ATTENDANCE_USER',
                'ROLE_HR_ATTENDANCE_MENU',
                'ROLE_WORKPLACE_INIT',
                'ROLE_MOBILIZATION',
                'ROLE_AUTH_USER',
            ];
        },
        //routes
        canViewHumanModuleRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_EMPLOYEE_LEAVE',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_EDUCATION',
                'ROLE_EDUCATION_ADMIN',
                'ROLE_CONTRACT_ADMIN',
                'ROLE_CONTRACT',
                'ROLE_CONTRACT_VIEW',
                'ROLE_EVALUATION_ADMIN',
                'ROLE_EVALUATION',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_MOBILIZATION',
                'ROLE_MOBILIZATION_EXECUTION',
                'ROLE_MOBILIZATION_SETTINGS',
            ];
        },
        //routes
        canViewEmployeeRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HR_EMPLOYEE_VIEW',
                'ROLE_MOBILIZATION_EMPLOYEE_VIEW',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_ATTENDANCE_USER',
            ];
        },
        canViewEmployeeOnlyRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HR_EMPLOYEE_VIEW',
                'ROLE_MOBILIZATION_EMPLOYEE_VIEW',
                'ROLE_ATTENDANCE_ADMIN',
                'ROLE_ATTENDANCE_USER',
                'ROLE_AUTH_USER',
            ];
        },
        canViewHumanResRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_HR_EMPLOYEE_VIEW',
                'ROLE_HUMAN_RESOURCES_USER',
                'ROLE_MOBILIZATION_EMPLOYEE_VIEW',
            ];
        },
        canViewCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_CODEBOOKS_VIEW'];
        },
        canViewHRCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_HR_CODEBOOKS_VIEW'];
        },
        canViewCodebooksOnlyRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_CODEBOOKS_VIEW'];
        },
        canViewCodebooksEditRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_CODEBOOKS_EDIT', 'ROLE_HR_CODEBOOKS_VIEW'];
        },
        canViewAttendanceRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_HR_ATTENDANCE_VIEW'];
        },
        canViewHumanRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_EMPLOYEE_LEAVE',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_EDUCATION',
                'ROLE_EDUCATION_ADMIN',
                'ROLE_CONTRACT_ADMIN',
                'ROLE_CONTRACT',
                'ROLE_CONTRACT_VIEW',
                'ROLE_EVALUATION_ADMIN',
                'ROLE_EVALUATION',
                'ROLE_ATTENDANCE_ADMIN',
            ];
        },
        canSeePDFBusinessUnit: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_PDF_BUSINESS_UNIT']);
        },
        canGenerateBusinessUnitPortfolioGraph: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HR_GRAPH_BUSINESS_UNIT']);
        },
    };
})();
