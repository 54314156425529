import {
    CloseCircleTwoTone,
    DeleteTwoTone,
    DownloadOutlined,
    EditTwoTone,
    FilePdfOutlined,
    PlusCircleOutlined,
    SaveTwoTone,
    StopOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Divider, Form, Input, Row, Space, Table, Tooltip, Upload, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AssetSimpleIndex from '../../../../../components/asset-simple/assetSimple';
import NyHistoryModal from '../../../../../components/history';
import NyNoteModal from '../../../../../components/note-modal';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import { AssetRights } from '../../../../../rights/assetRights';
import { FixedAssetRights } from '../../../../../rights/fixedAssetRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    customAssetExtraRenderOption,
    customAssetRenderOption,
    customEmployeeRenderOption,
    errorNotification,
    fileDownload,
    getDateFormat,
    getEmployeeSelectOption,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import { manageFileUpload } from '../../../../../utils/ny-file-upload-custom/FilesFunctions';
import CompanySearch from '../../../../administration/views/company/search';
import { BusinessUnitSearch } from '../../../../human/views/business-unit/search';
import EmployeeIndex from '../../../../human/views/employee';

const AssetReturnEdit = (props: any) => {
    const enAssetReleaseSpecType = useEnum('ASSET_RELEASE_SPEC_TYPE');
    const enableAssetRental = useApplicationSetting('ENABLE_ASSET_RENTAL');
    const [editHeader, setEditHeader] = useState(geti18nText('assetReturn.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [editingKey, setEditingKey] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const [note, setNote] = useState<any>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [uploadFile, setUploadFile] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [employee, setEmployee] = useState(undefined);
    const [assetReturnFiles, setAssetReturnFiles] = useState<any>(undefined);
    const [refreshFiles, setRefreshFiles] = useState<any>(0);
    const [type, setType] = useState(enAssetReleaseSpecType.EMPLOYEE);
    const [businessUnit, setBusinessUnit] = useState(undefined);
    const enDocumentType = useEnum('DOCUMENT_TYPE');
    const [company, setCompany] = useState(undefined);
    const [addItemInProcess, setAddItemInProcess] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const { id } = useParams<any>();
    const isEditing = (record: any) => record.id === editingKey;

    useEffect(() => {
        if (props?.addedData?.type) {
            setType(props.addedData.type);
        }
    }, [props?.addedData?.type]);

    useEffect(() => {
        getAssetReturnFiles();
    }, [refreshFiles]);

    const isCompanyType = () => {
        return type && type == enAssetReleaseSpecType.COMPANY;
    };

    const canCreate = () => {
        return AssetRights.canCreateReturn() || (FixedAssetRights.canCreateAssetRent() && isCompanyType());
    };

    const resetItems = () => {
        setItems([]);
        setAddItemInProcess(false);
        setEditingKey('');
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('ord') && dataForm.hasOwnProperty('dateReturn')) {
            setEditHeader(
                geti18nText('assetReturn.edit.title') +
                    ' - ' +
                    dataForm.ord +
                    '/' +
                    moment(dataForm.dateReturn).format('YYYY')
            );
        } else {
            setEditHeader(geti18nText('assetReturn.edit.title'));
        }

        if (dataForm.assetReturnFiles !== undefined) {
            setAssetReturnFiles(dataForm.assetReturnFiles);
            let uploadedFile = dataForm.assetReturnFiles.find((file: any) => file.active);
            if (uploadedFile) {
                setUploadFile(uploadedFile.file.id);
            }
        }

        if (dataForm.file !== undefined) {
            setFile(dataForm.file?.id);
        }

        if (dataForm.employee) {
            setEmployee(dataForm.employee?.id);
            dataForm.employee = getEmployeeSelectOption(dataForm.employee);
            setType(enAssetReleaseSpecType.EMPLOYEE);
        } else if (dataForm.businessUnit) {
            setBusinessUnit(dataForm.businessUnit?.id);
            dataForm.businessUnit = setSearchFormat(dataForm.businessUnit, 'name', 'name');
            setType(enAssetReleaseSpecType.ORG_UNIT);
        } else if (dataForm.company) {
            setCompany(dataForm.company?.id);
            dataForm.company = setSearchFormat(dataForm.company, 'name', 'name');
            setType(enAssetReleaseSpecType.COMPANY);
        }

        setNote(dataForm.note ? dataForm.note : undefined);

        if (dataForm.assetReturnItem) {
            setItems(dataForm.assetReturnItem);
        }

        if (dataForm.dateReturn) {
            dataForm.dateReturn = setDateFormat(dataForm.dateReturn);
        }

        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        assetForm.resetFields();
        setItems([]);
        setEditingKey('');
        setFile(undefined);
        setUploadFile(undefined);
        setNote(undefined);
        setEmployee(undefined);
        setBusinessUnit(undefined);
        setCompany(undefined);
        setAssetReturnFiles(undefined);
        setEditHeader(geti18nText('assetReturn.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const cancel = (record: any) => {
        setEditingKey('');
        if (!(record.asset && record.asset.id !== undefined) || !(record.description !== undefined)) {
            deleteRow(record.id);
        }
        setAddItemInProcess(false);
    };

    const deleteRow = (id: any) => {
        if (id) {
            setItems(items.filter((row: any) => row.id !== id));
        }
    };

    const save = async (id: any) => {
        try {
            const row = await assetForm.validateFields();
            const newData = [...items];
            const index = newData.findIndex((item) => id === item.id);
            if (!row?.name || row.name.length < 1) {
                const assetName =
                    (row?.asset?.label?.props?.children && row?.asset?.label?.props?.children[0]?.props?.children) ??
                    '';
                const inventoryNumber =
                    row?.asset?.label?.props?.children &&
                    row.asset.label.props.children[2]?.props?.children &&
                    row.asset.label.props.children[2].props.children[1]?.props?.children;
                const name = customAssetRenderOption({
                    text: assetName,
                    serialNumber: inventoryNumber,
                });
                row.asset.name = name;
            }

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setItems(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setItems(newData);
                setEditingKey('');
            }
            setAddItemInProcess(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record.id)} disabled={!isCreate}>
                            <SaveTwoTone
                                style={{
                                    fontSize: '20px',
                                }}
                                twoToneColor="#52c41a"
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <NyModalConfirm
                            title={geti18nText('app.default.cancel.confirm')}
                            onConfirm={() => {
                                cancel(record);
                            }}
                        >
                            <Button type="link" disabled={!isCreate}>
                                <CloseCircleTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                    twoToneColor="#faad14"
                                />
                            </Button>
                        </NyModalConfirm>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                {isCreate && (
                    <Space size="middle" style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                            <Button type="link" disabled={editingKey !== '' || !isCreate} onClick={() => edit(record)}>
                                <EditTwoTone
                                    style={{
                                        fontSize: '20px',
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <NyModalConfirm
                                title={geti18nText('app.default.delete.confirm')}
                                onConfirm={() => {
                                    deleteRow(record.id);
                                }}
                            >
                                <Button type="link" disabled={!isCreate}>
                                    <DeleteTwoTone
                                        style={{
                                            fontSize: '20px',
                                        }}
                                        twoToneColor="#ff7875"
                                    />
                                </Button>
                            </NyModalConfirm>
                        </Tooltip>
                    </Space>
                )}
            </React.Fragment>
        );
    };

    const columns = [
        {
            title: geti18nText('assetReturn.table.column.asset'),
            dataIndex: 'asset',
            editable: true,
            inputType: 'asset',
            render: (text: any, record: any) => {
                if (record?.asset?.id) {
                    return record.asset.name
                        ? record.asset.name
                        : customAssetRenderOption({
                              text: record?.asset?.assetModel?.name,
                              serialNumber: record?.asset?.serialNumber,
                          });
                }
            },
        },
        {
            title: geti18nText('assetReturn.table.column.description'),
            dataIndex: 'description',
            width: '450px',
            editable: true,
        },
        ...(!dataForm
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '110px',
                      render: (text: any, record: any) => {
                          if (items.length >= 1) {
                              const editable = isEditing(record);
                              return editable ? (
                                  <ActionEditable text={text} record={record} />
                              ) : (
                                  <ActionNotEditable text={text} record={record} />
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    form: assetForm,
                };
            },
        };
    });

    const edit = (record: any) => {
        setAddItemInProcess(true);
        let editForm = undefined;
        editForm = { ...record };

        if (editForm?.asset?.id) {
            editForm.asset = {
                id: editForm?.asset?.id,
                name: record?.asset?.name
                    ? record.asset.name
                    : record?.asset?.assetModel?.name
                    ? record.asset.assetModel.name
                    : '',
                serialNumber: record?.asset?.serialNumber ? record.asset.serialNumber : '',
            };
        }

        assetForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const addNewRowAsset = (event: any) => {
        event.stopPropagation();
        setAddItemInProcess(true);
        let newId =
            items.length > 0
                ? items.reduce((max: any, element: any) => (element.id > max ? element.id : max), items[0].id) + 1
                : 1;
        const newData = {
            id: newId,
            asset: undefined,
            description: undefined,
        };
        setItems([...items, newData]);
        edit(newData);
    };

    const upload = {
        name: 'file',
        action: CONSTANTS_REQ.FILES.UPLOAD,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                if (info.file.response) {
                    const result = info.file.response;
                    info.file.id = result;
                    manageFileUpload(
                        info.file,
                        result,
                        dataForm,
                        dataForm,
                        enDocumentType.RETURN,
                        null,
                        CONSTANTS_REQ.FILES.MANAGE_UPLOAD,
                        CONSTANTS_REQ.FILES.DELETE_BY_IDS,
                        CONSTANTS_REQ.FILES.MANAGE_DELETE,
                        setRefreshFiles
                    );
                    setUploadFile(result);
                    getAssetReturnFiles();
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    const getAssetReturnFiles = () => {
        if (dataForm) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.ASSET_RETURN.FILES, {
                id: dataForm,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    setAssetReturnFiles(result.data);
                }
            });
        }
    };

    const cancelAssetReturn = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ASSET_RETURN.DEACTIVATE + '/' + dataForm, undefined, {
            id: dataForm,
        }).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                okNotification();
                props.setVisible(false);
                props.onSave();
                onModalClose();
            } else if (result.data && result.data.error) {
                errorNotification(result.data.error);
            } else if (result.data && result.data instanceof String) {
                errorNotification(result.data);
            } else {
                errorNotification();
            }
        });
    };

    const historyColumns = [
        {
            title: geti18nText('assetRelease.history.column.name'),
            dataIndex: ['file', 'name'],
        },
        {
            title: geti18nText('assetRelease.history.column.uploaded'),
            dataIndex: 'uploaded',
            render: (uploaded: any) => {
                if (uploaded) {
                    return getDateFormat(uploaded, 'DD.MM.YYYY HH:mm:ss');
                }
            },
        },
        {
            title: geti18nText('assetRelease.history.column.uploadedBy'),
            dataIndex: 'user',
            render: (user: any) => {
                if (user && user.person) {
                    return `${user.person.firstName} ${user.person.lastName}`;
                }
            },
        },
        {
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record.file && record.file.id && (
                                <Tooltip title={geti18nText('assetReturn.button.download')}>
                                    <FilePdfOutlined
                                        style={{ marginLeft: '10px', fontSize: '16px' }}
                                        onClick={() => {
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.file.id,
                                                null,
                                                record.file.name
                                            );
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const downloadUploadedFile = () => {
        if (assetReturnFiles && assetReturnFiles.length > 0 && uploadFile != null && uploadFile != undefined) {
            const lastUploadedFile = assetReturnFiles.at(-1);
            if (lastUploadedFile && lastUploadedFile.file && lastUploadedFile.file.id && lastUploadedFile.file.name) {
                fileDownload(
                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + lastUploadedFile.file.id,
                    null,
                    lastUploadedFile.file.name
                );
            }
        } else {
            fileDownload(
                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file,
                null,
                geti18nText('app.default.assetReturnPrint', [moment().format('yyyyMMDD')])
            );
        }
    };

    const getCustomFooterContent = (
        <React.Fragment>
            <div className="ny-modal-footer-content" style={{ marginTop: '2px' }}>
                <NyNoteModal note={note} />
            </div>
            <div style={{ float: 'left', marginLeft: '45px' }}>
                {file !== undefined && (
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => {
                            fileDownload(
                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + file,
                                null,
                                geti18nText('app.default.assetReturnPrint', [moment().format('yyyyMMDD')])
                            );
                        }}
                    >
                        {geti18nText('assetReturn.button.downloadNew')}
                    </Button>
                )}
                {uploadFile !== undefined && (
                    <Button icon={<DownloadOutlined />} onClick={downloadUploadedFile}>
                        {geti18nText('assetReturn.button.download')}
                    </Button>
                )}
                {assetReturnFiles && (
                    <NyHistoryModal
                        columns={historyColumns}
                        dataSource={assetReturnFiles}
                        customColumns={true}
                        buttonStyle={{ marginLeft: '0px', top: '-1px' }}
                    />
                )}
                {!isCreate && canCreate() && (
                    <Upload {...upload} data={{ documentType: 2, id: dataForm }}>
                        <Button icon={<UploadOutlined />} disabled={loading} style={{ marginLeft: '5px' }}>
                            {geti18nText('assetReturn.button.upload')}
                        </Button>
                    </Upload>
                )}
            </div>
            {!isCreate && canCreate() && (
                <div style={{ float: 'right' }}>
                    <NyModalConfirm
                        title={geti18nText('assetReturn.popconfirm.cancel')}
                        onConfirm={() => {
                            cancelAssetReturn();
                        }}
                    >
                        <Button icon={<StopOutlined />} type="primary" danger>
                            {geti18nText('assetReturn.button.cancel')}
                        </Button>
                    </NyModalConfirm>
                </div>
            )}
        </React.Fragment>
    );

    const onEmployeeChange = (value: any) => {
        if (value.id !== -1) {
            setEmployee(value.id);
        } else {
            setEmployee(undefined);
        }
        resetItems();
    };

    const onBusinessUnitChange = (value: any) => {
        if (value?.id !== -1 && value !== null) {
            setBusinessUnit(value?.id);
        } else {
            setBusinessUnit(undefined);
        }
        resetItems();
    };

    const onCompanyChange = (value: any) => {
        if (value?.id !== -1 && value !== null) {
            setCompany(value?.id);
        } else {
            setCompany(undefined);
        }
        resetItems();
    };
    const assetFilter = [
        { field: 'active', condition: 'equals_bool', value: 1 },
        { field: 'availabilityStatus', condition: 'equals', value: 2 },
        {
            field:
                type == enAssetReleaseSpecType.EMPLOYEE
                    ? 'employeeId'
                    : type == enAssetReleaseSpecType.ORG_UNIT
                    ? 'businessUnitId'
                    : type == enAssetReleaseSpecType.COMPANY
                    ? 'companyId'
                    : '',
            condition: 'equals',
            value:
                type === enAssetReleaseSpecType.EMPLOYEE
                    ? employee
                    : type == enAssetReleaseSpecType.ORG_UNIT
                    ? businessUnit
                    : type == enAssetReleaseSpecType.COMPANY
                    ? company
                    : null,
        },
    ];

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }: any) => {
        const inputNode =
            inputType === 'asset' ? (
                <NySearchField
                    style={{ width: '100%' }}
                    url={CONSTANTS_REQ.ASSET.SEARCH}
                    map={{
                        id: 'id',
                        label: 'label',
                        text: 'text',
                        name: 'name',
                        serialNumber: 'serialNumber',
                        inventoryNumber: 'inventoryNumber',
                        assetModel: 'assetModel',
                        assetType: 'assetType',
                        item: 'item',
                        measureUnit: 'measureUnit',
                        quantity: 'quantity',
                    }}
                    searchBy="assetModel.name || serialNumber || inventoryNumber"
                    renderOption={customAssetExtraRenderOption}
                    addedFilter={assetFilter}
                    SearchPopupComponent={<AssetSimpleIndex defaultFilterValue={assetFilter} disabled />}
                    order="assetModel.name"
                    customListWidth={'1200px'}
                    mustGetPopupContainer={false}
                />
            ) : (
                <Input />
            );

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required:
                                    dataIndex == 'asset' ||
                                    (dataIndex == 'description' &&
                                        Object.values([
                                            enAssetReleaseSpecType.EMPLOYEE,
                                            enAssetReleaseSpecType.ORG_UNIT,
                                        ]).includes(type)),
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.ASSET_RETURN.EDIT}
            setValues={setValues}
            width={1200}
            hideActivationButtons={!isCreate || !canCreate()}
            hideSubmitButton={
                !isCreate ||
                !(items.length > 0) ||
                editingKey !== '' ||
                !canCreate() ||
                (enableAssetRental != '1' && isCompanyType())
            }
            form={form}
            setIsCreate={setIsCreate}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={false}
            customFooterContent={getCustomFooterContent}
            normalize={(values: any) => {
                if (values.dateReturn) values.dateReturn = getDateFormat(values.dateReturn);

                if (enAssetReleaseSpecType.EMPLOYEE && values.employee) {
                    values.employee = getSearchFormat(values.employee);
                    delete values.businessUnit;
                } else if (enAssetReleaseSpecType.ORG_UNIT && values.businessUnit) {
                    values.businessUnit = getSearchFormat(values.businessUnit);
                    delete values.employee;
                } else if (enAssetReleaseSpecType.COMPANY && values.company) {
                    values.company = getSearchFormat(values.company);
                }

                const itemsAsset: any = [];
                if (items !== undefined) {
                    items.map((item: any) => {
                        let obj: any = {};
                        if (item.asset) {
                            obj.asset = { id: item.asset.id };
                        }

                        if (item.description) {
                            obj.description = item.description;
                        }

                        itemsAsset.push(obj);
                    });
                }

                values.assetReturnItem = itemsAsset;

                return values;
            }}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('assetReturn.edit.dateReturn')}
                        name="dateReturn"
                        initialValue={moment()}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker
                            disabled={!isCreate}
                            ref={focusInput}
                            style={{ width: '100%' }}
                            format={isCompanyType() ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'}
                            mustGetPopupContainer={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {type === enAssetReleaseSpecType.EMPLOYEE ? (
                        <Form.Item
                            label={geti18nText('assetReturn.edit.employee')}
                            name="employee"
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                map={{
                                    id: 'id',
                                    label: 'text',
                                    employmentRecordId: 'employmentRecordId',
                                    businessUnit: 'businessUnit',
                                }}
                                searchBy="person.first_name || ' ' || person.last_name || ' ' || employment_record_id "
                                itemName={[
                                    ['person', 'firstName'],
                                    ['person', 'lastName'],
                                ]}
                                renderOption={customEmployeeRenderOption}
                                customItemNameLabel={'firstName lastName'}
                                SearchPopupComponent={<EmployeeIndex disabled={true} parentKey="asset-return-edit" />}
                                disabled={!isCreate}
                                onChange={onEmployeeChange}
                                order="person.last_name, person.first_name"
                                mustGetPopupContainer={false}
                                setDefaultFilterValue={() => [{ field: 'active', condition: 'equals_bool', value: 1 }]}
                            />
                        </Form.Item>
                    ) : type === enAssetReleaseSpecType.ORG_UNIT ? (
                        <BusinessUnitSearch
                            formItemName={'businessUnit'}
                            required={true}
                            onChange={onBusinessUnitChange}
                            disabled={!isCreate}
                            // scroll={{ y: 340, x: 800 }}
                        />
                    ) : type === enAssetReleaseSpecType.COMPANY ? (
                        <CompanySearch
                            label={geti18nText('assetRelease.rental.edit.company')}
                            disabled={!isCreate}
                            isModal={true}
                            required={true}
                            onChange={onCompanyChange}
                            mustGetPopupContainer={false}
                        />
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
            {((type === enAssetReleaseSpecType.EMPLOYEE && employee !== undefined) ||
                (type === enAssetReleaseSpecType.ORG_UNIT && businessUnit !== undefined) ||
                (type === enAssetReleaseSpecType.COMPANY && company !== undefined)) && (
                <React.Fragment>
                    <Divider>{geti18nText('assetReturn.divider.1')}</Divider>
                    {isCreate && (
                        <Row gutter={24} style={{ height: '36px' }}>
                            <Col span={24}>
                                <Button
                                    style={{ float: 'right', marginRight: '12px' }}
                                    icon={<PlusCircleOutlined />}
                                    onClick={addNewRowAsset}
                                    disabled={addItemInProcess}
                                >
                                    {geti18nText('assetReturn.button.addAsset')}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form form={assetForm} component={false}>
                                <Table
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    size={'small'}
                                    dataSource={items}
                                    columns={mergedColumns}
                                    rowClassName="editable-row"
                                    pagination={false}
                                    style={{ maxHeight: '400px', overflowY: 'auto' }}
                                />
                            </Form>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </NyDataEdit>
    );
};

export default AssetReturnEdit;
