import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Badge, Card, Empty } from 'antd';
import { Axis, Chart, Coordinate, Interaction, Interval, Legend, Tooltip } from 'bizcharts';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const PieChartGenderCard = ({ width = '527px', height = 397, myPortal = false }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const [dataHRGender, setDataHRGender] = useState<any>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const canViewCharts = () => {
        return HumanResourcesRights.canViewCharts();
    };

    const getData = () => {
        if (canViewCharts()) {
            setLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.HUMAN_RESOURCES_DATA.GENDER).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    setDataHRGender(result.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setError(true);
                }
            });
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const PieChartGender = ({ dataHRGender }: any) => {
        const data = dataHRGender?.map((obj: any) => {
            const key = Object.keys(obj)[0];
            const value = obj[key];
            const totalCount = dataHRGender.reduce((acc: number, curr: any) => acc + curr[Object.keys(curr)[0]], 0);
            const percentage = (value / totalCount) * 100;

            return {
                item:
                    key == 'FEMALE: '
                        ? geti18nText('businessUnit.graph.sex.female')
                        : geti18nText('businessUnit.graph.sex.male'),
                count: value,
                percent: percentage.toFixed(2),
            };
        });

        const cols = {
            count: {
                formatter: (val: any) => {
                    return val;
                },
            },
        };

        const options = {
            stroke: 'yellow',
        };

        const colors = ['#096dd9', '#cf1322'];

        return error ? (
            <Empty />
        ) : loading ? (
            <NySpinner />
        ) : (
            <Chart
                height={height}
                data={data}
                scale={cols}
                autoFit
                onIntervalClick={(e: any) => {
                    const states = e.target.cfg.element.getStates();
                }}
                onGetG2Instance={(c: any) => {}}
                padding={[0, 0, 0, 0]}
            >
                <Coordinate type="theta" radius={0.75} />
                <Tooltip showTitle={false} />
                <Axis visible={false} />

                <Interval
                    animate={false}
                    position="count"
                    adjust="stack"
                    color={['item', colors]}
                    label={[
                        'count',
                        {
                            style: {
                                fill: isDarkTheme ? 'white' : 'black',
                            },
                            layout: { type: 'pie-spider', cfg: { action: 'ellipsis' } },
                            content: (data) => {
                                return `${data.item}: ${data.count}`;
                            },
                        },
                    ]}
                />

                <Legend
                    itemName={{
                        style: {
                            fill: isDarkTheme ? 'white' : 'black',
                        },
                    }}
                    itemStates={{
                        active: {
                            nameStyle: {
                                opacity: 0.8,
                            },
                        },
                        unchecked: {
                            nameStyle: {
                                fill: 'gray',
                            },
                            markerStyle: {
                                fill: 'gray',
                            },
                        },
                    }}
                />
                <Interaction type="element-single-selected" />
                <Tooltip style={{ padding: '16px' }}>
                    {(title: any, items: any) => {
                        const color = items[0].color;
                        return (
                            <div style={{ padding: '8px' }}>
                                {' '}
                                <Badge
                                    dot={true}
                                    style={{
                                        marginTop: '6px',
                                        right: '14px',
                                        background: color,
                                    }}
                                ></Badge>
                                {`${items.at(0)?.data?.item}: ${items.at(0)?.data?.percent}% `}
                            </div>
                        );
                    }}
                </Tooltip>
            </Chart>
        );
    };

    return (
        <div
            style={
                myPortal
                    ? {
                          width: width,
                          paddingLeft: '12px',
                          paddingRight: '12px',
                          marginTop: '8px',
                          marginBottom: '12px',
                      }
                    : {}
            }
        >
            <Card
                className="ny-graph-card"
                title={geti18nText('businessUnit.graph.sex')}
                bodyStyle={{
                    height: 'calc(100% - 35px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '4px 5px 0px 5px',
                    marginTop: '1px',
                }}
                type="inner"
            >
                {dataHRGender?.length > 0 && <PieChartGender dataHRGender={dataHRGender} />}
            </Card>
        </div>
    );
};

export default PieChartGenderCard;
