import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Badge, Card, Empty } from 'antd';
import { Axis, Chart, Interval, Legend, Tooltip } from 'bizcharts';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';

const ChartAgeRange = ({ width = '527px', height = 372, myPortal = false }: any) => {
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const [dataHRAgeStat, setDataHRAgeStat] = useState<any>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const canViewCharts = () => {
        return HumanResourcesRights.canViewCharts();
    };

    const getData = () => {
        if (canViewCharts()) {
            setLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.HUMAN_RESOURCES_DATA.AGE).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    console.log('result', result);
                    setDataHRAgeStat(result.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setError(true);
                }
            });
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const ColumnChartAgeRange = ({ dataHRAgeStat }: any) => {
        const colors = [
            'rgb(228, 26, 28)',
            'rgb(55, 126, 184)',
            'rgb(77, 175, 74)',
            'rgb(152, 78, 163)',
            'rgb(255, 127, 0)',
            'rgb(255, 255, 51)',
        ];

        const ranges = [
            { label: '18-24', min: 18, max: 24 },
            { label: '25-34', min: 25, max: 34 },
            { label: '35-44', min: 35, max: 44 },
            { label: '45-54', min: 45, max: 54 },
            { label: '55-64', min: 55, max: 64 },
            { label: '65+', min: 65, max: Number.MAX_SAFE_INTEGER },
        ];

        const result: any = ranges.map((range) => {
            let accumulator = 0;

            dataHRAgeStat?.forEach((item: any, index: any) => {
                const value: any = Object.values(item)[0];
                const age = parseInt(Object.keys(item)[0], 10);

                if (age >= range.min && age <= range.max) {
                    accumulator += value;
                }
            });
            return [range.label, accumulator];
        });

        const data = result.map((obj: any, index: number) => {
            const totalCount = result.reduce((acc: number, curr: any) => acc + curr[Object.keys(curr)[1]], 0);
            const percentage = (obj.at(1) / totalCount) * 100;
            return {
                item: obj.at(0),
                item2: obj.at(0),
                number: obj.at(1),
                color: colors[index],
                percent: percentage.toFixed(2),
            };
        });

        const axisConfig = {
            label: {
                autoHide: false,
                style: {
                    textAlign: 'center' as const,
                    fill: isDarkTheme ? '#ccc' : 'black',
                },
                formatter: (text: string) => truncateLabel(text, 13),
            },
            line: {
                style: {
                    stroke: '#ccc',
                    lineDash: [3, 3],
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: '#ccc',
                        lineDash: [3, 3],
                    },
                },
            },
        };

        const axisTitleConfig = {
            title: {
                text: geti18nText('businessUnit.graph.age.hover'),
                style: {
                    fontSize: 12,
                    textAlign: 'center' as const,
                    fill: isDarkTheme ? '#ccc' : 'black',
                },
                position: 'center',
            },
        };

        const truncateLabel = (text: string, maxLength: number) => {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        };

        return error ? (
            <Empty />
        ) : loading ? (
            <NySpinner />
        ) : (
            <Chart height={height} autoFit data={data} padding={[2, 10, 60, 60]}>
                <Interval
                    position="item*number"
                    style={{ lineWidth: 0 }}
                    color={['item2', data?.map((item: any) => item.color)]}
                />
                <Axis name="item" {...axisConfig} />
                <Axis name="number" {...axisTitleConfig} />

                <Tooltip style={{ padding: '16px' }}>
                    {(title: any, items: any) => {
                        const color = items[0].color;
                        return (
                            <div style={{ padding: '8px' }}>
                                {' '}
                                <Badge
                                    dot={true}
                                    style={{
                                        marginTop: '6px',
                                        right: '14px',

                                        background: color,
                                    }}
                                ></Badge>
                                {`${geti18nText('businessUnit.graph.age.hover')}: ${items.at(0)?.data?.number} - ${
                                    items.at(0)?.data?.percent
                                }% `}
                            </div>
                        );
                    }}
                </Tooltip>
                <Legend
                    itemName={{
                        style: {
                            fill: isDarkTheme ? 'white' : 'black',
                        },
                    }}
                    itemStates={{
                        active: {
                            nameStyle: {
                                opacity: 0.8,
                            },
                        },
                        unchecked: {
                            nameStyle: {
                                fill: 'gray',
                            },
                            markerStyle: {
                                fill: 'gray',
                            },
                        },
                    }}
                />
            </Chart>
        );
    };

    return (
        <div
            style={
                myPortal
                    ? {
                          width: width,
                          paddingLeft: '12px',
                          paddingRight: '12px',
                          marginTop: '8px',
                          height: '100%',
                      }
                    : {}
            }
        >
            <Card
                className="ny-graph-card"
                title={geti18nText('businessUnit.graph.age')}
                bodyStyle={{
                    height: 'calc(100% - 35px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '4px 5px 0px 5px',
                    marginTop: '26px',
                }}
                type="inner"
            >
                <ColumnChartAgeRange dataHRAgeStat={dataHRAgeStat} />
            </Card>
        </div>
    );
};

export default ChartAgeRange;
