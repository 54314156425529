import {
    CaretLeftOutlined,
    CaretRightOutlined,
    CheckCircleOutlined,
    DeleteTwoTone,
    EllipsisOutlined,
    FilePdfOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    NyDatePicker,
    NyModalConfirm,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import {
    Alert,
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    Descriptions,
    Divider,
    Dropdown,
    Empty,
    Form,
    Image,
    Input,
    List,
    Menu,
    Modal,
    Popover,
    Result,
    Row,
    Statistic,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import _, { round } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, FILE_FALLBACK } from '../../../../../utils/Constants';
import {
    GetMealOrderStatusColor,
    GetMealOrderStatusColorDark,
    checkIsHoliday,
    checkIsWeekend,
    customDeliverySpotTimeRenderOption,
    customEmployeeRenderOption,
    errorNotification,
    fileDownload,
    getBillingStatusIcon,
    getDateFormat,
    getDeliverySpotTimeSelectOption,
    getEmployeeSelectOption,
    getErrorNotificationMessage,
    getMealAdvancePrice,
    getMealSubventionAmount,
    getMealSubventionPrice,
    getNextWorkday,
    getSearchFormat,
    okNotification,
    setDateFormat,
    warningNotification,
} from '../../../../../utils/Utils';
import EmployeeIndex from '../../../../human/views/employee';
import { MealsRights } from '../../../../../rights/mealsRights';
import NyImageUpload from '../../../../../utils/ny-file-upload-image';

const { Meta } = Card;

const MealOrderWizard = ({
    visible,
    setVisible,
    date,
    refresh,
    setRefresh,
    value,
    setValue,
    canCancelOrder,
    allowOrdersToday = null,
    mealOrderEntryTime = null,
    mealOrderCancelTime = null,
    isAdminForm = true,
    theme,
    showGenerateBills = false,
}: any) => {
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);
    const { employee } = useSelector((state: RootState) => state.employee);
    const mealOrderStatus = useEnum('MEAL_ORDER_STATUS');
    const enBillingStatus: any = useEnum('BILLING_STATUS');
    const mealSubventionValue: any = useApplicationSetting('MEALS_SUBVENTION');
    const deliverCutlery: any = useApplicationSetting('MEALS_DELIVER_CUTLERY');

    const [form] = Form.useForm();
    const [assetForm] = Form.useForm();
    const [menuListGrouped, setMenuListGrouped] = useState<any>([]);
    const [selectedDeliverySpot, setSelectedDeliverySpot] = useState<any>(null);
    const [selectedDeliverySpotConfirm, setSelectedDeliverySpotConfirm] = useState<any>(null);
    const [selectedDeliverySpotValue, setSelectedDeliverySpotValue] = useState<any>(null);
    const [selectedDeliverySpotConfirmValue, setSelectedDeliverySpotConfirmValue] = useState<any>(null);
    const [wizardStep, setWizardStep] = useState<number>(1);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<any>([]);
    const [itemsLength, setItemsLength] = useState(0);
    const [nextWorkDay, setNextWorkDay] = useState<any>(undefined);
    const [orderDate, setOrderDate] = useState<any>(undefined);
    const [nextOrderDate, setNextOrderDate] = useState<any>(undefined);
    const [status, setStatus] = useState<any>(mealOrderStatus.UNCONFIRMED);
    const [dataForm, setDataForm] = useState(undefined);
    const [ordersList, setOrdersList] = useState([]);
    const [holidays, setHolidays] = useState([]);
    //subvention
    const [userAdvance, setUserAdvance] = useState<any>(0);
    const [startAdvance, setStartAdvance] = useState<any>(0);
    const [userSubvention, setUserSubvention] = useState<any>(0);
    const [totalSubvention, setTotalSubvention] = useState<any>(0);
    const [totalAdvance, setTotalAdvance] = useState<any>(0);
    const [totalSubventionInit, setTotalSubventionInit] = useState<any>(0);
    const [totalAdvanceInit, setTotalAdvanceInit] = useState<any>(0);
    const [totalAdvanceOrder, setTotalAdvanceOrder] = useState<any>(0);
    const [totalSubventionOrder, setTotalSubventionOrder] = useState<any>(0);

    const [totalSubventionConfirmed, setTotalSubventionConfirmed] = useState<any>(0);
    const [totalAdvanceConfirmed, setTotalAdvanceConfirmed] = useState<any>(0);
    const [showInitValues, setShowInitValues] = useState<boolean>(false);
    const [currency, setCurrency] = useState<any>('');
    const [deliveryType, setDeliveryType] = useState<any>(undefined);
    const [deliveryTypeConfirm, setDeliveryTypeConfirm] = useState<any>(undefined);
    const [subventionIsNotNull, setSubventionIsNotNull] = useState<boolean>(false);
    const [cutleryModalVisible, setCutleryModalVisible] = useState<any>(false);
    const [cutleryNeeded, setCutleryNeeded] = useState<any>(false);

    const [item, setItem] = useState<any>(undefined);
    const [modalItemVisible, setModalItemVisible] = useState<boolean>(false);
    const [deliverySpotModalVisible, setDeliverySpotModalVisible] = useState<boolean>(false);
    const [isDisabledForm, setIsDisabledForm] = useState<boolean>(false);
    const [subventionOverflowConfirmed, setSubventionOverflowConfirmed] = useState<boolean>(false);

    const [activeEmployee, setActiveEmployee] = useState(employee);

    const [leftCollapseActiveKeys, setLeftCollapseActiveKeys] = useState<any>(['0']);
    const { Panel } = Collapse;
    const { Text } = Typography;
    const [data, setData] = useState<any>(null);
    const [nextMealDays, setNextMealDays] = useState<any>();
    const [isReopenedModal, setIsReopenedModal] = useState<boolean>(false);
    const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false);
    const [loadingConfirmButton, setLoadingConfirmButton] = useState<boolean>(false);
    const isDarkTheme = theme != 'dark' ? false : true;

    const [formBill] = Form.useForm();
    const [uploadBillFiles, setUploadBillFiles] = useState<any>([]);
    const [uploadBillModalVisible, setUploadBillModalVisible] = useState<boolean>(false);

    const isConfirmedOrder = () => {
        return value && value.id && status == mealOrderStatus.CONFIRMED;
    };

    const isEdit = () => {
        return value && value.id > 0;
    };

    const canCreate = () => {
        return MealsRights.isMealsUser();
    };

    const canCreateAdmin = () => {
        return MealsRights.isMealsAdmin();
    };

    const disabledForm = () => {
        return itemsLength == 0 || !items.length || (items && items.length == 0) || isDisabledForm;
    };

    const canEntryOrConfirm = (date: any) => {
        let todayMinutes = moment().format('HH:mm');
        return mealOrderEntryTime > todayMinutes && allowOrdersToday == true;
    };

    const canCancelTodayOrder = () => {
        let todayMinutes = moment().format('HH:mm');
        return !(mealOrderCancelTime < todayMinutes);
    };

    const checkIsToday = (date: any) => {
        let dateValueDate = moment(date).format('DD.MM.YYYY');
        let todayDate = moment().format('DD.MM.YYYY');
        return dateValueDate == todayDate;
    };

    const checkIsNextWorkday = (date: any) => {
        const dateValueDate = moment(date).format('DD.MM.YYYY');
        return dateValueDate == nextWorkDay;
    };

    const isDisabledOrderEdit = () => {
        if (canCreateAdmin() || canCreate()) {
            let todayMinutes = moment().format('HH:mm');
            if (orderDate && dataForm) {
                if (!checkIsHoliday(orderDate, holidays) && !checkIsWeekend(moment(orderDate))) {
                    if (checkIsToday(orderDate)) {
                        if (canCreateAdmin()) {
                            setIsDisabledForm(!canCancelTodayOrder());
                        } else if (canCreate()) {
                            setIsDisabledForm(!canEntryOrConfirm(orderDate));
                        } else {
                            setIsDisabledForm(true);
                        }
                    } else if (checkIsNextWorkday(orderDate)) {
                        setIsDisabledForm(
                            !(
                                (mealOrderEntryTime > todayMinutes && allowOrdersToday == false) ||
                                allowOrdersToday == true ||
                                canCreateAdmin()
                            )
                        );
                    } else {
                        const now = moment().format('DD.MM.YYYY');
                        const orderDateValue = moment(orderDate).format('DD.MM.YYYY');
                        setIsDisabledForm(moment(orderDateValue, 'DD.MM.YYYY').isBefore(moment(now, 'DD.MM.YYYY')));
                    }
                } else {
                    setIsDisabledForm(true);
                }
            } else {
                setIsDisabledForm(false);
            }
        } else {
            setIsDisabledForm(true);
        }
    };

    useEffect(() => {
        if (value) {
            getValues(value);
        }
    }, [value]);

    useEffect(() => {
        if (date != undefined && value == undefined) {
            setOrderDate(getDateFormat(date, 'yyyy-MM-DD'));
        }
    }, [date]);

    useEffect(() => {
        if (orderDate != undefined && orderDate != 'Invalid date') {
            getBalance();
            isDisabledOrderEdit();
        }
    }, [orderDate]);

    useEffect(() => {
        if (isDisabledForm) {
            setWizardStep(2);
        }
    }, [isDisabledForm]);

    useEffect(() => {
        if (userSubvention - totalSubvention < 0 && mealSyncTypeHNB) {
            setSubventionOverflowConfirmed(true);
        }
    }, [userSubvention]);

    useEffect(() => {
        if (userSubvention - totalSubvention >= 0) {
            setSubventionIsNotNull(true);
        } else {
            setSubventionIsNotNull(false);
        }
    }, [userSubvention, totalSubvention]);

    useEffect(() => {
        if (!value) {
            getLastDeliverySpot();
        }
        fetchHolidays();
        fetchNextWorkday();
        getAppCurrency();
        if (isEdit()) {
            getNextFiveDayOrder();
        }
    }, []);

    useEffect(() => {
        if (selectedDeliverySpot != undefined) {
            getDeliverySpotMenuData(selectedDeliverySpot);
        }
    }, [selectedDeliverySpot]);

    const defaultActiveFilter = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getBalance = () => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.MEAL_ORDER.BALANCE + '/' + getDateFormat(orderDate, 'YYYY-MM-DD'),
            undefined
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                setUserAdvance(result.data.stanje_predujma);
                setStartAdvance(result.data.stanje_predujma);
                setUserSubvention(result.data.stanje_subvencije);
            }
        });
    };

    const setAppSettingsCurrencyFilterValue = () => {
        return [
            { field: 'settingModule', condition: 'equals', value: 1 },
            { field: 'settingGroup', condition: 'equals', value: 'COMPANY_INFO' },
        ];
    };

    const getAppCurrency = () => {
        const stringUri = encodeURI(JSON.stringify(setAppSettingsCurrencyFilterValue()));
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST_ALL, { search: stringUri }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        result.data.forEach((element: any) => {
                            if (element.settingKey === 'DEFAULT_CURRENCY_ISO_CODE') {
                                setCurrency(
                                    element.value ? element.value : element.defaultValue ? element.defaultValue : ''
                                );
                            }
                        });
                    }
                }
            }
        );
    };

    const getValues = (value: any) => {
        if (value && value.id > 0) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.EDIT + '/' + value.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        const orderData = result.data;
                        let formData: any = {};
                        formData.id = orderData.id;
                        setDataForm(orderData.id);

                        if (canCancelOrder && !canCancelOrder(getDateFormat(orderData.orderDate, 'DD.MM.YYYY'))) {
                            setWizardStep(2);
                        }
                        setDeliveryType(result?.data?.deliverySpotTime?.type);
                        formData.orderDate = setDateFormat(orderData.orderDate);
                        setOrderDate(getDateFormat(orderData.orderDate, 'yyyy-MM-DD'));
                        setStatus(orderData.status);
                        setCutleryNeeded(orderData.deliverCutlery);
                        formData.deliverySpot = getDeliverySpotTimeSelectOption(orderData.deliverySpotTime);
                        setSelectedDeliverySpotValue(formData.deliverySpot);

                        formData.employee = getEmployeeSelectOption(orderData.employee);
                        setActiveEmployee(orderData.employee);
                        formData.created = setDateFormat(moment(orderData.created));
                        form.setFieldsValue(formData);
                        setDeliveryType(orderData?.deliverySpotTime?.type);
                        setSelectedDeliverySpot(orderData?.deliverySpotTime?.delivery?.id);
                        let itemsBasketCopy: any = [];
                        orderData?.orderItem?.map((item: any) => {
                            itemsBasketCopy.push({
                                ...item,
                                quantity: item.quantity,
                                amount: item.amount,
                                price: item.price,
                                currency: {
                                    id: item.currency && item.currency.id,
                                    isoCode: item.currency && item.currency.isoCode,
                                },
                            });

                            setItemsLength(result.data.totalPrice ?? 0);
                        });
                        setItems(itemsBasketCopy);
                        if (mealSyncTypeHNB) {
                            if (orderData.status == mealOrderStatus.CONFIRMED) {
                                setTotalSubventionConfirmed(orderData.totalSubvention);
                                setTotalAdvanceConfirmed(orderData.totalAdvance);
                            }
                            setTotalSubventionOrder(orderData.totalSubvention);
                            setTotalAdvanceOrder(orderData.totalAdvance);
                        }

                        if (orderData.billFileId) {
                            NyRequestResolver.requestGet(
                                CONSTANTS_REQ.FILES.INFO + '/' + orderData.billFileId,
                                undefined,
                                undefined
                            ).then((result: any) => {
                                if (result && result.status == RESPONSE.OK && result.data) {
                                    let filesImage = [
                                        {
                                            id: orderData.billFileId,
                                            uid: orderData.billFileId,
                                            name: result.data.name,
                                            url:
                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                '/' +
                                                orderData.billFileId +
                                                '?lastmod=' +
                                                new Date().valueOf(),
                                        },
                                    ];
                                    setUploadBillFiles(filesImage);
                                }
                            });
                        }
                        if (orderData.billNumber) {
                            formBill.setFieldValue('billNumber', orderData.billNumber);
                        }

                        setData(orderData);
                        form.setFieldsValue({
                            employee: orderData.employee,
                        });
                        form.setFieldsValue({
                            created: formData.created,
                        });
                    } else {
                        getErrorNotificationMessage(result);
                        return (
                            <Result
                                status="error"
                                title={geti18nText('app.default.user.has.no.right.to.approve')}
                            ></Result>
                        );
                    }
                }
            );
        }
    };

    const fetchNextWorkday = () => {
        let search = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_id', condition: 'equals', value: employee && employee.id },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.LIST, {
            search: search,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content && result.data.content.length > 0) {
                let usedDates: any = [];
                usedDates = result.data.content.map((item: any) => {
                    return {
                        date: moment(item.orderDate).format('DD.MM.YYYY'),
                        name: item.orderDate,
                        active: item.active,
                    };
                });

                setOrdersList(usedDates);
            }
        });
    };

    const nextOrder = () => {
        setIsReopenedModal(true);

        setMenuListGrouped(undefined);
        setWizardStep(1);
        getLastDeliverySpot();
        setDataForm(undefined);
        setStatus(mealOrderStatus.UNCONFIRMED);
        setTotalAdvance(0);
        setTotalSubvention(0);
        setUserAdvance(0);
        setStartAdvance(0);
        setUserSubvention(0);
        setItems([]);
        setItemsLength(0);
        setOrderDate(nextOrderDate);
        form.setFieldsValue({ orderDate: nextOrderDate });
        getDeliverySpotMenuData(selectedDeliverySpot);
        setNextOrderDate(undefined);
        if (setValue) {
            setValue(undefined);
        }
        setCutleryNeeded(false);
    };

    const getNextAvailableWorkDay = () => {
        let momentNext = moment(form.getFieldValue('orderDate')).add(1, 'days');

        while (momentNext) {
            if (
                !checkIsHoliday(momentNext, [...holidays, ...ordersList]) &&
                !checkIsWeekend(momentNext) &&
                !checkNextDaysDates(momentNext)
            ) {
                setNextOrderDate(momentNext);
                break;
            }
            momentNext = momentNext.add(1, 'days');
        }
    };

    const getNextFiveDayOrder = () => {
        const resolvedData: any = [];

        let search = encodeURI(
            JSON.stringify([
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'employee_id', condition: 'equals', value: employee && employee.id },
                {
                    field: 'orderDate',
                    condition: 'date_range',
                    value: searchValue(),
                },
            ])
        );
        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.LIST, {
            search: search,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.content && result.data.content.length > 0) {
                result.data.content.map(async (element: any) => {
                    element.start = moment(element.orderDate).format('yyyy-MM-DD');
                    element.end = moment(element.orderDate).format('yyyy-MM-DD');
                    element.tempId = element.id;
                    element.color =
                        theme === 'dark'
                            ? GetMealOrderStatusColorDark(element.status)
                            : GetMealOrderStatusColor(element.status);
                    element.type = 'mealOrder';

                    const compareDay = Number(moment(element.start));
                    const index = resolvedData.findIndex((object: any) => object.tempId == compareDay);
                    if (index > -1) {
                        const item = resolvedData[index];
                        resolvedData.splice(index, 1, { ...item, ...element });
                    } else {
                        resolvedData.push(element);
                    }
                });
            }

            setNextMealDays(resolvedData);
        });
    };

    const searchValue = () => {
        return value?.orderDate
            ? `${getDateFormat(value.orderDate, 'yyyy-MM-DD')}, ${getDateFormat(
                  moment(value.orderDate).add(32, 'days'),
                  'yyyy-MM-DD'
              )}`
            : `${getDateFormat(form.getFieldValue('orderDate'), 'yyyy-MM-DD')}, ${getDateFormat(
                  moment(form.getFieldValue('orderDate'), 'DD.MM.YYYY').add(32, 'days'),
                  'yyyy-MM-DD'
              )}`;
    };

    const checkNextDaysDates = (momentNext: any) => {
        const isDateOrder: any = nextMealDays?.find(
            (day: any) => getDateFormat(day.orderDate, 'DD.MM.YYYY') == getDateFormat(momentNext, 'DD.MM.YYYY')
        );
        return isDateOrder ? true : false;
    };

    const onCancel = () => {
        setVisible(false);
        if (setValue) setValue(undefined);
        setMenuListGrouped(undefined);
        form.resetFields();
        assetForm.resetFields();
        setWizardStep(1);
        setOrderDate(undefined);
        setDataForm(undefined);
        setItems([]);
        setItemsLength(0);
        setUserAdvance(0);
        setStartAdvance(0);
        setNextOrderDate(undefined);
        setUserSubvention(0);
        setTotalAdvance(0);
        setTotalSubvention(0);
        setCutleryNeeded(false);
        setUploadBillFiles([]);
    };

    const getLastDeliverySpot = () => {
        let lastDeliverySpotTime: any = {};
        let lastDeliverySpot: any = {};
        NyRequestResolver.requestGet(CONSTANTS_REQ.MEAL_ORDER.LAST_DELIVERY_SPOT).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data && result.data.deliverySpotId) {
                lastDeliverySpotTime = result.data;
                lastDeliverySpot = { deliverySpotId: result.data.deliverySpotId, type: result.data.type };
            }
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.MEAL_DELIVERY_SPOT.SEARCH +
                    '/' +
                    moment(form.getFieldValue('orderDate'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                {
                    search: encodeURI(JSON.stringify(defaultActiveFilter())),
                }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    const findItem: any = result.data.find(
                        (item: any) =>
                            item.deliverySpotId === lastDeliverySpot.deliverySpotId &&
                            item.type === lastDeliverySpot.type
                    );
                    if (findItem) {
                        const spot = getDeliverySpotTimeSelectOption(lastDeliverySpotTime);
                        form.setFieldsValue({ deliverySpot: spot });
                        setSelectedDeliverySpotValue(spot);
                        setDeliveryType(lastDeliverySpot?.type);
                        setSelectedDeliverySpot(lastDeliverySpot.deliverySpotId);
                    }
                }
            });
        });
    };

    const getDeliverySpotMenuData = (deliverySpot: any) => {
        setMenuListGrouped([]);
        if (deliverySpot > -1) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.MEAL_MENU.EDIT +
                    '/' +
                    deliverySpot +
                    '/' +
                    moment(form.getFieldValue('orderDate')).format('yyyy-MM-DD'),
                { deliveryType: deliveryType }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    const emptyData: any = [];
                    const mealsWithNoGroupList: any = [];
                    let data = _.cloneDeep(result.data);
                    let noGroupHasMeals = false;
                    data = data
                        .filter(
                            (element: any) =>
                                element.mealGroup?.id == null ||
                                element.mealGroup?.id == undefined ||
                                element.mealGroup == null ||
                                element.mealGroup == undefined ||
                                element.mealGroup == emptyData
                        )
                        .map((value: any) => {
                            mealsWithNoGroupList.push(value);
                            noGroupHasMeals = true;
                        });

                    const mealsGroupOther: any = { group: 'Ostalo', meals: mealsWithNoGroupList, size: 0 };

                    const groupedList: any = _(result.data)
                        .filter((item: any) => item.mealGroup?.id != null && item.mealGroup?.id != undefined)
                        .groupBy((x) => x.mealGroup.name)
                        .map((value: any, key: any) => {
                            return {
                                group: key,
                                meals: value,
                                size: items
                                    .map((item: any) => item.quantity)
                                    .reduce((sum: any, current: any) => sum + current, 0),
                            };
                        })
                        .value();
                    if (noGroupHasMeals) {
                        groupedList.push(mealsGroupOther);
                    }
                    setMenuListGrouped(groupedList);
                }
            });
        }
    };

    const fetchHolidays = () => {
        let active = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOT_WORKING_DAYS.ALL_NOT_WORKING_DAYS, {
            search: active,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    const holidayList = result.data.map((day: any) => {
                        return {
                            date: moment(day.date).format('DD.MM.YYYY.'),
                            name: day.name,
                            active: day.active,
                        };
                    });
                    setHolidays(holidayList);
                    setNextWorkDay(getNextWorkday(holidayList));
                }
            }
        });
    };

    const saveOrder = (status: number) => {
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];

                if (activeEmployee && activeEmployee.id) {
                    params.employee = { id: activeEmployee.id };
                }
                params.orderDate = getDateFormat(values.orderDate, 'yyyy-MM-DD');
                params.deliverySpotTime = getSearchFormat(values.deliverySpot);
                params.status = status;
                items.map((item: any) => {
                    if (!item.mealGroup.id || item.mealGroup.id == 999) {
                        item.mealGroup = {};
                    }
                    if (!subventionIsNotNull) {
                        item.amount = item.price * item.quantity;
                        item.advance = item.price * item.quantity;
                        item.subvention = 0;
                    }
                });

                params.orderItem = items;
                params.totalSubvention = totalSubvention;
                params.totalAdvance = totalAdvance;
                params.deliverCutlery = cutleryNeeded;

                NyRequestResolver.requestPost(CONSTANTS_REQ.MEAL_ORDER.EDIT, undefined, {
                    ...params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        afterSaveActions();
                        fetchNextWorkday();
                    } else {
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                    setLoadingConfirmButton(false);
                    setLoadingSaveButton(false);
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const updateOrder = (status: any) => {
        form.validateFields()
            .then((values: any) => {
                setLoading(true);
                let params: any = [];
                if (activeEmployee && activeEmployee.id) {
                    params.employee = { id: activeEmployee.id };
                }
                params.id = dataForm;
                params.orderDate = getDateFormat(values.orderDate, 'yyyy-MM-DD');
                params.deliverySpotTime = getSearchFormat(values.deliverySpot);
                params.status = status;
                params.deliverCutlery = cutleryNeeded;
                items.map((item: any) => {
                    if (!item.mealGroup.id || item.mealGroup.id == 999) {
                        item.mealGroup = {};
                    }
                    if (!subventionIsNotNull) {
                        item.amount = item.price * item.quantity;
                        item.advance = item.price * item.quantity;
                        item.subvention = 0;
                    }
                });
                params.orderItem = items;
                NyRequestResolver.requestPut(CONSTANTS_REQ.MEAL_ORDER.EDIT + '/' + dataForm, undefined, {
                    ...params,
                }).then((result: any) => {
                    if (setLoading) setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        afterSaveActions();
                    } else {
                        if (result.data && result.data.error) {
                            if (geti18nText(result.data.error) != '') {
                                errorNotification(result.data.error);
                            } else {
                                errorNotification(JSON.stringify(result.data.error));
                            }
                        } else {
                            errorNotification();
                        }
                    }
                    setLoadingConfirmButton(false);
                    setLoadingSaveButton(false);
                });
            })
            .catch((errorInfo: any) => {
                if (setLoading) setLoading(false);
                console.log(errorInfo);
            });
    };

    const afterSaveActions = () => {
        if (setLoading) setLoading(false);
        okNotification();
        getNextFiveDayOrder();
        getNextAvailableWorkDay();
        setWizardStep(3);
        setRefresh((prevValue: number) => prevValue + 1);
    };

    const onDeliverySpotChange = (value: any) => {
        setSelectedDeliverySpotConfirmValue(value);
        const type = value?.text?.props && value?.text?.props['custom-data']?.type;
        const spot = value?.text?.props && value?.text?.props['custom-data']?.deliverySpotId;
        if (items && (items.length == 0 || !items.length)) {
            setDeliveryType(type);
            setSelectedDeliverySpot(spot);
        } else if (items && items.length > 0 && (type != deliveryType || spot != selectedDeliverySpot)) {
            setDeliveryTypeConfirm(type);
            setSelectedDeliverySpotConfirm(spot);
            setDeliverySpotModalVisible(true);
        }
    };

    const hasUserAdvance = () => {
        if (mealSyncTypeHNB) {
            return userAdvance > 0;
        }
        return true;
    };

    const canAddItemsWithSubvention = () => {
        return mealSyncTypeHNB && subventionIsNotNull && userAdvance - totalAdvance > 0 && !subventionOverflowConfirmed;
    };

    const getItemsCount = () => {
        if (canAddItemsWithSubvention()) {
            return NyUtils.formatNumber(totalAdvance ?? 0, 2) + ' ' + currency;
        } else {
            return NyUtils.formatNumber(itemsLength ?? 0, 2) + ' ' + currency;
        }
    };

    const initialColumns: any = [
        ...[
            {
                title: geti18nText('meal.menu.group.table.image'),
                dataIndex: ['meal', 'fileId'],
                width: '70px',
                render: (fileId: any, record: any) => {
                    if (record && record.meal) {
                        return (
                            <Popover
                                title={record.meal.name}
                                placement="right"
                                content={
                                    <>
                                        {record.meal.fileId ? (
                                            <Image
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '464px',
                                                    width: '464px',
                                                    borderRadius: '5px',
                                                }}
                                                preview={false}
                                                src={
                                                    CONSTANTS_REQ.FILES.DOWNLOAD +
                                                    '/' +
                                                    record.meal.fileId +
                                                    '?tenant=' +
                                                    NySession.getUser().tenantId
                                                }
                                            />
                                        ) : (
                                            <Image
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '464px',
                                                    width: '464px',
                                                    borderRadius: '5px',
                                                }}
                                                src="error"
                                                fallback={FILE_FALLBACK}
                                            />
                                        )}
                                    </>
                                }
                            >
                                {record.meal.fileId ? (
                                    <Image
                                        style={{
                                            objectFit: 'cover',
                                            height: '64px',
                                            width: '64px',
                                            borderRadius: '5px',
                                        }}
                                        preview={false}
                                        src={
                                            CONSTANTS_REQ.FILES.DOWNLOAD +
                                            '/' +
                                            record.meal.fileId +
                                            '?tenant=' +
                                            NySession.getUser().tenantId
                                        }
                                    />
                                ) : (
                                    <Image
                                        style={{
                                            objectFit: 'cover',
                                            height: '64px',
                                            width: '64px',
                                            borderRadius: '5px',
                                        }}
                                        src="error"
                                        fallback={FILE_FALLBACK}
                                    />
                                )}
                            </Popover>
                        );
                    }
                },
            },
        ],
        ...(mealSyncTypeHNB === true && isAdminForm === true
            ? [
                  {
                      title: geti18nText('meal.table.column.refId'),
                      dataIndex: ['meal', 'refId'],
                      width: '100px',
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('meal.menu.group.table.name'),
                dataIndex: ['meal', 'name'],
                width: '130px',
            },

            {
                title: geti18nText('meal.delivery.edit.quantity'),
                inputType: 'quantity',
                dataIndex: 'quantity',
                width: '50px',
                align: 'right',
                render: (text: any, record: any) => {
                    return NyUtils.formatNumber(record.quantity, record.quantity % 1 > 0 ? 1 : 0);
                },
            },
            {
                title: geti18nText('meal.delivery.edit.price'),
                dataIndex: 'price',
                width: '130px',
                align: 'right',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {NyUtils.formatNumber(record.price ?? 0, 2) +
                                ' ' +
                                (record.currency && record.currency.isoCode ? record.currency.isoCode : '')}
                            {mealSyncTypeHNB && subventionIsNotNull && (
                                <div style={{ color: 'gray' }}>
                                    {' '}
                                    {geti18nText('meal.delivery.edit.subvention') +
                                        ' -' +
                                        (!subventionOverflowConfirmed
                                            ? getMealSubventionAmount(
                                                  mealSubventionValue,
                                                  record.meal?.allowedDiscount ?? 0,
                                                  true
                                              )
                                            : '0,00') +
                                        '%'}
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                title: geti18nText('meal.delivery.edit.amount'),
                dataIndex: 'amount',
                width: '130px',
                align: 'right',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {mealSyncTypeHNB && subventionIsNotNull && !subventionOverflowConfirmed ? (
                                <>
                                    {NyUtils.formatNumber(record.advance ?? 0, 2) +
                                        ' ' +
                                        (record.currency && record.currency.isoCode ? record.currency.isoCode : '')}
                                </>
                            ) : (
                                <>
                                    {NyUtils.formatNumber(
                                        record.price * record.quantity > 0 ? record.price * record.quantity : 0,
                                        2
                                    ) +
                                        ' ' +
                                        (record.currency && record.currency.isoCode ? record.currency.isoCode : '')}
                                </>
                            )}
                        </div>
                    );
                },
            },
        ],
        ...(!isDisabledForm
            ? [
                  {
                      title: geti18nText('settings.shortcuts.action'),
                      key: 'action',
                      width: '70px',
                      align: 'right',
                      render: (text: any, record: any) => {
                          return (
                              <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                                  <NyModalConfirm
                                      title={geti18nText('app.default.delete.confirm')}
                                      onConfirm={() => {
                                          deleteRow(record);
                                      }}
                                  >
                                      <Button type="link" disabled={isDisabledForm}>
                                          <DeleteTwoTone
                                              style={{
                                                  fontSize: '20px',
                                              }}
                                              twoToneColor="#ff7875"
                                          />
                                      </Button>
                                  </NyModalConfirm>
                              </Tooltip>
                          );
                      },
                  },
              ]
            : []),
        ...[],
    ];

    const generateBill = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.GROUP_ORDER.GENERATE_BILL, {
            mealOrderId: dataForm,
        }).then((result: any) => {
            if (result.status == RESPONSE.OK) {
                okNotification(geti18nText('meal.delivery.table.generate.bill.report.success'));
            } else {
                getErrorNotificationMessage(result);
            }
        });
    };

    const uploadBill = () => {
        const billFileId =
            uploadBillFiles?.length > 0 && uploadBillFiles[0] && uploadBillFiles[0].id ? uploadBillFiles[0].id : null;
        if (billFileId) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.MEAL_ORDER.UPDATE_BILL_FILE + '/' + dataForm, undefined, {
                mealOrderId: dataForm,
                billFileId: billFileId,
                billNumber: formBill.getFieldValue('billNumber'),
            }).then((result: any) => {
                if (result.status == RESPONSE.OK) {
                    okNotification(geti18nText('meal.delivery.table.generate.bill.report.success'));
                    setUploadBillModalVisible(false);
                } else {
                    getErrorNotificationMessage(result);
                }
            });
        } else {
            warningNotification(geti18nText('systematization.documents.add.file'));
        }
    };

    const actionsMenu = (
        <Menu>
            <div style={{ display: 'block', margin: '5px' }}>
                {!data?.billFileId && (
                    <Button onClick={generateBill} style={{ width: '100%' }} icon={<FilePdfOutlined />}>
                        {geti18nText('meal.delivery.table.generate.bill.report.action')}
                    </Button>
                )}
                <Button
                    onClick={() => setUploadBillModalVisible(true)}
                    style={{ width: '100%' }}
                    icon={<UploadOutlined />}
                >
                    {geti18nText('meal.delivery.table.upload.bill.report.action')}
                </Button>
            </div>
        </Menu>
    );

    const getCustomFooterContent = (
        <React.Fragment>
            <div style={{ float: 'left' }}>
                {showGenerateBills &&
                    dataForm &&
                    status &&
                    status == mealOrderStatus.CONFIRMED &&
                    data?.billingStatus == enBillingStatus.PAID && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                                <Button type="primary">
                                    {geti18nText('app.default.actions')}
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                {wizardStep == 1 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {geti18nText('meals.order.edit.totalPrice') + ':'}
                        <div style={{ marginLeft: '5px', fontWeight: 'bold' }}>
                            <Statistic value={getItemsCount()} />
                        </div>
                    </div>
                )}
            </div>

            <Button
                onClick={() => {
                    setVisible(false);
                    if (setValue) setValue(undefined);
                }}
            >
                {geti18nText('app.default.button.cancel')}
            </Button>

            {wizardStep == 2 && !isDisabledForm && (
                <Button type="primary" onClick={() => setWizardStep(1)} disabled={isDisabledForm}>
                    <CaretLeftOutlined />
                    {geti18nText('meals.back')}
                </Button>
            )}

            {wizardStep == 1 && (
                <Button
                    type="primary"
                    onClick={() => {
                        setWizardStep(2);
                        if (deliverCutlery > 0) {
                            if (cutleryNeeded == false) {
                                setCutleryModalVisible(true);
                            }
                        }
                    }}
                    disabled={disabledForm()}
                >
                    {geti18nText('meals.basket')}
                    <CaretRightOutlined />
                </Button>
            )}

            {wizardStep == 2 && !disabledForm() && (
                <>
                    <NyModalConfirm
                        title={geti18nText('meals.order.save.question')}
                        onConfirm={() => {
                            setLoadingSaveButton(true);
                            if (isEdit() && !isReopenedModal) {
                                updateOrder(mealOrderStatus.UNCONFIRMED);
                            } else {
                                saveOrder(mealOrderStatus.UNCONFIRMED);
                            }
                            setStatus(mealOrderStatus.UNCONFIRMED);
                        }}
                    >
                        <Button loading={loadingSaveButton} disabled={disabledForm()}>
                            {geti18nText('meals.order.save')}
                        </Button>
                    </NyModalConfirm>
                    <NyModalConfirm
                        title={geti18nText('meals.order.order.question')}
                        onConfirm={() => {
                            setLoadingConfirmButton(true);
                            if (isEdit() && !isReopenedModal) {
                                updateOrder(mealOrderStatus.CONFIRMED);
                            } else {
                                saveOrder(mealOrderStatus.CONFIRMED);
                            }
                            setStatus(mealOrderStatus.CONFIRMED);
                        }}
                        content={subventionIsNotNull ? null : geti18nText('meals.order.order.content')}
                    >
                        <Button
                            type="primary"
                            loading={loadingConfirmButton}
                            icon={<CheckCircleOutlined />}
                            disabled={disabledForm()}
                        >
                            {geti18nText('meal.order.edit.editOrder')}
                        </Button>
                    </NyModalConfirm>
                </>
            )}
        </React.Fragment>
    );

    const deleteRow = (record: any) => {
        if (record?.meal?.id) {
            setItems(items.filter((row: any) => row.meal.id !== record.meal.id));
            if (mealSyncTypeHNB) {
                calculateMealSubvention(record.meal.allowedDiscount, record);
            }
            setItemsLength((prevValue: number) => prevValue - record.quantity * record.price);
        }
    };

    const showItemQuantity = (item: any) => {
        const findItem = items.find((basket: any) => basket.meal.id == item.meal.id);

        if (findItem) {
            return NyUtils.formatNumber(findItem.quantity, findItem.quantity % 1 > 0 ? 1 : 0);
        }

        return 0;
    };

    const getItemQuantityInGroup = (groupId: any) => {
        let groupOrderSum: any = 0;

        const filteredOrders = items.filter((basket: any) => basket?.mealGroup?.id == groupId);

        if (filteredOrders && filteredOrders.length > 0) {
            groupOrderSum = filteredOrders
                .map((c: any) => c.quantity)
                .reduce((sum: any, current: any) => sum + current);
        }

        return groupOrderSum;
    };

    const quantityIsLessThanMax = (mealGroupId: any = null, minOrder: any = 1, maxOrder: any = 1) => {
        return round(getItemQuantityInGroup(mealGroupId) + minOrder, 2) <= round(maxOrder, 2);
    };

    const quantityIsLessThanMaxOrder = (item: any) => {
        const maxOrder = item?.mealGroup?.maxOrder != undefined ? item.mealGroup.maxOrder : 1;
        let groupedItemsCopy: any = [...menuListGrouped];
        const objIndexGrouped = groupedItemsCopy.findIndex((obj: any) => obj.group == item.mealGroup.name);
        return groupedItemsCopy[objIndexGrouped]?.size < maxOrder;
    };

    //start subvention calculation
    const calculateMealSubvention = (allowedDiscount: any, item: any) => {
        let mealSubventionPercent =
            subventionIsNotNull && !subventionOverflowConfirmed
                ? getMealSubventionAmount(mealSubventionValue, allowedDiscount)
                : 0;

        const priceAmount = (item.price * mealSubventionPercent) / 100;
        const price = round(priceAmount > 0 ? priceAmount : 0, 2);
        const amount = price * item.quantity;
        const mealPriceWithSubvention = round(amount > 0 ? amount : 0, 2);
        const advanceAmount = item.amount - mealPriceWithSubvention;
        const mealPriceWithAdvance = round(advanceAmount > 0 ? advanceAmount : 0, 2);

        item = {
            ...item,
            subvention: mealPriceWithSubvention,
            advance: mealPriceWithAdvance,
        };
        return item;
    };

    const calculateTotalAdvanceAndSubvention = (items: any) => {
        let totalAdvanceTemp = 0;
        let totalSubventionTemp = 0;

        let allItemsAdvance = 0;
        let allItemsSubvention = 0;
        items.map((item: any) => {
            let newObject = calculateMealSubvention(item?.meal?.allowedDiscount, item);
            allItemsAdvance = allItemsAdvance + newObject.advance > 0 ? allItemsAdvance + newObject.advance : 0;
            allItemsSubvention =
                allItemsSubvention + newObject.subvention > 0 ? allItemsSubvention + newObject.subvention : 0;
            if (subventionOverflowConfirmed) {
                totalAdvanceTemp = totalAdvanceTemp + (item.price ?? 0) * (item?.quantity ?? 1);
                totalSubventionTemp = 0;
            } else {
                totalAdvanceTemp = totalAdvanceTemp + newObject.advance > 0 ? totalAdvanceTemp + newObject.advance : 0;
                totalSubventionTemp =
                    totalSubventionTemp + newObject.subvention > 0 ? totalSubventionTemp + newObject.subvention : 0;
            }
        });
        if (totalAdvanceTemp <= userAdvance) {
            if (allItemsSubvention <= userSubvention) {
                setTotalSubvention(round(allItemsSubvention, 2));
                setTotalAdvance(round(allItemsAdvance, 2));
                setSubventionOverflowConfirmed(false);
            } else {
                setTotalSubvention(round(totalSubventionTemp, 2));
                setTotalAdvance(round(totalAdvanceTemp, 2));
            }
        }
    };

    useEffect(() => {
        if (mealSyncTypeHNB) {
            calculateTotalAdvanceAndSubvention(items);
        }
    }, [items, userAdvance]);
    //end subvention calculation

    const beforeAddItem = (item: any) => {
        if (mealSyncTypeHNB) {
            const itemSubventionPrice = !subventionOverflowConfirmed
                ? getMealSubventionPrice(item, mealSubventionValue)
                : 0;
            const minOrder =
                item?.meal?.minOrder != undefined
                    ? item.meal.minOrder
                    : item?.mealGroup?.minOrder != undefined
                    ? item.mealGroup.minOrder
                    : 1;
            const mealSubventionWithQuantity = itemSubventionPrice * minOrder;

            const maxOrder = item?.mealGroup?.maxOrder != undefined ? item.mealGroup.maxOrder : 1;
            if (quantityIsLessThanMax(item?.mealGroup?.id, minOrder, maxOrder)) {
                if (
                    userSubvention - totalSubvention - mealSubventionWithQuantity <= 0 &&
                    !subventionOverflowConfirmed
                ) {
                    setItem(item);
                    setModalItemVisible(true);
                } else {
                    addItemInBasket(item);
                }
            } else {
                warningNotification(geti18nText('meal.order.edit.cantAddItems'));
            }
        } else {
            addItemInBasket(item);
        }
    };

    const addItemInBasket = (item: any, extraSubventionOverflow: boolean = false) => {
        if (!isDisabledForm) {
            const minOrder =
                item?.meal?.minOrder != undefined
                    ? item.meal.minOrder
                    : item?.mealGroup?.minOrder != undefined
                    ? item.mealGroup.minOrder
                    : 1;
            const maxOrder = item?.mealGroup?.maxOrder != undefined ? item.mealGroup.maxOrder : 1;

            let itemsBasketCopy: any = [...items];

            let itemInBasket = itemsBasketCopy.find((i: any) => i.meal.id == item.meal.id);
            const objIndex = itemsBasketCopy.findIndex((obj: any) => obj.meal.id == item.meal.id);

            let updatedObj: any = {};

            if (itemInBasket) {
                if (quantityIsLessThanMax(item?.mealGroup?.id, minOrder, maxOrder)) {
                    updatedObj = {
                        ...itemsBasketCopy[objIndex],
                        quantity: itemsBasketCopy[objIndex].quantity + minOrder,
                        amount: round(
                            (itemsBasketCopy[objIndex].quantity + minOrder) * itemsBasketCopy[objIndex].price,
                            2
                        ),
                        price: round(itemsBasketCopy[objIndex].price, 2),
                    };

                    if (mealSyncTypeHNB) {
                        updatedObj = calculateMealSubvention(item.meal.allowedDiscount, updatedObj);
                    }

                    const updatedProjects = [
                        ...itemsBasketCopy.slice(0, objIndex),
                        updatedObj,
                        ...itemsBasketCopy.slice(objIndex + 1),
                    ];

                    if (mealSyncTypeHNB) {
                        const itemPrice =
                            subventionIsNotNull && !subventionOverflowConfirmed && !extraSubventionOverflow
                                ? (updatedObj.advance / updatedObj.quantity) * minOrder
                                : updatedObj.price * minOrder;
                        if (userAdvance - totalAdvance - itemPrice >= 0) {
                            itemsBasketCopy = updatedProjects;
                            setItems(itemsBasketCopy);
                            setItemsLength(
                                (prevValue: number) => prevValue + minOrder * itemsBasketCopy[objIndex].price
                            );
                            setShowInitValues(true);
                        } else {
                            warningNotification(geti18nText('meal.order.edit.noUserAdvance'));
                        }
                    } else {
                        itemsBasketCopy = updatedProjects;
                        setItems(itemsBasketCopy);
                        setItemsLength((prevValue: number) => prevValue + minOrder * itemsBasketCopy[objIndex].price);
                    }
                } else {
                    warningNotification(geti18nText('meal.order.edit.cantAddItems'));
                }
            } else {
                if (quantityIsLessThanMax(item?.mealGroup?.id, minOrder, maxOrder)) {
                    let newObject: any = {
                        ...item,
                        quantity: minOrder,
                        amount: round(item.priceList.price * minOrder, 2),
                        price: round(item.priceList.price, 2),
                        currency: {
                            id: item.priceList.currencyId,
                            isoCode: item.priceList.currencyIsoCode,
                        },
                    };
                    if (mealSyncTypeHNB) {
                        newObject = calculateMealSubvention(item.meal.allowedDiscount, newObject);

                        const itemPrice =
                            subventionIsNotNull && !subventionOverflowConfirmed && !extraSubventionOverflow
                                ? (newObject.advance / newObject.quantity) * minOrder
                                : newObject.price * minOrder;
                        if (userAdvance - totalAdvance - itemPrice >= 0) {
                            itemsBasketCopy.push(newObject);
                            setItems(itemsBasketCopy);
                            setItemsLength((prevValue: number) => prevValue + minOrder * item.priceList.price);
                            setShowInitValues(true);
                        } else {
                            warningNotification(geti18nText('meal.order.edit.noUserAdvance'));
                        }
                    } else {
                        itemsBasketCopy.push(newObject);
                        setItems(itemsBasketCopy);
                        setItemsLength((prevValue: number) => prevValue + minOrder * item.priceList.price);
                    }
                } else {
                    warningNotification(geti18nText('meal.order.edit.cantAddItems'));
                }
            }
        }
    };

    const removeFromBasket = (item: any) => {
        if (!isDisabledForm) {
            const minOrder =
                item?.meal?.minOrder != undefined
                    ? item.meal.minOrder
                    : item?.mealGroup?.minOrder != undefined
                    ? item.mealGroup.minOrder
                    : 1;

            let itemsBasketCopy: any = [...items];
            let itemInBasket = itemsBasketCopy.find((i: any) => i.meal.id == item.meal.id);

            const objIndex = itemsBasketCopy.findIndex((obj: any) => obj.meal.id == item.meal.id);

            let updatedObj: any = {};
            if (itemInBasket) {
                if (itemsBasketCopy[objIndex].quantity > minOrder) {
                    updatedObj = {
                        ...itemsBasketCopy[objIndex],
                        quantity: itemsBasketCopy[objIndex].quantity - minOrder,
                        amount: round(
                            (itemsBasketCopy[objIndex].quantity - minOrder) * itemsBasketCopy[objIndex].price,
                            2
                        ),
                        price: round(itemsBasketCopy[objIndex].price, 2),
                    };

                    if (mealSyncTypeHNB) {
                        updatedObj = calculateMealSubvention(item.meal.allowedDiscount, updatedObj);
                    }
                    const updatedProjects = [
                        ...itemsBasketCopy.slice(0, objIndex),
                        updatedObj,
                        ...itemsBasketCopy.slice(objIndex + 1),
                    ];
                    itemsBasketCopy = updatedProjects;
                    setItemsLength((prevValue: number) => prevValue - minOrder * itemsBasketCopy[objIndex].price);
                    setShowInitValues(true);
                } else {
                    if (mealSyncTypeHNB) {
                        updatedObj = calculateMealSubvention(item.meal.allowedDiscount, updatedObj);
                    }

                    let price = itemsBasketCopy[objIndex].price;
                    setItemsLength((prevValue: number) => prevValue - minOrder * price);

                    itemsBasketCopy = itemsBasketCopy.filter((row: any) => row.meal.id !== item.meal.id);
                    setShowInitValues(true);
                }
                setItems(itemsBasketCopy);
            }
        }
    };

    const onEndDateChange = (value: any) => {
        if (form.getFieldValue('validFrom')) {
            form.setFieldsValue({
                duration: Math.abs(form.getFieldValue('validFrom').startOf('day').diff(value, 'days')) + 1,
            });
        }
    };

    const disabledendDate = (current: any) => {
        if (form.getFieldValue('validFrom')) {
            return moment(current).add(1, 'days') < moment(form.getFieldValue('validFrom')).endOf('day');
        }
    };

    return (
        <>
            <Modal
                title={
                    value && value.id
                        ? geti18nText('app.enum.MEAL_ORDER_STATUS.' + status) +
                          ' ' +
                          geti18nText('meal.order.edit.tab.header').toLowerCase() +
                          ' (' +
                          moment(form.getFieldValue('orderDate')).format('DD.MM.YYYY.') +
                          ')'
                        : geti18nText('meal.order.edit.new')
                }
                visible={visible}
                maskClosable={false}
                width={1200}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={getCustomFooterContent}
            >
                <Form layout="vertical" form={form}>
                    <>
                        {wizardStep != 3 && isAdminForm == false && (
                            <Row gutter={24}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('meal.order.edit.orderDate')}
                                        name="orderDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                        initialValue={moment(date)}
                                    >
                                        <NyDatePicker style={{ width: '100%' }} nyTestId="order-date" disabled={true} />
                                    </Form.Item>
                                    {mealSyncTypeHNB && !isDisabledForm && (
                                        <Descriptions style={{ width: '100%' }} bordered size="small">
                                            <Descriptions.Item
                                                style={{
                                                    borderTop: '2px solid #68d01866',
                                                    fontWeight: '500',
                                                }}
                                                labelStyle={{ backgroundColor: '#52c41a16' }}
                                                label={geti18nText('meal.order.widget.advance')}
                                            >
                                                <div className="green-desc-item">
                                                    {subventionIsNotNull ? (
                                                        <>
                                                            {userAdvance - totalAdvance >= 0
                                                                ? NyUtils.formatNumber(
                                                                      userAdvance - totalAdvance + totalAdvanceInit >= 0
                                                                          ? userAdvance -
                                                                                totalAdvance +
                                                                                totalAdvanceInit
                                                                          : 0,
                                                                      2
                                                                  ) +
                                                                  ' ' +
                                                                  currency
                                                                : NyUtils.formatNumber(
                                                                      userAdvance + totalAdvanceInit >= 0
                                                                          ? userAdvance + totalAdvanceInit
                                                                          : 0,
                                                                      2
                                                                  ) +
                                                                  ' ' +
                                                                  currency}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {userAdvance - itemsLength >= 0
                                                                ? NyUtils.formatNumber(
                                                                      userAdvance - itemsLength + totalAdvanceInit >= 0
                                                                          ? userAdvance - itemsLength + totalAdvanceInit
                                                                          : 0,
                                                                      2
                                                                  ) +
                                                                  ' ' +
                                                                  currency
                                                                : NyUtils.formatNumber(
                                                                      userAdvance + totalAdvanceInit >= 0
                                                                          ? userAdvance + totalAdvanceInit
                                                                          : 0,
                                                                      2
                                                                  ) +
                                                                  ' ' +
                                                                  currency}
                                                        </>
                                                    )}
                                                    {showInitValues && (
                                                        <>
                                                            {dataForm ? (
                                                                <>
                                                                    {' ('}
                                                                    {NyUtils.formatNumber(
                                                                        userAdvance - totalAdvanceOrder >= 0
                                                                            ? userAdvance - totalAdvanceOrder
                                                                            : 0,
                                                                        2
                                                                    )}{' '}
                                                                    {currency}
                                                                    {')'}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {' ('}
                                                                    {NyUtils.formatNumber(
                                                                        userAdvance >= 0 ? userAdvance : 0,
                                                                        2
                                                                    )}{' '}
                                                                    {currency}
                                                                    {')'}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                style={{
                                                    borderTop: '2px solid #2c7be566',
                                                    fontWeight: '500',
                                                }}
                                                labelStyle={{ backgroundColor: '#2c7be516' }}
                                                label={geti18nText('meal.order.widget.subvention')}
                                            >
                                                <div className="blue-desc-item">
                                                    <>
                                                        {subventionIsNotNull
                                                            ? NyUtils.formatNumber(
                                                                  userSubvention -
                                                                      totalSubvention +
                                                                      totalSubventionInit >=
                                                                      0
                                                                      ? userSubvention -
                                                                            totalSubvention +
                                                                            totalSubventionInit
                                                                      : 0,
                                                                  2
                                                              ) +
                                                              ' ' +
                                                              currency
                                                            : NyUtils.formatNumber(
                                                                  userSubvention + totalSubventionInit >= 0
                                                                      ? userSubvention + totalSubventionInit
                                                                      : 0,
                                                                  2
                                                              ) +
                                                              ' ' +
                                                              currency}

                                                        {showInitValues && (
                                                            <>
                                                                {dataForm ? (
                                                                    <>
                                                                        {' ('}
                                                                        {NyUtils.formatNumber(
                                                                            userSubvention - totalSubventionOrder >= 0
                                                                                ? userSubvention - totalSubventionOrder
                                                                                : 0,
                                                                            2
                                                                        )}{' '}
                                                                        {currency}
                                                                        {')'}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {' ('}
                                                                        {NyUtils.formatNumber(
                                                                            userSubvention >= 0 ? userSubvention : 0,
                                                                            2
                                                                        )}{' '}
                                                                        {currency}
                                                                        {')'}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    )}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('menu.meals.deliverySpot')}
                                        name="deliverySpot"
                                        rules={[
                                            {
                                                required: true,
                                                message: geti18nText('app.default.required'),
                                            },
                                        ]}
                                    >
                                        <NySearchField
                                            url={
                                                CONSTANTS_REQ.MEAL_DELIVERY_SPOT.SEARCH +
                                                '/' +
                                                getDateFormat(form.getFieldValue('orderDate'), 'yyyy-MM-DD')
                                            }
                                            map={{
                                                id: 'id',
                                                label: 'text',
                                                delivery: 'delivery',
                                                type: 'type',
                                                deliverySpotId: 'deliverySpotId',
                                            }}
                                            className=""
                                            searchBy="name"
                                            onChange={onDeliverySpotChange}
                                            renderOption={customDeliverySpotTimeRenderOption}
                                            setDefaultFilterValue={defaultActiveFilter}
                                            style={{ width: '100%' }}
                                            order="time"
                                            disabled={isConfirmedOrder() || isDisabledForm}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {wizardStep != 3 && isAdminForm == true && (
                            <>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('meal.delivery.edit.column.person')}
                                            name={'employee'}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={CONSTANTS_REQ.EMPLOYEE.SEARCH}
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    employmentRecordId: 'employmentRecordId',
                                                    businessUnit: 'businessUnit',
                                                }}
                                                searchBy="person.first_name || ' ' || person.last_name"
                                                itemName={[
                                                    ['person', 'firstName'],
                                                    ['person', 'lastName'],
                                                ]}
                                                customItemNameLabel={'firstName lastName'}
                                                SearchPopupComponent={
                                                    <EmployeeIndex
                                                        disabled={false}
                                                        filteredForTravel
                                                        parentKey="meals-wizard"
                                                    />
                                                }
                                                nyTestId="employee"
                                                renderOption={customEmployeeRenderOption}
                                                className=""
                                                order="person.last_name, person.first_name"
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('menu.meals.deliverySpot')}
                                            name="deliverySpot"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                url={
                                                    CONSTANTS_REQ.MEAL_DELIVERY_SPOT.SEARCH +
                                                    '/' +
                                                    getDateFormat(form.getFieldValue('orderDate'), 'yyyy-MM-DD')
                                                }
                                                map={{
                                                    id: 'id',
                                                    label: 'text',
                                                    delivery: 'delivery',
                                                    type: 'type',
                                                    deliverySpotId: 'deliverySpotId',
                                                }}
                                                className=""
                                                searchBy="name"
                                                onChange={onDeliverySpotChange}
                                                renderOption={customDeliverySpotTimeRenderOption}
                                                setDefaultFilterValue={defaultActiveFilter}
                                                style={{ width: '100%' }}
                                                order="time"
                                                disabled={isConfirmedOrder()}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Form.Item name="id" style={{ display: 'none' }}>
                                        <Input />
                                    </Form.Item>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('meal.order.edit.orderDate')}
                                            name="orderDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            initialValue={moment(date)}
                                        >
                                            <NyDatePicker
                                                style={{ width: '100%' }}
                                                nyTestId="order-date"
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('meal.delivery.edit.created')}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                            name="created"
                                        >
                                            <NyDatePicker
                                                format="DD.MM.YYYY HH:mm"
                                                style={{ width: '100%' }}
                                                onChange={onEndDateChange}
                                                disabledDate={disabledendDate}
                                                nyTestId="valid-to"
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                    {wizardStep == 1 &&
                        (menuListGrouped != undefined && menuListGrouped.length > 0 ? (
                            <Tabs>
                                {menuListGrouped.map((group: any) => {
                                    return (
                                        <Tabs.TabPane
                                            tab={group.group}
                                            key={group.group}
                                            style={{ overflowY: 'auto', maxHeight: '50vh', overflowX: 'hidden' }}
                                        >
                                            <Row gutter={24} style={{ padding: '0 10px' }}>
                                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    <List
                                                        grid={{
                                                            gutter: 24,
                                                            xs: 2,
                                                            sm: 2,
                                                            md: 2,
                                                            lg: 4,
                                                            xl: 4,
                                                            xxl: 4,
                                                        }}
                                                        loading={loading}
                                                        dataSource={group && group.meals ? group.meals : []}
                                                        renderItem={(item: any) => {
                                                            let priceWithSubvention = getMealAdvancePrice(
                                                                item,
                                                                mealSubventionValue
                                                            );
                                                            return (
                                                                <List.Item key={item.id}>
                                                                    <Card
                                                                        hoverable
                                                                        style={{ width: '100%' }}
                                                                        cover={
                                                                            item.meal.fileId ? (
                                                                                <Image
                                                                                    style={{
                                                                                        objectFit: 'cover',
                                                                                        border: '1px solid #f0f0f0',
                                                                                    }}
                                                                                    height="150px"
                                                                                    src={
                                                                                        CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                                        '/' +
                                                                                        item.meal.fileId +
                                                                                        '?tenant=' +
                                                                                        NySession.getUser().tenantId
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <Image
                                                                                    style={{
                                                                                        objectFit: 'cover',
                                                                                        border: '1px solid #f0f0f0',
                                                                                    }}
                                                                                    height="150px"
                                                                                    src="error"
                                                                                    fallback={FILE_FALLBACK}
                                                                                />
                                                                            )
                                                                        }
                                                                        actions={[
                                                                            <MinusCircleOutlined
                                                                                style={{
                                                                                    top: '2px',
                                                                                    position: 'relative',
                                                                                }}
                                                                                disabled={isDisabledForm}
                                                                                onClick={() => removeFromBasket(item)}
                                                                            />,
                                                                            <Badge
                                                                                count={
                                                                                    showItemQuantity(item) +
                                                                                    ' ' +
                                                                                    geti18nText('meal.order.edit.pcs')
                                                                                }
                                                                            />,
                                                                            <PlusCircleOutlined
                                                                                disabled={
                                                                                    isDisabledForm ||
                                                                                    !quantityIsLessThanMaxOrder(item) ||
                                                                                    !hasUserAdvance()
                                                                                }
                                                                                style={{
                                                                                    top: '2px',
                                                                                    position: 'relative',
                                                                                }}
                                                                                onClick={() => beforeAddItem(item)}
                                                                            />,
                                                                        ]}
                                                                    >
                                                                        <div>
                                                                            {item?.meal?.description?.length > 190 ? (
                                                                                <Meta
                                                                                    style={{
                                                                                        maxHeight: '90px',
                                                                                        padding: '0px',
                                                                                    }}
                                                                                    title={
                                                                                        <div
                                                                                            style={{
                                                                                                whiteSpace: 'pre-line',
                                                                                            }}
                                                                                        >
                                                                                            {item.meal.name}
                                                                                        </div>
                                                                                    }
                                                                                    description={
                                                                                        <Tooltip
                                                                                            placement="topLeft"
                                                                                            title={
                                                                                                item.meal.description
                                                                                            }
                                                                                        >
                                                                                            {item.meal.description}
                                                                                        </Tooltip>
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <Meta
                                                                                    title={
                                                                                        <div
                                                                                            style={{
                                                                                                whiteSpace: 'pre-line',
                                                                                            }}
                                                                                        >
                                                                                            {item.meal.name}
                                                                                        </div>
                                                                                    }
                                                                                    description={
                                                                                        item.meal.description
                                                                                            ? item.meal.description
                                                                                            : '-'
                                                                                    }
                                                                                />
                                                                            )}

                                                                            <Meta
                                                                                style={{
                                                                                    margin: '10px 0',
                                                                                    overflow: 'scroll',
                                                                                    maxHeight: '90px',
                                                                                    padding: '0px',
                                                                                }}
                                                                                title={geti18nText(
                                                                                    'meal.table.column.allergen'
                                                                                )}
                                                                                description={
                                                                                    item.meal.allergen ? (
                                                                                        <Tooltip
                                                                                            placement="topLeft"
                                                                                            title={item.meal.allergen}
                                                                                        >
                                                                                            {item.meal.allergen}
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        '-'
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Meta
                                                                                title={geti18nText(
                                                                                    'meal.order.edit.price'
                                                                                )}
                                                                                description={
                                                                                    <>
                                                                                        {mealSyncTypeHNB ? (
                                                                                            <>
                                                                                                <Tooltip
                                                                                                    title={geti18nText(
                                                                                                        'meal.order.priceWithoutSubvention'
                                                                                                    )}
                                                                                                >
                                                                                                    <Tag>
                                                                                                        {NyUtils.formatNumber(
                                                                                                            item
                                                                                                                .priceList
                                                                                                                .price,
                                                                                                            2
                                                                                                        ) +
                                                                                                            ' ' +
                                                                                                            (item
                                                                                                                .priceList
                                                                                                                .currencyIsoCode
                                                                                                                ? item
                                                                                                                      .priceList
                                                                                                                      .currencyIsoCode
                                                                                                                : '')}
                                                                                                    </Tag>
                                                                                                </Tooltip>
                                                                                                /{'   '}
                                                                                                <Tooltip
                                                                                                    title={geti18nText(
                                                                                                        'meal.order.priceWithSubvention'
                                                                                                    )}
                                                                                                >
                                                                                                    <Tag color="green">
                                                                                                        {NyUtils.formatNumber(
                                                                                                            priceWithSubvention,
                                                                                                            2
                                                                                                        ) +
                                                                                                            ' ' +
                                                                                                            (item
                                                                                                                .priceList
                                                                                                                .currencyIsoCode
                                                                                                                ? item
                                                                                                                      .priceList
                                                                                                                      .currencyIsoCode
                                                                                                                : '')}
                                                                                                    </Tag>
                                                                                                </Tooltip>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Tag>
                                                                                                {NyUtils.formatNumber(
                                                                                                    item.priceList
                                                                                                        .price,
                                                                                                    2
                                                                                                ) +
                                                                                                    ' ' +
                                                                                                    (item.priceList
                                                                                                        .currencyIsoCode
                                                                                                        ? item.priceList
                                                                                                              .currencyIsoCode
                                                                                                        : '')}
                                                                                            </Tag>
                                                                                        )}
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </Card>
                                                                </List.Item>
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    );
                                })}
                            </Tabs>
                        ) : (
                            <Row gutter={24}>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Empty
                                        description={
                                            selectedDeliverySpot
                                                ? geti18nText('meal.order.edit.select.delivery.spot.no.meals')
                                                : geti18nText('meal.order.edit.select.delivery.spot')
                                        }
                                    />
                                </Col>
                            </Row>
                        ))}
                    {wizardStep == 2 && (
                        <>
                            <Row gutter={24} style={{ marginTop: '8px' }}>
                                <Col span={24}>
                                    <Form form={assetForm} component={false}>
                                        <Table
                                            rowKey={'meal.id'}
                                            pagination={false}
                                            size={'small'}
                                            dataSource={items}
                                            columns={initialColumns}
                                        />
                                        {deliverCutlery > 0 && (
                                            <>
                                                <br />
                                                <Form.Item name="deliverCutlery">
                                                    <Checkbox
                                                        checked={cutleryNeeded}
                                                        onChange={(val: any) => {
                                                            setCutleryNeeded(val.target.checked);
                                                        }}
                                                    >
                                                        {geti18nText('meal.order.edit.cutlery.checkbox')}
                                                    </Checkbox>
                                                </Form.Item>
                                            </>
                                        )}
                                        <Divider orientation={'right'}>
                                            <div>
                                                {subventionIsNotNull && mealSyncTypeHNB && (
                                                    <Row gutter={24}>
                                                        {geti18nText('meal.delivery.edit.subvention')}:{' '}
                                                        {NyUtils.formatNumber(totalSubvention, 2)} {currency}
                                                    </Row>
                                                )}
                                                <Row gutter={24} style={{ fontSize: '14px' }}>
                                                    <b>
                                                        {geti18nText('meal.delivery.edit.total')}: {getItemsCount()}
                                                    </b>
                                                </Row>
                                            </div>
                                        </Divider>
                                    </Form>
                                </Col>
                            </Row>
                            {mealSyncTypeHNB && isAdminForm && (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Collapse
                                            activeKey={leftCollapseActiveKeys}
                                            onChange={(key: any) => {
                                                setLeftCollapseActiveKeys(key);
                                            }}
                                        >
                                            <Panel header={geti18nText('meal.delivery.edit.collapse.title')} key="1">
                                                {' '}
                                                <Descriptions
                                                    column={1}
                                                    bordered
                                                    size="small"
                                                    style={{ color: isDarkTheme ? 'white' : 'black' }}
                                                >
                                                    {data?.billFileId && (
                                                        <Descriptions.Item
                                                            className={'ant-typography-print-desc'}
                                                            label={geti18nText(
                                                                'meal.delivery.edit.collapse.billFileId'
                                                            )}
                                                        >
                                                            <Text className={'ant-typography-print-text'} strong>
                                                                {
                                                                    <Button
                                                                        icon={<FilePdfOutlined />}
                                                                        onClick={() => {
                                                                            fileDownload(
                                                                                CONSTANTS_REQ.FILES.DOWNLOAD +
                                                                                    '/' +
                                                                                    data?.billFileId,
                                                                                null,
                                                                                geti18nText(
                                                                                    'meal.delivery.edit.collapse.billFileId'
                                                                                ) +
                                                                                    ' ' +
                                                                                    getDateFormat(
                                                                                        data.billingTimestamp,
                                                                                        'DDMMYYYY - HH:mm'
                                                                                    )
                                                                            );
                                                                        }}
                                                                    >
                                                                        {geti18nText(
                                                                            'meal.delivery.edit.collapse.billFileId.download'
                                                                        )}
                                                                    </Button>
                                                                }
                                                            </Text>
                                                        </Descriptions.Item>
                                                    )}
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText('meal.delivery.edit.collapse.totalPrice')}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {(data && data.totalPrice
                                                                ? NyUtils.formatNumber(data.totalPrice, 2)
                                                                : '0,00') +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText('meal.delivery.edit.collapse.totalAdvance')}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {(data && data.totalAdvance
                                                                ? NyUtils.formatNumber(data.totalAdvance, 2)
                                                                : '0,00') +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText(
                                                            'meal.delivery.edit.collapse.totalSubvention'
                                                        )}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {(data && data.totalSubvention
                                                                ? NyUtils.formatNumber(data.totalSubvention, 2)
                                                                : '0,00') +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Divider />
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText(
                                                            'meal.delivery.edit.collapse.billingTimestamp'
                                                        )}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {data?.billingTimestamp
                                                                ? getDateFormat(
                                                                      data.billingTimestamp,
                                                                      'DD.MM.YYYY - HH:mm'
                                                                  )
                                                                : '-'}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText('meal.delivery.edit.collapse.billStatus')}
                                                    >
                                                        <Text className={'ant-typography-print-text'} strong>
                                                            {data?.billingStatus
                                                                ? getBillingStatusIcon(data.billingStatus)
                                                                : '-'}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Divider />
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText('meal.delivery.edit.collapse.reservationId')}
                                                    >
                                                        <Text className={'ant-typography-print-text'} strong>
                                                            {data?.reservationId ?? '-'}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText(
                                                            'meal.delivery.edit.collapse.reservationAdvance'
                                                        )}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {(data && data.reservationAdvance
                                                                ? NyUtils.formatNumber(data.reservationAdvance, 2)
                                                                : '0,00') +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText(
                                                            'meal.delivery.edit.collapse.reservationSubvention'
                                                        )}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {(data && data.reservationSubvention
                                                                ? NyUtils.formatNumber(data.reservationSubvention, 2)
                                                                : '0,00') +
                                                                ' ' +
                                                                currency}
                                                        </Text>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText(
                                                            'meal.delivery.edit.collapse.reservationStatus'
                                                        )}
                                                    >
                                                        <Text className={'ant-typography-print-text'} strong>
                                                            {data?.reservationStatus == 0
                                                                ? geti18nText('meal.delivery.edit.collapse.ok.status')
                                                                : geti18nText(
                                                                      'meal.delivery.edit.collapse.cancel.error'
                                                                  )}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    {data?.reservationResponseError && data?.reservationStatus != 0 && (
                                                        <Descriptions.Item
                                                            className={'ant-typography-print-desc'}
                                                            label={geti18nText(
                                                                'meal.delivery.edit.collapse.error.message'
                                                            )}
                                                        >
                                                            <Text className={'ant-typography-print-text'} strong>
                                                                {data?.reservationResponseError ?? '-'}
                                                            </Text>
                                                        </Descriptions.Item>
                                                    )}
                                                    <Divider />
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText('meal.delivery.edit.collapse.paymentType')}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {data?.paymentType ?? '-'}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item
                                                        className={'ant-typography-print-desc'}
                                                        label={geti18nText('meal.delivery.edit.collapse.priceList')}
                                                    >
                                                        <Text strong className={'ant-typography-print-text'}>
                                                            {data?.priceList ?? '-'}
                                                        </Text>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    {wizardStep == 3 && status == mealOrderStatus.UNCONFIRMED && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Result
                                    title={geti18nText('meal.order.edit.successUnconfirmed')}
                                    subTitle={
                                        geti18nText('meal.order.edit.orderSuccessUncofirmed') +
                                        ' ' +
                                        moment(form.getFieldValue('orderDate')).format('DD.MM.YYYY.')
                                    }
                                    extra={[
                                        <Button type="primary" key="console" onClick={nextOrder}>
                                            {geti18nText('meal.order.edit.orderFor')}{' '}
                                            {nextOrderDate && moment(nextOrderDate, 'DD.MM.YYYY').format('DD.MM.YYYY')}
                                        </Button>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    )}
                    {wizardStep == 3 && status == mealOrderStatus.CONFIRMED && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Result
                                    status="success"
                                    title={geti18nText('meal.order.edit.success')}
                                    subTitle={
                                        geti18nText('meal.order.edit.orderSuccess') +
                                        ' ' +
                                        moment(form.getFieldValue('orderDate')).format('DD.MM.YYYY.')
                                    }
                                    extra={[
                                        <Button type="primary" key="console" onClick={nextOrder}>
                                            {geti18nText('meal.order.edit.orderFor')}{' '}
                                            {nextOrderDate && moment(nextOrderDate, 'DD.MM.YYYY').format('DD.MM.YYYY')}
                                        </Button>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    )}
                </Form>
            </Modal>
            <Modal
                title={geti18nText('meal.order.edit.add.meal')}
                visible={modalItemVisible}
                maskClosable={false}
                width={600}
                onCancel={() => {
                    setItem(null);
                    setModalItemVisible(false);
                }}
                onOk={() => {
                    let totalFullPrice = 0;
                    items.map((item: any) => {
                        totalFullPrice = totalFullPrice + item?.quantity * item?.price;
                    });
                    let itemPrice = item?.priceList?.price ?? 0;
                    if (totalFullPrice + itemPrice > userAdvance) {
                        setSubventionOverflowConfirmed(false);
                        setModalItemVisible(false);
                        setItem(null);
                        warningNotification(geti18nText('meal.order.edit.noUserAdvance'));
                    } else {
                        setSubventionOverflowConfirmed(true);
                        setTotalSubvention(userSubvention);
                        setSubventionOverflowConfirmed(true);
                        setModalItemVisible(false);
                        addItemInBasket(item, true);
                        setItem(null);
                    }
                }}
                okText={geti18nText('app.default.button.yes')}
                cancelText={geti18nText('app.default.button.no')}
                destroyOnClose={true}
                className="modal-borderless"
            >
                <Alert
                    description={geti18nText('meal.order.edit.add.meal.confirm')}
                    type="warning"
                    showIcon
                    style={{ margin: '5px' }}
                />
            </Modal>
            <Modal
                title={geti18nText('meal.order.edit.change.deliverySpot')}
                visible={deliverySpotModalVisible}
                maskClosable={false}
                width={600}
                onCancel={() => {
                    form.setFieldsValue({ deliverySpot: selectedDeliverySpotValue });
                    setDeliverySpotModalVisible(false);
                }}
                onOk={() => {
                    form.setFieldsValue({ deliverySpot: selectedDeliverySpotConfirmValue });
                    setDeliveryType(deliveryTypeConfirm);
                    setSelectedDeliverySpot(selectedDeliverySpotConfirm);
                    setDeliveryTypeConfirm(null);
                    setSelectedDeliverySpotConfirm(selectedDeliverySpotConfirm);

                    setTotalAdvance(0);
                    setTotalSubvention(0);
                    setItems([]);
                    setItemsLength(0);
                    setCutleryNeeded(false);

                    setDeliverySpotModalVisible(false);
                }}
                okText={geti18nText('app.default.button.yes')}
                cancelText={geti18nText('app.default.button.no')}
                destroyOnClose={true}
                className="modal-borderless"
            >
                <Alert
                    description={geti18nText('meal.order.edit.change.deliverySpot.confirm')}
                    type="warning"
                    showIcon
                    style={{ margin: '5px' }}
                />
            </Modal>
            {deliverCutlery > 0 &&
                cutleryModalVisible &&
                cutleryNeeded == false &&
                status == mealOrderStatus.UNCONFIRMED && (
                    <Modal
                        title={geti18nText('meal.order.edit.cutlery.title')}
                        visible={cutleryModalVisible}
                        maskClosable={false}
                        width={500}
                        onCancel={() => {
                            setCutleryModalVisible(false);
                            setCutleryNeeded(false);
                        }}
                        onOk={() => {
                            setCutleryModalVisible(false);
                            setCutleryNeeded(true);
                        }}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        destroyOnClose={true}
                        className="modal-borderless"
                        // centered
                        closable={false}
                    >
                        <Alert
                            description={geti18nText('meal.order.edit.cutlery.content')}
                            type="info"
                            showIcon
                            style={{ margin: '5px' }}
                        />
                    </Modal>
                )}

            {uploadBillModalVisible && (
                <Modal
                    open={uploadBillModalVisible}
                    title={geti18nText('meal.delivery.table.upload.bill.report.action')}
                    maskClosable={false}
                    onCancel={() => setUploadBillModalVisible(false)}
                    footer={[
                        <Button onClick={() => setUploadBillModalVisible(false)}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            onClick={uploadBill}
                            disabled={!canCreateAdmin() || uploadBillFiles?.length < 1}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                    width={600}
                >
                    <Form form={formBill} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="billNumber" label={geti18nText('meal.delivery.edit.bill.billNumber')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="file" label={geti18nText('import.csv.file.name')}>
                                    <NyImageUpload
                                        files={uploadBillFiles}
                                        setFiles={setUploadBillFiles}
                                        saveOnUpload={true}
                                        accept={'*'}
                                        cropShape={null}
                                        listType={'text'}
                                        maxImage={1}
                                        canUpload={canCreateAdmin()}
                                        canEditFile={canCreateAdmin()}
                                        canDeleteFile={false}
                                        canEditName={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default MealOrderWizard;
